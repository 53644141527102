export enum Obras {
  Residencial = 'Residencial',
  Comercial = 'Comercial',
  Industrial = 'Industrial',
  Servicos = 'Serviços',
  Infraestrutura = 'Infraestrutura'
}

export interface Obra {
    obra: Obras[];
}


