import { Entidade } from './entidade.model';
import { Produto } from './produto.model';
import { Endereco } from './endereco';
import { SecretariaPmpa } from './secretariaPmpa.model';

export interface Plano {
  id: number;
  entidadeDO: Entidade;
  secretariaPmpaDO: SecretariaPmpa;
  produtoDO: Produto;
  descricao?: string;
  // acoes?: string;
  // situacao?: string;
  // dataAceite?: Date;
  // usuarioAceite?: string;
  dataInclusao: Date;
  timestampAsString: string;
  usuarioInclusao: string;
  usuarioAlteracao?: string;
  dataAnulacao: Date;
  usuarioAnulacao: string;
  justificativaAnulacao: string;
  codigoDMLU?: string;
}

// Para passar para o http put
export class InfoPlano {
  id: number;
  idEntidade: number;
  idSecretariaPmpa: number;
  idProduto: number;
  idEntidadeTransporte: number;
  idEntidadeDestino: number;
  idAnexoPlano: number;
  idRTElaboracaoPessoa: number;
  idRTElaboracaoAnexo: number;
  idRTExecucaoPessoa: number;
  idRTExecucaoAnexo: number;
  descricao?: string;
  quantidadePrevista?: number;
  unidadeQuantidadePrev: string;
  quantidadeRealizada?: number;
  endereco: Endereco;
  codigoDMLU?: string;
}
