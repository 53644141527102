import { Entidade } from './entidade.model';
import { Endereco } from './endereco';
import { LicencaAmbiental } from './licencaAmbiental.model';

export class ObraPmpa {
  id: number;
  nome: string;
  observacao: string;
  timestampAsString: string;
  dataInclusao: string;
  usuarioInclusao: string;
  usuarioAlteracao: string;
  status: string;
  endereco: Endereco;
  executora: Entidade;
  // licencaAmbientalDO?: LicencaAmbiental;
  // isentoLicenca?: boolean;
}


export interface ObraPmpaInter {
  id: number;
  nome: string;
  observacao: string;
  timestampAsString: string;
  dataInclusao: string;
  usuarioInclusao: string;
  usuarioAlteracao: string;
  status: string;
  endereco: Endereco;
  executora: Entidade;
  // licencaAmbientalDO?: LicencaAmbiental;
  // isentoLicenca?: boolean;

}
