import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { InfoPlano } from 'src/app/@shared/models/plano.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PlanoService = /** @class */ (function () {
    function PlanoService(http) {
        this.http = http;
    }
    PlanoService.prototype.excluirParticipacao = function (idPart) {
        var url = environment.apiUrl + '/planos/participacoes/' + idPart;
        return this.http.delete(url);
    };
    PlanoService.prototype.getAnexos = function (idPlano) {
        var url = environment.apiUrl + '/planos/' + idPlano + '/anexos';
        return this.http.get(url);
    };
    PlanoService.prototype.salvarAnexo = function (idPlano, anexo) {
        console.log(anexo);
        var url = environment.apiUrl + '/planos/' + idPlano + '/anexos';
        return this.http.post(url, anexo);
    };
    PlanoService.prototype.setFile = function (idAnexo, dados) {
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/octet-stream'
            })
        };
        var url = environment.apiUrl + '/anexos/' + idAnexo + '/file';
        var ret = this.http.put(url, dados, httpOptions);
        return ret;
    };
    PlanoService.prototype.excluirAnexo = function (idAnexo) {
        console.log('aaa');
        console.log(idAnexo);
        var url = environment.apiUrl + '/anexos/' + idAnexo;
        return this.http.delete(url);
    };
    PlanoService.prototype.getParticipacoes = function (idPlano) {
        var url = environment.apiUrl + '/planos/' + idPlano + '/participacoes';
        var ret = this.http.get(url);
        return ret;
    };
    PlanoService.prototype.salvarParticipacoes = function (idPlano, participacoes) {
        var url = environment.apiUrl + '/planos/' + idPlano + '/participacoes';
        var ret = this.http.put(url, participacoes);
        return ret;
    };
    PlanoService.prototype.getEntidadePorIdOuUsername = function (idEntidade, username) {
        var url = environment.apiUrl + '/entidades/idusername/' + idEntidade + '/' + username;
        return this.http.get(url);
    };
    PlanoService.prototype.getEntidadeDoPessoaFisica = function (user) {
        var url = environment.apiUrl + 'entidades/username/' + user;
        return this.http.get(url);
    };
    PlanoService.prototype.salvarMetas = function (idPlano, metas) {
        console.log('bbb');
        var url = environment.apiUrl + '/planos/' + idPlano + '/metas';
        var msg = this.http.put(url, metas);
        console.log('ccc');
        return msg;
    };
    PlanoService.prototype.aceitarPlano = function (idPlano) {
        var url = environment.apiUrl + '/planos/' + idPlano + '/aceitar';
        var o = this.http.get(url);
        return o;
    };
    PlanoService.prototype.devolverPlano = function (idPlano) {
        var url = environment.apiUrl + '/planos/' + idPlano + '/devolver';
        var o = this.http.get(url);
        return o;
    };
    PlanoService.prototype.enviarPlano = function (idPlano) {
        var url = environment.apiUrl + '/planos/' + idPlano + '/enviar';
        var o = this.http.put(url, null);
        return o;
    };
    PlanoService.prototype.getProdutos = function () {
        var url = environment.apiUrl + '/produtos';
        return this.http.get(url);
    };
    PlanoService.prototype.getPlanos = function () {
        return this.http.get(environment.apiUrl + '/planos/');
    };
    PlanoService.prototype.getOperadores = function () {
        var url = environment.apiUrl + '/entidades/operadores';
        return this.http.get(url);
    };
    /**
      getOperadoresDoPlano(idPlano:number): Observable<Entidade[]> {
        console.log('Vai chamar operadores do plano');
        const url = environment.apiUrl + '/planos/' + idPlano + '/operadores';
        const ret = this.http.get<Entidade[]>(url);
        console.log('Chamou operadores do plano ');
        return ret;
      }
     */
    PlanoService.prototype.getMetasDoPlanoPorAno = function (idPlano, anoReferencia) {
        var url = environment.apiUrl + '/planos/' + idPlano + '/' + anoReferencia + '/metas';
        return this.http.get(url);
    };
    PlanoService.prototype.getMetaDoPlano = function (idPlano) {
        var url = environment.apiUrl + '/planos/' + idPlano + '/meta';
        return this.http.get(url);
    };
    PlanoService.prototype.getTermo = function (idPlano) {
        var url = environment.apiUrl + '/planos/' + idPlano + '/termo';
        return this.http.get(url);
    };
    PlanoService.prototype.getDestinadoresDoPlano = function (idPlano) {
        var url = environment.apiUrl + '/planos/' + idPlano + '/destinadores';
        return this.http.get(url);
    };
    PlanoService.prototype.getDestinadores = function () {
        var url = environment.apiUrl + '/entidades/destinadores';
        return this.http.get(url);
    };
    PlanoService.prototype.getPlanosDaEntidade = function (idEntidade, anoReferencia) {
        var url = environment.apiUrl + '/planos/' + idEntidade + '/' + anoReferencia + '/planos';
        console.log('getPlanosDaEntidade....url.....>>>>>', url);
        return this.http.get(url);
    };
    PlanoService.prototype.getPlanosDaEntidadeAnoAnterior = function (idEntidade) {
        var url = environment.apiUrl + '/planos/' + idEntidade + '/planosAnoAnterior';
        console.log('getPlanosDaEntidade....url.....>>>>>', url);
        return this.http.get(url);
    };
    PlanoService.prototype.getPlanosRepresentados = function () {
        var url = environment.apiUrl + '/planos/representados';
        var newLocal = this.http.get(url);
        return newLocal;
    };
    PlanoService.prototype.getEntidade = function (id) {
        var url = environment.apiUrl + '/entidades/' + id;
        return this.http.get(url);
    };
    PlanoService.prototype.getPlano = function (id) {
        return this.http.get(environment.apiUrl + '/planos/');
    };
    PlanoService.prototype.criarPlano = function (infoPlano) {
        // const p = new InfoPlano();
        // p.idProduto = plano.produtoDO.id;
        // p.idEntidade = plano.entidade.id;
        // p.descricao = plano.descricao;
        // p.acoes = plano.acoes;
        console.log('PlanoService criarPlano .................', infoPlano);
        return this.http.post(environment.apiUrl + '/planos/', infoPlano);
    };
    PlanoService.prototype.anularPlano = function (plano) {
        return this.http.put(environment.apiUrl + '/planos/anulacao', plano);
    };
    PlanoService.prototype.atualizarPlano = function (plano) {
        var p = new InfoPlano();
        p.id = plano.id;
        p.idProduto = plano.produtoDO.id;
        p.idEntidade = plano.entidadeDO.id;
        p.descricao = plano.descricao;
        return this.http.put(environment.apiUrl + '/planos/', p);
    };
    PlanoService.prototype.excluirPlano = function (plano) {
        return this.http.delete(environment.apiUrl + '/planos/' + plano.id);
    };
    // obtemTiposDeEntidade(): Observable<KeyPair[]> {
    //   const url = environment.apiUrl + '/entidades/tipos';
    //   return this.http.get<KeyPair[]>(url);
    // }
    // obtemPapeis(): Observable<KeyPair[]> {
    //   const url = environment.apiUrl + '/' + this.globals.tipoAcesso + '/app/papeis';
    //   return this.http.get<KeyPair[]>(url);
    // }
    PlanoService.prototype.atualizaPlanoAnexoPessoaFisica = function () {
        console.log('atualizaPlanoAnexoPessoaFisica>>>>>>>>>>', environment.apiUrl + '/planos/atualizaPlanoAnexoPessoaFisica');
        return this.http.get(environment.apiUrl + '/planos/atualizaPlanoAnexoPessoaFisica');
    };
    PlanoService.prototype.atualizaQtdAnuaisRealizadasPrevistas = function (planos) {
        console.log('PlanoService atualizaQtdAnuaisRealizadasPrevistas .................', environment.apiUrl + '/planos/atualizaQuantidadesAnuais', planos);
        return this.http.put(environment.apiUrl + '/planos/atualizaQuantidadesAnuais', planos);
    };
    PlanoService.ngInjectableDef = i0.defineInjectable({ factory: function PlanoService_Factory() { return new PlanoService(i0.inject(i1.HttpClient)); }, token: PlanoService, providedIn: "root" });
    return PlanoService;
}());
export { PlanoService };
