import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AnexoService = /** @class */ (function () {
    function AnexoService(http) {
        this.http = http;
    }
    AnexoService.prototype.getAnexosPorEntidade = function (id, menuRaiz) {
        var url = environment.apiUrl + '/anexos/' + id + '/entidade/' + menuRaiz;
        console.log('AnexoService getAnexosPorEntidade url>>>>>', url);
        console.log('AnexoService getAnexosPorEntidade id>>>>>', id);
        return this.http.get(url);
    };
    AnexoService.prototype.getAnexos = function (id, urlCaminho) {
        var url = environment.apiUrl + urlCaminho + id + '/entidade';
        return this.http.get(url);
    };
    AnexoService.prototype.getAnexo = function (id) {
        var url = environment.apiUrl + '/anexos/' + id + '/file';
        console.log('AnexoService getAnexo url>>>>>', url);
        console.log('AnexoService getAnexo id>>>>>', id);
        return this.http.get(url);
    };
    AnexoService.prototype.getAnexoPorTipo = function (id, tipo) {
        var url = environment.apiUrl + '/anexos/' + id + '/' + tipo + '/porTipo';
        console.log('AnexoService getAnexo url>>>>>', url);
        console.log('AnexoService getAnexo id>>>>>', id);
        return this.http.get(url);
    };
    AnexoService.prototype.possuiAnexosObrigatorios = function (id, menuRaiz) {
        var url = environment.apiUrl + '/anexos/' + id + '/anexosObrigatorios/' + menuRaiz;
        console.log('AnexoService getAnexo url>>>>>', url);
        console.log('AnexoService getAnexo id>>>>>', id);
        return this.http.get(url);
    };
    AnexoService.prototype.incluirAnexo = function (id, anexo) {
        console.log('---------------------------------------------------------------');
        var rootUrl = environment.apiUrl + '/anexos/' + id + '/entidade';
        // console.log('Anexo Service - urlAnexo>>>>>>>', rootUrl);
        // console.log('Anexo Service - incluirAnexo>>>>>>>', anexo);
        return this.http.post(rootUrl, anexo);
    };
    AnexoService.prototype.salvarAnexo = function (id, anexo) {
        var url = environment.apiUrl + '/anexos/' + id + '/entidade';
        console.log('AnexoService salvarAnexo url>>>>>', url);
        console.log('AnexoService salvarAnexo anexo>>>>>', id, '---', anexo);
        return this.http.post(url, anexo);
    };
    AnexoService.prototype.setFile = function (idAnexo, dados) {
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/octet-stream'
            })
        };
        var url = environment.apiUrl + '/anexos/' + idAnexo;
        console.log('AnexoService setFile url>>>>>', url);
        console.log('AnexoService setFile anexo>>>>>', idAnexo);
        var ret = this.http.put(url, dados, httpOptions);
        console.log('AnexoService setFile ret>>>>>', ret);
        return ret;
    };
    AnexoService.prototype.excluirAnexo = function (idAnexo) {
        console.log('aaa');
        console.log(idAnexo);
        var url = environment.apiUrl + '/anexos/' + idAnexo;
        return this.http.delete(url);
    };
    AnexoService.prototype.situacaoAnexo = function (idAnexo, situacao) {
        var url = environment.apiUrl + '/anexosSituacao/' + idAnexo + '/' + situacao;
        var ret = this.http.get(url);
        console.log('AnexoService situacaoAnexo ret>>>>>', ret);
        return ret;
    };
    // editarObraPmpa(obraPmpa: ObraPmpa): Observable<ObraPmpa> {
    //   return this.http.put<ObraPmpa>(environment.apiUrl + '/obraPmpa/', obraPmpa);
    // }
    AnexoService.prototype.getAnexosDoPlano = function (id) {
        return this.http.get(environment.apiUrl + '/planoanexo/anexos/' + id);
    };
    AnexoService.ngInjectableDef = i0.defineInjectable({ factory: function AnexoService_Factory() { return new AnexoService(i0.inject(i1.HttpClient)); }, token: AnexoService, providedIn: "root" });
    return AnexoService;
}());
export { AnexoService };
