<sgr-menu></sgr-menu>

<div class="container">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/sgrIni">SGR</a></li>
    <li class="breadcrumb-item active" aria-current="page">MTRCC</li>
  </ol>

  <span *ngIf="!isPessoaFisicaVinculoEmpresa && variaveisGlobais.roleEntidade">
    <font color="red"><b>INFORMAÇÃO!</b></font>&nbsp;Caso você tenha vínculo com uma ou mais empresas, por favor
    providenciar a inclusão da(s) mesma(s) antes de gerar o MTRCC.<br>
  </span>
  <span *ngIf="isPessoaFisicaVinculoEmpresa && variaveisGlobais.roleEntidade">
    <font color="red"><b>INFORMAÇÃO!</b></font>&nbsp;Antes de gerar o MTRCC, favor atualizar, se necessário, as
    informações referentes à licença ambiental, endereço, veículo e responsável legal da(s) empresa(s).<br>
  </span>

  <div class="row justify-content-between">
    <div class="col-auto">
      <h1 class="page-header text-primary">
        MTRCC <br>
        <small>Manifesto de Transporte de Resíduos da Construção Civil </small>
      </h1>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-primary pr-4 mb-2" (click)="mostrarDialogParaNovo()"
        label="Adicionar Manifesto" *ngIf="roleEntidadeMtrcc && adicionarOk"><i class="fa fa-plus px-2"></i>Adicionar
        Manifesto</button>

      
      <button type="button" class="btn btn-primary pr-4 ml-2 mb-2" (click)="mostrarDialogParaModelo()"
        label="Adicionar Manifesto de Modelo" *ngIf="roleEntidadeMtrcc && adicionarOk && temModelo"><i
          class="fa fa-plus px-2"></i>Adicionar Manifesto de Modelo</button>

      <!-- <button type="button" (click)="resultadosManifestos()" label="Resultados Manifestos"
        class="btn btn-outline-success mx-2">Resultados</button> -->
      <span *ngIf="roleEntidadeMtrcc && !adicionarOk">
        <font size="3" color="red">FAVOR COMPLEMENTAR AS INFORMAÇÕES DA ENTIDADE!</font>
      </span>
    </div>
  </div>

  <div class="mobile-overflow-x">

    <!-- <p-table #dt [columns]="cols" [value]="cars" [paginator]="true" [rows]="10" [globalFilterFields]="['brand', 'color']">
      <ng-template pTemplate="caption">
          <div style="text-align: right">
              <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
              <input type="text" pInputText size="50" placeholder="Global Filter" (change)="doGlobalFilter($event.target.value)" style="width:auto">
          </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
          <tr>
              <th *ngFor="let col of columns">
                  {{col.header}}
              </th>
          </tr>
          <tr>
              <th *ngFor="let col of columns" [ngSwitch]="col.field">
                  <input *ngSwitchCase="'vin'" pInputText type="text" (input)="dt.filter($event.target.value, col.field, 'contains')">
                  <div *ngSwitchCase="'year'">
                      Value > {{yearFilter}}
                      <i class="pi pi-times" (click)="yearFilter=null;dt.filter(null, col.field, col.filterMatchMode)" style="cursor:pointer" *ngIf="yearFilter"></i>
                      <p-slider [style]="{'width':'100%','margin-top':'8px'}" [(ngModel)]="yearFilter" [min]="1970" [max]="2010" (onChange)="onYearChange($event, dt)"></p-slider>
                  </div>
                  <p-dropdown *ngSwitchCase="'brand'" [options]="brands" [style]="{'width':'100%'}" (onChange)="dt.filter($event.value, col.field, 'equals')"></p-dropdown>
                  <p-multiSelect *ngSwitchCase="'color'" [options]="colors" defaultLabel="All Colors" (onChange)="dt.filter($event.value, col.field, 'in')"></p-multiSelect>
                  <input *ngSwitchCase="'price'" pInputText type="text" placeholder="Custom - Greater Than" (input)="dt.filter($event.target.value, col.field, 'custom')">
              </th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
              <td *ngFor="let col of columns">
                  {{rowData[col.field]}}
              </td>
          </tr>
      </ng-template>
  </p-table> -->

    <p-table #tabelaManifestos [value]="manifestos" [paginator]="true" sortField="" [rows]="10"
      [globalFilterFields]="['numeroManifesto','geradorNome', 'transportadorNome', 'destinadorNome', 'destinadorEndereco']">
      <ng-template pTemplate="caption">
        <p-accordion>
          <p-accordionTab header="Filtros de pesquisa para buscar os manifestos na base de dados">
            <div class="row">
              <div class="form-group col-auto">
                <label for="">Número</label>
                <input [(ngModel)]="manifestoPesquisa" name="manifestoPesquisa" size='10' style="width: 110px"
                  class="form-control">
              </div>
              <div class="form-group col-auto">
                <label for="">Situação</label>
                <p-dropdown [options]="situacoes" [(ngModel)]="situacaoPesquisa" placeholder="Selecione"></p-dropdown>
              </div>
              <div class="form-group col-auto">
                <label for="">Período</label>
                <p-calendar [(ngModel)]="periodoPesquisa" numberOfMonths="3" selectionMode="range"
                  fixedWeekCount="false" [showIcon]="true" dateFormat="dd/mm/yy" showButtonBar="true" appendTo="body"
                  [style]="{'width':'232px', 'z-index': '999'}" [inputStyle]="{'width':'200px'}"></p-calendar>
              </div>
              <div class="form-group col-auto">
                <label for="">Secretaria</label>
                <p-dropdown [options]="secretarias" [(ngModel)]="secretariaPesquisa" placeholder="Selecione">
                </p-dropdown>
              </div>
              <div class="form-group col-auto">
                <label for="">Etapa</label>
                <p-dropdown [options]="etapas" [(ngModel)]="etapaPesquisa" placeholder="Selecione"></p-dropdown>
              </div>
              <div class="form-group col-auto">
                <label for="">Tipo Obra</label>
                <p-dropdown [options]="obras" [(ngModel)]="obraPesquisa" placeholder="Selecione"
                  [style]="{'width':'210px'}"></p-dropdown>
              </div>
              <div class="form-group col-auto">
                <label for="">Nome Obra</label>
                <p-dropdown [options]="nomeObras" [(ngModel)]="nomeObraPesquisa" placeholder="Selecione"
                  [style]="{'width':'250px'}"></p-dropdown>
              </div>
              <div class="form-group col-auto">
                <label for="">Material</label>
                <p-dropdown [options]="produtos" [(ngModel)]="produtoPesquisa" placeholder="Selecione"
                  [style]="{'width':'500px'}"></p-dropdown>
              </div>
              <div class="ui-inputgroup endereco" procempaCdl [codigo]="logradouroCdl?.codigoLogradouro"
                [numero]="logradouroCdl?.numero" [localizar]="true">
                <div class="form-group col-auto">
                  <label for="">Endereço Resíduo</label>
                  <input cdlLogradouro [(ngModel)]="logradouro" name="logradouro"
                    (seleciona)="selecionaCDLPesquisa($event)" [ngModelOptions]="{standalone: true}"
                    style="width: 500px" class="form-control endereco-residuo">
                </div>
                <div class="form-group col-auto">
                  <label for="">Número</label>
                  <input cdlNumero [(ngModel)]="numero" name="numero" [ngModelOptions]="{standalone: true}"
                    style="width: 90px" class="form-control">
                </div>
              </div>
              <div class="form-group col-auto align-self-end">
                <button type="button" pButton class="btn btn-primary" (click)="pesquisaManifesto()" label="Pesquisa"
                  style="width:200px; height:38px"></button>
              </div>
            </div>
          </p-accordionTab>
        </p-accordion>

        <!-- <div class="row">
        <div class="col-12 pb-2">
          Filtrar manifestos da lista abaixo
        </div>
        <div class="col-md-12 col-lg-6 busca form-group">
          <input type="text" pInputText size="70" placeholder="Filtrar manifestos"  id="filtro" name="filtro" #filtro (input)="tabelaManifestos.filterGlobal($event.target.value, 'contains')" class="form-control">
            <i class="fa fa-search" aria-hidden="true"></i>
        </div>
      </div> -->

        <div class="row justify-content-between py-3">
          <div class="col-auto num-resultados busca">
            <input type="text" pInputText size="60" placeholder="Filtrar manifestos da lista abaixo" id="filtro"
              name="filtro" #filtro (input)="tabelaManifestos.filterGlobal($event.target.value, 'contains')"
              class="form-control">
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
          <div class="col-auto num-resultados">
            <label for="numResultados">Resultados encontrados:</label>
            <strong id="numResultados" class="text-primary">{{tabelaManifestos._totalRecords}}</strong>
          </div>
          <div class="col-auto">
            <button type="button" (click)="geraPdfListaManifesto(tabelaManifestos, 'pdf')"
              label="Gera pdf dos Manifestos da lista" class="btn btn-outline-success mx-2">Baixar PDF</button>
            <button type="button" (click)="geraPdfListaManifesto(tabelaManifestos, 'excel')"
              label="Gera excel dos Manifestos da lista" class="btn btn-outline-success">Baixar EXCEL</button>
          </div>
        </div>


        <!-- <div class="ui-g-12" style="border: groove"> -->

      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th class="pl-2 text-center" pSortableColumn="numeroManifesto" style="width: 100px">
            Número
            <p-sortIcon class="align-right" field="numeroManifesto" style="padding-right: 0"></p-sortIcon>
          </th>

          <th class="pl-2 text-center" pSortableColumn="geradorNome" style="width: 200px">
            Gerador
            <p-sortIcon field="geradorNome"></p-sortIcon>
            <span style="font-size:10px">
              <p-dropdown [options]="manifestosGeradores" [style]="{'width':'100%'}"
                (onChange)="tabelaManifestos.filter($event.value, 'geradorNome', 'equals')"></p-dropdown>
            </span>
          </th>

          <th class="pl-2 text-center" pSortableColumn="transportadorNome" style="width: 200px;">
            Transportador
            <p-sortIcon field="transportadorNome"></p-sortIcon>
            <span style="font-size:10px">
              <p-dropdown [options]="manifestosTransportadores" [style]="{'width':'100%'}"
                (onChange)="tabelaManifestos.filter($event.value, 'transportadorNome', 'equals')"></p-dropdown>
            </span>
          </th>

          <th class="pl-2 text-center" pSortableColumn="destinadorNome" style="width: 200px;">
            Destino Final
            <p-sortIcon field="destinadorNome"></p-sortIcon>
            <span style="font-size:10px">
              <p-dropdown [options]="manifestosDestinadores" [style]="{'width':'100%'}"
                (onChange)="tabelaManifestos.filter($event.value, 'destinadorNome', 'equals')"></p-dropdown>
            </span>
          </th>
          <th class="pl-2 text-center" style="width: 100px;">
            Dt.Situação
          </th>

          <th class="pl-2 text-center" style="min-width: 100px; max-width: 350px;">Ações</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-manifesto>
        <tr>
          <!-- <td class="pl-3"><font size='2'>{{manifesto.numeroManifesto}}</font></td> -->
          <td class="pl-2" *ngIf="manifesto.validade">{{manifesto.numeroManifesto}}</td>
          <td class="pl-2" *ngIf="!manifesto.validade"><span
              style="background-color:red; color:white">&nbsp;&nbsp;&nbsp;{{manifesto.numeroManifesto}}&nbsp;&nbsp;&nbsp;</span>
          </td>
          <!-- <td>{{manifesto.geradorNome}}&nbsp;&nbsp;&nbsp;<button pButton (click)="totalizadorEntidade(manifesto.id)" class="ui-button-secondary botao-acao"><img src="assets/img/sigma.png" align="middle" height="22" width="22"></button></td> -->
          <td>{{manifesto.geradorNome}}</td>
          <td>{{manifesto.transportadorNome}}</td>
          <td *ngIf="manifesto.destinadorCnpj==='92.963.560/0001-60'">{{manifesto.destinadorEndereco}}</td>
          <td *ngIf="manifesto.destinadorCnpj!=='92.963.560/0001-60'">{{manifesto.destinadorNome}}</td>
          <td *ngIf="manifesto.destinadorDataRecebimento===null && manifesto.dataAnulacaoRecusa===null">
            {{manifesto.geradorDataExpedicao | date: 'dd/MM/yyyy' : 'pt' }}</td>
          <td *ngIf="manifesto.destinadorDataRecebimento!==null">
            {{manifesto.destinadorDataRecebimento | date: 'dd/MM/yyyy' : 'pt'}}</td>
          <td *ngIf="manifesto.dataAnulacaoRecusa!==null">
            {{manifesto.dataAnulacaoRecusa | date: 'dd/MM/yyyy' : 'pt'}}</td>
          <!-- <td *ngIf="situacaoPesquisa==='Todos'"></td> -->
          <td>
            <div class="botoes">
              <button class="btn btn-outline-secondary" (click)="mostrarDialogParaEditar(manifesto.id)"><i
                  class="fa fa-info pr-2"></i>Detalhes</button>

              <!-- TRANSPORTADOR -->
              <button title="Anular" (click)="mostrarDialogEditParaAnulacaoRecusa(manifesto.id, 'anular')"
                class="btn btn-outline-secondary"
                *ngIf="manifesto.isTransportador && manifesto.dataAnulacaoRecusa == null && manifesto.destinadorDataRecebimento == null"><i
                  class="fa fa-times pr-2"></i>Anular</button>

              <!-- DESTINADOR PESSOA JURÍDICA-->
              <button title="Receber" (click)="salvarDestinador(manifesto.id)" label="Receber"
                class="btn btn-outline-success"
                *ngIf="manifesto.isDestinador && manifesto.destinadorDataRecebimento == null && manifesto.dataAnulacaoRecusa == null">Receber</button>
              <button title="Recusar" (click)="mostrarDialogEditParaAnulacaoRecusa(manifesto.id, 'recusar')"
                label="Recusar" class="btn btn-outline-danger"
                *ngIf="manifesto.isDestinador && manifesto.destinadorDataRecebimento == null && manifesto.dataAnulacaoRecusa == null">Recusar</button>

              <!-- DESTINADOR PESSOA FÍSICA-->
              <button title="Receber" (click)="salvarDestinador(manifesto.id)" label="Receber"
                class="btn btn-outline-success"
                *ngIf="manifesto.destinadorCpf !== null  && manifesto.destinadorDataRecebimento == null && manifesto.dataAnulacaoRecusa == null && variaveisGlobais.roleEntidade">Receber</button>
              <button title="Recusar" (click)="mostrarDialogEditParaAnulacaoRecusa(manifesto.id, 'recusar')"
                label="Recusar" class="btn btn-outline-danger"
                *ngIf="manifesto.destinadorCpf !== null && manifesto.destinadorDataRecebimento == null && manifesto.dataAnulacaoRecusa == null && variaveisGlobais.roleEntidade">Recusar</button>
            </div>



            <span *ngIf="manifesto.destinadorDataRecebimento !== null"
              class="text-success">&nbsp;&nbsp;&nbsp;Recebido</span>
            <span *ngIf="manifesto.recusado" class="text-danger">&nbsp;&nbsp;&nbsp;Recusado</span>
            <span *ngIf="manifesto.anulado" class="text-secondary">&nbsp;&nbsp;&nbsp;Anulado</span>
          </td>
        </tr>
      </ng-template>

    </p-table>
  </div>
</div>


<div [hidden]="submitted" id="">
  <form [formGroup]="manifestoForm" (ngSubmit)="onSubmit()">

    <!-- Dialog com novo/edit -->
    <p-dialog class="modal-form" [header]="tituloDialogEdit" [(visible)]="mostrarDialogEdit" [responsive]="true"
      showEffect="fade" [modal]="true" [width]="1100" [height]="800" *ngIf="mostrarDialogEdit" class="scroller ontop"
      resizable="true">
      <!-- <input type="button" value="CPTURE" (click)="geraPdfManifesto()"/> -->

      <p-tabView [activeIndex]="index" (onChange)="changeTable($event)">
        <!-- ABA GERADOR -->
        <p-tabPanel header="Gerador" id="pdfGerador">

          <div class="nav-alternar-abas">
            <div class="text-right">
              <button type="button" (click)="alternarAbas(1)" class="btn btn-outline-secondary">Próximo<i
                  class="pi pi-angle-double-right"></i></button>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12 col-sm-4 xs-mb-2">
              <p-radioButton name="tipoGerador" value="juridica" label="Pessoa Jurídica" formControlName="tipoGerador"
                (onClick)="alternarTipoPessoa('juridica', 'gerador')"></p-radioButton>
            </div>
            <div class="col-12 col-sm-4">
              <p-radioButton name="tipoGerador" value="fisica" label="Pessoa Física" formControlName="tipoGerador"
                (onClick)="alternarTipoPessoa('fisica', 'gerador')"></p-radioButton>
            </div>
            <div class="col-12 col-sm-4 text-right">
              <!-- <button type="button" class="btn btn-primary pr-4" (click)="geraPdfManifesto(true)" label="PDF do Manifesto" *ngIf="!isInsert">PDF do Manifesto</button> -->
              <button type="button" (click)="geraPdfManifesto(true)" label="PDF do Manifesto"
                class="btn btn-outline-success mx-2" *ngIf="!isInsert && !isManifestoModelo">PDF do Manifesto</button>
            </div>
          </div>

          <div class="row" *ngIf="isGeradorPF()">
            <div class="col-12 col-sm-4 form-group">
              <!-- CPF -->
              <label>CPF</label>
              <p-inputMask formControlName="geradorCpf" mask="999.999.999-99" [style]="{'width':'100%'}" id="geradorCpf"
                #geradorCpf (onBlur)="buscaPessoa(geradorCpf.value, 'gerador')"></p-inputMask>
              <!-- <p-message severity="error" text="Informe o CPF!" *ngIf="!manifestoForm.controls['geradorCpf'].valid&&manifestoForm.controls['geradorCpf'].dirty"></p-message> -->
            </div>
            <div class="col-12 col-sm-8 form-group">
              <!-- Nome -->
              <label>Nome</label>
              <input pInputText type="text" formControlName="geradorNome" />
            </div>
          </div>

          <div class="row" *ngIf="!isGeradorPF()">
            <div class="col-12 col-sm-4 form-group">
              <!-- CNPJ -->
              <label>CNPJ</label>
              <p-inputMask formControlName="geradorCnpj" #geradorCnpj mask="99.999.999/9999-99" id="geradorCnpj"
                (onBlur)="buscaEntidade(geradorCnpj.value, 'gerador')" autofocus></p-inputMask>
              <!-- <p-message severity="error" text="Informe o CNPJ!" *ngIf="!manifestoForm.controls['geradorCnpj'].valid&&manifestoForm.controls['geradorCnpj'].dirty"></p-message> -->
              <!-- <div [hidden]="geradorCnpj.valid || geradorCnpj.pristine" class="alert alert-danger">
            Informe o CNPJ!
          </div> -->

              <!-- <script type="text/javascript">
              document.getElementById("geradorCnpj").focus();
            </script> -->

            </div>
            <div class="col-12 col-sm-8 form-group">
              <!-- Razão Social -->
              <label>Razão Social</label>
              <input pInputText type="text" formControlName="geradorNome" />
            </div>
          </div>
          <div class="row" *ngIf="!isInsert || isGeradorPF() || (!isGeradorPF() && isGeradorCNPJSemCadastro)">
            <div class="col-12 col-sm-8 form-group">
              <!-- Endereço -->
              <label>Endereço</label>
              <input pInputText formControlName="geradorEndereco" />
            </div>

            <div class="col-12 col-sm-4 form-group">
              <!-- Município -->
              <label>Município</label>
              <input pInputText formControlName="geradorMunicipio" />
            </div>
            <!-- <div class="p-col-4">
          <div class="p-grid">
            <div class="p-col">
              <label>Secretaria/Obra/Filial</label>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col">
              <input pInputText formControlName="geradorEnderecoIdentificacao"/>
            </div>
          </div>
        </div>
        <div class="p-col-8">
          <div class="p-grid">
            <div class="p-col">
              <label>Complemento/Observações</label>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col">
              <input pInputText formControlName="geradorEnderecoObservacao"/>
            </div>
          </div>
        </div> -->

          </div>
          <!-- <div class="p-grid" *ngIf="(!isGeradorPF() && isGeradorCNPJSemCadastro) || !isInsert">
          <div class="p-col-8">
            <div class="p-grid">
              <div class="p-col">
                <label>Endereço</label>
              </div>
            </div>
            <div class="p-grid">
              <div class="p-col">
                <input pInputText formControlName="geradorEndereco"/>
              </div>
            </div>
          </div>
          <div class="p-col-4">
            <div class="p-grid">
              <div class="p-col">
                <label>Município</label>
              </div>
            </div>
            <div class="p-grid">
              <div class="p-col">
                <input pInputText formControlName="geradorMunicipio"/>
              </div>
            </div>
          </div>
        </div> -->
          <div class="row">
            <div class="col-auto form-group"
              *ngIf="!isGeradorPF() && endsEntidadeGerador.length>0 && !isGeradorCNPJSemCadastro &&  isInsert">
              <label>Endereços</label>
              <p-table [value]="endsEntidadeGerador" [rows]="3" [paginator]="true">
                <ng-template pTemplate="body" let-endereco>
                  <tr>
                    <td style="width: 10px;">
                      <p-radioButton name="geradorEndSel" value="{{endereco.id}}" label=""
                        formControlName="geradorEndSel" (onClick)="enderecoSelecionado(endereco, 'gerador', 'tela')">
                      </p-radioButton>
                    </td>
                    <td style="width: 60px;" *ngIf="isPMPAGerador">
                      {{endereco.identificacao}}
                    </td>
                    <td style="width: 200px;">
                      {{endereco.enderecoFormatado}}
                    </td>
                    <td style="width: 100px;" *ngIf="!isPMPAGerador">
                      {{endereco.municipio}}
                    </td>
                    <td style="width: 100px;" *ngIf="!isPMPAGerador">
                      {{endereco.observacao}}
                    </td>
                    <!-- <td style="width: 100px; padding-left: 38px;">
                    {{endereco.identificacao}}
                </td>
                <td style="width: 200px;">
                  {{endereco.observacao}}
                </td> -->
                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div class="col-auto form-group" *ngIf="!isGeradorPF() && respsEntidadeGerador.length>0 &&  isInsert">
              <label *ngIf='isPMPAGerador'>Secretário Municipal</label>
              <label *ngIf='!isPMPAGerador'>Responsável</label>
              <p-table [value]="respsEntidadeGerador" [rows]="3" [paginator]="true">

                <ng-template pTemplate="body" let-pessoaFisica>
                  <tr>
                    <td style="width: 0px;">
                      <p-radioButton name="geradorRespSel" value="{{pessoaFisica.id}}" label=""
                        formControlName="geradorRespSel" (onClick)="responsavelSelecionado(pessoaFisica, 'gerador')">
                      </p-radioButton>
                    </td>
                    <td style="width: 300px;" *ngIf='!isPMPAGerador'>
                      {{pessoaFisica.email}}
                    </td>
                    <td style="width: 300px;" *ngIf='isPMPAGerador'>
                      {{pessoaFisica.nomeCompleto}}
                    </td>
                    <td style="width: 100px;" *ngIf='!isPMPAGerador'>
                      {{pessoaFisica.telefone}}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>



          <div class="row" *ngIf="!isInsert || isGeradorPF()|| (!isGeradorPF() && isGeradorCNPJSemCadastro)">
            <div class="col-12 col-sm-8 form-group">
              <!-- E-mail -->
              <label>E-mail</label>
              <input type="text" pInputText formControlName="geradorEmail" />
            </div>
            <div class="col-12 col-sm-4 form-group">
              <!-- Telefone -->
              <label>Telefone</label>
              <p-inputMask formControlName="geradorFone" mask="(99)9999-9999?9" [style]="{'width':'100%'}">
              </p-inputMask>
            </div>
          </div>

          <!-- <div class="p-grid" *ngIf="!isGeradorPF()">
        <div class="p-col">
          <div class="p-grid p-align-center">
            <div class="p-col">
              <label>Licença Ambiental / Declaração de Isenção</label>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col">
              <input pInputText formControlName="geradorLicenca"/>
            </div>
          </div>
        </div>
      </div> -->

          <div class="row" *ngIf="isGeradorPmpa()">
            <div class="col-12 col-md-9 form-group">
              <label>Observação</label>
              <textarea formControlName="geradorObservacao" rows="2" cols="80" pInputTextarea></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-auto form-group">
              <!-- Data de Expedição -->
              <label>Data de Expedição</label>
              <!-- <p-calendar formControlName="geradorDataExpedicao" name="geradorDE" #geradorDE appendTo="body" [showIcon]="true" dateFormat="dd/mm/yy" [locale]="languageCalendar"
             [minDate]="minimumDateGerador" (onBlur)="mudaDataTransportador($event)" ></p-calendar> -->
              <p-calendar formControlName="geradorDataExpedicao" name="geradorDE" #geradorDE appendTo="body"
                [showIcon]="true" dateFormat="dd/mm/yy" [locale]="languageCalendar" [minDate]="minimumDateGerador"
                (onSelect)="mudaDataTransportador($event)"></p-calendar>
            </div>
          </div>

          <p-table [value]="licencasEUsVOList" [paginator]="true" [rows]="10"
            *ngIf="!variaveisGlobais.roleEntidade && licencaEUGerador">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="width: 150px; height: 60px;">
                  Licença Ambiental
                </th>
                <th class="text-center" style="width: 150px; height: 60px;">
                  Expediente Único
                </th>
                <th class="text-center" style="width: 150px;">
                  Número do SEI (se houver)
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-leu>
              <tr *ngIf="leu.licencaGerador && leu.licencaCodigo!==''">
                <td>{{leu.licencaCodigo}}</td>
                <td></td>
                <td></td>
              </tr>
              <tr *ngIf="leu.euGerador && leu.euFormatado!==''">
                <td></td>
                <td><a href="{{leu.euUrl}}">{{leu.euFormatado}}</a></td>
                <td *ngIf="leu.euSei !== 'null'">
                  <a href="{{leu.euUrlSei}}">{{leu.euSei}}</a>
                </td>
                <td *ngIf="leu.euSei === 'null'"></td>
              </tr>
            </ng-template>
          </p-table>

        </p-tabPanel>

        <!-- ABA RESÍDUOS -->
        <p-tabPanel header="Resíduos" id="pdfResiduos">

          <div class="nav-alternar-abas">
            <div class="text-right">
              <button type="button" (click)="alternarAbas(0)" class="btn btn-outline-secondary"><i
                  class="pi pi-angle-double-left"></i>Anterior</button>
              <button type="button" (click)="alternarAbas(2)" class="btn btn-outline-secondary">Próximo<i
                  class="pi pi-angle-double-right"></i></button>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-auto form-group">
              <label>Tipo da Obra</label>
              <p-dropdown [options]="obras" formControlName="obra" placeholder="Selecione"></p-dropdown>
            </div>
            <div class="col-12 col-sm-auto form-group">
              <label>Etapa</label>
              <p-dropdown [options]="etapas" formControlName="etapa" placeholder="Selecione"></p-dropdown>
            </div>
            <div class="col-12 col-sm-4 col-md-2 form-group">
              <label>Quantidade</label>
              <input pInputText formControlName="quantidade" minlength="1" (keypress)="numberOnly($event)">
            </div>
            <div class="col-12 col-sm-8 col-md-4 sm-align-form form-group">
              <p-radioButton name="unidadeQuantidade" value="m³" label="m³" formControlName="unidadeQuantidade"
                class="mr-3"></p-radioButton>
              <p-radioButton name="unidadeQuantidade" value="toneladas" label="toneladas"
                formControlName="unidadeQuantidade"></p-radioButton>
            </div>
          </div>
          <div class="row" *ngIf="informaObraPmpa">
            <div class="col-12 col-sm-auto form-group">
              <label>Nome da Obra</label>
              <p-dropdown [options]="nomeObras" formControlName="nomeobra" placeholder="Selecione"
                (onChange)="buscaExecutora($event.value)"></p-dropdown>
            </div>
            <div class="col-12 col-sm-auto sm-align-form form-group">
              <label>Empresa Executora: </label><strong> {{entidadeExecutora?.razaoSocial}}</strong>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 form-group">
              <label>Materiais Predominantes</label>
              <p-multiSelect [options]="produtos" formControlName="produto" defaultLabel="Selecione" selectionLimit="5"
                [maxSelectedLabels]="5" appendTo="body" placeholder="Selecione"></p-multiSelect>
            </div>
          </div>
          <br><br>
          <div *ngIf="isInsert==true">
            <div class="row mb-3">
              <div class="col-auto xs-mb-2">
                <p-radioButton name="enderecoRetirada" value="poa" label="Endereço de Porto Alegre"
                  formControlName="enderecoRetirada" inputId="f01" (onClick)="alternarEndereco('poa')"></p-radioButton>
              </div>
              <div class="col-auto">
                <p-radioButton name="enderecoRetirada" value="foraPoa" label="Endereço de Outros Municípios"
                  formControlName="enderecoRetirada" inputId="f02" (onClick)="alternarEndereco('foraPoa')">
                </p-radioButton>
              </div>
            </div>


            <div *ngIf="isPoa">
              <!-- <label class="pr-2">Endereço de retirada dos resíduos - Porto Alegre</label> -->
              <div class="row procempaCdl" procempaCdl [maxResults]="50" [codigo]="logradouroCdl?.codigoLogradouro"
                [numero]="logradouroCdl?.numero" [localizar]="true">
                <div class="col-12 col-sm-8 form-group">
                  <label>Logradouro</label>
                  <input pInputText cdlLogradouro formControlName="logradouro" size="70" name="logradouro"
                    (seleciona)="selecionaCDL($event)" placeholder="Informe NOME">
                </div>
                <div class="col-12 col-sm-4 form-group">
                  <label>Número</label>
                  <input pInputText cdlNumero formControlName="numero" name="numero" size="15"
                    placeholder="Informe NÚMERO">
                </div>
              </div>

            </div>

            <div class="panel-group" *ngIf="!isPoa">
              <div class="panel panel-default">
                <!-- <label class="pr-2">Endereço de retirada dos resíduos - Outros Municípios</label> -->
                <div class="panel-body">
                  <div class="row">
                    <div class="col-12 col-sm-9 form-group">
                      <label for="vin">Logradouro</label>
                      <input pInputText id="nomeLogradouro" formControlName="logradouro" name="logradouro"
                        placeholder="Informe NOME" size="50">
                    </div>
                    <div class="col-12 col-sm-3 form-group">
                      <label for="vin">Número</label>
                      <input pInputText id="numero" style="width: 150px" formControlName="numero" name="numero"
                        placeholder="Informe NÚMERO" class="d-block">
                    </div>
                    <div class="col-12 form-group">
                      <label for="vin">Município</label>
                      <input pInputText id="municipio" size="25" formControlName="municipio" name="municipio"
                        placeholder="Informe MUNICÍPIO">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div *ngIf="isInsert!==true && manifestoEdit.endereco!==null">
            <hr>
            <label class="pr-2">Endereço de retirada dos resíduos</label>
            <p>{{enderecoRetiradaCompleto}}</p>

          </div>
          <br><br><br><br><br><br><br><br><br><br><br>
        </p-tabPanel>

        <!-- ABA TRANSPORTADOR -->
        <p-tabPanel header="Transportador" id="pdfTransportador">

          <div class="nav-alternar-abas">
            <div class="text-right">
              <button type="button" (click)="alternarAbas(1)" class="btn btn-outline-secondary"><i
                  class="pi pi-angle-double-left"></i>Anterior</button>
              <button type="button" (click)="alternarAbas(3)" class="btn btn-outline-secondary">Próximo<i
                  class="pi pi-angle-double-right"></i></button>
            </div>
          </div>

          <!-- Tipo -->
          <div class="row mb-3">
            <div class="col-12 col-sm-4 xs-mb-2">
              <p-radioButton name="tipoTransportador" value="juridica" label="Pessoa Jurídica"
                formControlName="tipoTransportador" (onClick)="alternarTipoPessoa('juridica', 'transportador')">
              </p-radioButton>
            </div>
            <div class="col-12 col-sm-4">
              <p-radioButton name="tipoTransportador" value="fisica" label="Pessoa Física"
                formControlName="tipoTransportador" (onClick)="alternarTipoPessoa('fisica', 'transportador')">
              </p-radioButton>
            </div>
            <!-- <div class="p-col-6">
            <p-radioButton name="tipoTransportador" value="proprio" label="O Próprio" formControlName="tipoTransportador" (onClick)="alternarTipoPessoa('proprio', 'transportador')"></p-radioButton>
        </div> -->
          </div>

          <div class="row" *ngIf="isTransportadorPF()">
            <div class="col-12 col-sm-4 form-group">
              <!-- CPF -->
              <label>CPF</label>
              <!-- <p-inputMask formControlName="transportadorCpf" mask="999.999.999-99" [style]="{'width':'100%'}"></p-inputMask> -->
              <p-inputMask formControlName="transportadorCpf" mask="999.999.999-99" [style]="{'width':'100%'}"
                id="transportadorCpf" #transportadorCpf (onBlur)="buscaPessoa(transportadorCpf.value, 'transportador')">
              </p-inputMask>

            </div>
            <div class="col-12 col-sm-8 form-group">
              <!-- Nome -->
              <label>Nome</label>
              <input pInputText formControlName="transportadorNome" />
            </div>
          </div>

          <div class="row" *ngIf="!isTransportadorPF()">
            <div class="col-12 col-sm-4 form-group">
              <!-- CNPJ -->
              <label>CNPJ</label>
              <p-inputMask formControlName="transportadorCnpj" #transportadorCnpj mask="99.999.999/9999-99"
                (onBlur)="buscaEntidade(transportadorCnpj.value, 'transportador')" [style]="{'width':'100%'}" autofocus>
              </p-inputMask>
            </div>
            <div class="col-12 col-sm-8 form-group">
              <!-- Razão Social -->
              <label>Razão Social</label>
              <input pInputText formControlName="transportadorNome" />
            </div>
          </div>

          <div class="row" *ngIf="isInsert && !isTransportadorPF() && endsEntidadeTransportador.length>0">
            <div class="col-auto form-group">
              <label>Endereços</label>
              <p-table [value]="endsEntidadeTransportador" [rows]="3" [paginator]="true">
                <ng-template pTemplate="body" let-endereco>
                  <tr>
                    <td style="width: 0px;">
                      <p-radioButton name="transportadorEndSel" value="{{endereco.id}}" label=""
                        formControlName="transportadorEndSel"
                        (onClick)="enderecoSelecionado(endereco, 'transportador', 'tela')"></p-radioButton>
                    </td>
                    <td style="width: 300px;padding-left: 38px;">
                      {{endereco.enderecoFormatado}}
                    </td>
                    <td style="width: 100px;">
                      {{endereco.municipio}}
                    </td>
                    <td style="width: 150px;">
                      <span *ngIf="endereco.licencaAmbientalDO !== null">L.Ambiental:
                        {{endereco?.licencaAmbientalDO?.licenca}}</span>
                      <span *ngIf="endereco.licencaAmbientalDO === null && endereco.isentoLicenca">L.Ambiental:
                        Isento</span>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

          </div>
          <div class="row" *ngIf="!isInsert || isTransportadorPF()">
            <div class="col-12 col-sm-8 form-group">
              <!-- Endereço -->
              <label>Endereço</label>
              <input pInputText formControlName="transportadorEndereco" />
            </div>
            <div class="col-12 col-sm-4 form-group">
              <!-- Município -->
              <label>Município</label>
              <input pInputText formControlName="transportadorMunicipio" />
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-8 form-group">
              <!-- E-mail -->
              <label>E-mail</label>
              <input pInputText formControlName="transportadorEmail" />
            </div>
            <div class="col-12 col-sm-4 form-group">
              <!-- Telefone -->
              <label>Telefone</label>
              <p-inputMask formControlName="transportadorFone" mask="(99)9999-9999?9" [style]="{'width':'100%'}">
              </p-inputMask>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-3 form-group" *ngIf="(!isInsert || isTransportadorPF()) && !isManifestoModelo">
              <label>Veículo *</label>
              <input pInputText formControlName="transportadorPlaca" />
            </div>
          </div>



          <!-- <div class="p-grid" *ngIf="isInsert && !isTransportadorPF() && veisEntidadeTransportador.length>0">
        <label>Veículos *</label>
        <p-table [value]="veisEntidadeTransportador">

          <ng-template pTemplate="body" let-veiculo>
            <tr>
              <td style="width: 15px;">
                <p-radioButton name="transportadorVeiSel" value="{{veiculo.id}}" label="" formControlName="transportadorVeiSel" (onClick)="veiculoSelecionado(veiculo, 'transportador')"></p-radioButton>
              </td>
              <td style="width: 50px;">
                  {{veiculo.placa}}
              </td>
              <td style="width: 50px;">
                {{veiculo.renavam}}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <br>
      </div> -->

          <div class="row"
            *ngIf="(isInsert && !isTransportadorPF() && veisEntidadeTransportador.length>0) || isManifestoModelo">
            <div class="col-auto form-group">
              <label>Veículos</label>
              <p-table [value]="veisEntidadeTransportador" [rows]="3" [paginator]="true">

                <ng-template pTemplate="body" let-veiculo>
                  <tr>
                    <td style="width: 15px;">
                      <p-radioButton name="transportadorVeiSel" value="{{veiculo.id}}" label=""
                        formControlName="transportadorVeiSel" (onClick)="veiculoSelecionado(veiculo, 'transportador')">
                      </p-radioButton>
                    </td>
                    <td style="width: 50px;">
                      {{veiculo.placa}}
                    </td>
                    <td style="width: 50px;">
                      {{veiculo.renavam}}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>


          <!-- <div class="p-col-3" *ngIf="!isTransportadorPF()">
          <div class="p-grid p-align-center">
            <div class="p-col">
              <label>Licença Ambiental</label>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col">
              <input pInputText formControlName="transportadorLicenca"/>
            </div>
          </div>
        </div> -->

          <!-- <div class="p-grid">
            <div class="p-col">
              <label>Placa *</label>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col">
              <input pInputText formControlName="transportadorPlaca"/>
            </div>
          </div> -->
          <div class="row" *ngIf="isInsert || manifestoEdit.transportadorDataTransporte!=null">
            <div class="col-auto form-group">
              <!-- Data de Transporte -->
              <label>Data de Transporte</label>
              <p-calendar formControlName="transportadorDataTransporte" appendTo="body" dateFormat="dd/mm/yy"
                [locale]="languageCalendar" [showIcon]="true" [minDate]="minimumDateTransportador"></p-calendar>
            </div>
          </div>
          <div class="row" *ngIf="manifestoEdit.anulado">
            <div class="col-4 form-group">
              <!-- Data de Anulação -->
              <label>Data de Anulação</label>
              <p-calendar formControlName="dataAnulacaoRecusa" dateFormat="dd/mm/yy" [locale]="languageCalendar"
                [showIcon]="true"></p-calendar>
            </div>
            <div class="col-8 form-group">
              <!-- Justificativa anulação -->
              <label>Justificativa de Anulação</label>
              <input pInputText formControlName="justificativaAnulacaoRecusa" disabled />
            </div>
          </div>
        </p-tabPanel>

        <!-- ABA DESTINO FINAL -->
        <p-tabPanel header="Destino Final" id="pdfDestinoFinal">

          <div class="nav-alternar-abas">
            <div class="text-right">
              <button type="button" (click)="alternarAbas(2)" class="btn btn-outline-secondary"><i
                  class="pi pi-angle-double-left"></i>Anterior</button>
            </div>
          </div>

          <!-- Tipo -->
          <div class="row mb-3">
            <div class="col-12 col-sm-4 xs-mb-2">
              <p-radioButton name="tipoDestinador" value="juridica" label="Pessoa Jurídica"
                formControlName="tipoDestinador" (onClick)="alternarTipoPessoa('juridica', 'destinador')">
              </p-radioButton>
            </div>
            <div class="col-12 col-sm-4">
              <p-radioButton name="tipoDestinador" value="fisica" label="Pessoa Física" formControlName="tipoDestinador"
                (onClick)="alternarTipoPessoa('fisica', 'destinador')"></p-radioButton>
            </div>
            <!-- <div class="p-col-6">
            <p-radioButton name="tipoDestinador" value="proprio" label="O Próprio" formControlName="tipoDestinador" (onClick)="alternarTipoPessoa('proprio', 'destinador')"></p-radioButton>
        </div> -->
          </div>

          <div class="row" *ngIf="isDestinoFinalPF()">
            <!-- <div class="col-12 mb-3">
          <label><strong class="text-danger">ATENÇÃO! </strong>Após receber ou recusar o recebimento do MTRCC, favor enviar um e-mail à SMAMS (smams.mtrcc@portoalegre.rs.gov.br) solicitando a baixa no Manifesto conforme a situação (Recebido ou Recusado - com motivo).</label>
        </div> -->
            <div class="col-12 col-sm-4 form-group">
              <!-- CPF -->
              <label>CPF</label>
              <!-- <p-inputMask formControlName="destinadorCpf" mask="999.999.999-99" [style]="{'width':'100%'}"></p-inputMask> -->
              <p-inputMask formControlName="destinadorCpf" mask="999.999.999-99" [style]="{'width':'100%'}"
                id="destinadorCpf" #destinadorCpf (onBlur)="buscaPessoa(destinadorCpf.value, 'destinador')">
              </p-inputMask>
            </div>
            <div class="col-12 col-sm-8 form-group">
              <!-- Nome -->
              <label>Nome</label>
              <input pInputText formControlName="destinadorNome" />
            </div>
          </div>

          <div class="row" *ngIf="!isDestinoFinalPF()">
            <div class="col-12 col-sm-4 form-group">
              <!-- CNPJ -->
              <label>CNPJ</label>
              <p-inputMask formControlName="destinadorCnpj" #destinadorCnpj mask="99.999.999/9999-99"
                (onBlur)="buscaEntidade(destinadorCnpj.value, 'destinador')" [style]="{'width':'100%'}"></p-inputMask>
            </div>
            <div class="col-12 col-sm-8 form-group">
              <!-- Razão Social -->
              <label>Razão Social</label>
              <input pInputText formControlName="destinadorNome" />
            </div>
          </div>
          <div class="row" *ngIf="isInsert && !isDestinoFinalPF() && endsEntidadeDestinador.length>0">
            <div class="col-auto form-group">
              <label>Endereços</label>
              <p-table [value]="endsEntidadeDestinador" [rows]="3" [paginator]="true">

                <ng-template pTemplate="body" let-endereco>
                  <tr>
                    <td style="width: 15px;">
                      <p-radioButton name="destinadorEndSel" value="{{endereco.id}}" label=""
                        formControlName="destinadorEndSel"
                        (onClick)="enderecoSelecionado(endereco, 'destinador', 'tela')"></p-radioButton>
                    </td>
                    <td style="width: 300px;" *ngIf="!isPMPADestinador">
                      {{endereco?.enderecoFormatado}}
                    </td>
                    <td style="width: 100px;" *ngIf="!isPMPADestinador">
                      {{endereco?.municipio}}
                    </td>
                    <td style="width: 150px;" *ngIf="isPMPADestinador">
                      {{endereco?.identificacao}}
                    </td>
                    <td style="width: 300px;" *ngIf="isPMPADestinador">
                      {{endereco?.enderecoFormatadoCurto}}
                    </td>
                    <td style="width: 150px;">
                      <span *ngIf="endereco.licencaAmbientalDO !== null">L.Ambiental:
                        {{endereco?.licencaAmbientalDO?.licenca}}</span>
                      <span *ngIf="endereco.licencaAmbientalDO === null && endereco.isentoLicenca">L.Ambiental:
                        Isento</span>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
          <div class="row" *ngIf="!isInsert || isDestinoFinalPF()">
            <div class="col-12 col-sm-6 form-group">
              <!-- Endereço -->
              <label>Endereço</label>
              <input pInputText formControlName="destinadorEndereco" />
            </div>
            <div class="col-12 col-sm-3 form-group">
              <!-- Município -->
              <label>Município</label>
              <input pInputText formControlName="destinadorMunicipio" />
            </div>
            <div class="col-12 col-sm-3 form-group">
              <label>Licença Ambiental</label>
              <input pInputText formControlName="destinadorLicenca" />
            </div>
          </div>

          <div class="row" *ngIf="!isPMPADestinador">
            <div class="col-12 col-sm-8 form-group">
              <!-- E-mail -->
              <label>E-mail</label>
              <input pInputText formControlName="destinadorEmail" />
            </div>
            <div class="col-12 col-sm-4 form-group">
              <!-- Telefone -->
              <label>Telefone</label>
              <p-inputMask formControlName="destinadorFone" mask="(99)9999-9999?9" [style]="{'width':'100%'}">
              </p-inputMask>
            </div>
          </div>

          <!-- <div class="row" *ngIf="!isInsert || isDestinoFinalPF()">

        <div class="col-12 col-sm-3 form-group">
          <label>Licença</label>
          <input pInputText formControlName="destinadorLicenca"/>
        </div>
        <div class="col-12 col-sm-3 form-group">
          <label>Órgão Emissor</label>
          <input pInputText formControlName="destinadorOrgaoEmissor"/>
        </div>
        <div class="col-12 col-sm-3 form-group">
          <label>Validade</label>
          <p-calendar formControlName="destinadorValidade" dateFormat="dd/mm/yy" [locale]="languageCalendar" [showIcon]="true"></p-calendar>
        </div>

      </div> -->

          <!--   <div *ngIf="isInsert && !isDestinoFinalPF()">

      <div class="p-grid">
        <div class="p-col-3">
          <label>Licença Ambiental</label>
        </div>
        <div class="p-col-5">
          <p-checkbox label=" Declaração de isenção." formControlName="destinadorLicencaIsento" name='destinadorLicencaIsento' #destinadorLicencaIsento (onChange)="licencaIsencao(destinadorLicencaIsento, 'destinador')"></p-checkbox>

        </div>
        <div class="p-col-4">
        </div>

      </div>



      <div class="p-grid">

        <p-table [value]="licsEntidadeDestinador" *ngIf="licsEntidadeDestinador.length>0">

          <ng-template pTemplate="body" let-licenca>
            <tr>
              <td style="width: 15px;">
                <p-radioButton name="destinadorLicSel" value="{{licenca.id}}" label="" formControlName="destinadorLicSel" (onClick)="licencaSelecionada(licenca, 'destinador')"></p-radioButton>
              </td>
              <td style="width: 50px;">
                  {{licenca?.licenca}}
              </td>
              <td style="width: 50px;">
                {{licenca?.secretariaPmpaDO?.sigla}}
              </td>
              <td style="width: 50px;">
                {{licenca?.dataValidade | date: 'dd/MM/yyyy' : 'pt'}}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>




    </div>
    -->





          <!-- <div class="p-grid" *ngIf="!isDestinoFinalPF()">
        <div class="p-col">
          <div class="p-grid p-align-center">
            <div class="p-col">
              <label>Licença Ambiental/ Declaração de Isenção</label>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col">
              <input pInputText formControlName="destinadorLicenca"/>
            </div>
          </div>
        </div>
      </div>
 -->
          <div class="row" *ngIf="isDestinadorPmpa()">
            <div class="col-12">
              <label>Observação</label>
              <textarea formControlName="destinadorObservacao" rows="2" cols="80" pInputTextarea></textarea>
            </div>
          </div>

          <div class="row" *ngIf="manifestoEdit.destinadorDataRecebimento!=null">
            <div class="col-auto form-group">
              <label>Data de Recebimento</label>
              <p-calendar formControlName="destinadorDataRecebimento" dateFormat="dd/mm/yy" [locale]="languageCalendar"
                [showIcon]="true"></p-calendar>
            </div>
          </div>
          <div class="row" *ngIf="manifestoEdit.recusado">
            <div class="col-4 col-lg-3 form-group">
              <label>Data de recusa</label>
              <p-calendar formControlName="dataAnulacaoRecusa" dateFormat="dd/mm/yy" [locale]="languageCalendar"
                [showIcon]="true"></p-calendar>
            </div>
            <div class="col-8 col-lg-9 form-group">
              <label>Justificativa de recusa</label>
              <input pInputText formControlName="justificativaAnulacaoRecusa" disabled />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 col-sm-8 col-lg-9">
              <button type="button" icon="pi pi-check" [disabled]="!manifestoForm.valid"
                *ngIf="isInsert && isPessoaFisicaVinculoEmpresa" (click)="salvar(true)"
                class="btn btn-primary mb-2">Salvar - Modelo</button>
              <div class="texto-nota" *ngIf="isInsert && isPessoaFisicaVinculoEmpresa">
                <em>
                  ATENÇÃO!!! Ao clicar no botão "Salvar - Modelo" o manifesto será salvo normalmente.
                  E além disso será marcado como modelo para a inclusão de novos manifestos tendo como base a maioria
                  suas informações,
                  necessitando informar apenas a quantidade do resíduo e a placa do veículo do Transportador.
                </em>
              </div>

              <!-- <p-checkbox label="Manifesto Modelo - as informações desse manifesto serão usadas para adicionar novos manifestos." formControlName="mtrccModelo"
              binary="false" name='mtrccModelo' #mtrccModelo (onChange)="selecionaModelo()" *ngIf="isInsert"></p-checkbox> -->
            </div>
            <div class="col-12 col-sm-4 col-lg-3 text-right">
              <button type="button" icon="pi pi-times" (click)="cancelar()"
                class="btn btn-outline-secondary">Cancelar</button>

              <button type="button" icon="pi pi-check" [disabled]="!manifestoForm.valid"
                *ngIf="(!manifestoEdit.isDestinador && manifestoEdit.dataAnulacaoRecusa == null && manifestoEdit.destinadorDataRecebimento == null && roleEntidadeMtrcc) && !isManifestoModelo"
                (click)="salvar(false)" class="btn btn-primary" style="margin-left: 5px">Salvar</button>
              <button type="button" icon="pi pi-check" [disabled]="!manifestoForm.valid" *ngIf="isManifestoModelo"
                (click)="salvar(false)" class="btn btn-primary" style="margin-left: 5px">Salvar</button>
            </div>

          </div>

          <p-table [value]="licencasEUsVOList" [paginator]="true" [rows]="10"
            *ngIf="!variaveisGlobais.roleEntidade && licencaEUDestinador">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="width: 150px; height: 60px;">
                  Licença Ambiental
                </th>
                <th class="text-center" style="width: 150px; height: 60px;">
                  Expediente Único
                </th>
                <th class="text-center" style="width: 150px;">
                  Número do SEI (se houver)
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-leu>
              <tr *ngIf="leu.licencaDestinador && leu.licencaCodigo!==''">
                <td>{{leu.licencaCodigo}}</td>
                <td></td>
                <td></td>
              </tr>
              <tr *ngIf="leu.euDestinador && leu.euFormatado!==''">
                <td></td>
                <td><a href="{{leu.euUrl}}">{{leu.euFormatado}}</a></td>
                <td *ngIf="leu.euSei !== 'null'">
                  <a href="{{leu.euUrlSei}}">{{leu.euSei}}</a>
                </td>
                <td *ngIf="leu.euSei === 'null'"></td>
              </tr>
            </ng-template>
          </p-table>



        </p-tabPanel>


      </p-tabView>

    </p-dialog>
  </form>
</div>

<!-- Dialog MANIFESTOS MODELOS -->
<p-dialog header="Modelos de Manifestos" [(visible)]="manifestoModelo" [responsive]="true" showEffect="fade"
  [modal]="true" resizable="true" [width]="1100" [height]="800" class="scroller">
  <!-- <p-tabView> -->

  <p-table #tabelaModeloMtrcc [value]="manifestosModelos" [paginator]="true" [rows]="10" sortField="geradorNome"
    [globalFilterFields]="['geradorNome','transportadorNome','destinadorNome', 'endereco.enderecoFormatadoCurto', 'produto']"
    *ngIf="entidades && entidades.length > 0">
    <ng-template pTemplate="caption">
      <div class="row">
        <div class="col-md-12 col-lg-12 busca form-group">
          <input type="text" pInputText size="120" placeholder="Filtrar manifestos da lista abaixo" id="filtro"
            name="filtro" #filtro (input)="tabelaModeloMtrcc.filterGlobal($event.target.value, 'contains')"
            class="form-control">
          <i class="fa fa-search" aria-hidden="true"></i>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="body" let-rowIndex="rowIndex" let-manifesto>
      <div class="p-grid">
        <div class="p-col-12">
          <font size="1">
            <label
              class="font-weight-bold">Gerador:&nbsp;&nbsp;&nbsp;</label>{{manifesto.geradorNome}}&nbsp;&nbsp;&nbsp;
            <label
              class="font-weight-bold">Transportador:&nbsp;&nbsp;&nbsp;</label>{{manifesto.transportadorNome}}&nbsp;&nbsp;&nbsp;
            <label class="font-weight-bold">Destino
              Final:&nbsp;&nbsp;&nbsp;</label>{{manifesto.destinadorNome}}&nbsp;&nbsp;&nbsp;
            <label
              class="font-weight-bold">Endereço:&nbsp;&nbsp;&nbsp;</label>{{manifesto.endereco?.enderecoFormatadoCurto}}<br>
            <label class="font-weight-bold">Tido da Obra:&nbsp;&nbsp;&nbsp;</label>{{manifesto.obra}}&nbsp;&nbsp;&nbsp;
            <label class="font-weight-bold">Etapa:&nbsp;&nbsp;&nbsp;</label>{{manifesto.etapa}}&nbsp;&nbsp;&nbsp;
            <label class="font-weight-bold">Materiais:&nbsp;&nbsp;&nbsp;</label>{{manifesto.produto}}
            <button title="Adicionar Manifesto" class="btn btn-outline-secondary mx-2"
              (click)="mostrarDialogParaNovoModelo(manifesto.id)" *ngIf="roleEntidadeMtrcc && adicionarOk"><i
                class="fa fa-plus px-2"></i>Adicionar Manifesto - Modelo {{rowIndex+1}}</button>
            &nbsp;&nbsp;&nbsp;
            <button title="Excluir Manifesto Modelo" class="btn btn-outline-secondary mx-2"
              (click)="desfazManifestoModelo(manifesto)" *ngIf="roleEntidadeMtrcc && adicionarOk"><i
                class="fa fa-times pr-2"></i>Excluir Manifesto - Modelo {{rowIndex+1}}</button>

            <!-- <div *ngFor="let produto of manifesto.produto">{{"zzz"}}</div><br> -->
          </font>
          <hr style="height:2px;border-width:0;color:gray;background-color:gray">
        </div>
      </div>
    </ng-template>






  </p-table>



  <!-- <div *ngFor="let manifesto of manifestosModelos; let i = index">
      <div class="p-grid">
        <div class="p-col-12">
          <font size="1">
          <label class="font-weight-bold">Gerador:&nbsp;&nbsp;&nbsp;</label>{{manifesto.geradorNome}}&nbsp;&nbsp;&nbsp;
          <label class="font-weight-bold">Transportador:&nbsp;&nbsp;&nbsp;</label>{{manifesto.transportadorNome}}&nbsp;&nbsp;&nbsp;
          <label class="font-weight-bold">Destino Final:&nbsp;&nbsp;&nbsp;</label>{{manifesto.destinadorNome}}&nbsp;&nbsp;&nbsp;
          <label class="font-weight-bold">Endereço:&nbsp;&nbsp;&nbsp;</label>{{manifesto.endereco?.enderecoFormatadoCurto}}<br>
          <label class="font-weight-bold">Tido da Obra:&nbsp;&nbsp;&nbsp;</label>{{manifesto.obra}}&nbsp;&nbsp;&nbsp;
          <label class="font-weight-bold">Etapa:&nbsp;&nbsp;&nbsp;</label>{{manifesto.etapa}}&nbsp;&nbsp;&nbsp;
          <label class="font-weight-bold">Materiais:&nbsp;&nbsp;&nbsp;</label>{{manifesto.produto}}
          <button title="Adicionar Manifesto" class="btn btn-outline-secondary mx-2"
          (click)="mostrarDialogParaNovoModelo(manifesto.id)" *ngIf="roleEntidadeMtrcc && adicionarOk"><i class="fa fa-plus px-2"></i>Adicionar Manifesto - Modelo {{i+1}}</button>
          &nbsp;&nbsp;&nbsp;
          <button title="Excluir Manifesto Modelo" class="btn btn-outline-secondary mx-2"
          (click)="desfazManifestoModelo(manifesto)" *ngIf="roleEntidadeMtrcc && adicionarOk"><i class="fa fa-times pr-2"></i>Excluir Manifesto - Modelo {{i+1}}</button>
        </font>
        <hr style="height:2px;border-width:0;color:gray;background-color:gray">
        </div>
      </div>
    </div> -->

  <!-- <td>
          <button class="btn btn-outline-secondary mx-2" (click)="mostrarDialogParaEditar(manifesto.id)"><i class="fa fa-info pr-2"></i>Detalhes</button>

          <button title="Anular" (click)="mostrarDialogEditParaAnulacaoRecusa(manifesto.id, 'anular')"  class="btn btn-outline-secondary mx-2"
            *ngIf="manifesto.isTransportador && manifesto.dataAnulacaoRecusa == null && manifesto.destinadorDataRecebimento == null"><i class="fa fa-times pr-2"></i>Anular</button>

          <button title="Receber" (click)="salvarDestinador(manifesto.id)" label="Receber" class="btn btn-outline-success mx-2"
            *ngIf="manifesto.isDestinador && manifesto.destinadorDataRecebimento == null && manifesto.dataAnulacaoRecusa == null">Receber</button>
          <button title="Recusar" (click)="mostrarDialogEditParaAnulacaoRecusa(manifesto.id, 'recusar')" label="Recusar" class="btn btn-outline-danger mx-2"
            *ngIf="manifesto.isDestinador && manifesto.destinadorDataRecebimento == null && manifesto.dataAnulacaoRecusa == null">Recusar</button>

          <button title="Receber" (click)="salvarDestinador(manifesto.id)" label="Receber" class="btn btn-outline-success mx-2"
            *ngIf="manifesto.destinadorCpf !== null  && manifesto.destinadorDataRecebimento == null && manifesto.dataAnulacaoRecusa == null && !variaveisGlobais.roleEntidade">Receber</button>
          <button title="Recusar" (click)="mostrarDialogEditParaAnulacaoRecusa(manifesto.id, 'recusar')" label="Recusar" class="btn btn-outline-danger mx-2"
            *ngIf="manifesto.destinadorCpf !== null && manifesto.destinadorDataRecebimento == null && manifesto.dataAnulacaoRecusa == null && !variaveisGlobais.roleEntidade">Recusar</button>


          <span *ngIf="manifesto.destinadorDataRecebimento !== null" class="text-success">&nbsp;&nbsp;&nbsp;Recebido</span>
          <span *ngIf="manifesto.recusado" class="text-danger">&nbsp;&nbsp;&nbsp;Recusado</span>
          <span *ngIf="manifesto.anulado" class="text-secondary">&nbsp;&nbsp;&nbsp;Anulado</span>
        </td> -->

  <!-- </p-tabView> -->

  <!-- <p-footer>
    <div class="p-col" style="text-align: right">
        <button type="button" (click)="salvarJustificativa(manifestoEdit)" class="btn btn-primary" style="margin-left: 5px">Salvar</button>
      </div>
    </p-footer> -->
</p-dialog>




<!-- Dialog JUSTIFICAR ANULAÇÃO DE MANIFESTO -->
<p-dialog header="Anular/Recusar Manifesto" [(visible)]="manifestoEdit" [responsive]="true" showEffect="fade"
  [modal]="true" resizable="true" [width]="800" [height]="600" *ngIf="justificarAnulacaoRecusa">
  <p-tabView>
    <div class="row">
      <div class="col-12 col-sm-6 pb-0">
        <label class="font-weight-bold">Número Manifesto</label>
        <p class="text-secondary">
          {{manifestoEdit.numeroManifesto}}
        </p>
      </div>
      <div class="col-12 col-sm-6">
        <label class="font-weight-bold">Gerador</label>
        <p class="text-secondary">
          {{manifestoEdit.geradorNome}}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 pb-0">
        <label class="font-weight-bold">Transportador</label>
        <p class="text-secondary">
          {{manifestoEdit.transportadorNome}}
        </p>
      </div>
      <div class="col-12 col-sm-6 pb-0">
        <label class="font-weight-bold">Destino Final</label>
        <p class="text-secondary">
          {{manifestoEdit.destinadorNome}}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-10">
        <label class="font-weight-bold">Justificativa de Anulação/Recusa do Manifesto</label>
        <textarea pInputTextarea id="descricao" [(ngModel)]="manifestoEdit.justificativaAnulacaoRecusa" [rows]=5
          [cols]="90"></textarea>
      </div>
    </div>
  </p-tabView>

  <p-footer>
    <div class="row text-right">
      <div class="col-12">
        <button type="button" (click)="salvarJustificativa(manifestoEdit)" class="btn btn-primary"
          style="margin-right: 12px">Salvar</button>
      </div>
    </div>

  </p-footer>
</p-dialog>


<!-- Dialog TOTALIZADOR POR ENTIDADE -->
<form [formGroup]="manifestoForm" (ngSubmit)="onSubmit()">
  <p-dialog header="Totalizador" [(visible)]="manifestoEdit" [responsive]="true" [modal]="true"
    [contentStyle]="{'width': '800px', 'height': '500px'}" styleClass="active" *ngIf="isTotalizador">
    <div class="p-grid">
      <div class="p-col-6">
        <div class="p-grid">
          <div class="p-col">
            <label>Número Manifesto</label>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col">
            {{manifestoEdit.numeroManifesto}}
          </div>
        </div>
      </div>
      <div class="p-col-6">
        <div class="p-grid">
          <div class="p-col">
            <label>Gerador</label>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col">
            {{manifestoEdit.geradorNome}}
          </div>
        </div>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-6">
        <div class="p-grid">
          <div class="p-col">
            <label>Transportador</label>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col">
            {{manifestoEdit.transportadorNome}}
          </div>
        </div>
      </div>
      <div class="p-col-6">
        <div class="p-grid">
          <div class="p-col">
            <label>Destino Final</label>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col">
            {{manifestoEdit.destinadorNome}}
          </div>
        </div>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-4">
        <div class="p-grid">
          <div class="p-col">
            <label>Período</label>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col">
            <p-calendar formControlName="totalInicio" dateFormat="dd/mm/yy" [locale]="languageCalendar"
              [showIcon]="true"></p-calendar>
          </div>
        </div>
      </div>
      <div class="p-col-4">
        <div class="p-grid">
          <div class="p-col">
            <label>&nbsp;</label>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col">
            <p-calendar formControlName="totalFim" dateFormat="dd/mm/yy" [locale]="languageCalendar" [showIcon]="true">
            </p-calendar>
          </div>
        </div>
      </div>
      <div class="p-col-2">
        <div class="p-grid">
          <div class="p-col">
            <label>&nbsp;</label>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col">
            <button type="button" pButton class="ui-button-secondary" (click)="totalizaEntidade(manifestoEdit.id)"
              label="Pesquisa"></button>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>


</form>




<!-- <p>
    Form Value: {{ manifestoForm.value | json }}
  </p> -->


<!-- <p>
    Form Status: {{ manifestoForm.status }}
  </p>
  <p>
    {{diagnostic}}
</p> -->

<!-- Dialog INCLUIR MATERIAIS PREDOMINANTES -->
<!-- <p-dialog header="Material Predominante" [(visible)]="tipoMaterial" [responsive]="true" showEffect="fade" [modal]="true" [width]="800" [height]="300" *ngIf="mostrarDialogMaterial">
  <p-tabView>
    <div class="p-grid">
      <div class="p-col-8">
        <div class="p-grid">
          <div class="p-col">
            <label>Tipo *</label>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col">
            <input pInputText [(ngModel)]="tipoMaterial.material"/>
          </div>
        </div>
      </div>
      <div class="p-col-4">
        <div class="p-grid">
          <div class="p-col">
            <label>Classificação *</label>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col">
            <p-dropdown [options]="classificacoes" [(ngModel)]="selClassificacao" placeholder="Selecione"></p-dropdown>
          </div>
        </div>
      </div>
    </div>
  </p-tabView>
  <p-footer>
    <div class="p-col" style="text-align: right">
      <button pButton type="button" label="Salvar" icon="pi pi-check" (click)="salvarMaterial()" class="ui-button-primary" style="margin-left: 5px"></button>
    </div>
  </p-footer>
</p-dialog> -->

<!-- <script type="text/javascript">
  document.getElementById("idGeradorCnpj").focus();
</script> -->