import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var NotificacoesService = /** @class */ (function () {
    function NotificacoesService() {
        this.notificacoesSubject = new Subject();
    }
    NotificacoesService.prototype.notificar = function (severity, summary, detail) {
        console.log('notificar>>>>>>>', severity, summary, detail);
        this.notificacoesSubject.next({ severity: severity, summary: summary, detail: detail });
    };
    NotificacoesService.prototype.notificarInfo = function (mensagem, detalhe) {
        this.notificar('info', mensagem, detalhe);
    };
    NotificacoesService.prototype.notificarSucesso = function (mensagem, detalhe) {
        this.notificar('success', mensagem, detalhe);
    };
    NotificacoesService.prototype.notificarAviso = function (mensagem, detalhe) {
        this.notificar('warn', mensagem, detalhe);
    };
    NotificacoesService.prototype.notificarErro = function (mensagem, detalhe) {
        console.log('notificarErro>>>>>>>', mensagem, detalhe);
        this.notificar('error', mensagem, detalhe);
    };
    NotificacoesService.ngInjectableDef = i0.defineInjectable({ factory: function NotificacoesService_Factory() { return new NotificacoesService(); }, token: NotificacoesService, providedIn: "root" });
    return NotificacoesService;
}());
export { NotificacoesService };
