import { SecretariaPmpa } from './secretariaPmpa.model';
import { SecretariaPmpaInter } from './secretariaPmpa.model';

export class PessoaFisica {
    id?: number;
    email ? = null;
    telefone ? = null;
    celular ? = null;
    nomeCompleto ? = null;
    cpf ? = null;
    secretariaPmpa ?: SecretariaPmpa;
    rLegal ? = false;
    rusrLegal ? = false;
    rSecretario ? = false;
    rtElaboracao ? = false;
    rtExecucao ? = false;
    ativo ? = false;
    dataAtivoInicio ? = null;
    dataAtivoFim ? = null;
    idEntidade ? = null;
    idSecretaria ? = null;
  }

export class PessoaFisicaSecretaria {
  id?: number;
  email ? = null;
  telefone ? = null;
  celular ? = null;
  nomeCompleto ? = null;
  cpf ? = null;
  secretariaPmpa ?: SecretariaPmpaInter;
  rLegal ? = false;
  rusrLegal ? = false;
  rSecretario ? = false;
  rtElaboracao ? = false;
  rtExecucao ? = false;
  ativo ? = false;
  dataAtivoInicio ? = null;
  dataAtivoFim ? = null;
  idEntidade ? = null;
  idSecretaria ? = null;
  sigla ? = null;
}


