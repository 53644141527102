import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ManifestoService = /** @class */ (function () {
    function ManifestoService(http) {
        this.http = http;
    }
    ManifestoService.prototype.getManifesto = function (id) {
        return this.http.get(environment.apiUrl + '/manifestos/');
    };
    ManifestoService.prototype.getManifestos = function () {
        return this.http.get(environment.apiUrl + '/manifestos/listall');
    };
    ManifestoService.prototype.getManifestosDasEmpresas = function (pessoaFisica, situacaoPesquisa, dataInicialPesquisa, dataFinalPesquisa, obraPesquisa, produtoPesquisa, secretariaPesquisa, nomeObraPesquisa, codLogradouroPesquisa, numLogradouroPesquisa, etapaPesquisa, manifestoPesquisa) {
        console.log('getManifestosDasEmpresas>>>>>>dataInicial>>>>>>', dataInicialPesquisa);
        return this.http.get(environment.apiUrl + '/manifestos/lista-entidade/' + pessoaFisica.id
            + '?situacaoPesquisa=' + situacaoPesquisa + '&dataInicialPesquisa=' + dataInicialPesquisa
            + '&dataFinalPesquisa=' + dataFinalPesquisa + '&obraPesquisa=' + obraPesquisa
            + '&produtoPesquisa=' + produtoPesquisa + '&secretariaPesquisa=' + secretariaPesquisa
            + '&nomeObraPesquisa=' + nomeObraPesquisa + '&codLogradouroPesquisa=' + codLogradouroPesquisa
            + '&numLogradouroPesquisa=' + numLogradouroPesquisa + '&etapaPesquisa=' + etapaPesquisa
            + '&manifestoPesquisa=' + manifestoPesquisa);
    };
    ManifestoService.prototype.getManifestosDaEmpresa = function (idEntidade, situacaoPesquisa, dataInicialPesquisa, dataFinalPesquisa, obraPesquisa, produtoPesquisa, secretariaPesquisa, nomeObraPesquisa, codLogradouroPesquisa, numLogradouroPesquisa, etapaPesquisa, manifestoPesquisa) {
        console.log('getManifestosDaEmpresa>>>>>>dataInicial>>>>>>', dataInicialPesquisa);
        return this.http.get(environment.apiUrl + '/manifestos/lista-entidade/' + idEntidade
            + '?situacaoPesquisa=' + situacaoPesquisa + '&dataInicialPesquisa=' + dataInicialPesquisa
            + '&dataFinalPesquisa=' + dataFinalPesquisa + '&obraPesquisa=' + obraPesquisa
            + '&produtoPesquisa=' + produtoPesquisa + '&secretariaPesquisa=' + secretariaPesquisa
            + '&nomeObraPesquisa=' + nomeObraPesquisa + '&codLogradouroPesquisa=' + codLogradouroPesquisa
            + '&numLogradouroPesquisa=' + numLogradouroPesquisa + '&etapaPesquisa=' + etapaPesquisa
            + '&manifestoPesquisa=' + manifestoPesquisa);
    };
    // getManifestosModelos(idPessoaFisica, idEntidade): Observable<Manifesto[]> {
    //       console.log('getManifestosModelos>>>>>>>>>>>>', idPessoaFisica, '---', idEntidade);
    //       return this.http.get<Manifesto[]>(environment.apiUrl + '/manifestos/manifestosModelos/' + idEntidade
    //       + idPessoaFisica);
    //   }
    ManifestoService.prototype.getManifestosSmams = function (situacaoPesquisa, dataInicialPesquisa, dataFinalPesquisa, obraPesquisa, produtoPesquisa, secretariaPesquisa, nomeObraPesquisa, codLogradouroPesquisa, numLogradouroPesquisa, etapaPesquisa, manifestoPesquisa) {
        console.log('getManifestosSmams>>>>>>>>>>', environment.apiUrl + '/manifestos/listaSmams/' + situacaoPesquisa);
        return this.http.get(environment.apiUrl + '/manifestos/listaSmams?situacaoPesquisa=' + situacaoPesquisa
            + '&dataInicialPesquisa=' + dataInicialPesquisa + '&dataFinalPesquisa=' + dataFinalPesquisa + '&obraPesquisa='
            + obraPesquisa + '&produtoPesquisa=' + produtoPesquisa + '&secretariaPesquisa=' + secretariaPesquisa
            + '&nomeObraPesquisa=' + nomeObraPesquisa + '&codLogradouroPesquisa=' + codLogradouroPesquisa
            + '&numLogradouroPesquisa=' + numLogradouroPesquisa + '&etapaPesquisa=' + etapaPesquisa
            + '&manifestoPesquisa=' + manifestoPesquisa);
    };
    ManifestoService.prototype.getManifestosResultados = function (idPessoaFisica, dataInicial, dataFinal) {
        // console.log('getManifestosResultados>>>>>>>>>>>', dataInicial);
        return this.http.get(environment.apiUrl + '/manifestos/resultados/'
            + idPessoaFisica + '/' + dataInicial + '/' + dataFinal);
    };
    ManifestoService.prototype.getManifestosResultadosCnpj = function (idEntidade, dataInicial, dataFinal) {
        // console.log('getManifestosResultadosCnpj>>>>>>>>>>>', idEntidade);
        return this.http.get(environment.apiUrl + '/manifestos/resultadosCnpj/'
            + idEntidade + '/' + dataInicial + '/' + dataFinal);
    };
    ManifestoService.prototype.getProdutosManifesto = function (idManifesto) {
        console.log('getProdutosManifesto>>>>>>>>>>>', idManifesto);
        return this.http.get(environment.apiUrl + '/manifestos/produtoManifesto/' + idManifesto);
    };
    ManifestoService.prototype.getProdutosManifestos = function (manifestosId) {
        console.log('getProdutosManifestos Service >>>>>>', manifestosId);
        return this.http.get(environment.apiUrl + '/manifestos/produtoManifestoLista/' + manifestosId);
    };
    ManifestoService.prototype.totalEntidadePeriodo = function (idEntidade) {
        return this.http.get(environment.apiUrl + '/manifestos/totalEntidadePeriodo/' + idEntidade);
    };
    ManifestoService.prototype.criarManifesto = function (manifesto) {
        console.log('>>>>>>>>>>>>>>>>>>>>>>>....................CriarManifesto Service >>>>>>', manifesto);
        // console.log('CriarManifesto Service >>>>>>', manifesto);
        return this.http.post(environment.apiUrl + '/manifestos/', manifesto);
    };
    ManifestoService.prototype.editarManifesto = function (manifesto) {
        return this.http.put(environment.apiUrl + '/manifestos/', manifesto);
    };
    ManifestoService.prototype.anularManifesto = function (manifesto) {
        return this.http.put(environment.apiUrl + '/manifestos/anulacao', manifesto);
    };
    ManifestoService.prototype.desfazManifestoModelo = function (manifesto) {
        return this.http.put(environment.apiUrl + '/manifestos/desfazModelo', manifesto);
    };
    ManifestoService.prototype.excluirManifesto = function (manifesto) {
        return this.http.delete(environment.apiUrl + '/manifestos/' + manifesto.id);
    };
    // obtemObras(): Observable<KeyPair[]> {
    //   const url = environment.apiUrl + '/' + this.globals.tipoAcesso + '/app/obras';
    //   return this.http.get<KeyPair[]>(url);
    // }
    // obtemEtapas(): Observable<KeyPair[]> {
    //   const url = environment.apiUrl + '/' + this.globals.tipoAcesso + '/app/etapas';
    //   return this.http.get<KeyPair[]>(url);
    // }
    ManifestoService.prototype.enviaEmailManifesto = function (id, pdfManifesto) {
        var rootUrl = environment.apiUrl + '/manifestos/' + id + '/emailManifesto';
        // const headers = new Headers({
        //   'Content-Type': 'application/json',
        //   'Accept': 'application/json'
        // });
        console.log('enviaEmailManifesto>>>>>>>', rootUrl);
        console.log('enviaEmailManifesto......>>>>>>>', pdfManifesto);
        return this.http.post(rootUrl, pdfManifesto, { reportProgress: true });
    };
    ManifestoService.prototype.correcoesExtras = function () {
        var rootUrl = environment.apiUrl + '/manifestos/correcoesExtras';
        return this.http.get(rootUrl);
    };
    ManifestoService.ngInjectableDef = i0.defineInjectable({ factory: function ManifestoService_Factory() { return new ManifestoService(i0.inject(i1.HttpClient)); }, token: ManifestoService, providedIn: "root" });
    return ManifestoService;
}());
export { ManifestoService };
