var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { SelectItem } from 'primeng/api';
import { EntidadeService } from '../../entidade/entidade.service';
import { EnderecoService } from '../../services/endereco.service';
import { PessoaFisicaService } from '../../services/pessoaFisica.service';
import { SpinnerService } from '../../services/spinner.service';
import { NotificacoesService } from '../../services/notificacoes.service';
import { AnexoService } from '../../services/anexo.service';
import { ProdutoService } from '../../produto/produto.service';
import { DmluService } from '../dmlu.service';
import { Globals } from 'src/app/globals';
import { Contrato } from 'src/app/@shared/models/contrato.model';
import { Anexo } from 'src/app/@shared/models/anexo.model';
import { ptBr } from 'src/app/@shared/utils/calendar';
import { ContratoVO } from 'src/app/@shared/models/contratoVO.model';
var ContratoComponent = /** @class */ (function () {
    function ContratoComponent(router, route, 
    // private fb: FormBuilder,
    keycloakService, spinnerService, notificacoesService, dmluService, entidadeService, enderecoService, pessoaFisicaService, produtoService, anexoService, globals) {
        this.router = router;
        this.route = route;
        this.keycloakService = keycloakService;
        this.spinnerService = spinnerService;
        this.notificacoesService = notificacoesService;
        this.dmluService = dmluService;
        this.entidadeService = entidadeService;
        this.enderecoService = enderecoService;
        this.pessoaFisicaService = pessoaFisicaService;
        this.produtoService = produtoService;
        this.anexoService = anexoService;
        this.globals = globals;
        this.submitted = false;
        this.idEntidade = null;
        this.contratoId = null;
        this.contratos = [];
        this.temContrato = false;
        this.dataInicial = new Date('01/01/1500');
        this.dataFinal = new Date('01/01/1500');
        this.abaEmpresa = true;
        this.abaAssinaturaSei = false;
        this.abaResiduo = false;
        this.abaInfos = false;
        this.abaIsento = false;
        this.abaFinalizar = false;
        this.abaTramitacao = false;
        this.abaVigente = false;
        this.codigoDmlu = false;
        this.numeroContrato = false;
        this.documentos = '';
        this.sistemaSEI = 'sistemaSEI';
        this.selectedSituacao = '';
        this.selectedAnaliseIsencao = '';
        this.selectedIsencaoPrazo = '';
        this.selectedAnaliseDocumental = '';
        this.selectedSEI = 'liberada';
        this.horaVistoria = null;
        this.adimplenteInadimplente = 'adimplente';
        // Novo/edit
        this.insertMode = false;
        this.mostrarDialogEdit = false;
        this.contratoAnaliseSAC = false;
        this.contratoInfDivida = false;
        this.contratoAnaliseSMCA = false;
        this.contratoDiretorGeral = false;
        this.contratoInfComplementar = false;
        this.contratoAnaliseNCC = false;
        this.contratoNccInfComplementar = false;
        this.contratoVistoria = false;
        this.contratoAssinaturaEmpresa = false;
        this.contratoAssinaturaPmpa = false;
        // contratoElaboracao: Boolean = false;
        this.contratoPendenteUsuario = false;
        this.contratoVigente = false;
        this.tituloDialogEdit = 'Contrato com o DMLU';
        // Pessoa Física
        this.responsaveisEntidade = [];
        this.responsavel = null;
        this.responsavelSel = null;
        // Endereço
        this.enderecosEntidade = [];
        this.endereco = null;
        this.enderecoSel = null;
        this.residuos = [];
        this.residuosContrato = [];
        this.residuosSelTb = [];
        this.residuosSelCp = [];
        this.residuosSelTg = [];
        this.residuosSelTgSt = [];
        this.residuosTransbordo = [];
        this.residuosCompostagem = [];
        this.residuosTriagem = [];
        this.alteracaoResiduos = false;
        this.novoTipoResiduo = false;
        this.residuoNovo = '';
        this.isCompleto = true;
        this.mensagemValidacao = '';
        this.anexoSel = '';
        // novoAnexoDescricao: string;
        this.tiposAnexos = [
            { label: 'CPF/RG', value: 'CPF/RG' },
            { label: 'Contrato Social', value: 'Contrato Social' },
            { label: 'Procuração', value: 'Procuração' }
        ];
        this.tipoAnexo = '';
        this.cpfRg = '';
        this.isCpfRg = false;
        this.anexoCpgRg = null;
        this.contratoSocialEstatuto = '';
        this.isContratoSocialEstatuto = false;
        this.anexoContratoSocialEstatuto = null;
        this.procuracao = '';
        this.isProcuracao = false;
        this.anexoProcuracao = null;
        this.relDivida = '';
        this.isRelDivida = false;
        this.anexoRelDivida = null;
        this.boletoPago = '';
        this.isBoletoPago = false;
        this.anexoBoletoPago = null;
        this.activeRoute = this.router.url;
        this.variaveisGlobais = globals;
        this.idEntidade = route.snapshot.params['id'];
        console.log('Construtor ContratoComponent >>>>>>>>>>>', this.idEntidade);
    }
    ContratoComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log('ngOnInit---------ContratoComponent--------------->>>', this.globals);
        this.empresaIsentaTarifa = false;
        this.variaveisGlobais = this.globals;
        this.variaveisGlobais.menuRaiz = this.router.url.split('/')[1];
        this.entidade = this.variaveisGlobais.entidade;
        if (this.globals.entidade == null) {
            this.globals.recuperaKeycloak().subscribe(function (user) {
                if (_this.globals.roleEntidade) {
                    if (_this.variaveisGlobais.pessoaFisica === null) {
                        _this.globals.recuperaEntidadePessoaFisica().subscribe(function (entidade) {
                            _this.variaveisGlobais = _this.globals;
                            console.log('--------------------------->>>>>00');
                            _this.inicializaOnInit();
                        });
                    }
                    else {
                        _this.inicializaOnInit();
                    }
                }
                else {
                    console.log('--------------------------->>>>>11');
                    _this.inicializaOnInit();
                }
            });
        }
        else {
            console.log('--------------------------->>>>>22');
            this.inicializaOnInit();
        }
    };
    ContratoComponent.prototype.inicializaOnInit = function () {
        var _this = this;
        this.contrato = new Contrato();
        this.languageCalendar = ptBr;
        // this.mostrarDialogEdit = true;
        if (this.entidade !== null) {
            this.entidadeService.getEntidadePorId(this.idEntidade).subscribe(function (entidade) {
                _this.entidade = entidade;
                _this.spinnerService.ocultar();
                console.log('inicializaOnInit ..... SMAMS/DMLU.....>>>>>>>', entidade);
                if (entidade != null) {
                    _this.entidade = entidade;
                    // this.buscaEnderecoEntidade(entidade, null);
                    if (_this.contrato !== null) {
                        console.log('inicializaOnInit ..... buscaContratos.....>>>>>>>', _this.contrato);
                        _this.buscaContratos();
                    }
                    _this.buscaPessoasFisicas(entidade);
                    _this.buscaEnderecos(entidade);
                    _this.recuperaAnexosEntidade(entidade.id);
                }
                _this.nomeUsuario = _this.variaveisGlobais.userFullName;
                // this.pessoaFisica = this.variaveisGlobais.pessoaFisica;
                _this.variaveisGlobais.entidade = _this.entidade;
                // this.variaveisGlobais.enderecosEntidade = this.endsEntidadeRepresentada;
            }, function (error) {
                console.log(error);
                console.log(error.error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
        else {
            this.buscaPessoasFisicas(this.entidade);
        }
        // Carrega valor inicial para projetos
        if (this.variaveisGlobais.roleEntidade) {
        }
        else {
        }
        // Carrega os residuos
        this.produtoService.getProdutos(this.variaveisGlobais.menuRaiz, this.variaveisGlobais.roleEntidade).subscribe(function (residuos) {
            console.log('Cadastro Inicializa Resíduos>>>>>>>>>>>>>>', residuos);
            _this.spinnerService.ocultar();
            _this.residuos = residuos;
            _this.residuosTransbordo = [];
            _this.residuosCompostagem = [];
            _this.residuosTriagem = [];
            residuos.forEach(function (residuo) {
                if (residuo.gdrTransbordo) {
                    _this.residuosTransbordo.push(residuo);
                }
                if (residuo.gdrCompostagem) {
                    _this.residuosCompostagem.push(residuo);
                }
                if (residuo.gdrTriagem) {
                    _this.residuosTriagem.push(residuo);
                }
            });
            _this.residuosTransbordo.sort(function (a, b) {
                if (a.nome > b.nome) {
                    return 1;
                }
                if (a.nome < b.nome) {
                    return -1;
                }
                return 0;
            });
            _this.residuosCompostagem.sort(function (a, b) {
                if (a.nome > b.nome) {
                    return 1;
                }
                if (a.nome < b.nome) {
                    return -1;
                }
                return 0;
            });
            _this.residuosTriagem.sort(function (a, b) {
                if (a.nome > b.nome) {
                    return 1;
                }
                if (a.nome < b.nome) {
                    return -1;
                }
                return 0;
            });
        }, function (error) {
            _this.notificacoesService.notificarErro('Erro ao obter resíduos', error.message);
        });
        //Busca valor da UFM
        if (this.variaveisGlobais.roleEntidade) {
            this.dmluService.getValorUFM().subscribe(function (valorUFM) {
                // console.log('inicializaOnInit ..... getContratoIdEntidade.....>>>>>>>', contratos);
                _this.valorUFM = valorUFM;
            }, function (error) {
                console.log(error);
                console.log(error.error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    ContratoComponent.prototype.buscaContratos = function () {
        var _this = this;
        this.dmluService.getContratosIdEntidade(this.idEntidade).subscribe(function (contratos) {
            console.log('inicializaOnInit ..... getContratoIdEntidade.....>>>>>>>', contratos);
            _this.contratos = contratos;
            _this.spinnerService.ocultar();
            contratos.forEach(function (contrato) {
                contrato.idContratante = _this.entidade.id;
            });
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ContratoComponent.prototype.checkActive = function (route) {
        return route === this.activeRoute;
    };
    ContratoComponent.prototype.onSubmit = function () {
        // this.ngOnInit();
    };
    ContratoComponent.prototype.mostrarDialogParaNovo = function () {
        var _this = this;
        //Verifica se já existe contrato aberto para a empresa
        this.dmluService.getEmpresaJaTemContrato(this.entidade.id).subscribe(function (empresaJaTemContrato) {
            if (empresaJaTemContrato) {
                _this.notificacoesService.notificarAviso('Empresa não pode iniciar novo contrato pois já possui contrato vigente ou em tramitação', null);
                return;
            }
            _this.contrato = new Contrato();
            _this.mostrarDialogEdit = true;
            _this.insertMode = true;
            _this.tituloDialogEdit = 'Novo Contrato com o DMLU';
            _this.abaEmpresa = true;
            _this.abaAssinaturaSei = false;
            _this.abaResiduo = false;
            _this.abaInfos = false;
            _this.abaFinalizar = false;
            _this.abaTramitacao = false;
            _this.abaVigente = false;
            _this.contrato.id = null;
            _this.residuosSelTb = [];
            _this.residuosSelCp = [];
            _this.residuosSelTg = [];
            _this.residuos.forEach(function (res) {
                _this.residuosTransbordo.forEach(function (item) {
                    item.selecionado = false;
                });
                _this.residuosCompostagem.forEach(function (item) {
                    item.selecionado = false;
                });
                _this.residuosTriagem.forEach(function (item) {
                    item.selecionado = false;
                });
            });
            // console.log('mostrarDialogParaNovo>>>>>>>>>>>>>>', this.contratoVO);
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ContratoComponent.prototype.mostrarDialogParaEditar = function (idContrato) {
        var _this = this;
        this.insertMode = false;
        this.abaAssinaturaSei = false;
        this.abaResiduo = false;
        this.abaInfos = false;
        this.abaFinalizar = false;
        this.abaTramitacao = false;
        this.abaVigente = false;
        var contratoParaEditar = this.contratos.find(function (contrato) {
            console.log('00mostrarDialogParaEditar>>>>>>>>>>>>>>', idContrato);
            return contrato.id === idContrato;
        });
        console.log('11mostrarDialogParaEditar>>>>>>>>>>>>>>', this.contratos);
        this.contratoVO = __assign({}, contratoParaEditar);
        this.dmluService.getContratoId(idContrato).subscribe(function (contratoEditado) {
            _this.contrato = contratoEditado;
            _this.enderecoSel = _this.contrato.enderecoContratante.id;
            _this.responsavelSel = _this.contrato.responsavelLegal.id;
            // if (this.contrato.obsPendencia !== null) {
            //   this.obsPendencia = this.contrato.obsPendencia.split('***');
            // }
            if (_this.contrato.dataInicial !== null) {
                _this.contrato.dataInicial = new Date(_this.contrato.dataInicial);
                _this.contrato.dataFinal = new Date(_this.contrato.dataFinal);
            }
            if (_this.entidade.codigoDmlu === null) {
                _this.codigoDmlu = false;
            }
            _this.buscaResiduosContrato(true);
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ContratoComponent.prototype.buscaResiduosContrato = function (isEdicaoContrato) {
        var _this = this;
        // Carrega os residuos do contrato
        this.produtoService.getProdutosContrato(this.contratoVO.id).subscribe(function (residuos) {
            console.log('Cadastro Inicializa Resíduos>>>>>>>>>>>>>>', residuos);
            _this.spinnerService.ocultar();
            // this.residuosContrato = residuos;
            // this.residuosContrato.sort(function (a, b) {
            //   if (a.nome > b.nome) {
            //     return 1;
            //   }
            //   if (a.nome < b.nome) {
            //     return -1;
            //   }
            //   return 0;
            // });
            // this.contrato.residuosDOList = this.residuosContrato;
            _this.residuosSelTb = [];
            _this.residuosSelCp = [];
            _this.residuosSelTg = [];
            residuos.forEach(function (res) {
                _this.residuosTransbordo.forEach(function (item) {
                    if (item.id === res.id) {
                        item.selecionado = res.selecionado;
                        _this.residuosSelTb.push(res);
                    }
                });
                _this.residuosCompostagem.forEach(function (item) {
                    if (item.id === res.id) {
                        item.selecionado = res.selecionado;
                        _this.residuosSelCp.push(res);
                    }
                });
                _this.residuosTriagem.forEach(function (item) {
                    if (item.id === res.id) {
                        item.selecionado = res.selecionado;
                        _this.residuosSelTg.push(res);
                    }
                });
            });
            // this.residuosSelTb = this.residuosTransbordo;
            // this.residuosSelCp = this.residuosCompostagem;
            // this.residuosSelTg = this.residuosTriagem;
            // console.log('-----resíduos Transbordo>>>', this.residuosSelTb);
            // console.log('-----resíduos Compostagem>>>', this.residuosSelCp);
            // console.log('-----resíduos Triagem>>>', this.residuosSelTg);
            if (isEdicaoContrato) {
                _this.mostrarDialogEdit = true;
                _this.abaEmpresa = true;
                _this.codigoDmlu = true;
            }
            else {
                _this.alteracaoResiduos = true;
            }
        }, function (error) {
            _this.notificacoesService.notificarErro('Erro ao obter resíduos', error.message);
        });
    };
    ContratoComponent.prototype.editarContratoTramitacao = function (idContrato, situacao) {
        var _this = this;
        this.horaVistoria = null;
        var contratoParaEditar = this.contratos.find(function (contrato) {
            return contrato.id === idContrato;
        });
        this.contratoVO = __assign({}, contratoParaEditar);
        this.dmluService.getContratoId(idContrato).subscribe(function (contratoEditado) {
            _this.contrato = contratoEditado;
            // console.log('cpntratoElaborado>>>>>>>>>>>>>>', this.contratoVO);
            _this.enderecoSel = _this.contrato.enderecoContratante.id;
            _this.responsavelSel = _this.contrato.responsavelLegal.id;
            if (situacao === 'analiseSAC') {
                _this.contratoAnaliseSAC = true;
            }
            if (situacao === 'infRegularidade') {
                _this.contratoInfDivida = true;
            }
            if (situacao === 'analiseSMCA') {
                _this.codigoDmlu = true;
                if (_this.entidade.codigoDmlu === null) {
                    _this.codigoDmlu = false;
                }
                _this.contratoAnaliseSMCA = true;
                if (_this.contrato.situacao === 'Cadastro encaminhado para análise técnica') {
                    _this.tituloDialogEdit = 'Análise Técnica da Empresa ' + _this.entidade.razaoSocial;
                }
                if (_this.contrato.situacao === 'Aguardando vistoria técnica') {
                    _this.tituloDialogEdit = 'Análise Técnica da Empresa ' + _this.entidade.razaoSocial +
                        ' - Andamento do contrato após a vistoria técnica.';
                }
                _this.selectedSituacao = 'Aprovado'; // Inicializa as opções na tela de edição
            }
            if (situacao === 'analiseIsencaoTarifa') {
                _this.contratoDiretorGeral = true;
                _this.tituloDialogEdit = 'Análise para isenção de tarifa da Empresa ' + _this.entidade.razaoSocial;
                _this.selectedAnaliseIsencao = 'Deferida'; // Inicializa as opções na tela de edição
            }
            if (situacao === 'complementar') {
                _this.contratoInfComplementar = true;
            }
            if (situacao === 'vistoria') {
                _this.contratoVistoria = true;
            }
            if (situacao === 'analiseNCC') {
                _this.numeroContrato = true;
                if (_this.contrato.numeroContrato === null) {
                    _this.numeroContrato = false;
                }
                _this.contratoAnaliseNCC = true;
                _this.tituloDialogEdit = 'Análise Documental da Empresa ' + _this.entidade.razaoSocial;
                _this.selectedAnaliseDocumental = 'Aprovada'; // Inicializa as opções na tela de edição
            }
            if (situacao === 'ncccomplementar') {
                _this.contratoNccInfComplementar = true;
            }
            if (situacao === 'assinaturaEmpresa') {
                _this.contratoAssinaturaEmpresa = true;
            }
            if (situacao === 'assinaturaPmpa') {
                _this.contratoAssinaturaPmpa = true;
            }
            _this.insertMode = false;
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ContratoComponent.prototype.buscaPessoasFisicas = function (entidade) {
        var _this = this;
        //    BUSCA PessoaFisica
        this.pessoaFisicaService.getPessoasFisicasResponsavelLegalEntidade(entidade.id).subscribe(function (pessoasFisicas) {
            _this.responsaveisEntidade = pessoasFisicas;
            if (pessoasFisicas.length > 0) {
                _this.responsavelSel = pessoasFisicas[0].id;
                _this.responsavel = pessoasFisicas[0];
            }
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ContratoComponent.prototype.buscaEnderecos = function (entidade) {
        var _this = this;
        //    BUSCA PessoaFisica
        this.enderecoService.getEnderecosPorEntidade(entidade.id).subscribe(function (enderecos) {
            _this.enderecosEntidade = enderecos;
            if (enderecos.length > 0) {
                _this.enderecoSel = enderecos[0].id;
                _this.endereco = enderecos[0];
            }
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ContratoComponent.prototype.responsavelSelecionado = function (pessoaFisica) {
        console.log('Responsável  respSelecionado>>>>>>>', pessoaFisica);
        var pfParaEditar = this.responsaveisEntidade.find(function (pf) {
            return pf.id === pessoaFisica;
        });
        this.responsavel = __assign({}, pfParaEditar);
        // this.contrato.responsavelLegal = pessoaFisica;
    };
    ContratoComponent.prototype.enderecoSelecionado = function (endereco) {
        console.log('Responsável  endereçoSelecionado>>>>>>>', endereco);
        var enderecoParaEditar = this.enderecosEntidade.find(function (end) {
            return end.id === endereco;
        });
        this.endereco = __assign({}, enderecoParaEditar);
        // this.contrato.enderecoContratante = endereco;
    };
    ContratoComponent.prototype.visualizaAnexo = function (tipoAnexo) {
        var _this = this;
        var anexo = new Anexo();
        if (tipoAnexo === 'cpfRg') {
            anexo = this.anexoCpgRg;
        }
        if (tipoAnexo === 'contratoEstatuto') {
            anexo = this.anexoContratoSocialEstatuto;
        }
        if (tipoAnexo === 'procuracao') {
            anexo = this.anexoProcuracao;
        }
        this.spinnerService.exibir();
        this.anexoService.getAnexo(anexo.id).subscribe(function (anexoDO) {
            _this.spinnerService.ocultar();
            console.log('visualizaAnexo Anexo>>>>>>>', anexoDO);
            var tipoArquivo = anexoDO.fileType.split('/');
            console.log('Tipo Arquivo>>>>>', tipoArquivo);
            if (tipoArquivo[0] === 'image') {
                var image = new Image();
                image.src = 'data:image/jpg;base64,' + anexoDO.file;
                var w = window.open('');
                w.document.write(image.outerHTML);
            }
            else if (tipoArquivo[0] === 'application') {
                if (tipoArquivo[1] === 'pdf') {
                    var byteCharacters = atob(anexoDO.file);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    var file = new Blob([byteArray], { type: 'application/pdf;base64' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }
                else if (tipoArquivo[1] === 'vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                    tipoArquivo[1] === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    //docx
                    // var reader = new FileReader();
                    var byteCharacters = atob(anexoDO.file);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    var file = new Blob([byteArray], { type: 'application/octet-stream' });
                    var downloadUrl = window.URL.createObjectURL(file);
                    var a = document.createElement('a');
                    a.href = downloadUrl;
                    a.download = anexoDO.fileName;
                    document.body.appendChild(a);
                    a.click();
                    URL.revokeObjectURL(downloadUrl);
                }
                else {
                    _this.notificacoesService.notificarSucesso('Arquivo não previsto para visualização!', '');
                }
            }
        }, function (error) {
            console.log('ERRO>>>>>>', error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
        // var FileSaver = require('file-saver');
        // var blob = new Blob(["Hello, world!"], { type: "text/plain;charset=utf-8" });
        // FileSaver.saveAs(blob, "hello world.txt");
        // console.log("File Saver>>>>>", FileSaver)
        // const anexoPdf = atob(anexo.value);
        // const b = new Blob([(<any>anexoPdf)], { type: 'application/pdf;base64' });
        // const fileURL = URL.createObjectURL(b);
        // window.open(fileURL);
    };
    ContratoComponent.prototype.recuperaAnexosEntidade = function (idEntidade) {
        var _this = this;
        this.anexoCpgRg = null;
        this.anexoContratoSocialEstatuto = null;
        this.anexoProcuracao = null;
        this.anexoRelDivida = null;
        this.anexoBoletoPago = null;
        this.anexos = [];
        this.anexoService.getAnexosPorEntidade(idEntidade, this.variaveisGlobais.menuRaiz).subscribe(function (anexos) {
            _this.anexos = anexos;
            _this.isCpfRg = false;
            _this.cpfRg = 'Por favor, anexar o CPF/RG do Responsável Legal!' +
                ' Para incluir o anexo, acesse a edição de Empresas, aba de ANEXOS.';
            _this.isContratoSocialEstatuto = false;
            _this.contratoSocialEstatuto = 'Por favor, anexar o Contrato Social ou Estatuto do Responsável Legal!' +
                ' Para incluir o anexo, acesse a edição de Empresas, aba de ANEXOS.';
            _this.isProcuracao = false;
            _this.procuracao = 'Empresa sem necessidade de procuração de representação!';
            _this.isBoletoPago = false;
            _this.isCpfRg = false;
            _this.isProcuracao = false;
            _this.isRelDivida = false;
            _this.anexos.forEach(function (anexo) {
                if (anexo.tipoAnexo === 'CPF/RG') {
                    _this.cpfRg = anexo.fileName;
                    _this.isCpfRg = true;
                    _this.anexoCpgRg = anexo;
                }
                if (anexo.tipoAnexo === 'Contrato Social') {
                    _this.contratoSocialEstatuto = anexo.fileName;
                    _this.isContratoSocialEstatuto = true;
                    _this.anexoContratoSocialEstatuto = anexo;
                }
                if (anexo.tipoAnexo === 'Procuração') {
                    _this.procuracao = anexo.fileName;
                    _this.isProcuracao = true;
                    _this.anexoProcuracao = anexo;
                }
                if (anexo.tipoAnexo === 'Relatório Dívida') {
                    _this.relDivida = anexo.fileName;
                    _this.isRelDivida = true;
                    _this.anexoRelDivida = anexo;
                }
                if (anexo.tipoAnexo === 'Boleto Pago') {
                    _this.boletoPago = anexo.fileName;
                    _this.isBoletoPago = true;
                    _this.anexoBoletoPago = anexo;
                }
                // console.log('Boletoooooo>>>>>>>>>anexos>>>>', anexo.tipoAnexo);
            });
        }, function (error) {
            console.log('ListaEntidade>>>>>>>>>erro>>>>', error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ContratoComponent.prototype.salvar = function () {
        // Cadastro encaminhado para análise técnica
        var _this = this;
        // Pendente com NCC - elaboração do contrato
        // Pendente com NCC - assinatura do SAF
        // Pendente com usuário - assinar contrato
        // Pendente com usuário - documentos e/ou SEI
        // Contrato vigente
        // Contrato vencido
        // Contrato pendente de vistoria
        // NCC - Núcleo de Contratos e Convênios
        // NCC/DMLU/PMPA - fone 3289-6841
        this.contrato.contratante = this.entidade;
        this.contrato.responsavelLegal = this.responsavel;
        // this.enderecoSel.entidadeDO = null;
        this.contrato.enderecoContratante = this.endereco;
        this.contrato.situacao = 'Cadastro encaminhado para verificação de regularidade no SAC';
        this.contrato.rgCPFAnexo = this.anexoCpgRg;
        this.contrato.contratoSocialEstatutoAnexo = this.anexoContratoSocialEstatuto;
        this.contrato.procuracaoRepresentacaoAnexo = this.anexoProcuracao;
        this.contrato.residuosDOList = [];
        // if (this.contrato.outrosResiduos === undefined) {
        //   this.contrato.outrosResiduos = null;
        // }
        // console.log('Contrato Salvar....>>>', this.contrato);
        this.residuosSelTb.forEach(function (residuo) {
            if (residuo.selecionado !== undefined) {
                if (residuo.selecionado) {
                    _this.contrato.residuosDOList.push(residuo);
                }
            }
        });
        this.residuosSelCp.forEach(function (residuo) {
            if (residuo.selecionado !== undefined) {
                if (residuo.selecionado) {
                    _this.contrato.residuosDOList.push(residuo);
                }
            }
        });
        this.residuosSelTg.forEach(function (residuo) {
            if (residuo.selecionado !== undefined) {
                if (residuo.selecionado) {
                    _this.contrato.residuosDOList.push(residuo);
                }
            }
        });
        if (this.empresaIsentaTarifa === true) {
            this.contrato.isencaoTarifa = true;
        }
        else {
            this.contrato.isencaoTarifa = false;
        }
        // console.log('Contrato Salvar....>>>', this.contrato);
        // Verifica se todos os campos foram preenchidos
        if (this.contrato.contratante.email == null) {
            this.notificacoesService.notificarAviso('Por favor informe o Email da empresa!', null);
            return;
        }
        if (this.contrato.enderecoContratante == null) {
            this.notificacoesService.notificarAviso('Por favor informe o Endereço da empresa!', null);
            return;
        }
        if (this.contrato.responsavelLegal == null) {
            this.notificacoesService.notificarAviso('Por favor informe o Responsável Legal da empresa!', null);
            return;
        }
        if (this.contrato.rgCPFAnexo == null) {
            this.notificacoesService.notificarAviso('Por favor incluir o CPF/RG do Responsável Legal!', null);
            return;
        }
        if (this.contrato.contratoSocialEstatutoAnexo == null) {
            this.notificacoesService.notificarAviso('Por favor incluir o Contrato Social ou Estatuto da Empresa!', null);
            return;
        }
        if (this.contrato.residuosDOList.length < 1) {
            this.notificacoesService.notificarAviso('Por favor informe os resíduos que serão descartados!', null);
            return;
        }
        this.dmluService.novoContrato(this.contrato).subscribe(function (contratoNovo) {
            console.log('salvado....................>>>>>>>>>>>>>>', contratoNovo);
            _this.buscaContratos();
            _this.mostrarDialogEdit = false;
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarSucesso('Adicionado com sucesso', null);
        }, function (error) {
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro('Erro ao adicionar ', error);
        });
    };
    ContratoComponent.prototype.atualizarResiduos = function () {
        var _this = this;
        this.spinnerService.exibir();
        this.dmluService.getContratoId(this.contrato.id).subscribe(function (contratoEditado) {
            _this.contrato = contratoEditado;
            _this.contrato.residuosDOList = [];
            _this.residuosSelTb.forEach(function (residuo) {
                if (residuo.selecionado !== undefined) {
                    if (residuo.selecionado) {
                        _this.contrato.residuosDOList.push(residuo);
                    }
                }
            });
            _this.residuosSelCp.forEach(function (residuo) {
                if (residuo.selecionado !== undefined) {
                    if (residuo.selecionado) {
                        _this.contrato.residuosDOList.push(residuo);
                    }
                }
            });
            _this.residuosSelTg.forEach(function (residuo) {
                if (residuo.selecionado !== undefined) {
                    if (residuo.selecionado) {
                        _this.contrato.residuosDOList.push(residuo);
                    }
                }
            });
            //Verifica se todos os campos foram preenchidos
            if (_this.contrato.residuosDOList.length < 1) {
                _this.notificacoesService.notificarAviso('Por favor informe os resíduos que serão descartados!', null);
                return;
            }
            console.log('Contrato Salvar....>>>', _this.contrato);
            _this.dmluService.atualizarResiduos(_this.contrato).subscribe(function (contratoEditado) {
                _this.buscaContratos();
                _this.alteracaoResiduos = false;
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarSucesso('Atualizado com sucesso', null);
            }, function (error) {
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro('Erro ao atualizar ', error);
            });
        }, function (error) {
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro('Erro ao recuperar contrato ', error);
        });
    };
    // contratoParaAtualizar(situacao, usuario) {
    //   this.spinnerService.exibir();
    //   this.dmluService.getContratoId(this.contrato.id).subscribe(contratoEditado => {
    //     this.contrato = contratoEditado;
    //     if (usuario == 'PMPA') {
    //       this.contratoAndamentoPmpa(situacao);
    //     } else {
    //       this.contratoAndamentoEmpresa(situacao);
    //     }
    //   },
    //   error => {
    //     this.spinnerService.ocultar();
    //     this.notificacoesService.notificarErro('Erro ao recuperar contrato ', error);
    //   });
    // }
    ContratoComponent.prototype.contratoAndamentoPmpa = function (situacao) {
        var _this = this;
        if (situacao === 'contratoAnaliseSAC') {
            if (this.adimplenteInadimplente === 'adimplente') {
                this.contrato.situacao = 'Cadastro encaminhado para análise técnica';
            }
            else {
                this.contrato.situacao = 'Cadastro aguardando regularização da dívida';
            }
            this.empresaAdimplenteInadimplente();
            this.atualizaContrato(this.contrato);
            this.contratoAnaliseSAC = false;
        }
        if (situacao === 'contratoAnaliseSMCA') {
            if (this.selectedSituacao === 'Aprovado') {
                if (this.entidade.codigoDmlu === null) {
                    this.notificacoesService.notificarAviso('Por favor informar o Código da balança!', null);
                    return;
                }
                else {
                    if (this.contrato.isencaoTarifa) {
                        this.contrato.situacao = 'Encaminhado para o Diretor Geral';
                    }
                    else {
                        this.contrato.situacao = 'Encaminhado para análise documental';
                    }
                    this.entidadeService.editarEntidade(this.entidade).subscribe(function (entidade) {
                        _this.entidade = entidade;
                        _this.contrato.contratante = entidade;
                        _this.atualizaContrato(_this.contrato);
                    }, function (error) {
                        console.log(error);
                        _this.spinnerService.ocultar();
                        _this.notificacoesService.notificarErro(error.error, null);
                    });
                }
            }
            if (this.selectedSituacao === 'Complemento') {
                if (this.contrato.obsSmcaComplementar === null || this.contrato.obsSmcaComplementar === '') {
                    this.notificacoesService.notificarAviso('Por favor informar as orientações sobre os documentos complementares necessários!', null);
                    return;
                }
                else {
                    this.contrato.situacao = 'Aguardando avaliação técnica complementar';
                    this.atualizaContrato(this.contrato);
                }
            }
            if (this.selectedSituacao === 'Vistoria') {
                this.contrato.dataVistoria = null;
                if (this.dataVistoria !== undefined) {
                    this.contrato.dataVistoria = this.dataVistoria;
                }
                if (this.contrato.obsSmcaVistoria === null || this.contrato.obsSmcaVistoria === '') {
                    this.notificacoesService.notificarAviso('Por favor informar as orientações necessárias à vistoria!', null);
                    return;
                }
                if (this.contrato.dataVistoria === null) {
                    this.notificacoesService.notificarAviso('Por favor informar a data/hora sugeridas para a realização da vistoria!', null);
                    return;
                }
                if (this.horaVistoria === null) {
                    this.notificacoesService.notificarAviso('Por favor informar a data/hora sugeridas para a realização da vistoria!', null);
                    return;
                }
                this.contrato.situacao = 'Aguardando vistoria técnica';
                this.contrato.dataVistoria.setHours(new Date(this.horaVistoria).getHours());
                this.contrato.dataVistoria.setMinutes(new Date(this.horaVistoria).getMinutes());
                this.atualizaContrato(this.contrato);
            }
            if (this.selectedSituacao === 'Reprovado') {
                this.contrato.situacao = 'Cadastro técnico reprovado';
                this.atualizaContrato(this.contrato);
            }
            this.contratoAnaliseSMCA = false;
        }
        if (situacao === 'contratoVistoria') {
            if (this.entidade.codigoDmlu === null) {
                this.notificacoesService.notificarErro('Por favor informar o Código da balança!', null);
                return;
            }
            else {
                this.contrato.situacao = 'Encaminhado para análise documental';
                this.entidadeService.editarEntidade(this.entidade).subscribe(function (entidade) {
                    _this.entidade = entidade;
                    _this.contrato.contratante = entidade;
                    _this.atualizaContrato(_this.contrato);
                }, function (error) {
                    console.log(error);
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro(error.error, null);
                });
            }
            this.contratoAnaliseSMCA = false;
        }
        // console.log('----------contratoAndamentoPmpa>>>>>', situacao);
        if (situacao === 'contratoAnaliseIsencao') {
            // console.log('----------contratoAnaliseIsencao>>>>>', this.selectedAnaliseIsencao);
            // console.log('----------contratoAnaliseIsencao>>>>>', this.selectedIsencaoPrazo);
            if (this.selectedAnaliseIsencao === 'Deferida') {
                if (this.selectedIsencaoPrazo === undefined || this.selectedIsencaoPrazo === '') {
                    this.notificacoesService.notificarAviso('Por favor, informar a opção de isenção!', null);
                    return;
                }
                if (this.selectedIsencaoPrazo === 'isencaoDescargas') {
                    if (this.contrato.isencaoDescargas === null) {
                        this.notificacoesService.notificarAviso('Por favor, informar o Número de descargas!', null);
                        return;
                    }
                }
                if (this.selectedIsencaoPrazo === 'isencaoDeterminada') {
                    if (this.contrato.isencaoData === null) {
                        this.notificacoesService.notificarAviso('Por favor, informar a Data limite para a isenção!', null);
                        return;
                    }
                }
                if (this.selectedIsencaoPrazo === 'isencaoDescargas') {
                    this.contrato.isencaoData = null;
                }
                if (this.selectedIsencaoPrazo === 'isencaoDeterminada') {
                    this.contrato.isencaoDescargas = null;
                }
                this.contrato.situacao = 'Encaminhado para análise documental';
            }
            if (this.selectedAnaliseIsencao === 'Indeferida') {
                this.contrato.situacao = 'Solicitação de isenção indeferida';
            }
            // console.log('----------contratoAndamentoPmpa>>>>>', this.contrato);
            this.atualizaContrato(this.contrato);
            this.contratoDiretorGeral = false;
        }
        if (situacao === 'contratoAnaliseNCC') {
            if (this.selectedAnaliseDocumental === 'Aprovada') {
                if (this.contrato.numeroContrato === null) {
                    this.notificacoesService.notificarAviso('Por favor informar o Número do contrato!', null);
                    return;
                }
                if (this.contrato.anoContrato === null) {
                    this.notificacoesService.notificarAviso('Por favor informar o Ano do contrato!', null);
                    return;
                }
                // if (this.selectedSEI === 'liberada') {
                this.contrato.situacao = 'Contrato aguardando assinaturas digitais no SEI';
                // }
                // if (this.selectedSEI === 'naoLiberada') {
                //   this.contrato.situacao = 'Aguarda liberação de assinatura eletrônica do Gerador no sistema SEI';
                // }
            }
            if (this.selectedAnaliseDocumental === 'Complemento') {
                if (this.contrato.obsNccComplementar === null || this.contrato.obsNccComplementar === '') {
                    this.notificacoesService.notificarAviso('Por favor informar as orientações sobre os ' +
                        'documentos complementares necessários!', null);
                    return;
                }
                else {
                    this.contrato.situacao = 'Aguardando complementação de documentação';
                }
            }
            if (this.selectedAnaliseDocumental === 'Reprovada') {
                this.contrato.situacao = 'Análise documental reprovada';
            }
            this.atualizaContrato(this.contrato);
            this.contratoAnaliseNCC = false;
        }
        if (situacao === 'contratoAssinadoPmpa') {
            if (this.contrato.dataInicial === null) {
                this.notificacoesService.notificarAviso('Por favor informar a data da assinatura do contrato no SAF!', null);
                return;
            }
            this.contrato.situacao = 'Contrato vigente';
            this.atualizaContrato(this.contrato);
            this.contratoAssinaturaPmpa = false;
            // this.empresaAdimplenteInadimplente(this.entidade.id, true);
        }
    };
    ContratoComponent.prototype.contratoAndamentoEmpresa = function (situacao) {
        if (situacao === 'contratoInfDivida') {
            // if (this.isRelDivida) {
            if (!this.isBoletoPago) {
                this.notificacoesService.notificarAviso('Por favor, antes de reenviar o Contrato providencie o pagamento dos ' +
                    'débitos e após insira o Boleto Pago nos anexos da Empresa.', null);
                return;
            }
            // }
            this.contrato.situacao = 'Cadastro reencaminhado para verificação de regularidade no SAC';
            this.atualizaContrato(this.contrato);
            this.contratoInfDivida = false;
        }
        if (situacao === 'contratoAnaliseSMCA') {
            this.contrato.situacao = 'Cadastro encaminhado para análise técnica';
            this.atualizaContrato(this.contrato);
            this.contratoInfComplementar = false;
        }
        if (situacao === 'contratoAnaliseSMCAResiduos') {
            this.contrato.situacao = 'Cadastro encaminhado para análise técnica - resíduos';
            this.atualizaContrato(this.contrato);
            this.contratoInfComplementar = false;
        }
        if (situacao === 'contratoInfComplementar') {
            var anexoComplementar_1 = false;
            this.anexos.forEach(function (anexo) {
                if (anexo.tipoAnexo === 'Complementar') {
                    anexoComplementar_1 = true;
                }
            });
            console.log('................contratoAndamentoEmpresa>>>', this.contrato);
            if (!anexoComplementar_1) {
                if (this.contrato.obsSmcaComplementada === null || this.contrato.obsSmcaComplementada === '') {
                    this.notificacoesService.notificarAviso('Por favor providenciar as informações complementares solicitadas!', null);
                    return;
                }
            }
            this.contrato.situacao = 'Cadastro reencaminhado para análise técnica';
            this.atualizaContrato(this.contrato);
            this.contratoInfComplementar = false;
        }
        if (situacao === 'contratoNccInfComplementar') {
            // let anexoComplementar: Boolean = false;
            // this.anexos.forEach(anexo => {
            //   if (anexo.tipoAnexo === 'Complementar') {
            //     anexoComplementar = true;
            //   }
            // });
            // console.log('................contratoAndamentoEmpresa>>>', this.contrato);
            // if (!anexoComplementar) {
            if (this.contrato.obsNccComplementada === null || this.contrato.obsNccComplementada === '') {
                this.notificacoesService.notificarAviso('Por favor providenciar as informações complementares solicitadas!', null);
                return;
            }
            // }
            this.contrato.situacao = 'Reencaminhado para análise documental';
            this.atualizaContrato(this.contrato);
            this.contratoNccInfComplementar = false;
        }
        if (situacao === 'contratoAssinadoEmpresa') {
            this.contrato.situacao = 'Contrato assinado pelo Gerador';
            this.atualizaContrato(this.contrato);
            this.contratoAssinaturaEmpresa = false;
        }
    };
    ContratoComponent.prototype.atualizaContrato = function (contrato) {
        var _this = this;
        this.spinnerService.exibir();
        this.dmluService.contratoAndamento(contrato).subscribe(function (contratoEditado) {
            // Remove o valor atual
            _this.contratos = _this.contratos.filter(function (item) { return item.id !== _this.contrato.id; });
            _this.buscaContratos();
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarSucesso('Contrato atualizado com sucesso', null);
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    // controlaOpcao(opcao) {
    //   console.log('.......controlaFuncao>>>>' , opcao);
    //   if (opcao === 'documentos') {
    //     this.sistemaSEI = '';
    //     this.contrato.numeroSei = null;
    //   }
    //   if (opcao === 'sistemaSEI') {
    //     this.documentos = '';
    //     this.contrato.numeroSei = null;
    //   }
    //   if (opcao === 'numeroSEI') {
    //     this.sistemaSEI = '';
    //     this.documentos = '';
    //   }
    // }
    // controlaSituacao(situacao) {
    //   console.log('.......controlaSituacao..............>>>>' , situacao);
    //   //this.selectedSituacao = situacao;
    // }
    ContratoComponent.prototype.gerarNovoCodigoDmlu = function (idEntidade) {
        var _this = this;
        this.spinnerService.exibir();
        this.dmluService.gerarCodigoDmlu(idEntidade).subscribe(function (codigoDmlu) {
            _this.entidade.codigoDmlu = codigoDmlu;
            _this.spinnerService.ocultar();
            // this.notificacoesService.notificarSucesso('Código Balança gerado com sucesso', null);
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ContratoComponent.prototype.empresaAdimplenteInadimplente = function () {
        var _this = this;
        this.spinnerService.exibir();
        var adimplente = false;
        if (this.adimplenteInadimplente === 'adimplente') {
            adimplente = true;
        }
        this.dmluService.empresaAdimplenteInadimplente(this.entidade.id, adimplente).subscribe(function (entidade) {
            _this.entidade = entidade;
            _this.spinnerService.ocultar();
            // this.notificacoesService.notificarSucesso('Código Balança gerado com sucesso', null);
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ContratoComponent.prototype.gerarNovoNumeroContrato = function () {
        var _this = this;
        this.spinnerService.exibir();
        this.dmluService.gerarNumeroContrato().subscribe(function (numeroContrato) {
            _this.contrato.numeroContrato = numeroContrato;
            _this.contrato.anoContrato = new Date().getFullYear();
            _this.spinnerService.ocultar();
            // this.notificacoesService.notificarSucesso('Código Balança gerado com sucesso', null);
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ContratoComponent.prototype.novosTiposResiduos = function () {
        this.novoTipoResiduo = true;
    };
    ContratoComponent.prototype.enviaEmailSMCA = function () {
        var _this = this;
        this.novoTipoResiduo = false;
        this.spinnerService.exibir();
        this.dmluService.enviaEmailSMCA(this.entidade.id, this.residuoNovo).subscribe(function (retorno) {
            _this.spinnerService.ocultar();
            // this.notificacoesService.notificarSucesso('Código Balança gerado com sucesso', null);
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ContratoComponent.prototype.atualizaSelecaoResiduo = function (residuoSel, selecionado, tipoLocal) {
        var _this = this;
        console.log('------------------------------>>', residuoSel, '---', selecionado);
        if (tipoLocal === 'transbordo') {
            this.residuosTransbordo.forEach(function (residuo) {
                if (residuoSel === residuo.id) {
                    residuo.selecionado = selecionado;
                }
            });
            this.residuosSelTb = [];
            this.residuosTransbordo.forEach(function (res) {
                if (res.selecionado) {
                    _this.residuosSelTb.push(res);
                }
            });
        }
        if (tipoLocal === 'compostagem') {
            this.residuosCompostagem.forEach(function (residuo) {
                if (residuoSel === residuo.id) {
                    residuo.selecionado = selecionado;
                }
            });
            this.residuosSelCp = [];
            this.residuosCompostagem.forEach(function (res) {
                if (res.selecionado) {
                    _this.residuosSelCp.push(res);
                }
            });
        }
        if (tipoLocal === 'triagem') {
            this.residuosTriagem.forEach(function (residuo) {
                if (residuoSel === residuo.id) {
                    residuo.selecionado = selecionado;
                }
            });
            this.residuosSelTg = [];
            this.residuosTriagem.forEach(function (res) {
                if (res.selecionado) {
                    _this.residuosSelTg.push(res);
                }
            });
        }
        // console.log('------------------------------>>', this.residuos);
        // console.log('------------------------------>>', this.residuosContrato);
    };
    ContratoComponent.prototype.alterarResiduos = function (idContrato) {
        var _this = this;
        // console.log('------------------------------>>', this.residuos);
        var contratoParaEditar = this.contratos.find(function (contrato) {
            return contrato.id === idContrato;
        });
        this.contratoVO = __assign({}, contratoParaEditar);
        this.dmluService.getContratoId(idContrato).subscribe(function (contratoEditado) {
            _this.contrato = contratoEditado;
            console.log('Cadastro Inicializa Resíduos>>>>>buscaResiduosContrato>>>>>>>>>', _this.contrato);
            _this.buscaResiduosContrato(false);
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ContratoComponent.prototype.changeCheckmark = function (boolEvt, residuo) {
        console.log(boolEvt + ' ' + residuo);
        if (boolEvt) {
            this.residuosSelTb.push(residuo);
        }
        else {
            for (var index = 0; index < this.residuosSelTb.length; index++) {
                var element = this.residuosSelTb[index];
                if (element.id === residuo.id) {
                    this.residuosSelTb.splice(index, 1);
                }
            }
        }
        console.log(this.residuosSelTb);
    };
    ContratoComponent.prototype.atualizaIsencao = function (selecionado) {
        // console.log('------------atualizaIsencao------------------>>', selecionado);
        this.empresaIsentaTarifa[0] = selecionado;
        this.contrato.isencaoTarifa = selecionado;
        // console.log('------------atualizaIsencao------------------>>', this.empresaIsentaTarifa[0]);
    };
    return ContratoComponent;
}());
export { ContratoComponent };
