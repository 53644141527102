/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/primeng/components/blockui/blockui.ngfactory";
import * as i3 from "primeng/components/dom/domhandler";
import * as i4 from "primeng/components/blockui/blockui";
import * as i5 from "../../../../node_modules/primeng/components/progressspinner/progressspinner.ngfactory";
import * as i6 from "primeng/components/progressspinner/progressspinner";
import * as i7 from "./spinner.component";
import * as i8 from "../services/spinner.service";
var styles_SpinnerComponent = [i0.styles];
var RenderType_SpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SpinnerComponent, data: {} });
export { RenderType_SpinnerComponent as RenderType_SpinnerComponent };
export function View_SpinnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "p-blockUI", [], null, null, null, i2.View_BlockUI_0, i2.RenderType_BlockUI)), i1.ɵprd(512, null, i3.DomHandler, i3.DomHandler, []), i1.ɵdid(2, 4374528, null, 0, i4.BlockUI, [i1.ElementRef, i3.DomHandler], { blocked: [0, "blocked"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 4, "div", [["class", "sgr-spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p-progressSpinner", [], null, null, null, i5.View_ProgressSpinner_0, i5.RenderType_ProgressSpinner)), i1.ɵdid(5, 49152, null, 0, i6.ProgressSpinner, [], { strokeWidth: [0, "strokeWidth"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "ui-widget"], ["style", " color: white; margin-left: 15px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Aguarde..."]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.exibir; _ck(_v, 2, 0, currVal_0); var currVal_1 = 4; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_SpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sgr-spinner", [], null, null, null, View_SpinnerComponent_0, RenderType_SpinnerComponent)), i1.ɵdid(1, 245760, null, 0, i7.SpinnerComponent, [i8.SpinnerService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SpinnerComponentNgFactory = i1.ɵccf("sgr-spinner", i7.SpinnerComponent, View_SpinnerComponent_Host_0, {}, {}, []);
export { SpinnerComponentNgFactory as SpinnerComponentNgFactory };
