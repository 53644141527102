<sgr-menu></sgr-menu>

<div class="container">

  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/sgrIni">SGR</a></li>
    <li class="breadcrumb-item active" aria-current="page">Cadastro</li>
  </ol>

  <h1 class="page-header text-primary">
    Secretaria/Órgão<br>
    <small>Cadastro</small>
  </h1>


  <div class="mobile-overflow-x">
    <p-table #tabelaDestinosFinais [value]="secretariasPmpa" [paginator]="true" sortField="secretaria" [rows]="10"
      [globalFilterFields]="['secretaria', 'sigla']">
      <ng-template pTemplate="caption">
        <div class="row justify-content-between">
          <div class="col-auto form-group">
            <button type="button" class="btn btn-primary text-nowrap" (click)="mostrarDialogParaNovo()"
              label="Adicionar" *ngIf="!variaveisGlobais.roleEntidade">
              <i class="fa fa-plus pr-2"></i>Adicionar Secretaria/Órgão
            </button>
          </div>
          <div class="col-sm-12 col-md-6 form-group busca text-right">
            <input type="text" pInputText size="50" placeholder="Filtrar secretarias"
              (input)="tabelaDestinosFinais.filterGlobal($event.target.value, 'contains')" class="form-control" />
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="secretaria" class="text-center" style="width: 200px; height: 60px;">
            Secretaria
            <p-sortIcon field="secretaria"></p-sortIcon>
          </th>
          <th pSortableColumn="sigla" class="text-center" style="width: 100px; height: 60px;">
            Sigla
            <p-sortIcon field="sigla"></p-sortIcon>
          </th>
          <th class="text-center" style="width: 350px; height: 60px;">
            Endereço
          </th>
          <th style="width: 150px;" class="text-center">
            Ações
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-secretariaPmpa>
        <tr>
          <td>
            {{ secretariaPmpa?.secretaria }}
          </td>
          <td>
            {{ secretariaPmpa?.sigla }}
          </td>
          <td>
            {{ secretariaPmpa?.endereco?.enderecoFormatadoCurto }}
          </td>
          <td>
            <button title="Editar" (click)="mostrarDialogParaEditar(secretariaPmpa.id)" label="Detalhes"
              class="btn btn-outline-secondary mx-2">
              <i class="fa fa-info pr-2"></i>Detalhes
            </button>
            <span class="text-danger" *ngIf="!secretariaPmpa.ativo">Inativo</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<!-- Dialog para novo -->
<p-dialog [header]="tituloDialogEdit" [(visible)]="mostrarDialogEdit" [responsive]="true" showEffect="fade"
  [modal]="true" [width]="700" [height]="800">
  <div class="row">
    <div class="col-sm-9 form-group">
      <label for="vin">Secretaria/Órgão</label>
      <input pInputText id="secretaria" [(ngModel)]="secretariaPmpaEdit.secretaria" size="30" class="form-control" />
    </div>
    <div class="col-sm-3 form-group">
      <label for="vin">Sigla</label>
      <input pInputText id="sigla" [(ngModel)]="secretariaPmpaEdit.sigla" size="30" class="form-control" />
    </div>
    <!-- <div class="ui-g-12">
      <div class="ui-g-3">
        <label for="vin">Órgão</label>
      </div>
      <div class="ui-g-9">
        <input pInputText id="nome" [(ngModel)]="secretariaPmpaEdit.orgao" size="30"/>
      </div>
    </div> -->
    <div class="col-12 form-group">
      <label for="vin">Situação</label>
      <div>
        <p-radioButton name="dfAtivo" [value]="true" label="Ativo" [(ngModel)]="secretariaPmpaEdit.ativo">
        </p-radioButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <p-radioButton name="dfAtivo" [value]="false" label="Inativo" [(ngModel)]="secretariaPmpaEdit.ativo">
        </p-radioButton>
      </div>
    </div>
    <div class="col-12 form-group">
      <label for="vin">Observação</label>
      <textarea pInputTextarea id="observacao" [(ngModel)]="secretariaPmpaEdit.observacao" [rows]="3"
        class="form-control"></textarea>
    </div>

    <fieldset class="col-12">
      <legend>Endereço</legend>
      <div class="row procempaCdl" procempaCdl [maxResults]="18" [codigo]="logradouroCdl?.codigoLogradouro"
        [numero]="logradouroCdl?.numero" [localizar]="true">
        <div class="col-sm-8 form-group">
          <label>Logradouro</label>
          <input cdlLogradouro [(ngModel)]="logradouro" size="35" name="logradouro" (seleciona)="selecionaCDL($event)"
            placeholder="Informe NOME" class="ui-input-text ui-corner-all form-control ui-state-default">
        </div>
        <div class="col-sm-4 form-group">
          <label>Número</label>
          <input cdlNumero [(ngModel)]="numero" name="numero" size="15" placeholder="NÚMERO"
            class="form-control ui-state-default">
        </div>
      </div>
    </fieldset>
    <div class="col-12 mb-3" *ngIf="!isInsert">
      {{secretariaPmpaEdit?.endereco?.enderecoFormatado}}
    </div>

  </div>


  <div class="botoes text-right" *ngIf="!variaveisGlobais.roleEntidade">
    <button type="button" (click)="mostrarDialogEdit = false" label="Cancelar" class="btn btn-outline-secondary">
      Cancelar
    </button>
    <button type="button" (click)="salvar()" label="Salvar" class="btn btn-primary">
      Salvar
    </button>
  </div>
</p-dialog>

<p-dialog header="Excluir Destino Final" [(visible)]="mostrarDialogExcluir" [responsive]="true" showEffect="fade"
  [modal]="true" [width]="500">
  <p *ngIf="secretariaPmpaExcluir" class="mb-5">
    <strong>Confirma a exclusão</strong> do órgão/secretaria <em>{{ secretariaPmpaExcluir.secretaria}}</em>?
  </p>
  <div class="botoes text-right">
    <button type="button" class="btn btn-outline-secondary" (click)="mostrarDialogExcluir = false"
      label="Não, cancelar">
      Não. Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="excluir()" label="Sim, excluir">
      Sim. Excluir
    </button>
  </div>
</p-dialog>