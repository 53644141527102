import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { MenuItem, SelectItem } from 'primeng/api';
import { Entidade } from 'src/app/@shared/models/entidade.model';
import { EntidadeService } from '../entidade/entidade.service';
import { SpinnerService } from '../services/spinner.service';
import { NotificacoesService } from '../services/notificacoes.service';
import { Globals } from 'src/app/globals';
import { SecretariaPmpa } from 'src/app/@shared/models/secretariaPmpa.model';
import { SecretariaPmpaService } from '../services/secretariaPmpa.service';
import { Endereco } from 'src/app/@shared/models/endereco';
import { LogradouroCdl } from 'procempa-cdl-lib';
import { Localizacao } from 'src/app/@shared/models/localizacao';

@Component({
  templateUrl: './secretariaPmpa.component.html',
  styleUrls: ['./secretariaPmpa.component.scss']
})
export class SecretariaPmpaComponent implements OnInit {

  secretariasPmpa: SecretariaPmpa[] = [];
  items: MenuItem[];
  nomeUsuario: string;
  //entidadePMPA: Entidade;
  entidadeNome = '';
  // Novo/edit
  secretariaPmpaEdit: SecretariaPmpa;
  mostrarDialogEdit: boolean;
  tituloDialogEdit: string;
  isInsert: boolean;
  // Exluir
  secretariaPmpaExcluir: SecretariaPmpa;
  mostrarDialogExcluir: boolean;

  variaveisGlobais: Globals;
  activeRoute: string;
  // CDL
  endereco: Endereco;
  enderecoRes: Endereco = {};
  logradouroCdl: LogradouroCdl;
  localizacao: Localizacao;
  logradouro: string;
  numero: string;

  constructor(
    private router: Router,
    private keycloakService: KeycloakService,
    private secretariaPmpaService: SecretariaPmpaService,
    private entidadeService: EntidadeService,
    private spinnerService: SpinnerService,
    private notificacoesService: NotificacoesService,
    private globals: Globals
  ) {

    this.activeRoute = this.router.url;
    this.variaveisGlobais = globals;
  }

  ngOnInit() {

    if (this.variaveisGlobais.menuRaiz === null || this.variaveisGlobais.menuRaiz === '') {
      this.router.navigate(['/sgrIni']);
    } else {
      this.inicializaOnInit();
    }

  }

  inicializaOnInit() {
    this.spinnerService.exibir();
    this.secretariaPmpaEdit = new SecretariaPmpa();
    this.endereco = null;
    this.enderecoRes = null;
    this.logradouroCdl = null;
    this.localizacao = null;
    this.logradouro = null;
    this.numero = null;
    this.secretariaPmpaService.getSecretariasPmpa().subscribe(secretariasPmpa => {
          this.spinnerService.ocultar();
          this.secretariasPmpa = secretariasPmpa;
    });

  }

  logout() {
    const url = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
    this.keycloakService.getKeycloakInstance().logout({redirectUri: url});
  }

  mostrarDialogParaNovo() {
    this.secretariaPmpaEdit = new SecretariaPmpa();
    this.mostrarDialogEdit = true;
    this.isInsert = true;

    this.tituloDialogEdit = 'Nova Secretaria';
  }

  mostrarDialogParaEditar(idSecretariaPmpa: number) {
    const secretariaPmpaParaEditar = this.secretariasPmpa.find(function(secretariaPmpa) {
      return secretariaPmpa.id === idSecretariaPmpa;
    });
    this.secretariaPmpaEdit = {...secretariaPmpaParaEditar};
    console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.secretariaPmpaEdit);

    this.mostrarDialogEdit = true;
    this.isInsert = false;
    this.tituloDialogEdit = 'Editar Secretaria';
  }

  mostrarDialogEditParaExcluir(idProduto: number) {
    // this.produtoExcluir =  this.produtos.find(function(produto) {
    //   return produto.id === idProduto;
    // });
    // this.mostrarDialogExcluir = true;
  }

  salvar() {

    //Verifica se todos os campos foram preenchidos
    if (this.secretariaPmpaEdit.secretaria == null || this.secretariaPmpaEdit.secretaria === '') {
      this.notificacoesService.notificarAviso('Por favor informar o NOME da Secretaria/Órgão!', null);
      return ;
    }
    if (this.secretariaPmpaEdit.sigla == null || this.secretariaPmpaEdit.sigla === '') {
      this.notificacoesService.notificarAviso('Por favor informar a SIGLA da Secretaria/Órgão!', null);
      return ;
    }
    if (this.secretariaPmpaEdit.endereco == null || this.secretariaPmpaEdit.endereco === '') {
      this.notificacoesService.notificarAviso('Por favor informar o ENDEREÇO da Secretaria/Órgão!', null);
      return ;
    }

    this.spinnerService.exibir();

    console.log('salvar....................>>>>>>>>>>>>>>', this.secretariaPmpaEdit);
    // this.secretariaPmpaEdit.entidadeDO = this.entidadePMPA;

    // Se novo
    if (this.secretariaPmpaEdit.id == null) {
      this.secretariaPmpaService.criarSecretariaPmpa(this.secretariaPmpaEdit).subscribe(secretariaPmpaNovo => {
        console.log('salvado....................>>>>>>>>>>>>>>', secretariaPmpaNovo);
        this.secretariasPmpa.push(secretariaPmpaNovo);

        this.mostrarDialogEdit = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso('Adicionado com sucesso', null);
      },
      error => {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro('Erro ao adicionar ', error);
      });
    } else { // Se editando
      this.secretariaPmpaService.editarSecretariaPmpa(this.secretariaPmpaEdit).subscribe(secretariaPmpaEditado => {
        // Remove o valor atual
        this.secretariasPmpa = this.secretariasPmpa.filter(item => item.id !== this.secretariaPmpaEdit.id);

        // Adiciona o novo
        this.secretariasPmpa.push(secretariaPmpaEditado);

        this.mostrarDialogEdit = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso('Atualizado com sucesso', null);
      },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });
    }
  }

  excluir() {
    // this.spinnerService.exibir();
    // console.log('salvar....................>>>>>>>>>>>>>>', this.variaveisGlobais.menuRaiz);

    // this.produtoService.excluirProduto(this.produtoExcluir, this.variaveisGlobais.menuRaiz).subscribe(produto => {
    //   if (produto == null) {
    //     // Remove o valor atual
    //     this.produtos = this.produtos.filter(item => item.id !== this.produtoExcluir.id);
    //   }
    //   this.mostrarDialogExcluir = false;
    //   this.spinnerService.ocultar();
    //   this.notificacoesService.notificarSucesso('Excluído com sucesso', null);
    // },
    // error => {
    //   console.log(error);
    //   this.spinnerService.ocultar();
    //   this.notificacoesService.notificarErro('Erro ao exluir', error.error);
    // });
  }


  checkActive(route: string) {
    return route === this.activeRoute;
  }

  selecionaCDL(endereco) {

    this.localizacao = {latitude:null, longitude:null};

    if (endereco.latitude !== undefined && endereco.latitude !== null) {
      this.localizacao = {
        latitude: endereco.latitude,
        longitude: endereco.longitude
      };
      delete endereco['latitude'];
      delete endereco['longitude'];
    }

    this.secretariaPmpaEdit.endereco = endereco;
    this.secretariaPmpaEdit.endereco.localizacao = this.localizacao;
  }
}
