import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../@core/services/spinner.service';
import { NotificacoesService } from '../../@core/services/notificacoes.service';
import { Router } from '@angular/router';
import { InfoAutoCadastro } from '../../@shared/models/autoCadastro.model';
// import { SelectItem } from 'primeng/api';
import { SmfPessoaService } from '../../@core/services/smfPessoa.service';
// import { EntidadeService } from 'src/app/@core/entidade/entidade.service';
import { Globals } from '../../globals';
import { PessoaFisica } from 'src/app/@shared/models/pessoaFisica.model';
import { PessoaFisicaService } from 'src/app/@core/services/pessoaFisica.service';
// import { Entidade } from 'src/app/@shared/models/entidade.model';

@Component({
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})
export class CadastroComponent implements OnInit {

  // tiposEntidade: SelectItem[];
  // entidade: Entidade;
  pessoaFisica: PessoaFisica;
  mensagemCadastro = '';
  cpf: string;
  nomeCompleto: string;
  emailLogin: string;
  variaveisGlobais;

  constructor(
      private router: Router,
      private notificacoesService: NotificacoesService,
      private spinnerService: SpinnerService,
      // private entidadeService: EntidadeService,
      private pessoaFisicaService: PessoaFisicaService,
      private smfPessoaService: SmfPessoaService,
      private globals: Globals
  ) { }

  ngOnInit() {
    // Configura o form
    // this.form = this.formBuilder.group({
      // 'nomeCompleto': new FormControl(this.globals.userFullName, Validators.required),
      // 'cpf': new FormControl('', Validators.required),
      // 'emailLogin': new FormControl(this.globals.userEmail, Validators.required)
      // 'usuario': new FormControl('', Validators.required),
      // 'cnpj': new FormControl('', Validators.required),
      // 'nomeFantasia': new FormControl('', Validators.required),
      // 'razaoSocial': new FormControl('', Validators.required),
      // 'telefone': new FormControl('', Validators.required),
      // 'email': new FormControl('', Validators.required),
      // 'numeroCnae': new FormControl('', Validators.required),
      // 'descricaoCnae': new FormControl('', Validators.required)
    // });

    this.variaveisGlobais = this.globals;

    this.pessoaFisica = new PessoaFisica();

    setTimeout(() => {
      this.pessoaFisica.nomeCompleto = this.variaveisGlobais.userFullName;
      this.pessoaFisica.email = this.variaveisGlobais.userEmail;
  }, 500);
    console.log('----------------->', this.pessoaFisica);
  }

  verificaCpfExiste(cpf: string) {

    console.log('verificaCpfExiste>>>>>>>', cpf);
    if (cpf === '__.___.___-__') {
      cpf = null;
    }

    if (cpf == null || cpf === '') {
      // this.spinnerService.ocultar();
      // this.notificacoesService.notificarErro('Não encontrou entidade para CNPJ informado!', null);
    } else {
      const novoCpf = cpf.replace(/[^\d]+/g, '');
      // Acessa SMF
      this.smfPessoaService.getPessoaFazenda(novoCpf, '1').subscribe(pessoa => {
        console.log('ACESSO PESSOA SMF>>>>>>>>', pessoa);
        if (pessoa.nomePessoa !== null) {
          this.pessoaFisica.nomeCompleto = pessoa.nomePessoa;
        }
      });
    }
  }

  salvar() {

    if (this.pessoaFisica.cpf === undefined || this.pessoaFisica.cpf === null || this.pessoaFisica.cpf === '') {
      this.notificacoesService.notificarAviso('Por favor informe o CPF!', null);
      return ;
    }
    if (this.pessoaFisica.nomeCompleto === undefined || this.pessoaFisica.nomeCompleto === null || this.pessoaFisica.nomeCompleto === '') {
      this.notificacoesService.notificarAviso('Por favor informe o Nome Completo!', null);
      return ;
    }
    if (this.pessoaFisica.celular === undefined || this.pessoaFisica.celular === null || this.pessoaFisica.celular === '') {
      this.notificacoesService.notificarAviso('Por favor informe o Celular!', null);
      return ;
    }

    this.spinnerService.exibir();
    this.pessoaFisica.ativo = true;
    this.pessoaFisicaService.cadastroPessoaFisica(this.pessoaFisica).subscribe(
      retorno => {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso('PessoaFisica salva com sucesso', null );
        // this.globals.pessoaFisica = retorno;
        // this.pessoaFisica = retorno;
        this.router.navigate(['sgr']);

      },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      }
    );




    // const info = new InfoAutoCadastro();
    // info.nomeCompleto = this.nomeCompleto;
    // info.emailLogin = this.emailLogin;
    // info.cpf = this.cpf;
    // info.cnpj = this.form.get('cnpj').value;
    // info.nomeFantasia = this.form.get('nomeFantasia').value;
    // info.razaoSocial = this.form.get('razaoSocial').value;
    // info.telefone = this.form.get('telefone').value;
    // info.email = this.form.get('email').value;
    // info.numeroCnae = this.form.get('numeroCnae').value;
    // info.descricaoCnae = this.form.get('descricaoCnae').value;

    // this.entidadeService.autoCadastro(info).subscribe(entidade => {
    //   console.log('Fez autocadastro');
    //   this.router.navigate(['sgr']);
    //   this.spinnerService.ocultar();

    // },
    // error => {
    //   console.log('Erro autocadastro>>>>>>>>', error);
    //   this.notificacoesService.notificarErro(error.error, null);
    //   console.log(error);
    //   this.spinnerService.ocultar();
    // });

  }

  // buscaEntidade(cnpj: string) {

  //   let novoCnpj = cnpj.replace(/[^\d]+/g, '');

  //   console.log('BuscaEntidade sem formatação>>>>>>>', novoCnpj);

  //   if (novoCnpj === '') {
  //     novoCnpj = null;
  //   }

  //   if (novoCnpj === null) {
  //   } else {

  //     this.entidadeService.getEntidadeCnpj(novoCnpj).subscribe(
  //       entidade => {
  //         this.entidade = entidade;
  //         this.smfPessoaService.getPessoaFazenda(novoCnpj, '2').subscribe(pessoa => {
  //           console.log('ACESSO PESSOA SMF>>>>>>>>', pessoa);
  //           if (pessoa.nomePessoa != null) {
  //             this.form.get('nomeFantasia').setValue(pessoa.nomeFantasia);
  //             this.form.get('nomeFantasia').disable();
  //             this.form.get('razaoSocial').setValue(pessoa.nomePessoa);
  //             this.form.get('razaoSocial').disable();
  //             this.form.get('telefone').setValue(pessoa.telefone);
  //             this.form.get('telefone').disable();
  //             this.form.get('email').setValue(pessoa.email);
  //             this.form.get('email').disable();
  //             this.form.get('numeroCnae').setValue(pessoa.numeroCnae);
  //             this.form.get('numeroCnae').disable();
  //             this.form.get('descricaoCnae').setValue(pessoa.descricaoCnae);
  //             this.form.get('descricaoCnae').disable();
  //           }
  //         });

  //         if (entidade !== null) {
  //           this.mensagemCadastro = 'ATENÇÃO! A Empresa ' + entidade.nomeFantasia + ', CNPJ: ' + entidade.cnpj + ', já está cadastrada. '
  //                 + 'Solicite ao responsável pelo cadastro da Empresa que efetive a tua inclusão.';
  //                 this.form.get('cpf').setValue('');
  //                 this.form.get('cpf').disable();
  //         }
  //       },
  //       error => {
  //         console.log(error);
  //         console.log(error.error);
  //         this.spinnerService.ocultar();
  //         this.notificacoesService.notificarErro(error.error, null);
  //       });

  //   }

  // }

  // buscaPessoaFisica(cpf: string) {

  //   const novoCpf = cpf.replace(/[^\d]+/g, '');
  //   this.form.get('nomeCompleto').setValue('');

  //   console.log('BuscaPessoaFisica sem formatação>>>>>>>', novoCpf);

  //   if (novoCpf === '') {
  //     this.notificacoesService.notificarErro('Não encontrou entidade para CPF informado!', null);
  //   } else {

  //     this.spinnerService.exibir();
  //     this.smfPessoaService.getPessoaFazenda(novoCpf, '1').subscribe(pessoa => {
  //       console.log('ACESSO PESSOA SMF>>>>>>>>', pessoa);
  //       this.form.get('nomeCompleto').setValue(pessoa.nomePessoa);
  //       this.spinnerService.ocultar();
  //     });
  //   }

  // }


}
