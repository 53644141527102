import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Contrato } from 'src/app/@shared/models/contrato.model';
import { ContratoVO } from 'src/app/@shared/models/contratoVO.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DmluService = /** @class */ (function () {
    function DmluService(http) {
        this.http = http;
    }
    //CONTRATOS
    DmluService.prototype.getContratosIdEntidade = function (idEntidade) {
        var url = environment.apiUrl + '/gdr/contratoIdEntidade/' + idEntidade;
        return this.http.get(url);
    };
    DmluService.prototype.getContratoId = function (idContrato) {
        var url = environment.apiUrl + '/gdr/contratoId/' + idContrato;
        return this.http.get(url);
    };
    DmluService.prototype.novoContrato = function (contrato) {
        console.log('Service ... novoContrato>>>', contrato);
        return this.http.post(environment.apiUrl + '/gdr', contrato);
    };
    DmluService.prototype.atualizarResiduos = function (contrato) {
        console.log('Service ... atualizarResiduos>>>', contrato);
        return this.http.put(environment.apiUrl + '/gdr', contrato);
    };
    DmluService.prototype.contratoAndamento = function (contrato) {
        return this.http.put(environment.apiUrl + '/gdr/contratoAndamento', contrato);
    };
    DmluService.prototype.getEmpresaJaTemContrato = function (idEntidade) {
        var url = environment.apiUrl + '/gdr/empresaJaTemContrato/' + idEntidade;
        console.log('Service ... getEmpresaJaTemContrato>>>', idEntidade);
        return this.http.get(url);
    };
    // getEntidadesComContrato(idEntidade: number): Observable<Contrato> {
    //   const url = environment.apiUrl + '/gdr/entidadeComContrato/' + idEntidade;
    //   return this.http.get<Contrato>(url);
    // }
    DmluService.prototype.getEntidadesComContrato = function (listaIdEntidade) {
        console.log('Service ... getEntidadesComContrato>>>', listaIdEntidade);
        var url = environment.apiUrl + '/gdr/entidadeComContrato?listaIdEntidade=' + listaIdEntidade;
        return this.http.get(url);
    };
    DmluService.prototype.getContratoValido = function (idEntidade) {
        return this.http.get(environment.apiUrl + '/gdr/contratoValido/' + idEntidade);
    };
    DmluService.prototype.gerarNumeroContrato = function () {
        var url = environment.apiUrl + '/gdr/geraNumeroContrato';
        return this.http.get(url);
    };
    DmluService.prototype.gerarCodigoDmlu = function (idEntidade) {
        var url = environment.apiUrl + '/gdr/geraCodigoDmlu/' + idEntidade;
        return this.http.get(url);
    };
    DmluService.prototype.empresaAdimplenteInadimplente = function (idEntidade, adimplente) {
        var url = environment.apiUrl + '/gdr/empresaAdimplenteDmlu/' + idEntidade + '/' + adimplente;
        return this.http.get(url);
    };
    DmluService.prototype.enviaEmailSMCA = function (idEntidade, residuoNovo) {
        var url = environment.apiUrl + '/gdr/enviaEmailSMCA/' + idEntidade + '/' + residuoNovo;
        return this.http.get(url);
    };
    //CERTIFICADOS
    DmluService.prototype.getCertificadosIdEntidade = function (idEntidade) {
        var url = environment.apiUrl + '/gdr/certificadoIdEntidade/' + idEntidade;
        return this.http.get(url);
    };
    DmluService.prototype.editarCertificado = function (certificado) {
        return this.http.put(environment.apiUrl + '/gdr/certificado', certificado);
    };
    DmluService.prototype.novoCertificado = function (certificado) {
        console.log('Service ... novoCertificado>>>', certificado);
        return this.http.post(environment.apiUrl + '/gdr/certificado', certificado);
    };
    DmluService.prototype.getValorUFM = function () {
        return this.http.get(environment.apiUrl + '/gdr/valorUFM');
    };
    DmluService.prototype.getDescargaResiduoPesquisa = function (codigoBalancaPesquisa, mesPesquisa) {
        return this.http.get(environment.apiUrl + '/gdr/movimentoMensalGeral/' + codigoBalancaPesquisa + '/' + mesPesquisa);
    };
    DmluService.prototype.getTotalPorResiduo = function (codigoBalancaPesquisa, mesPesquisa) {
        return this.http.get(environment.apiUrl + '/gdr/totalPorResituo/' + codigoBalancaPesquisa + '/' + mesPesquisa);
    };
    DmluService.prototype.getDescargaResiduoCodigoBalancaMes = function (codigoBalanca, mesPesquisa) {
        return this.http.get(environment.apiUrl + '/gdr/descargaResiduoCodigoBalancaMes/' + codigoBalanca + '/' + mesPesquisa);
    };
    DmluService.prototype.atualizarDescarga = function (cdr) {
        console.log('Service ... atualizarDescarga>>>', cdr);
        return this.http.put(environment.apiUrl + '/gdr/atualizarDescarga', cdr);
    };
    DmluService.prototype.excluirDescarga = function (cdr) {
        var url = environment.apiUrl + '/gdr/deletarDescarga/' + cdr.id;
        return this.http.delete(url);
    };
    DmluService.ngInjectableDef = i0.defineInjectable({ factory: function DmluService_Factory() { return new DmluService(i0.inject(i1.HttpClient)); }, token: DmluService, providedIn: "root" });
    return DmluService;
}());
export { DmluService };
