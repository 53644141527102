<div class="container" *ngIf="isAutorizado">
  <div *ngIf="!pessoaAtiva">
    <div *ngIf="pessoasFisicas.length < 1">
      <br />
      <font size="4" color="red">{{ mensPessoaInativa }}</font>
    </div>
    <div
      *ngIf="pessoasFisicas.length > 1 && variaveisGlobais.pessoaFisica == null"
      align="center"
    >
      <br /><br /><br /><br /><br /><br /><br /><br />
      <div class="bg-light p-3 border">
        Este email está sendo utilizado <b>por mais de um usuário</b>. <br />Por
        favor, identifique o seu usuário! <br /><br />
        <div
          *ngFor="let pessoaFisica of pessoasFisicas"
          style="text-align: left"
          class="p-2 ml-3"
        >
          <p-radioButton
            class="hoverover"
            name="selecionaUsuario"
            [value]="pessoaFisica.id"
            [label]="pessoaFisica.nomeCompleto"
            [(ngModel)]="pesFisica"
            inputId="f01"
            (click)="controlaSelUsuario(pessoaFisica.id)"
          ></p-radioButton
          >&nbsp;&nbsp;&nbsp;
        </div>
      </div>
    </div>
  </div>

<!-- 
  <div>
    <h1 class="h4 text-danger mt-4">ATENÇÃO!</h1>
    Informamos a atualização do tutorial "GUIA DE USO" do MTRCC. <a href="../../../assets/doc/Guia do Usuario SGR - MTRCC - 20220214 v2.pdf" target="_blank">Acessar aqui!</a><br>
    E do tutorial "GUIA DE USO" do Grandes Geradores. <a href="../../../assets/doc/Manual do Usuário SGR - Grandes Geradores v1.pdf" target="_blank">Acessar aqui!</a><br>
    Como podem observar, a principal mudança está no momento do cadastramento de uma nova empresa e novo usuário. Anteriormente era necessário informar alguns dados da empresa e ao mesmo tempo algumas informações do responsável legal. <br>
    A partir de agora, no momento do cadastramento será solicitado apenas as informações do responsável legal. Após esse primeiro cadastro o usuário acessa o sistema, seleciona MTRCC ou Grandes Geradores, e então poderá incluir a(s) empresa(s).<br>
    Qualquer dúvida, por favor, entrar em contato com a SMAMUS através do email: <a href="mailto:smamus.mtrcc@portoalegre.rs.gov.br">smamus.mtrcc@portoalegre.rs.gov.br</a>
  </div>
--> 
<div> 
  <h1 class="h4 text-danger">ATENÇÃO!</h1>
  <font size="2">As informações das EMPRESAS cadastradas em qualquer um dos módulos (MTRCC, Grandes Geradores 
  ou GDR) valem para todo o SISTEMA, ou seja, para qualquer um dos módulos.<br>
  Para cadastrar novas EMPRESAS ou alterar qualquer uma das suas informações, 
  basta acessar qualquer um dos módulos. Porém, o acesso a determinado módulo define o tipo 
  de informação que será solicitado à EMPRESA.</font>
</div>

  <div *ngIf="pessoaAtiva">
    <div class="card-deck mt-5 mb-2">
      <!-- Logística MTRCC -->
      <div class="card"
        pTooltip="Criar Manifesto de Transporte de Resíduos da Construção Civil"
        tooltipPosition="bottom"
        showDelay="1"
        hideDelay="1"
        tooltipStyleClass="text-center">
        <div class="card-body">
          <a routerLink="/mtrcc"
            (click)="selecionaNavegacao('mtrcc')"
            class="a-nostyle">
                <img src="assets/img/Manifesto.svg" class="my-3" />
                <h2 class="m-0 pt-2">
                  MTRCC<br>
                  <small>Manifesto de Transporte de Resíduos da Construção Civil</small>
                </h2>
                <!-- <p class="ml-md-3 text-center d-sm-block">
                O Manifesto de Transporte de Resíduos da Construção Civil
                (MTRCC) foi regulamentado pelo Decreto nº 18705, de 08 de
                Julho de 2014 e é um documento emitido.
              </p> -->
          </a>
        </div>
      </div>

      <!-- Grandes Geradores -->
      <div class="card"
        pTooltip="Cadastrar grandes geradores de resíduos sólidos em atendimento ao Decreto Municipal 20.684/2020"
        tooltipPosition="bottom"
        showDelay="1"
        hideDelay="1"
        tooltipStyleClass="text-center">
        <div class="card-body" *ngIf="!variaveisGlobais.roleEntidade">
          <a routerLink="/grandeGerador" class="a-nostyle">
            <img src="assets/img/Grande_geradores.svg" class="my-3"
            />
            <h2 class="pt-2">
              Grandes Geradores
            </h2>
          </a>
        </div>

        <div class="card-body" *ngIf="variaveisGlobais.roleEntidade">
          <a routerLink="/grandesGeradores" class="a-nostyle">
            <img src="assets/img/Grande_geradores.svg" class="my-3"/>
            <h2 class="pt-2">
              Grandes Geradores<br><br>
              <small>Somente para cadastro de Grandes Geradores, Transportadores e
                Unidades de Destino Final, conforme o Decreto 20.684/2020.</small>
            </h2>
          </a>
        </div>
      </div>

      <!-- GDR -->
      <div class="card"
        pTooltip="Cadastrar empresas com contrato de entrega de resíduos ao DMLU"
        tooltipPosition="bottom"
        showDelay="1"
        hideDelay="1"
        tooltipStyleClass="text-center">
        <div class="card-body">
          <a routerLink="/gdr" class="a-nostyle">
            <img src="assets/img/gestao-residuos.svg" class="my-2"/>
            <h2 class="m-0 pt-2">
              GDR<small>&nbsp;&nbsp;&nbsp;(Gestão de Descarga de Resíduos)</small><br><br>
              <small>Escolha essa opção se deseja contratar o DMLU para destinação de resíduos na estação de transbordo ou unidade de triagem e compostagem.
              </small>
            </h2>
          </a>
        </div>
      </div>

     <!-- <div class="card bloqueado"
        pTooltip="Cadastrar empresas com contrato de entrega de resíduos ao DMLU"
        tooltipPosition="bottom"
        showDelay="1"
        hideDelay="1"
        tooltipStyleClass="text-center">
        <div class="card-body">
          <a routerLink="/gdr" class="a-nostyle">
            <img src="assets/img/gestao-residuos.svg" class="my-3"/>
            <h2 class="m-0 pt-2">
              GDR<br>
              <small>Gestão de Descarga de Resíduos</small>
            </h2>
          </a>
        </div>
      </div> -->


    </div>

      <!-- Logística Reversa -->
      <!-- <div class="card bloqueado"
        pTooltip="Em desenvolvimento"
        tooltipPosition="bottom"
        showDelay="1"
        hideDelay="1">
        <div class="card-body ">
          <a routerLink="/logisticaReversa" class="a-nostyle">
            <img src="assets/img/recycling.svg" class="my-3"/>
            <h2 class="pt-2">
              Logística Reversa<br>
              <small>em desenvolvimento</small>
            </h2>
          </a>
        </div>
      </div> -->




      <!-- <div class="row justify-content-center">
        <div
          class="card bg-light col-5"
          *ngIf="variaveisGlobais.roleEntidade"
          pTooltip="Somente para cadastramento de empresas prestadoras de serviços de transporte e de destinação final de resíduos"
          tooltipPosition="bottom"
          showDelay="1"
          hideDelay="1"
          tooltipStyleClass="text-center"
        >
          <div class="card-body">
            <a
              (click)="selecionaNavegacao('prestadoraServico')"
              class="col a-nostyle"
            >
              <h2 class="col text-center text-nowrap">
                Empresas Prestadoras de Serviço <br />
                (para Grandes Geradores)
              </h2>
            </a>
          </div>
        </div>
      </div> -->

      <!-- <ul class="nav nav-pills mb-3">
      <li class="nav-item col">
        <a
          (click)="selecionaNavegacao('dadosEmpresa')"
          class="nav-link"
          *ngIf="variaveisGlobais.roleEntidade"
        >
          <a class="nav-link"> -->
      <!-- <i class="fa fa-address-card-o"></i>Informações adicionais das
          Empresas Prestadoras de Serviço</a
        >
      </li>
      <li class="nav-item col">
        <a (click)="selecionaNavegacao('grandesGeradores')" class="nav-link"> -->
      <!-- <a class="nav-link"> -->
      <!-- <i class="fa fa-industry"></i>Grandes Geradores</a
        >
      </li>
      <li class="nav-item col">
      <a routerLink="/logisticaReversa" routerLinkActive="active" class="nav-link"> -->
      <!-- <a class="nav-link"> <i class="fa fa-recycle"></i>Logística Reversa</a>
      </li>
      <li class="nav-item col"> -->
      <!-- <a routerLink="/mtrcc" routerLinkActive="active" class="nav-link"> -->
      <!-- <a (click)="selecionaNavegacao('mtrcc')" class="nav-link">
          <i class="fa fa-file-text"></i>MTRCC</a
        >
      </li> -->
      <!-- <li  class="nav-item col">
        <a (click)="selecionaNavegacao('mapa')" class="nav-link">
            <i class="fa fa-globe"></i>Mapa</a>
    </li> -->
      <!-- <a class="nav-link"> -->
      <!-- <i class="fa fa-industry"></i>Mapa</a> -->
      <!-- <img src="assets/img/rio-grande-do-sul.png" height="50" width="50">Mapa</a> -->

      <!-- <p-tabMenu [model]="items" [activeItem]="activeItem">
  <ng-template pTemplate="item" let-item let-i="index">
      <div style="position: relative; text-align: center; min-width: 10em">
          <div class="ui-menuitem-icon" [ngClass]="item.icon" *ngIf="item.icon"></div>
          <div class="ui-menuitem-text">
              {{item.label}}
          </div>
          <a tabindex="0" class="ui-menuitem-icon" (click)="closeItem($event, i)" style="position: absolute; right: -1em; top: -.5em; padding: 0" *ngIf="i !== 0">
              <span class="pi pi-times"></span>
          </a>
      </div>
  </ng-template>
</p-tabMenu> -->
      <!-- </ul> -->
  </div>
</div>

<div class="container h-100" *ngIf="!isAutorizado">
  <div
    class="row align-items-center bg-light p-4 border mt-5 text-center mx-auto align-middle rounded justify-content-center"
  >
    <span class="">
      {{ variaveisGlobais?.userFullName }},&nbsp;&nbsp;<font size="4" color="red">
        você não tem autorização para acessar o Sistema SGR.</font
      ></span
    >
  </div>
</div>

<script>
  $(function() {
    $('[data-toggle="tooltip"]').tooltip();
  });
</script>
