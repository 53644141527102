import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { EntidadeService } from '../entidade/entidade.service';
import { SpinnerService } from '../services/spinner.service';
import { NotificacoesService } from '../services/notificacoes.service';
import { Globals } from 'src/app/globals';
import { LicencaAmbiental } from 'src/app/@shared/models/licencaAmbiental.model';
import { LicencaAmbientalService } from '../services/licencaAmbiental.service';
import { Entidade } from 'src/app/@shared/models/entidade.model';
import { SelectItem } from 'primeng/primeng';
import { SecretariaPmpa } from 'src/app/@shared/models/secretariaPmpa.model';
import { SecretariaPmpaService } from '../services/secretariaPmpa.service';

@Component({
  templateUrl: './licencaAmbiental.component.html',
  styleUrls: ['./licencaAmbiental.component.scss']
})
export class LicencaAmbientalComponent implements OnInit {

  licencasAmbientais: LicencaAmbiental[] = [];
  licencaAmbiental: LicencaAmbiental;
  entidade: Entidade;
  mostrarLicencas: Boolean = false;

  mostrarDialogEdit: boolean;
  tituloDialogEdit: string;
  isInsert: boolean;

  //Secretarias
  secretarias: SelectItem[] = [];
  secretariasPmpa: SecretariaPmpa[] = [];
  idSecretaria: number = null;

  // Exluir
  licencaAmbientalExcluir: LicencaAmbiental;
  mostrarDialogExcluir: boolean;


  variaveisGlobais: Globals;
  activeRoute: string;

  constructor(
    private router: Router,
    private keycloakService: KeycloakService,
    private licencaAmbientalService: LicencaAmbientalService,
    private entidadeService: EntidadeService,
    private secretariaPmpaService: SecretariaPmpaService,
    private spinnerService: SpinnerService,
    private notificacoesService: NotificacoesService,
    private globals: Globals
  ) {

    this.activeRoute = this.router.url;
    this.variaveisGlobais = globals;
  }

  ngOnInit() {

    if (this.variaveisGlobais.menuRaiz === null || this.variaveisGlobais.menuRaiz === '') {
      this.router.navigate(['/sgrIni']);
    } else {
      this.inicializaOnInit();
    }
  }

  inicializaOnInit() {
    this.spinnerService.exibir();
    this.licencaAmbiental = new LicencaAmbiental();

    this.entidade = null;
    this.entidadeService.getEntidadeCnpj('92.963.560/0001-60').subscribe(entidade => {
      this.entidade = entidade;
      this.licencaAmbientalService.getLicencasPorEntidade(entidade.id).subscribe(licencas => {
        this.spinnerService.ocultar();
        this.licencasAmbientais = licencas;
      });

    },
    error => {
      console.log(error);
      console.log(error.error);
      this.spinnerService.ocultar();
      this.notificacoesService.notificarErro(error.error, null);
    });

    this.secretariasPmpa = [];
    this.secretariaPmpaService.getSecretariasPmpa().subscribe(secretarias => {
        this.secretariasPmpa = secretarias;
        secretarias.forEach(sec => {
          this.secretarias.push( {label: sec.sigla, value: sec});
        });

      },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });


  }

  checkActive(route: string) {
    return route === this.activeRoute;
  }

  mostrarDialogParaNovo() {
    this.licencaAmbiental = new LicencaAmbiental();
    this.mostrarDialogEdit = true;
    this.isInsert = true;

    this.tituloDialogEdit = 'Nova Licença Ambiental';
  }

  mostrarDialogParaEditar(idLicenca: number) {
    const licencaParaEditar = this.licencasAmbientais.find(function(licencaAmbiental) {
      return licencaAmbiental.id === idLicenca;
    });
    this.licencaAmbiental = {...licencaParaEditar};
    console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.licencaAmbiental);

    this.mostrarDialogEdit = true;
    this.isInsert = false;
    this.tituloDialogEdit = 'Editar Licença Ambiental';
    this.licencaAmbiental.dataValidade = new Date(this.licencaAmbiental.dataValidade);

  }

  salvar() {

    //Verifica se todos os campos foram preenchidos
    if (this.licencaAmbiental.licenca == null || this.licencaAmbiental.licenca === '') {
      this.notificacoesService.notificarAviso('Por favor informar a LICENÇA!', null);
      return ;
    }
    if (this.licencaAmbiental.dataValidade == null) {
      this.notificacoesService.notificarAviso('Por favor informar a VALIDADE da Licença!', null);
      return ;
    }
    if (this.licencaAmbiental.secretariaPmpaDO == null) {
      this.notificacoesService.notificarAviso('Por favor informar o ÓRGAO EMISSOR da Licença!', null);
      return ;
    }

    this.spinnerService.exibir();

    this.licencaAmbiental.entidadeDO = this.entidade;
    this.licencaAmbiental.orgaoEmissor = this.licencaAmbiental.secretariaPmpaDO.sigla;
    console.log('salvar....................>>>>>>>>>>>>>>', this.licencaAmbiental);

    // Se novo
    if (this.licencaAmbiental.id == null) {
      this.licencaAmbientalService.criarLicenca(this.licencaAmbiental).subscribe(laNovo => {
        console.log('salvado....................>>>>>>>>>>>>>>', laNovo);
        this.licencasAmbientais.push(laNovo);

        this.mostrarDialogEdit = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso('Adicionado com sucesso', null);
      },
      error => {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro('Erro ao adicionar ', error);
      });
    } else { // Se editando
      this.licencaAmbientalService.editarLicenca(this.licencaAmbiental).subscribe(laEditado => {
        // Remove o valor atual
        this.licencasAmbientais = this.licencasAmbientais.filter(item => item.id !== this.licencaAmbiental.id);

        // Adiciona o novo
        this.licencasAmbientais.push(laEditado);

        this.mostrarDialogEdit = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso('Atualizado com sucesso', null);
      },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });
    }
  }

}
