import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InfoAutoCadastro } from 'src/app/@shared/models/autoCadastro.model';
import { Entidade } from 'src/app/@shared/models/entidade.model';
import { PessoaFisica } from 'src/app/@shared/models/pessoaFisica.model';

@Injectable({providedIn: 'root'})

export class EntidadeService {

  constructor(private http: HttpClient) {}

  autoCadastro(info: InfoAutoCadastro): Observable<Entidade> {
    console.log('autoCadastro>>>>>>>>>>>>>>>>>>', info);

    const url = environment.apiUrl + '/pessoaFisica/autoCad';
    console.log('autoCadastro url>>>>>>>>>>>>>>>>>>', url);
    const ret = this.http.post<Entidade>(url, info);
    console.log('autoCadastro Retorno>>>>>>>>>>>>>>>>>>', ret);
    return ret;
  }

  getEntidades(): Observable<Entidade[]> {
    console.log('getEntidades url>>>>>>>>>>>>>>>>>>', environment.apiUrl  + '/entidades/');
    return this.http.get<Entidade[]>(environment.apiUrl + '/entidades/');
  }

  getEntidadeRepresentada(): Observable<Entidade> {
    const url = environment.apiUrl + '/entidades/representada';
    console.log('----------EntidadeService getEntidadeRepresentada>>>>', url);
    return this.http.get<Entidade>(url);
  }

  getEntidade(id: number): Observable<Entidade> {
    return this.http.get<Entidade>(environment.apiUrl + '/entidades/');
  }

  getEntidadePorId(id: number): Observable<Entidade> {
    return this.http.get<Entidade>(environment.apiUrl + '/entidades/' + id + '/entidadeId');
  }

  getSubEntidades(id: number): Observable<Entidade[]> {
    const url = environment.apiUrl + '/entidades/' + id + '/subentidades';
    return this.http.get<Entidade[]>(url);
  }

  getEntidadePorUserName(userName: string): Observable<Entidade> {
    return this.http.get<Entidade>(environment.apiUrl + '/entidades/username/' + userName);
  }

  getEntidadeCnpj(cnpj: string): Observable<Entidade> {
    let cnpjSemFormatacao = cnpj.replace('/', '.');
    cnpjSemFormatacao = cnpjSemFormatacao.replace('-', '.');
    const url = environment.apiUrl + '/entidades/cnpj/' + cnpjSemFormatacao;
    console.log('----------EntidadeService getEntidadeCnpj>>>>', url);
    return this.http.get<Entidade>(url);
  }

  getEntidadePorCodigoDmlu(codigoDmlu: number): Observable<Entidade> {
    return this.http.get<Entidade>(environment.apiUrl + '/entidades/' + codigoDmlu + '/codigoDMLU');
  }

  getPessoaFisica(idEntidade: number): Observable<PessoaFisica[]> {
    const url = environment.apiUrl + '/entidades/' + idEntidade + '/pessoaFisica';
    return this.http.get<Entidade[]>(url);
  }

  getPessoaFisicaPorId(id: number): Observable<PessoaFisica> {
    const url = environment.apiUrl + '/entidades/' + id + '/pessoaFisicaId';
    return this.http.get<PessoaFisica>(url);
  }

  getPessoaFisicaPorUserName(userName: string): Observable<PessoaFisica> {
    const url = environment.apiUrl + '/entidades/' + userName + '/pessoaFisicaUserName';
    return this.http.get<PessoaFisica>(url);
  }

  criarEntidade(entidade: Entidade): Observable<Entidade> {
    return this.http.post<Entidade>(environment.apiUrl + '/entidades/', entidade);
  }

  editarEntidade(entidade: Entidade): Observable<Entidade> {
    const url = environment.apiUrl + '/entidades/';
    console.log('----------EntidadeService editarEntidade>>>>', url);
    return this.http.put<Entidade>(url, entidade);
  }

  excluirEntidade(entidade: Entidade): Observable<Entidade> {
    return this.http.delete<Entidade>(environment.apiUrl + '/entidades/' + entidade.id);
  }

  getEntidadesMtrcc(): Observable<Entidade[]> {
    console.log('getEntidadesMtrcc>>>>>>>>>>', environment.apiUrl + '/entidades/listaEntidadesMtrcc');
    return this.http.get<Entidade[]>(environment.apiUrl + '/entidades/listaEntidadesMtrcc');
  }

  getEntidadesGeradoras(): Observable<Entidade[]> {
    console.log('getEntidadesGeradoras>>>>>>>>>>', environment.apiUrl + '/entidades/listaEntidadesGeradoras');
    return this.http.get<Entidade[]>(environment.apiUrl + '/entidades/listaEntidadesGeradoras');
  }

  getEntidadesGdr(situacao: String): Observable<Entidade[]> {
    // console.log('getEntidadesGeradoras>>>>>>>>>>', environment.apiUrl + '/entidades/listaEntidadesGdr');
    return this.http.get<Entidade[]>(environment.apiUrl + '/entidades/listaEntidadesGdr/' + situacao);
  }

  getEntidadesVinculadas(id: number): Observable<Entidade[]> {
    console.log('getEntidadesVinculadas>>>>>>>>>>', environment.apiUrl + '/entidades/listaEntidadesVinculadas/' + id);
    return this.http.get<Entidade[]>(environment.apiUrl + '/entidades/listaEntidadesVinculadas/' + id);
  }

  getEntidadesPesquisa(opcoesPesquisa: String): Observable<Entidade[]> {
    return this.http.get<Entidade[]>(environment.apiUrl + '/entidades/listaEntidades/' + opcoesPesquisa);
  }

  getEntidadesPesquisaGdr(opcoesPesquisa: String): Observable<Entidade[]> {
    return this.http.get<Entidade[]>(environment.apiUrl + '/entidades/listaEntidadesPesqGdr/' + opcoesPesquisa);
  }

  getEntidadesDaPessoaFisica(idPessoaFisica: number): Observable<Entidade[]> {
    console.log('getEntidadesDaPessoaFisica>>>>>>>>>>', environment.apiUrl + '/entidades/pessoaFisica/' + idPessoaFisica);
    return this.http.get<Entidade[]>(environment.apiUrl + '/entidades/pessoaFisica/' + idPessoaFisica);
  }

  getPessoaJuridica(cnpj): Observable<any> {
    console.log('....................EntidadeService getPessoaJuridica>>>>', environment.apiUrl + '/entidades/fazenda/' + cnpj);
    return this.http.get<Entidade>(environment.apiUrl + '/entidades/fazenda/' + cnpj);
  }

  atualizaEmpresaFazenda(): Observable<any> {
    console.log('....................atualizaEmpresaFazenda>>>>', environment.apiUrl + '/entidades/atualizaFazenda');
    return this.http.get<any>(environment.apiUrl + '/entidades/atualizaFazenda');
  }

  correcoesExtras() {

    const rootUrl = environment.apiUrl + '/entidades/correcoesExtras';
    return this.http.get<String>(rootUrl);

  }

  getEntidadeHabilitaDesabilita(entidade: Entidade): Observable<Entidade> {
    const url = environment.apiUrl + '/entidades/habilitarDesabilitar';
    console.log('----------EntidadeService getEntidadeHabilitaDesabilita>>>>', url);
    return this.http.put<Entidade>(url, entidade);
  }

}
