import { Entidade } from './entidade.model';
import { PessoaFisica } from './pessoaFisica.model';
import { Anexo } from './anexo.model';
import { EnderecoEntidade } from './enderecoEntidade';
import { Produto } from './produto.model';

export interface ContratoInter {
  id: number;
  timestampAsString: string;
  dataInclusao: string;
  usuarioInclusao: string;
  usuarioAlteracao: string;
  contratada: Entidade;
  contratante: Entidade;
  numeroContrato: number;
  anoContrato: number;
  dataInicial: Date;
  dataFinal: Date;
  responsavelLegal: PessoaFisica;
  enderecoContratante: EnderecoEntidade;
  numeroSei: string;
  numeroDocumentoSei: string;
  situacao: string;
  rgCPFAnexo: Anexo;
  contratoSocialEstatutoAnexo: Anexo;
  procuracaoRepresentacaoAnexo: Anexo;
  relDivida: Anexo;
  boletoPago: Anexo;
  obsSacAnalise: string;
  obsRegularidadeDivida: string;
  obsSmcaComplementar: string;
  obsSmcaComplementada: string;
  obsSmcaVistoria: string;
  dataVistoria: Date;
  obsNccComplementar: string;
  obsNccComplementada: string;
  obsReprovado: string;
  usuarioAnaliseSac: string;
  usuarioAssinaturaEmpresa: string;
	dataAssinaturaEmpresa: Date;
	usuarioAssinaturaPmpa: string;
  residuosDOList: Produto[];
  isencaoTarifa: Boolean;
  isencaoDescargas: number;
  isencaoData: Date;
}

export class Contrato {
  id: number;
  timestampAsString: string;
  dataInclusao: string;
  usuarioInclusao: string;
  usuarioAlteracao: string;
  contratada: Entidade;
  contratante: Entidade;
  numeroContrato: number;
  anoContrato: number;
  dataInicial: Date;
  dataFinal: Date;
  responsavelLegal: PessoaFisica;
  enderecoContratante: EnderecoEntidade;
  numeroSei: string;
  numeroDocumentoSei: string;
  situacao: string;
  rgCPFAnexo: Anexo;
  contratoSocialEstatutoAnexo: Anexo;
  procuracaoRepresentacaoAnexo: Anexo;
  relDivida: Anexo;
  boletoPago: Anexo;
  obsSacAnalise: string;
  obsRegularidadeDivida: string;
  obsSmcaComplementar: string;
  obsSmcaComplementada: string;
  obsSmcaVistoria: string;
  dataVistoria: Date;
  obsNccComplementar: string;
  obsNccComplementada: string;
  obsReprovado: string;
  usuarioAnaliseSac: string;
  usuarioAssinaturaEmpresa: string;
	dataAssinaturaEmpresa: Date;
	usuarioAssinaturaPmpa: string;
  residuosDOList: Produto[];
  isencaoTarifa: Boolean;
  isencaoDescargas: number;
  isencaoData: Date;
}
