import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SmfPessoaService = /** @class */ (function () {
    function SmfPessoaService(http) {
        this.http = http;
    }
    SmfPessoaService.prototype.getPessoaFazenda = function (documento, tipoPessoa) {
        console.log('....................EntidadeService getPessoaJuridica>>>>', environment.apiUrl + '/pessoaFazenda/' + documento + '---' + tipoPessoa);
        return this.http.get(environment.apiUrl + '/pessoaFazenda/' + documento + '/' + tipoPessoa);
    };
    SmfPessoaService.ngInjectableDef = i0.defineInjectable({ factory: function SmfPessoaService_Factory() { return new SmfPessoaService(i0.inject(i1.HttpClient)); }, token: SmfPessoaService, providedIn: "root" });
    return SmfPessoaService;
}());
export { SmfPessoaService };
