import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Participacao } from 'src/app/@shared/models/participacao.model';
import { Anexo } from 'src/app/@shared/models/anexo.model';
import { Entidade } from 'src/app/@shared/models/entidade.model';
import { Meta } from 'src/app/@shared/models/meta.model';
import { Plano, InfoPlano } from 'src/app/@shared/models/plano.model';
import { Produto } from 'src/app/@shared/models/produto.model';
import { Termo } from 'src/app/@shared/models/termo.model';
import { KeyPair } from 'src/app/@shared/models/keyPair.model';
import { PlanoPrevistoRealizado } from 'src/app/@shared/models/planoPrevistoRealizado.model';

@Injectable({providedIn: 'root'})
export class PlanoService {

  constructor(private http: HttpClient) { }

  excluirParticipacao(idPart: number): Observable<Participacao> {
    const url = environment.apiUrl + '/planos/participacoes/' + idPart;
    return this.http.delete<Participacao>(url);
  }

  getAnexos(idPlano: number): Observable<Anexo[]> {
    const url = environment.apiUrl + '/planos/' + idPlano + '/anexos';
    return this.http.get<Anexo[]>(url);
  }

  salvarAnexo(idPlano: number, anexo: Anexo): Observable<Anexo> {
    console.log(anexo);
    const url = environment.apiUrl + '/planos/' + idPlano + '/anexos';
    return this.http.post<Anexo>(url, anexo);
  }

  setFile(idAnexo: number, dados: any): Observable<Object> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/octet-stream'
      })
    };

    const url = environment.apiUrl + '/anexos/' + idAnexo + '/file';
    const ret = this.http.put(url, dados, httpOptions);
    return ret;
  }

  excluirAnexo(idAnexo: number): Observable<Anexo> {
    console.log('aaa');
    console.log(idAnexo);
    const url = environment.apiUrl + '/anexos/' + idAnexo;
    return this.http.delete<Anexo>(url);
  }

  getParticipacoes(idPlano: number): Observable<Participacao[]> {
    const url = environment.apiUrl + '/planos/' + idPlano + '/participacoes';
    const ret = this.http.get<Participacao[]>(url);
    return ret;
  }

  salvarParticipacoes(idPlano: number, participacoes: any): Observable<Object> {
    const url = environment.apiUrl + '/planos/' + idPlano + '/participacoes';
    const ret = this.http.put(url, participacoes);
    return ret;
  }

  getEntidadePorIdOuUsername(idEntidade: number, username: string): any {
    const url = environment.apiUrl + '/entidades/idusername/' + idEntidade + '/' + username;
    return this.http.get<Entidade>(url);
  }

  getEntidadeDoPessoaFisica(user: string): Observable<Entidade> {
    const url = environment.apiUrl + 'entidades/username/' + user;
    return this.http.get<Entidade>(url);
  }

  salvarMetas(idPlano: number, metas: Meta[]): Observable<Object> {
    console.log('bbb');
    const url = environment.apiUrl + '/planos/' + idPlano + '/metas';
    const msg = this.http.put(url, metas);
    console.log('ccc');
    return msg;
  }

  aceitarPlano(idPlano: number): Observable<Plano> {
    const url = environment.apiUrl + '/planos/' + idPlano + '/aceitar';
    const o = this.http.get<Plano>(url);
    return o;
  }

  devolverPlano(idPlano: number): Observable<Plano> {
    const url = environment.apiUrl + '/planos/' + idPlano + '/devolver';
    const o = this.http.get<Plano>(url);
    return o;
  }

  enviarPlano(idPlano: number): Observable<Plano> {
    const url = environment.apiUrl + '/planos/' + idPlano + '/enviar';
    const o = this.http.put<Plano>(url, null);
    return o;
  }

  getProdutos(): Observable<Produto[]> {
    const url = environment.apiUrl + '/produtos';
    return this.http.get<Produto[]>(url);
  }

  getPlanos(): Observable<Plano[]> {
    return this.http.get<Plano[]>(environment.apiUrl + '/planos/');
  }

  getOperadores(): Observable<Entidade[]> {
    const url = environment.apiUrl + '/entidades/operadores';
    return this.http.get<Entidade[]>(url);
  }

/**
  getOperadoresDoPlano(idPlano:number): Observable<Entidade[]> {
    console.log('Vai chamar operadores do plano');
    const url = environment.apiUrl + '/planos/' + idPlano + '/operadores';
    const ret = this.http.get<Entidade[]>(url);
    console.log('Chamou operadores do plano ');
    return ret;
  }
 */
  getMetasDoPlanoPorAno(idPlano: number, anoReferencia: number): Observable<Meta[]> {
    const url = environment.apiUrl + '/planos/' + idPlano + '/' + anoReferencia + '/metas';
    return this.http.get<Meta[]>(url);
  }

  getMetaDoPlano(idPlano: number): Observable<Meta> {
    const url = environment.apiUrl + '/planos/' + idPlano + '/meta';
    return this.http.get<Meta>(url);
  }

  getTermo(idPlano: number): Observable<Termo> {
    const url = environment.apiUrl + '/planos/' + idPlano + '/termo';
    return this.http.get<Termo>(url);
  }


  getDestinadoresDoPlano(idPlano: number): Observable<Entidade[]> {
    const url = environment.apiUrl + '/planos/' + idPlano + '/destinadores';
    return this.http.get<Entidade[]>(url);
  }

  getDestinadores(): Observable<Entidade[]> {
    const url = environment.apiUrl + '/entidades/destinadores';
    return this.http.get<Entidade[]>(url);
  }


  getPlanosDaEntidade(idEntidade: number, anoReferencia: number): Observable<Plano[]> {
    const url = environment.apiUrl + '/planos/' + idEntidade + '/' + anoReferencia + '/planos';

    console.log('getPlanosDaEntidade....url.....>>>>>', url);

    return this.http.get<Plano[]>(url);
  }

  getPlanosDaEntidadeAnoAnterior(idEntidade: number): Observable<Plano[]> {
    const url = environment.apiUrl + '/planos/' + idEntidade + '/planosAnoAnterior';

    console.log('getPlanosDaEntidade....url.....>>>>>', url);

    return this.http.get<Plano[]>(url);
  }

  getPlanosRepresentados(): Observable<Plano[]> {
    const url = environment.apiUrl + '/planos/representados';
    const newLocal = this.http.get<Plano[]>(url);
    return newLocal;
  }


  getEntidade(id: number): Observable<Entidade> {
    const url = environment.apiUrl + '/entidades/' + id;
    return this.http.get<Entidade>(url);
  }

  getPlano(id: string): Observable<Plano> {
    return this.http.get<Plano>(environment.apiUrl + '/planos/');
  }

  criarPlano(infoPlano: InfoPlano): Observable<Plano> {

    // const p = new InfoPlano();
    // p.idProduto = plano.produtoDO.id;
    // p.idEntidade = plano.entidade.id;
    // p.descricao = plano.descricao;
    // p.acoes = plano.acoes;
    console.log('PlanoService criarPlano .................', infoPlano);

    return this.http.post<Plano>(environment.apiUrl + '/planos/', infoPlano);
  }

  anularPlano(plano: Plano): Observable<Plano> {
    return this.http.put<Plano>(environment.apiUrl + '/planos/anulacao', plano);
  }

  atualizarPlano(plano: Plano): Observable<Plano> {
    const p = new InfoPlano();
    p.id = plano.id;
    p.idProduto = plano.produtoDO.id;
    p.idEntidade = plano.entidadeDO.id;
    p.descricao = plano.descricao;
    return this.http.put<Plano>(environment.apiUrl + '/planos/', p);
  }

  excluirPlano(plano: Plano): Observable<Plano> {
    return this.http.delete<Plano>(environment.apiUrl + '/planos/' + plano.id);
  }

  // obtemTiposDeEntidade(): Observable<KeyPair[]> {
  //   const url = environment.apiUrl + '/entidades/tipos';
  //   return this.http.get<KeyPair[]>(url);
  // }

  // obtemPapeis(): Observable<KeyPair[]> {
  //   const url = environment.apiUrl + '/' + this.globals.tipoAcesso + '/app/papeis';
  //   return this.http.get<KeyPair[]>(url);
  // }

  atualizaPlanoAnexoPessoaFisica(): Observable<String> {
    console.log('atualizaPlanoAnexoPessoaFisica>>>>>>>>>>', environment.apiUrl + '/planos/atualizaPlanoAnexoPessoaFisica');
    return this.http.get<String>(environment.apiUrl + '/planos/atualizaPlanoAnexoPessoaFisica');
  }

  atualizaQtdAnuaisRealizadasPrevistas(planos: PlanoPrevistoRealizado[]): Observable<any[]> {
    console.log('PlanoService atualizaQtdAnuaisRealizadasPrevistas .................',
      environment.apiUrl + '/planos/atualizaQuantidadesAnuais', planos);
    return this.http.put<Plano[]>(environment.apiUrl + '/planos/atualizaQuantidadesAnuais', planos);
  }


}
