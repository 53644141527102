import { Component, OnInit, Inject, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { DOCUMENT } from '@angular/platform-browser';
import { Entidade } from '../../@shared/models/entidade.model';
import { EnderecoEntidade } from '../../@shared/models/enderecoEntidade';
import { EntidadeService } from '../../@core/entidade/entidade.service';
import { EnderecoService } from '../../@core/services/endereco.service';
import { Globals } from '../../globals';
import { NotificacoesService } from '../../@core/services/notificacoes.service';
import { SpinnerService } from '../../@core/services/spinner.service';
import { VeiculoService } from '../../@core/services/veiculo.service';
import { Veiculo } from '../../@shared/models/veiculo.model';
import { PessoaFisica } from '../../@shared/models/pessoaFisica.model';
import { PessoaFisicaService } from 'src/app/@core/services/pessoaFisica.service';
import { THROW_IF_NOT_FOUND } from '@angular/core/src/di/injector';

@Component({
  selector: 'sgr-sgrInicial',
  templateUrl: './sgrInicial.component.html',
  styleUrls: ['./sgrInicial.component.scss']
})
export class SgrInicialComponent {
  //private username: string;
  items: MenuItem[];
  // entidadeMtrcc: boolean = false;
  nomeUsuario: string;
  usuarioAutenticado: boolean;
  // entidadeRepresentada: Entidade;
  // enderecosEntidadeRepresentada: EnderecoEntidade[] = [];
  // veiculosEntidadeRepresentada: Veiculo[] = [];
  pessoasFisicas: PessoaFisica[] = [];
  pesFisica: PessoaFisica;
  variaveisGlobais;
  // entidadeMtrcc: boolean;
  pessoaAtiva: Boolean = false;
  isAutorizado: Boolean = true;
  mensPessoaInativa = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private entidadeService: EntidadeService,
    private pessoaFisicaService: PessoaFisicaService,
    private enderecoService: EnderecoService,
    private veiculoService: VeiculoService,
    // tslint:disable-next-line: deprecation
    @Inject(DOCUMENT) private document,
    // private menuService: MenuService,
    private keycloakService: KeycloakService,
    private globals: Globals,
    private spinnerService: SpinnerService,
    private notificacoesService: NotificacoesService
  ) {}

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit() {
    
    this.variaveisGlobais = this.globals;
    console.log('BBBBB...SgrInicialComponent................>>>>>>', this.variaveisGlobais);

    setTimeout(() => {


          this.spinnerService.ocultar();

          this.isAutorizado = true;
          if (!this.variaveisGlobais.roleEntidade) {
            if (this.keycloakService.hasRole('administrador')) {
              this.isAutorizado = true;
            } else {
              this.isAutorizado = false;
            }
          }

          if (this.isAutorizado) {

            this.variaveisGlobais.menuRaiz = this.router.url.split('/')[1];

            // if (this.keycloakService.hasRole("Entidade")) {
              //   this.globals.roleEntidade = true;
              // }
            this.pessoasFisicas = [];

            // for (let index = 0; index < this.variaveisGlobais.pessoasFisicas.length; index++) {
            //   const pf = this.variaveisGlobais.pessoasFisicas[index];
            //   console.log('*******SgrInicialComponent.. foreach..............>>>>>>', pf);
            //   this.pessoasFisicas.push(pf);
            // }
            this.pessoasFisicas = this.variaveisGlobais.pessoasFisicas;
              // console.log('ZZZZ...SgrInicialComponent................>>>>>>', this.pessoasFisicas);
            this.pessoaAtiva = false;
            if (this.pessoasFisicas.length > 1) {
              // console.log('XXXXXXXX...SgrInicialComponent................>>>>>>', this.variaveisGlobais.pessoaFisica);

              if (this.variaveisGlobais.pessoaFisica !== null) {
                this.pessoaAtiva = false;
                if (this.variaveisGlobais.entidades !== null) {
                  this.pessoaAtiva = true;
                }
              }


            } else {

              if (this.globals.pessoaFisica === null) {
                this.globals.recuperaKeycloak().subscribe(
                  user => {
                    this.nomeUsuario = this.globals.userFullName;

                    if (this.globals.roleEntidade) {
                      console.log('AAAAAA--------------000SgrInicialComponent.............recuperaEntidadePessoaFisica--', this.globals);
                      this.globals.recuperaEntidadePessoaFisica().subscribe(
                        entidade => {
                          this.variaveisGlobais = this.globals;
                          this.pessoaAtiva = false;
                          if (this.variaveisGlobais.entidades !== null) {
                            this.pessoaAtiva = true;
                          }

                      });
                    } else {
                      this.variaveisGlobais = this.globals;
                      this.pessoaAtiva = false;
                      if (this.variaveisGlobais.entidades !== null) {
                        this.pessoaAtiva = true;
                      }
                    }
                  });
              } else {
                this.pessoaFisicaService.getPessoaFisicaAtiva(this.globals.pessoaFisica.id).subscribe(pf => {

                  // console.log('######################SgrInicialComponent................>>>>>>', pf);
                  if (pf !== null) {
                    if (!pf.ativo) {
                      this.isAutorizado = false;
                    }

                  }

                  if (this.isAutorizado) {
                    this.variaveisGlobais = this.globals;
                    this.pessoaAtiva = false;
                    if (this.variaveisGlobais.entidades !== null) {
                      this.pessoaAtiva = true;
                    }
                  }
                });
              }

            }

            // console.log('Entidade>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>......');
          }




    }, 1000);



  }

  logout() {
    const url =
      document.location.protocol +
      '//' +
      document.location.hostname +
      ':' +
      document.location.port;
    this.keycloakService.getKeycloakInstance().logout({ redirectUri: url });
  }

  selecionaNavegacao(navegacao) {

    var caminho = '/' + navegacao;
    var mensagem = '';

    if (this.variaveisGlobais.roleEntidade) {
      if (navegacao === 'mtrcc') {
        caminho = caminho + '/manifestos';
      }
    }

    // console.log('selecionaNavegacao>>>>>......', caminho);
    this.router.navigate([caminho]);

    // this.router.navigate([caminho]);
  }
  controlaSelUsuario(idPessoaFisica) {

    this.pessoaFisicaService.getPessoaFisicaAtiva(idPessoaFisica).subscribe(pf => {

      // console.log('######################SgrInicialComponent................>>>>>>', pf);
      if (pf !== null) {
        if (!pf.ativo) {
          this.isAutorizado = false;
        }

      }

      if (this.isAutorizado) {
        this.entidadeService.getEntidadesDaPessoaFisica(idPessoaFisica).subscribe(entidades => {
          this.pessoasFisicas.forEach(pessoaF => {
            if (pessoaF.id === idPessoaFisica) {
              this.globals.pessoaFisica = pessoaF;
            }
          });



          this.globals.entidades = entidades;
          if (entidades.length === 1) {
            this.globals.entidade = entidades[0];
          }

          this.variaveisGlobais = this.globals;
          this.pessoaAtiva = false;
          // console.log('Globals>>>>>>>>>buscaEntidades>>>>', entidades);
          if (this.variaveisGlobais.entidades !== null) {
            this.pessoaAtiva = true;
          }
        });
      }
    });
  }

  usuarioAtivo(idPessoaFisica) {

      this.pessoaFisicaService.getPessoaFisicaAtiva(idPessoaFisica).subscribe(pf => {

        // console.log('######################SgrInicialComponent................>>>>>>', pf);
        if (pf !== null) {
          if (!pf.ativo) {
            this.isAutorizado = false;
          }

        }

      });

  }

}
