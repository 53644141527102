
export interface Entidade {
  id: number;
  cnpj: string;
  razaoSocial: string;
  nomeFantasia?: string;
  numeroCnae?: string;
  descricaoCnae?: string;
  telefone?: string;
  email?: string;
  codigoDmlu: number;
  adimplenteDmlu: boolean;
  dataInclusao: Date;
  timestampAsString?: string;
  usuarioInclusao: string;
  usuarioAlteracao?: string;
  qtdPlanosEdicao?: number;
  possuiPlanos?: boolean;
  // nomeSisLog: string;
  licencaAmbiental: string;
  licencaAmbientalOrgaoEmissor: string;
  licencaAmbientalDataValidade: Date;
  alvara: string;
  gdrCertificado: number;
  gdrNumeroContrato: string;
  gdrAnoContrato: string;
  gdrSituacaoContrato: string;
  desabilitadaMtrcc: boolean;
}


export class EntidadeClass {
  id: number;
  cnpj: string;
  razaoSocial: string;
  nomeFantasia?: string;
  numeroCnae?: string;
  descricaoCnae?: string;
  telefone?: string;
  email?: string;
  codigoDmlu: number;
  adimplenteDmlu: boolean;
  dataInclusao: Date;
  timestampAsString?: string;
  usuarioInclusao: string;
  usuarioAlteracao?: string;
  qtdPlanosEdicao?: number;
  possuiPlanos?: boolean;
  // nomeSisLog: string;
  licencaAmbiental: string;
  licencaAmbientalOrgaoEmissor: string;
  licencaAmbientalDataValidade: Date;
  alvara: string;
  gdrCertificado: number;
  gdrNumeroContrato: string;
  gdrAnoContrato: string;
  gdrSituacaoContrato: string;
  desabilitadaMtrcc: boolean;
}
