var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { EntidadeService } from '../entidade/entidade.service';
import { SpinnerService } from '../services/spinner.service';
import { NotificacoesService } from '../services/notificacoes.service';
import { Globals } from 'src/app/globals';
import { LicencaAmbiental } from 'src/app/@shared/models/licencaAmbiental.model';
import { LicencaAmbientalService } from '../services/licencaAmbiental.service';
import { SecretariaPmpaService } from '../services/secretariaPmpa.service';
var LicencaAmbientalComponent = /** @class */ (function () {
    function LicencaAmbientalComponent(router, keycloakService, licencaAmbientalService, entidadeService, secretariaPmpaService, spinnerService, notificacoesService, globals) {
        this.router = router;
        this.keycloakService = keycloakService;
        this.licencaAmbientalService = licencaAmbientalService;
        this.entidadeService = entidadeService;
        this.secretariaPmpaService = secretariaPmpaService;
        this.spinnerService = spinnerService;
        this.notificacoesService = notificacoesService;
        this.globals = globals;
        this.licencasAmbientais = [];
        this.mostrarLicencas = false;
        //Secretarias
        this.secretarias = [];
        this.secretariasPmpa = [];
        this.idSecretaria = null;
        this.activeRoute = this.router.url;
        this.variaveisGlobais = globals;
    }
    LicencaAmbientalComponent.prototype.ngOnInit = function () {
        if (this.variaveisGlobais.menuRaiz === null || this.variaveisGlobais.menuRaiz === '') {
            this.router.navigate(['/sgrIni']);
        }
        else {
            this.inicializaOnInit();
        }
    };
    LicencaAmbientalComponent.prototype.inicializaOnInit = function () {
        var _this = this;
        this.spinnerService.exibir();
        this.licencaAmbiental = new LicencaAmbiental();
        this.entidade = null;
        this.entidadeService.getEntidadeCnpj('92.963.560/0001-60').subscribe(function (entidade) {
            _this.entidade = entidade;
            _this.licencaAmbientalService.getLicencasPorEntidade(entidade.id).subscribe(function (licencas) {
                _this.spinnerService.ocultar();
                _this.licencasAmbientais = licencas;
            });
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
        this.secretariasPmpa = [];
        this.secretariaPmpaService.getSecretariasPmpa().subscribe(function (secretarias) {
            _this.secretariasPmpa = secretarias;
            secretarias.forEach(function (sec) {
                _this.secretarias.push({ label: sec.sigla, value: sec });
            });
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    LicencaAmbientalComponent.prototype.checkActive = function (route) {
        return route === this.activeRoute;
    };
    LicencaAmbientalComponent.prototype.mostrarDialogParaNovo = function () {
        this.licencaAmbiental = new LicencaAmbiental();
        this.mostrarDialogEdit = true;
        this.isInsert = true;
        this.tituloDialogEdit = 'Nova Licença Ambiental';
    };
    LicencaAmbientalComponent.prototype.mostrarDialogParaEditar = function (idLicenca) {
        var licencaParaEditar = this.licencasAmbientais.find(function (licencaAmbiental) {
            return licencaAmbiental.id === idLicenca;
        });
        this.licencaAmbiental = __assign({}, licencaParaEditar);
        console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.licencaAmbiental);
        this.mostrarDialogEdit = true;
        this.isInsert = false;
        this.tituloDialogEdit = 'Editar Licença Ambiental';
        this.licencaAmbiental.dataValidade = new Date(this.licencaAmbiental.dataValidade);
    };
    LicencaAmbientalComponent.prototype.salvar = function () {
        var _this = this;
        //Verifica se todos os campos foram preenchidos
        if (this.licencaAmbiental.licenca == null || this.licencaAmbiental.licenca === '') {
            this.notificacoesService.notificarAviso('Por favor informar a LICENÇA!', null);
            return;
        }
        if (this.licencaAmbiental.dataValidade == null) {
            this.notificacoesService.notificarAviso('Por favor informar a VALIDADE da Licença!', null);
            return;
        }
        if (this.licencaAmbiental.secretariaPmpaDO == null) {
            this.notificacoesService.notificarAviso('Por favor informar o ÓRGAO EMISSOR da Licença!', null);
            return;
        }
        this.spinnerService.exibir();
        this.licencaAmbiental.entidadeDO = this.entidade;
        this.licencaAmbiental.orgaoEmissor = this.licencaAmbiental.secretariaPmpaDO.sigla;
        console.log('salvar....................>>>>>>>>>>>>>>', this.licencaAmbiental);
        // Se novo
        if (this.licencaAmbiental.id == null) {
            this.licencaAmbientalService.criarLicenca(this.licencaAmbiental).subscribe(function (laNovo) {
                console.log('salvado....................>>>>>>>>>>>>>>', laNovo);
                _this.licencasAmbientais.push(laNovo);
                _this.mostrarDialogEdit = false;
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarSucesso('Adicionado com sucesso', null);
            }, function (error) {
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro('Erro ao adicionar ', error);
            });
        }
        else { // Se editando
            this.licencaAmbientalService.editarLicenca(this.licencaAmbiental).subscribe(function (laEditado) {
                // Remove o valor atual
                _this.licencasAmbientais = _this.licencasAmbientais.filter(function (item) { return item.id !== _this.licencaAmbiental.id; });
                // Adiciona o novo
                _this.licencasAmbientais.push(laEditado);
                _this.mostrarDialogEdit = false;
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarSucesso('Atualizado com sucesso', null);
            }, function (error) {
                console.log(error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    return LicencaAmbientalComponent;
}());
export { LicencaAmbientalComponent };
