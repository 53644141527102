import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FieldsetModule } from "primeng/fieldset";
import { InputMaskModule } from "primeng/inputmask";
import { PanelModule } from "primeng/panel";
import {
  ButtonModule,
  CardModule,
  DropdownModule,
  EditorModule,
  InputTextModule,
  MenubarModule,
  ProgressSpinnerModule,
  TooltipModule,
  RadioButtonModule
} from "primeng/primeng";
import { CoreModule } from "../@core/core.module";
import { MenuModuleSGR } from "../menu/menu.module";
import { CadastroComponent } from "./cadastro/cadastro.component";
// import { EscolhaComponent } from "./escolha/escolha.component";
import { PrestadoraServicoComponent } from "./prestadoraServico/prestadoraServico.component";
import { SgrInicialComponent } from "./sgrInicial/sgrInicial.component";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PanelModule,
    FieldsetModule,
    InputTextModule,
    InputMaskModule,
    DropdownModule,
    ButtonModule,
    MenubarModule,
    TooltipModule,
    RadioButtonModule,
    EditorModule,
    ProgressSpinnerModule,
    CardModule,
    CoreModule,
    MenuModuleSGR
  ],
  declarations: [
    // EscolhaComponent,
    CadastroComponent,
    SgrInicialComponent,
    PrestadoraServicoComponent
  ],
  providers: []
})
export class InicialModule {}
