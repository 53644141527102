import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PessoaFisicaService = /** @class */ (function () {
    function PessoaFisicaService(http) {
        this.http = http;
    }
    PessoaFisicaService.prototype.getPessoaFisicaPesquisa = function (criterioPesquisa, tipoCriterioPesquisa) {
        console.log('getPessoaFisicaPesquisa 22>>>', criterioPesquisa);
        console.log('getPessoaFisicaPesquisa 33>>>', tipoCriterioPesquisa);
        return this.http.get(environment.apiUrl + '/pessoaFisica/pesquisa/'
            + '?criterioPesquisa=' + criterioPesquisa + '&tipoCriterioPesquisa=' + tipoCriterioPesquisa);
    };
    PessoaFisicaService.prototype.getPessoaFisicaPorEmail = function (email) {
        console.log('getPessoaFisicaPorEmail>>>>>>>>>>', environment.apiUrl + '/pessoaFisica/email/' + email);
        return this.http.get(environment.apiUrl + '/pessoaFisica/email/' + email);
    };
    PessoaFisicaService.prototype.getPessoaFisicaRTPorEntidade = function (id, tipoRT) {
        var url = environment.apiUrl + '/pessoaFisica/' + id + '/' + tipoRT + '/rt/entidade';
        console.log('getPessoaFisicaPorEntidade Service >>>>>>', url);
        return this.http.get(url);
    };
    PessoaFisicaService.prototype.getPessoasFisicasDaEntidade = function (idEntidade) {
        var url = environment.apiUrl + '/pessoaFisica/entidade/' + idEntidade;
        return this.http.get(url);
    };
    PessoaFisicaService.prototype.getPessoasFisicasResponsavelEntidade = function (idEntidade, cnpj) {
        var cnpjSemFormatacao = cnpj.replace('/', '.');
        cnpjSemFormatacao = cnpjSemFormatacao.replace('-', '.');
        var url = environment.apiUrl + '/pessoaFisica/entidade/tipoResp/' + idEntidade + '/' + cnpjSemFormatacao;
        return this.http.get(url);
    };
    PessoaFisicaService.prototype.getPessoasFisicasResponsavelLegalEntidade = function (idEntidade) {
        var url = environment.apiUrl + '/pessoaFisica/entidade/responsavelLegal/' + idEntidade;
        return this.http.get(url);
    };
    PessoaFisicaService.prototype.getPessoasFisicasEntidadeRL = function (idEntidade, idPessoaFisica) {
        var url = environment.apiUrl + '/pessoaFisica/entidade/responsavelLegal/' + idEntidade + '/' + idPessoaFisica;
        return this.http.get(url);
    };
    PessoaFisicaService.prototype.getPessoasFisicasPorCpf = function (cpf) {
        var url = environment.apiUrl + '/pessoaFisica/cpf/' + cpf;
        return this.http.get(url);
    };
    PessoaFisicaService.prototype.criarPessoaFisica = function (pessoaFisica) {
        return this.http.post(environment.apiUrl + '/pessoaFisica/', pessoaFisica);
    };
    PessoaFisicaService.prototype.cadastroPessoaFisica = function (pessoaFisica) {
        return this.http.post(environment.apiUrl + '/pessoaFisica/cadastro/', pessoaFisica);
    };
    PessoaFisicaService.prototype.atualizarPessoaFisica = function (pessoaFisica) {
        console.log('----------PessoaFisicaService atualizarPessoaFisica>>>>', pessoaFisica);
        return this.http.put(environment.apiUrl + '/pessoaFisica/', pessoaFisica);
    };
    PessoaFisicaService.prototype.inativarPessoaFisica = function (pessoaFisica) {
        var url = environment.apiUrl + '/pessoaFisica/inativar';
        console.log('----------PessoaFisicaService inativarPessoaFisica>>>>', url);
        return this.http.put(url, pessoaFisica);
    };
    PessoaFisicaService.prototype.geraRelacionamentoPJPF = function (pessoaFisica) {
        return this.http.put(environment.apiUrl + '/pessoaFisica/geraRelacionamento/', pessoaFisica);
    };
    PessoaFisicaService.prototype.getRTPessoasFisicasDoPlano = function (id) {
        return this.http.get(environment.apiUrl + '/planopessoafisica/pessoasfisicas/' + id);
    };
    PessoaFisicaService.prototype.getPessoaFisicaAtiva = function (id) {
        return this.http.get(environment.apiUrl + '/pessoaFisica/ativa/' + id);
    };
    PessoaFisicaService.prototype.getResponsavelLegalContrato = function (idContrato) {
        return this.http.get(environment.apiUrl + '/pessoaFisica/contratoDmlu/' + idContrato);
    };
    PessoaFisicaService.ngInjectableDef = i0.defineInjectable({ factory: function PessoaFisicaService_Factory() { return new PessoaFisicaService(i0.inject(i1.HttpClient)); }, token: PessoaFisicaService, providedIn: "root" });
    return PessoaFisicaService;
}());
export { PessoaFisicaService };
