import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { MenuItem, SelectItem } from 'primeng/api';
import { Entidade } from 'src/app/@shared/models/entidade.model';
import { EntidadeService } from '../../entidade/entidade.service';
import { ProdutoService } from '../produto.service';
import { Produto } from 'src/app/@shared/models/produto.model';
import { Classificacao } from 'src/app/@shared/models/classificacao.model';
import { SpinnerService } from '../../services/spinner.service';
import { NotificacoesService } from '../../services/notificacoes.service';
import { ClassificacaoService } from '../../services/classificacao.service';
import { Globals } from 'src/app/globals';

@Component({
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListaProdutosComponent implements OnInit {

  produtos: Produto[];
  items: MenuItem[];
  nomeUsuario: string;
  entidadeRepresentada: Entidade;
  entidadeNome = '';
  // Novo/edit
  produtoEdit: Produto;
  mostrarDialogEdit: boolean;
  tituloDialogEdit: string;
  unidadesMedida: SelectItem[];
  classificacao: Classificacao[];
  classificacoes: SelectItem[];
  selClassificacao: string;

  tipoListas: SelectItem[];
  selectedTipos: string[];
  gdrDestino: String = '';
  // Exluir
  produtoExcluir: Produto;
  mostrarDialogExcluir: boolean;

  variaveisGlobais: Globals;
  activeRoute: string;

  constructor(
    private router: Router,
    private keycloakService: KeycloakService,
    private produtoService: ProdutoService,
    private entidadeService: EntidadeService,
    private spinnerService: SpinnerService,
    private notificacoesService: NotificacoesService,
    private classificacaoService: ClassificacaoService,
    private globals: Globals
  ) {

    this.activeRoute = this.router.url;


    this.tipoListas = [
      { label: 'LRR', value: 'lrr' },
      { label: 'MTRCC', value: 'mtrcc' }
    ];
    this.variaveisGlobais = globals;
  }

  ngOnInit() {

    console.log('000ListaProdutosComponent>>>>>>>>>>>', this.variaveisGlobais);

    if (this.variaveisGlobais.menuRaiz === null || this.variaveisGlobais.menuRaiz === '') {
      this.router.navigate(['/sgrIni']);
    } else {
      this.inicializaOnInit();
    }

  }

  inicializaOnInit() {
    this.spinnerService.exibir();

    // Carrega valores para unidades de medida
    // this.unidadesMedida = [];
    // for (const tipo of Object.keys(TipoUnidadeMedida)) {
    //   this.unidadesMedida.push( {label: TipoUnidadeMedida[tipo], value: tipo});
    // }

    // Carrega valor inicial para projetos
    // if (this.variaveisGlobais.menuRaiz === 'mtrcc') {
    this.produtoService.getProdutos(this.variaveisGlobais.menuRaiz, this.variaveisGlobais.roleEntidade).subscribe(produtos => {
      this.spinnerService.ocultar();
      this.produtos = produtos;
      console.log('Produtos>>>>>>>>', produtos);
    });
    // } else if (this.variaveisGlobais.menuRaiz === 'grandeGerador') {
    //   this.produtoService.getProdutos().subscribe(produtos => {
    //     this.spinnerService.ocultar();
    //     this.produtos = produtos;
    //   });
    //  }

    // Carrega valores para classificações
    this.classificacoes = [];
    // Carrega valor inicial para projetos
    this.classificacaoService.getClassificacoes().subscribe(classificacoes => {
      this.spinnerService.ocultar();
      this.classificacao = classificacoes;
      classificacoes.forEach(clas => {
        this.classificacoes.push({ label: clas.classificacao, value: clas.classificacao });
      });
    },
      error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });

  }

  logout() {
    const url = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
    this.keycloakService.getKeycloakInstance().logout({ redirectUri: url });
  }

  // public getUnidade(produto: Produto) {
  //   return produto.unidadeMedida + ' (' + TipoUnidadeMedida[produto.unidadeMedida] + ')';
  // }

  // public getUnidadeAbreviada(produto: Produto) {
  //   return TipoUnidadeMedida[produto.unidadeMedida];
  // }

  mostrarDialogParaNovo() {

    this.produtoEdit = new Produto();
    this.mostrarDialogEdit = true;

    if (this.variaveisGlobais.menuRaiz === 'grandeGerador'
      || this.variaveisGlobais.menuRaiz === 'mtrcc') {
      this.tituloDialogEdit = 'Novo Resíduo';
    } else {
      this.tituloDialogEdit = 'Novo Produto';
    }
  }

  mostrarDialogParaEditar(idProduto: number) {
    const produtoParaEditar = this.produtos.find(function (produto) {
      return produto.id === idProduto;
    });
    this.produtoEdit = { ...produtoParaEditar };
    // console.log('mostrarDialogParaEditar>>>>classificação>>>>>>>>>>', this.classificacoes);
    console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.produtoEdit);
    if (this.produtoEdit.classificacaoDO != null) {
      this.selClassificacao = this.produtoEdit.classificacaoDO.classificacao;
    }
    console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.produtoEdit);

    this.mostrarDialogEdit = true;
    if (this.produtoEdit.gdrTransbordo) {
      this.gdrDestino = 'transbordo'
    }
    if (this.produtoEdit.gdrCompostagem) {
      this.gdrDestino = 'compostagem'
    }
    if (this.produtoEdit.gdrTriagem) {
      this.gdrDestino = 'triagem'
    }

    if (this.variaveisGlobais.menuRaiz === 'grandeGerador'
      || this.variaveisGlobais.menuRaiz === 'mtrcc') {
      this.tituloDialogEdit = 'Editar Resíduo';
    } else {
      this.tituloDialogEdit = 'Editar Produto pós-consumo';
    }
  }

  mostrarDialogEditParaExcluir(idProduto: number) {
    this.produtoExcluir = this.produtos.find(function (produto) {
      return produto.id === idProduto;
    });
    this.mostrarDialogExcluir = true;
  }

  salvar() {

    // console.log('tipoListas>>>>>>>>>>>>>>', this.selectedTipos);
    // Verifica se todos os campos foram preenchidos
    if (this.produtoEdit.nome == null || this.produtoEdit.nome === ''
      || this.produtoEdit.descricao == null || this.produtoEdit.descricao === ''
      // || this.selectedTipos == null || this.selectedTipos.length === 0
      // || this.produtoEdit.unidadeMedida == null
    ) {
      this.notificacoesService.notificarErro('Preencha todos os campos', null);
      return;
    }
    if (this.variaveisGlobais.menuRaiz === 'gdr') {
      if (this.gdrDestino === '') {
        this.notificacoesService.notificarErro('Preencha a unidade de destino', null);
        return;
      }
    }

    this.spinnerService.exibir();


    if (this.variaveisGlobais.menuRaiz === 'mtrcc') {
      this.produtoEdit.mtrcc = true;
      this.produtoEdit.lrr = false;
      this.produtoEdit.gg = false;
      this.produtoEdit.gdrTransbordo = false;
      this.produtoEdit.gdrCompostagem = false;
      this.produtoEdit.gdrTriagem = false;
    } else if (this.variaveisGlobais.menuRaiz === 'grandeGerador') {
      this.produtoEdit.mtrcc = false;
      this.produtoEdit.lrr = false;
      this.produtoEdit.gg = true;
      this.produtoEdit.gdrTransbordo = false;
      this.produtoEdit.gdrCompostagem = false;
      this.produtoEdit.gdrTriagem = false;
    } else if (this.variaveisGlobais.menuRaiz === 'lrr') {
      this.produtoEdit.mtrcc = false;
      this.produtoEdit.lrr = true;
      this.produtoEdit.gg = false;
      this.produtoEdit.gdrTransbordo = false;
      this.produtoEdit.gdrCompostagem = false;
      this.produtoEdit.gdrTriagem = false;
    } else if (this.variaveisGlobais.menuRaiz === 'gdr') {
      this.produtoEdit.mtrcc = false;
      this.produtoEdit.lrr = false;
      this.produtoEdit.gg = false;
      this.produtoEdit.gdrTransbordo = false;
      this.produtoEdit.gdrCompostagem = false;
      this.produtoEdit.gdrTriagem = false;
      if (this.gdrDestino === 'transbordo') {
        this.produtoEdit.gdrTransbordo = true;
      }
      if (this.gdrDestino === 'compostagem') {
        this.produtoEdit.gdrCompostagem = true;
      }
      if (this.gdrDestino === 'triagem') {
        this.produtoEdit.gdrTriagem = true;
      }
    }

    if (this.variaveisGlobais.menuRaiz === 'mtrcc') {
      this.classificacao.forEach(clas => {
        if (clas.classificacao === this.selClassificacao) {
          this.produtoEdit.classificacaoDO = clas;
        }
      });
    }

    console.log('salvar....................>>>>>>>>>>>>>>', this.produtoEdit);

    // Se novo
    if (this.produtoEdit.id == null) {
      this.produtoService.criarProduto(this.produtoEdit).subscribe(produtoNovo => {
        this.produtos.push(produtoNovo);

        this.mostrarDialogEdit = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso('Adicionado com sucesso', null);
      },
        error => {
          this.spinnerService.ocultar();
          this.notificacoesService.notificarErro('Erro ao adicionar ', error);
        });
    } else { // Se editando
      this.produtoService.editarProduto(this.produtoEdit).subscribe(produtoEditado => {
        // Remove o valor atual
        this.produtos = this.produtos.filter(item => item.id !== this.produtoEdit.id);

        // Adiciona o novo
        this.produtos.push(produtoEditado);

        this.mostrarDialogEdit = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso('Atualizado com sucesso', null);
      },
        error => {
          console.log(error);
          this.spinnerService.ocultar();
          this.notificacoesService.notificarErro(error.error, null);
        });
    }
  }

  isSMAMS(): boolean {
    // const ret = KeycloakService.keycloakAuth.hasResourceRole('SMAMS');
    // return ret;
    const ret = this.keycloakService.hasRole('SMAMS');
    return ret;
  }

  excluir() {
    this.spinnerService.exibir();
    console.log('salvar....................>>>>>>>>>>>>>>', this.variaveisGlobais.menuRaiz);

    this.produtoService.excluirProduto(this.produtoExcluir, this.variaveisGlobais.menuRaiz).subscribe(produto => {
      if (produto == null) {
        // Remove o valor atual
        this.produtos = this.produtos.filter(item => item.id !== this.produtoExcluir.id);
      }
      this.mostrarDialogExcluir = false;
      this.spinnerService.ocultar();
      this.notificacoesService.notificarSucesso('Excluído com sucesso', null);
    },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro('Erro ao exluir', error.error);
      });
  }


  checkActive(route: string) {
    return route === this.activeRoute;
  }

}
