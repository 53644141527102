import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { FieldsetModule } from 'primeng/fieldset';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';
// import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule, CardModule, DialogModule, DropdownModule, EditorModule, InputTextModule,
  MenubarModule, ProgressSpinnerModule, MultiSelectModule } from 'primeng/primeng';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { CdlLibModule } from 'procempa-cdl-lib';
import { MenuModuleSGR } from '../menu/menu.module';
import { SGRPipesModule } from '../pipes/pipes.module';
import { ListaManifestosComponent } from './lista/lista.component';
import { ResultadosManifestosComponent } from './resultados/resultadosManifestos.component';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      ButtonModule,
      ProgressSpinnerModule,
      InputTextareaModule,
      RadioButtonModule,
      InputMaskModule,
      CalendarModule,
      MultiSelectModule,
      TabViewModule,
      MessageModule,
      InputTextModule,
      AccordionModule,
      FieldsetModule,
      DropdownModule,
      DialogModule,
      CheckboxModule,
      MenubarModule,
      EditorModule,
      CardModule,
      TableModule,
      CdlLibModule,
      MenuModuleSGR,
      SGRPipesModule

    ],
    declarations: [
      ListaManifestosComponent,
      ResultadosManifestosComponent
    ],
    providers: []
  })
export class ManifestoModule { }
