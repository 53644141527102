<sgr-menu></sgr-menu>
<div class="container">

  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/sgrIni">SGR</a></li>
    <li class="breadcrumb-item active" aria-current="page">Cadastro</li>
  </ol> 

  <h1 class="page-header text-primary">
    Obras<br>
    <small>Cadastro</small>
  </h1>
  <!-- 
  <h2
    class="text-primary mt-4 mb-0 pb-4"
    style="font-family: 'Roboto', 'Trebuchet MS', Arial, Helvetica, sans-serif"
  >
    Obra
  </h2> -->

  <div>
    <p-table #tabelaDestinosFinais [value]="obrasPmpa" [paginator]="true" sortField="nome" [rows]="10"
      [globalFilterFields]="['nome', 'status', 'executora.razaoSocial', 'endereco.enderecoFormatadoCurto']">
      <ng-template pTemplate="caption">
        <div class="row justify-content-between">
          <div class="col-auto form-group">
            <button type="button" class="btn btn-primary text-nowrap" (click)="mostrarDialogParaNovo()"
              label="Adicionar" *ngIf="!variaveisGlobais.roleEntidade">
              <i class="fa fa-plus pr-2"></i>Adicionar
            </button>
          </div>
          <div class="col-md-6 form-group busca">
            <input type="text" pInputText size="50" placeholder="Filtrar obras"
              (input)="tabelaDestinosFinais.filterGlobal($event.target.value, 'contains')" class="form-control" />
              <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="nome" class="text-center" style="width: 200px; height: 60px;">
            Nome Obra
            <p-sortIcon field="nome"></p-sortIcon>
          </th>
          <th pSortableColumn="status" class="text-center" style="width: 100px; height: 60px;">
            Status
            <p-sortIcon field="status"></p-sortIcon>
          </th>
          <th pSortableColumn="executora.razaoSocial" class="text-center" style="width: 300px; height: 60px;">
            Executora
            <p-sortIcon field="executora.razaoSocial"></p-sortIcon>
          </th>
          <th pSortableColumn="endereco.enderecoFormatadoCurto" class="text-center" style="width: 300px; height: 60px;">
            Endereço
            <p-sortIcon field="endereco.enderecoFormatadoCurto"></p-sortIcon>
          </th>
          <th style="width: 150px;" class="text-center">
            Ações
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-obraPmpa>
        <tr>
          <td>
            {{ obraPmpa?.nome }}
          </td>
          <td>
            {{ obraPmpa?.status }}
          </td>
          <td>
            {{ obraPmpa?.executora?.razaoSocial }}
          </td>
          <td>
            {{ obraPmpa?.endereco?.enderecoFormatadoCurto }}
          </td>
          <td>
            <button title="Editar" (click)="mostrarDialogParaEditar(obraPmpa.id)" label="Detalhes"
              class="btn btn-outline-secondary mx-2">
              <i class="fa fa-info pr-2"></i>Detalhes
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<!-- Dialog para novo -->
<p-dialog [header]="tituloDialogEdit" [(visible)]="mostrarDialogEdit" [responsive]="true" showEffect="fade"
  [modal]="true" [width]="700">
  <div class="row">
    <div class="col-auto form-group">
      <label for="vin">Nome da Obra</label>
      <input pInputText id="nome" [(ngModel)]="obraPmpaEdit.nome" size="30" class="form-control"/>
    </div>
    <div class="col-auto form-group">
      <label for="vin">Status</label>
      <div class="radios-inline">
        <p-radioButton name="status" value="Ativa" label="Ativa" [(ngModel)]="obraPmpaEdit.status"></p-radioButton>
        <p-radioButton name="status" value="Paralisada" label="Paralisada" [(ngModel)]="obraPmpaEdit.status">
        </p-radioButton>
        <p-radioButton name="status" value="Concluída" label="Concluida" [(ngModel)]="obraPmpaEdit.status">
        </p-radioButton>
      </div>
    </div>
    <div class="col-12 form-group">
      <label for="vin">Observação</label>
      <textarea pInputTextarea id="observacao" [(ngModel)]="obraPmpaEdit.observacao" [rows]="3" class="form-control"></textarea>
    </div>

    <fieldset class="col-12">
      <legend>Endereço</legend>
      <div class="row procempaCdl" procempaCdl [maxResults]="18" [codigo]="logradouroCdl?.codigoLogradouro"
        [numero]="logradouroCdl?.numero" [localizar]="true">
        <div class="col-sm-8 form-group">
          <label>Logradouro</label>
          <input cdlLogradouro [(ngModel)]="logradouro" size="35" name="logradouro"
                (seleciona)="selecionaCDL($event)" placeholder="Informe NOME" class="ui-input-text ui-corner-all" class="form-control">
        </div>
        <div class="col-sm-4 form-group">
          <label>Número</label>
          <input cdlNumero [(ngModel)]="numero" name="numero" size="15" placeholder="NÚMERO" class="form-control">
        </div>
        <div class="col-12 mb-3" *ngIf="!isInsert">
          {{obraPmpaEdit?.endereco?.enderecoFormatado}}
        </div>
      </div>
    </fieldset>
    
    <div class="col-auto form-group">
      <label for="vin">Empresa Executora</label>
      <p-inputMask [(ngModel)]="cnpjExecutora" name="cnpjExecutora" mask="99.999.999/9999-99"
        (onBlur)="buscaEntidade(this)"></p-inputMask>
    </div>
    <div class="col-md-8 form-group" style="display: flex; align-items: flex-end; padding-bottom: 0.3em;">
      {{obraPmpaEdit?.executora?.razaoSocial}}
    </div>
  </div>


  <div class="botoes text-right" *ngIf="!variaveisGlobais.roleEntidade">
    <button type="button" (click)="mostrarDialogEdit = false" label="Cancelar" class="btn btn-outline-secondary">
      Cancelar
    </button>
    <button type="button" (click)="salvar()" label="Salvar" class="btn btn-primary">
      Salvar
    </button>
  </div>
</p-dialog>