/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./@core/spinner/spinner.component.ngfactory";
import * as i3 from "./@core/spinner/spinner.component";
import * as i4 from "./@core/services/spinner.service";
import * as i5 from "./@core/notificacoes/notificacoes.component.ngfactory";
import * as i6 from "./@core/notificacoes/notificacoes.component";
import * as i7 from "./@core/services/notificacoes.service";
import * as i8 from "./@core/header/header.component.ngfactory";
import * as i9 from "./@core/header/header.component";
import * as i10 from "@angular/router";
import * as i11 from "@procempa/ngx-keycloak";
import * as i12 from "./globals";
import * as i13 from "./app.component";
import * as i14 from "./@core/services/pessoaFisica.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["id", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "sgr-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(2, 245760, null, 0, i3.SpinnerComponent, [i4.SpinnerService, i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "sgr-notificacoes", [], null, null, null, i5.View_NotificacoesComponent_0, i5.RenderType_NotificacoesComponent)), i1.ɵdid(4, 245760, null, 0, i6.NotificacoesComponent, [i7.NotificacoesService], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "sgr-header", [], null, null, null, i8.View_HeaderComponent_0, i8.RenderType_HeaderComponent)), i1.ɵdid(6, 114688, null, 0, i9.HeaderComponent, [i10.Router, i10.ActivatedRoute, i11.KeycloakService, i12.Globals], null, null), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i10.RouterOutlet, [i10.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "footer", [["class", "pezin col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["class", "pr-2"], ["height", "16px"], ["src", "assets/img/desenvolvimento-procempa.svg"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); _ck(_v, 6, 0); _ck(_v, 8, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i13.AppComponent, [i10.Router, i4.SpinnerService, i14.PessoaFisicaService, i12.Globals], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i13.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
