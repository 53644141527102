import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ObraPmpa } from 'src/app/@shared/models/obraPmpa.model';

@Injectable({providedIn: 'root'})
export class ObraPmpaService {

  constructor(private http: HttpClient) { }

  getObraPmpa(id: number): Observable<ObraPmpa> {
    const url = environment.apiUrl + '/obraPmpa/byId/' + id;
    console.log('criarObraPmpa Service >>>>>>', url);
    return this.http.get<ObraPmpa>(url);
  }

  getObrasPmpa(): Observable<ObraPmpa[]> {
    const url = environment.apiUrl + '/obraPmpa';
    console.log('getObrasPmpa Service >>>>>>', url);
    return this.http.get<ObraPmpa[]>(url);
  }

  getObrasPmpaAtivas(): Observable<ObraPmpa[]> {
    const url = environment.apiUrl + '/obraPmpa/ativas';
    console.log('getObrasPmpaAtivas Service >>>>>>', url);
    return this.http.get<ObraPmpa[]>(url);
  }

  criarObraPmpa(obraPmpa: ObraPmpa): Observable<ObraPmpa> {

    return this.http.post<ObraPmpa>(environment.apiUrl + '/obraPmpa/', obraPmpa);
  }

  editarObraPmpa(obraPmpa: ObraPmpa): Observable<ObraPmpa> {
    return this.http.put<ObraPmpa>(environment.apiUrl + '/obraPmpa/', obraPmpa);
  }

  deletaObraPmpa(idObraPmpa) {

    const url = environment.apiUrl + '/obraPmpa/deletar/' + idObraPmpa;
    console.log('ObraPmpa Service - DELETA>>>>>>>', url);

    return this.http
      .delete(url, idObraPmpa);

  }

}
