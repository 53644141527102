
export interface MtrccExcel01 {
  numero: string;
  idGerador: string;
  nomeGerador: string;
  enderecoGerador: string;
  idTransportador: string;
  nomeTransportador: string;
  enderecoTransportador: string;
  idDestinador: string;
  nomeDestinador: string;
  enderecoDestinador: string;
  data: string;
  situacao: string;
  veiculo: string;
  volume: string;
  enderecoResiduo: string;
  material: string;
}


export class MtrccExcel01Class {
  numero: string;
  idGerador: string;
  nomeGerador: string;
  enderecoGerador: string;
  idTransportador: string;
  nomeTransportador: string;
  enderecoTransportador: string;
  idDestinador: string;
  nomeDestinador: string;
  enderecoDestinador: string;
  data: string;
  situacao: string;
  veiculo: string;
  volume: string;
  enderecoResiduo: string;
  material: string;
}
