import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { MenuItem, MessageService, SelectItem } from 'primeng/api';
import { Entidade } from 'src/app/@shared/models/entidade.model';
import { EntidadeService } from '../entidade/entidade.service';
import { SpinnerService } from '../services/spinner.service';
import { NotificacoesService } from '../services/notificacoes.service';
import { Globals } from 'src/app/globals';
import { PessoaFisica } from 'src/app/@shared/models/pessoaFisica.model';
import { PessoaFisicaService } from '../services/pessoaFisica.service';

@Component({
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UsuarioComponent implements OnInit {

  pessoasFisicas: PessoaFisica[] = [];
  pessoaFisica: PessoaFisica;
  entidades: Entidade[] = [];
  items: MenuItem[];
  nomeUsuario: string;
  entidade: Entidade;
  entidadeNome = '';
  // Novo/edit
  mostrarDialogEdit: boolean;
  tituloDialogEdit: string;
  isInsert: boolean;
  totalPF: number;
  totalEntidades: number;

  //Pesquisa
  nomePesquisa: string;
  cpfPesquisa: string;
  emailPesquisa: string;
  cnpjPesquisa: string;

  variaveisGlobais: Globals;
  activeRoute: string;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private keycloakService: KeycloakService,
    private pessoaFisicaService: PessoaFisicaService,
    private entidadeService: EntidadeService,
    private spinnerService: SpinnerService,
    private notificacoesService: NotificacoesService,
    private globals: Globals
  ) {

    this.activeRoute = this.router.url;
    this.variaveisGlobais = globals;
  }

  ngOnInit() {

    if (this.variaveisGlobais.menuRaiz === null || this.variaveisGlobais.menuRaiz === '') {
      this.router.navigate(['/sgrIni']);
    } else {
      this.inicializaOnInit();
    }

  }

  inicializaOnInit() {
    // this.spinnerService.exibir();
    this.pessoaFisica = new PessoaFisica();

  }

  buscaPessoasFisicas(opcoes) {
    // this.pessoasFisicasPmpa = [];
    // this.pfsSecretaria = [];
    // this.pessoaFisicaService.getPessoasFisicasDaEntidade(idEntidade).subscribe(pessoaFisicas => {
    //   this.pessoasFisicasPmpa = pessoaFisicas;
    //   this.pfsSecretaria = pessoaFisicas;
    //   console.log('buscaPessoasFisicasEntidade........pessoas...........', this.pessoasFisicasPmpa);
    //   let ativos = 0;
    //   this.pessoasFisicasPmpa.forEach(pessoa => {
    //     if (pessoa.ativo) {
    //       ativos = ativos + 1;
    //       console.log('buscaPessoasFisicasEntidade.....Ativos..............', ativos);
    //     }
    //   });
    //   this.buscaSecretariasPmpa();
    //   this.podeInativar = false;
    //   if (ativos > 1) {
    //     this.podeInativar = true;
    //   }
    //   console.log('Pode Inativar...................', this.podeInativar, '---', ativos);
    // });

  }

  logout() {
    const url = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
    this.keycloakService.getKeycloakInstance().logout({ redirectUri: url });
  }

  mostrarDialogParaNovo() {
    this.pessoaFisica = new PessoaFisica();
    this.mostrarDialogEdit = true;
    this.isInsert = true;

    this.tituloDialogEdit = 'Nova Responsável Legal/Usuário/Fiscal';

  }

  mostrarDialogParaEditar(idPessoaFisica: number) {
    const pfParaEditar = this.pessoasFisicas.find(function (pf) {
      return pf.id === idPessoaFisica;
    });
    this.pessoaFisica = { ...pfParaEditar };
    console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.pessoaFisica);

    this.mostrarDialogEdit = true;
    this.isInsert = false;
    this.tituloDialogEdit = 'Editar Responsável Legal/Usuário/Fiscal';

    this.buscarEntidades(this.pessoaFisica.id);

  }

  verificaCpfExiste(cpf: string) {

    if (cpf === '__.___.___-__') {
      console.log('111verificaCpfExiste>>>>>>>', cpf);
      cpf = null;
    }

    console.log('222verificaCpfExiste>>>>>>>', cpf);
    if (cpf == null || cpf === '') {
      // this.spinnerService.ocultar();
      // this.notificacoesService.notificarErro('Não encontrou entidade para CNPJ informado!', null);
    } else {

      this.pessoaFisicaService.getPessoasFisicasPorCpf(cpf).subscribe(pessoaFisica => {

        console.log('verificaCnpjExiste1111>>>>>>>', pessoaFisica);

        if (pessoaFisica != null) {
          this.pessoaFisica = pessoaFisica;
          this.pessoaFisica.ativo = true;
          this.isInsert = false;
          // this.spinnerService.ocultar();
          // this.notificacoesService.notificarErro('Empresa com CNPJ informado já está cadastrada!', null);
        } else {
          const novoCpf = cpf.replace(/[^\d]+/g, '');
          // Acessa SMF
        }

      },
        error => {
          console.log(error);
          console.log(error.error);
          this.pessoaFisica.cpf = null;
          this.spinnerService.ocultar();
          this.notificacoesService.notificarErro(error.error, null);
        });
    }

  }

  checkActive(route: string) {
    return route === this.activeRoute;
  }

  pesquisaUsuario() {
    this.pessoasFisicas = [];

    if (this.nomePesquisa === undefined || this.nomePesquisa === null) {
      this.nomePesquisa = '';
    }
    if (this.cpfPesquisa === undefined || this.cpfPesquisa === null) {
      this.cpfPesquisa = '';
    }
    if (this.emailPesquisa === undefined || this.emailPesquisa === null) {
      this.emailPesquisa = '';
    }
    if (this.cnpjPesquisa === undefined || this.cnpjPesquisa === null) {
      this.cnpjPesquisa = '';
    }

    let apenasUmCriterio: boolean = false;
    let criterioPesquisa: string;
    let tipoCriterioPesquisa: string;
    if (this.nomePesquisa !== '') {
      apenasUmCriterio = true;
      criterioPesquisa = this.nomePesquisa;
      tipoCriterioPesquisa = "nome";
    }
    if (this.cpfPesquisa !== '') {
      if (apenasUmCriterio) {
        this.notificacoesService.notificarAviso('Favor selecionar apenas UM critério de pesquisa.!', null);
        return;
      } else {
        apenasUmCriterio = true;
        criterioPesquisa = this.cpfPesquisa;
        tipoCriterioPesquisa = "cpf";
      }
    }
    if (this.emailPesquisa !== '') {
      if (apenasUmCriterio) {
        this.notificacoesService.notificarAviso('Favor selecionar apenas UM critério de pesquisa.!', null);
        return;
      } else {
        apenasUmCriterio = true;
        criterioPesquisa = this.emailPesquisa;
        tipoCriterioPesquisa = "email";
      }
    }
    if (this.cnpjPesquisa !== '') {
      if (apenasUmCriterio) {
        this.notificacoesService.notificarAviso('Favor selecionar apenas UM critério de pesquisa.!', null);
        return;
      } else {
        apenasUmCriterio = true;
        criterioPesquisa = this.cnpjPesquisa;
        tipoCriterioPesquisa = "cnpj";
      }
    }
    console.log('getPessoaFisicaPesquisa 00>>>', criterioPesquisa);
    console.log('getPessoaFisicaPesquisa 11>>>', tipoCriterioPesquisa);

    this.spinnerService.exibir();
    this.pessoaFisicaService.getPessoaFisicaPesquisa(criterioPesquisa, tipoCriterioPesquisa).subscribe(pessoasFisicas => {
      console.log('getPessoaFisicaPesquisa 00>>>>>00>>>', pessoasFisicas);
      this.pessoasFisicas = pessoasFisicas;

      this.spinnerService.ocultar();
    },
      error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });



  }

  buscarEntidades(idPessoaFisica) {

    this.spinnerService.exibir();

    this.entidadeService.getEntidadesDaPessoaFisica(idPessoaFisica).subscribe(entidades => {
      this.entidades = entidades;
      this.totalEntidades = entidades.length;
      // console.log('inicializaOnInit>>>>>>>>>buscaEntidades>>>>', this.entidades);

      this.spinnerService.ocultar();

    },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });

  }
}
