import { Component, OnInit, OnDestroy } from '@angular/core';
import { Message } from 'primeng/api';
import { Subscription } from 'rxjs';
import { NotificacoesService } from '../services/notificacoes.service';

@Component({
  selector: 'sgr-notificacoes',
  templateUrl: './notificacoes.component.html',
  styleUrls: ['./notificacoes.component.scss']
})
export class NotificacoesComponent implements OnInit, OnDestroy {

  mensagens: Message[] = [];
  subscription: Subscription;

  constructor(private notificacoesService: NotificacoesService) { }

  ngOnInit() {
    this.subscription = this.notificacoesService.notificacoesSubject
    .subscribe(notificacao => {
      this.mensagens = [];
      this.mensagens.push(notificacao);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
