import { Localizacao } from './localizacao';
import { Entidade } from './entidade.model';
import { LicencaAmbiental } from './licencaAmbiental.model';

export class EnderecoEntidade {
  id: number;
  dataInclusao: Date;
  timestampAsString?: string;
  usuarioInclusao: string;
  usuarioAlteracao?: string;
  cep?: number;
  codigoBairro?: number;
  codigoLogradouro?: number;
  codigoLogradouroFim?: number;
  codigoLogradouroInicio?: number;
  dataHistorico?: Date;
  enderecoFormatado?: string;
  enderecoFormatadoCurto?: string;
  lado?: string;
  localizacao?: Localizacao;
  nomeBairro?: string;
  nomeBairroHistorico?: string;
  nomeHistorico?: string;
  nomeLogradouro?: string;
  nomeLogradouroFim?: string;
  nomeLogradouroInicio?: string;
  numero?: number;
  municipio?: string;
  situacao?: string;
  entidadeDO?: Entidade;
  identificacao?: string;
	observacao?: string;
	ativo?: boolean;
  licencaAmbientalDO?: LicencaAmbiental;
  isentoLicenca?: boolean;
}
