/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/primeng/components/menubar/menubar.ngfactory";
import * as i3 from "primeng/components/dom/domhandler";
import * as i4 from "primeng/components/menubar/menubar";
import * as i5 from "./menu.component";
import * as i6 from "@angular/router";
import * as i7 from "../@core/entidade/entidade.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../globals";
import * as i10 from "@procempa/ngx-keycloak";
var styles_MenuComponent = [i0.styles];
var RenderType_MenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuComponent, data: {} });
export { RenderType_MenuComponent as RenderType_MenuComponent };
export function View_MenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "menu-principal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p-menubar", [], null, null, null, i2.View_Menubar_0, i2.RenderType_Menubar)), i1.ɵprd(512, null, i3.DomHandler, i3.DomHandler, []), i1.ɵdid(4, 49152, null, 0, i4.Menubar, [i1.ElementRef, i3.DomHandler, i1.Renderer2], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_MenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sgr-menu", [], null, null, null, View_MenuComponent_0, RenderType_MenuComponent)), i1.ɵdid(1, 114688, null, 0, i5.MenuComponent, [i6.ActivatedRoute, i6.Router, i7.EntidadeService, i8.DOCUMENT, i9.Globals, i10.KeycloakService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuComponentNgFactory = i1.ɵccf("sgr-menu", i5.MenuComponent, View_MenuComponent_Host_0, { routeControle: "routeControle" }, {}, []);
export { MenuComponentNgFactory as MenuComponentNgFactory };
