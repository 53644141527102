<div id="header">
  <sgr-spinner></sgr-spinner>
  <sgr-notificacoes></sgr-notificacoes>
</div>

<sgr-header> </sgr-header>

<!-- <sgr-menu *ngIf="variaveisGlobais.menuRaiz !== 'sgrIni'"></sgr-menu> -->
<router-outlet></router-outlet>

<footer class="pezin col">
  <img
    class="pr-2"
    src="assets/img/desenvolvimento-procempa.svg"
    height="16px"
  />
</footer>
