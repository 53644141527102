<sgr-menu></sgr-menu>
<div style="width: fit-content; margin: auto;">
  <h2 class="text-primary mt-4 mb-0 pb-4" style="font-family: 'Roboto', 'Trebuchet MS', Arial, Helvetica, sans-serif">
    Certificado de identificação do gerador - DMLU
  </h2>

  <div style="overflow-x: auto">
    <p-table #tabelaCertificados [value]="certificados" [paginator]="true" sortField="competencia"
      [rows]="10" [globalFilterFields]="['competencia', 'placaVeiculo']"
      [style]="{ width: '1300px' }">
      <ng-template pTemplate="caption">
        <div class="ui-g ui-fluid">


          <div class="ui-g-12" *ngIf="!variaveisGlobais.roleEntidade" style="text-align: left;">
            <p-radioButton name="group1" value="adimplente" label="Empresa Adimplente" [(ngModel)]="adimplenteInadimplente" inputId="cta"></p-radioButton>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p-radioButton name="group1" value="inadimplente" label="Empresa Inadimplente" [(ngModel)]="adimplenteInadimplente" inputId="atc"></p-radioButton>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button type="button" class="btn btn-primary text-nowrap" (click)="empresaAdimplenteInadimplente()"
              label="Atualizar Empresa"><i class="fa fa-check pr-2"></i>Atualiza Adimplência
              </button>


          </div>


          <div class="ui-g-12" *ngIf="variaveisGlobais.roleEntidade && !adimplente" style="text-align: left;">
                <b><font color="red">EMPRESA INADIMPLENTE</font></b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Favor contatar SAC-DMLU - email: comercialização@dmlu.prefpoa.com.br - telefones (51) 3289-6983 / 3289-6880
          </div>





          <div class="ui-g-12">
            <div class="ui-g-6" style="text-align: left;">
              <button type="button" class="btn btn-primary text-nowrap" (click)="mostrarDialogParaNovo()"
                label="Adicionar" *ngIf="variaveisGlobais.roleEntidade && adimplente">
                <i class="fa fa-plus pr-2"></i>Novo Certificado
              </button>
            </div>
            <div class="ui-g-6" style="text-align: right;">
              <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
              <input type="text" pInputText size="50" placeholder="Filtrar certificados"
                (input)="tabelaCertificados.filterGlobal($event.target.value, 'contains')"
                style="width:auto"/>
            </div>
          </div>

        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="competencia" class="text-center" style="width: 100px; height: 60px;">
            Competência
            <p-sortIcon field="competencia"></p-sortIcon>
          </th>
          <th pSortableColumn="placaVeiculo" class="text-center" style="width: 100px; height: 60px;">
            Placa Veículo
            <p-sortIcon field="placaVeiculo"></p-sortIcon>
          </th>
          <th class="text-center" style="width: 100px; height: 60px;">
            Quantidade (Kg)
          </th>
          <th style="width: 200px;" class="text-center">
            Ações
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-certificado>
        <tr>
          <td>{{ certificado?.mesCompetencia + '/' + certificado?.anoCompetencia }}</td>
          <!-- <td>{{ certificado?.dataInclusao | date: 'dd/MM/yyyy' : 'pt'}}</td> -->
          <td>
            {{ certificado?.placaVeiculo }}
          </td>
          <td>
            {{ certificado?.quantidade }}
          </td>
          <td>
            <button title="Editar" (click)="mostrarDialogParaEditar(certificado.id)" label="Detalhes"
              class="btn btn-outline-secondary mx-2"><i class="fa fa-info pr-2"></i>Detalhes
            </button>

            <button title="Gerar PDF" (click)="unicoOuMultiplosGeradores(certificado.id)" label="Gerar PDF"
              *ngIf='certificado.isentoMtrFepam === true' class="btn btn-outline-secondary mx-2"><i class="fa fa-file pr-2"></i>PDF Certificado Isento de MTR FEPAM</button>

            <button title="Gerar PDF" (click)="pdfListaManifesto(certificado, false)" label="Gerar PDF Certificado"
              *ngIf='certificado.isentoMtrFepam === false' class="btn btn-outline-secondary mx-2"><i class="fa fa-file pr-2"></i>PDF Certificado com MTR FEPAM
          </button>

          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>




<p-dialog [header]="tituloDialogEdit" [(visible)]="mostrarDialogEdit" [responsive]="true" showEffect="fade"
  [modal]="true" [width]="1300" [contentStyle]="{'overflow-y': 'auto', 'overflow-x': 'hidden',
  'max-height': '80vh','min-height':'250px'}">

  <div class="container">

    <button (click)="abaEmpresa = true; abaResiduo = false; abaInfos = false; abaGerar = false;"
      type="button" class="btn btn-outline-secondary">Empresa</button>&nbsp;&nbsp;&nbsp;
    <button (click)="abaEmpresa = false; abaResiduo = true; abaInfos = false; abaGerar = false;"
      type="button" class="btn btn-outline-secondary">Resíduos</button>&nbsp;&nbsp;&nbsp;
    <button (click)="abaEmpresa = false; abaResiduo = false; abaInfos = true; abaGerar = false;"
      type="button" class="btn btn-outline-secondary">Informações</button>&nbsp;&nbsp;&nbsp;
    <button (click)="abaEmpresa = false; abaResiduo = false; abaInfos = false; abaGerar = true;"
      type="button" class="btn btn-outline-secondary" *ngIf='variaveisGlobais.roleEntidade && insertMode'>
      Gerar Certificado</button>&nbsp;&nbsp;&nbsp;
    <!-- <button (click)="salvar()" label="Salvar" type="button" class="btn btn-outline-secondary">
      Gerar Certificado</button> -->
    <!-- <button type="button" class="btn btn-primary text-nowrap float-right" (click)="salvar()"
      label="Gerar Certificado" *ngIf='variaveisGlobais.roleEntidade && insertMode'>Gerar Certificado
    </button> -->

    <div *ngIf="abaEmpresa">
      <div class="card text-black info04">
        <div class="card-header">
          <font size='5'><strong>Informações da Empresa</strong></font>
        </div>
        <div class="card-body">
          <div class="p-grid">
            <div class="p-col-12">
              <div class="p-grid">
                <div class="p-col">
                  <label>Empresa:&nbsp;&nbsp;&nbsp;</label><b>{{entidade?.razaoSocial}}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label>Email:&nbsp;&nbsp;&nbsp;</label><b>{{entidade?.email}}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label>Fone:&nbsp;&nbsp;&nbsp;</label><b>{{entidade?.telefone}}</b>
                </div>
              </div>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-12">
              <div class="p-grid">
                <div class="p-col">
                  <label>Endereço&nbsp;&nbsp;&nbsp;</label><b>{{enderecoContrato?.enderecoFormatado}}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-12">
              <div class="p-grid">
                <div class="p-col">
                  <label>Responsável&nbsp;&nbsp;&nbsp;</label><b>{{responsavelContrato?.nomeCompleto}}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div *ngIf="abaResiduo">
      <!-- <div class="card text-black info04">
        <div class="card-header">
          <font size='5'>Relação de resíduos informado pelo usuário.</font>
        </div>
        <div class="card-body">
          <div class="ui-g-12">
            {{contrato.obsResiduosEmpresa}}
          </div>
        </div>
      </div> -->





      <div class="card text-black info04">
        <div class="card-header">
          <font size='5'><strong>Estação de Transbordo</strong> - Relação de resíduos.</font>
        </div>
        <div class="card-body">
          <div *ngFor="let residuo of residuos; let i = index">
            <div class="p-grid" *ngIf="residuo?.gdrTransbordo">
              <div class="p-col-12">
                <i class="fa fa-check" aria-hidden="true">
                  {{residuo.nome}}
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="card text-black info04">
        <div class="card-header">
          <font size='5'><strong>Unidade de Compostagem</strong> - Relação de resíduos.</font>
        </div>
        <div class="card-body">
          <div *ngFor="let residuo of residuos; let i = index">
            <div class="p-grid" *ngIf="residuo?.gdrCompostagem">
              <div class="p-col-12">
                <i class="fa fa-check" aria-hidden="true">
                  {{residuo.nome}}
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card text-black info04">
        <div class="card-header">
          <font size='5'><strong>Unidade de Triagem</strong> - Relação de resíduos.</font>
        </div>
        <div class="card-body">
          <div *ngFor="let residuo of residuos; let i = index">
            <div class="p-grid" *ngIf="residuo?.gdrTriagem">
              <div class="p-col-12">
                <i class="fa fa-check" aria-hidden="true">
                  {{residuo.nome}}
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="abaInfos && insertMode">

      <div class="card text-black info04">
        <div class="card-header">
          <font size='5'><strong>Informações</strong></font>
        </div>
        <div class="card-body">
          <div class="p-grid">
            <div class="p-col-12">
              <div class="p-grid">
                <div class="p-col-12">

                  Declaro estar ciente de que os resíduos sólidos autorizados para destinação nas unidades do DMLU são tão somente os
                  listados neste certificado, os quais deverão ser encaminhados à(s) unidade(s) respectivamente(s) relacionada(s) para tal.
                  Estou ciente de que é vedada a destinação de quaisquer outros resíduos para tais unidades, sem autorização prévia da
                  equipe técnica e reavaliação do cadastro técnico. A licença de operação vigente da unidade, permite o recebimento apenas
                  de resíduos similares aos domiciliares.
                  <br><br>
                  Declaro que, no cumprimento do previsto pelo Decreto Estadual 38.356 de 01.04.1998, regulamentador da Lei Nº 9921 de
                  27.07.1993, suas alterações e substituições, resíduos com potencial de reciclagem ou reaproveitamento gerados pelo
                  estabelecimento não poderão ser destinados à unidade de transbordo, devendo ser encaminhados para unidades de
                  triagem, para reciclagem e/ou reaproveitamento.
                  <br><br>
                  Estou ciente de que é expressamente proibida a entrada de resíduos Classe I, resíduos industriais Classe II (conforme
                  Resolução CONSEMA 73/2004, suas alterações e substituições), bem como resíduos de construção civil (conforme resoluções
                  CONAMA 307/2002, 448/2012 e 469/2015, suas alterações e substituições), nas unidades do DMLU.
                  Estou ciente de que o recebimento das minhas cargas de resíduos encaminhadas somente será possível a partir da apresentação
                  do Certificado de Identificação de Gerador de Resíduos Sólidos devidamente preenchido e assinado, acompanhado
                  do Manifesto de Transporte de Resíduos ou Declaração de Isenção. A disposição conjunta com outros geradores,
                  bem como as ações de empresa(s) contratada(s) para manejo e/ou transporte de resíduos inserem responsabilidade solidária
                  a todos os envolvidos.
                  <br><br>
                  Informações: DMLU/Divisão de Destino Final: Av. Azenha, 631, sala 44, Azenha, Porto Alegre.<br>
                  Telefone: 3289.69.85. E-mail: smca@dmlu.prefpoa.com.br

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="abaGerar && insertMode">

      <div class="card text-black info04">
        <div class="card-header">
          <font size='5'><strong>Certificado</strong></font>
        </div>
        <div class="card-body">
          <div class="p-grid">
            <div class="p-col-12">
              <div class="p-grid">
                <div class="p-col-12">



                  <div class="ui-g-12" *ngIf="variaveisGlobais.roleEntidade" style="text-align: left;">
                    <label><b>Favor informar se o Certificado está ou não isento do MTR FEPAM:</b></label>
                    <br><br>

                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <p-radioButton name="group1" value="mtrFepam" label="Não isento do MTR FEPAM" [(ngModel)]="mtrFepamIsento" inputId="nimf"></p-radioButton>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <p-radioButton name="group1" value="mtrIsento" label="Isento do MTR FEPAM" [(ngModel)]="mtrFepamIsento" inputId="imf"></p-radioButton>
                    <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button type="button" class="btn btn-primary text-nowrap" (click)="empresaAdimplenteInadimplente()"
                      label="Atualizar Empresa"><i class="fa fa-check pr-2"></i>Atualiza Adimplência
                      </button> -->
                  </div>
                </div>
                <div class="p-col-12">
                    <button type="button" class="btn btn-primary text-nowrap float-right" (click)="salvar()"
                    label="Gerar Certificado" *ngIf='variaveisGlobais.roleEntidade && insertMode'>Gerar Certificado
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="abaInfos && !insertMode">

      <div class="card text-black info04">
        <div class="card-header">
          <font size='5'><strong>Informações</strong></font>
        </div>
        <div class="card-body" *ngIf="certificado.placaVeiculo === null && !variaveisGlobais.roleEntidade">
          <div class="p-grid">
            <div class="p-col-6">
              <div class="p-grid">
                <div class="p-col">
                  <label>Data e Hora do recebimento dos resíduos</label>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col">
                  <p-calendar class="col-sm-3 p0" [(ngModel)]="certificado.recebimento" [showIcon]="true" dateFormat="dd/mm/yy"
                    [style]="{'width':'50%'}" [inputStyle]="{'width':'50%'}"></p-calendar>
                  <p-calendar [(ngModel)]="horaRecebimento" timeOnly="true" showTime="true" hourFormat="24" [utc]="true"
                    [style]="{'width':'40%', 'align' : 'left'}" [inputStyle]="{'width':'40%'}"></p-calendar>
                  </div>
              </div>
            </div>
            <div class="p-col-3">
              <div class="p-grid">
                <div class="p-col">
                  <label>Placa Veículo</label>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col">
                  <input pInputText id="placaVeiculo" [(ngModel)]="placaVeiculo" maxlength="10" size="10"/>
                </div>
              </div>
            </div>
            <div class="p-col-3">
              <div class="p-grid">
                <div class="p-col">
                  <label>Quantidade</label>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col">
                  <input pInputText id="quantidade" [(ngModel)]="certificado.quantidade" maxlength="8" size="8" pKeyFilter="num"/> em Kg
                </div>
              </div>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-8">
              <div class="p-grid">
                <div class="p-col">
                  <label>Apontador</label>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col">
                  <input pInputText id="apontador" [(ngModel)]="certificado.apontador" maxlength="80" size="80"/>
                </div>
              </div>
            </div>
            <div class="p-col-4">
              <div class="p-grid">
                <div class="p-col">
                  <label>Matrícula</label>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col">
                  <input pInputText id="matricula" [(ngModel)]="certificado.matricula" maxlength="10" size="10" pKeyFilter="num"/>
                </div>
              </div>
            </div>

          </div>
          <div class="p-grid">
            <div class="p-col-12">
              <div class="p-grid">
                <div class="p-col" style="text-align: right;">
                  <button type="button" class="btn btn-primary" (click)="salvar()" >Atualizar Certificado</button>
                </div>
              </div>
            </div>
          </div>


        </div>

        <div class="card-body" *ngIf="certificado.placaVeiculo !== null">
          <div class="p-grid">
            <div class="p-col-12">
              <div class="p-grid">
                <div class="p-col">
                  <label>Data e Hora do recebimento:&nbsp;&nbsp;&nbsp;</label><b>
                      {{certificado?.recebimento | date: 'dd/MM/yyyy' : 'pt'}}
                      {{certificado?.recebimento | date: 'hh:mm' : 'pt'}}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label>Placa Veículo:&nbsp;&nbsp;&nbsp;</label><b>{{certificado?.placaVeiculo}}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label>Quantidade:&nbsp;&nbsp;&nbsp;</label><b>{{certificado?.quantidade}} Kg</b>
                </div>
              </div>
            </div>
            <div class="p-col-12">
              <div class="p-grid">
                <div class="p-col">
                  <label>Matrícula:&nbsp;&nbsp;&nbsp;</label><b>{{certificado?.matricula}}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label>Apontador:&nbsp;&nbsp;&nbsp;</label><b>{{certificado?.apontador}}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>






  </div>
</p-dialog>


<!-- Dialog Único ou Múltiplos Geradores -->
<p-dialog header="Certificado para um único ou múltiplos geradores" [(visible)]="certificado" [responsive]="true" showEffect="fade" [modal]="true" resizable="true" [width]="520" [height]="250" *ngIf="isUnicoOuMultiplosGeradores">
  <p-tabView>
    <div class="row">
      <div class="col-12 col-sm-4">
        <p-radioButton name="qtdGerador" value="unico" label="Único Gerador" [(ngModel)]="qtdGerador"></p-radioButton>
      </div>
      <div class="col-12 col-sm-6">
        <p-radioButton name="qtdGerador" value="multiplos" label="Múltiplos Geradores" [(ngModel)]="qtdGerador"></p-radioButton>
      </div>
    </div>

  </p-tabView>

  <p-footer>
    <div class="row text-center">
      <div class="col-12">
         <button title="Gerar PDF" (click)="pdfListaManifesto(certificado, true)" label="Gerar PDF Certificado"
         class="btn btn-outline-secondary mx-2"><i class="fa fa-file pr-2"></i>PDF Certificado
       </button>


      </div>
    </div>

    </p-footer>
</p-dialog>
