import { Component } from '@angular/core';

@Component({
  selector: 'sgr-nao-encontrada',
  templateUrl: './nao-encontrada.component.html',
  styleUrls: ['./nao-encontrada.component.scss']
})
export class NaoEncontadaComponent {

}
