import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var SpinnerService = /** @class */ (function () {
    function SpinnerService() {
        this.spinnerSubject = new Subject();
    }
    SpinnerService.prototype.alterar = function (exibir) {
        this.spinnerSubject.next(exibir);
    };
    SpinnerService.prototype.exibir = function () {
        this.alterar(true);
    };
    SpinnerService.prototype.ocultar = function () {
        this.alterar(false);
    };
    SpinnerService.ngInjectableDef = i0.defineInjectable({ factory: function SpinnerService_Factory() { return new SpinnerService(); }, token: SpinnerService, providedIn: "root" });
    return SpinnerService;
}());
export { SpinnerService };
