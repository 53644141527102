import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeycloakService, NgxKeycloakModule } from '@procempa/ngx-keycloak';
import { environment } from 'src/environments/environment';
import { CoreModule } from './@core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InicialModule } from './inicial/inicial.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CdlLibModule } from 'procempa-cdl-lib';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CdlLibModule.forRoot(),  
    NgxKeycloakModule.forRoot(),
    BsDropdownModule.forRoot(),
    AppRoutingModule,
    CoreModule,
    InicialModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: startKeycloak,
      deps: [KeycloakService],
      multi: true
    }
    // { provide: LOCALE_ID, useValue: 'pt-PT' },
  ],
  bootstrap: [AppComponent]
  
})

export class AppModule { }

export function startKeycloak(kc: KeycloakService) {
  const url = window.location.origin.split('//');

  let env = { url: '', clientId: '', realm: '' };
  if (url[1] === '10.110.114.228:4200' || url[1] === 'localhost:4200') {

    // env = environment.keycloak;
    env = environment.acessoPoa;

  } else {
    const urlIs = url[1].split('.');
    // console.log("UrlIs..............", urlIs[0]);
    if (urlIs[0] === 'sgr' || urlIs[0] === 'sgr-hom') {
      env = environment.acessoPoa;
    }
    if (urlIs[0] === 'sgr-admin' || urlIs[0] === 'sgr-admin-hom') {
      env = environment.keycloak;
    }
  }

  // console.log('route..............', env);
  kc.urlsToIgnore = ['cdlrest', 'arcgis'];

  kc.minValidity === 5000000;

  return () => kc.init(env);
  // return () => kc.init(env, { checkLoginIframe: false });
}