import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { MenuItem, SelectItem } from 'primeng/api';
import { Entidade } from 'src/app/@shared/models/entidade.model';
import { EntidadeService } from '../entidade/entidade.service';
import { SpinnerService } from '../services/spinner.service';
import { NotificacoesService } from '../services/notificacoes.service';
import { ClassificacaoService } from '../services/classificacao.service';
import { Globals } from 'src/app/globals';
import { Endereco } from 'src/app/@shared/models/endereco';
import { LogradouroCdl } from '../../@shared/models/logradouroCdl';
import { Localizacao } from 'src/app/@shared/models/localizacao';
import { EnderecoEntidade } from 'src/app/@shared/models/enderecoEntidade';
import { EnderecoService } from '../services/endereco.service';
import { LicencaAmbiental } from 'src/app/@shared/models/licencaAmbiental.model';
import { LicencaAmbientalService } from '../services/licencaAmbiental.service';

@Component({
  templateUrl: './destinoFinal.component.html',
  styleUrls: ['./destinoFinal.component.scss']
})
export class DestinoFinalComponent implements OnInit {

  destinosFinais: EnderecoEntidade[] = [];
  items: MenuItem[];
  nomeUsuario: string;
  entidadePMPA: Entidade;
  entidadeNome = '';
  // Novo/edit
  destinoFinalEdit: EnderecoEntidade;
  mostrarDialogEdit: boolean;
  tituloDialogEdit: string;
  isInsert: boolean;
  // Exluir
  destinoFinalExcluir: EnderecoEntidade;
  mostrarDialogExcluir: boolean;

  variaveisGlobais: Globals;
  activeRoute: string;

  licenca: LicencaAmbiental;
  licencas: LicencaAmbiental[] = [];
  licencasItem: SelectItem[] = [];
  idLicenca: number;
  licencaIsento: boolean = false;

  // CDL
  endereco: Endereco;
  enderecoRes: Endereco = {};
  logradouroCdl: LogradouroCdl;
  localizacao: Localizacao;
  logradouro: string;
  numero: string;

  constructor(
    private router: Router,
    private keycloakService: KeycloakService,
    private destinoFinalService: EnderecoService,
    private entidadeService: EntidadeService,
    private licencaAmbientalService: LicencaAmbientalService,
    private spinnerService: SpinnerService,
    private notificacoesService: NotificacoesService,
    private classificacaoService: ClassificacaoService,
    private globals: Globals
  ) {

    this.activeRoute = this.router.url;
    this.variaveisGlobais = globals;
  }

  ngOnInit() {

    console.log('000ListaProdutosComponent>>>>>>>>>>>', this.variaveisGlobais);

    if (this.variaveisGlobais.menuRaiz === null || this.variaveisGlobais.menuRaiz === '') {
      this.router.navigate(['/sgrIni']);
    } else {
      this.inicializaOnInit();
    }

  }

  inicializaOnInit() {
    this.spinnerService.exibir();
    // this.destinoFinalEdit = {
    //   id: null,
    //   dataInclusao: null,
    //   timestampAsString: null,
    //   usuarioInclusao: null,
    //   usuarioAlteracao: null,
    //   nome: '',
    //   observacao: '',
    //   entidadeDO: null,
    //   endereco: null,
    //   ativo: true

    // };
    this.destinoFinalEdit = {
      id: null,
      dataInclusao: null,
      timestampAsString: null,
      usuarioInclusao: null,
      usuarioAlteracao: null,
      cep: null,
      codigoBairro: null,
      codigoLogradouro: null,
      codigoLogradouroFim: null,
      codigoLogradouroInicio: null,
      dataHistorico: null,
      enderecoFormatado: null,
      enderecoFormatadoCurto: null,
      lado: null,
      localizacao: null,
      nomeBairro: null,
      nomeBairroHistorico: null,
      nomeHistorico: null,
      nomeLogradouro: null,
      nomeLogradouroFim: null,
      nomeLogradouroInicio: null,
      numero: null,
      municipio: null,
      situacao: null,
      entidadeDO: null,
      identificacao: null,
      observacao: null,
      ativo: true
    }

    this.entidadeService.getEntidadeCnpj('92.963.560/0001-60').subscribe(entidade => {
      if (entidade != null) {
        this.entidadePMPA = entidade;
        this.destinoFinalService.getEnderecosPorEntidade(entidade.id).subscribe(destinosFinais => {
          console.log('Destinos Finais ........ >>>>>>>>', destinosFinais);
              this.spinnerService.ocultar();
              destinosFinais.forEach(df => {
                if (df.identificacao != null) {
                  this.destinosFinais.push(df);
                }
              });
        });

        this.licencasItem = [];
        this.licencaAmbientalService.getLicencasPorEntidade(entidade.id).subscribe(
          licencas => {
            this.licencas = licencas;
            console.log('Licencas Ambientais>>>>>Licenças>>>>>>>>>', this.licencas);
            licencas.forEach(lic => {
              this.licencasItem.push( {label: lic.licenca, value: lic.id});
            });

          },
          error => {
            this.spinnerService.ocultar();
            this.notificacoesService.notificarErro(error.error, null);
          }
        );

      }
    });

    // this.destinoFinalService.cepCorreios().subscribe(cep => {
    //   console.log('CEP CORREIOS>>>>>>>>', cep);
    // },
    // error => {
    //   this.spinnerService.ocultar();
    //   this.notificacoesService.notificarErro(error.error, null);
    // });

  }

  logout() {
    const url = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
    this.keycloakService.getKeycloakInstance().logout({redirectUri: url});
  }

  mostrarDialogParaNovo() {
    // this.destinoFinalEdit = {
    //   id: null,
    //   dataInclusao: null,
    //   timestampAsString: null,
    //   usuarioInclusao: null,
    //   usuarioAlteracao: null,
    //   nome: '',
    //   observacao: '',
    //   entidadeDO: null,
    //   endereco: null,
    //   ativo: true

    // };
    this.destinoFinalEdit = {
      id: null,
      dataInclusao: null,
      timestampAsString: null,
      usuarioInclusao: null,
      usuarioAlteracao: null,
      cep: null,
      codigoBairro: null,
      codigoLogradouro: null,
      codigoLogradouroFim: null,
      codigoLogradouroInicio: null,
      dataHistorico: null,
      enderecoFormatado: null,
      enderecoFormatadoCurto: null,
      lado: null,
      localizacao: null,
      nomeBairro: null,
      nomeBairroHistorico: null,
      nomeHistorico: null,
      nomeLogradouro: null,
      nomeLogradouroFim: null,
      nomeLogradouroInicio: null,
      numero: null,
      municipio: null,
      situacao: null,
      entidadeDO: null,
      identificacao: null,
      observacao: null,
      ativo: true
    }
    this.mostrarDialogEdit = true;
    this.isInsert = true;

    this.tituloDialogEdit = 'Novo Destino Final';
    this.destinoFinalEdit.isentoLicenca = false;
    this.idLicenca = null;
    this.licencaIsento = true;
  }

  mostrarDialogParaEditar(idDestinoFinal: number) {
    const destinoFinalParaEditar = this.destinosFinais.find(function(destinoFinal) {
      return destinoFinal.id === idDestinoFinal;
    });
    this.destinoFinalEdit = {...destinoFinalParaEditar};
    console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.destinoFinalEdit);

    this.mostrarDialogEdit = true;
    this.isInsert = false;
    this.tituloDialogEdit = 'Destino Final';
    this.idLicenca = null;
    if (this.destinoFinalEdit.licencaAmbientalDO !== null) {
      this.idLicenca = this.destinoFinalEdit.licencaAmbientalDO.id;
    }

    if (this.destinoFinalEdit.isentoLicenca !== null) {
      this.licencaIsento = this.destinoFinalEdit.isentoLicenca;
    }
  }

  mostrarDialogEditParaExcluir(idProduto: number) {
    // this.produtoExcluir =  this.produtos.find(function(produto) {
    //   return produto.id === idProduto;
    // });
    // this.mostrarDialogExcluir = true;
  }

  selLicenca() {
    this.licencaIsento = false;
  }

  licencaIsencao(licencaIsento) {

    // console.log('===========licencaIsencao=========>', licencaIsento.binary);
    console.log('===========licencaIsencao=========>', this.licencaIsento);
    this.destinoFinalEdit.isentoLicenca = this.licencaIsento;
    if (this.licencaIsento) {
      this.idLicenca = null;
    }

  }

  salvar() {

    //Verifica se todos os campos foram preenchidos
    if (this.destinoFinalEdit.identificacao == null || this.destinoFinalEdit.identificacao === '') {
      this.notificacoesService.notificarErro('Por favor informar o NOME do Destino Final!', null);
      return ;
    }

    this.spinnerService.exibir();

    console.log('salvar....................>>>>>>>>>>>>>>', this.destinoFinalEdit);
    this.destinoFinalEdit.municipio = 'Porto Alegre';
    this.destinoFinalEdit.entidadeDO = this.entidadePMPA;

    this.destinoFinalEdit.licencaAmbientalDO = null;
    this.licencas.forEach(lic => {
      if (lic.id === this.idLicenca) {
        this.destinoFinalEdit.licencaAmbientalDO = lic;
      }
    });
    this.destinoFinalEdit.isentoLicenca = this.licencaIsento;

    // Se novo
    if (this.destinoFinalEdit.id == null) {
      this.destinoFinalService.criarEndereco(this.destinoFinalEdit).subscribe(destinoFinalNovo => {
        console.log('salvado....................>>>>>>>>>>>>>>', destinoFinalNovo);
        this.destinosFinais.push(destinoFinalNovo);

        this.mostrarDialogEdit = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso('Adicionado com sucesso', null);
      },
      error => {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro('Erro ao adicionar ', error);
      });
    } else { // Se editando
      this.destinoFinalService.editarEndereco(this.destinoFinalEdit).subscribe(destinoFinalEditado => {
        // Remove o valor atual
        this.destinosFinais = this.destinosFinais.filter(item => item.id !== this.destinoFinalEdit.id);

        // Adiciona o novo
        this.destinosFinais.push(destinoFinalEditado);

        this.mostrarDialogEdit = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso('Atualizado com sucesso', null);
      },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });
    }

    this.idLicenca = null;
    this.licencaIsento = null;

  }

  isSMAMS(): boolean {
    const ret = this.keycloakService.hasRole('SMAMS');
    return ret;
  }

  excluir() {
    // this.spinnerService.exibir();
    // console.log('salvar....................>>>>>>>>>>>>>>', this.variaveisGlobais.menuRaiz);

    // this.produtoService.excluirProduto(this.produtoExcluir, this.variaveisGlobais.menuRaiz).subscribe(produto => {
    //   if (produto == null) {
    //     // Remove o valor atual
    //     this.produtos = this.produtos.filter(item => item.id !== this.produtoExcluir.id);
    //   }
    //   this.mostrarDialogExcluir = false;
    //   this.spinnerService.ocultar();
    //   this.notificacoesService.notificarSucesso('Excluído com sucesso', null);
    // },
    // error => {
    //   console.log(error);
    //   this.spinnerService.ocultar();
    //   this.notificacoesService.notificarErro('Erro ao exluir', error.error);
    // });
  }


  checkActive(route: string) {
    return route === this.activeRoute;
  }

  selecionaCDL(endereco) {

    this.localizacao = {latitude:null, longitude:null};

    if (endereco.latitude !== undefined && endereco.latitude !== null) {
      this.localizacao = {
        latitude: endereco.latitude,
        longitude: endereco.longitude
      };
      delete endereco['latitude'];
      delete endereco['longitude'];
    }

    this.destinoFinalEdit.cep = endereco.cep;
    this.destinoFinalEdit.codigoBairro = endereco.codigoBairro;
    this.destinoFinalEdit.codigoLogradouro = endereco.codigoLogradouro;
    this.destinoFinalEdit.codigoLogradouroFim = endereco.codigoLogradouroFim;
    this.destinoFinalEdit.codigoLogradouroInicio = endereco.codigoLogradouroInicio;
    this.destinoFinalEdit.dataHistorico = endereco.dataHistorico;
    this.destinoFinalEdit.enderecoFormatado = endereco.enderecoFormatado;
    this.destinoFinalEdit.enderecoFormatadoCurto = endereco.enderecoFormatadoCurto;
    this.destinoFinalEdit.lado = endereco.lado;
    this.destinoFinalEdit.localizacao = this.localizacao;
    this.destinoFinalEdit.nomeBairro = endereco.nomeBairro;
    this.destinoFinalEdit.nomeBairroHistorico = endereco.nomeBairroHistorico;
    this.destinoFinalEdit.nomeHistorico = endereco.nomeHistorico;
    this.destinoFinalEdit.nomeLogradouro = endereco.nomeLogradouro;
    this.destinoFinalEdit.nomeLogradouroFim = endereco.nomeLogradouroFim;
    this.destinoFinalEdit.nomeLogradouroInicio = endereco.nomeLogradouroInicio;
    this.destinoFinalEdit.numero = endereco.numero;
    this.destinoFinalEdit.municipio = endereco.municipio;
    this.destinoFinalEdit.situacao = endereco.situacao;

  }
}
