<sgr-menu></sgr-menu>
<div class="container">

  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/sgrIni">SGR</a></li>
    <li class="breadcrumb-item active" aria-current="page">Cadastro</li>
  </ol> 

  <h1 class="page-header text-primary">
    Veículos<br>
    <small>Cadastro</small>
  </h1>

  <!-- <h2
    class="text-primary mt-4 mb-0 pb-4"
    style="font-family: 'Roboto', 'Trebuchet MS', Arial, Helvetica, sans-serif"
  >
    Veículo
  </h2> -->

  <div>
    <p-table #tabelaDestinosFinais [value]="veiculos" [paginator]="true" sortField="nome" [rows]="10"
      [globalFilterFields]="['nome']">
      <ng-template pTemplate="caption">
        <div class="row justify-content-between">
          <div class="col-auto form-group">
            <button type="button" class="btn btn-primary text-nowrap" (click)="mostrarDialogParaNovo()"
              label="Adicionar" *ngIf="!variaveisGlobais.roleEntidade">
              <i class="fa fa-plus pr-2"></i>Adicionar
            </button>
          </div>
          <div class="col-sm-12 col-md-6 form-group busca">
            <input type="text" pInputText size="50" placeholder="Filtrar veículos"
              (input)="tabelaDestinosFinais.filterGlobal($event.target.value, 'contains')" class="form-control" />
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="placa" class="text-center" style="width: 200px; height: 60px;">
            Placa
            <p-sortIcon field="placa"></p-sortIcon>
          </th>
          <th class="text-center" style="width: 100px; height: 60px;">
            Renavam
          </th>
          <th style="width: 150px;" class="text-center">
            Ações
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-veiculoPmpa>
        <tr>
          <td>
            {{ veiculoPmpa?.placa }}
          </td>
          <td>
            {{ veiculoPmpa?.renavam }}
          </td>
          <td>
            <button title="Editar" (click)="mostrarDialogParaEditar(veiculoPmpa.id)" label="Detalhes"
              class="btn btn-outline-secondary mx-2">
              <i class="fa fa-info pr-2"></i>Detalhes
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<!-- Dialog para novo -->
<p-dialog [header]="tituloDialogEdit" [(visible)]="mostrarDialogEdit" [responsive]="true" showEffect="fade"
  [modal]="true" [width]="700">
  <div class="row">
    <div class="col-auto form-group">
      <label for="vin">Placa</label>
      <input pInputText id="placa" [(ngModel)]="veiculoEdit.placa" size="15" class="form-control" />
    </div>
  
    <div class="col-auto form-group">
      <label for="vin">Renavam</label>
      <input pInputText id="renavam" [(ngModel)]="veiculoEdit.renavam" size="15"  class="form-control"/>
    </div>
    <div class="col-auto ml-auto botoes form-group" *ngIf="!variaveisGlobais.roleEntidade">
      <button type="button" (click)="mostrarDialogEdit = false" label="Cancelar" class="btn btn-outline-secondary">
        Cancelar
      </button>
      <button type="button" (click)="salvar()" label="Salvar" class="btn btn-primary">
        Salvar
      </button>
    </div>
  </div>


  
</p-dialog>