import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'milhar'
})
export class MilharPipe implements PipeTransform {
    transform(milhar: string): string {

        var milharFinal: string = "";
        if (milhar != null) {
            // console.log("MilharPipe - Tamanho>>>", milhar.toString().length)

            if (milhar.toString().length < 4) {
                milharFinal = milhar;
            } if (milhar.toString().length == 4) {
                milharFinal = milhar.toString().substring(0, 1) + "." + milhar.toString().substring(1, 4);
            } if (milhar.toString().length == 5) {
                milharFinal = milhar.toString().substring(0, 2) + "." + milhar.toString().substring(2, 5);
            } if (milhar.toString().length == 6) {
                milharFinal = milhar.toString().substring(0, 3) + "." + milhar.toString().substring(3, 6);
            } if (milhar.toString().length == 7) {
                milharFinal = milhar.toString().substring(0, 1) + "." + milhar.toString().substring(1, 4) + "." + milhar.toString().substring(4, 7);
            }
        }



        // console.log("HoraPipe-------------->>", horaFinal);
        // return value.substr(0, 5) + '-' + value.substr(5, 3);
        return milharFinal;
    }
}