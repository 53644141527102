<header class="pt-2">
  <div class="container">
    <div class="d-flex justify-content-between">
      <div class="titulo-sistema">
        <strong>SGR</strong>
        <small>Sistema de Gestão de Resíduos</small>
      </div>
      <img
        class="brasao"
        src="assets/img/prefeitura.svg"
        height="40px"
        alt="Prefeitura de Porto Alegre"
      />
    </div>
  </div>

  <div class="barra-menu pb-2">
    <div class="container">
      <div class="d-flex justify-content-between">
        <!--
        <button class="btn btn-header d-sm-none" (click)="toggleMenu()">
          <i class="fa fa-bars fa-2x"></i>
        </button>
        -->

        <!-- Documentos módulo Grandes Geradores -->
        <div *ngIf="variaveisGlobais.menuRaiz=='grandeGerador'">
          <div class="btn-group" dropdown>
            <button dropdownToggle
              class="btn btn-outline-secondary btn-sm btn-header dropdown-toggle"
              style="white-space: nowrap;">
              Documentos <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
                role="menu" aria-labelledby="button-basic">
              <li role="menuitem">
                <a href="../../../assets/doc/Manual do Usuário SGR - Grandes Geradores v1.pdf" target="_blank"><i
                    class="fa fa-file-pdf-o pr-2"></i>Guia de Uso</a>
              </li>
              <li role="menuitem">
                <a
                  href="../../../assets/doc/2020 Decreto Municipal 20.684 2020 Grande Gerador.pdf"
                  target="_blank"><i class="fa fa-file-pdf-o pr-2"></i>Decreto 20.684-2020</a>
              </li>
            </ul>&nbsp;&nbsp;&nbsp;
            <button type="button" (click)="verInfoGG()" pButton icon="pi pi-info-circle" label="Info"
              class="btn btn-outline-secondary btn-sm btn-header">
            </button>
          </div>
        </div>

        <!-- Documentos módulo MTRCC -->
        <div *ngIf="variaveisGlobais.menuRaiz=='mtrcc'">
          <div class="btn-group" dropdown>
            <button dropdownToggle
              class="btn btn-outline-secondary btn-sm btn-header dropdown-toggle"
              style="white-space: nowrap;">
              Documentos <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
                role="menu" aria-labelledby="button-basic">
              <li role="menuitem">
                <a href="../../../assets/doc/Guia do Usuario SGR - MTRCC - 20220214 v2.pdf" target="_blank"><i
                    class="fa fa-file-pdf-o pr-2"></i>Guia de Uso</a>
              </li>
              <li role="menuitem">
                <a href="../../../assets/doc/Decreto_20368-2019_MTRCC_Online.pdf"
                  target="_blank"><i class="fa fa-file-pdf-o pr-2"></i>Decreto 20.368-2019</a>
              </li>
            </ul>&nbsp;&nbsp;&nbsp;
            <button type="button" (click)="verInfoMTRCC()" pButton icon="pi pi-info-circle" label="Info"
              class="btn btn-outline-secondary btn-sm btn-header">
            </button>
          </div>
        </div>

        <!-- Documentos módulo GDR -->
        <div *ngIf="variaveisGlobais.menuRaiz=='gdr'">
          <div class="btn-group" dropdown>
            <button dropdownToggle
              class="btn btn-outline-secondary btn-sm btn-header dropdown-toggle"
              style="white-space: nowrap;">
              Documentos <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
                role="menu" aria-labelledby="button-basic">
              <li role="menuitem">
                <a href="../../../assets/doc/Manual do Usuário SGR - GDR.pdf" target="_blank"><i
                    class="fa fa-file-pdf-o pr-2"></i>Guia de Uso</a>
              </li>
            </ul>&nbsp;&nbsp;&nbsp;
            <button type="button" (click)="verInfoGDR()" pButton icon="pi pi-info-circle" label="Info"
              class="btn btn-outline-secondary btn-sm btn-header">
            </button>
          </div>
        </div>

        <p-dialog header="Informações" [(visible)]="showMTRCC" [modal]="true" [responsive]="true" [style]="{width: '350px', minWidth: '200px'}" [minY]="70" 
        [maximizable]="true" [baseZIndex]="10000">
            <h1 class="h4 text-danger mt-4">ATENÇÃO!</h1>
            Informamos a atualização do tutorial "GUIA DE USO" do MTRCC. <b><i><a href="../../../assets/doc/Guia do Usuario SGR - MTRCC - 20220214 v2.pdf" target="_blank">Acessar aqui!</a></i></b><br><br>
            Como podem observar, a principal mudança está no momento do cadastramento de uma nova empresa e novo usuário. Anteriormente era necessário informar alguns dados da empresa e ao mesmo tempo algumas informações do responsável legal. <br><br>
            A partir de agora, no momento do cadastramento será solicitado apenas as informações do responsável legal. Após esse primeiro cadastro o usuário acessa o sistema, seleciona <i>MTRCC</i>, e então poderá incluir a(s) empresa(s).<br><br>
            Qualquer dúvida, por favor, entrar em contato com o SMAMUS através do email: <b><i><a href="mailto:smamus.mtrcc@portoalegre.rs.gov.br">smamus.mtrcc@portoalegre.rs.gov.br</a></i></b>
        </p-dialog>

        <p-dialog header="Informações" [(visible)]="showGG" [modal]="true" [responsive]="true" [style]="{width: '350px', minWidth: '200px'}" [minY]="70" 
        [maximizable]="true" [baseZIndex]="10000">
            <h1 class="h4 text-danger mt-4">ATENÇÃO!</h1>
            Informamos a atualização do tutorial "GUIA DE USO" do Grandes Geradores. <b><i><a href="../../../assets/doc/Manual do Usuário SGR - Grandes Geradores v1.pdf" target="_blank">Acessar aqui!</a></i></b><br><br>
            Como podem observar, a principal mudança está no momento do cadastramento de uma nova empresa e novo usuário. Anteriormente era necessário informar alguns dados da empresa e ao mesmo tempo algumas informações do responsável legal. <br><br>
            A partir de agora, no momento do cadastramento será solicitado apenas as informações do responsável legal. Após esse primeiro cadastro o usuário acessa o sistema, seleciona <i>Grandes Geradores</i>, e então poderá incluir a(s) empresa(s).<br><br>
            Qualquer dúvida, por favor, entrar em contato com o DMLU através do email: <b><i><a href="mailto:smca@dmlu.prefpoa.com.br">smca@dmlu.prefpoa.com.br</a></i></b>
        </p-dialog>

        <p-dialog header="Informações" [(visible)]="showGDR" [modal]="true" [responsive]="true" [style]="{width: '350px', minWidth: '200px'}" [minY]="70" 
        [maximizable]="true" [baseZIndex]="10000">
            <h1 class="h4 text-danger mt-4">ATENÇÃO!</h1>
            Informamos a atualização do tutorial "GUIA DE USO" do GDR. <b><i><a href="../../../assets/doc/Manual do Usuário SGR - GDR.pdf" target="_blank">Acessar aqui!</a></i></b><br><br>
            Como podem observar, a principal mudança está no momento do cadastramento de uma nova empresa e novo usuário. Anteriormente era necessário informar alguns dados da empresa e ao mesmo tempo algumas informações do responsável legal. <br><br>
            A partir de agora, no momento do cadastramento será solicitado apenas as informações do responsável legal. Após esse primeiro cadastro o usuário acessa o sistema, seleciona <i>GDR</i>, e então poderá incluir a(s) empresa(s).<br><br>
            Qualquer dúvida, por favor, entrar em contato com o DMLU através do email: <b><i><a href="mailto:smca@dmlu.prefpoa.com.br">smca@dmlu.prefpoa.com.br</a></i></b>
        </p-dialog>

        <!-- Info usuário logado -->
        <div *ngIf="usuarioAutenticado" class="login d-flex">
          <div class="info-login">
            <small>
              <strong>{{ nomeUsuario }}</strong>
              <span class="mt-0" *ngIf="entidadeRepresentada != null" >
                <br>
                {{ entidadeRepresentada.razaoSocial }}
              </span>
            </small>
          </div>
          <button
            (click)="logout()"
            class="btn btn-sm btn-header"
            style="white-space: nowrap;"
          >
            Sair<i class="fa fa-sign-out pl-2" aria-hidden="true"></i>
          </button>
        </div>

      </div>
    </div>
  </div>

      <ng-content></ng-content>
<!--
      <a
        class="btn btn-outline-secondary align-self-center ml-4 btn-sm"
        href="../../../assets/doc/glossario.pdf"
        target="_blank"
        ><i class="fa fa-file-pdf-o pr-2"></i>Glossário</a
      > -->

</header>

<!--
<div
  class="w-100 d-none menu bg-secondary"
  style="position:fixed; top:60px; left:0; height: 100vh; z-index:1000;"
  [ngClass]="{ 'd-block d-md-none': showMenu }"
>
  <nav>
    <ul class="list-unstyled text-white text-center">
      <li class="my-3 py-4">
        <div *ngIf="usuarioAutenticado">
          <p class="mb-0">
            <b>{{ nomeUsuario }}</b>
          </p>
          <p class="mt-0" *ngIf="entidadeRepresentada != null" class="small">
            {{ entidadeRepresentada.razaoSocial }}
          </p>
        </div>
      </li>
      <li class="my-3 py-4">Glossário</li>
      <li class="my-3 py-4">
        <a
          *ngIf="usuarioAutenticado"
          (click)="logout()"
          class="sair"
          style="white-space: nowrap;"
        >
          <i class="fa fa-sign-out pr-2"></i>Sair</a
        >
      </li>
    </ul>
  </nav>
</div>

-->
