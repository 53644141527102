import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { MenuItem, SelectItem } from 'primeng/api';
import { Entidade } from 'src/app/@shared/models/entidade.model';
import { EntidadeService } from '../entidade/entidade.service';
import { SpinnerService } from '../services/spinner.service';
import { NotificacoesService } from '../services/notificacoes.service';
import { Globals } from 'src/app/globals';
import { PessoaFisica, PessoaFisicaSecretaria } from 'src/app/@shared/models/pessoaFisica.model';
import { PessoaFisicaService } from '../services/pessoaFisica.service';
import { SmfPessoaService } from '../services/smfPessoa.service';
import { SecretariaPmpa } from 'src/app/@shared/models/secretariaPmpa.model';
import { SecretariaPmpaService } from '../services/secretariaPmpa.service';

@Component({
  templateUrl: './usuariosPmpa.component.html',
  styleUrls: ['./usuariosPmpa.component.scss']
})
export class UsuariosPmpaComponent implements OnInit {

  pessoasFisicasPmpa: PessoaFisica[] = [];
  pfsSecretaria: PessoaFisicaSecretaria[] = [];
  items: MenuItem[];
  nomeUsuario: string;
  entidade: Entidade;
  entidadeNome = '';
  // Novo/edit
  pessoaFisicaEdit: PessoaFisica;
  mostrarDialogEdit: boolean;
  tituloDialogEdit: string;
  isInsert: boolean;
  // Exluir
  pessoaFisicaExcluir: PessoaFisica;
  mostrarDialogExcluir: boolean;

  variaveisGlobais: Globals;
  activeRoute: string;

  secretarias: SelectItem[] = [];
  secretariasPmpa: SecretariaPmpa[] = [];
  idSecretaria: number = null;
  pfFuncao = '';
  pfFuncaoRT: string[];
  mudancaEmail: Boolean = false;
  podeInativar: Boolean = false;
  gdrDmluContrato: Boolean = false;

  constructor(
    private router: Router,
    private keycloakService: KeycloakService,
    private pessoaFisicaService: PessoaFisicaService,
    private entidadeService: EntidadeService,
    private spinnerService: SpinnerService,
    private notificacoesService: NotificacoesService,
    private smfPessoaService: SmfPessoaService,
    private secretariaPmpaService: SecretariaPmpaService,
    private globals: Globals
  ) {

    this.activeRoute = this.router.url;
    this.variaveisGlobais = globals;
  }

  ngOnInit() {

    if (this.variaveisGlobais.menuRaiz === null || this.variaveisGlobais.menuRaiz === '') {
      this.router.navigate(['/sgrIni']);
    } else {
      this.inicializaOnInit();
    }

  }

  inicializaOnInit() {
    this.spinnerService.exibir();
    this.pessoaFisicaEdit = new PessoaFisica();

    this.entidadeService.getEntidadeCnpj('92.963.560/0001-60').subscribe(pmpa => {
      this.entidade = pmpa;
      console.log('inicializaOnInit...........', this.entidade);

      this.buscaPessoasFisicasEntidade(this.entidade.id);
      this.spinnerService.ocultar();

    },
    error => {
      console.log(error);
      this.spinnerService.ocultar();
      this.notificacoesService.notificarErro(error.error, null);
    });

  }

  buscaPessoasFisicasEntidade(idEntidade) {
    this.pessoasFisicasPmpa = [];
    this.pfsSecretaria = [];
    this.pessoaFisicaService.getPessoasFisicasDaEntidade(idEntidade).subscribe(pessoaFisicas => {
        this.pessoasFisicasPmpa = pessoaFisicas;
        this.pfsSecretaria = pessoaFisicas;
        console.log('buscaPessoasFisicasEntidade........pessoas...........', this.pessoasFisicasPmpa);
        let ativos = 0;
        this.pessoasFisicasPmpa.forEach(pessoa => {
          if (pessoa.ativo) {
            ativos = ativos + 1;
            console.log('buscaPessoasFisicasEntidade.....Ativos..............', ativos);
          }
        });
        this.buscaSecretariasPmpa();
        this.podeInativar = false;
        if (ativos > 1) {
          this.podeInativar = true;
        }
        console.log('Pode Inativar...................', this.podeInativar, '---', ativos);
    });

  }

  buscaSecretariasPmpa() {
    this.secretariasPmpa = [];
    this.secretariaPmpaService.getSecretariasPmpa().subscribe(secretarias => {
        this.secretariasPmpa = secretarias;
        secretarias.forEach(sec => {
          this.secretarias.push( {label: sec.sigla, value: sec.id});

          this.pfsSecretaria.forEach(pf => {
            if (pf.idSecretaria === sec.id) {
              pf.sigla = sec.sigla;
            }

          });

        });

      },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });
  }

  logout() {
    const url = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
    this.keycloakService.getKeycloakInstance().logout({redirectUri: url});
  }

  mostrarDialogParaNovo() {
    this.pessoaFisicaEdit = new PessoaFisica();
    this.mostrarDialogEdit = true;
    this.isInsert = true;

    this.tituloDialogEdit = 'Novo Responsável Legal/Usuário/Fiscal';
    this.pfFuncao = 'rSecretario';
    this.pessoaFisicaEdit.ativo = true;
    this.pfFuncaoRT = [];

    this.secretarias = [];
    this.idSecretaria = null;
    this.secretariasPmpa.forEach(sec => {
      this.secretarias.push( {label: sec.sigla, value: sec.id});
    });

  }

  mostrarDialogParaEditar(idPessoaFisica: number) {
    const pfParaEditar = this.pessoasFisicasPmpa.find(function(pf) {
      return pf.id === idPessoaFisica;
    });
    this.pessoaFisicaEdit = {...pfParaEditar};
    console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.pessoaFisicaEdit);

    this.mostrarDialogEdit = true;
    this.isInsert = false;
    this.tituloDialogEdit = 'Editar Responsável Legal/Usuário/Fiscal';

    this.pfFuncaoRT = [];
    this.pfFuncao = '';
    if (this.pessoaFisicaEdit.rSecretario) {
      this.pfFuncao = 'rSecretario';
    }
    if (this.pessoaFisicaEdit.rLegal) {
      this.pfFuncao = 'rLegal';
    }
    if (this.pessoaFisicaEdit.rusrLegal) {
      this.pfFuncao = 'rusrLegal';
    }
    if (this.pessoaFisicaEdit.rtElaboracao && this.pessoaFisicaEdit.rtExecucao) {
      this.pfFuncaoRT = ['rElaboracao', 'rExecucao'];

    } else {
      if (this.pessoaFisicaEdit.rtElaboracao) {
        this.pfFuncaoRT = ['rElaboracao'];
      }
      if (this.pessoaFisicaEdit.rtExecucao) {
        this.pfFuncaoRT = ['rExecucao'];
      }
    }

    this.secretarias = [];
    this.secretariasPmpa.forEach(sec => {
      this.secretarias.push( {label: sec.sigla, value: sec.id});
    });
    this.idSecretaria = this.pessoaFisicaEdit.idSecretaria;

  }

  controlaFuncao(funcao) {
    console.log('.......controlaFuncao>>>>' , funcao);
    if (funcao === 'rSecretario' || funcao === 'rLegal' || funcao === 'rusrLegal') {
      this.pfFuncaoRT = [];
    } else {
      this.pfFuncao = '';
    }

  }

  mostrarDialogEditParaExcluir(idProduto: number) {
    // this.produtoExcluir =  this.produtos.find(function(produto) {
    //   return produto.id === idProduto;
    // });
    // this.mostrarDialogExcluir = true;
  }

  salvar() {

    this.pessoaFisicaEdit['idEntidade'] = this.entidade.id;

    this.pessoaFisicaEdit.rSecretario = false;
    this.pessoaFisicaEdit.rLegal = false;
    this.pessoaFisicaEdit.rusrLegal = false;
    this.pessoaFisicaEdit.rtElaboracao = false;
    this.pessoaFisicaEdit.rtExecucao = false;
    if (this.pfFuncao === 'rSecretario') {
      this.pessoaFisicaEdit.rSecretario = true;
    }
    if (this.pfFuncao === 'rLegal') {
      this.pessoaFisicaEdit.rLegal = true;
    }
    if (this.pfFuncao === 'rusrLegal') {
      this.pessoaFisicaEdit.rusrLegal = true;
    }
    if (this.pfFuncaoRT.length > 1) {
      this.pessoaFisicaEdit.rtElaboracao = true;
      this.pessoaFisicaEdit.rtExecucao = true;
    } else if (this.pfFuncaoRT.length > 0) {
      if (this.pfFuncaoRT[0] === 'rElaboracao') {
        this.pessoaFisicaEdit.rtElaboracao = true;
      }
      if (this.pfFuncaoRT[0] === 'rExecucao') {
        this.pessoaFisicaEdit.rtExecucao = true;
      }
    }

    let okSalvar = true;

    console.log('..........salvarPessoaFisica........listaPF..............>>', this.pessoaFisicaEdit);
    this.secretariasPmpa.forEach(secr => {
      if (secr.id === this.idSecretaria) {
        this.pessoaFisicaEdit.secretariaPmpa = secr;
      }

    });
    if (this.pessoaFisicaEdit.secretariaPmpa === undefined || this.pessoaFisicaEdit.secretariaPmpa === null) {
      this.notificacoesService.notificarAviso('Por favor, informar a Secretaria/Órgão!', null );
      okSalvar = false;
    }

    if (okSalvar) {
      console.log('salvarPessoaFisica........listaPF..............>>', this.pessoaFisicaEdit);
      if (this.pessoaFisicaEdit.id == null) {
        this.pessoaFisicaEdit.ativo = true;
        this.pessoaFisicaService.criarPessoaFisica(this.pessoaFisicaEdit).subscribe(
          retorno => {
            this.spinnerService.ocultar();
            this.notificacoesService.notificarSucesso('PessoaFisica salvo com sucesso', null );
            this.globals.pessoaFisica = retorno;
            if (this.pessoaFisicaEdit.id === null) {
              this.pessoasFisicasPmpa.push(retorno);
            }
            this.pessoaFisicaEdit = retorno;
            //this.pessoaFisicaEdit = null;
            this.buscaPessoasFisicasEntidade(this.entidade.id);
            this.mostrarDialogEdit = false;
            this.isInsert = false;

          },
          error => {
            console.log(error);
            this.spinnerService.ocultar();
            this.notificacoesService.notificarErro(error.error, null);
          }
        );
      } else {
        // let permiteInativar = false;
        // let permiteAtualizarRl = false;
        // if (this.pessoaFisicaEdit.ativo && this.pessoaFisicaEdit.rLegal) {
        //   permiteAtualizarRl = true;
        //   permiteInativar = true;
        // } else {
        //   this.pessoasFisicasPmpa.forEach(pessoaF => {
        //       if (this.pessoaFisicaEdit.id !== pessoaF.id) {
        //         if (pessoaF.rLegal) {
        //           permiteAtualizarRl = true;
        //           if (pessoaF.ativo) {
        //             permiteInativar = true;
        //           }
        //         }
        //       }
        //   });
        // }
        // if (permiteAtualizarRl && permiteInativar) {

          this.mudancaEmail = false;
          this.pessoasFisicasPmpa.forEach(pfOriginal => {
            if (pfOriginal.id === this.pessoaFisicaEdit.id) {
              if (pfOriginal.email !== this.pessoaFisicaEdit.email) {
                this.mudancaEmail = true;
              }
            }
          });

          if (!this.mudancaEmail) {
            this.atualizaPessoaFisica(this.pessoaFisicaEdit);
            this.mostrarDialogEdit = false;
            this.isInsert = false;
          }

        // } else {
        //   this.spinnerService.ocultar();
        //   this.notificacoesService.notificarAviso('A Empresa deve possuir ao menos um Responsável Legal ATIVO!', null );
        //   this.pessoaFisicaEdit.ativo = true;
        // }
      }
    }
  }

  atualizaPessoaFisica(pessoaFisica) {
    this.mudancaEmail = false;
    // pessoaFisica.secretariaPmpa = null;

    this.pessoaFisicaService.atualizarPessoaFisica(pessoaFisica).subscribe(
      retorno => {
        console.log('atualizaPessoaFisica.....................>>', retorno);
        // this.mostrarDialogEdit = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso('PessoaFisica salvo com sucesso', null );
        this.globals.pessoaFisica = retorno;
        this.pessoaFisicaEdit = retorno;
        this.mostrarDialogEdit = false;
        this.buscaPessoasFisicasEntidade(this.entidade.id);

      },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      }
    );

  }

  excluir() {
    // this.spinnerService.exibir();
    // console.log('salvar....................>>>>>>>>>>>>>>', this.variaveisGlobais.menuRaiz);

    // this.produtoService.excluirProduto(this.produtoExcluir, this.variaveisGlobais.menuRaiz).subscribe(produto => {
    //   if (produto == null) {
    //     // Remove o valor atual
    //     this.produtos = this.produtos.filter(item => item.id !== this.produtoExcluir.id);
    //   }
    //   this.mostrarDialogExcluir = false;
    //   this.spinnerService.ocultar();
    //   this.notificacoesService.notificarSucesso('Excluído com sucesso', null);
    // },
    // error => {
    //   console.log(error);
    //   this.spinnerService.ocultar();
    //   this.notificacoesService.notificarErro('Erro ao exluir', error.error);
    // });
  }

  verificaCpfExiste(cpf: string) {

    if (cpf === '__.___.___-__') {
      console.log('111verificaCpfExiste>>>>>>>', cpf);
      cpf = null;
    }

    console.log('222verificaCpfExiste>>>>>>>', cpf);
    if (cpf == null || cpf === '') {
      // this.spinnerService.ocultar();
      // this.notificacoesService.notificarErro('Não encontrou entidade para CNPJ informado!', null);
    } else {

      this.pessoaFisicaService.getPessoasFisicasPorCpf(cpf).subscribe(pessoaFisica => {

        console.log('verificaCnpjExiste1111>>>>>>>', pessoaFisica);

        if (pessoaFisica != null) {
          this.pessoaFisicaEdit = pessoaFisica;
          this.pessoaFisicaEdit.ativo = true;
          this.isInsert = false;
          // this.spinnerService.ocultar();
          // this.notificacoesService.notificarErro('Empresa com CNPJ informado já está cadastrada!', null);
        } else {
          const novoCpf = cpf.replace(/[^\d]+/g, '');
          // Acessa SMF
          this.smfPessoaService.getPessoaFazenda(novoCpf, '1').subscribe(pessoa => {
            console.log('ACESSO PESSOA SMF>>>>>>>>', pessoa);
            this.pessoaFisicaEdit.nomeCompleto = pessoa.nomePessoa;
          });
        }

      },
        error => {
          console.log(error);
          console.log(error.error);
          this.pessoaFisicaEdit.cpf = null;
          this.spinnerService.ocultar();
          this.notificacoesService.notificarErro(error.error, null);
        });
    }

  }

  checkActive(route: string) {
    return route === this.activeRoute;
  }

  selSecretaria(secretaria) {

    console.log('Sel Secretaria..............>>>', secretaria);

    this.gdrDmluContrato = false;
    if (secretaria === 'DMLU') {
      this.gdrDmluContrato = true;
    }

  }
}
