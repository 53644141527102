var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ptBr } from 'src/app/@shared/utils/calendar';
import * as jspdf from 'jspdf';
import { SpinnerService } from '../../services/spinner.service';
import { NotificacoesService } from '../../services/notificacoes.service';
import { DmluService } from '../dmlu.service';
import { EntidadeService } from '../../entidade/entidade.service';
import { EnderecoService } from '../../services/endereco.service';
import { PessoaFisicaService } from '../../services/pessoaFisica.service';
import { ProdutoService } from '../../produto/produto.service';
import { Globals } from 'src/app/globals';
import { Certificado } from 'src/app/@shared/models/certificado.model';
import { PlanoService } from '../../plano/plano.service';
var CertificadoComponent = /** @class */ (function () {
    function CertificadoComponent(router, route, spinnerService, notificacoesService, dmluService, entidadeService, enderecoService, pessoaFisicaService, produtoService, planoService, globals) {
        this.router = router;
        this.route = route;
        this.spinnerService = spinnerService;
        this.notificacoesService = notificacoesService;
        this.dmluService = dmluService;
        this.entidadeService = entidadeService;
        this.enderecoService = enderecoService;
        this.pessoaFisicaService = pessoaFisicaService;
        this.produtoService = produtoService;
        this.planoService = planoService;
        this.globals = globals;
        this.submitted = false;
        this.contratoId = null;
        this.certificados = [];
        this.temCertificado = false;
        this.adimplente = false;
        this.temEndereco = false;
        this.temResponsavel = false;
        this.abaEmpresa = true;
        this.abaResiduo = false;
        this.abaInfos = false;
        this.abaGerar = false;
        this.placaVeiculo = '';
        this.adimplenteInadimplente = 'adimplente';
        this.mtrFepamIsento = 'mtrFepam';
        this.isUnicoOuMultiplosGeradores = false;
        this.qtdGerador = 'unico';
        this.residuos = [];
        this.residuosSelecionados = [];
        this.insertMode = false;
        this.mostrarDialogEdit = false;
        this.tituloDialogEdit = 'Certificado de Identificação de Gerador';
        this.idEntidade = null;
        this.activeRoute = this.router.url;
        this.variaveisGlobais = globals;
        this.idEntidade = route.snapshot.params['id'];
    }
    CertificadoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.variaveisGlobais = this.globals;
        this.variaveisGlobais.menuRaiz = this.router.url.split('/')[1];
        this.entidade = this.variaveisGlobais.entidade;
        if (this.globals.entidade == null) {
            this.globals.recuperaKeycloak().subscribe(function (user) {
                if (_this.globals.roleEntidade) {
                    if (_this.variaveisGlobais.pessoaFisica === null) {
                        _this.globals.recuperaEntidadePessoaFisica().subscribe(function (entidade) {
                            _this.variaveisGlobais = _this.globals;
                            _this.inicializaOnInit();
                        });
                    }
                    else {
                        _this.inicializaOnInit();
                    }
                }
                else {
                    _this.inicializaOnInit();
                }
            });
        }
        else {
            this.inicializaOnInit();
        }
    };
    CertificadoComponent.prototype.inicializaOnInit = function () {
        var _this = this;
        this.certificado = new Certificado();
        this.languageCalendar = ptBr;
        // this.mostrarDialogEdit = true;
        if (this.certificado !== null) {
            this.buscaCertificados();
        }
        if (this.entidade !== null) {
            this.entidadeService.getEntidadePorId(this.idEntidade).subscribe(function (entidade) {
                _this.entidade = entidade;
                _this.spinnerService.ocultar();
                console.log('inicializaOnInit>>>>>>>>>>>>>>', _this.entidade);
                _this.adimplente = false;
                _this.adimplenteInadimplente = 'inadimplente';
                if (entidade != null) {
                    _this.entidade = entidade;
                    _this.buscaContratoValido();
                    // this.buscaPlanos(this.entidade.id);
                    if (_this.entidade.adimplenteDmlu) {
                        _this.adimplente = true;
                        _this.adimplenteInadimplente = 'adimplente';
                    }
                }
                _this.nomeUsuario = _this.variaveisGlobais.userFullName;
                _this.variaveisGlobais.entidade = _this.entidade;
            }, function (error) {
                console.log(error);
                console.log(error.error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    CertificadoComponent.prototype.buscaCertificados = function () {
        var _this = this;
        this.dmluService.getCertificadosIdEntidade(this.idEntidade).subscribe(function (certificados) {
            // console.log('buscaCertificados ..........>>>>>>>', certificados);
            _this.certificados = certificados;
            _this.spinnerService.ocultar();
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    CertificadoComponent.prototype.buscaContratoValido = function () {
        var _this = this;
        this.dmluService.getContratoValido(this.idEntidade).subscribe(function (contrato) {
            _this.contrato = contrato;
            _this.spinnerService.ocultar();
            // console.log('inicializaOnInit ..... buscaContratoValido.....>>>>>>>', contrato);
            _this.buscaEndereco(contrato.id);
            _this.buscaResponsavel(contrato.id);
            _this.buscaResiduosContrato(contrato.id);
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    CertificadoComponent.prototype.buscaResponsavel = function (idContrato) {
        var _this = this;
        //    BUSCA PessoaFisica
        this.pessoaFisicaService.getResponsavelLegalContrato(idContrato).subscribe(function (rlegal) {
            _this.responsavelContrato = rlegal;
            console.log('buscaResponsavel.....>>>>>>>', rlegal);
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    CertificadoComponent.prototype.buscaEndereco = function (idContrato) {
        var _this = this;
        //    BUSCA PessoaFisica
        this.enderecoService.getEnderecoContrato(idContrato).subscribe(function (endereco) {
            _this.enderecoContrato = endereco;
            console.log('buscaEndereco.....>>>>>>>', endereco);
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    CertificadoComponent.prototype.buscaResiduosContrato = function (idContrato) {
        var _this = this;
        // Carrega os residuos
        this.produtoService.getProdutosDoContrato(idContrato, this.variaveisGlobais.roleEntidade).subscribe(function (residuos) {
            console.log('Cadastro Inicializa Resíduos>>>>>>>>>>>>>>', residuos);
            _this.spinnerService.ocultar();
            _this.residuos = residuos;
            _this.residuos.sort(function (a, b) {
                if (a.nome > b.nome) {
                    return 1;
                }
                if (a.nome < b.nome) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }, function (error) {
            _this.notificacoesService.notificarErro('Erro ao obter resíduos', error.message);
        });
    };
    // buscaPlanos(idEntidade: number) {
    //   this.planoService.getPlanosDaEntidade(idEntidade, new Date().getFullYear()).subscribe(planos => {
    //     this.planos = planos;
    //     console.log('buscaPlanos.....>>>>>>>', planos);
    //   },
    //   error => {
    //     console.log(error);
    //     console.log(error.error);
    //     this.spinnerService.ocultar();
    //     this.notificacoesService.notificarErro(error.error, null);
    //   });
    // }
    CertificadoComponent.prototype.checkActive = function (route) {
        return route === this.activeRoute;
    };
    CertificadoComponent.prototype.onSubmit = function () {
        // this.ngOnInit();
    };
    CertificadoComponent.prototype.mostrarDialogParaNovo = function () {
        this.certificado = new Certificado();
        this.mostrarDialogEdit = true;
        this.insertMode = true;
        this.tituloDialogEdit = 'Novo Certificado de Identificação de Gerador';
        this.abaEmpresa = true;
        this.abaResiduo = false;
        this.abaInfos = false;
        this.abaGerar = false;
        this.isUnicoOuMultiplosGeradores = false;
        console.log('mostrarDialogParaNovo>>>>>>>>>>>>>>', this.certificado);
    };
    CertificadoComponent.prototype.mostrarDialogParaEditar = function (idCertificado) {
        var certificadoParaEditar = this.certificados.find(function (certificado) {
            return certificado.id === idCertificado;
        });
        this.certificado = __assign({}, certificadoParaEditar);
        console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.certificado);
        this.mostrarDialogEdit = true;
        this.insertMode = false;
        this.abaEmpresa = true;
        this.abaResiduo = false;
        this.abaInfos = false;
        this.abaGerar = false;
        this.isUnicoOuMultiplosGeradores = false;
        this.horaRecebimento = null;
        this.placaVeiculo = '';
    };
    CertificadoComponent.prototype.salvar = function () {
        var _this = this;
        this.certificado.contratante = this.entidade;
        this.certificado.isentoMtrFepam = false;
        if (this.mtrFepamIsento === 'mtrIsento') {
            this.certificado.isentoMtrFepam = true;
        }
        // Se novo
        if (this.certificado.id == null) {
            this.dmluService.novoCertificado(this.certificado).subscribe(function (certificadoNovo) {
                console.log('salvado....................>>>>>>>>>>>>>>', certificadoNovo);
                _this.buscaCertificados();
                _this.mostrarDialogEdit = false;
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarSucesso('Adicionado com sucesso', null);
                if (_this.variaveisGlobais.roleEntidade && _this.certificado.placaVeiculo === null) {
                    _this.pdfListaManifesto(certificadoNovo, certificadoNovo.isentoMtrFepam);
                }
            }, function (error) {
                _this.spinnerService.ocultar();
                console.log('ERRO....................>>>>>>>>>>>>>>', error.error);
                _this.notificacoesService.notificarErro('Erro ao adicionar ', error.error[0]);
            });
        }
        else { // Se editando
            // this.certificado.recebimento.setHours(new Date(this.horaRecebimento).getHours());
            // this.certificado.recebimento.setMinutes(new Date(this.horaRecebimento).getMinutes());
            // this.certificado.placaVeiculo = this.placaVeiculo;
            // console.log('atualizado....................>>>>>>>>>>>>>>', this.certificado);
            // this.dmluService.editarCertificado(this.certificado).subscribe(certificadoEditado => {
            //   this.buscaCertificados();
            //   this.mostrarDialogEdit = false;
            //   this.spinnerService.ocultar();
            //   this.notificacoesService.notificarSucesso('Atualizado com sucesso', null);
            // },
            // error => {
            //   console.log(error);
            //   this.spinnerService.ocultar();
            //   this.notificacoesService.notificarErro(error.error, null);
            // });
        }
    };
    CertificadoComponent.prototype.empresaAdimplenteInadimplente = function () {
        var _this = this;
        this.spinnerService.exibir();
        var adimplente = false;
        if (this.adimplenteInadimplente === 'adimplente') {
            adimplente = true;
        }
        this.dmluService.empresaAdimplenteInadimplente(this.entidade.id, adimplente).subscribe(function (entidade) {
            _this.entidade = entidade;
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarSucesso('Atualizada com sucesso!', null);
            // this.notificacoesService.notificarSucesso('Código Balança gerado com sucesso', null);
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    CertificadoComponent.prototype.unicoOuMultiplosGeradores = function (idCertificado) {
        var certificadoPdf = this.certificados.find(function (certificado) {
            return certificado.id === idCertificado;
        });
        this.certificado = __assign({}, certificadoPdf);
        this.isUnicoOuMultiplosGeradores = true;
    };
    CertificadoComponent.prototype.pdfListaManifesto = function (certificado, isentoMTR) {
        // console.log('-------------------------------->>>>>', this.qtdGerador);
        this.isUnicoOuMultiplosGeradores = false;
        var doc = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
        this.pdfInicial(doc, certificado, true);
        var img = new Image();
        // let linhas = 0;
        var paginas = 1;
        var numLinha = 45;
        // doc.setFontSize(10);
        // doc.setFontType('normal');
        doc.rect(15, numLinha, 180, 20);
        numLinha = numLinha + 5; // 55
        doc.setFontSize(12);
        doc.setFontType('bold');
        doc.text(18, numLinha, 'NOME DO GERADOR: ');
        doc.setFontType('normal');
        doc.text(64, numLinha, this.entidade.razaoSocial);
        numLinha = numLinha + 6; // 61
        doc.setFontType('bold');
        doc.text(18, numLinha, 'CNPJ: ');
        doc.setFontType('normal');
        doc.text(33, numLinha, this.entidade.cnpj);
        doc.setFontType('bold');
        doc.text(80, numLinha, 'TELEFONE: ');
        doc.setFontType('normal');
        if (this.entidade.telefone !== null && this.entidade.telefone !== '') {
            doc.text(111, numLinha, this.entidade.telefone);
        }
        doc.setFontType('bold');
        doc.text(147, numLinha, 'COD. DMLU: ');
        doc.setFontType('normal');
        if (this.entidade.codigoDmlu !== null) {
            doc.text(174, numLinha, this.entidade.codigoDmlu.toString());
        }
        numLinha = numLinha + 6; // 67
        doc.setFontType('bold');
        doc.text(18, numLinha, 'ENDEREÇO: ');
        doc.setFontType('normal');
        if (this.enderecoContrato.enderecoFormatado !== null && this.enderecoContrato.enderecoFormatado !== '') {
            doc.text(46, numLinha, this.enderecoContrato.enderecoFormatado);
        }
        // RESÍDUOS
        // let iniAltura = numLinha + 5;
        // let fimAltura = 0;
        // numLinha = numLinha + 9; // 78
        // doc.setFontType('bold');
        // doc.text(18, numLinha, 'Relação dos resíduos que serão transportados pelo usuário.');
        // numLinha = numLinha + 6; // 84
        // fimAltura = fimAltura + 6;
        // doc.setFontType('normal');
        // doc.text(18, numLinha, this.contrato.outrosResiduos);
        // fimAltura = fimAltura + 6;
        // numLinha = numLinha + 6;
        // doc.rect(15, iniAltura, 180, fimAltura);
        var iniAltura = numLinha + 5;
        var fimAltura = 0;
        numLinha = numLinha + 9; // 78
        var index = 0;
        this.residuos.forEach(function (residuo) {
            if (residuo.gdrTransbordo) {
                if (residuo.ativo) {
                    if (fimAltura === 0) {
                        doc.setFontType('bold');
                        doc.text(18, numLinha, 'Estação de Transbordo');
                        numLinha = numLinha + 6; // 84
                        fimAltura = fimAltura + 6;
                        doc.setFontType('normal');
                    }
                    index = index + 1;
                    doc.text(18, numLinha, index + ')');
                    doc.text(23, numLinha, residuo.nome);
                    fimAltura = fimAltura + 6;
                    numLinha = numLinha + 6;
                }
            }
        });
        doc.rect(15, iniAltura, 180, fimAltura);
        iniAltura = numLinha - 2;
        fimAltura = 0;
        numLinha = numLinha + 2;
        index = 0;
        this.residuos.forEach(function (residuo) {
            if (residuo.gdrCompostagem) {
                if (residuo.ativo) {
                    if (fimAltura === 0) {
                        doc.setFontType('bold');
                        doc.text(18, numLinha, 'Unidade de Compostagem');
                        numLinha = numLinha + 6;
                        fimAltura = fimAltura + 6;
                        doc.setFontType('normal');
                    }
                    index = index + 1;
                    doc.text(18, numLinha, index + ')');
                    doc.text(23, numLinha, residuo.nome);
                    fimAltura = fimAltura + 6;
                    numLinha = numLinha + 6;
                }
            }
        });
        doc.rect(15, iniAltura, 180, fimAltura);
        iniAltura = numLinha - 2;
        fimAltura = 0;
        numLinha = numLinha + 2;
        index = 0;
        this.residuos.forEach(function (residuo) {
            if (residuo.gdrTriagem) {
                if (residuo.ativo) {
                    if (fimAltura === 0) {
                        doc.setFontType('bold');
                        doc.text(18, numLinha, 'Unidade de Triagem');
                        numLinha = numLinha + 6;
                        fimAltura = fimAltura + 6;
                        doc.setFontType('normal');
                    }
                    index = index + 1;
                    doc.text(18, numLinha, index + ')');
                    doc.text(23, numLinha, residuo.nome);
                    fimAltura = fimAltura + 6;
                    numLinha = numLinha + 6;
                }
            }
        });
        doc.rect(15, iniAltura, 180, fimAltura);
        iniAltura = numLinha - 2;
        numLinha = numLinha - 2;
        doc.rect(15, numLinha, 110, 27);
        numLinha = numLinha + 6;
        doc.setFontType('bold');
        doc.text(18, numLinha, 'Data:');
        doc.text(73, numLinha, 'Horário:');
        doc.setFontType('normal');
        doc.text(30, numLinha, '____/____/_______');
        doc.text(93, numLinha, '____:____');
        numLinha = numLinha + 6;
        doc.setFontType('bold');
        doc.text(18, numLinha, 'Peso Líquido:');
        doc.text(73, numLinha, 'Placa Veículo:');
        doc.setFontType('normal');
        doc.text(48, numLinha, '______ Kg');
        doc.text(103, numLinha, '________');
        numLinha = numLinha + 6;
        doc.setFontType('bold');
        doc.text(18, numLinha, 'Matrícula:');
        doc.setFontType('normal');
        doc.text(40, numLinha, '_____________');
        numLinha = numLinha + 6;
        doc.setFontType('bold');
        doc.text(18, numLinha, 'Apontador:');
        doc.setFontType('normal');
        doc.text(43, numLinha, '__________________________________');
        numLinha = numLinha + 6;
        doc.rect(127, iniAltura, 68, 27);
        doc.setFontType('bold');
        doc.setFontSize(8);
        doc.text(129, iniAltura + 4, 'Assinatura e carimbo do contratante gerador');
        doc.rect(15, numLinha - 1, 180, 87);
        numLinha = numLinha + 3;
        doc.setFontSize(10);
        doc.setFontType('normal');
        doc.text(17, numLinha, 'Declaro estar ciente de que');
        doc.setFontType('bold');
        doc.text(61, numLinha, 'os resíduos sólidos autorizados para destinação nas unidades do DMLU'
            + ' são');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'tão somente aqueles efetivamente cadastrados para o gerador,');
        doc.setFontType('normal');
        doc.text(124, numLinha, 'os quais deverão ser encaminhados à(s)');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'unidade(s) respectivamente(s) relacionada(s) para tal. ');
        doc.text(103, numLinha, 'Estou ciente de que é vedada a destinação de quaisquer ');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'outros resíduos para tais unidades, sem autorização prévia da equipe técnica '
            + 'e reavaliação do cadastro técnico.');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'A licença de operação vigente da unidade, permite o recebimento');
        doc.setFontType('bold');
        doc.text(122, numLinha, 'apenas de resíduos similares aos');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'domiciliares.');
        doc.setFontType('normal');
        numLinha = numLinha + 7;
        doc.text(17, numLinha, 'Declaro que, no cumprimento do previsto pelo Decreto Estadual 38.356 de '
            + '01.04.1998, regulamentador da Lei');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'Nº 9921 de 27.07.1993, suas alterações e substituições, ');
        doc.setFontType('bold');
        doc.text(106, numLinha, 'resíduos com potencial de reciclagem ou');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'reaproveitamento gerados pelo estabelecimento não poderão ser destinados à unidade de transbordo, ');
        numLinha = numLinha + 4;
        doc.setFontType('normal');
        doc.text(17, numLinha, 'devendo ser encaminhados para unidades de triagem, para reciclagem '
            + 'e/ou reaproveitamento.');
        numLinha = numLinha + 7;
        doc.text(17, numLinha, 'Estou ciente de que ');
        doc.setFontType('bold');
        doc.text(49, numLinha, 'é expressamente proibida a entrada de resíduos Classe I, resíduos industriais '
            + 'Classe');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'II ');
        doc.setFontType('normal');
        doc.text(20, numLinha, '(conforme Resolução CONSEMA 73/2004, suas alterações e substituições), '
            + 'bem como ');
        doc.setFontType('bold');
        doc.text(158, numLinha, 'resíduos de');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'construção civil ');
        doc.setFontType('normal');
        doc.text(45, numLinha, '(conforme resoluções ');
        doc.text(81, numLinha, 'CONAMA 307/2002, 448/2012 e 469/2015, suas alterações e');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'substituições), nas unidades do DMLU.');
        numLinha = numLinha + 7;
        doc.text(17, numLinha, 'Estou ciente de que o recebimento das minhas cargas de resíduos encaminhadas somente será possível a');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'partir da apresentação do ');
        doc.setFontType('bold');
        doc.text(59, numLinha, 'Certificado de Identificação de Gerador de Resíduos Sólidos ');
        doc.setFontType('normal');
        doc.text(162, numLinha, 'devidamente');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'preenchido e assinado, acompanhado do ');
        doc.setFontType('bold');
        doc.text(83, numLinha, 'Manifesto de Transporte de Resíduos ou Declaração de Isenção. ');
        numLinha = numLinha + 4;
        doc.setFontType('normal');
        doc.text(17, numLinha, 'A disposição conjunta com outros geradores, bem como as ações de empresa(s) contratada(s) para manejo e/ou');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'transporte de resíduos inserem responsabilidade solidária a todos os envolvidos.');
        img = new Image();
        img.src = 'assets/img/logoProcempa.png';
        doc.addImage(img, 'png', 155, 285, 40, 5);
        doc.text(15, 290, paginas.toString());
        doc.text(20, 290, 'Secretaria Municipal de Meio Ambiente, Urbanismo e Sustentabilidade – SMAMUS');
        if (isentoMTR) {
            doc.addPage();
            if (this.qtdGerador === 'unico') {
                this.pdfInicial(doc, certificado, false);
                this.ciunicogerador(doc, certificado);
            }
        }
        if (this.qtdGerador === 'multiplos') {
            this.pdfInicial(doc, certificado, false);
            this.cimultiplosgeradores(doc, certificado);
        }
        doc.save('GDR_Certificado_' + new Date().toLocaleDateString() + '_'
            + new Date().getHours() + '_' + new Date().getMinutes() + '.pdf');
    };
    CertificadoComponent.prototype.pdfInicial = function (doc, certificado, isValidade) {
        doc.setFontSize(14);
        var img = new Image();
        img.src = 'assets/img/logo_pmpa.png';
        doc.addImage(img, 'png', 12, 15, 17, 15);
        img = new Image();
        img.src = 'assets/img/logoDMLU.png';
        doc.addImage(img, 'png', 180, 15, 17, 15);
        doc.text(35, 19, 'PREFEITURA MUNICIPAL DE PORTO ALEGRE');
        doc.text(35, 24, 'DMLU - DEPARTAMENTO MUNICIPAL DE LIMPEZA URBANA');
        doc.text(35, 29, 'DIRETORIA DE DESTINO FINAL');
        if (isValidade) {
            doc.text(15, 40, 'CERTIFICADO DE IDENTIFICAÇÃO DE GERADOR');
            doc.setFontType('bold');
            doc.setFontSize(14);
            doc.text(138, 40, 'Validade: ');
            var mesValidade = new Date(certificado.dataInclusao).getMonth() + 1;
            doc.text(162, 40, mesValidade + '/' +
                new Date(certificado.dataInclusao).getFullYear().toString());
        }
        // const dataInc = new Date(certificadoNovo.dataInclusao);
        // console.log('........................', this.certificado);
        // const mesano = dataInc.getMonth().toString() + '/' + dataInc.getFullYear().toString();
        // console.log('........................****', mesano);
        // doc.text(162, 40, mesano);
    };
    CertificadoComponent.prototype.cimultiplosgeradores = function (doc, certificado) {
        var img = new Image();
        // let linhas = 0;
        var paginas = 2;
        var numLinha = 35;
        // doc.setFontSize(10);
        // doc.setFontType('normal');
        doc.rect(15, numLinha, 180, 110);
        numLinha = numLinha + 7; // 55
        doc.setFontSize(14);
        doc.setFontType('bold');
        doc.text(58, numLinha, 'DECLARAÇÃO DE INSENÇÃO DE MTR');
        numLinha = numLinha + 6;
        doc.setFontSize(12);
        doc.text(48, numLinha, '(CARGA CONSOLIDADA DE MÚLTIPLOS GERADORES)');
        doc.setFontSize(10);
        numLinha = numLinha + 10;
        doc.setFontType('normal');
        doc.text(17, numLinha, 'Declaro, para os devidos fins, que ');
        doc.setFontType('bold');
        doc.text(72, numLinha, 'esta carga de resíduos sólidos apresentados junto à Estação de ');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'Transbordo Lomba do Pinheiro - ETLP, ');
        doc.setFontType('normal');
        doc.text(85, numLinha, 'para fins de destinação final a aterro sanitário, ');
        doc.setFontType('bold');
        doc.text(159, numLinha, 'foi consolidada');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'por este transportador,');
        doc.setFontType('normal');
        doc.text(57, numLinha, 'o qual assume perante o DMLU, a responsabilidade sobre a carga destinada e sobre');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'a veracidade das informações aqui prestadas.');
        numLinha = numLinha + 7;
        doc.text(17, numLinha, 'Declaro, para os devidos fins, que ');
        doc.setFontType('bold');
        doc.text(72, numLinha, 'esta carga é proveniente de geradores diversos,');
        doc.setFontType('normal');
        doc.text(155, numLinha, 'abaixo relacionados,');
        numLinha = numLinha + 4;
        doc.setFontType('bold');
        doc.text(17, numLinha, 'caracterizados em função de seu porte como isentos de apresentação de Manifesto de Transporte de');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'Resíduos ');
        doc.setFontType('normal');
        doc.text(34, numLinha, 'conforme estabelecido na Portaria FEPAM 87/2018, art. 4º, inc. XV, alterada pela Portaria ');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'FEPAM 12/2020.');
        numLinha = numLinha + 7;
        doc.text(17, numLinha, 'Declaro estar ciente de que ');
        doc.setFontType('bold');
        doc.text(62, numLinha, 'os resíduos sólidos autorizados para destinação na ETLP são apenas os');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'resíduos similares em composição e características aos domiciliares, ');
        doc.setFontType('normal');
        doc.text(135, numLinha, 'sendo vedado o encaminhamento');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'de quaisquer outros resíduos.');
        numLinha = numLinha + 7;
        doc.text(17, numLinha, 'Declaro estar ciente de que ');
        doc.setFontType('bold');
        doc.text(61, numLinha, 'é expressamente proibida a entrada no transbordo de resíduos Classe I,');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'resíduos industriais Classe II provenientes de processos produtivos ');
        doc.setFontType('normal');
        doc.text(133, numLinha, '(conforme Resolução CONSEMA');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, '73/2004, suas alterações e substituições), bem como ');
        doc.setFontType('bold');
        doc.text(102, numLinha, 'resíduos de construção civil ');
        doc.setFontType('normal');
        doc.text(151, numLinha, '(conforme resoluções');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'CONAMA 307/2002, 448/2012 e 469/2015, suas alterações e substituições).');
        numLinha = numLinha + 7;
        doc.text(17, numLinha, 'Declaro estar ciente de que os ');
        doc.setFontType('bold');
        doc.text(66, numLinha, 'resíduos com potencial de reciclagem ou reaproveitamento não poderão ser');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'destinados à estação de transbordo, ');
        doc.setFontType('normal');
        doc.text(80, numLinha, 'devendo ser encaminhados para unidades de triagem, para reciclagem');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'e/ou reaproveitamento, no cumprimento do previsto pelo Decreto Estadual 38.356 de 01.04.1998,');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'regulamentador da Lei Nº 9921 de 27.07.1993, suas alterações e substituições.');
        numLinha = numLinha + 12;
        doc.setFontSize(12);
        doc.text(18, numLinha, 'Gerador 1: ___________________________________________ CNPJ: ________________');
        numLinha = numLinha + 7;
        doc.text(18, numLinha, 'Gerador 2: ___________________________________________ CNPJ: ________________');
        numLinha = numLinha + 7;
        doc.text(18, numLinha, 'Gerador 3: ___________________________________________ CNPJ: ________________');
        numLinha = numLinha + 7;
        doc.text(18, numLinha, 'Gerador 4: ___________________________________________ CNPJ: ________________');
        numLinha = numLinha + 7;
        doc.text(18, numLinha, 'Gerador 5: ___________________________________________ CNPJ: ________________');
        numLinha = numLinha + 7;
        doc.text(18, numLinha, 'Gerador 6: ___________________________________________ CNPJ: ________________');
        numLinha = numLinha + 7;
        doc.text(18, numLinha, 'Gerador 7: ___________________________________________ CNPJ: ________________');
        numLinha = numLinha + 7;
        doc.text(18, numLinha, 'Gerador 8: ___________________________________________ CNPJ: ________________');
        numLinha = numLinha + 7;
        doc.text(18, numLinha, 'Gerador 9: ___________________________________________ CNPJ: ________________');
        numLinha = numLinha + 7;
        doc.text(18, numLinha, 'Gerador 10: __________________________________________ CNPJ: ________________');
        numLinha = numLinha + 15;
        doc.text(18, numLinha, 'Transportador: ____________________________');
        numLinha = numLinha + 7;
        doc.text(18, numLinha, 'CNPJ: ___________________________________');
        doc.rect(117, numLinha - 13, 76, 29);
        doc.setFontSize(8);
        doc.text(118, numLinha - 10, 'Carimbo e assinatura do representante legal transportador:');
        numLinha = numLinha + 15;
        doc.setFontSize(12);
        doc.text(18, numLinha, 'Data do recebimento: _____/_____/_____');
        numLinha = numLinha + 15;
        doc.setFontSize(10);
        doc.text(18, numLinha, 'Av. Azenha, 631 - Porto Alegre/RS - Telefone: (51) 3289-6985 - E-mail: smca@dmlu.prefpoa.com.br');
        doc.setFontSize(10);
        img = new Image();
        img.src = 'assets/img/logoProcempa.png';
        doc.addImage(img, 'png', 155, 285, 40, 5);
        doc.text(15, 290, paginas.toString());
        doc.text(20, 290, 'Secretaria Municipal de Meio Ambiente, Urbanismo e Sustentabilidade – SMAMUS');
    };
    CertificadoComponent.prototype.ciunicogerador = function (doc, certificado) {
        var img = new Image();
        // let linhas = 0;
        var paginas = 2;
        var numLinha = 35;
        // doc.setFontSize(10);
        // doc.setFontType('normal');
        doc.rect(15, numLinha, 180, 100);
        numLinha = numLinha + 7; // 55
        doc.setFontSize(14);
        doc.setFontType('bold');
        doc.text(58, numLinha, 'DECLARAÇÃO DE INSENÇÃO DE MTR');
        numLinha = numLinha + 6;
        doc.setFontSize(12);
        doc.text(85, numLinha, '(ÚNICO GERADOR)');
        doc.setFontSize(10);
        numLinha = numLinha + 10;
        doc.setFontType('normal');
        doc.text(17, numLinha, 'Declaro, para os devidos fins, que ');
        doc.setFontType('bold');
        doc.text(72, numLinha, 'esta carga de resíduos sólidos apresentados junto à Estação de ');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'Transbordo Lomba do Pinheiro - ETLP, ');
        doc.setFontType('normal');
        doc.text(85, numLinha, 'para fins de destinação final a aterro sanitário, ');
        doc.setFontType('bold');
        doc.text(158, numLinha, 'é proveniente de um');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'ÚNICO GERADOR,');
        doc.setFontType('normal');
        doc.text(50, numLinha, 'abaixo relacionado,');
        doc.setFontType('bold');
        doc.text(82, numLinha, 'caracterizados em função de seu porte como isentos de');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'apresentação de Manifesto de Transporte de Resíduos ');
        doc.setFontType('normal');
        doc.text(110, numLinha, 'conforme estabelecido na Portaria FEPAM 87/2018,');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'art. 4º, inc. XV, alterada pela Portaria FEPAM 12/2020,');
        doc.text(104, numLinha, 'o qual assume perante o DMLU, a responsabilidade');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'sobre a carga destinada e sobre a veracidade das informações aqui prestadas.');
        numLinha = numLinha + 7;
        doc.text(17, numLinha, 'Declaro estar ciente de que ');
        doc.setFontType('bold');
        doc.text(62, numLinha, 'os resíduos sólidos autorizados para destinação na ETLP são apenas os');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'resíduos similares em composição e características aos domiciliares, ');
        doc.setFontType('normal');
        doc.text(135, numLinha, 'sendo vedado o encaminhamento');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'de quaisquer outros resíduos.');
        numLinha = numLinha + 7;
        doc.text(17, numLinha, 'Declaro estar ciente de que ');
        doc.setFontType('bold');
        doc.text(61, numLinha, 'é expressamente proibida a entrada no transbordo de resíduos Classe I,');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'resíduos industriais Classe II provenientes de processos produtivos ');
        doc.setFontType('normal');
        doc.text(133, numLinha, '(conforme Resolução CONSEMA');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, '73/2004, suas alterações e substituições), bem como ');
        doc.setFontType('bold');
        doc.text(102, numLinha, 'resíduos de construção civil ');
        doc.setFontType('normal');
        doc.text(151, numLinha, '(conforme resoluções');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'CONAMA 307/2002, 448/2012 e 469/2015, suas alterações e substituições).');
        numLinha = numLinha + 7;
        doc.text(17, numLinha, 'Declaro estar ciente de que os ');
        doc.setFontType('bold');
        doc.text(66, numLinha, 'resíduos com potencial de reciclagem ou reaproveitamento não poderão ser');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'destinados à estação de transbordo, ');
        doc.setFontType('normal');
        doc.text(80, numLinha, 'devendo ser encaminhados para unidades de triagem, para reciclagem');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'e/ou reaproveitamento, no cumprimento do previsto pelo Decreto Estadual 38.356 de 01.04.1998,');
        numLinha = numLinha + 4;
        doc.text(17, numLinha, 'regulamentador da Lei Nº 9921 de 27.07.1993, suas alterações e substituições.');
        doc.setFontSize(12);
        numLinha = numLinha + 30;
        doc.text(18, numLinha, 'Gerador: ________________________________');
        numLinha = numLinha + 12;
        doc.text(18, numLinha, 'CNPJ: __________________________________');
        doc.rect(113, numLinha - 24, 81, 40);
        doc.setFontSize(10);
        doc.text(115, numLinha - 19, 'Carimbo e assinatura do representante legal');
        doc.text(115, numLinha - 15, 'GERADOR:');
        doc.setFontSize(12);
        numLinha = numLinha + 40;
        doc.text(18, numLinha, 'Transportador: ___________________________');
        numLinha = numLinha + 12;
        doc.text(18, numLinha, 'CNPJ: __________________________________');
        doc.rect(113, numLinha - 24, 81, 40);
        doc.setFontSize(10);
        doc.text(115, numLinha - 19, 'Carimbo e assinatura do representante legal');
        doc.text(115, numLinha - 15, 'TRANSPORTADOR:');
        numLinha = numLinha + 32;
        doc.setFontSize(12);
        doc.text(18, numLinha, 'Data do recebimento: _____/_____/_____');
        numLinha = numLinha + 15;
        doc.setFontSize(10);
        doc.text(18, numLinha, 'Av. Azenha, 631 - Porto Alegre/RS - Telefone: (51) 3289-6985 - E-mail: smca@dmlu.prefpoa.com.br');
        doc.setFontSize(10);
        img = new Image();
        img.src = 'assets/img/logoProcempa.png';
        doc.addImage(img, 'png', 155, 285, 40, 5);
        doc.text(15, 290, paginas.toString());
        doc.text(20, 290, 'Secretaria Municipal de Meio Ambiente, Urbanismo e Sustentabilidade – SMAMUS');
    };
    return CertificadoComponent;
}());
export { CertificadoComponent };
