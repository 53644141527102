import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EnderecoGeo } from '../../@shared/models/enderecoGeo';
import * as L from 'leaflet';

@Injectable({ providedIn: 'root' })
export class MapaService {

  infosGeos: string = '/assets/data/infosGeo.geojson';

  constructor(private http: HttpClient) { }

  geoEnderecoMtrcc(): Observable<EnderecoGeo[]> {
    const url = environment.apiUrl + '/enderecos/coordenadasGeoMtrcc/';
    // console.log('criarEndereco Service >>>>>>', url);
    return this.http.get<EnderecoGeo[]>(url);
  }

  geoEnderecoGdr(): Observable<EnderecoGeo[]> {
    const url = environment.apiUrl + '/enderecos/coordenadasGeoGdr/';
    // console.log('criarEndereco Service >>>>>>', url);
    return this.http.get<EnderecoGeo[]>(url);
  }

  geoInfos(map: L.map): void {
    this.http.get(this.infosGeos).subscribe((res: any) => {
      for (const c of res.features) {
        const lon = c.geometry.coordinates[0];
        const lat = c.geometry.coordinates[1];
        const marker = L.marker([lat, lon]);

        marker.addTo(map);
      }
    });

  }

}
