var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { MenuItem, SelectItem } from 'primeng/api';
import { EnderecoService } from '../../@core/services/endereco.service';
import { NotificacoesService } from '../../@core/services/notificacoes.service';
import { SmfPessoaService } from '../../@core/services/smfPessoa.service';
import { SpinnerService } from '../../@core/services/spinner.service';
import { VeiculoService } from '../../@core/services/veiculo.service';
import { EnderecoEntidade } from '../../@shared/models/enderecoEntidade';
import { Etapas } from '../../@shared/models/etapa.model';
import { Obras } from '../../@shared/models/obra.model';
import { ptBr } from '../../@shared/utils/calendar';
import { Globals } from '../../globals';
import { ManifestoService } from '../manifesto.service';
import { EntidadeService } from 'src/app/@core/entidade/entidade.service';
import { ProdutoService } from 'src/app/@core/produto/produto.service';
import { PessoaFisicaService } from 'src/app/@core/services/pessoaFisica.service';
import * as jspdf from 'jspdf';
import { SecretariaPmpaService } from 'src/app/@core/services/secretariaPmpa.service';
import { ObraPmpaService } from 'src/app/@core/services/obraPmpa.service';
import { LicencaAmbiental } from 'src/app/@shared/models/licencaAmbiental.model';
import { LicencaAmbientalService } from 'src/app/@core/services/licencaAmbiental.service';
import { ExcelService } from '../../@core/services/excel.service';
import { MtrccExcel01Class } from 'src/app/@shared/models/mtrccExcel01.model';
// import { Console } from 'console';
var ListaManifestosComponent = /** @class */ (function () {
    function ListaManifestosComponent(route, router, fb, manifestoService, keycloakService, entidadeService, enderecoService, pessoaFisicaService, veiculoService, spinnerService, secretariaPmpaService, produtoService, obraPmpaService, licencaAmbientalService, smfPessoaService, notificacoesService, 
    // private messageService: MessageService,
    excelService, globals) {
        this.route = route;
        this.router = router;
        this.fb = fb;
        this.manifestoService = manifestoService;
        this.keycloakService = keycloakService;
        this.entidadeService = entidadeService;
        this.enderecoService = enderecoService;
        this.pessoaFisicaService = pessoaFisicaService;
        this.veiculoService = veiculoService;
        this.spinnerService = spinnerService;
        this.secretariaPmpaService = secretariaPmpaService;
        this.produtoService = produtoService;
        this.obraPmpaService = obraPmpaService;
        this.licencaAmbientalService = licencaAmbientalService;
        this.smfPessoaService = smfPessoaService;
        this.notificacoesService = notificacoesService;
        this.excelService = excelService;
        this.globals = globals;
        this.roleEntidadeMtrcc = false;
        this.adicionarOk = true;
        this.temModelo = false;
        this.submitted = false;
        // entidadeRepresentada: Entidade;
        this.entidades = [];
        this.entidadeId = null;
        this.endsEntidadeRepresentada = [];
        this.endsEntidadeGerador = [];
        this.respsEntidadeGerador = [];
        this.respsEntidadeTransportador = [];
        this.respsEntidadeDestinador = [];
        this.pfSecrsEntidadeGerador = [];
        this.secsEntidadeGerador = [];
        this.siglaSecretaria = '';
        this.endsEntidadeTransportador = [];
        this.endsEntidadeDestinador = [];
        this.veisEntidadeRepresentada = [];
        this.veisEntidadeTransportador = [];
        this.licsDestinador = [];
        this.isPessoaFisicaVinculoEmpresa = false;
        // Novo/edit
        this.manifestos = [];
        this.manifestosAux = [];
        this.manifestosFiltros = [];
        this.manifestosModelos = [];
        this.manifestoModelo = false;
        this.isManifestoModelo = false;
        this.isGeradorCNPJSemCadastro = false;
        this.informaObraPmpa = false;
        this.dataInicial = '01/01/1500';
        this.dataFinal = '01/01/1500';
        this.situacoes = [];
        // <option value='Abertos'>Abertos</option>
        // <option value='Recebidos'>Finalizados/Recebidos</option>
        // <option value='AnuladosRecusados'>Anulados/Recusados</option>
        this.situacaoPesquisa = '';
        this.justificarAnulacaoRecusa = false;
        // mostrarDialogExcluir: boolean;
        // selectedPesquisa: string[] = ['Abertos'];
        this.desabilitaGeradorPJ = true;
        this.desabilitaTransportadorPJ = true;
        this.desabilitaDestinadorPJ = true;
        this.licencaEUGerador = false;
        this.licencaEUDestinador = false;
        this.mensagemValidacao = '';
        this.minimumDateGerador = new Date();
        this.minimumDateTransportador = new Date();
        this.enderecoRes = {};
        this.enderecos = [];
        // mostra input logradouro, cep e número
        this.isCompleto = true;
        this.isPoa = true;
        this.codLogradouroPesquisa = '';
        this.numLogradouroPesquisa = '';
        this.mostrarDialogoEnderecoPoa = false;
        this.mostrarDialogoEnderecoForaPoa = false;
        this.index = 0;
        this.mtrccExcelList = [];
        this.activeRoute = this.router.url;
        this.variaveisGlobais = globals;
        // console.log("Construtor ListaPlanosComponent snapshot>>>>>>>>>>>", route.snapshot);
        this.entidadeId = route.snapshot.params['id'];
    }
    ListaManifestosComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createForm();
        this.variaveisGlobais = this.globals;
        this.variaveisGlobais.menuRaiz = this.router.url.split('/')[1];
        if (this.globals.entidade == null) {
            this.globals.recuperaKeycloak().subscribe(function (user) {
                if (_this.globals.roleEntidade) {
                    if (_this.variaveisGlobais.pessoaFisica === null) {
                        _this.globals.recuperaEntidadePessoaFisica().subscribe(function (entidade) {
                            // console.log('recuperaEntidadePessoaFisica---------------------------');
                            _this.variaveisGlobais = _this.globals;
                            _this.inicializaEntidadeSelecionada();
                        });
                    }
                    else {
                        _this.inicializaEntidadeSelecionada();
                    }
                }
                else {
                    _this.inicializaEntidadeSelecionada();
                }
            });
        }
        else {
            this.inicializaEntidadeSelecionada();
        }
    };
    ListaManifestosComponent.prototype.ngAfterViewInit = function () {
        // setTimeout(() => {
        //   console.log('ngAfterViewInit--------------------->>>', this.gerCnpj);
        //    this.gerCnpj.nativeElement.focus();
        // }, 500);
    };
    ListaManifestosComponent.prototype.inicializaEntidadeSelecionada = function () {
        var _this = this;
        this.manifestoModeloAux = null;
        if (this.entidadeId != null) {
            this.entidadeService.getEntidadePorId(this.entidadeId).subscribe(function (entidade) {
                _this.entidadeManifesto = entidade;
                _this.spinnerService.ocultar();
                if (entidade != null) {
                    _this.entidadeManifesto = entidade;
                    if (!_this.variaveisGlobais.roleEntidade) {
                        if (_this.entidades.length < 1) {
                            _this.variaveisGlobais.entidades.push(entidade);
                        }
                    }
                    console.log('inicializaOnInit ..... SMAMS/DMLU.....>>>>>>>', _this.entidades);
                    _this.inicializaOnInit();
                }
            }, function (error) {
                console.log(error);
                console.log(error.error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
        else {
            this.inicializaOnInit();
        }
    };
    // focusMethod(evento) {
    //   // console.log('@@@@@@@>>>', evento);
    //   document.getElementById('#geradorCnpj').focus();
    // }
    // setFocus(evento) {
    //   setTimeout(() => {
    //     console.log('@@@@@@@>>>', evento);
    //   }, 500);
    // }
    ListaManifestosComponent.prototype.inicializaOnInit = function () {
        var _this = this;
        // Configuração de linguagem do calandar
        ptBr.firstDayOfWeek = 0;
        this.languageCalendar = ptBr;
        console.log('-------variaveisGlobais------------ccccc-------->>>>>', this.variaveisGlobais);
        this.pessoaFisica = this.variaveisGlobais.pessoaFisica;
        this.usuarioLogado = this.variaveisGlobais.pessoaFisica;
        this.entidades = this.variaveisGlobais.entidades;
        if (this.variaveisGlobais.roleEntidade) {
            if (this.entidades.length > 0) {
                this.isPessoaFisicaVinculoEmpresa = true;
            }
            else {
                this.licencaUsuarioLogado(this.usuarioLogado.id);
                this.isPessoaFisicaVinculoEmpresa = false;
            }
        }
        else {
            this.isPessoaFisicaVinculoEmpresa = true;
        }
        this.adicionarOk = true;
        this.situacoes = [];
        if (!this.variaveisGlobais.roleEntidade) {
            this.situacaoPesquisa = 'Selecione';
            this.situacoes.push({ label: 'Selecione', value: '' });
        }
        else {
            this.situacaoPesquisa = 'Abertos';
        }
        this.situacoes.push({ label: 'Abertos', value: 'Abertos' });
        this.situacoes.push({ label: 'Finalizados/Recebidos', value: 'Recebidos' });
        this.situacoes.push({ label: 'Anulados/Recusados', value: 'AnuladosRecusados' });
        this.situacoes.push({ label: 'Todos', value: 'Todos' });
        // console.log('-------inicializaOnInit------------ccccc-------->>>>>', this.situacoes);
        this.roleEntidadeMtrcc = false;
        if (this.variaveisGlobais.roleEntidade) {
            this.roleEntidadeMtrcc = true;
            this.buscaManifestos();
        }
        this.isTotalizador = false;
        this.dataInicial = '01/01/1500';
        this.dataFinal = '01/01/1500';
        // Carrega valores para obras
        this.obras = [];
        // this.obras.push( {label: 'Selecione', value: ''});
        for (var _i = 0, _a = Object.keys(Obras); _i < _a.length; _i++) {
            var obra = _a[_i];
            this.obras.push({ label: Obras[obra], value: obra });
        }
        // Carrega valores para nomesObra
        this.nomeObras = [];
        this.informaObraPmpa = false;
        this.entidadeExecutora = null;
        this.obraPmpaService.getObrasPmpaAtivas().subscribe(function (nomeObras) {
            _this.nomeObrasList = nomeObras;
            // this.nomeObras.push( {label: 'Selecione', value: ''});
            nomeObras.forEach(function (no) {
                _this.nomeObras.push({ label: no.nome, value: no.nome });
            });
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
        // Carrega valores para etapas
        this.etapas = [];
        // this.etapas.push( {label: 'Selecione', value: ''});
        for (var _b = 0, _c = Object.keys(Etapas); _b < _c.length; _b++) {
            var etapa = _c[_b];
            this.etapas.push({ label: Etapas[etapa], value: etapa });
        }
        // Carrega lista de produtos
        this.produtos = [];
        this.produtoService.getProdutos(this.variaveisGlobais.menuRaiz, this.variaveisGlobais.roleEntidade).subscribe(function (tipoProdutos) {
            _this.tipoProdutoList = tipoProdutos;
            // this.produtos.push( {label: 'Selecione', value: ''});
            tipoProdutos.forEach(function (pro) {
                _this.produtos.push({ label: pro.nome, value: pro.nome });
            });
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
        // if (!this.variaveisGlobais.roleEntidade) {
        this.isPMPAGerador = true;
        // Carrega lista de secretarias
        this.secretarias = [];
        this.secretariaPmpaService.getSecretariasPmpa().subscribe(function (secretarias) {
            _this.secretariaList = secretarias;
            // this.secretarias.push( {label: 'Selecione', value: ''});
            secretarias.forEach(function (sec) {
                _this.secretarias.push({ label: sec.sigla, value: sec.sigla });
            });
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
        // }
        console.log('***********************inicializaOnInit>>>>>>>>>>>>', this.entidadeManifesto);
    };
    // inicilizaFoco() {
    //   this.manifestoForm.get('geradorCnpj').focus();
    //   document.getElementById("idGeradorCnpj").focus();
    // }
    ListaManifestosComponent.prototype.licencaUsuarioLogado = function (isPessoaFisica) {
        var _this = this;
        this.licencaAmbientalUsuarioLogado = new LicencaAmbiental();
        this.licencaAmbientalService.getLicencasPorPessoaFisica(this.pessoaFisica.id)
            .subscribe(function (licencas) {
            //PEGA A PRIMEIRA LICENÇA VÁLIDA (AVALIAR)
            _this.licencaAmbientalUsuarioLogado = licencas[0];
        }, function (error) {
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaManifestosComponent.prototype.reciverFeedback = function (respostaFilho) {
        console.log('Foi emitido o evento e chegou no pai >>>> ', respostaFilho);
    };
    ListaManifestosComponent.prototype.pesquisaManifesto = function () {
        console.log('00pesquisaManifesto>>>>>>>>>>>>', this.periodoPesquisa);
        this.dataInicial = '01/01/1500';
        this.dataFinal = '01/01/1500';
        if (this.periodoPesquisa !== undefined && this.periodoPesquisa !== null) {
            if (this.periodoPesquisa.length > 0) {
                this.dataInicial = this.periodoPesquisa[0].toLocaleDateString();
                // console.log('11pesquisaManifesto>>>>>>>>>>>>', this.dataInicial);
                // this.dataFinal = '';
                if (this.periodoPesquisa.length > 1) {
                    if (this.periodoPesquisa[1] !== null) {
                        this.dataFinal = this.periodoPesquisa[1].toLocaleDateString();
                    }
                }
                // console.log('22pesquisaManifesto>>>>>>>>>>>>', this.dataFinal);
            }
        }
        this.buscaManifestos();
        // this.inicializaOnInit();
    };
    ListaManifestosComponent.prototype.buscaManifestos = function () {
        var _this = this;
        this.manifestos = [];
        this.manifestosModelos = [];
        this.spinnerService.exibir();
        if (this.manifestoPesquisa === undefined || this.manifestoPesquisa === null) {
            this.manifestoPesquisa = '';
        }
        if (this.obraPesquisa === undefined || this.obraPesquisa === null) {
            this.obraPesquisa = '';
        }
        if (this.produtoPesquisa === undefined || this.produtoPesquisa === null) {
            this.produtoPesquisa = '';
        }
        if (this.secretariaPesquisa === undefined || this.secretariaPesquisa === null) {
            this.secretariaPesquisa = '';
        }
        if (this.etapaPesquisa === undefined || this.etapaPesquisa === null) {
            this.etapaPesquisa = '';
        }
        if (this.nomeObraPesquisa === undefined || this.nomeObraPesquisa === null) {
            this.nomeObraPesquisa = '';
        }
        if (this.codLogradouroPesquisa === undefined || this.codLogradouroPesquisa === null) {
            this.codLogradouroPesquisa = '';
        }
        if (this.numLogradouroPesquisa === undefined || this.numLogradouroPesquisa === null) {
            this.numLogradouroPesquisa = '';
        }
        if (this.entidadeId == null) {
            // console.log('Manifestos>>>>>>>>00');
            if (this.manifestos.length <= 0 || this.manifestoPesquisa !== '') {
                // console.log('Manifestos>>>>>>>>11');
                if (this.variaveisGlobais.roleEntidade) {
                    console.log('.........pesquisaManifesto>>situação>>>>>>>>>', this.situacaoPesquisa);
                    // Carrega Lista MTRCC da ENTIDADE LOGADA
                    this.manifestos = [];
                    this.manifestosGeradores = [];
                    this.manifestosTransportadores = [];
                    this.manifestosDestinadores = [];
                    this.manifestosGeradores.push({ label: 'Todos', value: null });
                    this.manifestosTransportadores.push({ label: 'Todos', value: null });
                    this.manifestosDestinadores.push({ label: 'Todos', value: null });
                    this.manifestoService.getManifestosDasEmpresas(this.pessoaFisica, this.situacaoPesquisa, this.dataInicial, this.dataFinal, this.obraPesquisa, this.produtoPesquisa, this.secretariaPesquisa, this.nomeObraPesquisa, this.codLogradouroPesquisa, this.numLogradouroPesquisa, this.etapaPesquisa, this.manifestoPesquisa).subscribe(function (manifestos) {
                        console.log('Manifestos 00>>>>>00>>>', manifestos);
                        // this.buscaManifestosModelos(this.pessoaFisica.id, null);
                        manifestos.forEach(function (manifesto) {
                            manifesto.isGerador = false;
                            manifesto.isTransportador = false;
                            manifesto.isDestinador = false;
                            if (manifesto.mtrccModelo) {
                                _this.manifestosModelos.push(manifesto);
                            }
                            if (_this.isPessoaFisicaVinculoEmpresa) {
                                _this.entidades.forEach(function (entidade) {
                                    if (manifesto.geradorCnpj === entidade.cnpj) {
                                        manifesto.isGerador = true;
                                    }
                                    if (manifesto.transportadorCnpj === entidade.cnpj) {
                                        // console.log('Manifestos 00>>******************>>>00>>>', manifesto.mtrccModelo);
                                        manifesto.isTransportador = true;
                                    }
                                    if (manifesto.destinadorCnpj === entidade.cnpj) {
                                        manifesto.isDestinador = true;
                                    }
                                });
                            }
                            else {
                                if (manifesto.transportadorCpf === _this.usuarioLogado.cpf) {
                                    manifesto.isTransportador = true;
                                }
                            }
                            var incluiGerador = true;
                            var incluiTransportador = true;
                            var incluiDestinador = true;
                            _this.manifestosGeradores.forEach(function (ite) {
                                if (ite.label === manifesto.geradorNome) {
                                    incluiGerador = false;
                                }
                            });
                            _this.manifestosTransportadores.forEach(function (ite) {
                                if (ite.label === manifesto.transportadorNome) {
                                    incluiTransportador = false;
                                }
                            });
                            _this.manifestosDestinadores.forEach(function (ite) {
                                if (ite.label === manifesto.destinadorNome) {
                                    incluiDestinador = false;
                                }
                            });
                            if (incluiGerador) {
                                _this.manifestosGeradores.push({ label: manifesto.geradorNome, value: manifesto.geradorNome });
                            }
                            if (incluiTransportador) {
                                _this.manifestosTransportadores.push({ label: manifesto.transportadorNome, value: manifesto.transportadorNome });
                            }
                            if (incluiDestinador) {
                                _this.manifestosDestinadores.push({ label: manifesto.destinadorNome, value: manifesto.destinadorNome });
                            }
                        });
                        // this.transform(manifestos);
                        // this.manifestos.forEach(m => {
                        //   console.log('buscaManifestos>>', m.numeroManifesto);
                        // });
                        _this.manifestos = manifestos;
                        _this.spinnerService.ocultar();
                        // console.log('Manifestos Modelos **************00>>>>>00>>>', this.manifestosModelos);
                        _this.temModelo = false;
                        if (_this.manifestosModelos.length > 0) {
                            _this.temModelo = true;
                            // this.manifestosModelos.forEach(manif => {
                            //   console.log('Manifestos getProdutosManifesto 611**************>>>>>');
                            //   this.manifestoService.getProdutosManifesto(manif.id).subscribe(manifProd => {
                            //     manif.produto = manifProd.produto;
                            //     this.spinnerService.ocultar();
                            //   },
                            //     error => {
                            //       console.log(error);
                            //       console.log(error.error);
                            //       this.spinnerService.ocultar();
                            //       this.notificacoesService.notificarErro(error.error, null);
                            //     });
                            // });
                        }
                    }, function (error) {
                        console.log(error);
                        console.log(error.error);
                        _this.spinnerService.ocultar();
                        _this.notificacoesService.notificarErro(error.error, null);
                    });
                }
                else {
                    // Carrega Lista MTRCC da ENTIDADE LOGADA
                    this.manifestos = [];
                    this.manifestoService.getManifestosSmams(this.situacaoPesquisa, this.dataInicial, this.dataFinal, this.obraPesquisa, this.produtoPesquisa, this.secretariaPesquisa, this.nomeObraPesquisa, this.codLogradouroPesquisa, this.numLogradouroPesquisa, this.etapaPesquisa, this.manifestoPesquisa).subscribe(function (manifestos) {
                        _this.manifestos = manifestos;
                        // this.verificaManifestosVencidos();
                        // this.transform(manifestos);
                        _this.spinnerService.ocultar();
                        // console.log('Manifestos SMAMS>>>>>>>>', this.manifestos);
                        _this.manifestosGeradores = [];
                        _this.manifestosTransportadores = [];
                        _this.manifestosDestinadores = [];
                        _this.manifestosGeradores.push({ label: 'Todos', value: null });
                        _this.manifestosTransportadores.push({ label: 'Todos', value: null });
                        _this.manifestosDestinadores.push({ label: 'Todos', value: null });
                        manifestos.forEach(function (manifesto) {
                            var incluiGerador = true;
                            var incluiTransportador = true;
                            var incluiDestinador = true;
                            _this.manifestosGeradores.forEach(function (ite) {
                                if (ite.label === manifesto.geradorNome) {
                                    incluiGerador = false;
                                }
                            });
                            _this.manifestosTransportadores.forEach(function (ite) {
                                if (ite.label === manifesto.transportadorNome) {
                                    incluiTransportador = false;
                                }
                            });
                            _this.manifestosDestinadores.forEach(function (ite) {
                                if (ite.label === manifesto.destinadorNome) {
                                    incluiDestinador = false;
                                }
                            });
                            if (incluiGerador) {
                                _this.manifestosGeradores.push({ label: manifesto.geradorNome, value: manifesto.geradorNome });
                            }
                            if (incluiTransportador) {
                                _this.manifestosTransportadores.push({ label: manifesto.transportadorNome, value: manifesto.transportadorNome });
                            }
                            if (incluiDestinador) {
                                _this.manifestosDestinadores.push({ label: manifesto.destinadorNome, value: manifesto.destinadorNome });
                            }
                        });
                    }, function (error) {
                        console.log(error);
                        console.log(error.error);
                        _this.spinnerService.ocultar();
                        _this.notificacoesService.notificarErro(error.error, null);
                    });
                }
            }
        }
        else {
            // Carrega Lista MTRCC da ENTIDADE LOGADA
            this.manifestos = [];
            this.manifestosGeradores = [];
            this.manifestosTransportadores = [];
            this.manifestosDestinadores = [];
            this.manifestosGeradores.push({ label: 'Todos', value: null });
            this.manifestosTransportadores.push({ label: 'Todos', value: null });
            this.manifestosDestinadores.push({ label: 'Todos', value: null });
            this.manifestoService.getManifestosDaEmpresa(this.entidadeManifesto.id, this.situacaoPesquisa, this.dataInicial, this.dataFinal, this.obraPesquisa, this.produtoPesquisa, this.secretariaPesquisa, this.nomeObraPesquisa, this.codLogradouroPesquisa, this.numLogradouroPesquisa, this.etapaPesquisa, this.manifestoPesquisa).subscribe(function (manifestos) {
                // console.log('Manifestos......Carrega Lista MTRCC da ENTIDADE LOGADA...>>>>>>>>', this.entidades);
                // this.buscaManifestosModelos(null, this.entidadeManifesto.id);
                manifestos.forEach(function (manifesto) {
                    manifesto.isGerador = false;
                    manifesto.isTransportador = false;
                    manifesto.isDestinador = false;
                    if (manifesto.mtrccModelo) {
                        _this.manifestosModelos.push(manifesto);
                    }
                    if (!_this.variaveisGlobais.roleEntidade) {
                        if (_this.entidades.length < 1) {
                            _this.entidades = _this.variaveisGlobais.entidades;
                        }
                    }
                    // console.log('Manifestos......3333...>>>>>>>>', this.entidades);
                    _this.entidades.forEach(function (entidade) {
                        if (manifesto.geradorCnpj === entidade.cnpj) {
                            manifesto.isGerador = true;
                        }
                        if (manifesto.transportadorCnpj === entidade.cnpj) {
                            manifesto.isTransportador = true;
                        }
                        if (manifesto.destinadorCnpj === entidade.cnpj) {
                            manifesto.isDestinador = true;
                        }
                        var incluiGerador = true;
                        var incluiTransportador = true;
                        var incluiDestinador = true;
                        _this.manifestosGeradores.forEach(function (ite) {
                            if (ite.label === manifesto.geradorNome) {
                                incluiGerador = false;
                            }
                        });
                        _this.manifestosTransportadores.forEach(function (ite) {
                            if (ite.label === manifesto.transportadorNome) {
                                incluiTransportador = false;
                            }
                        });
                        _this.manifestosDestinadores.forEach(function (ite) {
                            if (ite.label === manifesto.destinadorNome) {
                                incluiDestinador = false;
                            }
                        });
                        if (incluiGerador) {
                            _this.manifestosGeradores.push({ label: manifesto.geradorNome, value: manifesto.geradorNome });
                        }
                        if (incluiTransportador) {
                            _this.manifestosTransportadores.push({ label: manifesto.transportadorNome, value: manifesto.transportadorNome });
                        }
                        if (incluiDestinador) {
                            _this.manifestosDestinadores.push({ label: manifesto.destinadorNome, value: manifesto.destinadorNome });
                        }
                    });
                });
                // this.transform(manifestos);
                _this.manifestos = manifestos;
                // console.log('Manifestos 11>>>>>>>>', this.manifestos);
                _this.spinnerService.ocultar();
                // console.log('Manifestos Modelos **************00>>>>>00>>>', this.manifestosModelos);
                _this.temModelo = false;
                if (_this.manifestosModelos.length > 0) {
                    _this.temModelo = true;
                    // this.manifestosModelos.forEach(manif => {
                    //   console.log('Manifestos getProdutosManifesto 785**************>>>>>');
                    //   this.manifestoService.getProdutosManifesto(manif.id).subscribe(manifProd => {
                    //     manif.produto = manifProd.produto;
                    //     this.spinnerService.ocultar();
                    //   },
                    //     error => {
                    //       console.log(error);
                    //       console.log(error.error);
                    //       this.spinnerService.ocultar();
                    //       this.notificacoesService.notificarErro(error.error, null);
                    //     });
                    // });
                }
            }, function (error) {
                console.log(error);
                console.log(error.error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
        if (this.manifestoPesquisa === '') {
            this.manifestoPesquisa = null;
        }
        if (this.obraPesquisa === '') {
            this.obraPesquisa = null;
        }
        if (this.produtoPesquisa === '') {
            this.produtoPesquisa = null;
        }
        if (this.secretariaPesquisa === '') {
            this.secretariaPesquisa = null;
        }
        if (this.etapaPesquisa === '') {
            this.etapaPesquisa = null;
        }
        if (this.nomeObraPesquisa === '') {
            this.nomeObraPesquisa = null;
        }
    };
    ListaManifestosComponent.prototype.buscaManifestosModelos = function (idPessoaFisica, idEntidade) {
        this.temModelo = false;
        this.manifestosModelos = [];
        // this.manifestoService.getManifestosModelos(idPessoaFisica, idEntidade).subscribe(manifestosModelos => {
        //   this.manifestosModelos = manifestosModelos;
        //   // this.verificaManifestosVencidos();
        //   // this.transform(manifestos);
        //   this.spinnerService.ocultar();
        //   console.log('Manifestos SMAMS>>>>>>>>', this.manifestos);
        // },
        //   error => {
        //   console.log(error);
        //   console.log(error.error);
        //   this.spinnerService.ocultar();
        //   this.notificacoesService.notificarErro(error.error, null);
        // });
    };
    ListaManifestosComponent.prototype.logout = function () {
        var url = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
        this.keycloakService.getKeycloakInstance().logout({ redirectUri: url });
    };
    ListaManifestosComponent.prototype.createForm = function () {
        this.manifestoForm = this.fb.group({
            tipoGerador: ['juridica'],
            tipoTransportador: ['juridica'],
            tipoDestinador: ['juridica'],
            obra: ['', Validators.required],
            nomeobra: [''],
            etapa: ['', Validators.required],
            produto: ['', Validators.required],
            classificacao: [''],
            quantidade: ['', Validators.required],
            unidadeQuantidade: ['m³', Validators.required],
            logradouro: [''],
            numero: [''],
            municipio: [''],
            // identificacao: [''],
            // observacao: [''],
            justificativaAnulacaoRecusa: [''],
            dataAnulacaoRecusa: [''],
            enderecoRetirada: ['poa'],
            geradorNome: [{ value: '', disabled: true }, Validators.required],
            geradorCnpj: ['', Validators.required],
            geradorCpf: ['TransienteSeJuridica', Validators.required],
            geradorEndSel: ['TransienteSeFisica', Validators.required],
            geradorEndereco: [{ value: '', disabled: true }, Validators.required],
            geradorMunicipio: [{ value: '', disabled: true }, Validators.required],
            // geradorEnderecoIdentificacao: [{ value: '', disabled: true}],
            // geradorEnderecoObservacao: [{ value: '', disabled: true}],
            geradorRespSel: ['TransienteSeFisica', Validators.required],
            geradorEmail: [{ value: '', disabled: true }],
            geradorFone: [{ value: '', disabled: true }, Validators.required],
            geradorLicenca: [{ value: '', disabled: true }],
            geradorObservacao: [''],
            geradorDataExpedicao: [new Date(), Validators.required],
            transportadorNome: [{ value: '', disabled: true }, Validators.required],
            transportadorCnpj: ['', Validators.required],
            transportadorCpf: ['TransienteSeJuridica', Validators.required],
            transportadorEndSel: ['TransienteSeFisica', Validators.required],
            transportadorEndereco: [{ value: '', disabled: true }, Validators.required],
            transportadorMunicipio: [{ value: '', disabled: true }, Validators.required],
            transportadorEmail: [{ value: '', disabled: true }],
            transportadorFone: [{ value: '', disabled: true }, Validators.required],
            transportadorLicenca: [{ value: '', disabled: true }, Validators.required],
            transportadorPlaca: [{ value: '', disabled: true }, Validators.required],
            transportadorVeiSel: ['TransienteSeJuridica', Validators.required],
            transportadorDataTransporte: [new Date(), Validators.required],
            destinadorNome: [{ value: '', disabled: true }, Validators.required],
            destinadorCnpj: ['', Validators.required],
            destinadorCpf: ['TransienteSeJuridica', Validators.required],
            destinadorEndSel: ['TransienteSeFisica', Validators.required],
            destinadorEndereco: [{ value: '', disabled: true }, Validators.required],
            destinadorMunicipio: [{ value: '', disabled: true }, Validators.required],
            destinadorEmail: [{ value: '', disabled: true }],
            destinadorFone: [{ value: '', disabled: true }],
            // destinadorLicencaIsento: [],
            // destinadorLicSel: [''],
            destinadorLicenca: [{ value: '' }],
            destinadorValidade: [new Date()],
            destinadorOrgaoEmissor: [{ value: '' }],
            destinadorObservacao: [{ value: '', disabled: false }],
            destinadorDataRecebimento: [new Date()],
            totalInicio: [new Date()],
            totalFim: [new Date()]
        });
    };
    ListaManifestosComponent.prototype.onSubmit = function () {
        this.ngOnInit();
    };
    ListaManifestosComponent.prototype.pesquisar = function () {
        // this.manifestoForm.reset();
        // this.manifestoForm.onSubmit(null);
    };
    Object.defineProperty(ListaManifestosComponent.prototype, "diagnostic", {
        get: function () { return JSON.stringify(this.manifestoForm.value); },
        enumerable: true,
        configurable: true
    });
    ListaManifestosComponent.prototype.isGeradorPF = function () {
        return this.manifestoForm.get('tipoGerador').value === 'fisica';
    };
    ListaManifestosComponent.prototype.isTransportadorPF = function () {
        return this.manifestoForm.get('tipoTransportador').value === 'fisica';
    };
    ListaManifestosComponent.prototype.isDestinoFinalPF = function () {
        return this.manifestoForm.get('tipoDestinador').value === 'fisica';
    };
    ListaManifestosComponent.prototype.isGeradorPmpa = function () {
        return this.manifestoForm.get('geradorCnpj').value === '92.963.560/0001-60';
    };
    ListaManifestosComponent.prototype.isDestinadorPmpa = function () {
        return this.manifestoForm.get('destinadorCnpj').value === '92.963.560/0001-60';
    };
    ListaManifestosComponent.prototype.alternarTipoPessoa = function (tipoPessoa, tipoEntidade) {
        // let tipoPessoaAux = '';
        this.mensagemValidacao = '';
        if (tipoEntidade === 'gerador') {
            this.endsEntidadeGerador = [];
        }
        if (tipoEntidade === 'transportador') {
            this.endsEntidadeTransportador = [];
            this.veisEntidadeTransportador = [];
        }
        if (tipoEntidade === 'destinador') {
            this.endsEntidadeDestinador = [];
            this.licsDestinador = [];
        }
        if (this.mensagemValidacao !== '') {
            this.spinnerService.ocultar();
            this.notificacoesService.notificarErro(this.mensagemValidacao, null);
        }
        else {
            this.disabledPJ(tipoPessoa, tipoEntidade);
            this.manifestoForm.get(tipoEntidade + 'Nome').setValue(null);
            this.manifestoForm.get(tipoEntidade + 'Email').setValue(null);
            this.manifestoForm.get(tipoEntidade + 'Fone').setValue(null);
            this.manifestoForm.get(tipoEntidade + 'Endereco').setValue(null);
            this.manifestoForm.get(tipoEntidade + 'Municipio').setValue(null);
            this.manifestoForm.get(tipoEntidade + 'Licenca').setValue(null);
            if (tipoPessoa === 'juridica') {
                this.manifestoForm.get(tipoEntidade + 'Cnpj').setValue(null);
                this.manifestoForm.get(tipoEntidade + 'Cpf').setValue('TransienteSeJuridica');
            }
            else {
                this.manifestoForm.get(tipoEntidade + 'Cnpj').setValue('TransienteSeFisica');
                this.manifestoForm.get(tipoEntidade + 'Cpf').setValue(null);
                if (tipoEntidade === 'destinador') {
                    this.manifestoForm.get(tipoEntidade + 'Licenca').setValue('Não possui licença.');
                    this.manifestoForm.get(tipoEntidade + 'OrgaoEmissor').setValue(null);
                }
            }
            console.log('.....................', tipoEntidade, ' - ', this.manifestoEdit);
            if (this.manifestoEdit.id != null) {
                if (tipoEntidade === 'gerador') {
                    if (tipoPessoa === 'juridica') {
                        if (this.manifestoEdit.geradorCnpj !== null) {
                            this.formataTelaGerador(this.manifestoEdit);
                            this.manifestoForm.get(tipoEntidade + 'Cnpj').setValue(this.manifestoEdit.geradorCnpj);
                        }
                    }
                    else {
                        if (this.manifestoEdit.geradorCpf !== null) {
                            this.formataTelaGerador(this.manifestoEdit);
                            this.manifestoForm.get(tipoEntidade + 'Cpf').setValue(this.manifestoEdit.geradorCpf);
                            this.manifestoForm.get(tipoEntidade + 'Licenca').setValue('Não possui licença.');
                        }
                        this.manifestoForm.get(tipoEntidade + 'EndSel').setValue('TransienteSeFisica');
                    }
                }
                if (tipoEntidade === 'transportador') {
                    this.manifestoForm.get(tipoEntidade + 'Placa').setValue('');
                    if (tipoPessoa === 'juridica') {
                        if (this.manifestoEdit.transportadorCnpj !== null) {
                            this.formataTelaTransportador(this.manifestoEdit);
                            this.manifestoForm.get(tipoEntidade + 'Cnpj').setValue(this.manifestoEdit.transportadorCnpj);
                        }
                    }
                    else {
                        if (this.manifestoEdit.transportadorCpf !== null) {
                            this.formataTelaTransportador(this.manifestoEdit);
                            this.manifestoForm.get(tipoEntidade + 'Cpf').setValue(this.manifestoEdit.transportadorCpf);
                            this.manifestoForm.get(tipoEntidade + 'Licenca').setValue('Não possui licença.');
                        }
                        this.manifestoForm.get(tipoEntidade + 'EndSel').setValue('TransienteSeFisica');
                        this.manifestoForm.get(tipoEntidade + 'VeiSel').setValue('TransienteSeFisica');
                    }
                }
                if (tipoEntidade === 'destinador') {
                    if (tipoPessoa === 'juridica') {
                        if (this.manifestoEdit.destinadorCnpj !== null) {
                            this.formataTelaDestinador(this.manifestoEdit);
                            this.manifestoForm.get(tipoEntidade + 'Cnpj').setValue(this.manifestoEdit.destinadorCnpj);
                        }
                    }
                    else {
                        if (this.manifestoEdit.destinadorCpf !== null) {
                            this.formataTelaDestinador(this.manifestoEdit);
                            this.manifestoForm.get(tipoEntidade + 'Cpf').setValue(this.manifestoEdit.destinadorCpf);
                            this.manifestoForm.get(tipoEntidade + 'Licenca').setValue('Não possui licença.');
                        }
                        this.manifestoForm.get(tipoEntidade + 'EndSel').setValue('TransienteSeFisica');
                    }
                }
            }
            else {
                if (tipoEntidade === 'transportador') {
                    if (tipoPessoa === 'fisica') {
                        this.manifestoForm.get(tipoEntidade + 'Placa').setValue('');
                        this.manifestoForm.get(tipoEntidade + 'Licenca').setValue('Não possui licença.');
                        this.manifestoForm.get(tipoEntidade + 'EndSel').setValue('TransienteSeFisica');
                        this.manifestoForm.get(tipoEntidade + 'VeiSel').setValue('TransienteSeFisica');
                    }
                }
            }
            this.manifestoForm.get(tipoEntidade + 'Cnpj').enable();
        }
    };
    // validaEntidade(entidade, enderecosEntidade, tipoPessoa, tipoEntidade) {
    //   console.log('validaEntidade MANIFESTO>>>>>>>>', tipoPessoa, '---', tipoEntidade, '---', entidade);
    //   var tpEntidade = '';
    //   if (tipoEntidade == 'gerador') {
    //     tpEntidade = 'GERADOR';
    //   } if (tipoEntidade == 'transportador') {
    //     tpEntidade = 'TRANSPORTADOR';
    //   } if (tipoEntidade == 'destinador') {
    //     tpEntidade = 'DESTINO FINAL';
    //   }
    //   if (tipoPessoa == 'juridica') {
    //     if (entidade.enderecoDO != null) {
    //       if (entidade.enderecoDO.municipio == null || entidade.enderecoDO.municipio == '') {
    //         this.mensagemValidacao = 'Entidade informada como ' + tpEntidade + ' não possui MUNICÍPIO cadastrado.';
    //       }
    //       if (entidade.enderecoDO.nomeLogradouro == null || entidade.enderecoDO.nomeLogradouro == '') {
    //         this.mensagemValidacao = 'Entidade informada como ' + tpEntidade + ' não possui LOGRADOURO cadastrado.';
    //       }
    //       if (entidade.enderecoDO.numero == null || entidade.enderecoDO.numero == '') {
    //         this.mensagemValidacao = 'Entidade informada como ' + tpEntidade + ' não possui NÚMERO DO LOGRADOURO cadastrado.';
    //       }
    //     } else {
    //       this.mensagemValidacao = 'Entidade informada como ' + tpEntidade + ' não possui ENDEREÇO cadastrado.';
    //     }
    //     if (tipoEntidade == 'transportador') {
    //       // if (entidade.placas == null || entidade.placas == '') {
    //       //   this.mensagemValidacao = 'Entidade informada como ' + tpEntidade + ' não possui VEÍCULO cadastrado.';
    //       // }
    //     }
    //     if (tipoEntidade == 'destinador' || tipoEntidade == 'transportador') {
    //       if (entidade.licencaAmbiental == null || entidade.licencaAmbiental == '') {
    //         this.mensagemValidacao = 'Entidade informada como ' + tpEntidade + ' não possui LICENÇA AMBIENTAL cadastrada.';
    //       }
    //     }
    //   }
    // }
    ListaManifestosComponent.prototype.disabledPJ = function (tipoPessoa, tipoEntidade) {
        console.log('disabledPJ>>>>>', tipoPessoa, '---', tipoEntidade);
        if (tipoPessoa === 'juridica') {
            console.log('disabledPJ - Manifesto>>>>>>>>', tipoPessoa, '---', tipoEntidade);
            this.manifestoForm.get(tipoEntidade + 'Nome').disable();
            this.manifestoForm.get(tipoEntidade + 'Endereco').disable();
            this.manifestoForm.get(tipoEntidade + 'Municipio').disable();
            this.manifestoForm.get(tipoEntidade + 'Email').disable();
            this.manifestoForm.get(tipoEntidade + 'Fone').disable();
            // this.manifestoForm.get(tipoEntidade + 'Licenca').disable();
        }
        else {
            this.manifestoForm.get(tipoEntidade + 'Nome').enable();
            this.manifestoForm.get(tipoEntidade + 'Endereco').enable();
            this.manifestoForm.get(tipoEntidade + 'Municipio').enable();
            this.manifestoForm.get(tipoEntidade + 'Email').enable();
            this.manifestoForm.get(tipoEntidade + 'Fone').enable();
            // this.manifestoForm.get(tipoEntidade + 'Licenca').enable();
        }
        if (tipoEntidade === 'transportador') {
            if (tipoPessoa === 'juridica') {
                this.manifestoForm.get(tipoEntidade + 'Placa').disable();
            }
            else {
                this.manifestoForm.get(tipoEntidade + 'Placa').enable();
            }
        }
    };
    ListaManifestosComponent.prototype.mostrarDialogParaNovo = function () {
        //Início correções automáticas nas tabelas
        // this.manifestoService.correcoesExtras().subscribe(string => {
        //   console.log('OKkkkkkkkkkkkkkk!!!!!!!!!!!');
        // },
        // error => {
        //     console.log(error);
        //     console.log(error.error);
        //     this.spinnerService.ocultar();
        //     this.notificacoesService.notificarErro(error.error, null);
        // });
        //Fim correções automáticas nas tabelas
        this.endsEntidadeGerador = [];
        this.respsEntidadeGerador = [];
        this.endsEntidadeTransportador = [];
        this.endsEntidadeDestinador = [];
        this.veisEntidadeTransportador = [];
        this.licsDestinador = [];
        this.minimumDateTransportador = new Date();
        this.createForm();
        this.manifestoEdit = {
            id: null,
            timestamp: null,
            dataInclusao: null,
            usuarioInclusao: null,
            usuarioAlteracao: null,
            numeroManifesto: null,
            numManifesto: null,
            anoReferencia: null,
            justificativaAnulacaoRecusa: null,
            dataAnulacaoRecusa: null,
            usuarioAnulacaoRecusa: null,
            anulado: false,
            recusado: false,
            obra: null,
            nomeobra: null,
            etapa: null,
            produto: null,
            classificacao: null,
            quantidade: null,
            unidadeQuantidade: null,
            endereco: null,
            isGerador: false,
            geradorNome: null,
            geradorCnpj: null,
            geradorCpf: null,
            geradorEndereco: null,
            geradorMunicipio: null,
            geradorEmail: null,
            geradorFone: null,
            geradorLicenca: null,
            geradorObservacao: null,
            geradorDataExpedicao: null,
            isTransportador: false,
            transportadorNome: null,
            transportadorCnpj: null,
            transportadorCpf: null,
            transportadorEndereco: null,
            transportadorMunicipio: null,
            transportadorEmail: null,
            transportadorFone: null,
            transportadorLicenca: null,
            transportadorPlaca: null,
            transportadorDataTransporte: null,
            isDestinador: false,
            destinadorNome: null,
            destinadorCnpj: null,
            destinadorCpf: null,
            destinadorEndereco: null,
            destinadorMunicipio: null,
            destinadorEmail: null,
            destinadorFone: null,
            destinadorLicenca: null,
            destinadorValidade: null,
            destinadorOrgaoEmissor: null,
            destinadorObservacao: null,
            destinadorDataRecebimento: null,
            executoraNome: null,
            executoraCnpj: null,
            executoraEmail: null,
            executoraFone: null,
            mtrccModelo: null
        };
        this.mostrarDialogEdit = true;
        this.isInsert = true;
        this.isManifestoModelo = false;
        this.informaObraPmpa = false;
        this.entidadeExecutora = null;
        this.manifestoForm.get('nomeobra').setValue('');
        this.tituloDialogEdit = 'Novo Manifesto';
        // this.manifestoForm.get('geradorCnpj').focus();
        // document.getElementById('idGeradorCnpj').focus();
        // }
        // setTimeout(() => {
        //   console.log('ngAfterViewInit--------------------->>>', this.gerCnpj);
        //    this.gerCnpj.nativeElement.focus();
        // }, 500);
    };
    ListaManifestosComponent.prototype.mostrarDialogParaModelo = function () {
        // console.log('*********************>>>>>', this.manifestosModelos);
        var _this = this;
        if (this.manifestosModelos.length === 1) {
            this.mostrarDialogParaNovoModelo(this.manifestosModelos[0].id);
        }
        else {
            this.manifestoModelo = true;
        }
        this.isManifestoModelo = true;
        if (this.manifestosModelos.length > 0) {
            this.temModelo = true;
            this.manifestosModelos.forEach(function (manif) {
                console.log('Manifestos getProdutosManifesto 611**************>>>>>');
                _this.manifestoService.getProdutosManifesto(manif.id).subscribe(function (manifProd) {
                    manif.produto = manifProd.produto;
                    _this.spinnerService.ocultar();
                }, function (error) {
                    console.log(error);
                    console.log(error.error);
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro(error.error, null);
                });
            });
        }
    };
    ListaManifestosComponent.prototype.mostrarDialogParaNovoModelo = function (idManifesto) {
        var _this = this;
        this.isManifestoModelo = true;
        var manifestoParaEditar = this.manifestosModelos.find(function (manifesto) {
            return manifesto.id === idManifesto;
        });
        // console.log('Editar mostrarDialogParaEditar***********11>>>>>>>', manifestoParaEditar);
        this.manifestoModeloAux = __assign({}, manifestoParaEditar);
        this.mostrarDialogParaEditar(idManifesto);
        this.manifestoEdit.id = null;
        this.manifestoEdit.timestamp = null;
        this.manifestoEdit.dataInclusao = null;
        this.manifestoEdit.usuarioInclusao = null;
        this.manifestoEdit.usuarioAlteracao = null;
        this.manifestoEdit.numeroManifesto = null;
        this.manifestoEdit.numManifesto = null;
        this.manifestoEdit.anoReferencia = null;
        this.manifestoEdit.quantidade = null;
        this.manifestoEdit.unidadeQuantidade = null;
        this.manifestoEdit.mtrccModelo = false;
        // this.manifestoForm.get('mtrccModelo').setValue(false);
        // this.manifestoForm.get('mtrccModelo').disable();
        this.buscaEntidade(this.manifestoEdit.geradorCnpj, 'gerador');
        this.manifestoForm.get('quantidade').enable();
        this.manifestoForm.get('quantidade').setValue(null);
        this.manifestoForm.get('unidadeQuantidade').enable();
        this.manifestoForm.get('transportadorPlaca').setValue(null);
        this.manifestoForm.get('transportadorPlaca').enable();
        this.buscaEntidade(this.manifestoEdit.transportadorCnpj, 'transportador');
        this.manifestoForm.get('transportadorCnpj').disable();
        this.manifestoForm.get('destinadorCnpj').disable();
        setTimeout(function () {
            _this.manifestoForm.get('geradorDataExpedicao').setValue(new Date());
            _this.manifestoForm.get('transportadorDataTransporte').setValue(new Date());
            // console.log('777777777777777777777777-------------------------*******>>>>');
        }, 2000);
        // console.log('-------------------------*******>>>>', this.manifestoEdit);
        this.mostrarDialogEdit = true;
        this.isInsert = false;
        this.justificarAnulacaoRecusa = false;
        this.tituloDialogEdit = 'Novo Manifesto';
        this.manifestoModeloAux === null;
        // }
    };
    ListaManifestosComponent.prototype.mostrarDialogParaEditar = function (idManifesto) {
        // console.log('*********************>>>>>', this.manifestos);
        var _this = this;
        var manifestoParaEditar = this.manifestos.find(function (manifesto) {
            return manifesto.id === idManifesto;
        });
        // console.log('Editar mostrarDialogParaEditar***********11>>>>>>>', manifestoParaEditar);
        this.manifestoEdit = __assign({}, manifestoParaEditar);
        // console.log('Editar mostrarDialogParaEditar***********11>>>>>>>', this.manifestoEdit);
        this.createForm();
        if (this.manifestoEdit.endereco !== null) {
            if (this.manifestoEdit.endereco.codigoLogradouro == null) {
                this.enderecoRetiradaCompleto = this.manifestoEdit.endereco.nomeLogradouro + ', '
                    + this.manifestoEdit.endereco.numero + ' - ' + this.manifestoEdit.endereco.municipio;
            }
            else {
                this.enderecoRetiradaCompleto = this.manifestoEdit.endereco.enderecoFormatado + ', Porto Alegre';
            }
        }
        this.informaObraPmpa = false;
        if (this.manifestoEdit.nomeobra !== null && this.manifestoEdit.nomeobra !== '') {
            this.informaObraPmpa = true;
            this.nomeObras = [];
            this.entidadeExecutora = null;
            this.nomeObrasList.forEach(function (no) {
                _this.nomeObras.push({ label: no.nome, value: no.nome });
                if (no.nome === _this.manifestoEdit.nomeobra) {
                    _this.entidadeExecutora = no.executora;
                    _this.manifestoForm.get('nomeobra').setValue(_this.manifestoEdit.nomeobra);
                    _this.manifestoForm.get('nomeobra').disable();
                }
            });
        }
        this.formataTelaGerador(this.manifestoEdit);
        if (this.manifestoEdit.geradorCnpj === null) {
            this.manifestoForm.get('geradorCnpj').setValue('TransienteSeFisica');
            this.manifestoForm.get('geradorCpf').setValue(this.manifestoEdit.geradorCpf);
            // this.manifestoForm.get('geradorCpf').disable();
            this.manifestoForm.get('tipoGerador').setValue('fisica');
            this.manifestoForm.get('geradorLicenca').setValue('');
            this.manifestoForm.get('geradorObservacao').setValue('');
            this.manifestoForm.get('geradorEndSel').setValue('TransienteSeFisica');
            this.manifestoForm.get('geradorRespSel').setValue('TransienteSeFisica');
        }
        else {
            this.manifestoForm.get('geradorCnpj').setValue(this.manifestoEdit.geradorCnpj);
            this.isGeradorCNPJSemCadastro = false;
            if (this.manifestoEdit.gerador == null) {
                this.isGeradorCNPJSemCadastro = true;
            }
        }
        this.disabledEdicao('gerador');
        this.manifestoForm.get('tipoGerador').disable();
        this.formataTelaTransportador(this.manifestoEdit);
        if (this.manifestoEdit.transportadorCnpj === null) {
            this.manifestoForm.get('transportadorCnpj').setValue('TransienteSeFisica');
            this.manifestoForm.get('transportadorCpf').setValue(this.manifestoEdit.transportadorCpf);
            this.manifestoForm.get('tipoTransportador').setValue('fisica');
            this.manifestoForm.get('transportadorLicenca').setValue('Não possui licença.');
            this.manifestoForm.get('transportadorEndSel').setValue('TransienteSeFisica');
            this.manifestoForm.get('transportadorVeiSel').setValue('TransienteSeFisica');
        }
        else {
            this.manifestoForm.get('transportadorCnpj').setValue(this.manifestoEdit.transportadorCnpj);
        }
        this.disabledPJ(this.manifestoForm.get('tipoTransportador').value, 'transportador');
        this.formataTelaDestinador(this.manifestoEdit);
        if (this.manifestoEdit.destinadorCnpj === null) {
            this.manifestoForm.get('destinadorCnpj').setValue('TransienteSeFisica');
            this.manifestoForm.get('destinadorCpf').setValue(this.manifestoEdit.destinadorCpf);
            this.manifestoForm.get('tipoDestinador').setValue('fisica');
            // this.manifestoForm.get('destinadorLicenca').setValue('Não possui licença.');
            this.manifestoForm.get('destinadorObservacao').setValue('');
            this.manifestoForm.get('destinadorEndSel').setValue('TransienteSeFisica');
        }
        else {
            this.manifestoForm.get('destinadorCnpj').setValue(this.manifestoEdit.destinadorCnpj);
        }
        this.disabledPJ(this.manifestoForm.get('tipoDestinador').value, 'destinador');
        this.manifestoForm.get('obra').setValue(this.manifestoEdit.obra);
        this.manifestoForm.get('obra').disable();
        this.manifestoForm.get('etapa').setValue(this.manifestoEdit.etapa);
        this.manifestoForm.get('etapa').disable();
        // this.manifestoForm.get('produto').setValue(this.manifestoEdit.produto);
        // this.manifestoForm.get('produto').disable();
        this.manifestoForm.get('quantidade').setValue(this.manifestoEdit.quantidade);
        this.manifestoForm.get('quantidade').disable();
        this.manifestoForm.get('unidadeQuantidade').setValue(this.manifestoEdit.unidadeQuantidade);
        this.manifestoForm.get('unidadeQuantidade').disable();
        if (this.manifestoEdit.endereco !== null) {
            this.manifestoForm.get('logradouro').setValue(this.manifestoEdit.endereco.nomeLogradouro);
            this.manifestoForm.get('numero').setValue(this.manifestoEdit.endereco.numero);
        }
        this.manifestoForm.get('logradouro').disable();
        this.manifestoForm.get('numero').disable();
        // this.manifestoForm.get('identificacao').setValue(this.manifestoEdit.endereco.identificacao);
        // this.manifestoForm.get('identificacao').disable();
        // this.manifestoForm.get('observacao').setValue(this.manifestoEdit.endereco.observacao);
        // this.manifestoForm.get('observacao').disable();
        this.manifestoForm.get('justificativaAnulacaoRecusa').setValue(this.manifestoEdit.justificativaAnulacaoRecusa);
        this.manifestoForm.get('justificativaAnulacaoRecusa').disable();
        this.manifestoForm.get('dataAnulacaoRecusa').setValue(new Date(this.manifestoEdit.dataAnulacaoRecusa));
        this.manifestoForm.get('dataAnulacaoRecusa').disable();
        if (this.manifestoEdit.dataAnulacaoRecusa != null || this.manifestoEdit.destinadorDataRecebimento != null
            || this.manifestoEdit.isDestinador || !this.variaveisGlobais.roleEntidade) {
            this.disabledEdicao('transportador');
            this.manifestoForm.get('tipoTransportador').disable();
            this.disabledEdicao('destinador');
            this.manifestoForm.get('tipoDestinador').disable();
        }
        // this.manifestoForm.get('mtrccModelo').setValue(this.manifestoEdit.mtrccModelo);
        // this.manifestoForm.get('mtrccModelo').disable();
        console.log('Manifestos getProdutosManifesto 1433**************>>>>>');
        // buscar produtos e classificações do manifesto
        this.manifestoService.getProdutosManifesto(idManifesto).subscribe(function (manifesto) {
            _this.manifestoEdit.produto = manifesto.produto;
            // this.manifestoEdit.classificacao = manifesto.classificacao;
            _this.spinnerService.ocultar();
            _this.manifestoForm.get('produto').setValue(_this.manifestoEdit.produto);
            _this.manifestoForm.get('produto').disable();
            console.log('....................mostrarDialogParaEditar>>>>>>>>', _this.manifestoEdit);
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
        this.mostrarDialogEdit = true;
        this.isInsert = false;
        // this.isManifestoModelo = false;
        this.justificarAnulacaoRecusa = false;
        this.tituloDialogEdit = 'Editar Manifesto';
        if (this.manifestoEdit.recusado || this.manifestoEdit.anulado || this.manifestoEdit.destinadorDataRecebimento !== null
            || this.keycloakService.hasRole('SMAMS')) {
            this.tituloDialogEdit = 'Consultar Manifesto';
        }
        // Buscar licenças ambientais e E.U dos endereços do gerador e destino final
        if (!this.variaveisGlobais.roleEntidade) {
            console.log('....................====================');
            if (this.manifestoEdit.geradorMunicipio === 'Porto Alegre' || this.manifestoEdit.geradorMunicipio === 'porto alegre') {
                this.enderecoService.getLicencasEusVO(this.manifestoEdit.id).subscribe(function (licencasEUs) {
                    // console.log('....................mostrarLicencasEUs>>>>>>>>', licencasEUs);
                    _this.licencasEUsVOList = [];
                    _this.licencasEUsVOList = licencasEUs;
                    _this.licencaEUGerador = false;
                    _this.licencaEUDestinador = false;
                    _this.licencasEUsVOList.forEach(function (leu) {
                        if (leu.licencaGerador || leu.euGerador) {
                            _this.licencaEUGerador = true;
                        }
                        if (leu.licencaDestinador || leu.euDestinador) {
                            _this.licencaEUDestinador = true;
                        }
                    });
                    console.log('....................mostrarLicencasEUs>>>>>>>>', _this.licencasEUsVOList);
                }, function (error) {
                    console.log(error);
                    console.log(error.error);
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro(error.error, null);
                });
            }
        }
        // if (this.manifestoEdit.destinadorLicenca !== '' && this.manifestoEdit.destinadorLicenca !== null) {
        //   this.licencasEUsVO = new LicencasEUsVO();
        //   this.licencasEUsVO.licencaGerador = false;
        //   this.licencasEUsVO.licencaDestinador = true;
        //   this.licencasEUsVO.licencaCodigo = this.manifestoEdit.destinadorLicenca;
        //   this.licencasEUsVO.euGerador = false;
        //   this.licencasEUsVO.euDestinador = false;
        //   this.licencasEUsVOList.push(this.licencasEUsVO);
        // }
        // <span *ngIf="endereco.licencaAmbientalDO !== null">{{endereco.licencaAmbientalDO?.licenca}}</span>
        // <span *ngIf="endereco.licencaAmbientalDO === null && endereco.isentoLicenca">Isento</span>
    };
    ListaManifestosComponent.prototype.totalizadorEntidade = function (idManifesto) {
        this.isTotalizador = true;
        var manifestoParaEditar = this.manifestos.find(function (manifesto) {
            return manifesto.id === idManifesto;
        });
        console.log('Editar mostrarDialogParaEditar11>>>>>>>', manifestoParaEditar);
        this.manifestoEdit = __assign({}, manifestoParaEditar);
    };
    ListaManifestosComponent.prototype.disabledEdicao = function (tipoEntidade) {
        this.manifestoForm.get(tipoEntidade + 'Cnpj').disable();
        this.manifestoForm.get(tipoEntidade + 'Cpf').disable();
        this.manifestoForm.get(tipoEntidade + 'Nome').disable();
        this.manifestoForm.get(tipoEntidade + 'Endereco').disable();
        this.manifestoForm.get(tipoEntidade + 'Municipio').disable();
        this.manifestoForm.get(tipoEntidade + 'Email').disable();
        this.manifestoForm.get(tipoEntidade + 'Fone').disable();
        // this.manifestoForm.get(tipoEntidade + 'Licenca').disable();
        if (tipoEntidade === 'gerador') {
            this.manifestoForm.get(tipoEntidade + 'DataExpedicao').disable();
            this.manifestoForm.get(tipoEntidade + 'Observacao').disable();
        }
        if (tipoEntidade === 'transportador') {
            this.manifestoForm.get(tipoEntidade + 'Placa').disable();
            this.manifestoForm.get(tipoEntidade + 'DataTransporte').disable();
        }
        console.log('.......................disabledEdicao - Manifesto>>>>>>>>', tipoEntidade);
        if (tipoEntidade === 'destinador') {
            this.manifestoForm.get(tipoEntidade + 'DataRecebimento').disable();
            this.manifestoForm.get(tipoEntidade + 'Observacao').disable();
        }
    };
    ListaManifestosComponent.prototype.buscaEntidade = function (cnpj, tipoEntidade) {
        // console.log('buscaEntidade--------------------->>>', this.gerCnpj);
        var _this = this;
        // console.log('@@@@@@@>>>', evento);
        // evento.el.focus = true;
        // if (evento !== false) {
        // document.getElementById('#geradorCnpj').focus();
        // setTimeout(() => {
        //   evento.focus();
        // }, 500);
        // }
        // console.log('buscaEntidade--------------------->>>', cnpj);
        // console.log('buscaEntidade--------------------->>>', cnpj.replace(/[^\d]+/g, ''));
        if (cnpj == null || cnpj === '' || cnpj.replace(/[^\d]+/g, '').length < 14) {
            //  this.spinnerService.ocultar();
            //  this.notificacoesService.notificarErro('CNPJ inválido!', null);
            return;
        }
        // this.nomeObras = [];
        if (tipoEntidade === 'gerador') {
            this.informaObraPmpa = false;
            this.entidadeExecutora = null;
            this.manifestoForm.get('nomeobra').setValue('');
            this.endsEntidadeGerador = [];
            this.respsEntidadeGerador = [];
            if (cnpj === '92.963.560/0001-60') {
                this.informaObraPmpa = true;
                // this.nomeObrasList.forEach(no => {
                //   this.nomeObras.push( {label: no.nome, value: no.nome});
                // });
            }
        }
        if (tipoEntidade === 'transportador') {
            this.endsEntidadeTransportador = [];
            this.veisEntidadeTransportador = [];
        }
        if (tipoEntidade === 'destinador') {
            this.endsEntidadeDestinador = [];
            this.licsDestinador = [];
        }
        // if (cnpj == null || cnpj === '' || cnpj === '__.___.___/____-__') {
        //  this.spinnerService.ocultar();
        //  this.notificacoesService.notificarErro('Não encontrou entidade para CNPJ informado!', null);
        // } else {
        this.entidadeService.getEntidadeCnpj(cnpj).subscribe(function (entidade) {
            _this.entidadeManifesto = entidade;
            // console.log('buscaEntidade---entidade------------------>>>', entidade);
            if (entidade === null) {
                _this.notificacoesService.notificarAviso('Empresa não localizada para o CNPJ informado no Sistema SGR.' +
                    ' Favor solicitar o cadastramento dessa empresa no SGR.', null);
                // if (tipoEntidade === 'gerador') {
                //   this.spinnerService.exibir();
                //   const novoCnpj = cnpj.replace(/[^\d]+/g, '');
                //   this.smfPessoaService.getPessoaFazenda(novoCnpj, '2').subscribe(pessoa => {
                //     if (pessoa === null) {
                //       this.notificacoesService.notificarAviso('Empresa não localizada para o CNPJ informado no Sistema SGR.' +
                //         ' Favor solicitar o cadastramento dessa empresa no SGR.', null);
                //     }
                //     this.spinnerService.ocultar();
                //   });
                // } else {
                //   this.spinnerService.ocultar();
                //   this.notificacoesService.notificarErro('Não localizada empresa para o CNPJ informado no Sistema SGR.' +
                //     ' Empresa existe no cadastro da Prefeitura Municipal de Porto Alegre – PMPA.' +
                //     ' Favor solicitar o cadastramento no SGR.', null);
                // }
            }
            else {
                if (entidade.desabilitadaMtrcc) {
                    _this.notificacoesService.notificarAviso('ATENÇÃO! Empresa ' + entidade.razaoSocial +
                        ' está inabilitada para geração de MTRCC. Para maior informação, entrar em contato ' +
                        ' com a SMAMUS pelo email: smamus.mtrcc@portoalegre.rs.gov.br', null);
                    if (_this.isManifestoModelo) {
                        _this.mostrarDialogEdit = false;
                    }
                    return;
                }
                if (tipoEntidade === 'gerador') {
                    _this.isGeradorCNPJSemCadastro = false;
                    _this.manifestoForm.get('geradorNome').disable();
                    _this.manifestoForm.get('geradorEndereco').disable();
                    _this.manifestoForm.get('geradorMunicipio').disable();
                    // this.manifestoForm.get('geradorEnderecoIdentificacao').disable();
                    // this.manifestoForm.get('geradorEnderecoObservacao').disable();
                    _this.manifestoForm.get('geradorEmail').disable();
                    _this.manifestoForm.get('geradorFone').disable();
                    _this.manifestoForm.get('geradorLicenca').disable();
                    // this.manifestoForm.get('geradorObservacao').disable();
                }
                console.log('BuscaEntidade1111>>>>>>>', entidade);
                _this.buscaEnderecoEntidade(entidade, tipoEntidade);
            }
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
        // }
    };
    ListaManifestosComponent.prototype.buscaPessoa = function (cpf, tipoEntidade) {
        //  console.log('buscaPessoa>>>>>>>', this.variaveisGlobais.pessoaFisica);
        var _this = this;
        if (cpf == null || cpf === '' || cpf.replace(/[^\d]+/g, '').length < 11) {
            // this.spinnerService.ocultar();
            // this.notificacoesService.notificarErro('CPF inválido!', null);
            return;
        }
        // if (cpf === '___.___.___-__') {
        //   cpf = null;
        // }
        this.pessoaFisica = null;
        // if (cpf == null || cpf === '' || cpf === '__.___.___-__') {
        //  this.spinnerService.ocultar();
        //  this.notificacoesService.notificarErro('Não encontrou entidade para CNPJ informado!', null);
        // } else {
        if (this.variaveisGlobais.pessoaFisica.cpf === cpf) {
            // console.log('22**************');
            this.pessoaFisica = this.variaveisGlobais.pessoaFisica;
            if (tipoEntidade === 'destinador') {
                this.buscaLicencaAmbiental(this.pessoaFisica, tipoEntidade);
            }
            else {
                this.formataPFAuto(this.pessoaFisica, tipoEntidade);
            }
        }
        else {
            this.pessoaFisicaService.getPessoasFisicasPorCpf(cpf).subscribe(function (pesFisica) {
                // console.log('33**************', pesFisica);
                _this.pessoaFisica = pesFisica;
                if (tipoEntidade === 'destinador') {
                    _this.buscaLicencaAmbiental(_this.pessoaFisica, tipoEntidade);
                }
                else {
                    _this.formataPFAuto(_this.pessoaFisica, tipoEntidade);
                }
            }, function (error) {
                console.log(error);
                console.log(error.error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
        // }
    };
    ListaManifestosComponent.prototype.buscaLicencaAmbiental = function (pf, tipoEntidade) {
        var _this = this;
        this.licencaAmbientalService.getLicencasPorPessoaFisica(pf.id).subscribe(function (licencas) {
            console.log('buscaLicencaAmbiental>>>>>>>', licencas);
            _this.formataPFAuto(_this.pessoaFisica, tipoEntidade);
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaManifestosComponent.prototype.formataPFAuto = function (pf, tipoEntidade) {
        // console.log('******************>>>>', pf);
        if (this.pessoaFisica !== null) {
            // this.isGeradorCNPJSemCadastro = false;
            this.manifestoForm.get(tipoEntidade + 'Nome').disable();
            // this.manifestoForm.get('geradorEndereco').disable();
            // this.manifestoForm.get('geradorMunicipio').disable();
            this.manifestoForm.get(tipoEntidade + 'Email').disable();
            this.manifestoForm.get(tipoEntidade + 'Fone').disable();
            // this.manifestoForm.get('geradorLicenca').disable();
            this.manifestoForm.get(tipoEntidade + 'Nome').setValue(pf.nomeCompleto);
            this.manifestoForm.get(tipoEntidade + 'Email').setValue(pf.email);
            if (pf.celular !== undefined && pf.celular !== null && pf.celular !== '') {
                this.manifestoForm.get(tipoEntidade + 'Fone').setValue(pf.celular);
            }
            else {
                this.manifestoForm.get(tipoEntidade + 'Fone').setValue(pf.telefone);
            }
        }
    };
    ListaManifestosComponent.prototype.geradorCNPJNaoCadastrado = function () {
        console.log('geradorCNPJNaoCadastrado>>>>>>>', this.manifestoForm);
        this.isGeradorCNPJSemCadastro = true;
        console.log('...................condições.......>>>', this.isInsert, '-', this.isGeradorPF(), '-', this.isGeradorCNPJSemCadastro);
        this.manifestoForm.get('geradorNome').setValue('');
        this.manifestoForm.get('geradorEndereco').setValue('');
        this.manifestoForm.get('geradorMunicipio').setValue('');
        this.manifestoForm.get('geradorEmail').setValue('');
        this.manifestoForm.get('geradorFone').setValue('');
        this.manifestoForm.get('geradorLicenca').setValue('');
        this.manifestoForm.get('geradorObservacao').setValue('');
        this.manifestoForm.get('geradorNome').enable();
        this.manifestoForm.get('geradorEndereco').enable();
        this.manifestoForm.get('geradorMunicipio').enable();
        this.manifestoForm.get('geradorEmail').enable();
        this.manifestoForm.get('geradorFone').enable();
        this.manifestoForm.get('geradorLicenca').enable();
        this.manifestoForm.get('geradorObservacao').enable();
    };
    ListaManifestosComponent.prototype.buscaEnderecoEntidade = function (entidade, tipoEntidade) {
        var _this = this;
        this.enderecoService.getEnderecosPorEntidade(entidade.id).subscribe(function (enderecos) {
            var enderecoManifesto = new EnderecoEntidade();
            if (_this.manifestoModeloAux !== null) { //gerando manifesto a partir do modelo
                enderecoManifesto.enderecoFormatado = null;
                enderecos.forEach(function (end) {
                    if (tipoEntidade === 'gerador') {
                        if (end.enderecoFormatado === _this.manifestoModeloAux.geradorEndereco) {
                            enderecoManifesto = end;
                        }
                    }
                    if (tipoEntidade === 'transportador') {
                        if (end.enderecoFormatado === _this.manifestoModeloAux.transportadorEndereco) {
                            enderecoManifesto = end;
                        }
                    }
                    if (tipoEntidade === 'destinador') {
                        if (end.enderecoFormatado === _this.manifestoModeloAux.destinadorEndereco) {
                            enderecoManifesto = end;
                        }
                    }
                });
                if (enderecoManifesto.enderecoFormatado === null) {
                    _this.notificacoesService.notificarErro('ATENÇÃO!!!! Um dos endereços deste Manifesto Modelo ' +
                        'foram excluídos. Este Manifesto não pode mais ser utilizado como Modelo!!! ' +
                        'Favor EXCLUIR esse Manifesto Modelo!', null);
                    // this.manifestoModelo = false;
                    _this.mostrarDialogEdit = false;
                    _this.isManifestoModelo = false;
                    return;
                }
            }
            else {
                enderecoManifesto = enderecos[0];
            }
            _this.spinnerService.ocultar();
            if (tipoEntidade === 'gerador') {
                _this.isPMPAGerador = false;
                _this.endsEntidadeGerador = [];
                if (entidade.cnpj === '92.963.560/0001-60') {
                    _this.isPMPAGerador = true;
                    _this.secretariaPmpaService.getSecretariasPmpa().subscribe(function (secretarias) {
                        _this.secsEntidadeGerador = [];
                        secretarias.forEach(function (secret) {
                            _this.secsEntidadeGerador.push(secret);
                            _this.endereco = new EnderecoEntidade();
                            _this.endereco.id = secret.id; //para selecionar a secretaria quando escolher outro endereço.
                            if (secret.endereco !== null) {
                                _this.endereco.enderecoFormatado = secret.endereco.enderecoFormatadoCurto;
                            }
                            _this.endereco.municipio = 'Porto Alegre';
                            _this.endereco.identificacao = secret.sigla;
                            _this.endsEntidadeGerador.push(_this.endereco);
                        });
                        _this.manifestoEdit.geradorSecretaria = secretarias[0];
                        _this.manifestoForm.get(tipoEntidade + 'EndSel').setValue(_this.endsEntidadeGerador[0].id);
                        _this.enderecoSelecionado(_this.endsEntidadeGerador[0], tipoEntidade, '');
                        _this.buscaPessoaFisica(entidade, tipoEntidade);
                    }, function (error) {
                        _this.spinnerService.ocultar();
                        _this.notificacoesService.notificarErro(error.error, null);
                    });
                }
                else {
                    if (enderecoManifesto === null) {
                        _this.notificacoesService.notificarErro('ATENÇÃO!!!! Favor solicitar para que a Empresa: ' +
                            entidade.razaoSocial + ' insira seu endereço.', null);
                        _this.mostrarDialogEdit = false;
                        _this.isManifestoModelo = false;
                        return;
                    }
                    _this.endsEntidadeGerador = enderecos;
                    _this.manifestoForm.get(tipoEntidade + 'EndSel').setValue(enderecoManifesto.id);
                    _this.enderecoSelecionado(enderecoManifesto, tipoEntidade, '');
                    _this.buscaPessoaFisica(entidade, tipoEntidade);
                }
            }
            if (tipoEntidade === 'transportador') {
                _this.endsEntidadeTransportador = [];
                if (entidade.cnpj === '92.963.560/0001-60') {
                    enderecos.forEach(function (end) {
                        if (end.identificacao === null) {
                            _this.endsEntidadeTransportador.push(end);
                            enderecos = _this.endsEntidadeTransportador;
                        }
                    });
                }
                else {
                    if (enderecoManifesto === null) {
                        _this.notificacoesService.notificarErro('ATENÇÃO!!!! Favor solicitar para que a Empresa: ' +
                            entidade.razaoSocial + ' insira seu endereço.', null);
                        _this.mostrarDialogEdit = false;
                        _this.isManifestoModelo = false;
                        return;
                    }
                    _this.endsEntidadeTransportador = enderecos;
                }
                _this.manifestoForm.get(tipoEntidade + 'EndSel').setValue(enderecoManifesto.id);
                _this.enderecoSelecionado(enderecoManifesto, tipoEntidade, '');
                _this.buscaVeiculoEntidade(entidade, tipoEntidade);
            }
            if (tipoEntidade === 'destinador') {
                _this.isPMPADestinador = false;
                _this.endsEntidadeDestinador = [];
                console.log('endereços destinador>>>>>>', enderecos);
                _this.manifestoEdit.destinadorCnpj = entidade.cnpj;
                if (entidade.cnpj === '92.963.560/0001-60') {
                    _this.isPMPADestinador = true;
                    enderecos.forEach(function (end) {
                        if (end.identificacao !== null) {
                            if (end.ativo) {
                                _this.endsEntidadeDestinador.push(end);
                            }
                        }
                    });
                    enderecos = _this.endsEntidadeDestinador;
                }
                else {
                    if (enderecoManifesto === null) {
                        _this.notificacoesService.notificarErro('ATENÇÃO!!!! Favor solicitar para que a Empresa: ' +
                            entidade.razaoSocial + ' insira seu endereço.', null);
                        _this.mostrarDialogEdit = false;
                        _this.isManifestoModelo = false;
                        return;
                    }
                    _this.endsEntidadeDestinador = enderecos;
                }
                _this.manifestoForm.get(tipoEntidade + 'EndSel').setValue(enderecoManifesto.id);
                _this.enderecoSelecionado(enderecoManifesto, tipoEntidade, '');
                _this.buscaPessoaFisica(entidade, tipoEntidade);
                // this.buscaLicencaEntidade(entidade, tipoEntidade);
            }
        }, function (error) {
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaManifestosComponent.prototype.buscaVeiculoEntidade = function (entidade, tipoEntidade) {
        var _this = this;
        console.log('buscaVeiculoEntidade0000000>>>>', entidade);
        this.veiculoService.getVeiculosPorEntidade(entidade.id).subscribe(function (veiculos) {
            if (tipoEntidade === 'transportador') {
                _this.veisEntidadeTransportador = [];
                _this.veisEntidadeTransportador = veiculos;
                if (veiculos.length < 1) {
                    _this.notificacoesService.notificarAviso("Para a empresa ser TRANSPORTADORA do MTRCC é necessário" +
                        " que a mesma tenha ao menos um veículo cadastrado", null);
                    return;
                }
            }
            console.log('buscaVeiculoEntidade>>>>', veiculos);
            if (veiculos.length > 0) {
                _this.manifestoForm.get(tipoEntidade + 'VeiSel').setValue(veiculos[0].id);
                _this.veiculoSelecionado(veiculos[0], tipoEntidade);
            }
            else {
                _this.manifestoForm.get(tipoEntidade + 'VeiSel').setValue('');
                _this.manifestoForm.get(tipoEntidade + 'Placa').setValue('');
            }
            //    BUSCA PessoaFisica
            _this.buscaPessoaFisica(entidade, tipoEntidade);
        }, function (error) {
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    // buscaLicencaEntidade(entidade: Entidade, tipoEntidade: string) {
    //   console.log('buscaLicencaEntidade>>>>', entidade);
    //   this.licencaAmbientalService.getLicencasPorEntidade(entidade.id).subscribe(licencas => {
    //     if (tipoEntidade === 'destinador') {
    //       this.licsEntidadeDestinador = [];
    //       this.licsEntidadeDestinador = licencas;
    //     }
    //     console.log('buscaLicencaEntidade>>>>', licencas);
    //     if (licencas.length > 0) {
    //       // this.manifestoForm.get(tipoEntidade + 'LicencaIsento').setValue('naoIsento');
    //       this.manifestoForm.get(tipoEntidade + 'Licenca').setValue(licencas[0].id);
    //       this.licencaSelecionada(licencas[0], tipoEntidade);
    //     } else {
    //       // this.manifestoForm.get(tipoEntidade + 'LicencaIsento').setValue('isento');
    //       this.manifestoForm.get(tipoEntidade + 'Licenca').setValue('');
    //       // this.manifestoForm.get(tipoEntidade + 'Validade').setValue('');
    //       // this.manifestoForm.get(tipoEntidade + 'OrgaoEmissor').setValue('');
    //     }
    //   },
    //   error => {
    //     this.spinnerService.ocultar();
    //     this.notificacoesService.notificarErro(error.error, null);
    //   });
    // }
    ListaManifestosComponent.prototype.buscaPessoaFisica = function (entidade, tipoEntidade) {
        var _this = this;
        //    BUSCA PessoaFisica
        this.pessoaFisicaService.getPessoasFisicasResponsavelEntidade(entidade.id, entidade.cnpj).subscribe(function (pessoasFisicas) {
            _this.pfSecrsEntidadeGerador = pessoasFisicas;
            if (tipoEntidade === 'gerador') {
                _this.respsEntidadeGerador = [];
            }
            if (tipoEntidade === 'transportador') {
                _this.respsEntidadeTransportador = [];
            }
            if (tipoEntidade === 'destinador') {
                _this.respsEntidadeDestinador = [];
            }
            pessoasFisicas.forEach(function (pf) {
                if (tipoEntidade === 'gerador') {
                    if (entidade.cnpj === '92.963.560/0001-60') {
                        var idSecretaria = _this.manifestoEdit.geradorSecretaria.id;
                        // console.log('..............................Secretaria>>>>', idSecretaria);
                        // se for PMPA seleciona o responsável legal da Secretaria selecionada
                        if (pf.idSecretaria === idSecretaria) {
                            _this.respsEntidadeGerador.push(pf);
                        }
                    }
                    else {
                        // se não for PMPA - apresenta os responsáveis na tela para seleção quando gerador
                        if (pf.email !== null && (pf.telefone !== null || pf.celular !== null)) {
                            // if (pf.rLegal) {
                            _this.respsEntidadeGerador.push(pf);
                        }
                    }
                }
                else {
                    if (tipoEntidade === 'transportador') {
                        if (entidade.cnpj === '92.963.560/0001-60') { //PMPA
                            if (pf.rSecretario) {
                                _this.respsEntidadeTransportador.push(pf);
                                // this.pessoaFisica = pf;
                            }
                        }
                        else {
                            // se não é gerador pega sempre o Responsável Legal
                            if (pf.rLegal) {
                                _this.respsEntidadeTransportador.push(pf);
                                // this.pessoaFisica = pf;
                            }
                        }
                    }
                    if (tipoEntidade === 'destinador') {
                        if (entidade.cnpj === '92.963.560/0001-60') { //PMPA
                            if (pf.rSecretario) {
                                _this.respsEntidadeDestinador.push(pf);
                                // this.pessoaFisica = pf;
                            }
                        }
                        else {
                            // se não é gerador pega sempre o Responsável Legal
                            if (pf.rLegal) {
                                _this.respsEntidadeDestinador.push(pf);
                                // this.pessoaFisica = pf;
                            }
                        }
                    }
                }
            });
            if (tipoEntidade === 'gerador') {
                if (_this.respsEntidadeGerador.length > 0) {
                    if (_this.isInsert) {
                        _this.manifestoForm.get('geradorRespSel').setValue(_this.respsEntidadeGerador[0].id);
                        _this.pessoaFisica = _this.respsEntidadeGerador[0];
                        _this.responsavelSelecionado(_this.respsEntidadeGerador[0], tipoEntidade);
                    }
                    else {
                    }
                }
                else {
                    _this.manifestoEdit.geradorEmail = null;
                    _this.manifestoEdit.geradorFone = null;
                    if (entidade.cnpj === '92.963.560/0001-60') {
                        _this.notificacoesService.notificarAviso('Favor solicitar à Secretaria selecionada no ' +
                            'Endereço para que a mesma inclua as informações do Secretário!', null);
                        return;
                    }
                    else {
                        _this.notificacoesService.notificarAviso('Favor solicitar à Empresa geradora para que providencie ' +
                            'a inclusão do Responsável Legal!', null);
                        return;
                    }
                }
            }
            if (tipoEntidade === 'transportador') {
                // console.log('------ Transportador >>>', this.respsEntidadeTransportador);
                if (_this.respsEntidadeTransportador.length > 0) {
                    // this.manifestoForm.get('transportadorRespSel').setValue(this.respsEntidadeTransportador[0].id);
                    _this.pessoaFisica = _this.respsEntidadeTransportador[0];
                    _this.responsavelSelecionado(_this.respsEntidadeTransportador[0], tipoEntidade);
                }
                else {
                    _this.manifestoEdit.transportadorEmail = null;
                    _this.manifestoEdit.transportadorFone = null;
                    if (entidade.cnpj === '92.963.560/0001-60') {
                        _this.notificacoesService.notificarAviso('Favor solicitar à Secretaria selecionada no ' +
                            'Endereço para que a mesma inclua as informações do Secretário!', null);
                        return;
                    }
                    else {
                        _this.notificacoesService.notificarAviso('Favor solicitar à Empresa transportadora para que providencie ' +
                            'a inclusão do Responsável Legal!', null);
                        return;
                    }
                }
            }
            if (tipoEntidade === 'destinador') {
                // console.log('------ Destinador >>>', this.respsEntidadeDestinador);
                if (_this.respsEntidadeDestinador.length > 0) {
                    // this.manifestoForm.get('destinadorRespSel').setValue(this.respsEntidadeDestinador[0].id);
                    _this.pessoaFisica = _this.respsEntidadeDestinador[0];
                    _this.responsavelSelecionado(_this.respsEntidadeDestinador[0], tipoEntidade);
                }
                else {
                    _this.manifestoEdit.destinadorEmail = null;
                    _this.manifestoEdit.destinadorFone = null;
                    if (entidade.cnpj === '92.963.560/0001-60') {
                        _this.notificacoesService.notificarAviso('Favor solicitar à Secretaria selecionada no ' +
                            'Endereço para que a mesma inclua as informações do Secretário!', null);
                        return;
                    }
                    else {
                        _this.notificacoesService.notificarAviso('Favor solicitar à Empresa de destino final para que providencie ' +
                            'a inclusão do Responsável Legal!', null);
                        return;
                    }
                }
            }
            _this.formataEntidade(entidade, _this.pessoaFisica, tipoEntidade);
            // this.mensagemValidacao = '';
            // this.validaEntidade(this.manifestoEdit, 'juridica', tipoEntidade);
            // // console.log('buscaPessoaFisica>>>>>>>>', entidade);
            // if (this.mensagemValidacao !== '') {
            //   this.spinnerService.ocultar();
            //   this.notificacoesService.notificarErro(this.mensagemValidacao, null);
            // }
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaManifestosComponent.prototype.formataEntidade = function (entidade, pessoaFisica, tipoEntidade) {
        // console.log('FormataEntidade>>>>>', tipoEntidade, '---', entidade, '*****************', pessoaFisica);
        if (tipoEntidade === 'gerador') {
            this.manifestoEdit.gerador = entidade;
            //      this.manifestoEdit.geradorCnpj = entidade.cnpj;
            if (this.siglaSecretaria === '') {
                this.manifestoEdit.geradorNome = entidade.razaoSocial;
            }
            else {
                this.manifestoEdit.geradorNome = entidade.razaoSocial + ' - ' + this.siglaSecretaria;
            }
            this.manifestoEdit.geradorLicenca = entidade.licencaAmbiental;
            // if (entidade.enderecoDO != null) {
            //   this.manifestoEdit.geradorEndereco = entidade.enderecoDO.nomeLogradouro + ', ' + entidade.enderecoDO.numero;
            //   this.manifestoEdit.geradorMunicipio = entidade.enderecoDO.municipio;
            // }
            // this.manifestoEdit.geradorFone = pessoaFisica.telefone;
            // this.manifestoEdit.geradorEmail = pessoaFisica.email;
            this.formataTelaGerador(this.manifestoEdit);
        }
        if (tipoEntidade === 'transportador') {
            this.manifestoEdit.transportador = entidade;
            //      this.manifestoEdit.transportadorCnpj = entidade.cnpj;
            this.manifestoEdit.transportadorNome = entidade.razaoSocial;
            // this.manifestoEdit.transportadorPlaca = entidade.placas;
            this.manifestoEdit.transportadorLicenca = entidade.licencaAmbiental;
            // if (entidade.enderecoDO != null) {
            //   this.manifestoEdit.transportadorEndereco = entidade.enderecoDO.nomeLogradouro + ', ' + entidade.enderecoDO.numero;
            //   this.manifestoEdit.transportadorMunicipio = entidade.enderecoDO.municipio;
            // }
            if (pessoaFisica.telefone != null) {
                this.manifestoEdit.transportadorFone = pessoaFisica.telefone;
            }
            else if (pessoaFisica.celular != null) {
                this.manifestoEdit.transportadorFone = pessoaFisica.celular;
            }
            this.manifestoEdit.transportadorEmail = pessoaFisica.email;
            this.formataTelaTransportador(this.manifestoEdit);
        }
        if (tipoEntidade === 'destinador') {
            this.manifestoEdit.destinador = entidade;
            //      this.manifestoEdit.destinadorCnpj = entidade.cnpj;
            this.manifestoEdit.destinadorNome = entidade.razaoSocial;
            // this.manifestoEdit.destinadorLicenca = entidade.licencaAmbiental;
            // if (entidade.enderecoDO != null) {
            //   this.manifestoEdit.destinadorEndereco = entidade.enderecoDO.nomeLogradouro + ', ' + entidade.enderecoDO.numero;
            //   this.manifestoEdit.destinadorMunicipio = entidade.enderecoDO.municipio;
            // }
            this.manifestoEdit.destinadorFone = pessoaFisica.telefone;
            this.manifestoEdit.destinadorEmail = pessoaFisica.email;
            this.formataTelaDestinador(this.manifestoEdit);
        }
    };
    ListaManifestosComponent.prototype.formataTelaGerador = function (manifesto) {
        console.log('formataTelaGerador>>>>>>>>>>>>', manifesto);
        this.manifestoForm.get('geradorNome').setValue(manifesto.geradorNome);
        this.manifestoForm.get('geradorEndereco').setValue(manifesto.geradorEndereco);
        this.manifestoForm.get('geradorMunicipio').setValue(manifesto.geradorMunicipio);
        this.manifestoForm.get('geradorEmail').setValue(manifesto.geradorEmail);
        this.manifestoForm.get('geradorFone').setValue(manifesto.geradorFone);
        this.manifestoForm.get('geradorLicenca').setValue(manifesto.geradorLicenca);
        this.manifestoForm.get('geradorObservacao').setValue(manifesto.geradorObservacao);
        if (manifesto.geradorDataExpedicao != null) {
            console.log('Editar formataTelaGerador>>>>>>>', this.manifestoEdit);
            this.manifestoForm.get('geradorDataExpedicao').setValue(new Date(this.manifestoEdit.geradorDataExpedicao));
            this.manifestoForm.get('geradorDataExpedicao').disable();
        }
        else {
            // this.manifestoForm.get('geradorDataExpedicao').setValue(null);
        }
    };
    ListaManifestosComponent.prototype.formataTelaTransportador = function (manifesto) {
        this.manifestoForm.get('transportadorNome').setValue(manifesto.transportadorNome);
        this.manifestoForm.get('transportadorEndereco').setValue(manifesto.transportadorEndereco);
        this.manifestoForm.get('transportadorMunicipio').setValue(manifesto.transportadorMunicipio);
        this.manifestoForm.get('transportadorEmail').setValue(manifesto.transportadorEmail);
        this.manifestoForm.get('transportadorFone').setValue(manifesto.transportadorFone);
        this.manifestoForm.get('transportadorPlaca').setValue(manifesto.transportadorPlaca);
        this.manifestoForm.get('transportadorLicenca').setValue(manifesto.transportadorLicenca);
        if (manifesto.transportadorDataTransporte != null) {
            this.manifestoForm.get('transportadorDataTransporte').setValue(new Date(this.manifestoEdit.transportadorDataTransporte));
            this.manifestoForm.get('transportadorDataTransporte').disable();
        }
        else {
            // this.manifestoForm.get('transportadorDataTransporte').setValue(null);
        }
    };
    ListaManifestosComponent.prototype.formataTelaDestinador = function (manifesto) {
        this.manifestoForm.get('destinadorNome').setValue(manifesto.destinadorNome);
        this.manifestoForm.get('destinadorEndereco').setValue(manifesto.destinadorEndereco);
        this.manifestoForm.get('destinadorMunicipio').setValue(manifesto.destinadorMunicipio);
        this.manifestoForm.get('destinadorLicenca').setValue(manifesto.destinadorLicenca);
        this.manifestoForm.get('destinadorEmail').setValue(manifesto.destinadorEmail);
        this.manifestoForm.get('destinadorFone').setValue(manifesto.destinadorFone);
        this.manifestoForm.get('destinadorLicenca').disable();
        this.manifestoForm.get('destinadorLicenca').setValue(manifesto.destinadorLicenca);
        // console.log('111Editar formataTelaDestinador>>>>>>>', this.manifestoEdit);
        // console.log('222Editar formataTelaDestinador>>>>>>>', manifesto);
        this.manifestoForm.get('destinadorValidade').disable();
        if (manifesto.destinadorValidade != null) {
            this.manifestoForm.get('destinadorValidade').setValue(new Date(manifesto.destinadorValidade));
        }
        else {
            this.manifestoForm.get('destinadorValidade').setValue(null);
        }
        this.manifestoForm.get('destinadorOrgaoEmissor').disable();
        if (manifesto.destinadorOrgaoEmissor != null) {
            this.manifestoForm.get('destinadorOrgaoEmissor').setValue(manifesto.destinadorOrgaoEmissor);
        }
        else {
            this.manifestoForm.get('destinadorOrgaoEmissor').setValue(null);
        }
        this.manifestoForm.get('destinadorObservacao').setValue(manifesto.destinadorObservacao);
        if (manifesto.destinadorDataRecebimento != null) {
            this.manifestoForm.get('destinadorDataRecebimento').setValue(new Date(manifesto.destinadorDataRecebimento));
            this.manifestoForm.get('destinadorDataRecebimento').disable();
        }
        else {
            this.manifestoForm.get('destinadorDataRecebimento').setValue(null);
        }
    };
    ListaManifestosComponent.prototype.cancelar = function () {
        this.mostrarDialogEdit = false;
    };
    // selecionaModelo() {
    //   console.log('===========mtrccModelo=========>', this.manifestoForm.get('mtrccModelo').value);
    //   this.manifestoEdit.mtrccModelo = this.manifestoForm.get('mtrccModelo').value;
    // }
    ListaManifestosComponent.prototype.salvar = function (modelo) {
        var _this = this;
        this.spinnerService.exibir();
        if (!this.isPoa) {
            this.manifestoEdit.endereco = this.enderecoRes;
            this.manifestoEdit.endereco.nomeLogradouro = this.manifestoForm.get('logradouro').value;
            this.manifestoEdit.endereco.numero = this.manifestoForm.get('numero').value;
            this.manifestoEdit.endereco.municipio = this.manifestoForm.get('municipio').value;
        }
        // console.log('Salvar PostPut ......Form.....$$$$$$$$$>>>>>>', this.manifestoForm);
        // console.log('Salvar PostPut ......Manifesto.....$$$$$$$$$>>>>>>', this.manifestoEdit);
        if (this.manifestoForm.get('geradorCnpj').value === 'TransienteSeFisica') {
            // || this.manifestoForm.get('geradorLicenca').value === 'TransienteLicenca') {
            // console.log('TransienteSeFisica>>>>>>>', this.manifestoEdit);
            // if (this.manifestoForm.get('geradorCnpj').value === 'TransienteSeFisica') {
            this.manifestoEdit.geradorCnpj = null;
            this.manifestoEdit.geradorCpf = this.manifestoForm.get('geradorCpf').value;
            // } else {
            //   this.manifestoEdit.geradorCnpj = this.manifestoForm.get('geradorCnpj').value;
            //   this.manifestoEdit.geradorCpf = null;
            // }
            this.manifestoEdit.geradorNome = this.manifestoForm.get('geradorNome').value;
            this.manifestoEdit.geradorLicenca = null;
            this.manifestoEdit.geradorEndereco = this.manifestoForm.get('geradorEndereco').value;
            this.manifestoEdit.geradorMunicipio = this.manifestoForm.get('geradorMunicipio').value;
            this.manifestoEdit.geradorFone = this.manifestoForm.get('geradorFone').value;
            this.manifestoEdit.geradorEmail = this.manifestoForm.get('geradorEmail').value;
            this.manifestoEdit.geradorObservacao = this.manifestoForm.get('geradorObservacao').value;
            this.manifestoEdit.gerador = null;
        }
        else {
            this.manifestoEdit.geradorCnpj = this.manifestoForm.get('geradorCnpj').value;
            this.manifestoEdit.geradorCpf = null;
            this.manifestoEdit.geradorObservacao = this.manifestoForm.get('geradorObservacao').value;
        }
        if (this.manifestoForm.get('transportadorCnpj').value === 'TransienteSeFisica') {
            this.manifestoEdit.transportadorCnpj = null;
            this.manifestoEdit.transportadorCpf = this.manifestoForm.get('transportadorCpf').value;
            this.manifestoEdit.transportadorNome = this.manifestoForm.get('transportadorNome').value;
            this.manifestoEdit.transportadorPlaca = this.manifestoForm.get('transportadorPlaca').value;
            this.manifestoEdit.transportadorLicenca = this.manifestoForm.get('transportadorLicenca').value;
            this.manifestoEdit.transportadorEndereco = this.manifestoForm.get('transportadorEndereco').value;
            this.manifestoEdit.transportadorMunicipio = this.manifestoForm.get('transportadorMunicipio').value;
            this.manifestoEdit.transportadorFone = this.manifestoForm.get('transportadorFone').value;
            this.manifestoEdit.transportadorEmail = this.manifestoForm.get('transportadorEmail').value;
            this.manifestoEdit.transportador = null;
        }
        else {
            this.manifestoEdit.transportadorCnpj = this.manifestoForm.get('transportadorCnpj').value;
            this.manifestoEdit.transportadorCpf = null;
        }
        if (this.manifestoForm.get('destinadorCnpj').value === 'TransienteSeFisica') {
            this.manifestoEdit.destinadorCnpj = null;
            this.manifestoEdit.destinadorCpf = this.manifestoForm.get('destinadorCpf').value;
            this.manifestoEdit.destinadorNome = this.manifestoForm.get('destinadorNome').value;
            this.manifestoEdit.destinadorObservacao = this.manifestoForm.get('destinadorObservacao').value;
            this.manifestoEdit.destinadorEndereco = this.manifestoForm.get('destinadorEndereco').value;
            this.manifestoEdit.destinadorMunicipio = this.manifestoForm.get('destinadorMunicipio').value;
            this.manifestoEdit.destinadorFone = this.manifestoForm.get('destinadorFone').value;
            this.manifestoEdit.destinadorEmail = this.manifestoForm.get('destinadorEmail').value;
            this.manifestoEdit.destinador = null;
            this.pessoaFisica = this.variaveisGlobais.pessoaFisica;
            // console.log('7777777777777777777777777777777777......>>>', this.usuarioLogado);
            // console.log('7777777777777777777777777777777777......>>>', this.licencaAmbientalUsuarioLogado);
            if (this.licencaAmbientalUsuarioLogado !== undefined && this.licencaAmbientalUsuarioLogado !== null) {
                this.manifestoEdit.destinadorLicenca = this.licencaAmbientalUsuarioLogado.licenca;
                this.manifestoEdit.destinadorValidade = this.licencaAmbientalUsuarioLogado.dataValidade;
                this.manifestoEdit.destinadorOrgaoEmissor = this.licencaAmbientalUsuarioLogado.orgaoEmissor;
            }
        }
        else {
            this.manifestoEdit.destinadorCnpj = this.manifestoForm.get('destinadorCnpj').value;
            this.manifestoEdit.destinadorCpf = null;
            this.manifestoEdit.destinadorObservacao = this.manifestoForm.get('destinadorObservacao').value;
            // if (this.manifestoForm.get('destinadorLicSel').value === '') {
            //   this.manifestoEdit.destinadorLicenca = 'DISPENSA';
            //   console.log('##################>>>> ISENTO LICENÇA>>>', this.manifestoEdit);
            // }
        }
        this.manifestoEdit.geradorDataExpedicao = this.manifestoForm.get('geradorDataExpedicao').value;
        this.manifestoEdit.transportadorDataTransporte = this.manifestoForm.get('transportadorDataTransporte').value;
        this.manifestoEdit.destinadorDataRecebimento = null;
        this.manifestoEdit.obra = this.manifestoForm.get('obra').value;
        this.manifestoEdit.etapa = this.manifestoForm.get('etapa').value;
        this.manifestoEdit.produto = this.manifestoForm.get('produto').value;
        this.manifestoEdit.quantidade = this.manifestoForm.get('quantidade').value;
        this.manifestoEdit.unidadeQuantidade = this.manifestoForm.get('unidadeQuantidade').value;
        this.manifestoEdit.mtrccModelo = false;
        if (modelo) {
            this.manifestoEdit.mtrccModelo = true;
        }
        if (this.manifestoEdit.endereco !== null) {
            this.manifestoEdit.endereco.numero = this.manifestoForm.get('numero').value;
        }
        // this.manifestoEdit.endereco.identificacao = this.manifestoForm.get('identificacao').value;
        // this.manifestoEdit.endereco.observacao = this.manifestoForm.get('observacao').value;
        // SE GERADOR FOR A PMPA, VERIFICAR DADOS DA EMPRESA EXECUTORA, QUANDO EXISTIR
        if (this.manifestoEdit.geradorCnpj === '92.963.560/0001-60') {
            this.manifestoEdit.nomeobra = this.manifestoForm.get('nomeobra').value;
            if (this.entidadeExecutora !== null) {
                this.manifestoEdit.executora = this.entidadeExecutora;
                this.manifestoEdit.executoraCnpj = this.entidadeExecutora.cnpj;
                this.manifestoEdit.executoraEmail = this.entidadeExecutora.email;
                this.manifestoEdit.executoraFone = this.entidadeExecutora.telefone;
                this.manifestoEdit.executoraNome = this.entidadeExecutora.razaoSocial;
            }
        }
        // Classificação
        var listaProduto = this.manifestoForm.get('produto').value;
        // console.log('LISTAPRODUTO>>>>>>>', listaProduto);
        // listaProduto.forEach(mat => {
        //   console.log('LISTAPRODUTO>>00000>>>>>', mat);
        // });
        // VERIFICA SE AS ENTIDADES DO USUÁRIO LOGADO ESTÃO ENTRE AS ENTIDADES DO MANIFESTO
        // console.log('00.......................>>>', this.manifestoEdit.executora);
        // console.log('11.......................>>>', this.entidadeExecutora);
        var isEntidadeManifesto = false;
        if (this.entidades.length > 0) {
            console.log('22.......................>>>', this.entidades);
            this.entidades.forEach(function (entidade) {
                if (_this.manifestoEdit.geradorCnpj === entidade.cnpj) {
                    isEntidadeManifesto = true;
                }
                if (_this.manifestoEdit.transportadorCnpj === entidade.cnpj) {
                    isEntidadeManifesto = true;
                }
                if (_this.manifestoEdit.destinadorCnpj === entidade.cnpj) {
                    isEntidadeManifesto = true;
                }
                if (_this.manifestoEdit.executoraCnpj === entidade.cnpj) {
                    isEntidadeManifesto = true;
                }
            });
        }
        else {
            // console.log('33.......................>>>', this.variaveisGlobais.pessoaFisica);
            if (this.variaveisGlobais.pessoaFisica.cpf === this.manifestoEdit.geradorCpf) {
                isEntidadeManifesto = true;
            }
            if (this.variaveisGlobais.pessoaFisica.cpf === this.manifestoEdit.transportadorCpf) {
                isEntidadeManifesto = true;
            }
            if (this.variaveisGlobais.pessoaFisica.cpf === this.manifestoEdit.destinadorCpf) {
                isEntidadeManifesto = true;
            }
        }
        this.mensagemValidacao = '';
        this.validaManifesto(this.manifestoEdit);
        if (this.mensagemValidacao !== '') {
            this.spinnerService.ocultar();
            this.notificacoesService.notificarAviso(this.mensagemValidacao, null);
            return;
        }
        if (isEntidadeManifesto) {
            console.log('Salvar PostPut..........>>>>>>>', this.manifestoEdit);
            // Se novo
            if (this.manifestoEdit.id == null) {
                this.manifestoService.criarManifesto(this.manifestoEdit).subscribe(function (manifestoNovo) {
                    console.log('Salvar Post>>>>>>>', manifestoNovo);
                    _this.mostrarDialogEdit = false;
                    _this.notificacoesService.notificarSucesso('Manisfesto gerado com sucesso. O prazo para o encerramento do procedimento é de 7 dias úteis.', null);
                    manifestoNovo.isGerador = false;
                    manifestoNovo.isTransportador = false;
                    manifestoNovo.isDestinador = false;
                    _this.manifestoEdit.anulado = false;
                    // buscar manifestos
                    _this.pessoaFisica = _this.variaveisGlobais.pessoaFisica;
                    // this.entidades = this.variaveisGlobais.entidades;
                    _this.manifestoPesquisa = '';
                    _this.situacaoPesquisa = 'Abertos';
                    _this.obraPesquisa = '';
                    _this.produtoPesquisa = '';
                    _this.secretariaPesquisa = '';
                    _this.etapaPesquisa = '';
                    _this.nomeObraPesquisa = '';
                    _this.codLogradouroPesquisa = '';
                    _this.numLogradouroPesquisa = '';
                    _this.dataInicial = '01/01/1500';
                    _this.dataFinal = '01/01/1500';
                    _this.buscaManifestos();
                    _this.entidades.forEach(function (entidade) {
                        if (manifestoNovo.geradorCnpj === entidade.cnpj) {
                            manifestoNovo.isGerador = true;
                        }
                        if (manifestoNovo.transportadorCnpj === entidade.cnpj) {
                            manifestoNovo.isTransportador = true;
                        }
                        if (manifestoNovo.destinadorCnpj === entidade.cnpj) {
                            manifestoNovo.isDestinador = true;
                        }
                    });
                    _this.manifestos.push(manifestoNovo);
                    _this.manifestoEdit = manifestoNovo;
                    _this.geraPdfManifestoProduto(false, _this.manifestoEdit.id);
                    _this.spinnerService.ocultar();
                }, function (error) {
                    // console.log('11Salvar Post Erro>>>>>>>', error.error[0]);
                    // console.log('22Salvar Post Erro>>>>>>>', error);
                    // console.log('33Salvar Post Erro>>>>>>>', error.error);
                    ///Comentei esse salvar novamente ... 15/03/2023
                    // if (error.error[0] === 'message exception ConstraintViolationException') {
                    //   // console.log('000000Salvar Post Erro>>>>>>>', error);
                    //   this.salvar(modelo);
                    // } else {
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro('Erro ao adicionar manifesto.', error.error);
                    // }
                });
            }
            else { // Se editando
                this.manifestoService.editarManifesto(this.manifestoEdit).subscribe(function (manifestoEditado) {
                    // Remove o valor atual
                    _this.manifestos = _this.manifestos.filter(function (item) { return item.id !== _this.manifestoEdit.id; });
                    // Adiciona o novo
                    manifestoEditado.isGerador = false;
                    manifestoEditado.isTransportador = false;
                    manifestoEditado.isDestinador = false;
                    // buscar manifestos
                    _this.pessoaFisica = _this.variaveisGlobais.pessoaFisica;
                    // this.entidades = this.variaveisGlobais.entidades;
                    _this.manifestoPesquisa = '';
                    _this.situacaoPesquisa = 'Abertos';
                    _this.obraPesquisa = '';
                    _this.produtoPesquisa = '';
                    _this.secretariaPesquisa = '';
                    _this.etapaPesquisa = '';
                    _this.nomeObraPesquisa = '';
                    _this.codLogradouroPesquisa = '';
                    _this.numLogradouroPesquisa = '';
                    _this.dataInicial = '01/01/1500';
                    _this.dataFinal = '01/01/1500';
                    _this.buscaManifestos();
                    _this.entidades.forEach(function (entidade) {
                        if (manifestoEditado.geradorCnpj === entidade.cnpj) {
                            manifestoEditado.isGerador = true;
                        }
                        if (manifestoEditado.transportadorCnpj === entidade.cnpj) {
                            manifestoEditado.isTransportador = true;
                        }
                        if (manifestoEditado.destinadorCnpj === entidade.cnpj) {
                            manifestoEditado.isDestinador = true;
                        }
                    });
                    _this.manifestos.push(manifestoEditado);
                    _this.mostrarDialogEdit = false;
                    _this.notificacoesService.notificarSucesso('Manifesto atualizado com sucesso', null);
                    _this.manifestoEdit = manifestoEditado;
                    _this.geraPdfManifestoProduto(false, _this.manifestoEdit.id);
                    _this.spinnerService.ocultar();
                }, function (error) {
                    console.log(error);
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro(error.error, null);
                });
            }
        }
        else {
            console.log('Salvar PostPut>>>>----Entidade logada não está no manifesto--->>>', this.manifestoEdit);
            this.spinnerService.ocultar();
            this.notificacoesService.notificarErro('Alguma das suas Empresas deve fazer parte do MANIFESTO.', null);
        }
        this.isGeradorCNPJSemCadastro = false;
        this.justificarAnulacaoRecusa = false;
        this.isManifestoModelo = false;
    };
    ListaManifestosComponent.prototype.desfazManifestoModelo = function (manifesto) {
        var _this = this;
        this.manifestoService.desfazManifestoModelo(manifesto).subscribe(function (manifestoEditado) {
            _this.buscaManifestos();
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaManifestosComponent.prototype.validaManifesto = function (manifesto) {
        console.log('VALIDAMANIFESTO>>>>----..........>>>>>', manifesto);
        // console.log('VALIDAMANIFESTO>>>>----GERADOR..........');
        if (this.manifestoForm.get('tipoGerador').value === 'juridica') {
            // console.log('VALIDAMANIFESTO>>>>----CNPJ..........>>>>>', this.manifestoForm.get('tipoGerador').value);
            if (manifesto.geradorCnpj == null || manifesto.geradorCnpj === '') {
                this.mensagemValidacao = 'Por favor informar o CNPJ do Gerador.';
                return;
            }
            if (manifesto.geradorEmail == null || manifesto.geradorEmail === '') {
                this.mensagemValidacao = 'Por favor informar o EMAIL do Gerador';
                return;
            }
        }
        else {
            // console.log('VALIDAMANIFESTO>>>>----CPF..........>>>>>', this.manifestoForm.get('tipoGerador').value);
            if (manifesto.geradorCpf == null || manifesto.geradorCpf === '') {
                this.mensagemValidacao = 'Por favor informar o CPF do Gerador.';
                return;
            }
        }
        if (manifesto.geradorNome == null || manifesto.geradorNome === '') {
            this.mensagemValidacao = 'Por favor informar o RESPONSÁVEL do Gerador';
            return;
        }
        if (manifesto.geradorEndereco == null || manifesto.geradorEndereco === '') {
            this.mensagemValidacao = 'Por favor informar o ENDEREÇO do Gerador';
            return;
        }
        if (manifesto.geradorMunicipio == null || manifesto.geradorMunicipio === '') {
            this.mensagemValidacao = 'Por favor informar o MUNICÍPIO do Gerador';
            return;
        }
        if (manifesto.geradorFone === 'null' || manifesto.geradorFone === '') {
            this.mensagemValidacao = 'Por favor informar o FONE do Gerador';
            return;
        }
        // console.log('VALIDAMANIFESTO>>>>----TRANSPORTADOR..........');
        if (this.manifestoForm.get('tipoTransportador').value === 'juridica') {
            if (manifesto.transportadorCnpj == null || manifesto.transportadorCnpj === '') {
                this.mensagemValidacao = 'Por favor informar o CNPJ do Transportador';
                return;
            }
        }
        else {
            if (manifesto.transportadorCpf == null || manifesto.transportadorCpf === '') {
                this.mensagemValidacao = 'Por favor informar o CPF do Transportador';
                return;
            }
        }
        if (manifesto.transportadorNome == null || manifesto.transportadorNome === '') {
            this.mensagemValidacao = 'Por favor informar o RESPONSÁVEL do Transportador';
            return;
        }
        if (manifesto.transportadorEndereco == null || manifesto.transportadorEndereco === '') {
            this.mensagemValidacao = 'Por favor informar o ENDEREÇO do Transportador';
            return;
        }
        if (manifesto.transportadorMunicipio == null || manifesto.transportadorMunicipio === '') {
            this.mensagemValidacao = 'Por favor informar o MUNICÍPIO do Transportador';
            return;
        }
        if (manifesto.transportadorEmail == null || manifesto.transportadorEmail === '') {
            this.mensagemValidacao = 'Por favor informar o EMAIL do Transportador';
            return;
        }
        if (manifesto.transportadorFone == null || manifesto.transportadorFone === '') {
            this.mensagemValidacao = 'Por favor informar o FONE do Transportador';
            return;
        }
        if (manifesto.transportadorPlaca == null || manifesto.transportadorPlaca === '') {
            this.mensagemValidacao = 'Por favor informar a PLACA do Transportador';
            return;
        }
        console.log('VALIDAMANIFESTO>>>>----DESTINADOR..........');
        if (this.manifestoForm.get('tipoDestinador').value === 'juridica') {
            if (manifesto.destinadorCnpj == null || manifesto.destinadorCnpj === '') {
                this.mensagemValidacao = 'Por favor informar o CNPJ do Destino Final';
                return;
            }
        }
        else {
            if (manifesto.destinadorCpf == null || manifesto.destinadorCpf === '') {
                this.mensagemValidacao = 'Por favor informar o CPF do Destino Final';
                return;
            }
        }
        if (manifesto.destinadorNome == null || manifesto.destinadorNome === '') {
            this.mensagemValidacao = 'Por favor informar o RESPONSÁVEL do Destino Final';
            return;
        }
        if (manifesto.destinadorEndereco == null || manifesto.destinadorEndereco === '') {
            this.mensagemValidacao = 'Por favor informar o ENDEREÇO do Destino Final';
            return;
        }
        if (manifesto.destinadorMunicipio == null || manifesto.destinadorMunicipio === '') {
            this.mensagemValidacao = 'Por favor informar o MUNICÍPIO do Destino Final';
            return;
        }
        if (manifesto.destinadorCnpj !== '92.963.560/0001-60') {
            if (manifesto.destinadorEmail == null || manifesto.destinadorEmail === '') {
                this.mensagemValidacao = 'Por favor informar o EMAIL do Destino Final';
                return;
            }
            if (manifesto.destinadorFone == null || manifesto.destinadorFone === '') {
                this.mensagemValidacao = 'Por favor informar o FONE do Destino Final';
                return;
            }
        }
        if (manifesto.destinadorLicenca == null || manifesto.destinadorLicenca === '') {
            this.mensagemValidacao = 'Por favor informar o LICENÇA do Destino Final';
            return;
        }
        if (this.manifestoForm.get('tipoDestinador').value === 'fisica') {
            if (manifesto.destinadorOrgaoEmissor == null || manifesto.destinadorOrgaoEmissor === '') {
                this.mensagemValidacao = 'Por favor informar o ÓRGÃO EMISSOR DA LICENÇA do Destino Final';
                return;
            }
            if (manifesto.destinadorValidade == null) {
                this.mensagemValidacao = 'Por favor informar a VALIDADE DA LICENÇA do Destino Final';
                return;
            }
            else {
                var dataHoje = new Date();
                console.log('0--------------------->>>>', dataHoje);
                console.log('1--------------------->>>>', manifesto.destinadorValidade);
                if (manifesto.destinadorValidade < dataHoje) {
                    this.mensagemValidacao = 'Por favor informar uma VALIDADE DA LICENÇA do Destino Final maior que a data de hoje';
                    return;
                }
            }
        }
        if (manifesto.obra == null || manifesto.obra === '') {
            this.mensagemValidacao = 'Por favor informar a OBRA dos Resíduos do Manifesto.';
            return;
        }
        if (manifesto.etapa == null || manifesto.etapa === '') {
            this.mensagemValidacao = 'Por favor informar a ETAPA dos Resíduos do Manifesto.';
            return;
        }
        if (manifesto.produto == null || manifesto.produto === '') {
            this.mensagemValidacao = 'Por favor informar o PRODUTO dos Resíduos do Manifesto.';
            return;
        }
        if (manifesto.quantidade == null || manifesto.quantidade === '') {
            this.mensagemValidacao = 'Por favor informar a QUANTIDADE dos Resíduos do Manifesto.';
            return;
        }
        if (manifesto.geradorCnpj !== '92.963.560/0001-60') {
            if (manifesto.endereco == null) {
                this.mensagemValidacao = 'Por favor informar o ENDEREÇO/NÚMERO dos Resíduos do Manifesto.';
                return;
            }
            else {
                if (manifesto.endereco.nomeLogradouro == null || manifesto.endereco.nomeLogradouro === '') {
                    this.mensagemValidacao = 'Por favor informar o ENDEREÇO/NÚMERO dos Resíduos do Manifesto.';
                    return;
                }
                if (manifesto.endereco.numero == null || manifesto.endereco.numero === '') {
                    this.mensagemValidacao = 'Por favor informar o ENDEREÇO/NÚMERO dos Resíduos do Manifesto.';
                    return;
                }
            }
        }
        else {
            if (manifesto.nomeobra == null || manifesto.nomeobra === '') {
                if (manifesto.endereco == null) {
                    this.mensagemValidacao = 'Por favor informar o ENDEREÇO/NÚMERO ou NOME DA OBRA dos Resíduos do Manifesto.';
                    return;
                }
                else {
                    if (manifesto.endereco.nomeLogradouro == null || manifesto.endereco.nomeLogradouro === '') {
                        this.mensagemValidacao = 'Por favor informar o ENDEREÇO/NÚMERO ou NOME DA OBRA dos Resíduos do Manifesto.';
                        return;
                    }
                    if (manifesto.endereco.numero == null || manifesto.endereco.numero === '') {
                        this.mensagemValidacao = 'Por favor informar o ENDEREÇO/NÚMERO ou NOME DA OBRA dos Resíduos do Manifesto.';
                        return;
                    }
                }
            }
        }
    };
    ListaManifestosComponent.prototype.salvarDestinador = function (idManifesto) {
        var _this = this;
        this.justificarAnulacaoRecusa = false;
        console.log('salvarDestinador >>>>>>>', this.manifestos);
        var manifesto = this.manifestos.find(function (manifesto) {
            return manifesto.id === idManifesto;
        });
        console.log('Editar mostrarDialogParaEditar11>>>>>>>', manifesto);
        this.manifestoEdit = __assign({}, manifesto);
        this.spinnerService.exibir();
        console.log('Manifestos getProdutosManifesto 2680**************>>>>>');
        // buscar produtos e classificações do manifesto
        this.manifestoService.getProdutosManifesto(idManifesto).subscribe(function (manifesto) {
            _this.manifestoEdit.produto = manifesto.produto;
            _this.manifestoEdit.destinadorDataRecebimento = new Date();
            _this.manifestoService.editarManifesto(_this.manifestoEdit).subscribe(function (manifestoEditado) {
                // Remove o valor atual
                _this.manifestos = _this.manifestos.filter(function (item) { return item.id !== _this.manifestoEdit.id; });
                // Adiciona o novo
                _this.manifestos.push(manifestoEditado);
                _this.mostrarDialogEdit = false;
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarSucesso('Manifesto atualizado com sucesso', null);
                _this.manifestoEdit = manifestoEditado;
                _this.manifestoEdit.produto = manifesto.produto;
                console.log('Editar salvarDestinador>>>>>>>', manifestoEditado);
                console.log('Editar salvarDestinador>>............>>>>>', _this.manifestoEdit);
                _this.geraPdfManifesto(false);
            }, function (error) {
                console.log(error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaManifestosComponent.prototype.mostrarDialogEditParaAnulacaoRecusa = function (idManifesto, tipo) {
        var manifestoAnularRecusar = this.manifestos.find(function (manifesto) {
            return manifesto.id === idManifesto;
        });
        console.log('Editar mostrarDialogParaEditar11>>>>>>>', manifestoAnularRecusar);
        this.manifestoEdit = __assign({}, manifestoAnularRecusar);
        this.justificarAnulacaoRecusa = true;
        this.mostrarDialogEdit = false;
        this.manifestoEdit.anulado = false;
        this.manifestoEdit.recusado = false;
        if (tipo === 'anular') {
            this.manifestoEdit.anulado = true;
        }
        if (tipo === 'recusar') {
            this.manifestoEdit.recusado = true;
        }
        //    this.manifestoAnulado = true;
        // this.excluir();
    };
    ListaManifestosComponent.prototype.salvarJustificativa = function (manifesto) {
        var _this = this;
        console.log('salvarJustificativa............>>>>>>>', manifesto);
        console.log('salvarJustificativa....++++........>>>>>>>', this.manifestoEdit);
        this.manifestoEdit.dataAnulacaoRecusa = new Date();
        this.manifestoEdit.transportadorDataTransporte = new Date();
        this.manifestoEdit.destinadorDataRecebimento = null;
        if (this.manifestoEdit.justificativaAnulacaoRecusa !== null && this.manifestoEdit.justificativaAnulacaoRecusa !== '') {
            this.spinnerService.exibir();
            this.manifestoService.anularManifesto(this.manifestoEdit).subscribe(function (manifestoAnuladoRecusado) {
                _this.justificarAnulacaoRecusa = false;
                _this.notificacoesService.notificarSucesso('Manifesto anulado/recusado com sucesso!', null);
                // this.mostrarDialogParaEditar(manifestoAnuladoRecusado.id);
                _this.mostrarDialogEdit = false;
                // Adiciona o novo
                manifestoAnuladoRecusado.isGerador = false;
                manifestoAnuladoRecusado.isTransportador = false;
                manifestoAnuladoRecusado.isDestinador = false;
                _this.entidades.forEach(function (entidade) {
                    if (manifestoAnuladoRecusado.geradorCnpj === entidade.cnpj) {
                        manifestoAnuladoRecusado.isGerador = true;
                    }
                    if (manifestoAnuladoRecusado.transportadorCnpj === entidade.cnpj) {
                        manifestoAnuladoRecusado.isTransportador = true;
                    }
                    if (manifestoAnuladoRecusado.destinadorCnpj === entidade.cnpj) {
                        manifestoAnuladoRecusado.isDestinador = true;
                    }
                });
                _this.manifestos = _this.manifestos.filter(function (item) { return item.id !== manifestoAnuladoRecusado.id; });
                if (_this.situacaoPesquisa === 'AnuladosRecusados') {
                    // Adiciona o novo
                    _this.manifestos.push(manifestoAnuladoRecusado);
                }
                _this.geraPdfManifestoProduto(false, _this.manifestoEdit.id);
                _this.spinnerService.ocultar();
            }, function (error) {
                console.log('Salvar Post Erro>>>>>>>', error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro('Erro ao anular manifesto.', error.error);
            });
            // this.onSubmit();
        }
        else {
            this.notificacoesService.notificarAviso('Por favor, informar a justificativa', null);
        }
    };
    ListaManifestosComponent.prototype.isSMAMS = function () {
        var ret = this.keycloakService.hasRole('SMAMS');
        console.log('isSMAMS>>>>>>>', ret);
        return ret;
    };
    ListaManifestosComponent.prototype.totalizaEntidade = function (idEntidade) {
        var _this = this;
        console.log('====================>', idEntidade);
        this.manifestoService.totalEntidadePeriodo(idEntidade).subscribe(function (totalizadorEntidade) {
        }, function (error) {
            console.log('Salvar Post Erro>>>>>>>', error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro('Erro ao anular manifesto.', error.error);
        });
    };
    ListaManifestosComponent.prototype.selecionaCDL = function (endereco) {
        this.localizacao = { latitude: null, longitude: null };
        if (endereco.latitude !== undefined && endereco.latitude !== null) {
            this.localizacao = {
                latitude: endereco.latitude,
                longitude: endereco.longitude
            };
            delete endereco['latitude'];
            delete endereco['longitude'];
        }
        this.manifestoEdit.endereco = endereco;
        this.manifestoEdit.endereco.localizacao = this.localizacao;
        if (this.endereco.codigoLogradouro !== undefined && this.endereco.codigoLogradouro !== null) {
            this.endereco.municipio = 'Porto Alegre';
        }
        else {
            this.endereco.enderecoFormatado =
                this.endereco.nomeLogradouro + ', ' + this.endereco.numero;
        }
    };
    ListaManifestosComponent.prototype.selecionaCDLPesquisa = function (endereco) {
        console.log('ENDEREÇO selecionaCDLPesquisa>>>>>>>', endereco);
        if (endereco === undefined) {
            this.codLogradouroPesquisa = '';
            this.numLogradouroPesquisa = '';
        }
        else {
            this.codLogradouroPesquisa = endereco.codigoLogradouro;
            this.numLogradouroPesquisa = endereco.numero;
        }
    };
    ListaManifestosComponent.prototype.alternarEndereco = function (alternaEndereco) {
        if (alternaEndereco === 'poa') {
            this.isPoa = true;
        }
        else {
            this.isPoa = false;
        }
    };
    ListaManifestosComponent.prototype.responsavelSelecionado = function (pessoaFisica, tipoEntidade) {
        console.log('Responsável  respSelecionado>>>>>>>', pessoaFisica);
        if (tipoEntidade === 'gerador') {
            this.manifestoEdit.geradorEmail = pessoaFisica.email;
            this.manifestoEdit.geradorFone = pessoaFisica.telefone;
        }
    };
    ListaManifestosComponent.prototype.enderecoSelecionado = function (endereco, tipoEntidade, origem) {
        var _this = this;
        // console.log('ENDEREÇO enderecoSelecionado>>>>>>>', endereco);
        // console.log('ENTIDADE >>>>>>>', this.entidadeManifesto);
        //console.log('MANIFESTO >>>>>>>', this.manifestoEdit);
        // console.log('Secretarias >>>>>>>', this.secsEntidadeGerador);
        // console.log('TIPOENTIDADEs >>>>>>>', tipoEntidade);
        this.siglaSecretaria = '';
        if (tipoEntidade === 'gerador') {
            if (endereco.identificacao !== null) {
                this.siglaSecretaria = endereco.identificacao;
                this.manifestoEdit.geradorNome = this.entidadeManifesto.razaoSocial + ' - ' + endereco.identificacao;
                this.manifestoForm.get('geradorNome').setValue(this.manifestoEdit.geradorNome);
            }
            if (this.entidadeManifesto.cnpj === '92.963.560/0001-60') {
                this.secsEntidadeGerador.forEach(function (secr) {
                    if (secr.id === endereco.id) {
                        _this.manifestoEdit.geradorSecretaria = secr;
                        //              this.buscaPessoaFisica(this.entidadeManifesto, 'gerador');
                    }
                });
                if (origem === 'tela') {
                    var idSecretaria_1 = this.manifestoEdit.geradorSecretaria.id;
                    this.respsEntidadeGerador = [];
                    this.pfSecrsEntidadeGerador.forEach(function (pf) {
                        // se for PMPA seleciona o responsável legal da Secretaria selecionada
                        if (pf.idSecretaria === idSecretaria_1) {
                            _this.respsEntidadeGerador.push(pf);
                        }
                    });
                    if (this.respsEntidadeGerador.length > 0) {
                        this.manifestoForm.get('geradorRespSel').setValue(this.respsEntidadeGerador[0].id);
                        this.responsavelSelecionado(this.respsEntidadeGerador[0], tipoEntidade);
                    }
                    else {
                        this.manifestoEdit.geradorEmail = null;
                        this.manifestoEdit.geradorFone = null;
                        this.notificacoesService.notificarAviso('Favor solicitar à Secretaria selecionada no ' +
                            'Endereço para que a mesma inclua o Responsável Legal!', null);
                        return;
                    }
                }
            }
            this.manifestoEdit.geradorEndereco = endereco.enderecoFormatado;
            this.manifestoEdit.geradorMunicipio = endereco.municipio;
        }
        if (tipoEntidade === 'transportador') {
            this.manifestoEdit.transportadorEndereco = endereco.enderecoFormatado;
            this.manifestoEdit.transportadorMunicipio = endereco.municipio;
        }
        if (tipoEntidade === 'destinador') {
            if (this.manifestoEdit.destinadorCnpj === '92.963.560/0001-60') {
                // console.log('ENDEREÇO enderecoSelecionado>>>>>>>', endereco);
                this.manifestoEdit.destinadorObservacao = endereco.observacao;
                this.manifestoForm.get('destinadorObservacao').setValue(this.manifestoEdit.destinadorObservacao);
                if (endereco.identificacao !== null) {
                    if (endereco.enderecoFormatadoCurto === null) {
                        this.manifestoEdit.destinadorEndereco = endereco.identificacao;
                        this.manifestoEdit.destinadorMunicipio = endereco.municipio;
                    }
                    else {
                        this.manifestoEdit.destinadorEndereco = endereco.identificacao + ' - ' + endereco.enderecoFormatadoCurto;
                        this.manifestoEdit.destinadorMunicipio = endereco.municipio;
                    }
                }
            }
            else {
                this.manifestoEdit.destinadorEndereco = endereco.enderecoFormatado;
                this.manifestoEdit.destinadorMunicipio = endereco.municipio;
            }
            this.manifestoEdit.destinadorLicenca = null;
            this.manifestoEdit.destinadorValidade = null;
            this.manifestoEdit.destinadorOrgaoEmissor = null;
            if (endereco.licencaAmbientalDO !== null) {
                this.manifestoEdit.destinadorLicenca = endereco.licencaAmbientalDO.licenca;
                this.manifestoEdit.destinadorValidade = endereco.licencaAmbientalDO.dataValidade;
                this.manifestoEdit.destinadorOrgaoEmissor = endereco.licencaAmbientalDO.orgaoEmissor;
            }
            if (endereco.isentoLicenca) {
                this.manifestoEdit.destinadorLicenca = 'Isento';
            }
        }
    };
    ListaManifestosComponent.prototype.veiculoSelecionado = function (veiculo, tipoEntidade) {
        if (tipoEntidade === 'transportador') {
            this.manifestoEdit.transportadorPlaca = veiculo.placa;
        }
    };
    // limpaSelecao(evento) {
    //   console.log('.......===========limpaSelecao=========>', evento);
    // }
    // filtrarEmpresa(tabelaManifestos) {
    //   console.log('.......===========filtroEmpresa=========>', tabelaManifestos);
    //   let gerador = '';
    //   let transportador = '';
    //   let destinador = '';
    //   this.filtroEmpresa.forEach(opcao => {
    //     if (opcao === 'gerador') {
    //       gerador = 'gerador';
    //     }
    //     if (opcao === 'transportador') {
    //       gerador = 'transportador';
    //     }
    //     if (opcao === 'destinador') {
    //       gerador = 'destinador';
    //     }
    //   });
    //   this.manifestosAux = [];
    //   if (tabelaManifestos.filteredValue === undefined) {
    //     tabelaManifestos._value.forEach(manif => {
    //       this.manifestosAux.push(manif);
    //     });
    //   } else {
    //     tabelaManifestos.filteredValue.forEach(manif => {
    //       this.manifestosAux.push(manif);
    //     });
    //   }
    //   this.manifestosFiltros = [];
    //   this.manifestosAux.forEach(manifesto => {
    //     this.filtroEmpresa.forEach(opcao => {
    //       if (opcao === 'gerador') {
    //         this.manifestosFiltros.push(manifesto);
    //       } else if (opcao === 'transportador') {
    //         this.manifestosFiltros.push(manifesto);
    //       } else if (opcao === 'destinador') {
    //         this.manifestosFiltros.push(manifesto);
    //       }
    //     });
    //   });
    //   // if (tabelaManifestos.filteredValue === undefined) {
    //   //   tabelaManifestos === this.manifestosFiltros;
    //   // } else {
    //   //   tabelaManifestos.filteredValue === this.manifestosFiltros.values();
    //   // }
    //   console.log('.......===========filtroEmpresa===111======>', this.manifestos);
    //   console.log('.......===========filtroEmpresa===222======>', this.manifestosFiltros);
    // }
    // licencaSelecionada(licenca, tipoEntidade) {
    //   console.log('.......===========licencaSelecionada=========>', licenca);
    //   if (tipoEntidade === 'destinador') {
    //     this.manifestoEdit.destinadorLicenca = licenca.licenca;
    //     this.manifestoEdit.destinadorValidade = licenca.dataValidade;
    //     this.manifestoEdit.destinadorOrgaoEmissor = licenca.secretariaPmpaDO.sigla;
    //     this.manifestoForm.get('destinadorLicenca').setValue(licenca.licenca);
    //     this.manifestoForm.get('destinadorLicSel').setValue(licenca.id);
    //   }
    // }
    // licencaIsencao(destinadorLicencaIsento, tipoEntidade) {
    //   console.log('===========licencaIsencao=========>', destinadorLicencaIsento.checked);
    //   this.manifestoEdit.destinadorLicenca = null;
    //   this.manifestoEdit.destinadorValidade = null;
    //   this.manifestoEdit.destinadorOrgaoEmissor = null;
    //   if (tipoEntidade === 'destinador') {
    //     if (destinadorLicencaIsento.checked) {
    //        console.log('1===========licencaIsencao=========>');
    //       this.manifestoEdit.destinadorLicenca = 'DISPENSA';
    //       this.manifestoForm.get('destinadorLicenca').setValue('');
    //       this.manifestoForm.get('destinadorLicSel').setValue('');
    //       // this.manifestoForm.get('destinadorValidade').setValue('');
    //       // this.manifestoForm.get('destinadorOrgaoEmissor').setValue('');
    //     } else {
    //       console.log('2===========licencaIsencao=========>');
    //       if (this.licsEntidadeDestinador.length > 0) {
    //         this.licencaSelecionada(this.licsEntidadeDestinador[0], 'destinador');
    //       }
    //     }
    //   }
    // }
    // excluir() {
    //   this.spinnerService.exibir();
    //   this.manifestoService.excluirManifesto(this.manifestoExcluir).subscribe(() => {
    //     // Remove o valor atual
    //     this.manifestos = this.manifestos.filter(item => item.id !== this.manifestoExcluir.id);
    //     this.mostrarDialogExcluir = false;
    //     this.spinnerService.ocultar();
    //     this.notificacoesService.notificarSucesso('Manifesto excluído com sucesso', null);
    //   },
    //   error => {
    //     console.log(error);
    //     this.spinnerService.ocultar();
    //     this.notificacoesService.notificarErro('Erro ao exluir manifesto', error.error);
    //   });
    // }
    ListaManifestosComponent.prototype.buscaGerador = function (documentoGerador) {
        console.log('====================>', documentoGerador);
    };
    ListaManifestosComponent.prototype.mudaDataTransportador = function (dataGerador) {
        // console.log('0====================>', dataGerador);
        this.minimumDateTransportador = new Date(dataGerador);
        this.manifestoForm.get('transportadorDataTransporte').setValue(new Date(dataGerador));
    };
    // controlaMascara(quantidade) {
    //   // console.log('controlaMascara..............................>>>>>', quantidade);
    //   let valor = quantidade.target.value;
    //   // console.log('controlaMascara..............................>>>>>', valor.length);
    //   if (valor.length === 1) {
    //     valor = '000,0' + valor;
    //     this.manifestoForm.get('quantidade').setValue(valor);
    //   } else if (valor.length === 2) {
    //     valor = '000,' + valor;
    //     this.manifestoForm.get('quantidade').setValue(valor);
    //   } else if (valor.length === 3) {
    //     valor = '00' + valor.slice(0, 1) + ',' + valor.slice(1, 3);
    //     this.manifestoForm.get('quantidade').setValue(valor);
    //   } else if (valor.length === 4) {
    //     valor = '0' + valor.slice(0, 2) + ',' + valor.slice(2, 4);
    //     this.manifestoForm.get('quantidade').setValue(valor);
    //   } else if (valor.length === 5) {
    //     valor = valor.slice(0, 3) + ',' + valor.slice(3, 5);
    //     this.manifestoForm.get('quantidade').setValue(valor);
    //   }
    // }
    ListaManifestosComponent.prototype.numberOnly = function (event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        var valor = event.target.value;
        var situacao = true;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            situacao = false;
        }
        else if (valor.length > 4) {
            situacao = false;
        }
        return situacao;
    };
    ListaManifestosComponent.prototype.checkActive = function (route) {
        return route === this.activeRoute;
    };
    ListaManifestosComponent.prototype.changeTable = function (e) {
        this.index = e.index;
    };
    ListaManifestosComponent.prototype.alternarAbas = function (ind) {
        this.index = ind;
    };
    ListaManifestosComponent.prototype.resultadosManifestos = function () {
        this.router.navigate(['/mtrcc/resultados'], { relativeTo: this.route });
    };
    ListaManifestosComponent.prototype.geraPdfListaManifesto = function (tabelaManifestos, tipoRelatorio) {
        var _this = this;
        // console.log('00....tabela de manifestos selecioada >>>>', tabelaManifestos);
        this.mtrccExcel = new MtrccExcel01Class();
        this.mtrccExcelList = [];
        this.manifestosAux = [];
        if (tabelaManifestos.filteredValue === undefined) {
            tabelaManifestos._value.forEach(function (manif) {
                // console.log('11....>>>>', manif);
                _this.manifestosAux.push(manif);
            });
        }
        else {
            tabelaManifestos.filteredValue.forEach(function (manif) {
                // console.log('11....>>>>', manif);
                _this.manifestosAux.push(manif);
            });
        }
        var idManifestosTotal = [];
        var mId = [];
        // this.manifestos.forEach(manif => {
        this.manifestosAux.forEach(function (manif) {
            mId.push(manif.id.toString());
            if (mId.length > 499) {
                idManifestosTotal.push(mId);
                mId = [];
            }
        });
        if (mId.length > 0) {
            idManifestosTotal.push(mId);
            mId = [];
        }
        console.log('00....>>>>', idManifestosTotal.length);
        var tamListaFinal = idManifestosTotal.length;
        var tamLista = 0;
        this.spinnerService.exibir();
        for (var index = 0; index < idManifestosTotal.length + 10;) {
            if (idManifestosTotal.length > index) {
                idManifestosTotal.forEach(function (idm) {
                    console.log('11....>>>>', idm);
                    _this.manifestoService.getProdutosManifestos(idm).subscribe(function (manifestosProdutos) {
                        tamLista = tamLista + 1;
                        manifestosProdutos.forEach(function (manPr) {
                            _this.manifestosAux.forEach(function (manif) {
                                if (manif.id === manPr.id) {
                                    manif.produto = manPr.produto;
                                }
                            });
                        });
                        if (tamLista === tamListaFinal) {
                            console.log('11....>>>>', _this.manifestosAux);
                            _this.pdfListaManifesto(tipoRelatorio);
                            _this.spinnerService.ocultar();
                        }
                    }, function (error) {
                        console.log(error);
                        console.log(error.error);
                        _this.spinnerService.ocultar();
                        _this.notificacoesService.notificarErro(error.error, null);
                    });
                });
                index = idManifestosTotal.length + 5;
            }
            index = idManifestosTotal.length + 20;
        }
    };
    ListaManifestosComponent.prototype.pdfListaManifesto = function (tipoRelatorio) {
        var _this = this;
        console.log('00....pdfListaManifesto>>>>', this.manifestos.length);
        var doc = new jspdf('landscape', 'mm', 'a4'); // A4 size page of PDF
        this.pdfInicial(doc);
        var img = new Image();
        var linhas = 0;
        var paginas = 1;
        var numLinha = 88;
        var situacao = '';
        var dataManifesto = null;
        doc.setFontSize(10);
        doc.setFontType('normal');
        this.manifestosAux.sort(function (a, b) {
            return new Date(a.geradorDataExpedicao).getTime() - new Date(b.geradorDataExpedicao).getTime();
        });
        this.manifestosAux.forEach(function (manif) {
            // console.log('Lista classificada ....>', new Date(manif.geradorDataExpedicao).toLocaleDateString());
            if (manif.anulado) {
                situacao = 'Anulado';
                dataManifesto = manif.dataAnulacaoRecusa;
            }
            else if (manif.recusado) {
                situacao = 'Recusado';
                dataManifesto = manif.dataAnulacaoRecusa;
            }
            else if (manif.destinadorDataRecebimento == null) {
                situacao = 'Aberto';
                dataManifesto = manif.geradorDataExpedicao;
            }
            else {
                situacao = 'Recebido';
                dataManifesto = manif.destinadorDataRecebimento;
            }
            if (linhas === 3) {
                img = new Image();
                img.src = 'assets/img/logoProcempa.png';
                doc.addImage(img, 'png', 185, 195, 40, 5);
                doc.text(35, 200, paginas.toString());
                doc.text(50, 200, 'Secretaria Municipal de Meio Ambiente, Urbanismo e Sustentabilidade – SMAMUS');
                doc.addPage();
                _this.pdfInicial(doc);
                // buscar produtos do manifesto
                numLinha = 88;
                linhas = 0;
                paginas = paginas + 1;
                doc.setFontSize(10);
                doc.setFontType('normal');
            }
            linhas = linhas + 1;
            doc.text(35, numLinha, manif.numeroManifesto.toString());
            _this.mtrccExcel.numero = manif.numeroManifesto;
            // doc.text(35, numLinha, linhas.toString());
            if (manif.geradorCnpj != null) {
                doc.text(55, numLinha, manif.geradorCnpj);
                _this.mtrccExcel.idGerador = manif.geradorCnpj;
            }
            else {
                doc.text(55, numLinha, manif.geradorCpf);
                _this.mtrccExcel.idGerador = manif.geradorCpf;
            }
            doc.text(95, numLinha, manif.geradorNome);
            _this.mtrccExcel.nomeGerador = manif.geradorNome;
            numLinha = numLinha + 4;
            doc.text(95, numLinha, manif.geradorEndereco);
            _this.mtrccExcel.enderecoGerador = manif.geradorEndereco;
            numLinha = numLinha + 4;
            if (manif.transportadorCnpj != null) {
                doc.text(55, numLinha, manif.transportadorCnpj);
                _this.mtrccExcel.idTransportador = manif.transportadorCnpj;
            }
            else {
                doc.text(55, numLinha, manif.transportadorCpf);
                _this.mtrccExcel.idTransportador = manif.geradorCpf;
            }
            doc.text(95, numLinha, manif.transportadorNome);
            _this.mtrccExcel.nomeTransportador = manif.transportadorNome;
            numLinha = numLinha + 4;
            doc.text(95, numLinha, manif.transportadorEndereco);
            _this.mtrccExcel.enderecoTransportador = manif.transportadorEndereco;
            numLinha = numLinha + 4;
            if (manif.destinadorCnpj != null) {
                doc.text(55, numLinha, manif.destinadorCnpj);
                _this.mtrccExcel.idDestinador = manif.destinadorCnpj;
            }
            else {
                doc.text(55, numLinha, manif.destinadorCpf);
                _this.mtrccExcel.idDestinador = manif.destinadorCpf;
            }
            doc.text(95, numLinha, manif.destinadorNome);
            _this.mtrccExcel.nomeDestinador = manif.destinadorNome;
            numLinha = numLinha + 4;
            doc.text(95, numLinha, manif.destinadorEndereco);
            _this.mtrccExcel.enderecoDestinador = manif.destinadorEndereco;
            numLinha = numLinha + 4;
            if (manif.geradorDataExpedicao !== null) {
                doc.text(35, numLinha, new Date(dataManifesto).toLocaleDateString());
                _this.mtrccExcel.data = new Date(dataManifesto).toLocaleDateString();
            }
            doc.text(55, numLinha, situacao);
            _this.mtrccExcel.situacao = situacao;
            if (manif.transportadorPlaca !== null) {
                doc.text(95, numLinha, manif.transportadorPlaca);
                _this.mtrccExcel.veiculo = manif.transportadorPlaca;
            }
            var unidade = manif.unidadeQuantidade;
            if (manif.unidadeQuantidade === 'toneladas') {
                unidade = 't';
            }
            doc.text(117, numLinha, manif.quantidade + ' ' + unidade);
            _this.mtrccExcel.volume = manif.quantidade + unidade;
            if (manif.endereco !== null) {
                doc.text(133, numLinha, manif.endereco.nomeLogradouro + ', ' + manif.endereco.numero);
                _this.mtrccExcel.enderecoResiduo = manif.endereco.nomeLogradouro + ', ' + manif.endereco.numero;
            }
            numLinha = numLinha + 4;
            if (manif.produto !== null) {
                var produtoFormatado_1 = '';
                manif.produto.forEach(function (prd) {
                    if (produtoFormatado_1 === '') {
                        produtoFormatado_1 = prd;
                    }
                    else {
                        produtoFormatado_1 = produtoFormatado_1 + ', ' + prd;
                    }
                });
                doc.text(35, numLinha, produtoFormatado_1);
                _this.mtrccExcel.material = produtoFormatado_1;
            }
            numLinha = numLinha + 1;
            doc.rect(40, numLinha, 180, 0.1, 'F');
            numLinha = numLinha + 3;
            _this.mtrccExcelList.push(_this.mtrccExcel);
            _this.mtrccExcel = new MtrccExcel01Class();
        });
        img = new Image();
        img.src = 'assets/img/logoProcempa.png';
        doc.addImage(img, 'png', 185, 195, 40, 5);
        doc.text(35, 200, paginas.toString());
        doc.text(50, 200, 'Secretaria Municipal de Meio Ambiente, Urbanismo e Sustentabilidade – SMAMUS');
        if (tipoRelatorio === 'pdf') {
            doc.save('MTRCC_Lista_' + new Date().toLocaleDateString() + '_'
                + new Date().getHours() + '_' + new Date().getMinutes() + '.pdf');
        }
        if (tipoRelatorio === 'excel') {
            this.excelService.exportAsExcelFile(this.mtrccExcelList, 'MTRCC_Lista');
        }
    };
    ListaManifestosComponent.prototype.pdfInicial = function (doc) {
        doc.setFontSize(14);
        var img = new Image();
        img.src = 'assets/img/logo_pmpa.png';
        doc.addImage(img, 'png', 40, 15, 17, 15);
        doc.text(78, 20, 'PREFEITURA MUNICIPAL DE PORTO ALEGRE');
        doc.setFontSize(9);
        doc.text(73, 30, 'SECRETARIA MUNICIPAL DO MEIO AMBIENTE, URBANISMO E SUSTENTABILIDADE');
        doc.setFontSize(10);
        // img = new Image();
        // img.src = 'assets/img/smam.jpg';
        // doc.addImage(img, 'jpg', 210, 15, 17, 13);
        img = new Image();
        img.src = 'assets/img/mtrcconline.png';
        doc.addImage(img, 'png', 163, 32, 40, 12);
        doc.text(70, 40, 'SGR - Sistema de Gestão de Resíduos');
        // COLUNAS LISTA MANIFESTO
        doc.setFontSize(10);
        doc.setFontType('bolditalic');
        doc.text(35, 55, 'Nº MTRCC');
        doc.text(55, 55, 'CNPJ Gerador');
        doc.text(95, 55, 'Nome Gerador');
        doc.text(55, 59, 'Endereço Gerador');
        doc.text(55, 63, 'CNPJ Transportador');
        doc.text(95, 63, 'Nome Transportador');
        doc.text(55, 67, 'Endereço Transportador');
        doc.text(55, 71, 'CNPJ Destino Final');
        doc.text(95, 71, 'Nome Destino Final');
        doc.text(55, 75, 'Endereço Destino Final');
        doc.text(35, 79, 'Data');
        doc.text(55, 79, 'Situação');
        doc.text(95, 79, 'Veículo');
        doc.text(117, 79, 'Volume');
        doc.text(133, 79, 'Endereço Resíduo');
        doc.text(35, 83, 'Material');
        doc.rect(35, 84, 200, 0.5, 'F');
    };
    ListaManifestosComponent.prototype.geraPdfManifestoProduto = function (opcaoDownloadPdf, idManifesto) {
        var _this = this;
        console.log('Manifestos getProdutosManifesto 3444**************>>>>>');
        // buscar produtos e classificações do manifesto
        this.manifestoService.getProdutosManifesto(idManifesto).subscribe(function (manifesto) {
            _this.manifestoEdit.produto = manifesto.produto;
            _this.geraPdfManifesto(opcaoDownloadPdf);
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaManifestosComponent.prototype.geraPdfManifesto = function (opcaoDownloadPdf) {
        var _this = this;
        var doc = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
        console.log('geraPdfManifesto...........>>>>', this.manifestoEdit);
        doc.setFontSize(14);
        var img = new Image();
        img.src = 'assets/img/logo_pmpa.png';
        doc.addImage(img, 'png', 10, 15, 17, 15);
        doc.text(48, 20, 'PREFEITURA MUNICIPAL DE PORTO ALEGRE');
        doc.setFontSize(9);
        doc.text(43, 30, 'SECRETARIA MUNICIPAL DO MEIO AMBIENTE, URBANISMO E SUSTENTABILIDADE');
        doc.setFontSize(10);
        // img = new Image();
        // img.src = 'assets/img/smam.jpg';
        // doc.addImage(img, 'jpg', 180, 15, 17, 13);
        img = new Image();
        img.src = 'assets/img/mtrcconline.png';
        doc.addImage(img, 'png', 133, 32, 40, 12);
        doc.text(40, 40, 'SGR - Sistema de Gestão de Resíduos');
        // NÚMERO MANIFESTO
        doc.setFontSize(12);
        doc.setFontType('bolditalic');
        doc.text(50, 55, 'Manifesto.:');
        doc.setFontType('normal');
        console.log('.....................................', this.manifestoEdit.numeroManifesto);
        doc.text(76, 55, this.manifestoEdit.numeroManifesto.toString());
        // GERADOR
        doc.setFontSize(12);
        doc.setFontType('bolditalic');
        doc.text(20, 60, 'Gerador');
        if (this.manifestoEdit.geradorCnpj != null) {
            doc.text(20, 70, 'CNPJ: ');
            doc.setFontType('normal');
            doc.text(35, 70, this.manifestoEdit.geradorCnpj);
        }
        else {
            doc.setFontType('bolditalic');
            doc.text(20, 70, 'CPF: ');
            doc.setFontType('normal');
            doc.text(35, 70, this.manifestoEdit.geradorCpf);
        }
        doc.setFontType('bolditalic');
        doc.text(80, 70, 'Nome: ');
        doc.setFontType('normal');
        doc.text(95, 70, this.manifestoEdit.geradorNome);
        if (this.manifestoEdit.geradorCnpj !== '92.963.560/0001-60') {
            doc.setFontType('bolditalic');
            doc.text(20, 75, 'Fone: ');
            doc.setFontType('normal');
            doc.text(35, 75, this.manifestoEdit.geradorFone);
            doc.setFontType('bolditalic');
            doc.text(80, 75, 'Email: ');
            doc.setFontType('normal');
            if (this.manifestoEdit.geradorEmail != null) {
                doc.text(95, 75, this.manifestoEdit.geradorEmail);
            }
        }
        doc.setFontType('bolditalic');
        doc.text(20, 80, 'Endereço: ');
        doc.setFontType('normal');
        doc.text(42, 80, this.manifestoEdit.geradorEndereco + ' - ' + this.manifestoEdit.geradorMunicipio);
        doc.setFontType('bolditalic');
        doc.text(20, 85, 'Data Expedição: ');
        doc.setFontType('normal');
        doc.text(55, 85, new Date(this.manifestoEdit.geradorDataExpedicao).toLocaleDateString());
        // RESÍDUOS
        doc.setFontType('bolditalic');
        doc.text(20, 100, 'Resíduos');
        doc.text(20, 110, 'Obra: ');
        doc.setFontType('normal');
        var obraManifesto = '';
        this.obras.forEach(function (obra) {
            if (_this.manifestoEdit.obra === obra.value) {
                obraManifesto = obra.label;
            }
        });
        doc.text(35, 110, obraManifesto);
        doc.setFontType('bolditalic');
        doc.text(70, 110, 'Etapa: ');
        doc.setFontType('normal');
        var etapaManifesto = '';
        this.etapas.forEach(function (etapa) {
            if (_this.manifestoEdit.etapa === etapa.value) {
                etapaManifesto = etapa.label;
            }
        });
        doc.text(85, 110, etapaManifesto);
        doc.setFontType('bolditalic');
        doc.text(20, 115, 'Quantidade: ');
        doc.setFontType('normal');
        var unidade = this.manifestoEdit.unidadeQuantidade;
        if (this.manifestoEdit.unidadeQuantidade === 'toneladas') {
            unidade = 't';
        }
        doc.text(47, 115, this.manifestoEdit.quantidade + ' ' + unidade);
        doc.setFontType('bolditalic');
        doc.text(70, 115, 'Materiais: ');
        doc.setFontType('normal');
        var produto = '';
        var tamanho = 0;
        if (this.manifestoEdit.produto != null) {
            this.manifestoEdit.produto.forEach(function (pr) {
                if (tamanho > 0) {
                    produto = produto + ', ' + pr;
                }
                else {
                    produto = produto + pr;
                }
                tamanho += 1;
            });
        }
        doc.text(92, 115, produto);
        doc.setFontType('bolditalic');
        doc.text(20, 120, 'Endereço: ');
        doc.setFontType('normal');
        if (this.manifestoEdit.endereco !== null) {
            if (this.manifestoEdit.endereco.codigoLogradouro == null) {
                doc.text(42, 120, this.manifestoEdit.endereco.nomeLogradouro + ', ' + this.manifestoEdit.endereco.numero
                    + ' - ' + this.manifestoEdit.endereco.municipio);
            }
            else {
                doc.text(42, 120, this.manifestoEdit.endereco.enderecoFormatado + ' - Porto Alegre');
            }
        }
        // TRANSPORTADOR
        doc.setFontType('bolditalic');
        doc.text(20, 135, 'Transportador');
        if (this.manifestoEdit.transportadorCnpj != null) {
            doc.text(20, 145, 'CNPJ: ');
            doc.setFontType('normal');
            doc.text(35, 145, this.manifestoEdit.transportadorCnpj);
        }
        else {
            doc.setFontType('bolditalic');
            doc.text(20, 145, 'CPF: ');
            doc.setFontType('normal');
            doc.text(35, 145, this.manifestoEdit.transportadorCpf);
        }
        doc.setFontType('bolditalic');
        doc.text(80, 145, 'Nome: ');
        doc.setFontType('normal');
        doc.text(95, 145, this.manifestoEdit.transportadorNome);
        doc.setFontType('bolditalic');
        doc.text(20, 150, 'Fone: ');
        doc.setFontType('normal');
        doc.text(35, 150, this.manifestoEdit.transportadorFone);
        doc.setFontType('bolditalic');
        doc.text(80, 150, 'Email: ');
        doc.setFontType('normal');
        if (this.manifestoEdit.transportadorEmail != null) {
            doc.text(95, 150, this.manifestoEdit.transportadorEmail);
        }
        doc.setFontType('bolditalic');
        doc.text(20, 155, 'Endereço: ');
        doc.setFontType('normal');
        doc.text(42, 155, this.manifestoEdit.transportadorEndereco + ' - ' + this.manifestoEdit.transportadorMunicipio);
        doc.setFontType('bolditalic');
        doc.text(20, 160, 'Veículo: ');
        doc.setFontType('normal');
        if (this.manifestoEdit.transportadorPlaca != null) {
            doc.text(39, 160, this.manifestoEdit.transportadorPlaca);
        }
        if (this.manifestoEdit.transportadorDataTransporte != null) {
            doc.setFontType('bolditalic');
            doc.text(80, 160, 'Data Transporte: ');
            doc.setFontType('normal');
            doc.text(115, 160, new Date(this.manifestoEdit.transportadorDataTransporte).toLocaleDateString());
        }
        // DESTINO FINAL
        doc.setFontType('bolditalic');
        doc.text(20, 175, 'Destino Final');
        if (this.manifestoEdit.destinadorCnpj != null) {
            doc.text(20, 185, 'CNPJ: ');
            doc.setFontType('normal');
            doc.text(35, 185, this.manifestoEdit.destinadorCnpj);
        }
        else {
            doc.setFontType('bolditalic');
            doc.text(20, 185, 'CPF: ');
            doc.setFontType('normal');
            doc.text(35, 185, this.manifestoEdit.destinadorCpf);
        }
        doc.setFontType('bolditalic');
        doc.text(80, 185, 'Nome: ');
        doc.setFontType('normal');
        doc.text(95, 185, this.manifestoEdit.destinadorNome);
        if (this.manifestoEdit.geradorCnpj !== '92.963.560/0001-60') {
            doc.setFontType('bolditalic');
            doc.text(20, 190, 'Fone: ');
            doc.setFontType('normal');
            doc.text(35, 190, this.manifestoEdit.destinadorFone);
            doc.setFontType('bolditalic');
            doc.text(80, 190, 'Email: ');
            doc.setFontType('normal');
            if (this.manifestoEdit.destinadorEmail != null) {
                doc.text(95, 190, this.manifestoEdit.destinadorEmail);
            }
        }
        doc.setFontType('bolditalic');
        doc.text(20, 195, 'Endereço: ');
        doc.setFontType('normal');
        doc.text(42, 195, this.manifestoEdit.destinadorEndereco + ' - ' + this.manifestoEdit.destinadorMunicipio);
        if (this.manifestoEdit.destinadorDataRecebimento != null) {
            doc.setFontType('bolditalic');
            doc.text(20, 200, 'Data Recebimento: ');
            doc.setFontType('normal');
            doc.text(60, 200, new Date(this.manifestoEdit.destinadorDataRecebimento).toLocaleDateString());
        }
        if (this.manifestoEdit.destinadorCnpj == null && this.manifestoEdit.destinadorDataRecebimento == null) {
            doc.setFontType('normal');
            doc.setFontSize(8);
            doc.text(20, 204, 'Após receber ou recusar o recebimento do MTRCC, favor enviar um e-mail à SMAMS ' +
                '(smamus.mtrcc@portoalegre.rs.gov.br)');
            doc.text(20, 207, 'solicitando a baixa no Manifesto conforme a situação (Recebido ou Recusado - com motivo).');
            doc.setFontSize(12);
        }
        // SITUAÇÃO DO MANIFESTO
        doc.setFontType('bolditalic');
        doc.text(20, 215, 'Situação');
        if (this.manifestoEdit.anulado) {
            doc.setFontSize(18);
            doc.text(50, 216, 'A N U L A D O');
            doc.setFontSize(12);
            doc.text(20, 225, 'Data Anulação: ');
            doc.setFontType('normal');
            doc.text(52, 225, new Date(this.manifestoEdit.dataAnulacaoRecusa).toLocaleDateString());
            doc.setFontType('bolditalic');
            doc.text(20, 230, 'Justificativa: ');
            doc.setFontType('normal');
            doc.text(52, 230, this.manifestoEdit.justificativaAnulacaoRecusa);
        }
        else if (this.manifestoEdit.recusado) {
            doc.setFontSize(18);
            doc.text(50, 216, 'R E C U S A D O');
            doc.setFontSize(12);
            doc.text(20, 225, 'Data Recusa: ');
            doc.setFontType('normal');
            doc.text(51, 225, new Date(this.manifestoEdit.dataAnulacaoRecusa).toLocaleDateString());
            doc.setFontType('bolditalic');
            doc.text(20, 230, 'Justificativa: ');
            doc.setFontType('normal');
            doc.text(51, 230, this.manifestoEdit.justificativaAnulacaoRecusa);
        }
        else if (this.manifestoEdit.destinadorDataRecebimento == null) {
            doc.setFontSize(18);
            doc.text(50, 216, 'A B E R T O');
        }
        else {
            doc.setFontSize(18);
            doc.text(50, 216, 'R E C E B I D O');
        }
        doc.setFontSize(12);
        doc.setFontType('normal');
        if (this.manifestoEdit.dataAnulacaoRecusa === null && this.manifestoEdit.destinadorDataRecebimento === null) {
            doc.text(20, 240, 'O prazo para o encerramento do procedimento é de 7 dias úteis!');
            doc.text(20, 245, 'Em caso de pendências, estará sujeito a ação fiscal conforme Lei Nº 10.847/2010.');
            // doc.text(20, 255, 'Coordenação de Resíduos Sólidos - CRS');
        }
        img = new Image();
        img.src = 'assets/img/logoProcempa.png';
        doc.addImage(img, 'png', 155, 255, 40, 5);
        doc.text(20, 260, 'Secretaria Municipal de Meio Ambiente, Urbanismo e Sustentabilidade – SMAMUS');
        if (opcaoDownloadPdf) {
            doc.save('MTRCC_' + this.manifestoEdit.numeroManifesto + '.pdf');
            console.log('geraPdfManifesto...DOC........>>>>', FormData);
        }
        else {
            // doc.addPage();
            // var data = new Blob([doc.output('blob')], {
            //   type: 'application/pdf'
            // });
            // console.log('geraPdfManifesto...DOC........>>>>', data);
            var blob = doc.output('blob');
            // new Blob([doc], {type: 'application/pdf'});
            var formData = new FormData();
            formData.append('blob', blob);
            console.log('geraPdfManifesto...DOC........>>>>', formData);
            this.manifestoService.enviaEmailManifesto(this.manifestoEdit.id, formData).subscribe(function (anexo) {
                console.log('evidenciaDetail - Retorno INCLUIR Anexo ===============', anexo);
            }, function (error) {
                console.log('ERRO>>>>>>', error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    // dateRangeSelect(dataRange) {
    //   console.log('dateRangeSelect........>>>>', dataRange);
    // }
    // clearDateRangePicker(dataRange) {
    // }
    // salvarEndereco() {
    //   console.log("ENDEREÇO salvarEndereco>>>>>>>", this.endereco);
    //   // this.entidadeEdit.enderecoDO = endereco;
    //   this.endereco.entidadeDO = this.entidadeRepresentada;
    //   if (this.endereco.codigoLogradouro == null) {
    //     this.endereco.enderecoFormatado =
    //       this.endereco.nomeLogradouro + ", " + this.endereco.numero;
    //   } else {
    //     this.endereco.municipio = "Porto Alegre";
    //   }
    //   this.enderecoService.criarEndereco(this.endereco).subscribe(
    //     retorno => {
    //       this.mostrarDialogoEnderecoPoa = false;
    //       this.spinnerService.ocultar();
    //       this.notificacoesService.notificarSucesso(
    //         "Endereço salvo com sucesso",
    //         null
    //       );
    //       if (this.endereco.id === null) {
    //         this.enderecos.push(retorno);
    //       }
    //       this.endereco = retorno;
    //       this.enderecos = [];
    //       this.enderecoService
    //         .getEnderecosPorEntidade(this.entidadeRepresentada.id)
    //         .subscribe(
    //           enderecos => {
    //             this.enderecos = enderecos;
    //             this.globals.enderecosEntidade = enderecos;
    //           },
    //           error => {
    //             this.spinnerService.ocultar();
    //             this.notificacoesService.notificarErro(error.error, null);
    //           }
    //         );
    //     },
    //     error => {
    //       console.log(error);
    //       this.spinnerService.ocultar();
    //       this.notificacoesService.notificarErro(error.error, null);
    //     }
    //   );
    // }
    // informaEnderecoPoa(isPoa) {
    //   console.log('ENDEREÇO informaEnderecoPoa>>>>>>>', isPoa);
    //   this.endereco = {
    //     id: null,
    //     dataInclusao: null,
    //     timestampAsString: null,
    //     usuarioInclusao: null,
    //     usuarioAlteracao: null,
    //     cep: null,
    //     codigoBairro: null,
    //     codigoLogradouro: null,
    //     codigoLogradouroFim: null,
    //     codigoLogradouroInicio: null,
    //     dataHistorico: null,
    //     enderecoFormatado: null,
    //     enderecoFormatadoCurto: null,
    //     lado: null,
    //     localizacao: null,
    //     nomeBairro: null,
    //     nomeBairroHistorico: null,
    //     nomeHistorico: null,
    //     nomeLogradouro: null,
    //     nomeLogradouroFim: null,
    //     nomeLogradouroInicio: null,
    //     numero: null,
    //     municipio: null,
    //     situacao: null
    //   };
    //   this.tituloDialogEditEndereco = "";
    //   this.mostrarDialogoEnderecoPoa = false;
    //   this.mostrarDialogoEnderecoForaPoa = false;
    //   if (isPoa) {
    //     this.mostrarDialogoEnderecoPoa = true;
    //     this.tituloDialogEditEndereco = "Endereço de Porto Alegre.";
    //   } else {
    //     this.mostrarDialogoEnderecoForaPoa = true;
    //     this.tituloDialogEditEndereco = "Endereço de outros Municípios.";
    //   }
    // }
    ListaManifestosComponent.prototype.transform = function (manifestos) {
        // let tamMax = 0;
        // let anoM = '';
        // let numM = '';
        // let tamanho = 0;
        // manifestos.forEach(m => {
        // numM = m.numeroManifesto.toString().substring(4, m.numeroManifesto.toString().length);
        // tamanho = numM.toString().length;
        //   // console.log('Classificação tamanho>>', tamanho);
        //   if (tamanho > tamMax) {
        //     tamMax = tamanho;
        //   }
        // });
        // console.log('Classificação tamanho final>>', tamMax);
        // manifestos.forEach(m => {
        //   anoM = m.numeroManifesto.toString().substring(0, 4);
        //   numM = m.numeroManifesto.toString().substring(4, m.numeroManifesto.toString().length);
        //   console.log('Classificação tamanho>>', numM);
        //   for (let n = numM.toString().length; tamMax > n; ++n) {
        //     numM = '0' + numM;
        //   }
        //   m.numeroManifesto = anoM + numM;
        // });
        // console.log('Classificação tamanho final>>', tamMax);
        // if (!Array.isArray(array)) {
        //   return;
        // }
        var filtro = 'numeroManifesto';
        var nman1 = 0;
        var nman2 = 0;
        // manifestos.sort((nm1: any, nm2: any) => {
        //   // console.log('Classificação>>', nm1[filtro].toString(), '----', nm2[filtro].toString());
        //   anoM = nm1[filtro].toString().substring(0, 4);
        //   numM = nm1[filtro].toString().substring(4, nm1[filtro].toString().length);
        //   for (let n = numM.toString().length; tamMax > n; ++n) {
        //     numM = '0' + numM;
        //   }
        //   nman1 = Number(anoM + numM);
        //   anoM = nm2[filtro].toString().substring(0, 4);
        //   numM = nm2[filtro].toString().substring(4, nm2[filtro].toString().length);
        //   for (let n = numM.toString().length; tamMax > n; ++n) {
        //     numM = '0' + numM;
        //   }
        //   nman2 = Number(anoM + numM);
        //   // console.log('Classificação tamanho>>', numM);
        //   if (nman1 < nman2) {
        //     // console.log('Classificação>>', nman1, '--<--', nman2);
        //     return -1;
        //   } else if (nman1 > nman2) {
        //     // console.log('Classificação>>', nman1, '-->--', nman2);
        //     return 1;
        //   } else {
        //     return 0;
        //   }
        // });
        var anoManifesto = [];
        var ano = 0;
        manifestos.forEach(function (mn) {
            if (anoManifesto.length < 1) {
                ano = mn.anoReferencia;
                anoManifesto.push(mn.anoReferencia);
            }
            else {
                if (ano !== mn.anoReferencia) {
                    ano = mn.anoReferencia;
                    anoManifesto.push(mn.anoReferencia);
                }
            }
        });
        console.log('Classificação ANOS>>', anoManifesto);
        anoManifesto.forEach(function (anoM) {
            manifestos.sort(function (a, b) {
                if (anoM === b.anoReferencia) {
                    if (a.numManifesto > b.numManifesto) {
                        return 1;
                    }
                    if (a.numManifesto < b.numManifesto) {
                        return -1;
                    }
                    return 0;
                }
            });
        });
        this.manifestos = manifestos;
    };
    ListaManifestosComponent.prototype.buscaExecutora = function (nomeObra) {
        var _this = this;
        console.log('buscaExecutora 0000>>', nomeObra);
        this.nomeObrasList.forEach(function (no) {
            console.log('buscaExecutora 1111>>', no.nome);
            if (nomeObra === no.nome) {
                _this.entidadeExecutora = no.executora;
            }
        });
        console.log('buscaExecutora 2222>>', this.entidadeExecutora);
    };
    return ListaManifestosComponent;
}());
export { ListaManifestosComponent };
