import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ObraPmpaService = /** @class */ (function () {
    function ObraPmpaService(http) {
        this.http = http;
    }
    ObraPmpaService.prototype.getObraPmpa = function (id) {
        var url = environment.apiUrl + '/obraPmpa/byId/' + id;
        console.log('criarObraPmpa Service >>>>>>', url);
        return this.http.get(url);
    };
    ObraPmpaService.prototype.getObrasPmpa = function () {
        var url = environment.apiUrl + '/obraPmpa';
        console.log('getObrasPmpa Service >>>>>>', url);
        return this.http.get(url);
    };
    ObraPmpaService.prototype.getObrasPmpaAtivas = function () {
        var url = environment.apiUrl + '/obraPmpa/ativas';
        console.log('getObrasPmpaAtivas Service >>>>>>', url);
        return this.http.get(url);
    };
    ObraPmpaService.prototype.criarObraPmpa = function (obraPmpa) {
        return this.http.post(environment.apiUrl + '/obraPmpa/', obraPmpa);
    };
    ObraPmpaService.prototype.editarObraPmpa = function (obraPmpa) {
        return this.http.put(environment.apiUrl + '/obraPmpa/', obraPmpa);
    };
    ObraPmpaService.prototype.deletaObraPmpa = function (idObraPmpa) {
        var url = environment.apiUrl + '/obraPmpa/deletar/' + idObraPmpa;
        console.log('ObraPmpa Service - DELETA>>>>>>>', url);
        return this.http
            .delete(url, idObraPmpa);
    };
    ObraPmpaService.ngInjectableDef = i0.defineInjectable({ factory: function ObraPmpaService_Factory() { return new ObraPmpaService(i0.inject(i1.HttpClient)); }, token: ObraPmpaService, providedIn: "root" });
    return ObraPmpaService;
}());
export { ObraPmpaService };
