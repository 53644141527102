import { enableProdMode, LOCALE_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLocaleData } from '@angular/common';
import localPt from '@angular/common/locales/pt';

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = function() {};
  }
}
registerLocaleData(localPt, 'pt');
platformBrowserDynamic().bootstrapModule(AppModule, { providers: [{ provide: LOCALE_ID, useValue: 'pt' }] });
