import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SecretariaPmpaService = /** @class */ (function () {
    function SecretariaPmpaService(http) {
        this.http = http;
    }
    SecretariaPmpaService.prototype.getSecretariaPmpa = function (id) {
        var url = environment.apiUrl + '/secretariaPmpa/byId/' + id;
        console.log('criarSecretaria Service >>>>>>', url);
        return this.http.get(url);
    };
    SecretariaPmpaService.prototype.getSecretariasPmpa = function () {
        var url = environment.apiUrl + '/secretariaPmpa';
        console.log('criarSecretaria Service >>>>>>', url);
        return this.http.get(url);
    };
    SecretariaPmpaService.prototype.criarSecretariaPmpa = function (secretariaPmpa) {
        return this.http.post(environment.apiUrl + '/secretariaPmpa/', secretariaPmpa);
    };
    SecretariaPmpaService.prototype.editarSecretariaPmpa = function (secretariaPmpa) {
        return this.http.put(environment.apiUrl + '/secretariaPmpa/', secretariaPmpa);
    };
    SecretariaPmpaService.prototype.deletaSecretariaPmpa = function (idSecretariaPmpa) {
        var url = environment.apiUrl + '/secretariaPmpa/deletar/' + idSecretariaPmpa;
        console.log('Secretaria Service - DELETA>>>>>>>', url);
        return this.http
            .delete(url, idSecretariaPmpa);
    };
    SecretariaPmpaService.ngInjectableDef = i0.defineInjectable({ factory: function SecretariaPmpaService_Factory() { return new SecretariaPmpaService(i0.inject(i1.HttpClient)); }, token: SecretariaPmpaService, providedIn: "root" });
    return SecretariaPmpaService;
}());
export { SecretariaPmpaService };
