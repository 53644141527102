import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Message } from 'primeng/api';
import { Subscription } from 'rxjs';
import { SpinnerService } from '../services/spinner.service';

@Component({
  selector: 'sgr-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnDestroy {

  exibir: boolean;
  mensagens: Message[] = [];
  subscription: Subscription;

  constructor(private spinnerService: SpinnerService,
              private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.exibir = false;

    this.subscription = this.spinnerService.spinnerSubject.subscribe(deveExibir => {
      this.exibir = deveExibir;
      this.changeDetectorRef.detectChanges(); // Fix erro 'ExpressionChangedAfterItHasBeenCheckedError' para spinner
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
