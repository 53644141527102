import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Produto } from 'src/app/@shared/models/produto.model';

@Injectable({providedIn: 'root'})
export class ProdutoService {

  constructor(private http: HttpClient) { }

  // getProdutos(): Observable<Produto[]> {
  //   return this.http.get<Produto[]>(BACKEND_URL);
  // }

  getProdutos(tipoAceso, isEntidade): Observable<Produto[]> {
    if (isEntidade) {
      return this.http.get<Produto[]>(environment.apiUrl + '/produtos/tipoAcessoEmpresa/' + tipoAceso);
    } else {
      return this.http.get<Produto[]>(environment.apiUrl + '/produtos/tipoAcessoSecretaria/' + tipoAceso);
    }
  }

  getProdutosDoContrato(idContrato, isEntidade): Observable<Produto[]> {
    if (isEntidade) {
      return this.http.get<Produto[]>(environment.apiUrl + '/produtos/doContrato/' + idContrato);
    } else {
      // return this.http.get<Produto[]>(environment.apiUrl + '/produtos/tipoAcessoSecretaria/' + tipoAceso);
    }
  }

  getProduto(id: string): Observable<Produto> {
    return this.http.get<Produto>(environment.apiUrl + '/produtos/');
  }

  getProdutoNome(nome: string): Observable<Produto> {
    return this.http.get<Produto>(environment.apiUrl + '/produtos/nome/' + nome);
  }

  criarProduto(produto: Produto): Observable<Produto> {
    return this.http.post<Produto>(environment.apiUrl + '/produtos/', produto);
  }

  editarProduto(produto: Produto): Observable<Produto> {
    return this.http.put<Produto>(environment.apiUrl + '/produtos/', produto);
  }

  excluirProduto(produto: Produto, tipoAcesso: string): Observable<Produto> {
    return this.http.delete<Produto>(environment.apiUrl + '/produtos/deletar/' + produto.id + '/' + tipoAcesso);
  }

  getProdutosContrato(idContrato): Observable<Produto[]> {
    return this.http.get<Produto[]>(environment.apiUrl + '/produtos/contrato/' + idContrato);
  }

}
