<!-- <sgr-header></sgr-header> -->

<div class="container">
  <!-- <form [formGroup]="form" (ngSubmit)="salvar()"> -->
    <div class="p-grid">
      <div class="p-col-12">
        <label class="text-primary"><font size='5'><strong>Cadastramento de Responsável</strong></font></label>
        <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Olá! Bem vindo!
        <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Aqui você faz o cadastramento do Responsável legal para acesso ao sistema. <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As demais informações da Entidade/Empresa deverão ser
        complementadas ao entrar no sistema. <br>

        <!-- Aqui você faz o cadastramento da sua Entidade gestora ou Empresa
        individual<br />
        e do Responsável legal para acesso ao sistema. <br />
        As demais informações da Entidade/Empresa e Responsável deverão ser
        complementadas ao entrar no sistema. <br /> -->
        <!-- Após o cadastramento acesse as opções do sistema:
        <br>1) MTRCC; 2) Grandes Geradores; 3) Logística Reversa;<br> -->
      </div>
    </div>

    <div class="card text-black info04">
      <div class="card-header text-primary">
        <font size='5'><strong>Responsável Legal</strong></font>
      </div>
      <div class="card-body">
        <div class="p-grid">
          <div class="p-col-3">
            <label for="cpf" class="d-block">CPF<font color='red'><strong>&nbsp;*</strong></font></label>
            <!-- <p-inputMask id="cpf" mask="999.999.999-99" [(ngModel)]="pessoaFisica.cpf" placeholder="__.___.___/____-__" size="20" *ngIf="!insertModePF" disabled></p-inputMask> -->
            <p-inputMask id='cpf' mask="999.999.999-99" [(ngModel)]="pessoaFisica.cpf" placeholder="__.___.___/____-__" size="20"
             (onBlur)="verificaCpfExiste(pessoaFisica.cpf)"></p-inputMask>

          </div>
          <div class="p-col-9">
            <div class="form-group">
              <label for="nomeCompleto">Nome<font color='red'><strong>&nbsp;*</strong></font></label>
              <input type="text" id="nomeCompleto" [(ngModel)]="pessoaFisica.nomeCompleto" size="40" class="form-control" pInputText/>
            </div>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col-6">
            <div class="form-group">
              <label for="email">Email<font color='red'><strong>&nbsp;*</strong></font></label>
              <input [readonly]="true" type="text" id="email" [(ngModel)]="pessoaFisica.email" size="40" class="form-control" pInputText/>
            </div>
          </div>
          <div class="p-col-2">
            <label for="fone">Fone Comercial</label>
            <p-inputMask id="fone" mask="(99) 9999-9999?9" [(ngModel)]="pessoaFisica.telefone" size="15"></p-inputMask>
          </div>
          <div class="p-col-4">
            <label for="fone">Celular<font color='red'><strong>&nbsp;*</strong></font></label>
            <p-inputMask id="fone" mask="(99) 99999-999?9" [(ngModel)]="pessoaFisica.celular" size="15"></p-inputMask>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="p-grid" style="text-align: right;">
      <div class="p-col-12">
        <button title="Cadastrar" (click)="salvar()" label="Cadastrar" class="btn btn-primary">Enviar</button>
      </div>
    </div>
</div>








<!-- <p-panel>
  <p-header class="pl-5">
    <b>Entidade/Empresa responsável</b>
  </p-header>

  <div *ngIf='entidade !== null'></div>
      <font size="3" color="red"><b>{{mensagemCadastro}}</b></font>
  <div
    class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid"
    style="margin: 10px 0px"
  > -->
      <!-- <div class="ui-g-12">
          <label for="vin">ATENÇÃO!!! As seguites informações referentes ao CNPJ que será incluído (Razão Social, Telefone, Email, Número e Descrição do CNAE) serão buscadas no banco de dados da Secretaria Municipal da Fazenda (SMF).
            <br>Caso essas informações não correspondam ao momento atual da empresa em questão, favor entrar em contato com a SMF para a atualização dos mesmos.
            <br>Caso o CNPJ não seja encontrado no banco de dados da SMF, será permitida a inclusão dessas informações.
          </label>
      </div> -->

      <!-- <div class="pl-5 ui-g-3">
        <label>CNPJ</label>
        <br />
        <p-inputMask
          mask="99.999.999/9999-99"
          formControlName="cnpj"
          #cnpj
          placeholder="__.___.___/____-__"
          (onBlur)="buscaEntidade(cnpj.value)"
        ></p-inputMask>
        <div
          class="ui-message ui-messages-error ui-corner-all"
          *ngIf="
            form.get('cnpj').invalid &&
            (form.get('cnpj').dirty || form.get('cnpj').touched)
          "
        >
          <i class="fa fa-close"></i>
          CNPJ obrigatório
        </div>
      </div>
      <div class="offset-1 ui-g-8">
        <label>Nome Fantasia</label>
        <br />
        <input
          type="text"
          pInputText
          formControlName="nomeFantasia"
          size="35"
        />
      </div> -->
      <!--
          <div class="ui-grid-col-12">
            <div class="ui-message ui-messages-error ui-corner-all" *ngIf="form.get('nomeFantasia').invalid && (form.get('nomeFantasia').dirty || form.get('nomeFantasia').touched)">
              <i class="fa fa-close"></i>
              Nome Fantasia é obrigatório
            </div>
          </div>
          -->

      <!-- <div class="ui-g-4">
        <Label>Tipo&nbsp;Entidade*:</Label>
        <br>
        <p-dropdown [options]="tiposEntidade" formControlName="tipoEntidade" placeholder="Selecione"></p-dropdown>
      </div>
      <div class="ui-g-2">
        <div class="ui-message ui-messages-error ui-corner-all" *ngIf="form.get('tipoEntidade').invalid && (form.get('tipoEntidade').dirty || form.get('tipoEntidade').touched)">
          <i class="fa fa-close"></i>
          Tipo obrigatório
        </div>
      </div> -->
    <!-- </div> -->
<!--
    <div class="pl-5 ui-g-12">
      <label>Razão&nbsp;Social</label>
      <br />
      <input
        type="text"
        pInputText
        formControlName="razaoSocial"
        size="60"
      />
      <div
        class="ui-message ui-messages-error ui-corner-all"
        *ngIf="
          form.get('razaoSocial').invalid &&
          (form.get('razaoSocial').dirty || form.get('razaoSocial').touched)
        "
      >
        <i class="fa fa-close"></i>
        Razão Social é obrigatório
      </div>
    </div>
    <div class="pl-5 ui-g-3">
      <label for="vin">Telefone</label>
      <br>
      <p-inputMask id="fone" mask="(99) 9999-9999?9" formControlName="telefone" size="15"></p-inputMask>
    </div>
    <div class="pl-5 ui-g-9">
      <label for="vin">Email</label>
      <br>
      <input pInputText id="email" formControlName="email" size="55" />
    </div>
    <div class="pl-5 ui-g-3">
      <label for="vin">Número CNAE</label>
      <br>
      <input pInputText id="numeroCnae"  formControlName="numeroCnae" />
    </div>
    <div class="pl-5 ui-g-3">
      <label for="vin">Descrição CNAE</label>
      <br>
      <input pInputText id="descricaoCnae"  formControlName="descricaoCnae" size="55"/>
    </div> -->

    <!-- <div class="offset-1 ui-g-6">
      <label>Nome Sistema de Gestão de Resíduos</label>
      <br />
      <input
        type="text"
        pInputText
        formControlName="nomeSisLog"
        style="width: 250px"
      />
    </div> -->

    <!--
  -->
<!-- </div>
</p-panel> -->
