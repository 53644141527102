var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { MenuItem } from 'primeng/api';
import { EntidadeService } from '../entidade/entidade.service';
import { SpinnerService } from '../services/spinner.service';
import { NotificacoesService } from '../services/notificacoes.service';
import { Globals } from 'src/app/globals';
import { PessoaFisica } from 'src/app/@shared/models/pessoaFisica.model';
import { PessoaFisicaService } from '../services/pessoaFisica.service';
var UsuarioComponent = /** @class */ (function () {
    function UsuarioComponent(router, fb, keycloakService, pessoaFisicaService, entidadeService, spinnerService, notificacoesService, globals) {
        this.router = router;
        this.fb = fb;
        this.keycloakService = keycloakService;
        this.pessoaFisicaService = pessoaFisicaService;
        this.entidadeService = entidadeService;
        this.spinnerService = spinnerService;
        this.notificacoesService = notificacoesService;
        this.globals = globals;
        this.pessoasFisicas = [];
        this.entidades = [];
        this.entidadeNome = '';
        this.activeRoute = this.router.url;
        this.variaveisGlobais = globals;
    }
    UsuarioComponent.prototype.ngOnInit = function () {
        if (this.variaveisGlobais.menuRaiz === null || this.variaveisGlobais.menuRaiz === '') {
            this.router.navigate(['/sgrIni']);
        }
        else {
            this.inicializaOnInit();
        }
    };
    UsuarioComponent.prototype.inicializaOnInit = function () {
        // this.spinnerService.exibir();
        this.pessoaFisica = new PessoaFisica();
    };
    UsuarioComponent.prototype.buscaPessoasFisicas = function (opcoes) {
        // this.pessoasFisicasPmpa = [];
        // this.pfsSecretaria = [];
        // this.pessoaFisicaService.getPessoasFisicasDaEntidade(idEntidade).subscribe(pessoaFisicas => {
        //   this.pessoasFisicasPmpa = pessoaFisicas;
        //   this.pfsSecretaria = pessoaFisicas;
        //   console.log('buscaPessoasFisicasEntidade........pessoas...........', this.pessoasFisicasPmpa);
        //   let ativos = 0;
        //   this.pessoasFisicasPmpa.forEach(pessoa => {
        //     if (pessoa.ativo) {
        //       ativos = ativos + 1;
        //       console.log('buscaPessoasFisicasEntidade.....Ativos..............', ativos);
        //     }
        //   });
        //   this.buscaSecretariasPmpa();
        //   this.podeInativar = false;
        //   if (ativos > 1) {
        //     this.podeInativar = true;
        //   }
        //   console.log('Pode Inativar...................', this.podeInativar, '---', ativos);
        // });
    };
    UsuarioComponent.prototype.logout = function () {
        var url = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
        this.keycloakService.getKeycloakInstance().logout({ redirectUri: url });
    };
    UsuarioComponent.prototype.mostrarDialogParaNovo = function () {
        this.pessoaFisica = new PessoaFisica();
        this.mostrarDialogEdit = true;
        this.isInsert = true;
        this.tituloDialogEdit = 'Nova Responsável Legal/Usuário/Fiscal';
    };
    UsuarioComponent.prototype.mostrarDialogParaEditar = function (idPessoaFisica) {
        var pfParaEditar = this.pessoasFisicas.find(function (pf) {
            return pf.id === idPessoaFisica;
        });
        this.pessoaFisica = __assign({}, pfParaEditar);
        console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.pessoaFisica);
        this.mostrarDialogEdit = true;
        this.isInsert = false;
        this.tituloDialogEdit = 'Editar Responsável Legal/Usuário/Fiscal';
        this.buscarEntidades(this.pessoaFisica.id);
    };
    UsuarioComponent.prototype.verificaCpfExiste = function (cpf) {
        var _this = this;
        if (cpf === '__.___.___-__') {
            console.log('111verificaCpfExiste>>>>>>>', cpf);
            cpf = null;
        }
        console.log('222verificaCpfExiste>>>>>>>', cpf);
        if (cpf == null || cpf === '') {
            // this.spinnerService.ocultar();
            // this.notificacoesService.notificarErro('Não encontrou entidade para CNPJ informado!', null);
        }
        else {
            this.pessoaFisicaService.getPessoasFisicasPorCpf(cpf).subscribe(function (pessoaFisica) {
                console.log('verificaCnpjExiste1111>>>>>>>', pessoaFisica);
                if (pessoaFisica != null) {
                    _this.pessoaFisica = pessoaFisica;
                    _this.pessoaFisica.ativo = true;
                    _this.isInsert = false;
                    // this.spinnerService.ocultar();
                    // this.notificacoesService.notificarErro('Empresa com CNPJ informado já está cadastrada!', null);
                }
                else {
                    var novoCpf = cpf.replace(/[^\d]+/g, '');
                    // Acessa SMF
                }
            }, function (error) {
                console.log(error);
                console.log(error.error);
                _this.pessoaFisica.cpf = null;
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    UsuarioComponent.prototype.checkActive = function (route) {
        return route === this.activeRoute;
    };
    UsuarioComponent.prototype.pesquisaUsuario = function () {
        var _this = this;
        this.pessoasFisicas = [];
        if (this.nomePesquisa === undefined || this.nomePesquisa === null) {
            this.nomePesquisa = '';
        }
        if (this.cpfPesquisa === undefined || this.cpfPesquisa === null) {
            this.cpfPesquisa = '';
        }
        if (this.emailPesquisa === undefined || this.emailPesquisa === null) {
            this.emailPesquisa = '';
        }
        if (this.cnpjPesquisa === undefined || this.cnpjPesquisa === null) {
            this.cnpjPesquisa = '';
        }
        var apenasUmCriterio = false;
        var criterioPesquisa;
        var tipoCriterioPesquisa;
        if (this.nomePesquisa !== '') {
            apenasUmCriterio = true;
            criterioPesquisa = this.nomePesquisa;
            tipoCriterioPesquisa = "nome";
        }
        if (this.cpfPesquisa !== '') {
            if (apenasUmCriterio) {
                this.notificacoesService.notificarAviso('Favor selecionar apenas UM critério de pesquisa.!', null);
                return;
            }
            else {
                apenasUmCriterio = true;
                criterioPesquisa = this.cpfPesquisa;
                tipoCriterioPesquisa = "cpf";
            }
        }
        if (this.emailPesquisa !== '') {
            if (apenasUmCriterio) {
                this.notificacoesService.notificarAviso('Favor selecionar apenas UM critério de pesquisa.!', null);
                return;
            }
            else {
                apenasUmCriterio = true;
                criterioPesquisa = this.emailPesquisa;
                tipoCriterioPesquisa = "email";
            }
        }
        if (this.cnpjPesquisa !== '') {
            if (apenasUmCriterio) {
                this.notificacoesService.notificarAviso('Favor selecionar apenas UM critério de pesquisa.!', null);
                return;
            }
            else {
                apenasUmCriterio = true;
                criterioPesquisa = this.cnpjPesquisa;
                tipoCriterioPesquisa = "cnpj";
            }
        }
        console.log('getPessoaFisicaPesquisa 00>>>', criterioPesquisa);
        console.log('getPessoaFisicaPesquisa 11>>>', tipoCriterioPesquisa);
        this.spinnerService.exibir();
        this.pessoaFisicaService.getPessoaFisicaPesquisa(criterioPesquisa, tipoCriterioPesquisa).subscribe(function (pessoasFisicas) {
            console.log('getPessoaFisicaPesquisa 00>>>>>00>>>', pessoasFisicas);
            _this.pessoasFisicas = pessoasFisicas;
            _this.spinnerService.ocultar();
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    UsuarioComponent.prototype.buscarEntidades = function (idPessoaFisica) {
        var _this = this;
        this.spinnerService.exibir();
        this.entidadeService.getEntidadesDaPessoaFisica(idPessoaFisica).subscribe(function (entidades) {
            _this.entidades = entidades;
            _this.totalEntidades = entidades.length;
            // console.log('inicializaOnInit>>>>>>>>>buscaEntidades>>>>', this.entidades);
            _this.spinnerService.ocultar();
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    return UsuarioComponent;
}());
export { UsuarioComponent };
