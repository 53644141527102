var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { MenuItem } from 'primeng/api';
import { EntidadeService } from '../entidade/entidade.service';
import { SpinnerService } from '../services/spinner.service';
import { NotificacoesService } from '../services/notificacoes.service';
import { Globals } from 'src/app/globals';
import { SecretariaPmpa } from 'src/app/@shared/models/secretariaPmpa.model';
import { SecretariaPmpaService } from '../services/secretariaPmpa.service';
var SecretariaPmpaComponent = /** @class */ (function () {
    function SecretariaPmpaComponent(router, keycloakService, secretariaPmpaService, entidadeService, spinnerService, notificacoesService, globals) {
        this.router = router;
        this.keycloakService = keycloakService;
        this.secretariaPmpaService = secretariaPmpaService;
        this.entidadeService = entidadeService;
        this.spinnerService = spinnerService;
        this.notificacoesService = notificacoesService;
        this.globals = globals;
        this.secretariasPmpa = [];
        //entidadePMPA: Entidade;
        this.entidadeNome = '';
        this.enderecoRes = {};
        this.activeRoute = this.router.url;
        this.variaveisGlobais = globals;
    }
    SecretariaPmpaComponent.prototype.ngOnInit = function () {
        if (this.variaveisGlobais.menuRaiz === null || this.variaveisGlobais.menuRaiz === '') {
            this.router.navigate(['/sgrIni']);
        }
        else {
            this.inicializaOnInit();
        }
    };
    SecretariaPmpaComponent.prototype.inicializaOnInit = function () {
        var _this = this;
        this.spinnerService.exibir();
        this.secretariaPmpaEdit = new SecretariaPmpa();
        this.endereco = null;
        this.enderecoRes = null;
        this.logradouroCdl = null;
        this.localizacao = null;
        this.logradouro = null;
        this.numero = null;
        this.secretariaPmpaService.getSecretariasPmpa().subscribe(function (secretariasPmpa) {
            _this.spinnerService.ocultar();
            _this.secretariasPmpa = secretariasPmpa;
        });
    };
    SecretariaPmpaComponent.prototype.logout = function () {
        var url = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
        this.keycloakService.getKeycloakInstance().logout({ redirectUri: url });
    };
    SecretariaPmpaComponent.prototype.mostrarDialogParaNovo = function () {
        this.secretariaPmpaEdit = new SecretariaPmpa();
        this.mostrarDialogEdit = true;
        this.isInsert = true;
        this.tituloDialogEdit = 'Nova Secretaria';
    };
    SecretariaPmpaComponent.prototype.mostrarDialogParaEditar = function (idSecretariaPmpa) {
        var secretariaPmpaParaEditar = this.secretariasPmpa.find(function (secretariaPmpa) {
            return secretariaPmpa.id === idSecretariaPmpa;
        });
        this.secretariaPmpaEdit = __assign({}, secretariaPmpaParaEditar);
        console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.secretariaPmpaEdit);
        this.mostrarDialogEdit = true;
        this.isInsert = false;
        this.tituloDialogEdit = 'Editar Secretaria';
    };
    SecretariaPmpaComponent.prototype.mostrarDialogEditParaExcluir = function (idProduto) {
        // this.produtoExcluir =  this.produtos.find(function(produto) {
        //   return produto.id === idProduto;
        // });
        // this.mostrarDialogExcluir = true;
    };
    SecretariaPmpaComponent.prototype.salvar = function () {
        var _this = this;
        //Verifica se todos os campos foram preenchidos
        if (this.secretariaPmpaEdit.secretaria == null || this.secretariaPmpaEdit.secretaria === '') {
            this.notificacoesService.notificarAviso('Por favor informar o NOME da Secretaria/Órgão!', null);
            return;
        }
        if (this.secretariaPmpaEdit.sigla == null || this.secretariaPmpaEdit.sigla === '') {
            this.notificacoesService.notificarAviso('Por favor informar a SIGLA da Secretaria/Órgão!', null);
            return;
        }
        if (this.secretariaPmpaEdit.endereco == null || this.secretariaPmpaEdit.endereco === '') {
            this.notificacoesService.notificarAviso('Por favor informar o ENDEREÇO da Secretaria/Órgão!', null);
            return;
        }
        this.spinnerService.exibir();
        console.log('salvar....................>>>>>>>>>>>>>>', this.secretariaPmpaEdit);
        // this.secretariaPmpaEdit.entidadeDO = this.entidadePMPA;
        // Se novo
        if (this.secretariaPmpaEdit.id == null) {
            this.secretariaPmpaService.criarSecretariaPmpa(this.secretariaPmpaEdit).subscribe(function (secretariaPmpaNovo) {
                console.log('salvado....................>>>>>>>>>>>>>>', secretariaPmpaNovo);
                _this.secretariasPmpa.push(secretariaPmpaNovo);
                _this.mostrarDialogEdit = false;
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarSucesso('Adicionado com sucesso', null);
            }, function (error) {
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro('Erro ao adicionar ', error);
            });
        }
        else { // Se editando
            this.secretariaPmpaService.editarSecretariaPmpa(this.secretariaPmpaEdit).subscribe(function (secretariaPmpaEditado) {
                // Remove o valor atual
                _this.secretariasPmpa = _this.secretariasPmpa.filter(function (item) { return item.id !== _this.secretariaPmpaEdit.id; });
                // Adiciona o novo
                _this.secretariasPmpa.push(secretariaPmpaEditado);
                _this.mostrarDialogEdit = false;
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarSucesso('Atualizado com sucesso', null);
            }, function (error) {
                console.log(error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    SecretariaPmpaComponent.prototype.excluir = function () {
        // this.spinnerService.exibir();
        // console.log('salvar....................>>>>>>>>>>>>>>', this.variaveisGlobais.menuRaiz);
        // this.produtoService.excluirProduto(this.produtoExcluir, this.variaveisGlobais.menuRaiz).subscribe(produto => {
        //   if (produto == null) {
        //     // Remove o valor atual
        //     this.produtos = this.produtos.filter(item => item.id !== this.produtoExcluir.id);
        //   }
        //   this.mostrarDialogExcluir = false;
        //   this.spinnerService.ocultar();
        //   this.notificacoesService.notificarSucesso('Excluído com sucesso', null);
        // },
        // error => {
        //   console.log(error);
        //   this.spinnerService.ocultar();
        //   this.notificacoesService.notificarErro('Erro ao exluir', error.error);
        // });
    };
    SecretariaPmpaComponent.prototype.checkActive = function (route) {
        return route === this.activeRoute;
    };
    SecretariaPmpaComponent.prototype.selecionaCDL = function (endereco) {
        this.localizacao = { latitude: null, longitude: null };
        if (endereco.latitude !== undefined && endereco.latitude !== null) {
            this.localizacao = {
                latitude: endereco.latitude,
                longitude: endereco.longitude
            };
            delete endereco['latitude'];
            delete endereco['longitude'];
        }
        this.secretariaPmpaEdit.endereco = endereco;
        this.secretariaPmpaEdit.endereco.localizacao = this.localizacao;
    };
    return SecretariaPmpaComponent;
}());
export { SecretariaPmpaComponent };
