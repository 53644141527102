import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'reduzNomeImagem'
})
export class ReduzNomeImagemPipe implements PipeTransform {
    transform(nomeImagem: string): string {
      console.log('ReduzNomeImagemPipe.................... >>>>', nomeImagem);

        var nomeImagemFinal: string = "";
        // console.log("ObsMatrizPipe >>>", obsMatriz)
        if (nomeImagem) {
            // console.log("ObsMatrizPipe - Tamanho>>>", obsMatriz.toString().length)
            nomeImagemFinal = nomeImagem;
            if (nomeImagem.length > 40) {
              nomeImagemFinal = nomeImagem.toString().substring(0, 40) + " ...";
            }
          }



        // console.log("HoraPipe-------------->>", horaFinal);
        // return value.substr(0, 5) + '-' + value.substr(5, 3);
        return nomeImagemFinal;
    }
}
