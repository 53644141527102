import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'reduzObs'
})
export class ReduzObservacaoPipe implements PipeTransform {
    transform(observacao: string): string {

        var observacaoFinal: string = "";
        // console.log("ObsMatrizPipe >>>", obsMatriz)
        if (observacao) {
            // console.log("ObsMatrizPipe - Tamanho>>>", obsMatriz.toString().length)
            observacaoFinal = observacao;
            if (observacao.length > 100) {
              observacaoFinal = observacao.toString().substring(0, 100) + " ...";
            }
          }



        // console.log("HoraPipe-------------->>", horaFinal);
        // return value.substr(0, 5) + '-' + value.substr(5, 3);
        return observacaoFinal;
    }
}
