<sgr-menu></sgr-menu>
<div style="width: fit-content; margin: auto;">
  <h2 class="text-primary mt-4 mb-0 pb-4" style="font-family: 'Roboto', 'Trebuchet MS', Arial, Helvetica, sans-serif">
    Contrato com o DMLU - {{entidade.razaoSocial}}
  </h2>
</div>
<div style="width: fit-content; margin: auto;">

  <div style="overflow-x: auto">
    <p-table #tabelaContratos [value]="contratos" [paginator]="true" sortField="situacao" [rows]="10"
      [globalFilterFields]="['situacao', 'numeroSei', 'dataFinal']" [style]="{ width: '1300px' }">
      <ng-template pTemplate="caption">
        <div class="ui-g ui-fluid">
          <div class="ui-g-12">
            <div class="ui-g-2">
              <button type="button" class="btn btn-primary text-nowrap" (click)="mostrarDialogParaNovo()"
                label="Adicionar" *ngIf="variaveisGlobais.roleEntidade">
                <i class="fa fa-plus pr-2"></i>Novo Contrato
              </button>
            </div>
            <div class="ui-g-10" style="text-align: right;">
              <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
              <input type="text" pInputText size="50" placeholder="Filtrar contratos"
                (input)="tabelaContratos.filterGlobal($event.target.value, 'contains')" style="width:auto" />
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="situacao" class="text-center" style="width: 280px; height: 60px;">
            Situação
            <p-sortIcon field="situacao"></p-sortIcon>
          </th>
          <th pSortableColumn="dataFinal" class="text-center" style="width: 100px; height: 60px;">
            Validade
            <p-sortIcon field="dataFinal"></p-sortIcon>
          </th>
          <th pSortableColumn="numeroSei" class="text-center" style="width: 120px; height: 60px;">
            Nº SEI
            <p-sortIcon field="numeroSei"></p-sortIcon>
          </th>
          <th class="text-center" style="width: 90px; height: 60px;">
            Contrato
          </th>
          <th class="text-center" style="width: 100px; height: 70px;">
            Documento
          </th>
          <th style="width: 300px;" class="text-center">
            Ações
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-contrato>
        <tr>
          <td>{{ contrato?.situacao }}</td>
          <td>
            {{ contrato?.dataFinal | date: 'dd/MM/yyyy' : 'pt'}}
          </td>
          <td>
            {{ contrato?.numeroSei}}
          </td>
          <td><span *ngIf="contrato.numeroContrato !== null">
              {{ contrato?.numeroContrato }}/{{ contrato?.anoContrato }}</span>
          </td>
          <td>
            {{ contrato?.numeroDocumentoSei}}
          </td>
          <td>
            <button title="Editar" (click)="mostrarDialogParaEditar(contrato.id)" label="Detalhes"
              class="btn btn-outline-secondary mx-2"><i class="fa fa-info pr-2"></i>Detalhes
            </button>
            <button title="Alterar Resíduos" (click)="alterarResiduos(contrato.id)" label="Alterar Resíduos"
              *ngIf="contrato.situacao==='Contrato vigente' && variaveisGlobais.roleEntidade"
              class="btn btn-outline-secondary mx-2"><i class="fa fa-info pr-2"></i>Alterar Resíduos
            </button>
            <button title="Verificação de Regularidade - SAC"
              (click)="editarContratoTramitacao(contrato.id, 'analiseSAC')" label="Verificação de Regularidade - SAC"
              class="btn btn-outline-secondary mx-2" *ngIf="(contrato.situacao==='Cadastro encaminhado para verificação de regularidade no SAC' ||
              contrato.situacao==='Cadastro reencaminhado para verificação de regularidade no SAC')
                && !variaveisGlobais.roleEntidade">
              <i class="fa fa-file-text-o pr-2"></i>Verificação de Regularidade
            </button>
            <button title="Aguardando Regularização" (click)="editarContratoTramitacao(contrato.id, 'infRegularidade')"
              label="Aguardando Regularização" class="btn btn-outline-secondary mx-2" *ngIf="contrato.situacao==='Cadastro aguardando regularização da dívida'
                && variaveisGlobais.roleEntidade">
              <i class="fa fa-file-text-o pr-2"></i>Encaminhar Regularidade
            </button>
            <button title="Análise Técnica SMCA" (click)="editarContratoTramitacao(contrato.id, 'analiseSMCA')"
              label="Análise Técnica SMCA" class="btn btn-outline-secondary mx-2" *ngIf="(contrato.situacao==='Cadastro encaminhado para análise técnica' ||
              contrato.situacao==='Cadastro reencaminhado para análise técnica') && !variaveisGlobais.roleEntidade">
              <i class="fa fa-file-text-o pr-2"></i>Análise Técnica
            </button>
            <button title="Análise Isenção de Tarifa"
              (click)="editarContratoTramitacao(contrato.id, 'analiseIsencaoTarifa')" label="Análise Isenção de Tarifa"
              class="btn btn-outline-secondary mx-2"
              *ngIf="(contrato.situacao==='Encaminhado para o Diretor Geral') && !variaveisGlobais.roleEntidade">
              <i class="fa fa-file-text-o pr-2"></i>Análise Isenção de Tarifa
            </button>
            <button title="Aguarda Vistoria" (click)="editarContratoTramitacao(contrato.id, 'analiseSMCA')"
              label="Aguarda Vistoria" class="btn btn-outline-secondary mx-2"
              *ngIf="contrato.situacao==='Aguardando vistoria técnica' && !variaveisGlobais.roleEntidade">
              <i class="fa fa-file-text-o pr-2"></i>Vistoria Realizada
            </button>
            <button title="Aguarda Informações Complementares"
              (click)="editarContratoTramitacao(contrato.id, 'complementar')" label="Aguarda Informações Complementares"
              class="btn btn-outline-secondary mx-2"
              *ngIf="contrato.situacao==='Aguardando avaliação técnica complementar' && variaveisGlobais.roleEntidade">
              <i class="fa fa-file-text-o pr-2"></i>Enviar Informações
            </button>
            <button title="Análise Documental NCC" (click)="editarContratoTramitacao(contrato.id, 'analiseNCC')"
              label="Análise Documental NCC" class="btn btn-outline-secondary mx-2" *ngIf="(contrato.situacao==='Encaminhado para análise documental' ||
              contrato.situacao==='Reencaminhado para análise documental') && !variaveisGlobais.roleEntidade">
              <i class="fa fa-file-text-o pr-2"></i>Análise Documental
            </button>
            <button title="Aguarda Informações Complementares"
              (click)="editarContratoTramitacao(contrato.id, 'ncccomplementar')"
              label="Aguarda Informações Complementares" class="btn btn-outline-secondary mx-2"
              *ngIf="contrato.situacao==='Aguardando complementação de documentação' && variaveisGlobais.roleEntidade">
              <i class="fa fa-file-text-o pr-2"></i>Enviar Informações
            </button>
            <!-- <button title="Assinatura do Contrato" (click)="editarContratoTramitacao(contrato.id, 'assinaturaEmpresa')"
              label="Assinatura do Contrato" class="btn btn-outline-secondary mx-2"
              *ngIf="contrato.situacao===('Contrato disponível para assinatura do Gerador' || 'Aguarda liberação de assinatura eletrônica do Gerador no sistema SEI')
                 && variaveisGlobais.roleEntidade"><i class="fa fa-file-text-o pr-2"></i>Assinatura contrato
            </button>
            <button title="Assinatura do Contrato" (click)="editarContratoTramitacao(contrato.id, 'assinaturaPmpa')"
              label="Assinatura do Contrato" class="btn btn-outline-secondary mx-2"
              *ngIf="contrato.situacao==='Contrato assinado pelo Gerador' && !variaveisGlobais.roleEntidade">
              <i class="fa fa-file-text-o pr-2"></i>Assinatura contrato
            </button> -->







            <!-- <button title="Contrato Assinatura" (click)="editarContratoTramitacao(contrato.id, 'assinaturaUsuario')"
              label="Contrato Assinatura" class="btn btn-outline-secondary mx-2"
              *ngIf="contrato.situacao==='Pendente com usuário - assinar contrato' && variaveisGlobais.roleEntidade">
              <i class="fa fa-pencil pr-2"></i>Contrato Assinado?
            </button>
            <button title="Contrato Assinatura" (click)="editarContratoTramitacao(contrato.id, 'assinaturaNCC')"
              label="Contrato Assinatura" class="btn btn-outline-secondary mx-2"
              *ngIf="contrato.situacao==='Pendente com NCC - assinatura do SAF' && !variaveisGlobais.roleEntidade">
              <i class="fa fa-pencil pr-2"></i>Contrato Assinado?
            </button> -->
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-dialog [header]="tituloDialogEdit" [(visible)]="mostrarDialogEdit" [responsive]="true" showEffect="fade"
  [modal]="true" [width]="1300" [contentStyle]="{'overflow-y': 'auto', 'overflow-x': 'hidden',
  'max-height': '80vh','min-height':'250px'}">

  <div class="container">

    <!-- <h2 class="text-primary mt-4 mb-0">Contrato com o DMLU</h2> -->
    <button (click)="abaEmpresa = true; abaAssinaturaSei = false; abaResiduo = false; abaInfos = false;
      abaIsento = false; abaFinalizar = false; abaTramitacao = false; abaVigente = false" type="button"
      class="btn btn-outline-secondary">Empresa</button>&nbsp;&nbsp;&nbsp;
    <button (click)="abaEmpresa = false; abaAssinaturaSei = false; abaResiduo = true; abaInfos = false;
      abaIsento = false; abaFinalizar = false; abaTramitacao = false; abaVigente = false" type="button"
      class="btn btn-outline-secondary">Resíduos</button>&nbsp;&nbsp;&nbsp;
    <!-- <button (click)="abaEmpresa = false; abaAssinatura = false; abaResiduo = false; abaInfos = true; abaFinalizar = false"
      type="button" class="btn btn-outline-secondary">Informações</button>&nbsp;&nbsp;&nbsp; -->
    <!-- <button (click)="abaEmpresa = false; abaAssinaturaSei = false; abaResiduo = false; abaInfos = false;
      abaIsento = true; abaFinalizar = false; abaTramitacao = false; abaVigente = false" *ngIf="insertMode"
      type="button" class="btn btn-outline-secondary">Isenção</button>&nbsp;&nbsp;&nbsp; -->
    <button (click)="abaEmpresa = false; abaAssinaturaSei = true; abaResiduo = false; abaInfos = false;
      abaIsento = false; abaFinalizar = false; abaTramitacao = false; abaVigente = false"
      *ngIf="!insertMode && contrato?.situacao==='Contrato aguardando assinaturas digitais no SEI'" type="button"
      class="btn btn-outline-secondary">
      Pendente Assinatura Digital</button>&nbsp;&nbsp;&nbsp;
    <button (click)="abaEmpresa = false; abaAssinaturaSei = false; abaResiduo = false; abaInfos = false;
      abaIsento = false; abaFinalizar = true; abaTramitacao = false; abaVigente = false" *ngIf="insertMode"
      type="button" class="btn btn-outline-secondary">Finalizar</button>
    <!-- <button (click)="abaEmpresa = false; abaAssinatura = false; abaResiduo = false; abaInfos = false;
      abaFinalizar = false; abaTramitacao = true; abaVigente = false"
      *ngIf="!insertMode && contrato.situacao!=='Contrato vigente'"
      type="button" class="btn btn-outline-secondary">Em Tramitação</button> -->
    <button (click)="abaEmpresa = false; abaAssinaturaSei = false; abaResiduo = false; abaInfos = false;
      abaIsento = false; abaFinalizar = false; abaTramitacao = false; abaVigente = true"
      *ngIf="!insertMode && contrato.situacao==='Contrato vigente'" type="button"
      class="btn btn-outline-secondary">Contrato Vigente</button>

    <div *ngIf="abaEmpresa">
      <div class="card text-black info04">
        <div class="card-header">
          <font size='5'><strong>Informações da Empresa</strong></font>
        </div>
        <div class="card-body">
          <div class="p-grid">
            <div class="p-col-12">
              <div class="p-grid">
                <div class="p-col">
                  <label>Empresa:&nbsp;&nbsp;&nbsp;</label><b>{{entidade?.razaoSocial}}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label>Email:&nbsp;&nbsp;&nbsp;</label><b>{{entidade?.email}}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label>Fone:&nbsp;&nbsp;&nbsp;</label><b>{{entidade?.telefone}}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
                  <label *ngIf="entidade?.codigoDmlu !== null">Código Balança:&nbsp;&nbsp;&nbsp;</label>
                  <span
                    *ngIf="entidade?.codigoDmlu !== null"><b>{{entidade?.codigoDmlu}}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <label *ngIf="contrato?.numeroContrato !== null && !insertMode">Número do
                    Contrato:&nbsp;&nbsp;&nbsp;</label>
                  <span *ngIf="contrato?.numeroContrato !== null && !insertMode"><b>{{contrato?.numeroContrato + '/'
                      +
                      contrato?.anoContrato}}</b></span>
                </div>
              </div>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-12">
              <div class="p-grid">
                <div class="p-col">
                  <label>Anexos:</label>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col" *ngIf="isCpfRg">
                  <label>CPF/RG:&nbsp;&nbsp;&nbsp;</label><b>
                    <a class="link-anexo" style="cursor:pointer" (click)="visualizaAnexo('cpfRg');">{{cpfRg}}</a></b>
                </div>
                <div class="p-col" *ngIf="!isCpfRg">
                  <label>CPF/RG:&nbsp;&nbsp;&nbsp;</label><b>
                    <font color="red">{{cpfRg}}</font>
                  </b>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col" *ngIf="isContratoSocialEstatuto">
                  <label>Contrato Social ou Estatuto:&nbsp;&nbsp;&nbsp;</label><b>
                    <a class="link-anexo" style="cursor:pointer"
                      (click)="visualizaAnexo('contratoEstatuto');">{{contratoSocialEstatuto}}</a></b>
                </div>
                <div class="p-col" *ngIf="!isContratoSocialEstatuto">
                  <label>Contrato Social ou Estatuto:&nbsp;&nbsp;&nbsp;</label><b>
                    <font color="red">{{contratoSocialEstatuto}}</font>
                  </b>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col" *ngIf="isProcuracao">
                  <label>Procuração de Representação:&nbsp;&nbsp;&nbsp;</label><b>
                    <a class="link-anexo" style="cursor:pointer"
                      (click)="visualizaAnexo('procuracao');">{{procuracao}}</a></b>
                </div>
                <div class="p-col" *ngIf="!isProcuracao">
                  <label>Procuração de Representação:&nbsp;&nbsp;&nbsp;</label><b>{{procuracao}}</b>
                </div>
              </div>
            </div>
          </div>
          <div class="p-grid">
            <label>Endereço</label>
            <p-table [value]="enderecosEntidade">

              <ng-template pTemplate="body" let-endereco>
                <tr>
                  <td style="width: 0px;">
                    <p-radioButton name="enderecoSel" value="{{endereco.id}}" label="" [(ngModel)]="enderecoSel"
                      (onClick)="enderecoSelecionado(endereco.id)"></p-radioButton>
                  </td>
                  <td style="width: 300px; padding-left: 38px;">
                    {{endereco.enderecoFormatado}}
                  </td>
                  <td style="width: 100px;">
                    {{endereco.municipio}}
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <br>
          </div>
          <div class="p-grid">
            <label>Responsável</label>
            <p-table [value]="responsaveisEntidade">

              <ng-template pTemplate="body" let-pessoaFisica>
                <tr>
                  <td style="width: 0px;">
                    <p-radioButton name="responsavelSel" value="{{pessoaFisica.id}}" label=""
                      [(ngModel)]="responsavelSel" (onClick)="responsavelSelecionado(pessoaFisica.id)"></p-radioButton>
                  </td>
                  <td style="width: 300px; padding-left: 38px;">
                    {{pessoaFisica.email}}
                  </td>
                  <td style="width: 100px;">
                    {{pessoaFisica.telefone}}
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <br>
          </div>
        </div>
      </div>
    </div>


    <div *ngIf="abaResiduo">
      <br>
      <div class="ui-g-12" *ngIf="contrato.id === null">
        <label for="vin">
          <font size='5'><strong>Favor selecionar nas listas abaixo os resíduos que serão descartados pela
              empresa.</strong></font>
        </label>
        <br><br>
        <label for="vin"><strong>Caso não encontre o resíduo na lista abaixo, clique no botão ao lado e comunique ao
            DMLU a necessidade de enviar um tipo diferente de resíduo:</strong></label>
        <!-- <label for="vin"><strong>Caso não encontres o resíduo na lista abaixo, favor especificar:</strong></label> -->
        <!-- <textarea pInputTextarea id="obsResiduos" [(ngModel)]="contrato.outrosResiduos" [rows]="3"
          [cols]="130" *ngIf="contrato.id === null"></textarea>
        <span *ngIf="contrato.id !== null">{{contrato?.outrosResiduos}}<br></span> -->
        <button type="button" (click)="novosTiposResiduos()" label="Novo Tipo de Resíduo" class="btn btn-primary"
          *ngIf="variaveisGlobais.roleEntidade" style="margin-left: 5px;">Novo Tipo de Resíduo</button>

      </div>
      <br>
      <div class="ui-g-12">
        <label for="vin"><strong>Relação dos resíduos possíveis de serem descartados em cada local</strong></label>
      </div>
      <div class="card text-black info04">
        <div class="card-header">
          <font size='5'><strong>Estação de Transbordo</strong></font>
        </div>
        <div class="card-body">
          <div class="p-grid" *ngFor="let residuoTb of residuosTransbordo; let i = index">
            <div class="p-col-12">
              <p-checkbox name="residuosTransbordo" value="{{residuoTb.id}}" label="{{residuoTb.nome}}"
                [(ngModel)]="residuosSelTb" [(binary)]="residuoTb.selecionado"
                (onChange)="atualizaSelecaoResiduo(residuoTb.id, $event, 'transbordo')"></p-checkbox>
            </div>
          </div>
        </div>
      </div>


      <div class="card text-black info04">
        <div class="card-header">
          <font size='5'><strong>Unidade de Compostagem</strong></font>
        </div>
        <div class="card-body">
          <div class="p-grid" *ngFor="let residuoCp of residuosCompostagem; let i = index">
            <div class="p-col-12">

              <p-checkbox name="residuosCompostagem" value="{{residuoCp.id}}" label="{{residuoCp.nome}}"
                [(ngModel)]="residuosSelCp" [binary]="residuoCp.selecionado"
                (onChange)="atualizaSelecaoResiduo(residuoCp.id, $event, 'compostagem')"></p-checkbox>

            </div>
          </div>
        </div>
      </div>

      <div class="card text-black info04">
        <div class="card-header">
          <font size='5'><strong>Unidade de Triagem</strong></font>
        </div>
        <div class="card-body">
          <div class="p-grid" *ngFor="let residuoTg of residuosTriagem; let i = index">
            <div class="p-col-12">
              <p-checkbox name="residuosTriagem" value="{{residuoTg.id}}" label="{{residuoTg.nome}}"
                [(ngModel)]="residuosSelTg" [binary]="residuoTg.selecionado"
                (onChange)="atualizaSelecaoResiduo(residuoTg.id, $event, 'triagem')"></p-checkbox>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="abaIsento && insertMode">

      <div class="card text-black info04">
        <div class="card-header">
          <font size='5'><strong>Solicitação de isenção de tarifa</strong></font>
        </div>
        <div class="card-body">
          <div class="p-grid">
            <div class="p-col-12">

              <b>Por favor leia com atenção.</b><br>
              <br><br>




              <br><br>
              <p-checkbox name="empresaIsenta" value="{{contrato.isencaoTarifa}}" label="Solicitar isenção de tarifa"
                [binary]="contrato.isencaoTarifa" [(ngModel)]="empresaIsentaTarifa"
                (onChange)="atualizaIsencao($event)"></p-checkbox>

              <!-- <p-checkbox name="residuosTriagem" value="{{residuoTg.id}}" label="{{residuoTg.nome}}"
              [(ngModel)]="residuosSelTg" [binary]="residuoTg.selecionado"
              (onChange)="atualizaSelecaoResiduo(residuoTg.id, $event, 'triagem')"></p-checkbox> -->

            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="abaFinalizar && insertMode">

      <div class="card text-black info04">
        <div class="card-header">
          <font size='5'><strong>Enviar contrato ao DMLU</strong></font>
        </div>
        <div class="card-body">
          <div class="p-grid">
            <div class="p-col-12">
              Por favor leia com atenção.<br> <b>Informações importantes para a efetivação do contrato.</b>
            </div>
          </div>
          <div class="p-grid" *ngIf="!empresaIsentaTarifa">
            <div class="p-col-12">
              O valor atual/total de cada descarga de resíduos é de <b>{{valorUFM * 35 | currency:'R$
                ':'symbol':'3.2-2'}}</b> por tonelada
              (35 UFM), sendo que o valor atual da UFM é de: <b>R$ {{valorUFM}}</b>. Os resíduos somente poderão ser
              descartados mediante assinatura prévia do contrato de prestação de serviços.
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-12">
              Toda carga de resíduos apresentada às unidades do DMLU deverá vir acompanhada de Certificado de
              Identificação e de MTR da FEPAM. Caso o gerador seja desobrigado a utilizar MTR FEPAM a carga deverá estar
              acompanhada de termo de isenção de MTR assinado.
              <br>
              As unidades operacionais da Lomba do Pinheiro não estão autorizadas a receber MTRCC.
              <br><br>
              O Certificado de Identificação, com a liberação do acesso à Estação de Transbordo, poderá ser emitido
              somente após a assinatura do contrato.

              <br><br>
              <button type="button" (click)="salvar()" label="Enviar" class="btn btn-primary"
                *ngIf="variaveisGlobais.roleEntidade" style="margin-left: 5px;">
                Enviar
              </button>

            </div>
          </div>

        </div>
      </div>
    </div>

    <div *ngIf="abaAssinaturaSei && !insertMode">

      <div class="card text-black info04">
        <div class="card-header">
          <font size='5'><strong>Contrato aguardando assinaturas digitais no SEI</strong></font>
        </div>
        <div class="card-body">
          <div class="p-grid">
            <div class="p-col-12">
              <div class="p-grid">
                <div class="p-col-12">
                  Esse contrato deverá ser assinado digitalmente através do Sistema SEI - Sistema Eletrônico de
                  Informação.<br><br>
                  Para isso, proceda conforme as orientações a seguir: <br><br>
                  <pre>
  PARA CADASTRAMENTO SEI PMPA (caso não possua login ativo no sistema SEI):
  1) Sistema Eletrônico de Informações (SEI) da Prefeitura de Porto Alegre - <a href="http://sei.procempa.com.br/usuario_externo">Clique aqui!</a>
  2) Acessar o link: “Clique aqui se você ainda não está cadastrado”;
  3) Preencher os dados solicitados;
  4) Em caso de dúvidas, entrar em contato com COORDENAÇÃO DA DOCUMENTAÇÃO/SMPG pelo e-mail cd-sei@portoalegre.rs.gov.br
     ou pelo telefone (51) 3289-1236.

  OBSERVAÇÕES:
  1) Caso já possua login ativo no sistema SEI, por favor desconsiderar essa mensagem.
  2) Para solicitar a alteração do representante legal, o responsável deve indicar o nome completo e incluir a
     documentação que comprove os poderes para firmar o instrumento.
  3) O contrato só será liberado para visualização, bem como assinatura, quando o login no SEI PMPA já estiver ativo.
     Assim, solicitamos que aguarde o recebimento de um e-mail informando a disponibilização do documento.
  4) O SEI é o sistema oficial de tramitação de processos eletrônicos administrativos e informações em meio digital
     do Município de Porto Alegre, regulamentado pelo Decreto Municipal nº 18.916/2015.
          </pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="abaVigente && !insertMode">

      <div class="card text-black info04">
        <div class="card-header">
          <font size='5'><strong>Contrato Vigente</strong></font>
        </div>
        <div class="card-body">
          <div class="p-grid">
            <div class="p-col-12">
              <div class="p-grid">
                <div class="p-col-12">

                  <strong>Data de validade do contrato:</strong><br>
                  De &nbsp; &nbsp; &nbsp; {{ contrato?.dataInicial | date: 'dd/MM/yyyy' : 'pt'}} &nbsp;&nbsp;&nbsp;
                  a&nbsp;&nbsp;&nbsp;{{ contrato?.dataFinal | date: 'dd/MM/yyyy' : 'pt'}}
                  <br><br>
                  Mais informações ......
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>

    <!-- <div *ngIf="abaAssinatura && insertMode">

      <div class="card text-black info04">
        <div class="card-header">
          <font size='5'><strong>Assinatura Digital - SEI</strong></font>
        </div>
        <div class="card-body">
          <div class="p-grid">
            <div class="p-col-12">
              <div class="p-grid">
                <div class="p-col-12">
                  Esse contrato deverá ser assinado digitalmente através do Sistema SEI - Sistema Eletrônico de Informação.<br>
                  Para isso, proceda conforme as orientações a seguir: <br><br>

                  1. Caso a Empresa ainda não tenha cadastro no SEI.<br>
<pre>
  Para cadastrar-se no SEI, clique <a href="http://sei.procempa.com.br/usuario_externo">AQUI,</a> use a opção “Clique aqui se você ainda não está cadastrado” e
  siga as orientações detalhadas no <a href="../../../assets/doc/MANUAL_DO_USUÁRIO_EXTERNO_SEI.pdf">"Manual do Usuário Externo do SEI".</a>
  Em caso de dúvidas sobre a documentação a ser apresentada para liberação do login de acesso ao SEI,
  entrar em contato com a Coordenação da Documentação/SMPG:  e-mail: cd-sei@portoalegre.rs.gov.br - telefone: (51) 3289-1236.
</pre>

                  2. Caso a Empresa já tenha cadastro no SEI.<br>
<pre>
  Para efetivar a assinatura desse contrato com o DMLU, providencie as informações aqui solicitadas e encaminhe
  o mesmo para o Núcleo de Contratos e Convênios - NCC clicando no botão "Enviar" que está na aba "Finalizar" acima.
  Posteriormente o NCC, responsável pela conferência da documentação, enviará por email as informações
  referentes a assinatura digital do contrato.
  Em caso de dúvidas quanto à necessidade ou momento da assinatura do contrato, contatar NCC pelo telefone (51) 3289-6841
</pre>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div> -->
    <!-- <div *ngIf="abaInfos">
      <div class="card text-black info05">
        <div class="card-header">
          <font size='5'><strong>Informações sobre o contrato</strong></font>
        </div>
        <div class="card-body">

        </div>
      </div>
    </div> -->


    <!-- <div *ngIf="abaTramitacao && !insertMode">

      <div class="card text-black info04">
        <div class="card-header">
          <font size='5'><strong>Informações sobre a tramitação do contrato</strong></font>
        </div>
        <div class="card-body">
          <div class="p-grid">
            <div class="p-col-12">
              <div class="p-grid">
                <div class="p-col-12" *ngIf="contrato.situacao === 'Pendente com NCC - elaboração do contrato'">

<strong>Pendente com NCC - elaboração do contrato</strong><br><br>
Nesse momento o contrato está no NCC - Núcleo de Contratos e Convênios do DMLU para análise da documentação.
<br><br>
<span *ngIf="contrato.obsPendencia !== null">
  Contrato retornando para elaboração após solução de pendências anteriores.
  <br> <br>
  Observações:<br>
  <ul>
    <li *ngFor="let pendencia of obsPendencia">
      {{pendencia}}
    </li>
  </ul>
</span>
  <br><br>

Assim que finalizar essa etapa o NCC enviará ...
                </div>
                <div class="p-col-12" *ngIf="contrato.situacao === 'Pendente com usuário - assinar contrato'">

<strong>Contrato pendente com usuário - assinar contrato</strong><br><br>
Nesse momento o contrato já elaborado pelo NCC - Núcleo de Contratos e Convênios do DMLU está na pedência de assinatura pelo usuário.
<br><br>

Assim que usuário assinar será enviado ...
                </div>
                <div class="p-col-12" *ngIf="contrato.situacao === 'Pendente com NCC - assinatura do SAF'">

<strong>Contrato pendente com NCC - assinatura do SAF</strong><br><br>
Nesse momento o contrato já foi assinado pela Empresa e está na pedência de assinatura pelo NCC.
<br><br>

Assim que o NCC assinar será enviado ...
                </div>
                <div class="p-col-12" *ngIf="contrato.situacao === 'Pendente com usuário - documentos e/ou SEI'">

<strong>Contrato pendente com usuário - documentos e/ou SEI</strong><br><br>
<span *ngIf="contrato.obsPendencia === null">
  Corrija o problema com a documentação.<br>
  Havendo necessidade de sanar alguma dúvida entre em contato com NCC pelo telefone (51) 3289-6841.
</span>
<span *ngIf="contrato.obsPendencia !== null">
  Corrija o problema com a documentação considerando as observações do NCC ou entre em contato com o mesmo pelo telefone (51) 3289-6841.
  <br> <br>
  Observações:<br>
  <ul>
    <li *ngFor="let pendencia of obsPendencia">
      {{pendencia}}
    </li>
  </ul>
</span>
  <br><br>

Assim que o NCC assinar será enviado ...
                </div>




              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->



  </div>
</p-dialog>

<p-dialog header="Cadastro aguardando verificação de regularidade." [(visible)]="contratoAnaliseSAC" [responsive]="true"
  showEffect="fade" [modal]="true" resizable="true" [contentStyle]="{'max-height':'800px'}" [width]="750">
  <div class="ui-g">
    <div class="ui-g-12">
      Caso a Empresa esteja inadimplente, favor anexar o Relatório da Dívida. <br>
      Para isso basta acessar os detalhes da Empresa (na Lista de Empresas) e incluir o relatório na aba Anexos.<br>
    </div>
    <div class="ui-g-12" *ngIf="!variaveisGlobais.roleEntidade" style="text-align: left;">
      <p-radioButton name="group1" value="adimplente" label="Empresa Adimplente" [(ngModel)]="adimplenteInadimplente"
        inputId="cta"></p-radioButton>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <p-radioButton name="group1" value="inadimplente" label="Empresa Inadimplente"
        [(ngModel)]="adimplenteInadimplente" inputId="atc"></p-radioButton>
      <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <button type="button" class="btn btn-primary text-nowrap" (click)="empresaAdimplenteInadimplente()"
        label="Atualizar Empresa"><i class="fa fa-check pr-2"></i>Atualiza Adimplência
        </button> -->
    </div>
    <div class="ui-g-12">
      <label for="vin">Oservações</label>
      <br>
      <textarea pInputTextarea id="obsSacAnalise" [(ngModel)]="contrato.obsSacAnalise" [rows]="3"
        [cols]="80"></textarea>
    </div>
  </div>
  <div style="text-align: right;">
    <button type="button" class="btn btn-primary" (click)="contratoAndamentoPmpa('contratoAnaliseSAC')">
      <i class="fa fa-check" aria-hidden="true">Enviar</i></button>
  </div>
</p-dialog>

<p-dialog header="Cadastro aguardando regularização da dívida." [(visible)]="contratoInfDivida" [responsive]="true"
  showEffect="fade" [modal]="true" resizable="true" [contentStyle]="{'max-height':'800px'}" [width]="750">
  <div class="ui-g">
    <div class="ui-g-12">
      Após o pagamento da dívida em questão, favor anexar o Boleto do pagamento. <br>
      Para isso basta acessar os detalhes da Empresa (na Lista de Empresas) e incluir o relatório na aba Anexos.<br>
      Após anexar o Boleto e reenviar o Contrato, o DMLU terá até <b>2 dias úteis</b> para avaliar e dar o
      encaminhamento devido.<br>
    </div>
    <div class="ui-g-12">
      <label for="vin">Oservações</label>
      <br>
      <textarea pInputTextarea id="obsRegularidadeDivida" [(ngModel)]="contrato.obsRegularidadeDivida" [rows]="3"
        [cols]="80"></textarea>
    </div>
  </div>
  <div style="text-align: right;">
    <button type="button" class="btn btn-primary" (click)="contratoAndamentoEmpresa('contratoInfDivida')">
      <i class="fa fa-check" aria-hidden="true">Enviar</i></button>
  </div>
</p-dialog>

<p-dialog [header]="tituloDialogEdit" [(visible)]="contratoAnaliseSMCA" [responsive]="true" showEffect="fade"
  [modal]="true" resizable="true" [contentStyle]="{'max-height':'800px'}" [width]="950">
  <div class="ui-g">
    <div class="ui-g-12">
      <label for="vin">Selecione o resultado da análise técnica realizada.</label><br>
      <div class="ui-g-6">
        <p-radioButton name="group1" value="Aprovado" label="Cadastro técnico aprovado" [(ngModel)]="selectedSituacao"
          inputId="cta"></p-radioButton>
      </div>
      <div class="ui-g-6" *ngIf="contrato.situacao !== 'Aguardando vistoria técnica'">
        <p-radioButton name="group1" value="Complemento" label="Aguardando avaliação técnica complementar"
          [(ngModel)]="selectedSituacao" inputId="atc"></p-radioButton>
      </div>
      <div class="ui-g-6" *ngIf="contrato.situacao !== 'Aguardando vistoria técnica'">
        <p-radioButton name="group1" value="Vistoria" label="Necessita Vistoria Técnica" [(ngModel)]="selectedSituacao"
          inputId="nvt"></p-radioButton>
      </div>
      <div class="ui-g-6">
        <p-radioButton name="group1" value="Reprovado" label="Cadastro Técnico Reprovado" [(ngModel)]="selectedSituacao"
          inputId="ctr"></p-radioButton>
      </div>
    </div>
    <div *ngIf="selectedSituacao === 'Aprovado'">
      <div class="ui-g-12" *ngIf="!codigoDmlu">
        <label for="vin">Informe o Código da Balança se existir ou Gere Novo Código</label>
        <br>
        <input pInputText id="codigoBalanca" [(ngModel)]="entidade.codigoDmlu" maxlength="15" />
        <button title="Editar" (click)="gerarNovoCodigoDmlu(entidade.id)" label="Gerar Novo Código"
          class="btn btn-outline-secondary mx-2">Gerar Novo Código
        </button>
      </div>
      <div class="ui-g-12" *ngIf="codigoDmlu">
        <label for="vin">Código da Balança:&nbsp;&nbsp;&nbsp;</label><b>{{entidade.codigoDmlu}}</b>
      </div>
    </div>
    <div *ngIf="selectedSituacao === 'Complemento'">
      <div class="ui-g-12">
        <label for="vin">Oservações complementares</label>
        <br>
        <textarea pInputTextarea id="obsComplementar" [(ngModel)]="contrato.obsSmcaComplementar" [rows]="3"
          [cols]="100"></textarea>
      </div>
    </div>
    <div *ngIf="selectedSituacao === 'Vistoria'">
      <div class="ui-g-12">
        <label for="vin">Informe a data e hora sugeridas para a realização da vistoria.</label>
        <br>
        <p-calendar class="col-sm-3 p0" [(ngModel)]="dataVistoria" [showIcon]="true" dateFormat="dd/mm/yy"
          [style]="{'width':'50%'}" [inputStyle]="{'width':'50%'}" [showIcon]="true" [locale]="languageCalendar">
        </p-calendar>
        <p-calendar [(ngModel)]="horaVistoria" timeOnly="true" showTime="true" hourFormat="24" [utc]="true"
          [style]="{'width':'40%', 'align' : 'left'}" [inputStyle]="{'width':'40%'}" [locale]="languageCalendar">
        </p-calendar>
      </div>
      <div class="ui-g-12">
        <label for="vin">Oservações complementares</label>
        <br>
        <textarea pInputTextarea id="obsVistotia" [(ngModel)]="contrato.obsSmcaVistoria" [rows]="3"
          [cols]="100"></textarea>
      </div>
    </div>
    <div *ngIf="selectedSituacao === 'Reprovado'">
      <div class="ui-g-12" style="text-align: center">
        <label for="vin"><b>Cadastro Técnico Reprovado</b></label>
      </div>
      <div class="ui-g-12">
        <label for="vin">Oservações</label>
        <br>
        <textarea pInputTextarea id="obsReprovado" [(ngModel)]="contrato.obsReprovado" [rows]="3"
          [cols]="100"></textarea>
      </div>
    </div>
    <div *ngIf="contrato.situacao === 'Aguardando vistoria técnica'" style="text-align: left">
      <div class="ui-g-12">
        <br><br>
        <label for="vin">Data e hora da vistoria:&nbsp;&nbsp;&nbsp;</label><b>
          {{contrato?.dataVistoria | date: 'dd/MM/yyyy' : 'pt'}}
          {{contrato?.dataVistoria | date: 'hh:mm' : 'pt'}}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <br>
      </div>
      <div class="ui-g-12">
        <label for="vin">Observações da vistoria:&nbsp;&nbsp;&nbsp;</label><b>
          {{contrato.obsSmcaVistoria}}</b>
      </div>
    </div>
  </div>
  <div style="text-align: right;">
    <button type="button" class="btn btn-primary" (click)="contratoAndamentoPmpa('contratoAnaliseSMCA')">
      <i class="fa fa-check" aria-hidden="true"> Enviar</i></button>&nbsp;&nbsp;&nbsp;
  </div>
</p-dialog>

<p-dialog header="Contrato aguardando informação técnica complementar." [(visible)]="contratoInfComplementar"
  [responsive]="true" showEffect="fade" [modal]="true" resizable="true" [contentStyle]="{'max-height':'800px'}"
  [width]="750">
  <div class="ui-g">
    <div class="ui-g-12">
      Para aprovação de sua solicitação de contrato será necessário o fornecimento de informações técnicas
      complementares descritas abaixo, solicitadas pela SMCA (Seção de Monitoramento e Controle Ambiental) do DMLU. <br>
    </div>
    <div class="ui-g-12">
      <label for="vin">Informações complementares solicitadas:</label>
      <br>
      <b>{{contrato.obsSmcaComplementar}}</b>
    </div>
    <div class="ui-g-12">
      <label for="vin">Oservações</label>
      <br>
      <textarea pInputTextarea id="obsSmcaComplementada" [(ngModel)]="contrato.obsSmcaComplementada" [rows]="3"
        [cols]="80"></textarea>
    </div>
    <div class="ui-g-12">
      Favor providenciar as informações solicitadas e após reenviar solicitação de contrato.<br>
    </div>
  </div>
  <div style="text-align: right;">
    <button type="button" class="btn btn-primary" (click)="contratoAndamentoEmpresa('contratoInfComplementar')">
      <i class="fa fa-check" aria-hidden="true">Reenviar Contrato</i></button>
  </div>
</p-dialog>

<p-dialog header="Contrato aguardando informação documental complementar." [(visible)]="contratoNccInfComplementar"
  [responsive]="true" showEffect="fade" [modal]="true" resizable="true" [contentStyle]="{'max-height':'800px'}"
  [width]="750">
  <div class="ui-g">
    <div class="ui-g-12">
      Para aprovação de sua solicitação de contrato será necessário o fornecimento de informações documentais
      complementares descritas abaixo, solicitadas pela NCC (Núcleo de Contratos e Convênios) do DMLU. <br>
    </div>
    <div class="ui-g-12">
      <label for="vin">Informações complementares solicitadas:</label>
      <br>
      <b>{{contrato.obsNccComplementar}}</b>
    </div>
    <div class="ui-g-12">
      <label for="vin">Oservações</label>
      <br>
      <textarea pInputTextarea id="obsNccComplementada" [(ngModel)]="contrato.obsNccComplementada" [rows]="3"
        [cols]="80"></textarea>
    </div>
    <div class="ui-g-12">
      Favor providenciar as informações solicitadas e após reenviar solicitação de contrato.<br>
    </div>
  </div>
  <div style="text-align: right;">
    <button type="button" class="btn btn-primary" (click)="contratoAndamentoEmpresa('contratoNccInfComplementar')">
      <i class="fa fa-check" aria-hidden="true">Reenviar Contrato</i></button>
  </div>
</p-dialog>

<p-dialog [header]="tituloDialogEdit" [(visible)]="contratoAnaliseNCC" [responsive]="true" showEffect="fade"
  [modal]="true" resizable="true" [contentStyle]="{'max-height':'800px'}" [width]="950">
  <div class="ui-g">
    <div class="ui-g-12">
      <label for="vin">Selecione o resultado da análise documental realizada.</label>
      <br>
      <div class="ui-g-4">
        <p-radioButton name="group1" value="Aprovada" label="Documentação aprovada"
          [(ngModel)]="selectedAnaliseDocumental" inputId="ada"></p-radioButton>
      </div>
      <div class="ui-g-4">
        <p-radioButton name="group1" value="Complemento" label="Necessita complementação"
          [(ngModel)]="selectedAnaliseDocumental" inputId="adc"></p-radioButton>
      </div>
      <div class="ui-g-4">
        <p-radioButton name="group1" value="Reprovada" label="Documentação reprovada"
          [(ngModel)]="selectedAnaliseDocumental" inputId="adr"></p-radioButton>
      </div>
    </div>
  </div>
  <div class="ui-g" *ngIf="selectedAnaliseDocumental === 'Aprovada'">
    <div class="ui-g-12" *ngIf="!numeroContrato">
      <br>
      <label for="vin">Informe o Número do Contrato se existir ou Gere Novo Número</label>
      <br>
      <input pInputText id="numeroContrato" [(ngModel)]="contrato.numeroContrato" maxlength="15" />
      <button title="Editar" (click)="gerarNovoNumeroContrato()" label="Gerar Novo Número"
        class="btn btn-outline-secondary mx-2">Gerar Novo Número
      </button>&nbsp;&nbsp;&nbsp;
      <label for="vin">Ano do Contrato:&nbsp;&nbsp;&nbsp;</label>
      <input pInputText id="anoContrato" [(ngModel)]="contrato.anoContrato" maxlength="5" size="5" />
    </div>
    <div class="ui-g-12" *ngIf="numeroContrato">
      <label for="vin">Número do
        Contrato:&nbsp;&nbsp;&nbsp;</label><b>{{contrato.numeroContrato}}/{{contrato.anoContrato}}</b>
    </div>
    <!-- <div class="ui-g-12">
      <label for="vin">Verifique se a assinatura eletrônica do gerador está ou não liberada no SEI e selecione a opção:</label>
      <br>
      <div class="ui-g-6">
        <p-radioButton name="seiLiberado" value="liberada" label="Está liberada no SEI" [(ngModel)]="selectedSEI" inputId="lib"></p-radioButton>
      </div>
      <div class="ui-g-6">
        <p-radioButton name="seiLiberado" value="naoLiberada" label="Não está liberada no SEI" [(ngModel)]="selectedSEI" inputId="nlib"></p-radioButton>
      </div>
    </div> -->
  </div>
  <div *ngIf="selectedAnaliseDocumental === 'Complemento'">
    <div class="ui-g-12">
      <br>
      <label for="vin">Observações complementares</label>
      <br>
      <textarea pInputTextarea id="obsComplementar" [(ngModel)]="contrato.obsNccComplementar" [rows]="3"
        [cols]="100"></textarea>
    </div>
  </div>
  <div class="ui-g" *ngIf="selectedAnaliseDocumental === 'Reprovada'">
    <div class="ui-g-12" style="text-align: center">
      <br>
      <label for="vin"><b>Após a análise documental realizada, o setor NCC - Núcleo de Contratos e Convênios decide
          reprovar o contrato.</b></label>
      <br>
    </div>
    <div class="ui-g-12">
      <br>
      <label for="vin">Oservações</label>
      <br>
      <textarea pInputTextarea id="obsReprovado" [(ngModel)]="contrato.obsReprovado" [rows]="3" [cols]="100"></textarea>
    </div>
  </div>
  <div style="text-align: center;">
    <br>
    <button type="button" class="btn btn-primary" (click)="contratoAndamentoPmpa('contratoAnaliseNCC')">
      <i class="fa fa-check" aria-hidden="true"> Enviar</i></button>&nbsp;&nbsp;&nbsp;
  </div>
</p-dialog>

<p-dialog [header]="tituloDialogEdit" [(visible)]="contratoDiretorGeral" [responsive]="true" showEffect="fade"
  [modal]="true" resizable="true" height="600" [width]="1050">
  <div class="ui-g">
    <div class="ui-g-12">
      <label for="vin">Selecione o resultado da análise para isenção de tarifa.</label>
      <br>
      <div class="ui-g-3">
        <p-radioButton name="group1" value="Deferida" label="Solicitação Deferida" [(ngModel)]="selectedAnaliseIsencao"
          inputId="ada"></p-radioButton>
      </div>
      <div class="ui-g-3">
        <p-radioButton name="group1" value="Indeferida" label="Solicitação Indeferida"
          [(ngModel)]="selectedAnaliseIsencao" inputId="adc"></p-radioButton>
      </div>
      <div class="ui-g-6">
      </div>
    </div>
  </div>
  <div class="ui-g" *ngIf="selectedAnaliseIsencao === 'Deferida'">
    <div class="ui-g-12">
      <label for="vin">Por favor, selecione uma das opções de isenção abaixo.</label>
      <br>
      <p-radioButton name="group2" value="isencaoDescargas" label="Isenção para um número máximo de descargas"
        [(ngModel)]="selectedIsencaoPrazo" inputId="des"></p-radioButton>&nbsp;&nbsp;&nbsp;
      <label for="vin" *ngIf="selectedIsencaoPrazo === 'isencaoDescargas'"><b>>>>>></b> Informe o número de
        decargas:&nbsp;&nbsp;&nbsp;</label>
      <input pInputText id="numeroDescargas" [(ngModel)]="contrato.isencaoDescargas" maxlength="5"
        *ngIf="selectedIsencaoPrazo === 'isencaoDescargas'" />
      <br>
      <p-radioButton name="group2" value="isencaoDeterminada" label="Isenção COM prazo determinado"
        [(ngModel)]="selectedIsencaoPrazo" inputId="com"></p-radioButton>&nbsp;&nbsp;&nbsp;
      <label for="vin" *ngIf="selectedIsencaoPrazo === 'isencaoDeterminada'"><b>>>>>></b> Informe a data limite para a
        isenção:&nbsp;&nbsp;&nbsp;</label>
      <p-calendar class="col-sm-3 p0" [(ngModel)]="contrato.isencaoData" [showIcon]="true" dateFormat="dd/mm/yy"
        [style]="{'width':'40%'}" [inputStyle]="{'width':'40%'}" [showIcon]="true" [locale]="languageCalendar"
        *ngIf="selectedIsencaoPrazo === 'isencaoDeterminada'"></p-calendar>
      <br>
      <p-radioButton name="group2" value="isencaoIndeterminada" label="Isenção SEM prazo determinado"
        [(ngModel)]="selectedIsencaoPrazo" inputId="sem"></p-radioButton>
    </div>
    <!-- <div class="ui-g-12" *ngIf="selectedIsencaoPrazo === 'isencaoDescargas'">
      <label for="vin">Informe o número de decargas:&nbsp;&nbsp;&nbsp;</label>
      <input pInputText id="numeroDescargas" [(ngModel)]="contrato.isencaoDescargas" maxlength="5"/>
    </div>
    <div class="ui-g-12" *ngIf="selectedIsencaoPrazo === 'isencaoDeterminada'">
      <label for="vin">Informe a data limite para a isenção:&nbsp;&nbsp;&nbsp;</label>
      <p-calendar class="col-sm-3 p0" [(ngModel)]="contrato.isencaoData" [showIcon]="true" dateFormat="dd/mm/yy"
      [style]="{'width':'50%'}" [inputStyle]="{'width':'50%'}" [showIcon]="true" [locale]="languageCalendar"></p-calendar>
    </div> -->
  </div>
  <div class="ui-g" *ngIf="selectedAnaliseIsencao === 'Indeferida'">
    <div class="ui-g-12">
      <br>
      <label for="vin"><b>Após a análise da solicitação de isenção de tarifa, o Diretor Geral decide indeferir a
          solicitação.</b></label>
    </div>
    <div class="ui-g-12">
      <br>
      <label for="vin">Oservações</label>
      <br>
      <textarea pInputTextarea id="obsReprovado" [(ngModel)]="contrato.obsReprovado" [rows]="3" [cols]="100"></textarea>
    </div>
  </div>
  <div style="text-align: center;">
    <br>
    <button type="button" class="btn btn-primary" (click)="contratoAndamentoPmpa('contratoAnaliseIsencao')">
      <i class="fa fa-check" aria-hidden="true"> Enviar</i></button>&nbsp;&nbsp;&nbsp;
  </div>
  <br><br><br><br><br><br><br><br><br><br><br>
</p-dialog>

<p-dialog header="Assinatura do Contrato" [(visible)]="contratoAssinaturaEmpresa" [responsive]="true" showEffect="fade"
  [modal]="true" resizable="true" [contentStyle]="{'max-height':'800px'}" [width]="1050">
  <div class="ui-g">
    <div class="ui-g-12">
      Esse contrato deverá ser assinado digitalmente através do Sistema SEI - Sistema Eletrônico de Informação.<br>
      Para isso, proceda conforme as orientações a seguir: <br><br>

      1. Empresa ainda não tem cadastro no SEI.<br>
      <pre>
Para cadastrar-se no SEI, clique <a href="http://sei.procempa.com.br/usuario_externo">AQUI,</a> use a opção “Clique aqui se você ainda não está cadastrado” e
siga as orientações detalhadas no <a href="../../../assets/doc/MANUAL_DO_USUÁRIO_EXTERNO_SEI.pdf">"Manual do Usuário Externo do SEI".</a>
Em caso de dúvidas sobre a documentação a ser apresentada para liberação do login de acesso ao SEI,
entrar em contato com a Coordenação da Documentação/SMPG:  e-mail: cd-sei@portoalegre.rs.gov.br - telefone: (51) 3289-1236.
</pre>

      2. Empresa já tem cadastro no SEI.<br>
      <pre>
Favor providenciar a assinatura digital do contrato.
Em caso de dúvidas quanto à necessidade ou momento da assinatura do contrato, contatar NCC pelo telefone (51) 3289-6841

Após efetivar a assinatura,
clicar no botão "Enviar" para que o Núcleo de Contratos e Convênios - NCC também possa efetivar a sua assinatura.
</pre>
      <!-- <br>
<b>Caso já tenhas efetivado a assinatura, clique em "Enviar" para que o DMLU providencie a assinatura do mesmo.</b> -->
    </div>
  </div>
  <div style="text-align: right;">
    <br>
    <button type="button" class="btn btn-primary" (click)="contratoAndamentoEmpresa('contratoAssinadoEmpresa')">
      <i class="fa fa-check" aria-hidden="true"> Enviar</i></button>&nbsp;&nbsp;&nbsp;
  </div>
</p-dialog>

<p-dialog header="Assinatura do Contrato" [(visible)]="contratoAssinaturaPmpa" [responsive]="true" showEffect="fade"
  [modal]="true" resizable="true" [contentStyle]="{'max-height':'800px'}" [width]="950">
  <div class="ui-g">
    <div class="ui-g-12">
      <br>
      <label for="vin">Informe a data da assinatura do contrato no SAF.</label>
      <br>
      <p-calendar class="col-sm-3 p0" [(ngModel)]="contrato.dataInicial" [showIcon]="true" dateFormat="dd/mm/yy"
        [style]="{'width':'50%'}" [inputStyle]="{'width':'50%'}"></p-calendar>
    </div>
  </div>
  <div style="text-align: center;">
    <br>
    <button type="button" class="btn btn-primary" (click)="contratoAndamentoPmpa('contratoAssinadoPmpa')">
      <i class="fa fa-check" aria-hidden="true"> Contrato Assinado</i></button>&nbsp;&nbsp;&nbsp;
  </div>
  <br><br><br><br><br><br><br><br>
</p-dialog>

















<!-- <p-dialog header="Contrato Elaborado?" [(visible)]="contratoElaboracao" [responsive]="true" showEffect="fade"
  [modal]="true" resizable="true" [contentStyle]="{'max-height':'800px'}" [width]="950">
  <div class="ui-g">
    <div class="ui-g-12">
      Se a documentação enviada pela Empresa estiver OK e o Contrato já foi incluído no Sistema SEI, <br>
      informe o número do SEI, se necessário inclua as observações e clique no botão "Elaborado".
    </div>
    <div class="ui-g-12">
      <label for="vin">Número do SEI</label>
      <br>
      <input pInputText id="numeroSEI" [(ngModel)]="contrato.numeroSei" maxlength="15"
        (click)="controlaOpcao('numeroSEI')" />
    </div>
    <div class="ui-g-12">
      Se a documentação enviada pela Empresa NÃO estiver OK e/ou a Empresa ainda não se cadastrou no Sistema SEI, <br>
      selecione uma das opções a seguir, se necessário inclua as observações e clique no botão "Pendente".
    </div>
    <div class="ui-g-12">
      <p-checkbox name="group1" value="documentos" label="Atualizar Documentos" [(ngModel)]="documentos" inputId="doc"
        (click)="controlaOpcao('documentos')"></p-checkbox>&nbsp;&nbsp;&nbsp;
      <p-checkbox name="group1" value="sistemaSEI" label="Cadastro no SEI" [(ngModel)]="sistemaSEI" inputId="sei"
        (click)="controlaOpcao('sistemaSEI')"></p-checkbox>
    </div>
    <div class="ui-g-12">
      <label for="vin">Oservações</label>
      <br>
    </div>
  </div>
</p-dialog> -->

<!-- <p-dialog header="Contrato Pendente?" [(visible)]="contratoPendenteUsuario" [responsive]="true" showEffect="fade"
  [modal]="true" resizable="true" [contentStyle]="{'max-height':'800px'}" [width]="750">
  <div class="ui-g">
    <div class="ui-g-6">
      <label for="vin">Oservações</label>
      <br>
      <textarea pInputTextarea id="obsGeral" [(ngModel)]="contrato.obsPendencia" [rows]="5" [cols]="80"></textarea>
    </div>
  </div>
  <div style="text-align: right;">
  </div>
</p-dialog> -->

<p-dialog header="Alterar Resíduos do Contrato" [(visible)]="alteracaoResiduos" [responsive]="true" showEffect="fade"
  [modal]="true" [width]="1000" [contentStyle]="{'overflow-y': 'auto', 'overflow-x': 'hidden',
  'max-height': '80vh','min-height':'250px'}">

  <div class="container">
    <!-- <div class="ui-g-12">
      <label for="vin"><strong>Caso não encontre o resíduo na lista abaixo, clique no botão ao lado e comunique ao DMLU
          a necessidade de enviar um tipo diferente de resíduo:</strong></label>
      <button type="button" (click)="novosTiposResiduos()" label="Novo Tipo de Resíduo" class="btn btn-primary"
        *ngIf="variaveisGlobais.roleEntidade" style="margin-left: 5px;">Novo Tipo de Resíduo</button>

    </div> -->
    <br>
    <div class="ui-g-12">
      <label for="vin"><strong>Relação dos resíduos possíveis de serem descartados em cada local</strong></label>
    </div>
    <div class="card text-black info04">
      <div class="card-header">
        <font size='5'><strong>Estação de Transbordo</strong></font>
      </div>
      <div class="card-body">
        <div class="p-grid" *ngFor="let residuoTb of residuosTransbordo; let i = index">
          <div class="p-col-12">
            <p-checkbox name="residuosTransbordo" value="{{residuoTb.id}}" label="{{residuoTb.nome}}"
              [(ngModel)]="residuosSelTb" [(binary)]="residuoTb.selecionado"
              (onChange)="atualizaSelecaoResiduo(residuoTb.id, $event, 'transbordo')"></p-checkbox>
          </div>
        </div>
      </div>
    </div>


    <div class="card text-black info04">
      <div class="card-header">
        <font size='5'><strong>Unidade de Compostagem</strong></font>
      </div>
      <div class="card-body">
        <div class="p-grid" *ngFor="let residuoCp of residuosCompostagem; let i = index">
          <div class="p-col-12">

            <p-checkbox name="residuosCompostagem" value="{{residuoCp.id}}" label="{{residuoCp.nome}}"
              [(ngModel)]="residuosSelCp" [binary]="residuoCp.selecionado"
              (onChange)="atualizaSelecaoResiduo(residuoCp.id, $event, 'compostagem')"></p-checkbox>

          </div>
        </div>
      </div>
    </div>

    <div class="card text-black info04">
      <div class="card-header">
        <font size='5'><strong>Unidade de Triagem</strong></font>
      </div>
      <div class="card-body">
        <div class="p-grid" *ngFor="let residuoTg of residuosTriagem; let i = index">
          <div class="p-col-12">
            <p-checkbox name="residuosTriagem" value="{{residuoTg.id}}" label="{{residuoTg.nome}}"
              [(ngModel)]="residuosSelTg" [binary]="residuoTg.selecionado"
              (onChange)="atualizaSelecaoResiduo(residuoTg.id, $event, 'triagem')"></p-checkbox>
          </div>
        </div>
      </div>
    </div>

    <br><br>
    <button type="button" (click)="atualizarResiduos()" label="Atualizar Resíduos" class="btn btn-primary"
      *ngIf="variaveisGlobais.roleEntidade" style="margin-right: 5px;">Atualizar Resíduos</button>

    <br><br>


  </div>
</p-dialog>

<p-dialog header="Novo Tipo de Resíduo" [(visible)]="novoTipoResiduo" [responsive]="true" showEffect="fade"
  [modal]="true" resizable="true" [contentStyle]="{'max-height':'400px'}" [width]="950">
  <div class="ui-g">
    <div class="ui-g-12">
      <label for="vin"><strong>Por favor, informe abaixo o tipo de resíduo que desejas descartar:</strong></label>
      <br>
      <textarea pInputTextarea id="obsResiduos" [(ngModel)]="residuoNovo" [rows]="3" [cols]="100"
        *ngIf="contrato.id === null"></textarea>
    </div>
  </div>
  <div style="text-align: center;">
    <br>
    <button type="button" class="btn btn-primary" (click)="enviaEmailSMCA()">
      <i class="fa fa-check" aria-hidden="true"> Enviar Solicitação ao DMLU - SMCA</i></button>&nbsp;&nbsp;&nbsp;
  </div>
  <br><br><br><br><br><br><br><br>
</p-dialog>
