import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ptBr } from 'src/app/@shared/utils/calendar';
import * as jspdf from 'jspdf';
import { SpinnerService } from '../../services/spinner.service';
import { NotificacoesService } from '../../services/notificacoes.service';
import { DmluService } from '../dmlu.service';
import { EntidadeService } from '../../entidade/entidade.service';
import { Globals } from 'src/app/globals';
import { Entidade, EntidadeClass } from 'src/app/@shared/models/entidade.model';
import { ContratoDescargaResiduo } from 'src/app/@shared/models/contratoDescargaResiduo.model';

@Component({
  // selector: 'app-certificado',
  templateUrl: './balanca.component.html',
  styleUrls: ['./balanca.component.scss']
})
export class BalancaComponent implements OnInit {

  submitted = false;
  activeRoute: string;
  nomeUsuario: string;
  variaveisGlobais;

  codigoBalanca = null;
  contratoDescargaResiduo: ContratoDescargaResiduo;
  contratoDescargaResiduos: ContratoDescargaResiduo[] = [];
  contratoTotalResiduos: ContratoDescargaResiduo[] = [];
  contratoDescargaResiduosDetalhe: ContratoDescargaResiduo[] = [];
  first: number = 0;
  totalEntidades: number;
  totalDetalhes: number;
  totalPeso: number;

  languageCalendar: any;
  insertMode = false;
  mostrarMovimentoIndividual: Boolean = false;
  mostrarEdicao: Boolean = false;
  mostrarTotalResiduo: Boolean = false;

  tituloEdicao: string = '';

  idEntidade = null;
  entidade: Entidade;

  //Pesquisa
  mesPesquisa: string = '';
  meses = [];
  codigoBalancaPesquisa: number = null;
  exibeSemMovimentacao01: boolean = false;
  exibeSemMovimentacao02: boolean = false;

  //Edição
  dataEntrada: Date;
  dataSaida: Date;
  codigoBalancaEdicao: number;
  placaVeiculo: string;
  pesoBruto: number;
  pesoTara: number;
  pesoLiquido: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private spinnerService: SpinnerService,
    private notificacoesService: NotificacoesService,
    private dmluService: DmluService,
    private entidadeService: EntidadeService,
    private globals: Globals
  ) {

    this.activeRoute = this.router.url;

    this.variaveisGlobais = globals;
    this.codigoBalancaPesquisa = route.snapshot.params['codigoBalanca'];

    if (this.codigoBalancaPesquisa !== undefined) {
      this.codigoBalancaPesquisa = 19;
    }

    console.log('BalancaComponent ..........>>>>>>>', this.codigoBalancaPesquisa);
  }
  ngOnInit() {
    this.variaveisGlobais = this.globals;
    this.variaveisGlobais.menuRaiz = this.router.url.split('/')[1];
    this.entidade = this.variaveisGlobais.entidade;

    if (this.globals.entidade == null) {
      this.globals.recuperaKeycloak().subscribe(user => {
        if (this.globals.roleEntidade) {
          if (this.variaveisGlobais.pessoaFisica === null) {
            this.globals.recuperaEntidadePessoaFisica().subscribe(entidade => {
              this.variaveisGlobais = this.globals;
              this.inicializaOnInit();
            });
          } else {
            this.inicializaOnInit();
          }
        } else {
          this.inicializaOnInit();
        }
      });
    } else {
      this.inicializaOnInit();
    }

  }


  inicializaOnInit() {

    this.contratoDescargaResiduo = new ContratoDescargaResiduo();
    this.entidade = new EntidadeClass();
    this.languageCalendar = ptBr;
    // this.mostrarDialogEdit = true;
    // if (this.contratoDescargaResiduo !== null) {
    //   this.buscaContratoDescargaResiduo();
    // }
    this.meses = [];
    this.meses.push({ label: 'Selecione', value: '' });
    this.meses.push({ label: 'Janeiro', value: 'Janeiro' });
    this.meses.push({ label: 'Fevereiro', value: 'Fevereiro' });
    this.meses.push({ label: 'Março', value: 'Março' });
    this.meses.push({ label: 'Abril', value: 'Abril' });
    this.meses.push({ label: 'Maio', value: 'Maio' });
    this.meses.push({ label: 'Junho', value: 'Junho' });
    this.meses.push({ label: 'Julho', value: 'Julho' });
    this.meses.push({ label: 'Agosto', value: 'Agosto' });
    this.meses.push({ label: 'Setembro', value: 'Setembro' });
    this.meses.push({ label: 'Outubro', value: 'Outubro' });
    this.meses.push({ label: 'Novembro', value: 'Novembro' });
    this.meses.push({ label: 'Dezembro', value: 'Dezembro' });


  }

  buscaContratoDescargaResiduo(mesPesquisa, codigoBalancaPesquisa) {
    this.spinnerService.exibir();
    this.exibeSemMovimentacao01 = false;

    this.dmluService.getDescargaResiduoPesquisa(codigoBalancaPesquisa,mesPesquisa).subscribe(cdr => {

      // console.log('buscaContratoDescargaResiduo ..........>>>>>>>', cdr);
      
      this.contratoDescargaResiduos = cdr;
      this.totalEntidades = cdr.length;
      if (cdr.length < 1) {
        this.exibeSemMovimentacao01 = true;
      }

      this.spinnerService.ocultar();

    },
      error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });

  }

  mostrarDescargaResiduoCodigoBalancaMes(codigoBalanca: number) {

    this.insertMode = false;
    this.exibeSemMovimentacao02 = false;
    this.contratoDescargaResiduosDetalhe = [];
    this.tituloEdicao = 'Balança - Movimentação Individual de Descarga de Resíduos para o mês de ' + this.mesPesquisa;
    this.spinnerService.exibir();

    this.dmluService.getDescargaResiduoCodigoBalancaMes(codigoBalanca, this.mesPesquisa).subscribe(cdrs => {
      // console.log('------===>', cdrs);
      this.contratoDescargaResiduosDetalhe = cdrs;

      if (!this.variaveisGlobais.roleEntidade) {
        this.mostrarMovimentoIndividual = true;
      }

      this.totalDetalhes = cdrs.length;

      this.totalPeso = 0;
      this.contratoDescargaResiduosDetalhe.forEach(reg => {
        reg.dataDescargaInicio = new Date(reg.dataDescargaInicio);
        this.totalPeso = this.totalPeso + reg.pesoLiquido;
      });
      // console.log('mostrarDescargaResiduoCodigoBalancaMes>>>>>>>>>>>>>>', this.totalPeso);

      this.first = 0;

      this.entidadeService.getEntidadePorId(codigoBalanca).subscribe(ent => {
      // this.entidadeService.getEntidadePorCodigoDmlu(34009).subscribe(ent => {
        this.entidade = ent;
        // console.log('------===>', this.entidade);
      },
      error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });

      if (cdrs.length < 1) {
        this.exibeSemMovimentacao02 = true;
      }
      this.spinnerService.ocultar();

    },
      error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });


  }

  pesquisaBalanca(admin: boolean) {
    this.contratoDescargaResiduos = [];

    if (this.mesPesquisa === undefined || this.mesPesquisa === null) {
      this.mesPesquisa = '';
    }
    if (this.codigoBalancaPesquisa === undefined || this.codigoBalancaPesquisa === null || this.codigoBalancaPesquisa.toString() === '') {
      this.codigoBalancaPesquisa = 0;
    }

    if (this.mesPesquisa === '') {
        this.notificacoesService.notificarAviso('Favor informar o MÊS da pesquisa!', null);
        return;
    }

    if (!admin) {
      if (this.codigoBalancaPesquisa === 0) {
        this.notificacoesService.notificarAviso('Favor informar o CÓDIGO DMLU da pesquisa!', null);
        return;
      }
    }

    if (admin) {
      if (this.codigoBalancaPesquisa === 0) {
        this.buscaContratoDescargaResiduo(this.mesPesquisa, this.codigoBalancaPesquisa);
      } else {
        this.mostrarDescargaResiduoCodigoBalancaMes(this.codigoBalancaPesquisa);
      }
    } else {
      this.mostrarDescargaResiduoCodigoBalancaMes(this.codigoBalancaPesquisa);
    }
  }

  mostrarEditarDescarga(idCDR: number) {
    const cdr = this.contratoDescargaResiduosDetalhe.find(function (cdResiduo) {
      return cdResiduo.id === idCDR;
    });
    this.contratoDescargaResiduo = { ...cdr };
    console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.contratoDescargaResiduo);
    this.mostrarEdicao = true;

    this.dataEntrada = this.contratoDescargaResiduo.dataDescargaInicio;
    this.dataSaida = new Date(this.contratoDescargaResiduo.dataDescargaFinal);
    this.codigoBalancaEdicao = this.contratoDescargaResiduo.codigoBalanca;
    this.placaVeiculo = this.contratoDescargaResiduo.placaVeiculo;
    this.pesoBruto = this.contratoDescargaResiduo.pesoBruto;
    this.pesoTara = this.contratoDescargaResiduo.pesoTara;
    this.pesoLiquido = this.contratoDescargaResiduo.pesoLiquido;

  }

  atualizarDescarga() {

    this.contratoDescargaResiduo.dataDescargaInicio = this.dataEntrada;
    this.contratoDescargaResiduo.dataDescargaFinal = this.dataSaida;
    this.contratoDescargaResiduo.codigoBalanca = this.codigoBalancaEdicao;
    this.contratoDescargaResiduo.placaVeiculo = this.placaVeiculo;
    this.contratoDescargaResiduo.pesoBruto = this.pesoBruto;
    this.contratoDescargaResiduo.pesoTara = this.pesoTara;
    this.contratoDescargaResiduo.pesoLiquido = this.pesoBruto - this.pesoTara;

    this.spinnerService.exibir();
    this.dmluService.atualizarDescarga(this.contratoDescargaResiduo).subscribe(cdr => {

      // console.log('buscaContratoDescargaResiduo ..........>>>>>>>', cdr);
      this.mostrarDescargaResiduoCodigoBalancaMes(cdr.codigoBalanca);
      this.mostrarEdicao = false;
      this.spinnerService.ocultar();

    },
      error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });

  }

  excluirDescarga() {

    this.spinnerService.exibir();
    this.dmluService.excluirDescarga(this.contratoDescargaResiduo).subscribe(cdr => {

      // console.log('buscaContratoDescargaResiduo ..........>>>>>>>', cdr);
      this.mostrarDescargaResiduoCodigoBalancaMes(this.contratoDescargaResiduo.codigoBalanca);
      this.mostrarEdicao = false;
      this.spinnerService.ocultar();

    },
      error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });


  } 

  mostrarTotaisResiduoBalancaMes(codigoBalanca: number) {

    if (this.mesPesquisa === undefined || this.mesPesquisa === null) {
      this.mesPesquisa = '';
    }

    if (this.mesPesquisa === '') {
        this.notificacoesService.notificarAviso('Favor informar o MÊS da pesquisa!', null);
        return;
    }

    this.insertMode = false;
    this.mostrarTotalResiduo = true;
    this.contratoTotalResiduos = [];
    this.spinnerService.exibir();

    this.dmluService.getTotalPorResiduo(codigoBalanca, this.mesPesquisa).subscribe(cdr => {

      // console.log('buscaContratoDescargaResiduo ..........>>>>>>>', cdr);
      
      this.contratoTotalResiduos = cdr;
      this.totalEntidades = cdr.length;

      this.spinnerService.ocultar();

    },
      error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });

  }
}
