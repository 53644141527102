var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { MenuItem } from 'primeng/api';
import { SpinnerService } from '../services/spinner.service';
import { NotificacoesService } from '../services/notificacoes.service';
import { Globals } from 'src/app/globals';
import { VeiculoClass } from 'src/app/@shared/models/veiculo.model';
import { VeiculoService } from '../services/veiculo.service';
import { EntidadeService } from '../entidade/entidade.service';
var VeiculoPmpaComponent = /** @class */ (function () {
    function VeiculoPmpaComponent(router, keycloakService, veiculoService, spinnerService, notificacoesService, entidadeService, globals) {
        this.router = router;
        this.keycloakService = keycloakService;
        this.veiculoService = veiculoService;
        this.spinnerService = spinnerService;
        this.notificacoesService = notificacoesService;
        this.entidadeService = entidadeService;
        this.globals = globals;
        this.veiculos = [];
        this.entidadeNome = '';
        this.cnpjExecutora = '';
        this.activeRoute = this.router.url;
        this.variaveisGlobais = globals;
    }
    VeiculoPmpaComponent.prototype.ngOnInit = function () {
        if (this.variaveisGlobais.menuRaiz === null || this.variaveisGlobais.menuRaiz === '') {
            this.router.navigate(['/sgrIni']);
        }
        else {
            this.inicializaOnInit();
        }
    };
    VeiculoPmpaComponent.prototype.inicializaOnInit = function () {
        var _this = this;
        this.spinnerService.exibir();
        this.veiculoEdit = new VeiculoClass();
        this.entidade = null;
        this.entidadeService.getEntidadeCnpj('92.963.560/0001-60').subscribe(function (entidade) {
            _this.entidade = entidade;
            _this.veiculoService.getVeiculosPorEntidade(entidade.id).subscribe(function (veiculos) {
                _this.spinnerService.ocultar();
                _this.veiculos = veiculos;
            });
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    VeiculoPmpaComponent.prototype.logout = function () {
        var url = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
        this.keycloakService.getKeycloakInstance().logout({ redirectUri: url });
    };
    VeiculoPmpaComponent.prototype.mostrarDialogParaNovo = function () {
        this.veiculoEdit = new VeiculoClass();
        this.mostrarDialogEdit = true;
        this.isInsert = true;
        this.tituloDialogEdit = 'Novo Veículo';
    };
    VeiculoPmpaComponent.prototype.mostrarDialogParaEditar = function (idVeiculo) {
        var veiculoParaEditar = this.veiculos.find(function (veiculo) {
            return veiculo.id === idVeiculo;
        });
        this.veiculoEdit = __assign({}, veiculoParaEditar);
        console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.veiculoEdit);
        this.mostrarDialogEdit = true;
        this.isInsert = false;
        this.tituloDialogEdit = 'Editar Veículo';
    };
    VeiculoPmpaComponent.prototype.mostrarDialogEditParaExcluir = function (idProduto) {
        // this.produtoExcluir =  this.produtos.find(function(produto) {
        //   return produto.id === idProduto;
        // });
        // this.mostrarDialogExcluir = true;
    };
    VeiculoPmpaComponent.prototype.salvar = function () {
        var _this = this;
        //Verifica se todos os campos foram preenchidos
        if (this.veiculoEdit.placa == null || this.veiculoEdit.placa === '') {
            this.notificacoesService.notificarAviso('Por favor informar a PLACA do Veículo!', null);
            return;
        }
        if (this.veiculoEdit.renavam == null || this.veiculoEdit.renavam === '') {
            this.notificacoesService.notificarAviso('Por favor informar o RENAVAM do Veículo!', null);
            return;
        }
        // if (this.veiculoEdit.endereco == null || this.veiculoEdit.endereco === '') {
        //   this.notificacoesService.notificarAviso('Por favor informar o ENDEREÇO da Secretaria/Órgão!', null);
        //   return ;
        // }
        this.spinnerService.exibir();
        console.log('salvar....................>>>>>>>>>>>>>>', this.veiculoEdit);
        this.veiculoEdit.entidadeDO = this.entidade;
        // Se novo
        if (this.veiculoEdit.id == null) {
            this.veiculoService.criarVeiculo(this.veiculoEdit).subscribe(function (veiculoNovo) {
                console.log('salvado....................>>>>>>>>>>>>>>', veiculoNovo);
                _this.veiculos.push(veiculoNovo);
                _this.mostrarDialogEdit = false;
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarSucesso('Adicionado com sucesso', null);
            }, function (error) {
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro('Erro ao adicionar ', error);
            });
        }
        else { // Se editando
            this.veiculoService.editarVeiculo(this.veiculoEdit).subscribe(function (veiculoEditado) {
                // Remove o valor atual
                _this.veiculos = _this.veiculos.filter(function (item) { return item.id !== _this.veiculoEdit.id; });
                // Adiciona o novo
                _this.veiculos.push(veiculoEditado);
                _this.mostrarDialogEdit = false;
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarSucesso('Atualizado com sucesso', null);
            }, function (error) {
                console.log(error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    VeiculoPmpaComponent.prototype.excluir = function () {
        // this.spinnerService.exibir();
        // console.log('salvar....................>>>>>>>>>>>>>>', this.variaveisGlobais.menuRaiz);
        // this.produtoService.excluirProduto(this.produtoExcluir, this.variaveisGlobais.menuRaiz).subscribe(produto => {
        //   if (produto == null) {
        //     // Remove o valor atual
        //     this.produtos = this.produtos.filter(item => item.id !== this.produtoExcluir.id);
        //   }
        //   this.mostrarDialogExcluir = false;
        //   this.spinnerService.ocultar();
        //   this.notificacoesService.notificarSucesso('Excluído com sucesso', null);
        // },
        // error => {
        //   console.log(error);
        //   this.spinnerService.ocultar();
        //   this.notificacoesService.notificarErro('Erro ao exluir', error.error);
        // });
    };
    VeiculoPmpaComponent.prototype.checkActive = function (route) {
        return route === this.activeRoute;
    };
    return VeiculoPmpaComponent;
}());
export { VeiculoPmpaComponent };
