<sgr-menu></sgr-menu>

<div class="container">

  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/sgrIni">SGR</a></li>
    <li class="breadcrumb-item active" aria-current="page">Cadastro</li>
  </ol>

  <h1 class="page-header text-primary">
    Destino Final<br>
    <small>Cadastro</small>
  </h1>

  <div>
    <p-table #tabelaDestinosFinais [value]="destinosFinais" [paginator]="true" sortField="nome" [rows]="10"
      [globalFilterFields]="['nome', 'enderecoFormatadoCurto', 'licencaAmbientalDO.licenca']">
      <ng-template pTemplate="caption">
        <div class="row justify-content-between">
          <div class="col-auto form-group">
            <button type="button" class="btn btn-primary text-nowrap" (click)="mostrarDialogParaNovo()"
              label="Adicionar" *ngIf="!variaveisGlobais.roleEntidade">
              <i class="fa fa-plus pr-2"></i>Adicionar destino final
            </button>
          </div>
          <div class="col-sm-12 col-md-6 form-group busca">
            <input type="text" pInputText size="50" placeholder="Filtrar destinos finais" (input)="
                tabelaDestinosFinais.filterGlobal(
                  $event.target.value,
                  'contains'
                )
              " class="form-control" />
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="nome" class="text-center" style="width: 200px; height: 60px;">
            Nome
            <p-sortIcon field="nome"></p-sortIcon>
          </th>
          <th pSortableColumn="enderecoFormatadoCurto" class="text-center" style="width: 200px; height: 60px;">
            Endereço
            <p-sortIcon field="enderecoFormatadoCurto"></p-sortIcon>
          </th>
          <th pSortableColumn="licencaAmbientalDO.licenca" class="text-center" style="width: 100px; height: 60px;">
            Licença
            <p-sortIcon field="licencaAmbientalDO.licenca"></p-sortIcon>
          </th>
          <th style="width: 150px;" class="text-center">
            Ações
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-destinoFinal>
        <tr>
          <td>
            {{ destinoFinal?.identificacao }}
          </td>
          <td>{{ destinoFinal?.enderecoFormatadoCurto }}</td>
          <td>
            <span *ngIf="destinoFinal.licencaAmbientalDO !== null">{{destinoFinal.licencaAmbientalDO?.licenca}}</span>
            <span *ngIf="destinoFinal.licencaAmbientalDO === null && destinoFinal.isentoLicenca">Isento</span>
          </td>
          <td>
            <button title="Editar" (click)="mostrarDialogParaEditar(destinoFinal.id)" label="Detalhes"
              class="btn btn-outline-secondary mx-2">
              <i class="fa fa-info pr-2"></i>Detalhes
            </button>
            <span class="text-danger" *ngIf="!destinoFinal.ativo">Inativo</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<!-- Dialog para novo -->
<p-dialog [header]="tituloDialogEdit" [(visible)]="mostrarDialogEdit" [responsive]="true" showEffect="fade"
  [modal]="true" [width]="700">
  <div class="row">

    <div class="col-sm-6 form-group">
      <label for="vin">Nome</label>
      <input pInputText id="nome" [(ngModel)]="destinoFinalEdit.identificacao" size="30" class="form-control" />
    </div>

    <div class="col-12 form-group">
      <label for="vin">Situação</label>
      <div>
        <p-radioButton name="dfAtivo" [value]="true" label="Ativo" [(ngModel)]="destinoFinalEdit.ativo"></p-radioButton>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <p-radioButton name="dfAtivo" [value]="false" label="Inativo" [(ngModel)]="destinoFinalEdit.ativo">
        </p-radioButton>
      </div>
    </div>
    <div class="col-12 form-group">
      <label for="vin">Observação</label>
      <textarea pInputTextarea id="observacao" [(ngModel)]="destinoFinalEdit.observacao" [rows]="3"
        class="form-control"></textarea>
    </div>

    <fieldset class="col-12">
      <legend>Endereço</legend>
      <div class="procempaCdl row" procempaCdl [maxResults]="18" [codigo]="logradouroCdl?.codigoLogradouro"
        [numero]="logradouroCdl?.numero" [localizar]="true">
        <div class="col-sm-8 form-group">
          <label>Logradouro</label>
          <input cdlLogradouro [(ngModel)]="logradouro" size="35" name="logradouro" (seleciona)="selecionaCDL($event)"
            placeholder="Informe NOME" class="form-control ui-state-default" />
        </div>
        <div class="col-sm-4 form-group">
          <label>Número</label>
          <input cdlNumero [(ngModel)]="numero" name="numero" size="15" placeholder="NÚMERO"
            class="form-control ui-state-default" />
        </div>
      </div>
      <div class="mb-3" *ngIf="!isInsert">
        {{ destinoFinalEdit?.enderecoFormatado }}
      </div>
    </fieldset>
    <div class="col-12 form-group">
      <label for="vin">
        Somente preencha os campos abaixo em caso de obrigatoriedade da Licença Ambiental.<br>
        Selecione entre as Licenças ou marque a opção declarando a isenção.
      </label>
    </div>

    <div class="col-sm-auto form-group">
      <p-dropdown [options]="licencasItem" [(ngModel)]="idLicenca" placeholder="Selecione" (onChange)="selLicenca()">
      </p-dropdown>
      <!-- <p-checkbox label=" Declaração de isenção." [(ngModel)]="licencaIsento" name='isentoLicenca' #isentoLicenca (onChange)="licencaIsencao(isentoLicenca)"></p-checkbox> -->
    </div>
    <div class="col-sm-auto form-group">
      <p-checkbox label=" Declaração de isenção." [(ngModel)]="licencaIsento" binary="false" name='isentoLicenca'
        #isentoLicenca (onChange)="licencaIsencao(isentoLicenca)"></p-checkbox>
    </div>

    <!-- <div class="p-grid" *ngIf="isInsert==true">
    <div class="panel-group">
      <div class="panel panel-default">
        <label class="pr-2">Endereço</label>
        <div class="panel-body">
          <div class="p-grid procempaCdl" procempaCdl [maxResults]="18" [codigo]="logradouroCdl?.codigoLogradouro" [numero]="logradouroCdl?.numero" [localizar]="true">
            <div class="p-col-8">
                <div class="p-grid">
                  <div class="p-col">
                    <label>Logradouro</label>
                  </div>
                </div>
                <div class="p-grid">
                  <div class="p-col">
                      <input cdlLogradouro [(ngModel)]="logradouro" size="70" name="logradouro" (seleciona)="selecionaCDL($event)" placeholder="Informe NOME" class="ui-input-text ui-corner-all">
                    </div>
                </div>
            </div>
            <div class="p-col-4">
              <div class="p-grid">
                <div class="p-col">
                  <label>Número</label>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col">
                    <input cdlNumero [(ngModel)]="numero" name="numero" size="15" placeholder="Informe NÚMERO">
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  </div>

  <div class="botoes text-right" *ngIf="!variaveisGlobais.roleEntidade">
    <button type="button" (click)="mostrarDialogEdit = false" label="Cancelar" class="btn btn-outline-secondary">
      Cancelar
    </button>
    <button type="button" (click)="salvar()" label="Salvar" class="btn btn-primary">
      Salvar
    </button>
  </div>
</p-dialog>

<p-dialog header="Excluir Destino Final" [(visible)]="mostrarDialogExcluir" [responsive]="true" showEffect="fade"
  [modal]="true" [width]="500">
  <p *ngIf="destinoFinalExcluir" class="mb-5">
    <strong>Confirma a exclusão</strong> do destino final <em>{{ destinoFinalExcluir.identificacao}}</em>?
  </p>
  <div class="botoes text-right">
    <button type="button" class="btn btn-outline-secondary" (click)="mostrarDialogExcluir = false"
      label="Não, cancelar">
      Não. Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="excluir()" label="Sim, excluir">
      Sim. Excluir
    </button>
  </div>
</p-dialog>