/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./prestadoraServico.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../menu/menu.component.ngfactory";
import * as i3 from "../../menu/menu.component";
import * as i4 from "@angular/router";
import * as i5 from "../../@core/entidade/entidade.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../globals";
import * as i8 from "@procempa/ngx-keycloak";
import * as i9 from "@angular/common";
import * as i10 from "./prestadoraServico.component";
var styles_PrestadoraServicoComponent = [i0.styles];
var RenderType_PrestadoraServicoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrestadoraServicoComponent, data: {} });
export { RenderType_PrestadoraServicoComponent as RenderType_PrestadoraServicoComponent };
export function View_PrestadoraServicoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sgr-menu", [], null, null, null, i2.View_MenuComponent_0, i2.RenderType_MenuComponent)), i1.ɵdid(1, 114688, null, 0, i3.MenuComponent, [i4.ActivatedRoute, i4.Router, i5.EntidadeService, i6.DOCUMENT, i7.Globals, i8.KeycloakService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 32, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "ol", [["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "li", [["class", "breadcrumb-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["routerLink", "/sgrIni"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i9.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["SGR"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "li", [["aria-current", "page"], ["class", "breadcrumb-item active"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Grandes Geradores"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h1", [["class", "page-header text-primary mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Grandes Geradores"])), (_l()(), i1.ɵeld(12, 0, null, null, 22, "div", [["class", "card-deck"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 5, "div", [["class", "card bg-light"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "a", [["class", "a-nostyle"], ["routerLink", "/grandeGerador"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i9.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "h2", [["class", "p-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Grande Gerador "])), (_l()(), i1.ɵeld(19, 0, null, null, 3, "div", [["class", "card bloqueado"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "h2", [["class", "text-primary p-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Prestadores de Servi\u00E7o "])), (_l()(), i1.ɵeld(23, 0, null, null, 5, "div", [["class", "card bg-light"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 4, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 3, "a", [["class", "a-nostyle"], ["routerLink", "/transportador"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 26).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(26, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i9.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(27, 0, null, null, 1, "h2", [["class", "p-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Transportador "])), (_l()(), i1.ɵeld(29, 0, null, null, 5, "div", [["class", "card bg-light"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 4, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 3, "a", [["class", "a-nostyle"], ["routerLink", "/destinoFinal"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 32).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(32, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i9.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(33, 0, null, null, 1, "h2", [["class", "p-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Destino Final "]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_2 = "/sgrIni"; _ck(_v, 6, 0, currVal_2); var currVal_5 = "/grandeGerador"; _ck(_v, 16, 0, currVal_5); var currVal_8 = "/transportador"; _ck(_v, 26, 0, currVal_8); var currVal_11 = "/destinoFinal"; _ck(_v, 32, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).target; var currVal_1 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 16).target; var currVal_4 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 26).target; var currVal_7 = i1.ɵnov(_v, 26).href; _ck(_v, 25, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 32).target; var currVal_10 = i1.ɵnov(_v, 32).href; _ck(_v, 31, 0, currVal_9, currVal_10); }); }
export function View_PrestadoraServicoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_PrestadoraServicoComponent_0, RenderType_PrestadoraServicoComponent)), i1.ɵdid(1, 114688, null, 0, i10.PrestadoraServicoComponent, [i4.Router, i4.ActivatedRoute, i7.Globals], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrestadoraServicoComponentNgFactory = i1.ɵccf("ng-component", i10.PrestadoraServicoComponent, View_PrestadoraServicoComponent_Host_0, {}, {}, []);
export { PrestadoraServicoComponentNgFactory as PrestadoraServicoComponentNgFactory };
