/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notificacoes.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/primeng/components/growl/growl.ngfactory";
import * as i3 from "primeng/components/dom/domhandler";
import * as i4 from "primeng/components/growl/growl";
import * as i5 from "primeng/components/common/messageservice";
import * as i6 from "./notificacoes.component";
import * as i7 from "../services/notificacoes.service";
var styles_NotificacoesComponent = [i0.styles];
var RenderType_NotificacoesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificacoesComponent, data: {} });
export { RenderType_NotificacoesComponent as RenderType_NotificacoesComponent };
export function View_NotificacoesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p-growl", [["life", "7500"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.mensagens = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Growl_0, i2.RenderType_Growl)), i1.ɵprd(512, null, i3.DomHandler, i3.DomHandler, []), i1.ɵdid(2, 4636672, null, 0, i4.Growl, [i1.ElementRef, i3.DomHandler, i1.IterableDiffers, [2, i5.MessageService], i1.NgZone], { life: [0, "life"], value: [1, "value"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "7500"; var currVal_1 = _co.mensagens; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_NotificacoesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sgr-notificacoes", [], null, null, null, View_NotificacoesComponent_0, RenderType_NotificacoesComponent)), i1.ɵdid(1, 245760, null, 0, i6.NotificacoesComponent, [i7.NotificacoesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificacoesComponentNgFactory = i1.ɵccf("sgr-notificacoes", i6.NotificacoesComponent, View_NotificacoesComponent_Host_0, {}, {}, []);
export { NotificacoesComponentNgFactory as NotificacoesComponentNgFactory };
