<sgr-menu></sgr-menu>

<div class="container">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/sgrIni">SGR</a></li>
    <li class="breadcrumb-item active" aria-current="page">Grandes Geradores</li>
  </ol>
  <h1 class="page-header text-primary mb-3">Grandes Geradores</h1>

  <div class="card-deck">
       
    <div class="card bg-light">
      <div class="card-body">
        <a routerLink="/grandeGerador" class="a-nostyle">
          <h2 class="p-2">
            Grande Gerador
          </h2>
        </a>
      </div>
    </div>
       
    <div class="card bloqueado">
      <div class="card-body">
        <h2 class="text-primary p-2">
          Prestadores de Serviço
        </h2>
      </div>
      
    </div>
           
    <div class="card bg-light">
      <div class="card-body">
        <a routerLink="/transportador" class="a-nostyle">
          <h2 class="p-2">
            Transportador
          </h2>
        </a>
      </div>
    </div>

    <div class="card bg-light">
      <div class="card-body">
        <a routerLink="/destinoFinal" class="a-nostyle">
          <h2 class="p-2">
            Destino Final
          </h2>
        </a>
      </div>
    </div>

  </div>
</div>
