import { KeycloakService } from '@procempa/ngx-keycloak';
import { merge, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EnderecoService } from './@core/services/endereco.service';
import { VeiculoService } from './@core/services/veiculo.service';
import { EntidadeService } from './@core/entidade/entidade.service';
import { PessoaFisicaService } from './@core/services/pessoaFisica.service';
import { SpinnerService } from './@core/services/spinner.service';
import { NotificacoesService } from './@core/services/notificacoes.service';
import * as i0 from "@angular/core";
import * as i1 from "@procempa/ngx-keycloak";
import * as i2 from "./@core/entidade/entidade.service";
import * as i3 from "./@core/services/endereco.service";
import * as i4 from "./@core/services/pessoaFisica.service";
import * as i5 from "./@core/services/veiculo.service";
import * as i6 from "./@core/services/spinner.service";
import * as i7 from "./@core/services/notificacoes.service";
var Globals = /** @class */ (function () {
    function Globals(keycloakService, entidadeService, enderecoService, pessoaFisicaService, veiculoService, spinnerService, notificacoesService) {
        var _this = this;
        this.keycloakService = keycloakService;
        this.entidadeService = entidadeService;
        this.enderecoService = enderecoService;
        this.pessoaFisicaService = pessoaFisicaService;
        this.veiculoService = veiculoService;
        this.spinnerService = spinnerService;
        this.notificacoesService = notificacoesService;
        this.roleEntidade = false;
        this.entidade = null;
        this.entidades = [];
        // enderecosEntidade: EnderecoEntidade[] = [];
        // veiculosEntidade: Veiculo[] = [];
        this.pessoaFisica = null;
        this.pessoasFisicas = [];
        this.userFullName = '';
        this.userName = '';
        this.userEmail = '';
        this.menuRaiz = '';
        // setTimeout(() => {
        keycloakService.getUser().subscribe(function (user) {
            _this.userFullName = user.fullName;
            _this.userName = user.name;
        });
        this.menuRaiz = '';
        // }, 3850);
    }
    Globals.prototype.recuperaKeycloak = function () {
        var _this = this;
        return this.keycloakService.getUser().pipe(tap(function (user) {
            _this.userFullName = user.fullName;
            _this.userName = user.username;
            _this.userEmail = user.email;
            if (_this.keycloakService.hasRole('Entidade')) {
                _this.roleEntidade = true;
            }
        }));
    };
    Globals.prototype.recuperaEntidadePessoaFisica = function () {
        var _this = this;
        // console.log('Globals>>>>>>>>>recuperaEntidadePessoaFisica..........', this.menuRaiz);
        return new Observable(function (obs) {
            //  this.entidadeService.getEntidadeRepresentada().subscribe(entidade => {
            _this.pessoaFisicaService.getPessoaFisicaPorEmail(_this.userEmail).subscribe(function (pessoasFisicas) {
                _this.pessoasFisicas = pessoasFisicas;
                // console.log('############Globals>>>>>>>>>getEntidadeRepresentada>>>>', pessoasFisicas);
                if (pessoasFisicas.length === 1) {
                    // console.log('%%%%%%%%%%%%%%Globals>>>>>>>>>getEntidadeRepresentada>>>>', pessoasFisicas);
                    _this.pessoaFisica = pessoasFisicas[0];
                    var extras = [
                        _this.entidadeService.getEntidadesDaPessoaFisica(_this.pessoaFisica.id).pipe(tap(function (entidades) {
                            _this.entidades = entidades;
                            // console.log('0000Globals>>>>>>>>>buscaEntidades>>>>', this.entidades.length);
                            // console.log('Globals>>>>>>>>>buscaEntidades>>>>', this.entidades);
                            if (entidades.length === 1) {
                                _this.entidade = entidades[0];
                            }
                        }))
                    ];
                    merge.apply(void 0, extras).subscribe(function (outs) { }, function (e) { return obs.error(e); }, function () {
                        obs.next(pessoasFisicas);
                        obs.complete();
                    });
                }
            });
        });
    };
    Globals.ngInjectableDef = i0.defineInjectable({ factory: function Globals_Factory() { return new Globals(i0.inject(i1.KeycloakService), i0.inject(i2.EntidadeService), i0.inject(i3.EnderecoService), i0.inject(i4.PessoaFisicaService), i0.inject(i5.VeiculoService), i0.inject(i6.SpinnerService), i0.inject(i7.NotificacoesService)); }, token: Globals, providedIn: "root" });
    return Globals;
}());
export { Globals };
