import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Veiculo } from '../../@shared/models/veiculo.model';


@Injectable({providedIn: 'root'})
export class VeiculoService {

  constructor(private http: HttpClient) { }

  getVeiculo(id: number): Observable<Veiculo> {
    const url = environment.apiUrl + '/veiculos/byId/' + id;
    console.log('criarVeiculo Service >>>>>>', url);
    return this.http.get<Veiculo>(url);
  }

  getVeiculosPorEntidade(idEntidade: number): Observable<Veiculo[]> {
    const url = environment.apiUrl + '/veiculos/listaPorEntidade/' + idEntidade;
    // console.log('criarVeiculo Service >>>>>>', url);
    return this.http.get<Veiculo[]>(url);
  }

  criarVeiculo(veiculo: Veiculo): Observable<Veiculo> {

    return this.http.post<Veiculo>(environment.apiUrl + '/veiculos/', veiculo);
  }

  editarVeiculo(veiculo: Veiculo): Observable<Veiculo> {
    return this.http.put<Veiculo>(environment.apiUrl + '/veiculos/', veiculo);
  }

  deletaVeiculo(idVeiculo) {

    const url = environment.apiUrl + '/veiculos/deletar/' + idVeiculo;
    console.log('Veículo Service - DELETA>>>>>>>', url);

    return this.http
      .delete(url, idVeiculo);

  }

}
