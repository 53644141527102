import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PessoaFisica } from '../../@shared/models/pessoaFisica.model';
import { Entidade } from 'src/app/@shared/models/entidade.model';

@Injectable({ providedIn: 'root' })
export class PessoaFisicaService {

  constructor(private http: HttpClient) { }

  getPessoaFisicaPesquisa(criterioPesquisa: String, tipoCriterioPesquisa: String): Observable<PessoaFisica[]> {
    console.log('getPessoaFisicaPesquisa 22>>>', criterioPesquisa);
    console.log('getPessoaFisicaPesquisa 33>>>', tipoCriterioPesquisa);

    return this.http.get<PessoaFisica[]>(environment.apiUrl + '/pessoaFisica/pesquisa/'
      + '?criterioPesquisa=' + criterioPesquisa + '&tipoCriterioPesquisa=' + tipoCriterioPesquisa);
  }

  getPessoaFisicaPorEmail(email: String): Observable<PessoaFisica[]> {
    console.log('getPessoaFisicaPorEmail>>>>>>>>>>', environment.apiUrl + '/pessoaFisica/email/' + email);
    return this.http.get<PessoaFisica[]>(environment.apiUrl + '/pessoaFisica/email/' + email);
  }

  getPessoaFisicaRTPorEntidade(id: number, tipoRT: String): Observable<PessoaFisica[]> {
    const url = environment.apiUrl + '/pessoaFisica/' + id + '/' + tipoRT + '/rt/entidade';
    console.log('getPessoaFisicaPorEntidade Service >>>>>>', url);
    return this.http.get<PessoaFisica[]>(url);
  }

  getPessoasFisicasDaEntidade(idEntidade: number): Observable<PessoaFisica[]> {
    const url = environment.apiUrl + '/pessoaFisica/entidade/' + idEntidade;
    return this.http.get<PessoaFisica[]>(url);
  }

  getPessoasFisicasResponsavelEntidade(idEntidade: number, cnpj: string): Observable<PessoaFisica[]> {
    let cnpjSemFormatacao = cnpj.replace('/', '.');
    cnpjSemFormatacao = cnpjSemFormatacao.replace('-', '.');

    const url = environment.apiUrl + '/pessoaFisica/entidade/tipoResp/' + idEntidade + '/' + cnpjSemFormatacao;
    return this.http.get<PessoaFisica[]>(url);
  }

  getPessoasFisicasResponsavelLegalEntidade(idEntidade: number): Observable<PessoaFisica[]> {
    const url = environment.apiUrl + '/pessoaFisica/entidade/responsavelLegal/' + idEntidade;
    return this.http.get<PessoaFisica[]>(url);
  }

  getPessoasFisicasEntidadeRL(idEntidade: number, idPessoaFisica: number): Observable<PessoaFisica> {
    const url = environment.apiUrl + '/pessoaFisica/entidade/responsavelLegal/' + idEntidade + '/' + idPessoaFisica;
    return this.http.get<PessoaFisica>(url);
  }

  getPessoasFisicasPorCpf(cpf: string): Observable<PessoaFisica> {
    const url = environment.apiUrl + '/pessoaFisica/cpf/' + cpf;
    return this.http.get<PessoaFisica>(url);
  }

  criarPessoaFisica(pessoaFisica: PessoaFisica): Observable<PessoaFisica> {

    return this.http.post<PessoaFisica>(environment.apiUrl + '/pessoaFisica/', pessoaFisica);
  }

  cadastroPessoaFisica(pessoaFisica: PessoaFisica): Observable<PessoaFisica> {

    return this.http.post<PessoaFisica>(environment.apiUrl + '/pessoaFisica/cadastro/', pessoaFisica);
  }

  atualizarPessoaFisica(pessoaFisica: PessoaFisica): Observable<PessoaFisica> {
    console.log('----------PessoaFisicaService atualizarPessoaFisica>>>>', pessoaFisica);

    return this.http.put<PessoaFisica>(environment.apiUrl + '/pessoaFisica/', pessoaFisica);
  }

  inativarPessoaFisica(pessoaFisica: PessoaFisica): Observable<Object> {
    const url = environment.apiUrl + '/pessoaFisica/inativar';
    console.log('----------PessoaFisicaService inativarPessoaFisica>>>>', url);

    return this.http.put(url, pessoaFisica);
  }

  geraRelacionamentoPJPF(pessoaFisica: PessoaFisica): Observable<PessoaFisica> {

    return this.http.put<PessoaFisica>(environment.apiUrl + '/pessoaFisica/geraRelacionamento/', pessoaFisica);
  }

  getRTPessoasFisicasDoPlano(id: number): Observable<PessoaFisica[]> {
    return this.http.get<PessoaFisica[]>(environment.apiUrl + '/planopessoafisica/pessoasfisicas/' + id);
  }

  getPessoaFisicaAtiva(id: number): Observable<PessoaFisica> {
    return this.http.get<PessoaFisica>(environment.apiUrl + '/pessoaFisica/ativa/' + id);
  }

  getResponsavelLegalContrato(idContrato: number): Observable<PessoaFisica> {
    return this.http.get<PessoaFisica>(environment.apiUrl + '/pessoaFisica/contratoDmlu/' + idContrato);
  }
}
