<sgr-menu></sgr-menu>

<div class="container">
  <ol class="breadcrumb" *ngIf="variaveisGlobais.menuRaiz=='mtrcc'">
    <!-- Fazer breadcrumb pra admin tbm -->
    <li class="breadcrumb-item"><a routerLink="/sgrIni">SGR</a></li>
    <li class="breadcrumb-item active" aria-current="page">MTRCC</li>
  </ol>
  <ol class="breadcrumb"
    *ngIf="variaveisGlobais.menuRaiz=='grandeGerador' || variaveisGlobais.menuRaiz=='transportador' || variaveisGlobais.menuRaiz=='destinoFinal' ">
    <li class="breadcrumb-item"><a routerLink="/sgrIni">SGR</a></li>
    <li class="breadcrumb-item"><a routerLink="/grandesGeradores">Grandes Geradores</a></li>
    <li class="breadcrumb-item active" aria-current="page" *ngIf="variaveisGlobais.menuRaiz=='grandeGerador'">Grande
      Gerador</li>
    <li class="breadcrumb-item active" aria-current="page" *ngIf="variaveisGlobais.menuRaiz=='transportador'">
      Transportadores</li>
    <li class="breadcrumb-item active" aria-current="page" *ngIf="variaveisGlobais.menuRaiz=='destinoFinal'">Destino
      Final</li>
  </ol>
  <ol class="breadcrumb" *ngIf="variaveisGlobais.menuRaiz=='gdr'">
    <li class="breadcrumb-item"><a routerLink="/sgrIni">SGR</a></li>
    <li class="breadcrumb-item active" aria-current="page">Gestão de Descarga de Resíduos - DMLU</li>
  </ol>

  <!-- <div class="row" *ngIf="variaveisGlobais.menuRaiz=='mtrcc' && !isPessoaFisicaVinculoEmpresa">
    <div class="col-12 form-group">
      <label>
        <font color="red"><b>INFORMAÇÃO!</b></font><br>
        &nbsp;&nbsp;&nbsp;Caso você tenha vínculo com uma ou mais empresas, por favor providenciar a inclusão da(s) mesma(s) antes de gerar o MTRCC.<br>
      </label>
      <label>
        &nbsp;&nbsp;&nbsp;Antes de gerar o MTRCC, favor atualizar, se necessário, as informações referentes à licença ambiental, endereço, veículo e responsável legal.<br>
      </label>
    </div>
  </div> -->

  <h1 class="page-header text-primary" *ngIf="variaveisGlobais.menuRaiz=='mtrcc'">
    MTRCC <br>
    <small>Empresas</small>
  </h1>
  <h1 class="page-header text-primary" *ngIf="variaveisGlobais.menuRaiz=='grandeGerador'">
    Grandes Geradores
  </h1>
  <h1 class="page-header text-primary" *ngIf="variaveisGlobais.menuRaiz=='transportador'">
    Empresas Prestadoras de Serviço
  </h1>
  <h1 class="page-header text-primary" *ngIf="variaveisGlobais.menuRaiz=='destinoFinal'">
    Empresas Prestadoras de Serviço
  </h1>
  <h1 class="page-header text-primary" *ngIf="variaveisGlobais.menuRaiz=='gdr'">
    Gestão de Descarga de Resíduos - DMLU
  </h1>

  <div class="row justify-content-between" *ngIf="variaveisGlobais.roleEntidade">
    <div class="col-sm-3">
      <button type="button" class="btn btn-primary mb-4" (click)="mostrarDialogParaNovo()">
        <i class="fa fa-plus pr-2"></i>Adicionar Empresa
      </button>
    </div>
    <div class="col-auto">
      <button class="btn btn-outline-secondary" (click)="informacaoCadastral(variaveisGlobais.pessoaFisica)">
        <i class="fa fa-info pr-2"></i>Informações cadastrais do Usuário Logado
      </button>
    </div>
  </div>
  <div class="mobile-overflow-x">


    <!-- <p-table #tabelaEntidades [value]="entidades" [paginator]="true" [responsive]="true" sortField="razaoSocial"
      [rows]="15" [totalRecords]="totalEntidades" pageLinks="10" [rowsPerPageOptions]="[15,30,50]"
      [globalFilterFields]="['razaoSocial','cnpj', 'numeroCnae', 'dataInclusao']" *ngIf="entidades"
      autoLayout="true"> -->



    <p-table #tabelaEntidades [value]="entidades" sortField="razaoSocial" [rows]="15" [totalRecords]="totalEntidades"
      pageLinks="10" [rowsPerPageOptions]="[15,30,50]" [paginator]="true" *ngIf="entidades"
      [globalFilterFields]="['razaoSocial','cnpj', 'numeroCnae', 'dataInclusao']">

      <ng-template pTemplate="caption" *ngIf="!variaveisGlobais.roleEntidade">

        <div class="row" *ngIf="variaveisGlobais.menuRaiz=='grandeGerador'">
          <div class="col-12 radios-inline form-group text-left">
            <p-radioButton name="group1" value="Todos" label="Todos" [(ngModel)]="selectedPesquisa" inputId="to"
              (click)="pesquisaEntidades('Todos')"></p-radioButton>
            <p-radioButton name="group1" value="Geradores" label="Grandes Geradores" [(ngModel)]="selectedPesquisa"
              inputId="ab" (click)="pesquisaEntidades('Geradores')"></p-radioButton>
            <p-radioButton name="group1" value="Transportadores" label="Transportadores" [(ngModel)]="selectedPesquisa"
              inputId="re" (click)="pesquisaEntidades('Transportadores')"></p-radioButton>
            <p-radioButton name="group1" value="DestinoFinal" label="Destino Final" [(ngModel)]="selectedPesquisa"
              inputId="an" (click)="pesquisaEntidades('DestinoFinal')"></p-radioButton>
          </div>
          <div class="col-md-6 form-group busca">
            <input type="text" class="form-control" pInputText size="40" placeholder="Filtrar entidades"
              (input)="tabelaEntidades.filterGlobal($event.target.value, 'contains')">
             <i class="fa fa-search" aria-hidden="true"></i>
          </div>
          <div class="col-auto form-group ml-auto num-resultados">
            <label for="numResultados">Quantidade:</label>
            <strong id="numResultados" class="text-primary">{{entidades.length}}</strong>
          </div>
        </div>
        <div class="row" *ngIf="variaveisGlobais.menuRaiz!='grandeGerador'">
          <div class="col-md-6 form-group busca">
            <input type="text" class="form-control" pInputText size="50" placeholder="Filtrar entidades"
              (input)="tabelaEntidades.filterGlobal($event.target.value, 'contains')">
              <i class="fa fa-search" aria-hidden="true"></i>
          </div>
          <div class="col-auto form-group ml-auto num-resultados">
            <label for="numResultados">Quantidade:</label>
            <strong id="numResultados" class="text-primary">{{entidades.length}}</strong>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="caption" *ngIf="!variaveisGlobais.roleEntidade">

        <div class="row" *ngIf="variaveisGlobais.menuRaiz=='grandeGerador'">
          <div class="col-12 radios-inline form-group text-left">
            <p-radioButton name="group1" value="Todos" label="Todos" [(ngModel)]="selectedPesquisa" inputId="to"
              (click)="pesquisaEntidades('Todos')"></p-radioButton>
            <p-radioButton name="group1" value="Geradores" label="Grandes Geradores" [(ngModel)]="selectedPesquisa"
              inputId="ab" (click)="pesquisaEntidades('Geradores')"></p-radioButton>
            <p-radioButton name="group1" value="Transportadores" label="Transportadores" [(ngModel)]="selectedPesquisa"
              inputId="re" (click)="pesquisaEntidades('Transportadores')"></p-radioButton>
            <p-radioButton name="group1" value="DestinoFinal" label="Destino Final" [(ngModel)]="selectedPesquisa"
              inputId="an" (click)="pesquisaEntidades('DestinoFinal')"></p-radioButton>
          </div>
          <div class="col-md-6 form-group busca">
            <input type="text" class="form-control" pInputText size="40" placeholder="Filtrar entidades"
              (input)="tabelaEntidades.filterGlobal($event.target.value, 'contains')">
              <i class="fa fa-search" aria-hidden="true"></i>
          </div>
          <div class="col-auto form-group ml-auto num-resultados">
            <label for="numResultados">Quantidade:</label>
            <strong id="numResultados" class="text-primary">{{entidades.length}}</strong>
          </div>
        </div>

        <div class="row justify-content-between py-3" *ngIf="variaveisGlobais.menuRaiz=='mtrcc'">
          <div class="col-auto num-resultados busca">
            <input type="text" class="form-control"  pInputText size="50" placeholder="Filtrar entidades"
              (input)="tabelaEntidades.filterGlobal($event.target.value, 'contains')">
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
          <div class="col-auto num-resultados">
            <label for="numResultados">Quantidade:</label>
            <strong id="numResultados" class="text-primary">{{entidades.length}}</strong>
          </div>
        </div>

        <div *ngIf="variaveisGlobais.menuRaiz=='gdr'">
          <div class="row">
            <div class="col-auto">
              <label for="">Situação do Contrato:</label>
            </div>
            <div class="radios-inline col-auto form-group text-left">
               <p-radioButton name="group1" value="Vigente" label="Vigente" [(ngModel)]="selectedPesquisa" inputId="vi"
              (click)="pesquisaEntidadesGdr('Contrato vigente')"></p-radioButton>&nbsp;
              <p-radioButton name="group1" value="Encerrado" label="Encerrado" [(ngModel)]="selectedPesquisa" inputId="en"
                (click)="pesquisaEntidadesGdr('Contrato encerrado')"></p-radioButton>&nbsp;
              <p-radioButton name="group1" value="Em Andamento" label="Em Andamento" [(ngModel)]="selectedPesquisa"
                inputId="an" (click)="pesquisaEntidadesGdr('ContratoEmAndamento')"></p-radioButton>&nbsp;
              <p-radioButton name="group1" value="Todos" label="Todos" [(ngModel)]="selectedPesquisa" inputId="to"
                (click)="pesquisaEntidadesGdr('Todos')"></p-radioButton>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 form-group busca">
              <input type="text" pInputText size="30" placeholder="Filtrar entidades"
                (input)="tabelaEntidades.filterGlobal($event.target.value, 'contains')" class="form-control">
                <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <div class="col-auto form-group ml-auto num-resultados">
              <label for="numResultados">Quantidade:</label>
              <strong id="numResultados" class="text-primary">{{entidades.length}}</strong>
            </div>
          </div>   
        </div>
      </ng-template>
      <ng-template pTemplate="header" *ngIf="variaveisGlobais.roleEntidade">
        <tr>
          <th pSortableColumn="razaoSocial" style="width: 350px;" *ngIf="variaveisGlobais.menuRaiz==='mtrcc'">
            Razão Social
            <p-sortIcon field="razaoSocial"></p-sortIcon>
          </th>
          <th pSortableColumn="razaoSocial" style="width: 300px;" *ngIf="variaveisGlobais.menuRaiz==='grandeGerador'">
            Razão Social
            <p-sortIcon field="razaoSocial"></p-sortIcon>
          </th>
          <th pSortableColumn="razaoSocial" style="width: 220px;" *ngIf="variaveisGlobais.menuRaiz==='gdr'">
            Razão Social
            <p-sortIcon field="razaoSocial"></p-sortIcon>
          </th>
          <th pSortableColumn="cnpj" style="width: 100px;">
            CNPJ
            <p-sortIcon field="cnpj"></p-sortIcon>
          </th>
          <th pSortableColumn="numeroCnae" style="width: 80px;">
            CNAE
            <p-sortIcon field="numeroCnae"></p-sortIcon>
          </th>
          <th pSortableColumn="dataInclusao" style="width: 70px;">
            Cadastro
            <p-sortIcon field="dataInclusao"></p-sortIcon>
          </th>
          <th style="width: 80px;" *ngIf="variaveisGlobais.menuRaiz==='mtrcc'">Ações</th>
          <th style="width: 170px;" *ngIf="variaveisGlobais.menuRaiz==='grandeGerador'">Ações</th>
          <th style="width: 270px;" *ngIf="variaveisGlobais.menuRaiz==='gdr'">Ações</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="header" *ngIf="!variaveisGlobais.roleEntidade">
        <tr>
          <th pSortableColumn="razaoSocial" style="width: 250px;" *ngIf="variaveisGlobais.menuRaiz==='mtrcc'">
            Razão Social
            <p-sortIcon field="razaoSocial"></p-sortIcon>
          </th>
          <th pSortableColumn="razaoSocial" style="width: 200px;" *ngIf="variaveisGlobais.menuRaiz==='grandeGerador'">
            Razão Social
            <p-sortIcon field="razaoSocial"></p-sortIcon>
          </th>
          <th pSortableColumn="razaoSocial" style="width: 250px;" *ngIf="variaveisGlobais.menuRaiz==='gdr'">
            Razão Social
            <p-sortIcon field="razaoSocial"></p-sortIcon>
          </th>
          <th pSortableColumn="cnpj" style="width: 100px;">
            CNPJ
            <p-sortIcon field="cnpj"></p-sortIcon>
          </th>
          <th pSortableColumn="numeroCnae" style="width: 80px;" *ngIf="variaveisGlobais.menuRaiz!=='gdr'">
            CNAE
            <p-sortIcon field="numeroCnae"></p-sortIcon>
          </th>
          <th pSortableColumn="dataInclusao" style="width: 70px;" *ngIf="variaveisGlobais.menuRaiz!=='gdr'">
            Cadastro
            <p-sortIcon field="dataInclusao"></p-sortIcon>
          </th>
          <th style="width: 80px;" *ngIf="variaveisGlobais.menuRaiz==='gdr'">
            N.Contrato
          </th>
          <th style="width: 100px;" *ngIf="variaveisGlobais.menuRaiz==='gdr'">
            Status
          </th>
          <th style="width: 240px;" *ngIf="variaveisGlobais.menuRaiz==='mtrcc'">Ações</th>
          <th style="width: 270px;" *ngIf="variaveisGlobais.menuRaiz==='grandeGerador'">Ações</th>
          <th style="width: 220px;" *ngIf="variaveisGlobais.menuRaiz==='gdr'">Ações</th>
        </tr>
      </ng-template>


      <ng-template pTemplate="body" let-entidade>
        <tr>
          <td>{{entidade.razaoSocial}}</td>
          <td>{{entidade.cnpj}}</td>
          <td *ngIf="variaveisGlobais.menuRaiz!=='gdr' || variaveisGlobais.roleEntidade">{{entidade.numeroCnae}}</td>
          <td *ngIf="variaveisGlobais.menuRaiz!=='gdr' || variaveisGlobais.roleEntidade">{{entidade.dataInclusao | date:
            'dd/MM/yyyy' : 'pt' }}</td>
          <td *ngIf="variaveisGlobais.menuRaiz==='gdr' && !variaveisGlobais.roleEntidade">
            {{entidade.gdrNumeroContrato}}
          </td>
          <td *ngIf="variaveisGlobais.menuRaiz==='gdr' && !variaveisGlobais.roleEntidade">
            {{entidade.gdrSituacaoContrato}}
          </td>
          <td>
            <div class="botoes">
              <button class="btn btn-outline-secondary" (click)="mostrarDialogParaEditar(entidade.id)"><i
                  class="fa fa-info pr-1"></i>Detalhes</button>
              <button title="Resíduos" class="btn btn-outline-secondary" (click)="mostrarResiduosGerados(entidade.id)"
                *ngIf="podeExibirResiduos(entidade.possuiPlanos)"><i class="fa fa-recycle pr-1"></i>Resíduos</button>
              <button title="Manifestos" class="btn btn-outline-secondary" (click)="mostrarManifestos(entidade.id)"
                *ngIf="podeExibirManifestos(entidade)"><i class="fa fa-file pr-1"></i>Manifestos</button>
              <!-- <button title="Resultados" class="btn btn-outline-secondary" (click)="mostrarResultados(entidade.id)"
                *ngIf="podeExibirManifestos(entidade)"><i class="fa fa-recycle pr-1"></i>Resultados</button> -->
              <button title="Prestadoras Serviço" class="btn btn-outline-secondary pr-1"
                (click)="mostrarVinculosAtuais(entidade.id)"
                *ngIf="!variaveisGlobais.roleEntidade && variaveisGlobais.menuRaiz=='grandeGerador' && entidade.possuiPlanos"><i
                  class="fa fa-handshake-o pr-1"></i>Prestadoras Serviço</button>
              <button title="Contrato" class="btn btn-outline-secondary" (click)="mostrarContratoDMLU(entidade.id)"
                *ngIf="variaveisGlobais.menuRaiz=='gdr'"><i class="fa fa-recycle pr-1"></i>Contrato</button>
              <button title="Certificado" class="btn btn-outline-secondary"
                (click)="mostrarCertificadoDMLU(entidade.id)"
                *ngIf="variaveisGlobais.menuRaiz=='gdr' && entidade.gdrCertificado !== null">
                <i class="fa fa-recycle pr-1"></i>Certificado</button>
              <button title="Habilitar/Desabilitar" class="btn btn-outline-secondary"
                (click)="habilitarDesabilitarEmpresa(entidade.id)"
                *ngIf="variaveisGlobais.menuRaiz=='mtrcc' && !entidade.desabilitadaMtrcc && !variaveisGlobais.roleEntidade">
                <i class="fa fa-ban pr-1"></i>Desabilitar</button>
              <button title="Habilitar/Desabilitar" class="btn btn-outline-secondary"
                (click)="habilitarDesabilitarEmpresa(entidade.id)"
                *ngIf="variaveisGlobais.menuRaiz=='mtrcc' && entidade.desabilitadaMtrcc && !variaveisGlobais.roleEntidade">
                <i class="fa fa-check pr-1"></i>Habilitar</button>
<!--
                <button title="Balança" class="btn btn-outline-secondary" (click)="balanca(entidade.codigoDmlu)"
                *ngIf="variaveisGlobais.menuRaiz=='gdr' && variaveisGlobais.roleEntidade && entidade.codigoDmlu !== null">
                <i class="fa fa-check pr-1"></i>Balança</button>
-->                
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-dialog [header]="tituloDialogEdit" [(visible)]="mostrarDialogEdit" [contentStyle]="{'max-height':'800px'}"
  [responsive]="true" showEffect="fade" [modal]="true" resizable="true" *ngIf="entidadeEdit!==null"
  class="scroller container">
  <p-tabView [style]="{'width':'1250px'}">
    <p-tabPanel header="Dados da Empresa" [selected]="true">

      <span *ngIf="insertMode && controlaMensagemFazenda">
        <strong class="text-danger">INFORMAÇÃO!</strong>&nbsp;Caso algumas das informações da Empresa não estejam
        atualizadas, favor providenciar as necessárias
        alterações na Secretaria Municipal da Fazenda. <a
          href="https://servicos.procempa.com.br/servicedesk/customer/portal/12/create/194">Acesse aqui!</a><br><br>
      </span>

      <div class="row" *ngIf="entidadeEdit!==null && entidadeEdit!==undefined">
        <div class="col-12 col-sm-4 form-group">
          <label for="vin">CNPJ</label>
          <input pInputText id="cnpj" [(ngModel)]="entidadeEdit.cnpj" *ngIf="!insertMode" disabled
            class="form-control" />
          <input pInputText id="cnpj" [(ngModel)]="entidadeEdit.cnpj" *ngIf="insertMode"
            (blur)="verificaCnpjExiste(entidadeEdit.cnpj)" class="form-control" />
        </div>
        <div class="col-12 col-sm-8 form-group">
          <label for="vin">Nome Fantasia</label>
          <input pInputText id="nomeFantasia" [(ngModel)]="entidadeEdit.nomeFantasia" size="100" class="form-control" />
        </div>
        <div class="col-12 form-group">
          <label for="vin">Razão Social</label>
          <input pInputText id="razaoSocial" [(ngModel)]="entidadeEdit.razaoSocial" size="135"
            [disabled]='!controlaRazaoSocialEnt' class="form-control" />
        </div>
        <div class="col-12 col-sm-4 form-group">
          <label for="vin">Telefone</label>
          <!-- <p-inputMask id="fone" mask="(99) 9999-9999?9" [(ngModel)]="entidadeEdit.telefone" size="15"
            [disabled]='!controlaTelefoneEnt'></p-inputMask> -->
          <p-inputMask id="fone" mask="(99) 9999-9999?9" [(ngModel)]="entidadeEdit.telefone" size="15"></p-inputMask>
        </div>
        <div class="col-12 col-sm-8 form-group">
          <label for="vin">Email</label>
          <input pInputText id="email" [(ngModel)]="entidadeEdit.email" size="55" [disabled]='!controlaEmailEnt'
            class="form-control" />
        </div>
        <div class="col-12 col-sm-4 form-group" *ngIf="variaveisGlobais.menuRaiz=='grandeGerador'">
          <label for="vin">Alvará</label>
          <input pInputText id="alvara" [(ngModel)]="entidadeEdit.alvara" class="form-control" />
        </div>
        <div class="col-12 col-sm-4 form-group">
          <label for="vin">Número CNAE</label>
          <input pInputText id="numeroCnae" [(ngModel)]="entidadeEdit.numeroCnae" [disabled]='!controlaNumeroCnaeEnt'
            class="form-control" />
        </div>
        <div class="col-12 form-group"
          [class]=" variaveisGlobais.menuRaiz=='grandeGerador' ? 'col-sm-4 form-group' : 'col-sm-8 form-group' ">
          <label for="vin">Descrição CNAE</label>
          <input pInputText id="descricaoCnae" [(ngModel)]="entidadeEdit.descricaoCnae" size="100"
            [disabled]='!controlaDescricaoCnaeEnt' class="form-control" />
        </div>


      <div *ngIf="!insertMode">

        <p-fieldset class="col-12 mb-3" [legend]="tituloLicenca" *ngIf="isDestinoFinal">
          <label class="pr-2">Licenças Ambientais</label>
          <button class="btn btn-outline-primary btn-sm mb-3 mt-2" (click)="informaLicenca()"
            *ngIf="variaveisGlobais.roleEntidade && !isPMPA"><i class="fa fa-plus pr-2"></i>Adicionar Licenças
            Ambientais</button>
          <div>
            <p-table [value]="licencas" class="table table-responsive table-sm">

              <ng-template pTemplate="emptymessage">
                Licença Ambiental ainda não incluída.
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 130px;">Licença</th>
                  <th style="width: 210px;">Órgão Emissor</th>
                  <th style="width: 130px;">Validade</th>
                  <th style="width: 50px;" *ngIf="variaveisGlobais.roleEntidade && !isPMPA">Ações</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-licenca>
                <tr>
                  <td>
                    {{licenca.licenca}}
                  </td>
                  <td>
                    <span *ngIf='licenca.orgaoEmissor !== null'>
                      {{licenca.orgaoEmissor}}
                    </span>
                  </td>
                  <td>
                    {{licenca.dataValidade | date: 'dd/MM/yyyy' : 'pt'}}
                  </td>
                  <td *ngIf="variaveisGlobais.roleEntidade && !isPMPA">
                    <button title="Excluir" class="btn btn-outline-secondary btn-sm"
                      (click)="excluirLicenca(licenca.id)"> <i class="pi pi-trash"></i></button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>

        </p-fieldset>
        </div>

        <div class="col-12" *ngIf="isEntidade()">

        </div>
      </div>

      <label class="pr-2">Endereços</label>
      <button *ngIf="variaveisGlobais.roleEntidade && !isPMPA" class="btn btn-outline-primary btn-sm m-2"
        (click)="informaEnderecoPoa(true)">
        <i class="fa fa-plus pr-2"></i>Adicionar Endereço de Porto Alegre</button>

      <button *ngIf="variaveisGlobais.roleEntidade && !isPMPA" class="btn btn-outline-primary btn-sm m-2"
        (click)="informaEnderecoPoa(false)">
        <i class="fa fa-plus pr-2"></i>Adicionar Endereço de Outros Municípios</button>
      <p-table [value]="enderecos" *ngIf="!insertMode">

        <ng-template pTemplate="emptymessage">
          Endereço ainda não incluido.
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th style="width: 200px;" *ngIf="isPMPA">Identificação</th>
            <th style="width: 330px;" *ngIf="!variaveisGlobais.roleEntidade">
              Endereço (clique no endereço para informações do E.U.)</th>
            <th style="width: 330px;" *ngIf="variaveisGlobais.roleEntidade">
              Endereço</th>
            <th style="width: 100px;">Município</th>
            <th style="width: 100px;">Observação</th>
            <th style="width: 100px;">Licença</th>
            <th style="width: 150px;" *ngIf="!isPMPA">Ações</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-endereco>
          <tr>
            <td *ngIf="isPMPA">
              {{endereco.identificacao}}
            </td>
            <td *ngIf="!variaveisGlobais.roleEntidade && endereco.municipio === 'Porto Alegre'">
              <a [routerLink]=""
                (click)="mostraExpedienteUnico(endereco.codigoLogradouro, endereco.numero)">{{endereco.enderecoFormatado}}</a>
            </td>
            <td *ngIf="variaveisGlobais.roleEntidade || endereco.municipio !== 'Porto Alegre'">
              {{endereco.enderecoFormatado}}
            </td>
            <td>
              {{endereco.municipio}}
            </td>
            <td>
              {{endereco.observacao}}
            </td>
            <td>
              <span *ngIf="endereco.licencaAmbientalDO !== null">{{endereco.licencaAmbientalDO?.licenca}}</span>
              <span *ngIf="endereco.licencaAmbientalDO === null && endereco.isentoLicenca">Isento</span>
            </td>
            <td *ngIf="!isPMPA">
              <div class="botoes">
                <button class="btn btn-outline-secondary" (click)="atualizaEndereco(endereco.id)"><i
                    class="fa fa-info pr-2"></i>Detalhes</button>
                <button class="btn btn-outline-secondary" (click)="excluirEndereco(endereco.id)"
                  *ngIf="variaveisGlobais.roleEntidade"><i class="pi pi-trash"></i></button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <div *ngIf="insertMode && endereco.nomeLogradouro !== null && endereco.nomeLogradouro !== ''">
        <b>ENDEREÇO&nbsp;da&nbsp;Empresa:</b>&nbsp;{{endereco.nomeLogradouro}},&nbsp;{{endereco.numero}}&nbsp;-&nbsp;{{endereco.municipio}}
      </div>

      <div *ngIf="!insertMode">

        <div *ngIf="variaveisGlobais.menuRaiz!='grandeGerador' && variaveisGlobais.menuRaiz!='destinoFinal' && !isPMPA">
          <br>
          <label class="pr-2">Veículos</label>
          <button class="btn btn-outline-primary btn-sm m-2" (click)="informaVeiculo()"
            *ngIf="variaveisGlobais.roleEntidade"><i class="fa fa-plus pr-2"></i>Adicionar Veículos</button>
          <p-table [value]="veiculos">

            <ng-template pTemplate="emptymessage">
              Veículo ainda não incluido.
            </ng-template>

            <ng-template pTemplate="header">
              <tr>
                <th style="width: 130px;">Placa</th>
                <th style="width: 210px;">Renavam</th>
                <th style="width: 50px;" *ngIf="variaveisGlobais.roleEntidade">Ações</th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-veiculo>
              <tr>
                <td>
                  {{veiculo.placa}}
                </td>
                <td>
                  {{veiculo.renavam}}
                </td>
                <td *ngIf="variaveisGlobais.roleEntidade">
                  <button title="Excluir" class="btn btn-outline-secondary btn-sm" (click)="excluirVeiculo(veiculo.id)">
                    <i class="pi pi-trash"></i></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <br>

        <label class="h4 pr-2">Usuários
        </label>
        <button type="button" class="btn btn-outline-primary btn-sm m-2" (click)="addPessoaFisica()"
          *ngIf="variaveisGlobais.roleEntidade && !isPMPA">
          <i class="fa fa-plus pr-2"></i>Adicionar Usuário</button>
        <br>
        <p-table class="mt-1" [value]="pessoaFisicas">

          <ng-template pTemplate="emptymessage">
            Não há.
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th style="width: 130px;">Nome</th>
              <th style="width: 100px;">Função</th>
              <th style="width: 210px;">Email</th>
              <th style="width: 100px;">Comercial</th>
              <th style="width: 100px;">Celular</th>
              <th style="width: 210px;" *ngIf="!isPMPA">Ações</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-int>
            <tr>
              <td>
                {{int.nomeCompleto}}
              </td>
              <td>

                <span *ngIf='int.rSecretario === true'>Secretário</span>
                <span *ngIf='int.rLegal === true'>Resp. Legal</span>
                <span *ngIf='int.rusrLegal === true'>Usuário</span>
                <span *ngIf='int.rtElaboracao === true && int.rtExecucao === false'>RT Elaboração</span>
                <span *ngIf='int.rtElaboracao === false && int.rtExecucao === true'>RT Execução</span>
                <span *ngIf='int.rtElaboracao === true && int.rtExecucao === true'>RT Ambos</span>
              </td>
              <td>
                {{int.email}}
              </td>
              <td>
                {{int.telefone}}
              </td>
              <td>{{int.celular}}</td>
              <td *ngIf="!isPMPA">
                <button class="btn btn-outline-secondary mr-2" (click)="mostrarDialogParaEditarPessoaFisica(int.id)"><i
                    class="fa fa-info pr-2"></i>Detalhes</button>
                <span *ngIf='!int.ativo' class="text-danger">Inativo</span>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <br>
      </div>
      <div class="text-right botoes">
        <button type="button" icon="pi pi-times" class=" btn btn-outline-primary"
          (click)="mostrarDialogEdit = false">Cancelar</button>
        <button type="button" icon="pi pi-check" class="btn btn-primary" (click)="salvar()"
          *ngIf="podeSalvar && !insertMode">Salvar</button>
        <button type="button" icon="pi pi-check" class="btn btn-primary" (click)="salvarNovo()"
          *ngIf="insertMode">Salvar</button>
      </div>
    </p-tabPanel>

    <p-tabPanel header="Anexos"
      *ngIf="(variaveisGlobais.menuRaiz=='grandeGerador' || variaveisGlobais.menuRaiz=='gdr') && !insertMode"
      class="scroller">

      <div class="row justify-content-between" *ngIf="!insertMode">
        <div class="col-12" layout-align="center center">
          <label for="vin">
            <font color="red"><b>IMPORTANTE: Anexar somente documentos em PDF ou IMAGEM.</b></font>
          </label>
        </div>

        <div class="col-auto">
          <label for="vin">Arquivo</label>
          <br>
          <input type="file" class="form-control-file" [(ngModel)]="anexoSel" (change)="onFileChange($event)">
        </div>

        <div class="col-auto mb-3">
          <label for="vin">&nbsp;</label>
          <br>
          <button class="btn btn-outline-secondary tooltip-item" type="button" (click)="clearFile();">
            <div class="css-tooltip text-nowrap mr-2">Limpar arquivo</div>
          </button>
        </div>
        <div class="col-auto form-group">
          <label for="vin">Tipo Anexo</label>
          <p-dropdown [options]="tiposAnexos" [(ngModel)]="tipoAnexo" placeholder="Selecione"></p-dropdown>
        </div>
        <div class="col-auto">
          <label for="vin">&nbsp;</label>
          <br>
          <button class="btn btn-outline-primary tooltip-item" type="button" (click)="persisteAnexo();">
            <div class="css-tooltip">Salvar</div>
          </button>
        </div>
      </div>
      <br>
      <div *ngIf='anexos.length > 0'>
        <p-table #tabelaAnexos [value]="anexos" [paginator]="true" sortField="fileName" [rows]="10"
          [globalFilterFields]="['fileName', 'tipoAnexo', 'dataInclusao']">
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="fileName" style="width: 240px">Nome do anexo
                <p-sortIcon field="fileName"></p-sortIcon>
              </th>
              <th pSortableColumn="tipoAnexo" style="width: 100px;">Tipo do anexo
                <p-sortIcon field="tipoAnexo"></p-sortIcon>
              </th>
              <th pSortableColumn="dataInclusao" style="width: 110px;">Dt. Inclusão
                <p-sortIcon field="dataInclusao"></p-sortIcon>
              </th>
              <th style="width: 30px;" *ngIf="variaveisGlobais.menuRaiz==='gdr'">Situação</th>
              <th style="width: 100px;">Ações</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-anexo>
            <tr>
              <td style="word-wrap: break-word">
                <a class="link-anexo" style="cursor:pointer" (click)="visualizaAnexo(anexo);">{{ anexo.fileName }}</a>
              </td>
              <td>
                <div>{{ anexo.tipoAnexo }}</div>
              </td>
              <td>
                <div>{{ anexo.dataInclusao | date: 'dd/MM/yyyy' : 'pt'}}</div>
              </td>
              <td *ngIf="variaveisGlobais.menuRaiz==='gdr'">
                <div *ngIf="anexo.situacao || anexo.situacao===null">Ativo</div>
                <div *ngIf="!anexo.situacao && anexo.situacao!==null">Inativo</div>
              </td>
              <td *ngIf="variaveisGlobais.menuRaiz==='gdr' && !variaveisGlobais.roleEntidade">
                <button class="btn btn-outline-secondary css-tooltip" (click)="situacaoAnexo(anexo, 'Ativar');"
                  *ngIf="!anexo.situacao && anexo.situacao!==null">
                  Ativar</button>
                <button class="btn btn-outline-secondary css-tooltip" (click)="situacaoAnexo(anexo, 'Inativar');"
                  *ngIf="anexo.situacao || anexo.situacao===null">
                  Inativar</button>
                &nbsp;&nbsp;
                <button class="btn btn-outline-secondary css-tooltip" (click)="excluirAnexo(anexo);">
                  <i class="pi pi-trash pr-2"></i>Excluir</button>
              </td>
              <td *ngIf="variaveisGlobais.menuRaiz!=='gdr' || variaveisGlobais.roleEntidade">
                <button class="btn btn-outline-secondary css-tooltip" (click)="excluirAnexo(anexo);">
                  <i class="pi pi-trash pr-2"></i>Excluir</button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div *ngIf='anexos.length < 1 && comAnexos'>
        Empresa sem anexos!
      </div>
      <div *ngIf='!comAnexos'>
        Carregando anexos ....
      </div>



    </p-tabPanel>
  </p-tabView>

</p-dialog>

<p-dialog [header]="tituloDialogEditPessoaFisica" [(visible)]="mostrarDialogoPessoaFisica" [responsive]="true"
  showEffect="fade" [modal]="true" [width]="850" [contentStyle]="{'max-height':'800px'}" resizable="true"
  *ngIf="pessoaFisica!==null && entidadeEdit!==null" class="scroller">

  <div class="row">

    <div class="col-sm-4 col-md-auto form-group">
      <label for="cpf" class="d-block">CPF</label>
      <p-inputMask id="cpf" mask="999.999.999-99" [(ngModel)]="pessoaFisica.cpf" placeholder="__.___.___/____-__"
        size="20" *ngIf="!insertModePF" disabled></p-inputMask>
      <p-inputMask id="cpf" mask="999.999.999-99" [(ngModel)]="pessoaFisica.cpf" placeholder="__.___.___/____-__"
        size="20" *ngIf="insertModePF" (onBlur)="verificaCpfExiste(pessoaFisica.cpf)"></p-inputMask>
    </div>

    <div class="col-sm-8 form-group">
      <label for="float-input">Nome</label>
      <input id="float-input" type="text" size="60" pInputText [(ngModel)]="pessoaFisica.nomeCompleto"
        class="form-control">
    </div>

    <div class="col-sm-6 form-group">
      <label for="email">Email</label>
      <input id="email" type="text" class="form-control" pInputText [(ngModel)]="pessoaFisica.email" size="40" />
    </div>

    <div class="col-sm-3 form-group">
      <label for="fone">Fone Comercial</label>
      <p-inputMask id="fone" mask="(99) 9999-9999?9" [(ngModel)]="pessoaFisica.telefone" size="15"></p-inputMask>
    </div>

    <div class="col-sm-3 form-group">
      <label for="fone">Celular</label>
      <p-inputMask id="fone" mask="(99) 99999-999?9" [(ngModel)]="pessoaFisica.celular" size="15"></p-inputMask>
    </div>
    <div class="col-sm-6 form-group" *ngIf="isPMPA">
      <label for="vin">Secretaria/Órgão</label>
      <p-dropdown [options]="secretarias" [(ngModel)]="idSecretaria" placeholder="Selecione"></p-dropdown>
    </div>
    <div class="col-sm-6 form-group">
      <label for="vin">Situação</label>
      <div>
        <p-radioButton name="prodAtivo" [value]="true" label="Ativo" [(ngModel)]="pessoaFisica.ativo"></p-radioButton>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <p-radioButton name="prodAtivo" [value]="false" label="Inativo" [(ngModel)]="pessoaFisica.ativo">
        </p-radioButton>
      </div>

    </div>

    <div class="col-12 form-group">
      <label for="fone">Função</label>
      <br>
      <p-radioButton name="funcao" value="rLegal" label="Responsável Legal" [(ngModel)]="pfFuncao" inputId="f01"
        (click)="controlaFuncao(pfFuncao)"></p-radioButton>&nbsp;&nbsp;&nbsp;
      <p-radioButton name="funcao" value="rusrLegal" label="Usuário" [(ngModel)]="pfFuncao" inputId="f02"
        (click)="controlaFuncao(pfFuncao)"></p-radioButton>&nbsp;&nbsp;&nbsp;
      <p-checkbox name="group1" value="rElaboracao" label="RT Elaboração" [(ngModel)]="pfFuncaoRT" inputId="rtel"
        *ngIf="variaveisGlobais.menuRaiz==='grandeGerador' && variaveisGlobais.roleEntidade"
        (click)="controlaFuncao(pfFuncaoRT)"></p-checkbox>&nbsp;&nbsp;&nbsp;
      <p-checkbox name="group1" value="rExecucao" label="RT Execução" [(ngModel)]="pfFuncaoRT" inputId="rtex"
        *ngIf="variaveisGlobais.menuRaiz==='grandeGerador' && variaveisGlobais.roleEntidade"
        (click)="controlaFuncao(pfFuncaoRT)"></p-checkbox>&nbsp;&nbsp;&nbsp;


    </div>

  </div>
  <div style="text-align: right;">
    <button type="button" icon="fa fa-floppy-o" class="btn btn-primary" *ngIf="podeSalvar"
      (click)="salvarPessoaFisica()">Salvar</button>
  </div>

</p-dialog>


<p-dialog header="Informações Cadastrais" [(visible)]="mostrarDialogoPFLogada" [responsive]="true" showEffect="fade"
  [modal]="true" [width]="850" [contentStyle]="{'max-height':'800px'}" resizable="true" class="scroller">

  <div class="row">

    <div class="col-sm-4 col-md-auto form-group">
      <label for="cpf" class="d-block">CPF</label>
      <p-inputMask id="cpf" mask="999.999.999-99" [(ngModel)]="pessoaFisica.cpf" placeholder="__.___.___/____-__"
        size="20" disabled></p-inputMask>
      <!-- <p-inputMask id="cpf" mask="999.999.999-99" [(ngModel)]="pessoaFisica.cpf" placeholder="__.___.___/____-__" size="20" *ngIf="insertModePF" (onBlur)="verificaCpfExiste(pessoaFisica.cpf)"></p-inputMask> -->
    </div>

    <div class="col-sm-8 form-group">
      <label for="float-input">Nome</label>
      <input id="float-input" type="text" size="60" pInputText [(ngModel)]="pessoaFisica.nomeCompleto"
        class="form-control">
    </div>

    <div class="col-sm-6 form-group">
      <label for="email">Email</label>
      <input id="email" type="text" class="form-control" pInputText [(ngModel)]="pessoaFisica.email" size="40" />
    </div>

    <div class="col-sm-3 form-group">
      <label for="fone">Fone Comercial</label>
      <p-inputMask id="fone" mask="(99) 9999-9999?9" [(ngModel)]="pessoaFisica.telefone" size="15"></p-inputMask>
    </div>

    <div class="col-sm-3 form-group">
      <label for="fone">Celular</label>
      <p-inputMask id="fone" mask="(99) 99999-999?9" [(ngModel)]="pessoaFisica.celular" size="15"></p-inputMask>
    </div>


    <div class="col-sm-12 form-group">
      <label class="pr-2">Licenças Ambientais - caso você seja destinador final (que recebe resíduos do MTRCC), favor
        incluir a licença ambiental.</label>
    </div>
    <div class="col-sm-12 form-group">
      <button class="btn btn-outline-primary btn-sm" (click)="informaLicenca()"
        *ngIf="variaveisGlobais.roleEntidade && !isPMPA"><i class="fa fa-plus pr-2"></i>Adicionar Licenças
        Ambientais</button>
    </div>
    <div class="col-sm-12 form-group">
      <p-table [value]="licencas" class="table table-responsive table-sm">

        <ng-template pTemplate="emptymessage">
          Licença Ambiental ainda não incluída.
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 130px;">Licença</th>
            <th style="width: 210px;">Órgão Emissor</th>
            <th style="width: 130px;">Validade</th>
            <th style="width: 50px;" *ngIf="variaveisGlobais.roleEntidade && !isPMPA">Ações</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-licenca>
          <tr>
            <td>
              {{licenca.licenca}}
            </td>
            <td>
              <span *ngIf='licenca.orgaoEmissor !== null'>
                {{licenca.orgaoEmissor}}
              </span>
            </td>
            <td>
              {{licenca.dataValidade | date: 'dd/MM/yyyy' : 'pt'}}
            </td>
            <td *ngIf="variaveisGlobais.roleEntidade && !isPMPA">
              <button title="Excluir" class="btn btn-outline-secondary btn-sm" (click)="excluirLicenca(licenca.id)"> <i
                  class="pi pi-trash"></i></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </div>
  <div style="text-align: right;">
    <button type="button" icon="fa fa-floppy-o" class="btn btn-primary"
      (click)="salvaPessoaFisicaLogada(pessoaFisica)">Salvar</button>
  </div>

</p-dialog>

<p-dialog header="Excluir Entidade" [(visible)]="mostrarDialogExcluir" [responsive]="true" showEffect="fade"
  [modal]="true" resizable="true" [width]="500">

  <h3 *ngIf="entidadeExcluir">Confirma a exclusão da entidade '{{entidadeExcluir.razaoSocial}}'?</h3>
  <div class="ui-g-12"></div>
  <div class="botoes text-right">
    <button type="button" pButton icon="pi pi-times" (click)="mostrarDialogExcluir = false"
      label="Não, cancelar"></button>
    <button type="button" pButton icon="pi pi-check" (click)="excluir()" label="Sim, excluir"></button>
  </div>
</p-dialog>


<p-dialog [header]="tituloDialogEditEndereco" [(visible)]="mostrarDialogoEnderecoPoa" [responsive]="true"
  showEffect="fade" [modal]="true" resizable="true" [height]="500" [width]="650"
  *ngIf="endereco!=null && endereco!==undefined" [style]="{'overflow-y': 'auto'}">

  <div class="ui-g" style="padding-bottom:550px;">
    <div class="row" procempaCdl [maxResults]="18" [codigo]="logradouroCdl?.codigoLogradouro"
      [numero]="logradouroCdl?.numero" [localizar]="true">
      <div class="col-sm-9 form-group">
        <label>Logradouro</label>
        <input cdlLogradouro [(ngModel)]="endereco.nomeLogradouro" name="logradouro" (seleciona)="selecionaCDL($event)"
          size="50" placeholder="Nome" class="form-control ui-state-default">
      </div>
      <div class="col-sm-3 form-group">
        <label>Número</label>
        <input cdlNumero [(ngModel)]="endereco.numero" name="numero" size="13" placeholder="Número"
          class="form-control ui-state-default">
      </div>
    </div>
    <div class="row">
      <div class="col-12 form-group">
        <label>Observações</label>
        <textarea pInputTextarea id="observacao" [(ngModel)]="endereco.observacao" [rows]="2" [cols]="70"
          class="form-control"></textarea>
      </div>
    </div>

    <div class="row" *ngIf="variaveisGlobais.menuRaiz=='mtrcc' && !insertMode">
      <div class="col-12 mb-3">
        <label for="vin">
          Somente preencha os campos abaixo em caso de obrigatoriedade da Licença Ambiental.<br>
          Selecione entre as Licenças ou marque o opção declarando a isenção.
        </label>
      </div>
      <div class="col-md-5 form-group">
        <p-dropdown [options]="licencasItem" [(ngModel)]="idLicenca" placeholder="Selecione" (onChange)="selLicenca()">
        </p-dropdown>
      </div>
      <div class="col-md-5 form-group">
        <p-checkbox label=" Declaração de isenção." [(ngModel)]="licencaIsento" binary="false" name='isentoLicenca'
          #isentoLicenca (onChange)="licencaIsencao(isentoLicenca)"></p-checkbox>
      </div>
      <!-- <div class="col-md-2 text-right">
        <button type="button" class="btn btn-primary" (click)="salvarEndereco()">Salvar</button>
      </div> -->
    </div>

    <div class="row">
      <div class="col-12 text-right">
        <button type="button" class="btn btn-primary" *ngIf="!insertMode" (click)="salvarEndereco()">Salvar</button>
        <button type="button" class="btn btn-primary" *ngIf="insertMode" (click)="salvarEndereco()">OK</button>
      </div>
    </div>
  </div>

</p-dialog>

<p-dialog [header]="tituloDialogEditEndereco" [(visible)]="mostrarDialogoEnderecoForaPoa" [responsive]="true"
  showEffect="fade" [modal]="true" resizable="true" [height]="500" [width]="650"
  *ngIf="endereco!==null && endereco!==undefined" [style]="{'overflow-y': 'auto'}">
  <div class="p-grid">
    <div class="p-col-9">
      <label for="vin">Logradouro</label>
      <br>
      <input pInputText id="nomeLogradouro" [(ngModel)]="endereco.nomeLogradouro" size="50" /> &nbsp;
    </div>
    <div class="p-col-3">
      <label for="vin">Número</label>
      <br>
      <input pInputText id="numero" style="width: 80px" [(ngModel)]="endereco.numero" />
    </div>
    <div class="p-col-12">
      <label for="vin">Município</label>
      <br>
      <input pInputText id="municipio" [(ngModel)]="endereco.municipio" size="25" />
    </div>
    <div class="p-col-12">
      <label>Observações</label>
      <br>
      <textarea pInputTextarea id="observacao" [(ngModel)]="endereco.observacao" [rows]="2" [cols]="70"></textarea>
    </div>
    <div class="row" *ngIf="variaveisGlobais.menuRaiz =='mtrcc' && !insertMode">

      <div class="p-col-12">
        <label for="vin">
          Somente preencha os campos abaixo em caso de obrigatoriedade da Licença Ambiental.<br>
          Selecione entre as Licenças ou marque o opção declarando a isenção.
        </label>
      </div>
      <div class="p-col-5" *ngIf="variaveisGlobais.menuRaiz =='mtrcc'">
        <p-dropdown [options]="licencasItem" [(ngModel)]="idLicenca" placeholder="Selecione" (onChange)="selLicenca()">
        </p-dropdown>
      </div>
      <div class="p-col-5" *ngIf="variaveisGlobais.menuRaiz =='mtrcc'">
        <p-checkbox label=" Declaração de isenção." [(ngModel)]="licencaIsento" binary="false" name='isentoLicenca'
          #isentoLicenca (onChange)="licencaIsencao(isentoLicenca)"></p-checkbox>
      </div>
    </div>
    <!-- <div class="p-col-2">
      <button type="button" class="btn btn-primary" (click)="salvarEndereco()">Salvar</button>
    </div> -->

    <div class="row">
      <div class="col-12 text-right">
        <button type="button" class="btn btn-primary" *ngIf="!insertMode" (click)="salvarEndereco()">Salvar</button>
        <button type="button" class="btn btn-primary" *ngIf="insertMode" (click)="salvarEndereco()">OK</button>
      </div>
    </div>
  </div>

</p-dialog>

<p-dialog header="Incluir Licença Ambiental" [(visible)]="mostrarDialogoLicenca" [responsive]="true" showEffect="fade"
  [modal]="true" resizable="true" [contentStyle]="{'max-height':'1500px'}" [width]="850"
  *ngIf="licenca!==null && licenca!==undefined">
  <div class="row">
    <div class="col-12 col-sm-4 form-group">
      <label for="vin">Código</label>
      <input pInputText id="licencaAmbiental" [(ngModel)]="licenca.licenca" maxlength="15" />
    </div>
    <div class="col-12 col-sm-4 form-group">
      <label for="vin">Validade</label>
      <p-calendar *ngIf="licenca" [(ngModel)]="licenca.dataValidade" [showIcon]="true" dateFormat="dd/mm/yy">
      </p-calendar>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-1"><label for="vin">Selecione o Órgão Emissor ou digite ao lado</label></div>
    <div class="col-auto form-group">
      <p-dropdown [options]="secretarias" [(ngModel)]="licenca.secretariaPmpaDO" placeholder="Selecione"></p-dropdown>
    </div>
    <div class="col-6 col-sm-auto form-group">
      <label for="vin"></label>
      <input pInputText id="orgaoEmissor" [(ngModel)]="licenca.orgaoEmissor" />
    </div>
  </div>
  <div class="row">
    <div class="col-12 form-group">
      <label for="vin">Descrição</label>
      <textarea pInputTextarea id="descricao" [(ngModel)]="licenca.descricao" [rows]="3" [cols]="80"></textarea>
    </div>
  </div>

  <div class="text-right">
    <button type="button" class="btn btn-primary" (click)="salvarLicenca()">Salvar</button>
  </div>
</p-dialog>

<p-dialog header="Incluir Veículo" [(visible)]="mostrarDialogoVeiculo" [responsive]="true" showEffect="fade"
  [modal]="true" resizable="true" [contentStyle]="{'max-height':'800px'}" [width]="650"
  *ngIf="veiculo!==null && veiculo!==undefined">
  <div class="row">
    <div class="col-md-6">
      <label for="vin">Placa</label>
      <br>
      <input pInputText id="placa" [(ngModel)]="veiculo.placa" /> &nbsp;
    </div>

    <div class="col-md-6">
      <label for="vin">Renavam</label>
      <br>
      <input pInputText id="renavam" [(ngModel)]="veiculo.renavam" />
    </div>
  </div>
  <div style="text-align: right;">
    <button type="button" class="btn btn-primary" (click)="salvarVeiculo()">Salvar</button>
  </div>
</p-dialog>

<p-dialog [header]="tituloVinculos" [(visible)]="mostrarVinculos" [responsive]="true" showEffect="fade" resizable="true"
  [modal]="true" [width]="1250" [contentStyle]="{'max-height':'800px'}" *ngIf="entidadesVinculadas.length > 0">

  <div style="overflow-x: auto">

    <div class="row">
      <div class="col-md-4">
        <label for="vin">Empresa Geradora - CNPJ</label> {{entidadeEdit.cnpj}}
      </div>
      <div class="col-md-8">
        <label for="vin">Nome Fantasia</label> {{entidadeEdit.nomeFantasia}}
      </div>
    </div>
    <p-table #tabelaEntidades [value]="entidadesVinculadas" [paginator]="true" sortField="nome" [rows]="12"
      [globalFilterFields]="['nomeFantasia','razaoSocial','cnpj']" *ngIf="entidades && entidades.length > 0"
      [style]="{width:'1250px'}">

      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="nomeFantasia" class="text-center" style="width: 150px; height: 60px;">
            Nome Fantasia
            <p-sortIcon field="nomeFantasia"></p-sortIcon>
          </th>
          <th pSortableColumn="razaoSocial" class="text-center" style="width: 150px;">
            Razão Social
            <p-sortIcon field="razaoSocial"></p-sortIcon>
          </th>
          <th pSortableColumn="cnpj" class="text-center" style="width: 120px;">
            CNPJ
            <p-sortIcon field="cnpj"></p-sortIcon>
          </th>
          <th pSortableColumn="licencaAmbiental" class="text-center" style="width: 120px;">
            Serviço
            <p-sortIcon field="licencaAmbiental"></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-entidade>
        <tr>
          <td>{{entidade.nomeFantasia}}</td>
          <td>{{entidade.razaoSocial}}</td>
          <td>{{entidade.cnpj}}</td>
          <td>{{entidade.licencaAmbiental}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>




<p-dialog header="Alteração de Email" [(visible)]="pessoaFisica" [responsive]="true" showEffect="fade" [modal]="true"
  resizable="true" [width]="500" *ngIf="mudancaEmail">
  <h3>
    {{pessoaFisica.nomeCompleto}}, <font color="red"><b>ATENÇÃO!!!</b></font><br><br>
    Saiba que ao alterar seu email para <b>{{pessoaFisica.email}}</b> você estará perdendo o login de acesso ao sistema
    SGR através do email anterior.<br>
    Ao confirmar a alteração para esse novo email, possivelmente você deverá fazer o cadastro novamente no ACESSOPOA
    (ferramenta utilizada para acessar o sistema SGR, na opção CADASTRE-SE), como feito com o email anterior.
    <br><br>
    Confirma a mudança do Email?
  </h3>
  <div class="ui-g-12"></div>
  <div class="botoes text-right">
    <button type="button" pButton icon="pi pi-times" (click)="mudancaEmail = false" label="Não, cancelar"></button>
    <button type="button" pButton icon="pi pi-check" (click)="atualizaPessoaFisica(pessoaFisica)"
      label="Sim, alterar"></button>
  </div>
</p-dialog>


<p-dialog header="Expediente Único" [(visible)]="mostrarDialogoEU" [responsive]="true" showEffect="fade" [modal]="true"
  resizable="true" [contentStyle]="{'max-height':'800px'}" [width]="1000">
  <!-- <div class="row" *ngIf="expedienteUnicoVO !== null">
    <div class="col-md-6">
      <label for="vin">Expediente Único</label> <br>
      <a href="{{expedienteUnicoVO.urlAcessoExpedientes}}">{{expedienteUnicoVO.expedienteFormatado}}</a>
    </div>

    <div class="col-md-6" *ngIf="expedienteUnicoVO.procedimentoFormatadoSei !== 'null'">
      <label for="vin">Número do SEI (se houver)</label> <br>
      <a href="{{expedienteUnicoVO.urlConsultaPublicaSei}}">{{expedienteUnicoVO.procedimentoFormatadoSei}}</a>
    </div>
    <div class="col-md-6" *ngIf="expedienteUnicoVO.procedimentoFormatadoSei === 'null'">
      <label for="vin">Número do SEI (se houver)</label> <br>
    </div>
  </div>
  <div class="row" *ngIf="expedienteUnicoVO === null">
    <div class="col-md-6">
      <label for="vin">Expediente Único</label> <br><br>
      Endereço sem Expediente Único
    </div>

    <div class="col-md-6">
      <label for="vin">Número do SEI (se houver)</label> <br>
    </div>
  </div> -->



  <p-table [value]="expedienteUnicoVOList" [paginator]="true" [rows]="10">
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center" style="width: 150px; height: 60px;">
          Expediente Único
        </th>
        <th class="text-center" style="width: 150px;">
          Número do SEI (se houver)
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-expedienteUnicoVO>
      <tr>
        <td *ngIf="expedienteUnicoVO.expedienteFormatado !== 'naoOK'">
          <a href="{{expedienteUnicoVO.urlAcessoExpedientes}}">{{expedienteUnicoVO.expedienteFormatado}}</a>
        </td>
        <td *ngIf="expedienteUnicoVO.expedienteFormatado !== 'naoOK'">
          <a href="{{expedienteUnicoVO.urlConsultaPublicaSei}}"
            *ngIf="expedienteUnicoVO.procedimentoFormatadoSei !== 'null'">
            {{expedienteUnicoVO.procedimentoFormatadoSei}}</a>
        </td>
        <td *ngIf="expedienteUnicoVO.expedienteFormatado === 'naoOK'">
          {{expedienteUnicoVO.procedimentoFormatadoSei}}
        </td>
        <td *ngIf="expedienteUnicoVO.expedienteFormatado === 'naoOK'"></td>
      </tr>
    </ng-template>
  </p-table>






  <div style="text-align: right;">
    <button type="button" icon="pi pi-times" class=" btn btn-outline-primary"
      (click)="mostrarDialogoEU = false">Cancelar</button>
  </div>
</p-dialog>