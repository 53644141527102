import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Globals } from "src/app/globals";

//declare let L;

@Component({
  templateUrl: "./prestadoraServico.component.html",
  styleUrls: ["./prestadoraServico.component.scss"]
})
export class PrestadoraServicoComponent implements OnInit {
  activeRoute: string;
  variaveisGlobais: Globals;

  constructor(private router: Router, private route: ActivatedRoute,
    private globals: Globals) {
    this.activeRoute = this.router.url;
    globals.menuRaiz = this.router.url.split('/')[1];
    this.variaveisGlobais = globals;
    console.log('Construtor PrestadoraServicoComponent >>>>>>>>>>>', globals);

  }

  ngOnInit() {

    //   const map = L.map('map').setView([51.505, -0.09], 13);
    // //   L.TileLayer('https://api.procempa.com.br/apiman-gateway/tser/mapas-base-api/1.0/osm-procempa-2/{z}/{x}/{y}.png', {
    // //     attribution: "©<a href='http://openstreetmap.org/' target='_blank'>OpenStreetMap</a> contributors",
    // //     subdomains: ['1', '2', '3', '4'],
    // //     maxZoom: 22
    // // })
    //   L.TileLayer('https://api.procempa.com.br/apiman-gateway/tser/mapas-base-api/1.0/osm-procempa-2/{z}/{x}/{y}.png', {
    //       attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    //   }).addTo(map);
    //   L.Routing.control({
    //       waypoints: [
    //           L.latLng(57.74, 11.94),
    //           L.latLng(57.6792, 11.949)
    //       ]
    //   }).addTo(map);
  }

  selecionaNavegacao(navegacao) {
    var caminho = "/" + navegacao;
    // this.globals.menuRaiz = navegacao;

    this.router.navigate([caminho]);

    this.router.navigate([caminho]);
  }
  checkActive(route: string) {
    return route === this.activeRoute;
  }
}
