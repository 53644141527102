<sgr-menu></sgr-menu>

<div class="container">

  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/sgrIni">SGR</a></li>
    <li class="breadcrumb-item active" aria-current="page">Usuários</li>
  </ol>

  <h1 class="page-header text-primary">
    Usuários
  </h1>

  <div>
    <div class="mb-3">
      <p-accordion>
        <p-accordionTab header="Critérios de Pesquisa de Usuário">
          <div class="row">
            <div class="col-12 form-group">
              <label for="">Selecionar apenas UM dos critérios de pesquisa</label>
            </div>
            <div class="col-auto form-group">
              <label for="">Nome</label>
              <input [(ngModel)]="nomePesquisa" name="nomePesquisa" size='25' class="form-control" />
            </div>
            <div class="col-auto form-group">
              <label for="">CPF</label>
              <p-inputMask id="cpf" mask="999.999.999-99" [(ngModel)]="cpfPesquisa" size="15" name="cpfPesquisa">
              </p-inputMask>
            </div>
            <div class="col-auto form-group">
              <label for="">CNPJ</label>
              <p-inputMask id="cnpj" mask="99.999.999/9999-99" [(ngModel)]="cnpjPesquisa" size="16" name="cnpjPesquisa">
              </p-inputMask>
            </div>
            <div class="col-sm-8 col-md-6 col-lg-4 form-group">
              <label for="">Email</label>
              <input [(ngModel)]="emailPesquisa" name="emailPesquisa" size="15" class="form-control" />
            </div>
            <div class="botoes col-auto form-group" style="display: flex; align-items: flex-end">
              <button type="button" pButton class="btn btn-primary my-0" (click)="pesquisaUsuario()" label="Pesquisa"
                style="height:38px"></button>
              <button type="button" pButton class="btn btn-primary my-0"
                (click)="nomePesquisa='';cpfPesquisa='';emailPesquisa='';cnpjPesquisa=''" label="Limpar Critérios"
                style="height:38px"></button>
            </div>
          </div>
          
        </p-accordionTab>
      </p-accordion>
    </div>

    <div class="mobile-overflow-x">
      <p-table #tabelaUsuarios [value]="pessoasFisicas" [paginator]="true" sortField="nomeCompleto" [rows]="15"
      [totalRecords]="totalPF" pageLinks="10" [rowsPerPageOptions]="[15,30,50]" [globalFilterFields]="['nomeCompleto']">
        <ng-template pTemplate="caption">
          <div class="row justify-content-between">
            <div class="col-sm-12 col-md-7 form-group busca text-right">
              <input type="text" pInputText size="50" placeholder="Filtrar usuários"
                (input)="tabelaUsuarios.filterGlobal($event.target.value, 'contains')" class="form-control" />
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="nomeCompleto" class="text-center" style="width: 200px; height: 60px;">
              Nome
              <p-sortIcon field="nomeCompleto"></p-sortIcon>
            </th>
            <th class="text-center" style="width: 100px; height: 60px;">
              CPF
            </th>
            <th class="text-center" style="width: 200px; height: 60px;">
              Email
            </th>
            <th class="text-center" style="width: 100px; height: 60px;">
              Comercial
            </th>
            <th class="text-center" style="width: 100px; height: 60px;">
              Celular
            </th>
            <th style="width: 100px;" class="text-center">
              Ações
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-usuario>
          <tr>
            <td>
              {{ usuario?.nomeCompleto }}
            </td>
            <td>
              {{ usuario?.cpf }}
            </td>
            <td>
              {{ usuario?.email }}
            </td>
            <td>
              {{ usuario?.celular }}
            </td>
            <td>
              {{ usuario?.telefone }}
            </td>
            <td>
              <button title="Editar" (click)="mostrarDialogParaEditar(usuario.id)" label="Detalhes"
                class="btn btn-outline-secondary mx-2">
                <i class="fa fa-info pr-2"></i>Detalhes
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog [header]="tituloDialogEdit" [(visible)]="mostrarDialogEdit" [responsive]="true" showEffect="fade"
  [modal]="true" [width]="1000" [height]="1000">


  <div class="row">

    <div class="col-sm-3 form-group">
      <label for="cpf">CPF</label>
      <div class="card">
        <div class="card-header">
          {{pessoaFisica.cpf}}
        </div>
      </div>
      <!-- <p-inputMask id="cpf" mask="999.999.999-99" [(ngModel)]="pessoaFisica.cpf" placeholder="__.___.___/____-__"
        size="20" *ngIf="!isInsert" disabled></p-inputMask>
      <p-inputMask id="cpf" mask="999.999.999-99" [(ngModel)]="pessoaFisica.cpf" placeholder="__.___.___/____-__"
        size="20" *ngIf="isInsert" (onBlur)="verificaCpfExiste(pessoaFisica.cpf)"></p-inputMask> -->
    </div>

    <div class="col-sm-9 form-group">
      <label for="float-input">Nome</label>
      <div class="card">
        <div class="card-header">
          {{pessoaFisica.nomeCompleto}}
        </div>
      </div>
      <!-- <input id="float-input" type="text" size="60" pInputText [(ngModel)]="pessoaFisica.nomeCompleto"
        class="form-control" disabled> -->
    </div>
      <!-- <div>
        <p-radioButton name="prodAtivo" [value]="true" label="Ativo" [(ngModel)]="pessoaFisica.ativo" disabled>
        </p-radioButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <p-radioButton name="prodAtivo" [value]="false" label="Inativo" [(ngModel)]="pessoaFisica.ativo" disabled>
        </p-radioButton>
      </div> -->
    <!-- <div class="ui-g-6">
      <label for="lastName">Sobrenome</label>
      <br>
      <input id="lastName" type="text" size="30" pInputText [(ngModel)]="pessoaFisica.lastName">
    </div> -->

    <div class="col-sm-6 form-group">
      <label for="email">Email</label>
      <div class="card">
        <div class="card-header">
          {{pessoaFisica.email}}
        </div>
      </div>
      <!-- <input id="email" type="text" pInputText [(ngModel)]="pessoaFisica.email" size="40" class="form-control" disabled/> -->
    </div>

    <div class="col-sm-3 form-group">
      <label for="fone">Fone Comercial</label>
      <div class="card">
        <div class="card-header">
          {{pessoaFisica.telefone}}
        </div>
      </div>
      <!-- <p-inputMask id="fone" mask="(99) 9999-9999?9" [(ngModel)]="pessoaFisica.telefone" size="15" disabled></p-inputMask> -->
    </div>

    <div class="col-sm-3 form-group">
      <label for="fone">Celular</label>
      <div class="card">
        <div class="card-header">
          {{pessoaFisica.celular}}
        </div>
      </div>
      <!-- <p-inputMask id="fone" mask="(99) 99999-999?9" [(ngModel)]="pessoaFisica.celular" size="15" disabled></p-inputMask> -->
    </div>


  </div>
  <div class="row">
    <div class="col-12">
      <label for="">Empresas vinculadas ao usuário</label>
    </div>
  </div>

  <div class="row">

    <p-table #tabelaEntidades [value]="entidades" [paginator]="true" sortField="razaoSocial" [rows]="5"
      [totalRecords]="totalEntidades" pageLinks="10"
      [globalFilterFields]="['razaoSocial']" *ngIf="entidades" class="table-sm">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="razaoSocial" style="width: 160px;">
            Razão Social
            <p-sortIcon field="razaoSocial"></p-sortIcon>
          </th>
          <th class="text-center" style="width: 100px; height: 60px;">
            CNPJ
          </th>
          <th class="text-center" style="width: 200px; height: 60px;">
            Cnae
          </th>
          <th class="text-center" style="width: 100px; height: 60px;">
            Comercial
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-entidade>
        <tr>
          <td>
            {{ entidade?.razaoSocial }}
          </td>
          <td>
            {{ entidade?.cnpj }}
          </td>
          <td>
            {{ entidade?.numeroCnae }}
          </td>
          <td>
            {{ entidade?.telefone }}
          </td>
        </tr>
      </ng-template>
    </p-table>

  </div>




  <div class="botoes text-right" *ngIf="!variaveisGlobais.roleEntidade">
    <button type="button" (click)="mostrarDialogEdit = false" label="Cancelar" class="btn btn-outline-secondary">
      Cancelar
    </button>
    <!-- <button type="button" (click)="salvar()" label="Salvar" class="btn btn-primary">
      Salvar
    </button> -->
  </div>
</p-dialog>