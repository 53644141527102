import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Classificacao } from '../../@shared/models/classificacao.model';

@Injectable({providedIn: 'root'})
export class ClassificacaoService {

  constructor(private http: HttpClient) { }

  getClassificacoes(): Observable<Classificacao[]> {
    return this.http.get<Classificacao[]>(environment.apiUrl + '/classificacao/');
  }

  excluirClassificacao(classificacao: Classificacao): Observable<Classificacao> {
    return this.http.delete<Classificacao>(environment.apiUrl + '/classificacao/' + classificacao.id);
  }

}
