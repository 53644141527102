import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { KeycloakAuthGuard } from '@procempa/ngx-keycloak';
import { NaoEncontadaComponent } from './@core/nao-encontrada/nao-encontrada.component';
import { CadastroComponent } from './inicial/cadastro/cadastro.component';
import { SgrInicialComponent } from './inicial/sgrInicial/sgrInicial.component';
import { ListaEntidadesComponent } from './@core/entidade/lista/lista.component';
import { ListaManifestosComponent } from './manifesto/lista/lista.component';
import { ListaProdutosComponent } from './@core/produto/lista/lista.component';
import { ListaPlanosComponent } from './@core/plano/plano.component';
import { MapaComponent } from './@core/mapa/mapa.component';
import { PrestadoraServicoComponent } from './inicial/prestadoraServico/prestadoraServico.component';
import { ContratoComponent } from './@core/dmlu/contrato/contrato.component';
import { DestinoFinalComponent } from './@core/destinoFinal/destinoFinal.component';
import { SecretariaPmpaComponent } from './@core/secretariaPmpa/secretariaPmpa.component';
import { UsuariosPmpaComponent } from './@core/usuariosPmpa/usuariosPmpa.component';
import { ObraPmpaComponent } from './@core/obraPmpa/obraPmpa.component';
import { VeiculoPmpaComponent } from './@core/veiculoPmpa/veiculoPmpa.component';
import { ResultadosManifestosComponent } from './manifesto/resultados/resultadosManifestos.component';
import { LicencaAmbientalComponent } from './@core/licencaAmbiental/licencaAmbiental.component';
import { CertificadoComponent } from './@core/dmlu/certificado/certificado.component';
import { UsuarioComponent } from './@core/usuario/usuario.component';
import { BalancaComponent } from './@core/dmlu/balanca/balanca.component';

// ROTAS DADOS EMPRESA
const de: Routes = [
  {
    path: 'grandesGeradores',
    canActivate: [KeycloakAuthGuard],
    component: PrestadoraServicoComponent
  },
  {
    path: 'transportador',
    canActivate: [KeycloakAuthGuard],
    component: ListaEntidadesComponent
  },
  {
    path: 'destinoFinal',
    canActivate: [KeycloakAuthGuard],
    component: ListaEntidadesComponent
  }
];

// ROTAS MTRCC
const mtrcc: Routes = [
  {
    path: 'mtrcc',
    canActivate: [KeycloakAuthGuard],
    component: ListaEntidadesComponent
  },
  {
    path: 'mtrcc/manifestos',
    canActivate: [KeycloakAuthGuard],
    component: ListaManifestosComponent
  },
  {
    path: 'mtrcc/detalhes/:id',
    component: ListaManifestosComponent,
    canActivate: [KeycloakAuthGuard]
  },
  {
    path: 'mtrcc/produtos',
    component: ListaProdutosComponent,
    canActivate: [KeycloakAuthGuard]
    // data:{
    //   role: sgrRoles.empresa
    // } // urbanismo-sgr-produtos
  },
  {
    path: 'mtrcc/destinoFinal',
    component: DestinoFinalComponent,
    canActivate: [KeycloakAuthGuard] // urbanismo-sgr-mtrcc
  },
  {
    path: 'mtrcc/secretariaPmpa',
    component: SecretariaPmpaComponent,
    canActivate: [KeycloakAuthGuard] // urbanismo-sgr-mtrcc
  },
  {
    path: 'mtrcc/obraPmpa',
    component: ObraPmpaComponent,
    canActivate: [KeycloakAuthGuard] // urbanismo-sgr-mtrcc
  },
  {
    path: 'mtrcc/usuariosPmpa',
    component: UsuariosPmpaComponent,
    canActivate: [KeycloakAuthGuard] // urbanismo-sgr-mtrcc
  },
  {
    path: 'mtrcc/veiculoPmpa',
    component: VeiculoPmpaComponent,
    canActivate: [KeycloakAuthGuard] // urbanismo-sgr-mtrcc
  },
  {
    path: 'mtrcc/empresaManifestos/:id',
    canActivate: [KeycloakAuthGuard],
    component: ListaManifestosComponent
  },
  {
    path: 'mtrcc/licencaAmbiental',
    component: LicencaAmbientalComponent,
    canActivate: [KeycloakAuthGuard] // urbanismo-sgr-mtrcc
  },
  {
    path: 'mtrcc/resultados',
    canActivate: [KeycloakAuthGuard],
    component: ResultadosManifestosComponent
  },
  {
    path: 'mtrcc/resultados/:id',
    canActivate: [KeycloakAuthGuard],
    component: ResultadosManifestosComponent
  },
  {
    path: 'mtrcc/usuario',
    canActivate: [KeycloakAuthGuard],
    component: UsuarioComponent
  }
];

// ROTAS GRANDES GERADORES
const gg: Routes = [
  {
    path: 'grandeGerador',
    canActivate: [KeycloakAuthGuard],
    component: ListaEntidadesComponent
  },
  {
    path: 'grandeGerador/planos',
    canActivate: [KeycloakAuthGuard],
    component: ListaPlanosComponent
  },
  {
    path: 'grandeGerador/empresaResiduos/:id',
    canActivate: [KeycloakAuthGuard],
    component: ListaPlanosComponent
  },
  {
    path: 'grandeGerador/produtos',
    component: ListaProdutosComponent,
    canActivate: [KeycloakAuthGuard]
  },
  {
    path: 'grandeGerador/destinoFinal',
    component: DestinoFinalComponent,
    canActivate: [KeycloakAuthGuard] // urbanismo-sgr-mtrcc
  },
  {
    path: 'grandeGerador/secretariaPmpa',
    component: SecretariaPmpaComponent,
    canActivate: [KeycloakAuthGuard] // urbanismo-sgr-mtrcc
  },
  {
    path: 'grandeGerador/obraPmpa',
    component: ObraPmpaComponent,
    canActivate: [KeycloakAuthGuard] // urbanismo-sgr-mtrcc
  },
  {
    path: 'grandeGerador/usuariosPmpa',
    component: UsuariosPmpaComponent,
    canActivate: [KeycloakAuthGuard] // urbanismo-sgr-mtrcc
  },
  // {
  //   path: 'grandesGeradores/licencaAmbiental',
  //   component: LicencaAmbientalComponent,
  //   canActivate: [KeycloakAuthGuard] // urbanismo-sgr-mtrcc
  // },
  {
    path: 'grandeGerador/veiculoPmpa',
    component: VeiculoPmpaComponent,
    canActivate: [KeycloakAuthGuard] // urbanismo-sgr-mtrcc
  },
  {
    path: 'grandeGerador/usuario',
    canActivate: [KeycloakAuthGuard],
    component: UsuarioComponent
  }
];

// ROTAS DMLU - GESTÃO DE DESCARGA DE RESÍDUOS
const gdr: Routes = [
  {
    path: 'gdr',
    canActivate: [KeycloakAuthGuard],
    component: ListaEntidadesComponent
  },
  {
    path: 'gdr/contrato/:id',
    canActivate: [KeycloakAuthGuard],
    component: ContratoComponent
  },
  {
    path: 'gdr/certificado/:id',
    canActivate: [KeycloakAuthGuard],
    component: CertificadoComponent
  },
  {
    path: 'gdr/empresaResiduos/:id',
    canActivate: [KeycloakAuthGuard],
    component: ListaPlanosComponent
  },
  {
    path: 'gdr/produtos',
    component: ListaProdutosComponent,
    canActivate: [KeycloakAuthGuard]
  },
  {
    path: 'gdr/secretariaPmpa',
    component: SecretariaPmpaComponent,
    canActivate: [KeycloakAuthGuard]
  },
  {
    path: 'gdr/usuariosPmpa',
    component: UsuariosPmpaComponent,
    canActivate: [KeycloakAuthGuard]
  },
  {
    path: 'gdr/usuario',
    canActivate: [KeycloakAuthGuard],
    component: UsuarioComponent
  },
  {
    path: 'gdr/balanca',
    canActivate: [KeycloakAuthGuard],
    component: BalancaComponent
  },
  {
    path: 'gdr/balanca/:codigoBalanca',
    canActivate: [KeycloakAuthGuard],
    component: BalancaComponent
  }
];

// ROTAS LRR
const lrr: Routes = [];

// ROTAS INICIAIS
const raiz: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'sgr'
  },
  {
    path: 'sgr',
    component: SgrInicialComponent,
    canActivate: [KeycloakAuthGuard]
  },
  {
    path: 'sgrIni',
    component: SgrInicialComponent,
    canActivate: [KeycloakAuthGuard]
  },
  {
    path: 'cadastro',
    component: CadastroComponent,
    canActivate: [KeycloakAuthGuard]
  }];

// ROTAS MAPA
const mapa: Routes = [
  {
    path: 'mapa',
    canActivate: [KeycloakAuthGuard],
    component: MapaComponent
  }
];

// DEVE SER ADICIONADO POR ULTIMO
const naoEncontrado = { path: '**', component: NaoEncontadaComponent };

const routes: Routes = [
  ...raiz,
  ...de,
  ...gg,
  ...mtrcc,
  ...lrr,
  ...gdr,
  ...mapa,
  naoEncontrado];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }