import { Plano } from './plano.model';
import { Endereco } from './endereco';

export enum TipoUnidadeMedida {
  Kg = 'kg',
  Tonelada = 't',
  Litro = 'L',
  MetroCubico = 'm³',
  Unidade = 'unidade'
}

export interface Meta {
  id: number;
  plano: Plano;
  ano: number;
  mes: number;

  metaAnualPrevista: number;
  metaAnualRealizada: number;
  unidadeMedidaPrevista: TipoUnidadeMedida;
  unidadeMedidaRealizada: TipoUnidadeMedida;
  enderecoColeta: Endereco;

  // taxaRecupMMA: number; // Em %
  // taxaReducaoVolume: number; // Em %
  // residuoReciclavelGerado: number; // Kg/dia
  // residuoRecuperadoPosConsumo: number; // Kg/dia
  // residuoDestinadoAterro: number; // Kg/dia
  // taxaRecupFracaoSecaRSU: number; // (%)
  // qtdeNoMercado: number; //  (kg/dia)
  // qtdeRecuperada: number; // (kg/dia)
  // qtdeDdestinadoAterro: number; //  (kg/dia)
  // taxaDestinoAterro: number; //  (%)

}

