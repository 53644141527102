import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Anexo } from '../../@shared/models/anexo.model';

@Injectable({ providedIn: 'root' })
export class AnexoService {

  constructor(private http: HttpClient) { }

  getAnexosPorEntidade(id: number, menuRaiz: String): Observable<Anexo[]> {
    const url = environment.apiUrl + '/anexos/' + id + '/entidade/' + menuRaiz;
    console.log('AnexoService getAnexosPorEntidade url>>>>>', url);
    console.log('AnexoService getAnexosPorEntidade id>>>>>', id);
    return this.http.get<Anexo[]>(url);
  }

  getAnexos(id: number, urlCaminho: string): Observable<Anexo[]> {
    const url = environment.apiUrl + urlCaminho + id + '/entidade';
    return this.http.get<Anexo[]>(url);
  }

  getAnexo(id: number): Observable<Anexo> {
    const url = environment.apiUrl + '/anexos/' + id + '/file';
    console.log('AnexoService getAnexo url>>>>>', url);
    console.log('AnexoService getAnexo id>>>>>', id);
    return this.http.get<Anexo>(url);
  }

  getAnexoPorTipo(id: number, tipo: String): Observable<any> {
    const url = environment.apiUrl + '/anexos/' + id + '/' + tipo + '/porTipo';
    console.log('AnexoService getAnexo url>>>>>', url);
    console.log('AnexoService getAnexo id>>>>>', id);
    return this.http.get<any>(url);
  }

  possuiAnexosObrigatorios(id: number, menuRaiz: String): Observable<String> {
    const url = environment.apiUrl + '/anexos/' + id + '/anexosObrigatorios/' + menuRaiz;
    console.log('AnexoService getAnexo url>>>>>', url);
    console.log('AnexoService getAnexo id>>>>>', id);
    return this.http.get<String>(url);
  }

  incluirAnexo(id: number, anexo: Anexo) {
    console.log('---------------------------------------------------------------');

    const rootUrl = environment.apiUrl + '/anexos/' + id + '/entidade';

    // console.log('Anexo Service - urlAnexo>>>>>>>', rootUrl);
    // console.log('Anexo Service - incluirAnexo>>>>>>>', anexo);
    return this.http.post<Anexo>(rootUrl, anexo);

  }

  salvarAnexo(id: number, anexo: Anexo): Observable<Anexo> {
    const url = environment.apiUrl + '/anexos/' + id + '/entidade';
    console.log('AnexoService salvarAnexo url>>>>>', url);
    console.log('AnexoService salvarAnexo anexo>>>>>', id, '---', anexo);
    return this.http.post<Anexo>(url, anexo);
  }

  setFile(idAnexo: number, dados: any): Observable<Object> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream'
      })
    };

    const url = environment.apiUrl + '/anexos/' + idAnexo;
    console.log('AnexoService setFile url>>>>>', url);
    console.log('AnexoService setFile anexo>>>>>', idAnexo);
    const ret = this.http.put(url, dados, httpOptions);
    console.log('AnexoService setFile ret>>>>>', ret);
    return ret;
  }

  excluirAnexo(idAnexo: number): Observable<Anexo> {
    console.log('aaa');
    console.log(idAnexo);
    const url = environment.apiUrl + '/anexos/' + idAnexo;
    return this.http.delete<Anexo>(url);
  }

  situacaoAnexo(idAnexo: number, situacao: String): Observable<Anexo> {
    const url = environment.apiUrl + '/anexosSituacao/' + idAnexo + '/' + situacao;
    const ret = this.http.get<Anexo>(url);
    console.log('AnexoService situacaoAnexo ret>>>>>', ret);
    return ret;
  }

  // editarObraPmpa(obraPmpa: ObraPmpa): Observable<ObraPmpa> {
  //   return this.http.put<ObraPmpa>(environment.apiUrl + '/obraPmpa/', obraPmpa);
  // }

  getAnexosDoPlano(id: number): Observable<Anexo[]> {
    return this.http.get<Anexo[]>(environment.apiUrl + '/planoanexo/anexos/' + id);
  }


}
