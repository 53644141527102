var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { EntidadeService } from '../../entidade/entidade.service';
import { ProdutoService } from '../produto.service';
import { Produto } from 'src/app/@shared/models/produto.model';
import { SpinnerService } from '../../services/spinner.service';
import { NotificacoesService } from '../../services/notificacoes.service';
import { ClassificacaoService } from '../../services/classificacao.service';
import { Globals } from 'src/app/globals';
var ListaProdutosComponent = /** @class */ (function () {
    function ListaProdutosComponent(router, keycloakService, produtoService, entidadeService, spinnerService, notificacoesService, classificacaoService, globals) {
        this.router = router;
        this.keycloakService = keycloakService;
        this.produtoService = produtoService;
        this.entidadeService = entidadeService;
        this.spinnerService = spinnerService;
        this.notificacoesService = notificacoesService;
        this.classificacaoService = classificacaoService;
        this.globals = globals;
        this.entidadeNome = '';
        this.gdrDestino = '';
        this.activeRoute = this.router.url;
        this.tipoListas = [
            { label: 'LRR', value: 'lrr' },
            { label: 'MTRCC', value: 'mtrcc' }
        ];
        this.variaveisGlobais = globals;
    }
    ListaProdutosComponent.prototype.ngOnInit = function () {
        console.log('000ListaProdutosComponent>>>>>>>>>>>', this.variaveisGlobais);
        if (this.variaveisGlobais.menuRaiz === null || this.variaveisGlobais.menuRaiz === '') {
            this.router.navigate(['/sgrIni']);
        }
        else {
            this.inicializaOnInit();
        }
    };
    ListaProdutosComponent.prototype.inicializaOnInit = function () {
        var _this = this;
        this.spinnerService.exibir();
        // Carrega valores para unidades de medida
        // this.unidadesMedida = [];
        // for (const tipo of Object.keys(TipoUnidadeMedida)) {
        //   this.unidadesMedida.push( {label: TipoUnidadeMedida[tipo], value: tipo});
        // }
        // Carrega valor inicial para projetos
        // if (this.variaveisGlobais.menuRaiz === 'mtrcc') {
        this.produtoService.getProdutos(this.variaveisGlobais.menuRaiz, this.variaveisGlobais.roleEntidade).subscribe(function (produtos) {
            _this.spinnerService.ocultar();
            _this.produtos = produtos;
            console.log('Produtos>>>>>>>>', produtos);
        });
        // } else if (this.variaveisGlobais.menuRaiz === 'grandeGerador') {
        //   this.produtoService.getProdutos().subscribe(produtos => {
        //     this.spinnerService.ocultar();
        //     this.produtos = produtos;
        //   });
        //  }
        // Carrega valores para classificações
        this.classificacoes = [];
        // Carrega valor inicial para projetos
        this.classificacaoService.getClassificacoes().subscribe(function (classificacoes) {
            _this.spinnerService.ocultar();
            _this.classificacao = classificacoes;
            classificacoes.forEach(function (clas) {
                _this.classificacoes.push({ label: clas.classificacao, value: clas.classificacao });
            });
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaProdutosComponent.prototype.logout = function () {
        var url = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
        this.keycloakService.getKeycloakInstance().logout({ redirectUri: url });
    };
    // public getUnidade(produto: Produto) {
    //   return produto.unidadeMedida + ' (' + TipoUnidadeMedida[produto.unidadeMedida] + ')';
    // }
    // public getUnidadeAbreviada(produto: Produto) {
    //   return TipoUnidadeMedida[produto.unidadeMedida];
    // }
    ListaProdutosComponent.prototype.mostrarDialogParaNovo = function () {
        this.produtoEdit = new Produto();
        this.mostrarDialogEdit = true;
        if (this.variaveisGlobais.menuRaiz === 'grandeGerador'
            || this.variaveisGlobais.menuRaiz === 'mtrcc') {
            this.tituloDialogEdit = 'Novo Resíduo';
        }
        else {
            this.tituloDialogEdit = 'Novo Produto';
        }
    };
    ListaProdutosComponent.prototype.mostrarDialogParaEditar = function (idProduto) {
        var produtoParaEditar = this.produtos.find(function (produto) {
            return produto.id === idProduto;
        });
        this.produtoEdit = __assign({}, produtoParaEditar);
        // console.log('mostrarDialogParaEditar>>>>classificação>>>>>>>>>>', this.classificacoes);
        console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.produtoEdit);
        if (this.produtoEdit.classificacaoDO != null) {
            this.selClassificacao = this.produtoEdit.classificacaoDO.classificacao;
        }
        console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.produtoEdit);
        this.mostrarDialogEdit = true;
        if (this.produtoEdit.gdrTransbordo) {
            this.gdrDestino = 'transbordo';
        }
        if (this.produtoEdit.gdrCompostagem) {
            this.gdrDestino = 'compostagem';
        }
        if (this.produtoEdit.gdrTriagem) {
            this.gdrDestino = 'triagem';
        }
        if (this.variaveisGlobais.menuRaiz === 'grandeGerador'
            || this.variaveisGlobais.menuRaiz === 'mtrcc') {
            this.tituloDialogEdit = 'Editar Resíduo';
        }
        else {
            this.tituloDialogEdit = 'Editar Produto pós-consumo';
        }
    };
    ListaProdutosComponent.prototype.mostrarDialogEditParaExcluir = function (idProduto) {
        this.produtoExcluir = this.produtos.find(function (produto) {
            return produto.id === idProduto;
        });
        this.mostrarDialogExcluir = true;
    };
    ListaProdutosComponent.prototype.salvar = function () {
        var _this = this;
        // console.log('tipoListas>>>>>>>>>>>>>>', this.selectedTipos);
        // Verifica se todos os campos foram preenchidos
        if (this.produtoEdit.nome == null || this.produtoEdit.nome === ''
            || this.produtoEdit.descricao == null || this.produtoEdit.descricao === ''
        // || this.selectedTipos == null || this.selectedTipos.length === 0
        // || this.produtoEdit.unidadeMedida == null
        ) {
            this.notificacoesService.notificarErro('Preencha todos os campos', null);
            return;
        }
        if (this.variaveisGlobais.menuRaiz === 'gdr') {
            if (this.gdrDestino === '') {
                this.notificacoesService.notificarErro('Preencha a unidade de destino', null);
                return;
            }
        }
        this.spinnerService.exibir();
        if (this.variaveisGlobais.menuRaiz === 'mtrcc') {
            this.produtoEdit.mtrcc = true;
            this.produtoEdit.lrr = false;
            this.produtoEdit.gg = false;
            this.produtoEdit.gdrTransbordo = false;
            this.produtoEdit.gdrCompostagem = false;
            this.produtoEdit.gdrTriagem = false;
        }
        else if (this.variaveisGlobais.menuRaiz === 'grandeGerador') {
            this.produtoEdit.mtrcc = false;
            this.produtoEdit.lrr = false;
            this.produtoEdit.gg = true;
            this.produtoEdit.gdrTransbordo = false;
            this.produtoEdit.gdrCompostagem = false;
            this.produtoEdit.gdrTriagem = false;
        }
        else if (this.variaveisGlobais.menuRaiz === 'lrr') {
            this.produtoEdit.mtrcc = false;
            this.produtoEdit.lrr = true;
            this.produtoEdit.gg = false;
            this.produtoEdit.gdrTransbordo = false;
            this.produtoEdit.gdrCompostagem = false;
            this.produtoEdit.gdrTriagem = false;
        }
        else if (this.variaveisGlobais.menuRaiz === 'gdr') {
            this.produtoEdit.mtrcc = false;
            this.produtoEdit.lrr = false;
            this.produtoEdit.gg = false;
            this.produtoEdit.gdrTransbordo = false;
            this.produtoEdit.gdrCompostagem = false;
            this.produtoEdit.gdrTriagem = false;
            if (this.gdrDestino === 'transbordo') {
                this.produtoEdit.gdrTransbordo = true;
            }
            if (this.gdrDestino === 'compostagem') {
                this.produtoEdit.gdrCompostagem = true;
            }
            if (this.gdrDestino === 'triagem') {
                this.produtoEdit.gdrTriagem = true;
            }
        }
        if (this.variaveisGlobais.menuRaiz === 'mtrcc') {
            this.classificacao.forEach(function (clas) {
                if (clas.classificacao === _this.selClassificacao) {
                    _this.produtoEdit.classificacaoDO = clas;
                }
            });
        }
        console.log('salvar....................>>>>>>>>>>>>>>', this.produtoEdit);
        // Se novo
        if (this.produtoEdit.id == null) {
            this.produtoService.criarProduto(this.produtoEdit).subscribe(function (produtoNovo) {
                _this.produtos.push(produtoNovo);
                _this.mostrarDialogEdit = false;
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarSucesso('Adicionado com sucesso', null);
            }, function (error) {
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro('Erro ao adicionar ', error);
            });
        }
        else { // Se editando
            this.produtoService.editarProduto(this.produtoEdit).subscribe(function (produtoEditado) {
                // Remove o valor atual
                _this.produtos = _this.produtos.filter(function (item) { return item.id !== _this.produtoEdit.id; });
                // Adiciona o novo
                _this.produtos.push(produtoEditado);
                _this.mostrarDialogEdit = false;
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarSucesso('Atualizado com sucesso', null);
            }, function (error) {
                console.log(error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    ListaProdutosComponent.prototype.isSMAMS = function () {
        // const ret = KeycloakService.keycloakAuth.hasResourceRole('SMAMS');
        // return ret;
        var ret = this.keycloakService.hasRole('SMAMS');
        return ret;
    };
    ListaProdutosComponent.prototype.excluir = function () {
        var _this = this;
        this.spinnerService.exibir();
        console.log('salvar....................>>>>>>>>>>>>>>', this.variaveisGlobais.menuRaiz);
        this.produtoService.excluirProduto(this.produtoExcluir, this.variaveisGlobais.menuRaiz).subscribe(function (produto) {
            if (produto == null) {
                // Remove o valor atual
                _this.produtos = _this.produtos.filter(function (item) { return item.id !== _this.produtoExcluir.id; });
            }
            _this.mostrarDialogExcluir = false;
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarSucesso('Excluído com sucesso', null);
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro('Erro ao exluir', error.error);
        });
    };
    ListaProdutosComponent.prototype.checkActive = function (route) {
        return route === this.activeRoute;
    };
    return ListaProdutosComponent;
}());
export { ListaProdutosComponent };
