import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var VeiculoService = /** @class */ (function () {
    function VeiculoService(http) {
        this.http = http;
    }
    VeiculoService.prototype.getVeiculo = function (id) {
        var url = environment.apiUrl + '/veiculos/byId/' + id;
        console.log('criarVeiculo Service >>>>>>', url);
        return this.http.get(url);
    };
    VeiculoService.prototype.getVeiculosPorEntidade = function (idEntidade) {
        var url = environment.apiUrl + '/veiculos/listaPorEntidade/' + idEntidade;
        // console.log('criarVeiculo Service >>>>>>', url);
        return this.http.get(url);
    };
    VeiculoService.prototype.criarVeiculo = function (veiculo) {
        return this.http.post(environment.apiUrl + '/veiculos/', veiculo);
    };
    VeiculoService.prototype.editarVeiculo = function (veiculo) {
        return this.http.put(environment.apiUrl + '/veiculos/', veiculo);
    };
    VeiculoService.prototype.deletaVeiculo = function (idVeiculo) {
        var url = environment.apiUrl + '/veiculos/deletar/' + idVeiculo;
        console.log('Veículo Service - DELETA>>>>>>>', url);
        return this.http
            .delete(url, idVeiculo);
    };
    VeiculoService.ngInjectableDef = i0.defineInjectable({ factory: function VeiculoService_Factory() { return new VeiculoService(i0.inject(i1.HttpClient)); }, token: VeiculoService, providedIn: "root" });
    return VeiculoService;
}());
export { VeiculoService };
