var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { FileUpload } from 'primeng/primeng';
import { EnderecoEntidade } from 'src/app/@shared/models/enderecoEntidade';
import { PessoaFisica } from 'src/app/@shared/models/pessoaFisica.model';
import { LicencaAmbiental } from 'src/app/@shared/models/licencaAmbiental.model';
import { Globals } from 'src/app/globals';
import { environment } from 'src/environments/environment';
import { AnexoService } from '../../services/anexo.service';
import { EnderecoService } from '../../services/endereco.service';
import { NotificacoesService } from '../../services/notificacoes.service';
import { SpinnerService } from '../../services/spinner.service';
import { VeiculoService } from '../../services/veiculo.service';
import { LicencaAmbientalService } from '../../services/licencaAmbiental.service';
import { EntidadeService } from '../entidade.service';
import { PessoaFisicaService } from '../../services/pessoaFisica.service';
import { SmfPessoaService } from '../../services/smfPessoa.service';
import { SecretariaPmpaService } from '../../services/secretariaPmpa.service';
import { DmluService } from '../../dmlu/dmlu.service';
import { ExpedienteUnicoVO } from 'src/app/@shared/models/expedienteUnicoVO.model';
var ListaEntidadesComponent = /** @class */ (function () {
    function ListaEntidadesComponent(router, route, entidadeService, enderecoService, pessoaFisicaService, veiculoService, licencaAmbientalService, anexoService, keycloakService, spinnerService, notificacoesService, smfPessoaService, dmluService, secretariaPmpaService, globals) {
        this.router = router;
        this.route = route;
        this.entidadeService = entidadeService;
        this.enderecoService = enderecoService;
        this.pessoaFisicaService = pessoaFisicaService;
        this.veiculoService = veiculoService;
        this.licencaAmbientalService = licencaAmbientalService;
        this.anexoService = anexoService;
        this.keycloakService = keycloakService;
        this.spinnerService = spinnerService;
        this.notificacoesService = notificacoesService;
        this.smfPessoaService = smfPessoaService;
        this.dmluService = dmluService;
        this.secretariaPmpaService = secretariaPmpaService;
        this.globals = globals;
        this.pfFuncao = '';
        // usuarioAutenticado: boolean;
        // Lista
        this.entidades = [];
        this.entidadesVinculadas = [];
        this.veiculos = [];
        this.licencas = [];
        this.licencasItem = [];
        this.licencaIsento = false;
        this.selectedPesquisa = '';
        // para datas
        this.minimumDateGerador = new Date();
        // tiposEntidade: SelectItem[];
        this.pessoaFisicas = [];
        this.mudancaEmail = false;
        this.podeInativar = false;
        this.insertMode = false;
        this.insertModePF = false;
        this.isPMPA = false;
        this.controlaRazaoSocialEnt = false;
        this.controlaTelefoneEnt = false;
        this.controlaEmailEnt = false;
        this.controlaNumeroCnaeEnt = false;
        this.controlaDescricaoCnaeEnt = false;
        this.controlaMensagemFazenda = false;
        this.pessoaFisica = new PessoaFisica();
        this.mostrarDialogoPessoaFisica = false;
        this.mostrarDialogoPFLogada = false;
        this.mostrarDialogoVeiculo = false;
        this.mostrarDialogoLicenca = false;
        this.mostrarDialogoEU = false;
        this.expedienteUnicoVO = new ExpedienteUnicoVO();
        this.expedienteUnicoVOList = [];
        this.secretarias = [];
        this.secretariasPmpa = [];
        this.idSecretaria = null;
        this.index = 0;
        this.enderecos = [];
        this.isCompleto = true;
        this.logradouro = null;
        this.numero = null;
        this.mostrarDialogoEnderecoPoa = false;
        this.mostrarDialogoEnderecoForaPoa = false;
        this.anexoSel = '';
        this.anexos = [];
        this.BACKEND_URL = environment.apiUrl;
        this.comAnexos = false;
        // Anexos
        this.tiposAnexos = [];
        this.tipoAnexo = '';
        this.activeRoute = this.router.url;
        this.globals.menuRaiz = this.router.url.split('/')[1];
        // keycloakService.getUser().subscribe(user => {
        //   this.globals.userFullName = user.fullName;
        //   this.globals.userName = user.name;
        //   this.globals.roleEntidade = this.keycloakService.hasRole('Entidade');
        // this.variaveisGlobais = globals;
        // });
    }
    ListaEntidadesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.variaveisGlobais = this.globals;
        console.log('ngOnInit---------ListaEntidadesComponent----VVVV----------->>>', this.variaveisGlobais);
        this.endereco = new EnderecoEntidade();
        this.expedienteUnicoVO = new ExpedienteUnicoVO();
        if (this.globals.entidade == null) {
            this.globals.recuperaKeycloak().subscribe(function (user) {
                if (_this.globals.roleEntidade) {
                    if (_this.variaveisGlobais.pessoaFisica === null) {
                        _this.globals.recuperaEntidadePessoaFisica().subscribe(function (entidade) {
                            // console.log('recuperaEntidadePessoaFisica---------------------------');
                            // console.log('--------------------------->>>>>11', entidade);
                            _this.variaveisGlobais = _this.globals;
                            // console.log('--------------------------->>>>>00');
                            _this.inicializaOnInit();
                        });
                    }
                    else {
                        _this.inicializaOnInit();
                    }
                }
                else {
                    // console.log('--------------------------->>>>>11');
                    _this.inicializaOnInit();
                }
            });
        }
        else {
            // console.log('--------------------------->>>>>22');
            this.inicializaOnInit();
        }
    };
    ListaEntidadesComponent.prototype.inicializaOnInit = function () {
        var _this = this;
        this.entidadeRepresentada = this.variaveisGlobais.entidade;
        this.mostrarVinculos = false;
        if (this.variaveisGlobais.menuRaiz === 'grandeGerador') {
            this.tiposAnexos = [
                { label: 'Alvará', value: 'Alvará' },
                { label: 'CNPJ', value: 'CNPJ' },
                { label: 'CPF/RG', value: 'CPF/RG' },
                { label: 'Contrato Conjunto (Transp e Dest.Final)', value: 'Contrato Conjunto' },
                { label: 'Contrato Destino Final', value: 'Contrato Destino Final' },
                { label: 'Contrato Transporte', value: 'Contrato Transporte' },
                { label: 'Plano Resíduo', value: 'Plano Resíduo' },
                { label: 'RT Elaboração', value: 'RT Elaboração' },
                { label: 'RT Execução', value: 'RT Execução' }
            ];
        }
        if (this.variaveisGlobais.menuRaiz === 'gdr') {
            this.tiposAnexos = [
                { label: 'Alvará', value: 'Alvará' },
                { label: 'Boleto Pago', value: 'Boleto Pago' },
                { label: 'Contrato Social ou Estatuto', value: 'Contrato Social' },
                { label: 'CNH', value: 'CNH' },
                { label: 'CPF/RG', value: 'CPF/RG' },
                { label: 'Designação Diário Oficial', value: 'Designação Diário Oficial' },
                { label: 'Documento Complementar', value: 'Complementar' },
                { label: 'Outros', value: 'Outros' },
                { label: 'Procuração de Representação', value: 'Procuração' },
                { label: 'Relatório da Dívida', value: 'Relatório Dívida' },
                { label: 'Relatório da Vistoria Técnica', value: 'Vistoria' }
            ];
        }
        // Carrega valor inicial para projetos
        if (this.variaveisGlobais.roleEntidade) {
            if (this.variaveisGlobais.menuRaiz === 'grandeGerador' ||
                this.variaveisGlobais.menuRaiz === 'mtrcc' ||
                this.variaveisGlobais.menuRaiz === 'transportador' ||
                this.variaveisGlobais.menuRaiz === 'destinoFinal' ||
                this.variaveisGlobais.menuRaiz === 'gdr') {
                if (this.variaveisGlobais.entidades.length > 0) { // entidades já recuperadas
                    console.log('inicializaOnInit>>>>>>>>>Entidades já existem>>>>', this.entidades);
                    this.entidades = this.variaveisGlobais.entidades;
                    this.totalEntidades = this.entidades.length;
                    this.spinnerService.exibir();
                    this.secretariasPmpa = [];
                    this.secretariaPmpaService.getSecretariasPmpa().subscribe(function (secretarias) {
                        _this.secretariasPmpa = secretarias;
                        secretarias.forEach(function (sec) {
                            _this.secretarias.push({ label: sec.sigla, value: sec });
                        });
                        _this.spinnerService.ocultar();
                        if (_this.variaveisGlobais.menuRaiz === 'gdr') {
                            _this.gdrEntidadeComContrato();
                        }
                    }, function (error) {
                        console.log(error);
                        _this.spinnerService.ocultar();
                        _this.notificacoesService.notificarErro(error.error, null);
                    });
                    this.isPMPA = false;
                    if (this.entidadeRepresentada === undefined || this.entidadeRepresentada !== null) {
                        if (this.entidadeRepresentada.cnpj === '92.963.560/0001-60') {
                            this.isPMPA = true;
                            // console.log('inicializaOnInit......667.....>>', this.isPMPA);
                        }
                    }
                }
                else { // recuperar entidades
                    console.log('inicializaOnInit>>>>>>>>>Recuperar Entidades>>>>', this.entidades);
                    this.spinnerService.exibir();
                    this.entidadeService.getEntidadesDaPessoaFisica(this.variaveisGlobais.pessoaFisica.id).subscribe(function (entidades) {
                        _this.entidades = entidades;
                        _this.totalEntidades = entidades.length;
                        if (_this.variaveisGlobais.menuRaiz === 'gdr') {
                            _this.gdrEntidadeComContrato();
                        }
                        _this.secretariasPmpa = [];
                        _this.secretariaPmpaService.getSecretariasPmpa().subscribe(function (secretarias) {
                            _this.secretariasPmpa = secretarias;
                            secretarias.forEach(function (sec) {
                                _this.secretarias.push({ label: sec.sigla, value: sec });
                            });
                        }, function (error) {
                            console.log(error);
                            _this.spinnerService.ocultar();
                            _this.notificacoesService.notificarErro(error.error, null);
                        });
                        _this.isPMPA = false;
                        if (_this.entidadeRepresentada === undefined || _this.entidadeRepresentada !== null) {
                            if (_this.entidadeRepresentada.cnpj === '92.963.560/0001-60') {
                                _this.isPMPA = true;
                                console.log('inicializaOnInit......667.....>>', _this.isPMPA);
                            }
                        }
                        _this.spinnerService.ocultar();
                    }, function (error) {
                        console.log(error);
                        _this.spinnerService.ocultar();
                        _this.notificacoesService.notificarErro(error.error, null);
                    });
                }
            }
        }
        else {
            // console.log('inicializaOnInit>>>>>>>>>Administrador>>>>', this.entidades);
            if (this.variaveisGlobais.menuRaiz === 'grandeGerador') {
                this.spinnerService.exibir();
                this.entidadeService.getEntidadesGeradoras().subscribe(function (entidades) {
                    _this.spinnerService.ocultar();
                    _this.entidades = entidades;
                    _this.totalEntidades = entidades.length;
                    console.log('Lista Entidades >>>>', entidades);
                }, function (error) {
                    console.log(error);
                    console.log(error.error);
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro(error.error, null);
                });
                // this.pesquisaEntidades("");
            }
            if (this.variaveisGlobais.menuRaiz === 'mtrcc') {
                this.spinnerService.exibir();
                this.entidadeService.getEntidadesMtrcc().subscribe(function (entidades) {
                    _this.spinnerService.ocultar();
                    _this.entidades = entidades;
                    _this.totalEntidades = entidades.length;
                    // if (this.variaveisGlobais.menuRaiz === 'gdr') {
                    //   this.gdrEntidadeComContrato();
                    // }
                    console.log('Lista Entidades >>>>', entidades);
                }, function (error) {
                    console.log(error);
                    console.log(error.error);
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro(error.error, null);
                });
            }
            if (this.variaveisGlobais.menuRaiz === 'gdr') {
                this.spinnerService.exibir();
                // this.pesquisaEntidadesGdr('Todos');
                this.entidadeService.getEntidadesGdr('Contrato vigente').subscribe(function (entidades) {
                    _this.spinnerService.ocultar();
                    _this.entidades = entidades;
                    _this.totalEntidades = entidades.length;
                    _this.entidades.forEach(function (entidade) {
                        console.log('gdrEntidadeComContrato.....*******......>>>>', entidade);
                        if (entidade.gdrNumeroContrato !== null) {
                            entidade.gdrNumeroContrato = entidade.gdrNumeroContrato.toString() + '/' + entidade.gdrAnoContrato;
                        }
                    });
                    // this.gdrEntidadeComContrato();
                    console.log('Lista Entidades >>>>', entidades);
                }, function (error) {
                    console.log(error);
                    console.log(error.error);
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro(error.error, null);
                });
                // this.pesquisaEntidades("");
            }
        }
        this.selectedPesquisa = 'Todos';
        if (this.variaveisGlobais.menuRaiz === 'gdr') {
            this.selectedPesquisa = 'Vigente';
        }
        // console.log('....................ANEXOS .............>>>>', this.anexos);
        // this.nomeUsuario = this.variaveisGlobais.userFullName;
        // this.username = this.variaveisGlobais.userName;
    };
    ListaEntidadesComponent.prototype.isEntidade = function () {
        var ret = this.keycloakService.hasRole('Entidade');
        return ret;
    };
    ListaEntidadesComponent.prototype.podeExibirResiduos = function (possuiPlanos) {
        if (this.variaveisGlobais.menuRaiz === 'grandeGerador') {
            if (this.variaveisGlobais.roleEntidade) {
                return true;
            }
            else if (possuiPlanos) {
                // console.log('podeExibirResiduos...............>>>>TRUE');
                return true;
            }
        }
        return false;
    };
    ListaEntidadesComponent.prototype.podeExibirManifestos = function (entidade) {
        if (this.variaveisGlobais.menuRaiz === 'mtrcc') {
            if (!this.variaveisGlobais.roleEntidade) {
                return true;
            }
        }
        return false;
    };
    ListaEntidadesComponent.prototype.isSMAMS = function () {
        var ret = this.keycloakService.hasRole('SMAMS');
        return ret;
    };
    ListaEntidadesComponent.prototype.logout = function () {
        var url = document.location.protocol +
            '//' +
            document.location.hostname +
            ':' +
            document.location.port;
        this.keycloakService.getKeycloakInstance().logout({ redirectUri: url });
    };
    ListaEntidadesComponent.prototype.mostrarDialogParaNovo = function () {
        //Início correções automáticas nas tabelas
        // this.entidadeService.correcoesExtras().subscribe(string => {
        //   console.log('OKkkkkkkkkkkkkkk!!!!!!!!!!!');
        // },
        // error => {
        //     console.log(error);
        //     console.log(error.error);
        //     this.spinnerService.ocultar();
        //     this.notificacoesService.notificarErro(error.error, null);
        // });
        //Fim correções automáticas nas tabelas
        this.entidadeEdit = {
            id: null,
            cnpj: null,
            razaoSocial: '',
            dataInclusao: null,
            timestampAsString: null,
            usuarioInclusao: null,
            usuarioAlteracao: null,
            // nomeSisLog: null,
            licencaAmbiental: null,
            licencaAmbientalOrgaoEmissor: null,
            licencaAmbientalDataValidade: null,
            alvara: null,
            gdrNumeroContrato: null,
            gdrAnoContrato: null,
            gdrSituacaoContrato: null,
            gdrCertificado: null,
            codigoDmlu: null,
            adimplenteDmlu: null,
            desabilitadaMtrcc: null
        };
        this.enderecos = [];
        this.veiculos = [];
        this.licencas = [];
        this.pessoaFisicas = [];
        this.mostrarDialogEdit = true;
        this.tituloDialogEdit = 'Nova Entidade';
        this.insertMode = true;
        this.controlaRazaoSocialEnt = true;
        // this.controlaTelefoneEnt = true;
        this.controlaEmailEnt = true;
        this.controlaNumeroCnaeEnt = true;
        this.controlaDescricaoCnaeEnt = true;
        this.isDestinoFinal = false;
        if (this.variaveisGlobais.menuRaiz === 'destinoFinal') {
            this.isDestinoFinal = true;
            this.tituloLicenca = 'Informações da Licença Ambiental';
        }
        if (this.variaveisGlobais.menuRaiz === 'mtrcc') {
            this.isDestinoFinal = true;
            this.tituloLicenca = 'Se TRANSPORTADOR, preenchimento dos campos da licença são opcionais';
        }
        this.endereco = new EnderecoEntidade();
        this.endereco.nomeLogradouro = null;
        console.log("***************>>>", this.endereco);
        // corrige bug numeroManifesto .....
        // this.spinnerService.exibir();
        // this.entidadeService.atualizaEmpresaFazenda().subscribe(retorno => {
        //   this.notificacoesService.notificarSucesso('OKkkkkkkkkk', null);
        //   this.spinnerService.ocultar();
        // },
        //   error => {
        //     this.spinnerService.ocultar();
        //     this.notificacoesService.notificarErro(error.error, null);
        //   }
        // );
    };
    ListaEntidadesComponent.prototype.mostrarDialogComPlanos = function (idEntidade) {
        this.router.navigate(['/planos/' + idEntidade]);
        var entidadeParaEditar = this.entidades.find(function (entidade) {
            return entidade.id === idEntidade;
        });
        this.entidadeEdit = __assign({}, entidadeParaEditar);
        this.mostrarDialogPlanos = true;
        this.tituloDialogEdit = 'Planos da Entidade';
        this.insertMode = false;
    };
    ListaEntidadesComponent.prototype.mostrarResiduosGerados = function (idEntidade) {
        // console.log('mostrarResiduosGerados........00>>>>>', this.route);
        // this.entidadeEdit = this.entidades.find(function (entidade) {
        //   return entidade.id === idEntidade;
        // });
        // this.globals.entidade = this.entidadeEdit;
        // this.pessoaFisica = this.pessoaFisicas.find(function (pessoaFisica) {
        //   return pessoaFisica.id === idPessoaFisica;
        // });
        this.router.navigate(['/grandeGerador/empresaResiduos/', idEntidade], {
            relativeTo: this.route
        });
        console.log('mostrarResiduosGerados........11>>>>>', this.variaveisGlobais);
        // <a [routerLink]="['../matrizGestao', evento.id, evento.descricao]">
        // this.mostrarDialogPlanos = true;
        // this.tituloDialogEdit = 'Planos da Entidade';
    };
    ListaEntidadesComponent.prototype.mostrarManifestos = function (idEntidade) {
        this.router.navigate(['/mtrcc/empresaManifestos/', idEntidade], {
            relativeTo: this.route
        });
        console.log('mostrarManifestos........11>>>>>', this.variaveisGlobais);
    };
    ListaEntidadesComponent.prototype.mostrarResultados = function (idEntidade) {
        this.router.navigate(['/mtrcc/resultados/', idEntidade], {
            relativeTo: this.route
        });
        console.log('mostrarResultados........11>>>>>', this.variaveisGlobais);
    };
    ListaEntidadesComponent.prototype.mostrarContratoDMLU = function (idEntidade) {
        this.globals.entidade = this.entidades.find(function (entidade) {
            return entidade.id === idEntidade;
        });
        this.router.navigate(['/gdr/contrato/', idEntidade], {
            relativeTo: this.route
        });
    };
    ListaEntidadesComponent.prototype.mostrarCertificadoDMLU = function (idEntidade) {
        this.globals.entidade = this.entidades.find(function (entidade) {
            return entidade.id === idEntidade;
        });
        this.router.navigate(['/gdr/certificado/', idEntidade], {
            relativeTo: this.route
        });
    };
    ListaEntidadesComponent.prototype.mostrarDialogParaEditarPessoaFisica = function (idPessoaFisica) {
        var _this = this;
        var pFisica = this.pessoaFisicas.find(function (pessoaFisica) {
            return pessoaFisica.id === idPessoaFisica;
        });
        this.pessoaFisica = __assign({}, pFisica);
        // console.log('@@@@@@@@@@@@@@mostrarDialogParaEditarPessoaFisica........>>>>>', this.pessoaFisica);
        if (!this.variaveisGlobais.roleEntidade) {
            this.podeSalvar = true;
        }
        else {
            if (this.pessoaFisica.id === this.variaveisGlobais.pessoaFisica.id) {
                this.podeSalvar = true;
            }
            else {
                this.pessoaFisicaService.getPessoasFisicasEntidadeRL(this.entidadeEdit.id, this.variaveisGlobais.pessoaFisica.id)
                    .subscribe(function (pesFisica) {
                    _this.podeSalvar = false;
                    if (pesFisica !== null) {
                        if (pesFisica.rLegal) {
                            _this.podeSalvar = true;
                        }
                    }
                    _this.spinnerService.ocultar();
                }, function (error) {
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro(error.error, null);
                });
            }
        }
        this.pfFuncaoRT = [];
        this.pfFuncao = '';
        if (this.pessoaFisica.rLegal) {
            this.pfFuncao = 'rLegal';
        }
        if (this.pessoaFisica.rusrLegal) {
            this.pfFuncao = 'rusrLegal';
        }
        if (this.pessoaFisica.rtElaboracao && this.pessoaFisica.rtExecucao) {
            this.pfFuncaoRT = ['rElaboracao', 'rExecucao'];
        }
        else {
            if (this.pessoaFisica.rtElaboracao) {
                this.pfFuncaoRT = ['rElaboracao'];
            }
            if (this.pessoaFisica.rtExecucao) {
                this.pfFuncaoRT = ['rExecucao'];
            }
        }
        // } else {
        //   this.tituloDialogEditPessoaFisica = 'Editar PessoaFisica';
        // }
        this.insertMode = false;
        this.insertModePF = false;
        // if (this.variaveisGlobais.menuRaiz == "mtrcc") {
        this.tituloDialogEditPessoaFisica = 'Editar Responsável';
        console.log('mostrarDialogParaEditarPessoaFisica......667.....>>', this.isPMPA);
        if (this.isPMPA) {
            this.secretarias = [];
            this.secretariasPmpa.forEach(function (sec) {
                _this.secretarias.push({ label: sec.sigla, value: sec.id });
            });
            this.mostrarDialogoPessoaFisica = true;
            this.idSecretaria = this.pessoaFisica.idSecretaria;
            console.log('@@@@@@@@@@@@@@mostrarDialogParaEditarPessoaFisica........>>>>>', this.idSecretaria, '----', this.pessoaFisica);
        }
        else {
            this.mostrarDialogoPessoaFisica = true;
        }
    };
    ListaEntidadesComponent.prototype.mostrarDialogParaEditar = function (idEntidade) {
        var _this = this;
        // this.anexos.length = -1;
        this.isDestinoFinal = false;
        if (this.variaveisGlobais.menuRaiz === 'destinoFinal') {
            this.isDestinoFinal = true;
            this.tituloLicenca = 'Informações da Licença Ambiental';
        }
        if (this.variaveisGlobais.menuRaiz === 'mtrcc') {
            this.isDestinoFinal = true;
            this.tituloLicenca = 'Se TRANSPORTADOR, preenchimento dos campos da licença são opcionais';
        }
        this.enderecos = [];
        this.enderecoService.getEnderecosPorEntidade(idEntidade).subscribe(function (enderecos) {
            _this.enderecos = enderecos;
            console.log('........mostrarDialogParaEditar................>>', _this.enderecos);
        }, function (error) {
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
        this.licencasItem = [];
        this.licencaAmbientalService.getLicencasPorEntidade(idEntidade).subscribe(function (licencas) {
            _this.licencas = licencas;
            licencas.forEach(function (lic) {
                _this.licencasItem.push({ label: lic.licenca, value: lic.id });
            });
            _this.spinnerService.ocultar();
        }, function (error) {
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
        this.veiculoService.getVeiculosPorEntidade(idEntidade).subscribe(function (veiculos) {
            _this.veiculos = veiculos;
            _this.spinnerService.ocultar();
        }, function (error) {
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
        this.recuperaAnexosEntidade(idEntidade);
        this.buscaPessoasFisicasEntidade(idEntidade);
        // this.entidadeService.getEntidade;
        this.insertMode = false;
        var entidadeParaEditar = this.entidades.find(function (entidade) {
            return entidade.id === idEntidade;
        });
        this.entidadeEdit = __assign({}, entidadeParaEditar);
        if (this.entidadeEdit.licencaAmbientalDataValidade != null) {
            this.entidadeEdit.licencaAmbientalDataValidade = new Date(this.entidadeEdit.licencaAmbientalDataValidade);
        }
        console.log('Passou...ENTIDADE................', this.entidadeEdit);
        this.controlaRazaoSocialEnt = true;
        // this.controlaTelefoneEnt = true;
        this.controlaEmailEnt = true;
        this.controlaNumeroCnaeEnt = true;
        this.controlaDescricaoCnaeEnt = true;
        if (this.entidadeEdit.razaoSocial != null) {
            this.controlaRazaoSocialEnt = false;
        }
        // if (this.entidadeEdit.telefone != null) {
        //   this.controlaTelefoneEnt = false;
        // }
        if (this.entidadeEdit.email != null) {
            this.controlaEmailEnt = false;
        }
        if (this.entidadeEdit.numeroCnae != null) {
            this.controlaNumeroCnaeEnt = false;
        }
        if (this.entidadeEdit.descricaoCnae != null) {
            this.controlaDescricaoCnaeEnt = false;
        }
        this.podeSalvar = false;
        if (this.variaveisGlobais.roleEntidade || this.variaveisGlobais.userName === 'flavio'
            || this.variaveisGlobais.userName === 'walfrido') {
            this.podeSalvar = true;
        }
        // if (this.entidadeEdit.enderecoDO == null) {
        //   this.entidadeEdit.enderecoDO = {};
        // }
        this.mostrarDialogEdit = true;
        this.tituloDialogEdit = 'Editar Empresa ' + this.entidadeEdit.razaoSocial;
    };
    ListaEntidadesComponent.prototype.recuperaAnexosEntidade = function (idEntidade) {
        var _this = this;
        this.comAnexos = false;
        this.anexos = [];
        // this.anexos.length = 999;
        this.spinnerService.exibir();
        this.anexoService.getAnexosPorEntidade(idEntidade, this.variaveisGlobais.menuRaiz).subscribe(function (anexos) {
            _this.anexos = anexos;
            _this.spinnerService.ocultar();
            // if (this.anexos.length > 0) {
            console.log('recuperaAnexosEntidade>>>>>>>>>......Anexos..................', anexos);
            _this.comAnexos = true;
            // }
        }, function (error) {
            console.log('recuperaAnexosEntidade>>>>>>>>>erro>>>>', error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaEntidadesComponent.prototype.mostrarDialogEditParaExcluir = function (idEntidade) {
        this.entidadeExcluir = this.entidades.find(function (entidade) {
            return entidade.id === idEntidade;
        });
        this.mostrarDialogExcluir = true;
    };
    ListaEntidadesComponent.prototype.salvarNovo = function () {
        // Verifica se campos obrigatórios foram preenchidos
        if (this.entidadeEdit.cnpj == null || this.entidadeEdit.cnpj === '') {
            this.notificacoesService.notificarErro('CNPJ não informado', null);
            return;
        }
        if (this.entidadeEdit.nomeFantasia == null || this.entidadeEdit.nomeFantasia === '') {
            this.notificacoesService.notificarErro('NOME FANTASIA não informado', null);
            return;
        }
        if (this.entidadeEdit.razaoSocial == null || this.entidadeEdit.razaoSocial === '') {
            this.notificacoesService.notificarErro('RAZÃO SOCIAL não informado', null);
            return;
        }
        if (this.entidadeEdit.cnpj == null || this.entidadeEdit.cnpj === '') {
            this.notificacoesService.notificarErro('CNPJ não informado', null);
            return;
        }
        if (this.entidadeEdit.telefone == null || this.entidadeEdit.telefone === '') {
            this.notificacoesService.notificarErro('TELEFONE da Empresa não informado', null);
            return;
        }
        if (this.entidadeEdit.email == null || this.entidadeEdit.email === '') {
            this.notificacoesService.notificarErro('EMAIL não informado', null);
            return;
        }
        // console.log('ENDEREÇO DA ENTIDADE INSERT .............>>', this.endereco);
        if (this.endereco.municipio === undefined || this.endereco.municipio === null) {
            this.notificacoesService.notificarErro('ENDEREÇO não informado', null);
            return;
        }
        this.salvar();
    };
    ListaEntidadesComponent.prototype.salvar = function () {
        // console.log('SALVARRRRRRRRR   ENDEREÇO DA ENTIDADE INSERT .............>>', this.endereco);
        /**    // Verifica se todos os campos foram preenchidos
            if (this.entidadeEdit.nome == null || this.entidadeEdit.nome === ''
                || this.entidadeEdit.descricao == null || this.entidadeEdit.descricao === ''
                || this.entidadeEdit.unidadeMedida == null) {
              this.notificacoesService.notificarErro('Preencha todos os campos', null);
              return ;
            }
        */
        var _this = this;
        this.spinnerService.exibir();
        // Se novo
        if (this.entidadeEdit.id == null) {
            this.entidadeService.criarEntidade(this.entidadeEdit).subscribe(function (entidadeNovo) {
                _this.entidadeEdit = entidadeNovo;
                _this.entidades.push(entidadeNovo);
                _this.globals.entidade = entidadeNovo;
                _this.mostrarDialogEdit = false;
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarSucesso('Entidade adicionada com sucesso', null);
                // inclui relacionamento da nova empresa com usuário logado
                _this.pfFuncao = 'rLegal';
                _this.pessoaFisica = _this.variaveisGlobais.pessoaFisica;
                _this.geraRelacionamentoPJPF();
                _this.insertMode = false;
                _this.controlaRazaoSocialEnt = false;
                // this.controlaTelefoneEnt = false;
                _this.controlaEmailEnt = false;
                _this.controlaNumeroCnaeEnt = false;
                _this.controlaDescricaoCnaeEnt = false;
                _this.salvarEndereco();
                _this.pessoaFisica = _this.variaveisGlobais.pessoaFisica;
            }, function (error) {
                console.log(error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
        else {
            // Se editando
            this.entidadeService.editarEntidade(this.entidadeEdit).subscribe(function (entidadeEditado) {
                // Remove o valor atual
                // console.log("Lista de entidades atual .............>>", this.entidades);
                _this.entidades = _this.entidades.filter(function (item) { return item.id !== _this.entidadeEdit.id; });
                // Adiciona o novo
                _this.entidades.push(entidadeEditado);
                _this.globals.entidade = entidadeEditado;
                _this.globals.entidades = _this.entidades;
                _this.mostrarDialogEdit = false;
                _this.spinnerService.ocultar();
                _this.pessoaFisica = _this.variaveisGlobais.pessoaFisica;
                console.log('PESSOA FISICA .............>>', _this.pessoaFisica);
                _this.notificacoesService.notificarSucesso('Entidade atualizada com sucesso', null);
            }, function (error) {
                console.log(error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    ListaEntidadesComponent.prototype.excluir = function () {
        var _this = this;
        this.spinnerService.exibir();
        this.entidadeService.excluirEntidade(this.entidadeExcluir).subscribe(function () {
            // Remove o valor atual
            _this.entidades = _this.entidades.filter(function (item) { return item.id !== _this.entidadeExcluir.id; });
            _this.mostrarDialogExcluir = false;
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarSucesso('Entidade excluída com sucesso', null);
        }, function (error) {
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro('Erro ao exluir entidade', error.error);
        });
    };
    ListaEntidadesComponent.prototype.addPessoaFisica = function () {
        var _this = this;
        console.log('--------------------->>>>>', this.variaveisGlobais);
        this.pessoaFisica = new PessoaFisica();
        this.pessoaFisica.nomeCompleto = '';
        this.pessoaFisica.idEntidade = this.entidadeEdit.id;
        // this.pessoaFisica.endereco = new Endereco;
        console.log(this.pessoaFisica);
        this.mostrarDialogoPessoaFisica = true;
        // this.mostrarDialogEdit = false;
        // if (this.variaveisGlobais.menuRaiz == "mtrcc") {
        this.tituloDialogEditPessoaFisica = 'Novo Responsável';
        this.insertModePF = true;
        this.pfFuncao = 'rLegal';
        this.pessoaFisica.ativo = true;
        this.pfFuncaoRT = [];
        if (this.isPMPA) {
            this.secretarias = [];
            this.idSecretaria = null;
            this.secretariasPmpa.forEach(function (sec) {
                _this.secretarias.push({ label: sec.sigla, value: sec.id });
            });
        }
    };
    ListaEntidadesComponent.prototype.controlaFuncao = function (funcao) {
        console.log('.......controlaFuncao>>>>', funcao);
        if (funcao === 'rLegal' || funcao === 'rusrLegal') {
            this.pfFuncaoRT = [];
        }
        else {
            this.pfFuncao = '';
        }
    };
    ListaEntidadesComponent.prototype.salvarPessoaFisica = function () {
        var _this = this;
        // this.entidadeService.getEntidadePorId(this.entidadeEdit.id).subscribe(
        //   entidade => {
        //     this.entidadeEdit = entidade;
        // //Salva PessoaFisica
        this.pessoaFisica['idEntidade'] = this.entidadeEdit.id;
        this.pessoaFisica.rLegal = false;
        this.pessoaFisica.rusrLegal = false;
        this.pessoaFisica.rtElaboracao = false;
        this.pessoaFisica.rtExecucao = false;
        if (this.pfFuncao === 'rLegal') {
            this.pessoaFisica.rLegal = true;
        }
        if (this.pfFuncao === 'rusrLegal') {
            this.pessoaFisica.rusrLegal = true;
        }
        if (this.pfFuncaoRT.length > 1) {
            this.pessoaFisica.rtElaboracao = true;
            this.pessoaFisica.rtExecucao = true;
        }
        else if (this.pfFuncaoRT.length > 0) {
            if (this.pfFuncaoRT[0] === 'rElaboracao') {
                this.pessoaFisica.rtElaboracao = true;
            }
            if (this.pfFuncaoRT[0] === 'rExecucao') {
                this.pessoaFisica.rtExecucao = true;
            }
        }
        var okSalvar = true;
        if (this.isPMPA) {
            console.log('..........salvarPessoaFisica........listaPF..............>>', this.idSecretaria);
            console.log('..........salvarPessoaFisica........listaPF..............>>', this.secretariasPmpa);
            this.secretariasPmpa.forEach(function (secr) {
                if (secr.id === _this.idSecretaria) {
                    _this.pessoaFisica.secretariaPmpa = secr;
                }
            });
            if (this.pessoaFisica.secretariaPmpa === undefined || this.pessoaFisica.secretariaPmpa === null) {
                this.notificacoesService.notificarAviso('Por favor, informar a Secretaria/Órgão!', null);
                okSalvar = false;
            }
        }
        if (okSalvar) {
            console.log('salvarPessoaFisica........listaPF..............>>', this.pessoaFisica);
            if (this.pessoaFisica.id == null) {
                this.pessoaFisica.ativo = true;
                this.pessoaFisicaService.criarPessoaFisica(this.pessoaFisica).subscribe(function (retorno) {
                    // this.mostrarDialogEdit = false;
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarSucesso('PessoaFisica salvo com sucesso', null);
                    _this.globals.pessoaFisica = retorno;
                    if (_this.pessoaFisica.id === null) {
                        _this.pessoaFisicas.push(retorno);
                    }
                    _this.pessoaFisica = retorno;
                    _this.mostrarDialogoPessoaFisica = false;
                    _this.pessoaFisica = null;
                    _this.buscaPessoasFisicasEntidade(_this.entidadeEdit.id);
                    _this.pessoaFisica = _this.variaveisGlobais.pessoaFisica;
                    console.log('PESSOA FISICA .............>>', _this.pessoaFisica);
                }, function (error) {
                    console.log(error);
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro(error.error, null);
                });
            }
            else {
                var permiteInativar_1 = false;
                var permiteAtualizarRl_1 = false;
                if (this.pessoaFisica.ativo && this.pessoaFisica.rLegal) {
                    permiteAtualizarRl_1 = true;
                    permiteInativar_1 = true;
                }
                else {
                    this.pessoaFisicas.forEach(function (pessoaF) {
                        if (_this.pessoaFisica.id !== pessoaF.id) {
                            if (pessoaF.rLegal) {
                                permiteAtualizarRl_1 = true;
                                if (pessoaF.ativo) {
                                    permiteInativar_1 = true;
                                }
                            }
                        }
                    });
                }
                if (permiteAtualizarRl_1 && permiteInativar_1) {
                    this.mudancaEmail = false;
                    this.pessoaFisicas.forEach(function (pfOriginal) {
                        if (pfOriginal.id === _this.pessoaFisica.id) {
                            if (pfOriginal.email !== _this.pessoaFisica.email) {
                                _this.mudancaEmail = true;
                            }
                        }
                    });
                    if (!this.mudancaEmail) {
                        this.atualizaPessoaFisica(this.pessoaFisica);
                    }
                }
                else {
                    this.spinnerService.ocultar();
                    this.notificacoesService.notificarAviso('A Empresa deve possuir ao menos um Responsável Legal ATIVO!', null);
                    this.pessoaFisica.ativo = true;
                    // this.pessoaFisica.rLegal = true;
                }
            }
        }
        //   },
        //   error => {
        //     console.log(error);
        //     this.spinnerService.ocultar();
        //     this.notificacoesService.notificarErro(error.error, null);
        //   }
        // );
        this.insertMode = false;
    };
    ListaEntidadesComponent.prototype.atualizaPessoaFisica = function (pessoaFisica) {
        var _this = this;
        // console.log('atualizaPessoaFisica....................>', pessoaFisica);
        // console.log('atualizaPessoaFisica....................>', this.pessoaFisicas);
        this.mudancaEmail = false;
        this.pessoaFisicaService.atualizarPessoaFisica(pessoaFisica).subscribe(function (retorno) {
            // this.mostrarDialogEdit = false;
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarSucesso('PessoaFisica salvo com sucesso', null);
            _this.globals.pessoaFisica = retorno;
            _this.pessoaFisica = retorno;
            _this.mostrarDialogoPessoaFisica = false;
            _this.mostrarDialogoPFLogada = false;
            if (_this.entidadeEdit.id !== null) {
                _this.pessoaFisica = null;
                _this.buscaPessoasFisicasEntidade(_this.entidadeEdit.id);
            }
            _this.pessoaFisica = _this.variaveisGlobais.pessoaFisica;
            // console.log('PESSOA FISICA .............>>', this.pessoaFisica);
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaEntidadesComponent.prototype.geraRelacionamentoPJPF = function () {
        var _this = this;
        this.pessoaFisica['idEntidade'] = this.entidadeEdit.id;
        this.pessoaFisica.ativo = true;
        this.pessoaFisica.rLegal = true;
        this.pessoaFisica.rusrLegal = false;
        // console.log('salvarPessoaFisica........update..............>>', this.pessoaFisica);
        this.pessoaFisicaService.geraRelacionamentoPJPF(this.pessoaFisica).subscribe(function (retorno) {
            _this.pessoaFisica = null;
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaEntidadesComponent.prototype.inativarPessoaFisica = function (idPessoaFisica) {
        var _this = this;
        this.pessoaFisica = this.pessoaFisicas.find(function (pessoaFisica) {
            return pessoaFisica.id === idPessoaFisica;
        });
        this.pessoaFisicaService.inativarPessoaFisica(this.pessoaFisica).subscribe(function (retorno) {
            // this.mostrarDialogEdit = false;
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarSucesso('PessoaFisica inativada com sucesso', null);
            // this.globals.pessoaFisica = retorno;
            // this.pessoaFisica = retorno;
            _this.mostrarDialogoPessoaFisica = false;
            _this.buscaPessoasFisicasEntidade(_this.entidadeEdit.id);
            _this.pessoaFisica = null;
            _this.buscaPessoasFisicasEntidade(_this.entidadeEdit.id);
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaEntidadesComponent.prototype.buscaPessoasFisicasEntidade = function (idEntidade) {
        var _this = this;
        this.pessoaFisicas = [];
        this.pessoaFisicaService.getPessoasFisicasDaEntidade(idEntidade).subscribe(function (pessoaFisicas) {
            _this.pessoaFisicas = pessoaFisicas;
            // console.log('Pode Inativar........pessoas...........', this.pessoaFisicas);
            var ativos = 0;
            _this.pessoaFisicas.forEach(function (pessoa) {
                if (pessoa.ativo) {
                    ativos = ativos + 1;
                    // console.log('Pode Inativar.....Ativos..............', ativos);
                }
            });
            _this.podeInativar = false;
            if (ativos > 1) {
                _this.podeInativar = true;
            }
            // console.log('Pode Inativar...................', this.podeInativar, '---', ativos);
            _this.spinnerService.ocultar();
        });
    };
    ListaEntidadesComponent.prototype.selecionaCDL = function (endereco) {
        // console.log('ENDEREÇO selecionarCdl>>>>>>>', endereco);
        if (endereco.localizacao.latitude === undefined) {
            endereco.localizacao = {};
            // console.log('ENDEREÇO selecionarCdl>>>>>>>', endereco);
            endereco.localizacao.latitude = null;
            endereco.localizacao.longitude = null;
        }
        this.endereco = endereco;
        this.endereco.id = null;
        this.endereco.dataInclusao = null;
        this.endereco.timestampAsString = null;
        this.endereco.usuarioInclusao = null;
        this.endereco.usuarioAlteracao = null;
        this.endereco.isentoLicenca = false;
        // console.log('ENDEREÇO selecionarCdl>>>>>>>', this.endereco);
    };
    ListaEntidadesComponent.prototype.salvarEndereco = function () {
        // this.entidadeEdit.enderecoDO = endereco;
        // console.log('SALVAR ENDEREÇO >>>>>>>', this.entidadeEdit);
        // console.log('SALVAR ENDEREÇO >>>>>>>', this.insertMode);
        var _this = this;
        if (!this.insertMode && this.entidadeEdit.id !== null) {
            this.endereco.entidadeDO = this.entidadeEdit;
            // this.endereco.isentoLicenca = false;
            // const isencao = this.licencaIsento;
            // if (isencao === 'isento') {
            //   this.endereco.isentoLicenca = true;
            // }
            this.endereco.licencaAmbientalDO = null;
            this.licencas.forEach(function (lic) {
                if (lic.id === _this.idLicenca) {
                    _this.endereco.licencaAmbientalDO = lic;
                }
            });
            this.endereco.isentoLicenca = this.licencaIsento;
            // console.log('ENDEREÇO LICENÇA salvarEndereco>>>>>>>', this.licencaIsento.checked);
            if (this.endereco.codigoLogradouro == null) {
                this.endereco.enderecoFormatado =
                    this.endereco.nomeLogradouro + ', ' + this.endereco.numero;
            }
            else {
                this.endereco.municipio = 'Porto Alegre';
            }
            console.log('ENDEREÇO salvarEndereco>>>......>>>>', this.endereco);
            if (this.endereco.id === undefined || this.endereco.id === null) {
                this.enderecoService.criarEndereco(this.endereco).subscribe(function (retorno) {
                    _this.mostrarDialogoEnderecoPoa = false;
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarSucesso('Endereço salvo com sucesso', null);
                    if (_this.endereco.id === null) {
                        _this.enderecos.push(retorno);
                    }
                    _this.endereco = retorno;
                    _this.enderecos = [];
                    _this.enderecoService
                        .getEnderecosPorEntidade(_this.entidadeEdit.id)
                        .subscribe(function (enderecos) {
                        _this.enderecos = enderecos;
                    }, function (error) {
                        _this.spinnerService.ocultar();
                        _this.notificacoesService.notificarErro(error.error, null);
                    });
                }, function (error) {
                    console.log(error);
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro(error.error, null);
                });
            }
            else {
                this.enderecoService.atualizarEndereco(this.endereco).subscribe(function (retorno) {
                    _this.mostrarDialogoEnderecoPoa = false;
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarSucesso('Endereço salvo com sucesso', null);
                    if (_this.endereco.id === null) {
                        _this.enderecos.push(retorno);
                    }
                    _this.endereco = retorno;
                    _this.enderecos = [];
                    _this.enderecoService
                        .getEnderecosPorEntidade(_this.entidadeEdit.id)
                        .subscribe(function (enderecos) {
                        _this.enderecos = enderecos;
                    }, function (error) {
                        _this.spinnerService.ocultar();
                        _this.notificacoesService.notificarErro(error.error, null);
                    });
                }, function (error) {
                    console.log(error);
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro(error.error, null);
                });
            }
        }
        else {
            // console.log('SALVAR ENDEREÇO >>>>>>>', this.endereco);
            if (this.endereco.codigoLogradouro !== undefined && this.endereco.codigoLogradouro !== null) {
                this.endereco.municipio = 'Porto Alegre';
            }
        }
        this.idLicenca = null;
        this.licencaIsento = null;
        this.tituloDialogEditEndereco = '';
        this.mostrarDialogoEnderecoPoa = false;
        this.mostrarDialogoEnderecoForaPoa = false;
    };
    ListaEntidadesComponent.prototype.informaEnderecoPoa = function (isPoa) {
        console.log('ENDEREÇO informaEnderecoPoa>>>>>>>', this.licencasItem);
        this.endereco = new EnderecoEntidade();
        // this.endereco = {
        //   id: null,
        //   dataInclusao: null,
        //   timestampAsString: null,
        //   usuarioInclusao: null,
        //   usuarioAlteracao: null,
        //   cep: null,
        //   codigoBairro: null,
        //   codigoLogradouro: null,
        //   codigoLogradouroFim: null,
        //   codigoLogradouroInicio: null,
        //   dataHistorico: null,
        //   enderecoFormatado: null,
        //   enderecoFormatadoCurto: null,
        //   lado: null,
        //   localizacao: null,
        //   nomeBairro: null,
        //   nomeBairroHistorico: null,
        //   nomeHistorico: null,
        //   nomeLogradouro: null,
        //   nomeLogradouroFim: null,
        //   nomeLogradouroInicio: null,
        //   numero: null,
        //   municipio: null,
        //   situacao: null
        // };
        this.tituloDialogEditEndereco = '';
        this.mostrarDialogoEnderecoPoa = false;
        this.mostrarDialogoEnderecoForaPoa = false;
        if (isPoa) {
            this.mostrarDialogoEnderecoPoa = true;
            this.tituloDialogEditEndereco = 'Endereço de Porto Alegre.';
        }
        else {
            this.mostrarDialogoEnderecoForaPoa = true;
            this.tituloDialogEditEndereco = 'Endereço de outros Municípios.';
        }
        this.endereco.isentoLicenca = false;
        this.idLicenca = null;
        this.licencaIsento = null;
        console.log('NOVO ENDEREÇO atualizaEndereco>>>>>>>>', this.endereco, '----', this.insertMode);
    };
    ListaEntidadesComponent.prototype.atualizaEndereco = function (idEndereco) {
        var enderecoParaEditar = this.enderecos.find(function (end) {
            return end.id === idEndereco;
        });
        this.endereco = __assign({}, enderecoParaEditar);
        console.log('ENDEREÇO atualizaEndereco>>>>>>>>', this.endereco);
        this.tituloDialogEditEndereco = '';
        this.mostrarDialogoEnderecoPoa = false;
        this.mostrarDialogoEnderecoForaPoa = false;
        if (this.endereco.municipio === 'Porto Alegre') {
            this.mostrarDialogoEnderecoPoa = true;
            this.tituloDialogEditEndereco = 'Endereço de Porto Alegre.';
        }
        else {
            this.mostrarDialogoEnderecoForaPoa = true;
            this.tituloDialogEditEndereco = 'Endereço de outros Municípios.';
        }
        this.idLicenca = null;
        if (this.endereco.licencaAmbientalDO !== null) {
            this.idLicenca = this.endereco.licencaAmbientalDO.id;
        }
        this.licencaIsento = this.endereco.isentoLicenca;
    };
    ListaEntidadesComponent.prototype.excluirEndereco = function (idEndereco) {
        var _this = this;
        console.log('ENDEREÇO EXCLUSÃO>>>>>>>>', idEndereco);
        if (confirm('Confirma deleção do ENDEREÇO?')) {
            this.enderecoService.deletaEndereco(idEndereco).subscribe(function (res) {
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarSucesso('Endereço excluido com sucesso', null);
                _this.enderecos = [];
                _this.enderecoService
                    .getEnderecosPorEntidade(_this.entidadeEdit.id)
                    .subscribe(function (enderecos) {
                    _this.enderecos = enderecos;
                    // this.globals.enderecosEntidade = enderecos;
                }, function (error) {
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro(error.error, null);
                });
            }, function (error) {
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    ListaEntidadesComponent.prototype.informaLicenca = function () {
        console.log('LICENÇA informaLicença>>>>>>>');
        this.licenca = {
            id: null,
            dataInclusao: null,
            timestampAsString: null,
            usuarioInclusao: null,
            usuarioAlteracao: null,
            entidadeDO: null,
            secretariaPmpaDO: null,
            licenca: null,
            dataValidade: null,
            descricao: null
        };
        this.mostrarDialogoLicenca = true;
    };
    ListaEntidadesComponent.prototype.selLicenca = function () {
        this.licencaIsento = false;
    };
    ListaEntidadesComponent.prototype.licencaIsencao = function (licencaIsento) {
        // console.log('===========licencaIsencao=========>', licencaIsento.checked);
        // this.endereco.isentoLicenca = licencaIsento.checked;
        // if (licencaIsento.checked) {
        //   this.idLicenca = null;
        // }
        console.log('===========licencaIsencao=========>', this.licencaIsento);
        this.endereco.isentoLicenca = this.licencaIsento;
        if (this.licencaIsento) {
            this.idLicenca = null;
        }
    };
    ListaEntidadesComponent.prototype.informaVeiculo = function () {
        console.log('ENDEREÇO informaVeiculo>>>>>>>');
        this.veiculo = {
            id: null,
            dataInclusao: null,
            timestampAsString: null,
            usuarioInclusao: null,
            usuarioAlteracao: null,
            placa: null,
            renavam: null
        };
        this.mostrarDialogoVeiculo = true;
    };
    ListaEntidadesComponent.prototype.salvarLicenca = function () {
        var _this = this;
        if (this.licenca.secretariaPmpaDO !== null) {
            this.licenca.orgaoEmissor = this.licenca.secretariaPmpaDO.sigla;
        }
        // console.log('LICENÇA salvarLicenca>>>>>>>', this.licenca);
        if (this.entidadeEdit === undefined || this.entidadeEdit === null) {
            this.licenca.pessoaFisicaDO = this.pessoaFisica;
        }
        else {
            this.licenca.entidadeDO = this.entidadeEdit;
        }
        console.log('LICENÇA salvarLicenca>>>>>>>', this.licenca);
        this.licencaAmbientalService.criarLicenca(this.licenca).subscribe(function (retorno) {
            _this.mostrarDialogoLicenca = false;
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarSucesso('Licença Ambiental salva com sucesso!', null);
            _this.licenca = new LicencaAmbiental;
            _this.licencas = [];
            if (_this.entidadeEdit === undefined || _this.entidadeEdit === null) {
                _this.licencaAmbientalService.getLicencasPorPessoaFisica(_this.pessoaFisica.id)
                    .subscribe(function (licencas) {
                    _this.licencas = licencas;
                    licencas.forEach(function (lic) {
                        _this.licencasItem.push({ label: lic.licenca, value: lic.id });
                    });
                }, function (error) {
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro(error.error, null);
                });
            }
            else {
                _this.licencaAmbientalService.getLicencasPorEntidade(_this.entidadeEdit.id)
                    .subscribe(function (licencas) {
                    _this.licencas = licencas;
                    licencas.forEach(function (lic) {
                        _this.licencasItem.push({ label: lic.licenca, value: lic.id });
                    });
                }, function (error) {
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro(error.error, null);
                });
            }
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaEntidadesComponent.prototype.excluirLicenca = function (idLicenca) {
        var _this = this;
        console.log('LICENÇA EXCLUSÃO>>>>>>>>', idLicenca);
        if (confirm('Confirma deleção da Licença Ambiental?')) {
            this.licencaAmbientalService.deletaLicenca(idLicenca).subscribe(function (res) {
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarSucesso('Licença Ambiental excluida com sucesso', null);
                _this.licencas = [];
                _this.licencaAmbientalService.getLicencasPorEntidade(_this.entidadeEdit.id)
                    .subscribe(function (licencas) {
                    _this.licencas = licencas;
                    licencas.forEach(function (lic) {
                        _this.licencasItem.push({ label: lic.licenca, value: lic.id });
                    });
                }, function (error) {
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro(error.error, null);
                });
            }, function (error) {
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    ListaEntidadesComponent.prototype.salvarVeiculo = function () {
        var _this = this;
        console.log('VEÍCULO salvarVeiculo>>>>>>>', this.veiculo);
        this.veiculo.entidadeDO = this.entidadeEdit;
        this.veiculoService.criarVeiculo(this.veiculo).subscribe(function (retorno) {
            _this.mostrarDialogoVeiculo = false;
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarSucesso('Veículo salvo com sucesso!', null);
            _this.veiculo = retorno;
            _this.veiculos = [];
            _this.veiculoService
                .getVeiculosPorEntidade(_this.entidadeEdit.id)
                .subscribe(function (veiculos) {
                _this.veiculos = veiculos;
            }, function (error) {
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaEntidadesComponent.prototype.excluirVeiculo = function (idVeiculo) {
        var _this = this;
        console.log('VEÍCULO EXCLUSÃO>>>>>>>>', idVeiculo);
        if (confirm('Confirma deleção do VEÍCULO?')) {
            this.veiculoService.deletaVeiculo(idVeiculo).subscribe(function (res) {
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarSucesso('Veículo excluido com sucesso', null);
                _this.veiculos = [];
                _this.veiculoService
                    .getVeiculosPorEntidade(_this.entidadeEdit.id)
                    .subscribe(function (veiculos) {
                    _this.veiculos = veiculos;
                }, function (error) {
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro(error.error, null);
                });
            }, function (error) {
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    // adicionarAnexo(event) {
    //   console.log('1111111111');
    //   const arquivo: File = event.files[0];
    //   const tis = this;
    //   const reader = new FileReader();
    //   tis.spinnerService.exibir();
    //   reader.onload = function(e) {
    //     const data = reader.result;
    //     const novoAnexo: Anexo = {
    //       fileName: arquivo.name,
    //       fileType: arquivo.type,
    //       fileSize: arquivo.size,
    //       // base64File: data,
    //       file: data,
    //       descricao: tis.novoAnexoDescricao,
    //       tipoAnexo: tis.tipoAnexo,
    //       situacao: tis.situacao
    //     };
    //     tis.anexoService.salvarAnexo(tis.entidadeEdit.id, novoAnexo).subscribe(
    //       anexo => {
    //         tis.anexoService.setFile(anexo['id'], novoAnexo.file).subscribe(
    //           ret => {
    //             tis.spinnerService.ocultar();
    //             tis.notificacoesService.notificarSucesso(
    //               'Anexo adicionado com sucesso',
    //               null
    //             );
    //             console.log('setFile>>>>>>>>>>>>....', ret);
    //             tis.novoAnexoDescricao = null;
    //             tis.fileUpload.clear();
    //             tis.recuperaAnexosEntidade(tis.entidadeEdit.id);
    //             // tis.anexos.push(anexo);
    //           },
    //           error => {
    //             console.log(error);
    //             tis.spinnerService.ocultar();
    //             tis.notificacoesService.notificarErro(
    //               'Erro ao adicionar anexo',
    //               error.message
    //             );
    //           }
    //         );
    //       },
    //       error => {
    //         console.log(error);
    //         tis.spinnerService.ocultar();
    //         tis.notificacoesService.notificarErro(
    //           'Erro ao adicionar anexo',
    //           null
    //         );
    //       }
    //     );
    //   };
    //   reader.readAsArrayBuffer(arquivo);
    //   // reader.readAsDataURL(arquivo);
    // }
    // recuperaAnexo(anexo): string {
    //   // this.anexoService.getAnexo(anexo.id).subscribe(retorno => {
    //   //   // this.spinnerService.ocultar();
    //   //   console.log('getUrlAnexo', retorno);
    //   //   // this.notificacoesService.notificarSucesso('PessoaFisica salvo com sucesso', null);
    //   // },
    //   //   error => {
    //   //     console.log('ERRO>>>>>>', error);
    //   //     this.spinnerService.ocultar();
    //   //     this.notificacoesService.notificarErro(error.error, null);
    //   //   });
    // //  console.log('getUrlAnexo>>>>>', anexo);
    //   // const url = environment.apiUrl + '/anexos/' + idAnexo;
    //   // return url;
    //   const url = environment.apiUrl + '/anexos/' + anexo.id;
    //   console.log('getUrlAnexo>>>>>>>>>>>>>', url);
    //   return url;
    // }
    ListaEntidadesComponent.prototype.excluirAnexo = function (anexo) {
        var _this = this;
        var r = confirm('Confirme a exclusão do anexo ' + anexo.fileName + '?');
        if (r === true) {
            this.anexoService.excluirAnexo(anexo.id).subscribe(function () {
                _this.notificacoesService.notificarSucesso('Anexo excluído com sucesso', '');
                _this.anexos = _this.anexos.filter(function (item) { return item.id !== anexo.id; });
            }, function (error) {
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    ListaEntidadesComponent.prototype.situacaoAnexo = function (anexo, situacao) {
        var _this = this;
        anexo.situacao = false;
        if (situacao === 'ativar') {
            anexo.situacao = true;
        }
        anexo.file = null;
        this.anexoService.situacaoAnexo(anexo.id, situacao).subscribe(function (anexo) {
            _this.recuperaAnexosEntidade(_this.entidadeEdit.id);
            // this.anexos = this.anexos.filter(item => item.id !== anexo.id);
        }, function (error) {
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaEntidadesComponent.prototype.podeSalvarAnexo = function () {
        // if (this.planoEdit == null) {
        //   return false;
        // }
        // if (this.planoEdit.situacao === 'Edicao') {
        //   return true;
        // }
        if (this.insertMode === true) {
            return true;
        }
        return true;
    };
    ListaEntidadesComponent.prototype.visualizaAnexo = function (anexo) {
        var _this = this;
        // console.log('visualizaAnexo..........getUrlAnexo', anexo);
        this.spinnerService.exibir();
        this.anexoService.getAnexo(anexo.id).subscribe(function (anexoDO) {
            _this.spinnerService.ocultar();
            var tipoArquivo = anexoDO.fileType.split('/');
            if (tipoArquivo[0] === 'image') {
                var image = new Image();
                image.src = 'data:image/jpg;base64,' + anexoDO.file;
                var w = window.open('');
                w.document.write(image.outerHTML);
            }
            else if (tipoArquivo[0] === 'application') {
                if (tipoArquivo[1] === 'pdf') {
                    var byteCharacters = atob(anexoDO.file);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    var file = new Blob([byteArray], { type: 'application/pdf;base64' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }
                else if (tipoArquivo[1] ===
                    'vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                    tipoArquivo[1] ===
                        'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    // docx
                    // const reader = new FileReader();
                    var byteCharacters = atob(anexoDO.file);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    var file = new Blob([byteArray], { type: 'application/octet-stream' });
                    var downloadUrl = window.URL.createObjectURL(file);
                    var a = document.createElement('a');
                    a.href = downloadUrl;
                    a.download = anexoDO.fileName;
                    document.body.appendChild(a);
                    a.click();
                    URL.revokeObjectURL(downloadUrl);
                }
                else {
                    // xml
                    _this.notificacoesService.notificarSucesso('Arquivo não previsto para visualização!', '');
                }
            }
        }, function (error) {
            console.log('ERRO>>>>>>', error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
        // const FileSaver = require('file-saver');
        // const blob = new Blob(["Hello, world!"], { type: "text/plain;charset=utf-8" });
        // FileSaver.saveAs(blob, "hello world.txt");
        // console.log("File Saver>>>>>", FileSaver)
        // const anexoPdf = atob(anexo.value);
        // const b = new Blob([(<any>anexoPdf)], { type: 'application/pdf;base64' });
        // const fileURL = URL.createObjectURL(b);
        // window.open(fileURL);
    };
    ListaEntidadesComponent.prototype.onFileChange = function (event) {
        // console.log("UPLOAD TESTE EVENT>>>>>>>>>", event.target.files[0]);
        var _this = this;
        this.anexo = {
            id: null,
            fileType: null,
            fileName: null,
            fileSize: null,
            file: null
        };
        var reader = new FileReader();
        console.log('onFileChange reader>>>>>>>>>', reader);
        var file;
        if (event.target.files && event.target.files.length > 0) {
            file = event.target.files[0];
            reader.readAsDataURL(file);
            reader.onload = function () {
                // const novoAnexo: Anexo = {
                _this.anexo.fileName = file.name;
                _this.anexo.fileType = file.type;
                _this.anexo.fileSize = file.size;
                // base64File: data,
                console.log(reader.result);
                // @ts-ignore
                _this.anexo.file = reader.result.split(',')[1];
                _this.anexo.descricao = null;
                // }
                console.log('UPLOAD TESTE ANEXO>>>>>>>>>', _this.anexo);
            };
        }
        //   anexo.fileType = event.target.files[0].type,
        //   fileName: event.target.files[0].name,
        //   fileSize: event.target.files[0].size,
        //   value: JSON.stringify(reader),
        //   evidenciaDO: this.evidencia
        // } as Anexo;
        // this.anexo.fileName = event.target.files.name;
        // this.anexo.fileType = event.target.files.type;
        // this.anexo.fileSize = event.target.files.size;
        // this.anexo.value = reader.result;
        // this.anexo = novoAnexo;
        // console.log("UPLOAD TESTE>>>>>>>>>", reader);
        // console.log("UPLOAD TESTE EVENT>>>>>>>>>", event.target.files[0]);
    };
    ListaEntidadesComponent.prototype.clearFile = function () {
        // this.fileInput.nativeElement.value = '';
        this.anexo = null;
        this.anexoSel = '';
        // this.evidenciaForm.get('avatar').setValue("");
        // this.evidenciaForm.get('avatar').markAsPristine();
        // this.evidenciaForm.get('avatar').markAsUntouched();
    };
    ListaEntidadesComponent.prototype.persisteAnexo = function () {
        // console.log('persisteAnexo ANEXO ===============', this.anexoSel);
        // console.log('persisteAnexo TIPOANEXO ===============', this.tipoAnexo);
        var _this = this;
        if (this.anexoSel === '' || this.tipoAnexo === '') {
            this.notificacoesService.notificarErro('Para salvar é necessário <Escolher o Arquivo> e selecionar o <Tipo Anexo>!', null);
        }
        else {
            var tipoArquivo = this.anexo.fileType.split('/');
            // console.log('persisteAnexo ===============>>>>>>>>', tipoArquivo);
            if (tipoArquivo[0] !== 'image' && tipoArquivo[1] !== 'pdf') {
                this.notificacoesService.notificarErro('Favor anexar somente arquivos tipo imagem ou pdf!', null);
            }
            else {
                this.anexo.tipoAnexo = this.tipoAnexo;
                console.log('******************************************************************');
                if (this.anexo) {
                    this.anexo.situacao = true;
                    this.spinnerService.exibir();
                    this.anexoService.incluirAnexo(this.entidadeEdit.id, this.anexo).subscribe(function (anexo) {
                        // console.log('evidenciaDetail - Retorno INCLUIR Anexo ===============', anexo);
                        _this.recuperaAnexosEntidade(_this.entidadeEdit.id);
                        _this.spinnerService.ocultar();
                        _this.notificacoesService.notificarSucesso('Anexo salvo com sucesso!', null);
                        _this.anexoSel = '';
                        _this.tipoAnexo = '';
                        _this.anexo = {
                            id: null,
                            fileType: null,
                            fileName: null,
                            fileSize: null,
                            file: null,
                            situacao: null
                        };
                        // console.log('persisteAnexo ANEXO 0000===============');
                    }, function (error) {
                        console.log('ERRO>>>>>>', error);
                        _this.spinnerService.ocultar();
                        _this.notificacoesService.notificarErro(error.error, null);
                    });
                }
            }
        }
    };
    ListaEntidadesComponent.prototype.mostrarVinculosAtuais = function (idEntidade) {
        var _this = this;
        this.entidadeEdit = this.entidades.find(function (entidade) {
            return entidade.id === idEntidade;
        });
        console.log('Vinculadas Entidade Editada>>>>', this.entidadeEdit);
        this.spinnerService.exibir();
        this.entidadeService.getEntidadesVinculadas(idEntidade).subscribe(function (entidades) {
            console.log('Lista Entidades Vinculadas>>>>', entidades);
            if (entidades.length > 0) {
                _this.spinnerService.ocultar();
                _this.entidadesVinculadas = entidades;
                _this.mostrarVinculos = true;
            }
            else {
                _this.mostrarVinculos = false;
            }
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
        // this.pesquisaEntidades("");
        this.tituloVinculos = 'Lista de Transportadores e Destinos Finais';
    };
    ListaEntidadesComponent.prototype.pesquisaEntidades = function (tipoPesquisa) {
        var _this = this;
        this.entidades = [];
        console.log('Pesquisa>>>>>>>>>>>>', tipoPesquisa, this.selectedPesquisa);
        this.spinnerService.exibir();
        this.entidadeService.getEntidadesPesquisa(this.selectedPesquisa).subscribe(function (entidades) {
            _this.spinnerService.ocultar();
            _this.entidades = entidades;
            // this.totalEntidades = entidades.length;
            // this.entidades.forEach(entidade => {
            //   console.log('Lista Entidades >>>>', entidade.possuiPlanos);
            //   this.podeExibirResiduos(entidade);
            // });
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaEntidadesComponent.prototype.pesquisaEntidadesGdr = function (tipoPesquisa) {
        var _this = this;
        this.entidades = [];
        console.log('pesquisaEntidadesGdr>>>>>>>>>>>>', tipoPesquisa, this.selectedPesquisa);
        this.spinnerService.exibir();
        this.entidadeService.getEntidadesPesquisaGdr(tipoPesquisa).subscribe(function (entidades) {
            _this.spinnerService.ocultar();
            _this.entidades = entidades;
            _this.totalEntidades = entidades.length;
            _this.entidades.forEach(function (entidade) {
                console.log('gdrEntidadeComContrato.....*******......>>>>', entidade);
                if (entidade.gdrNumeroContrato !== null) {
                    entidade.gdrNumeroContrato = entidade.gdrNumeroContrato.toString() + '/' + entidade.gdrAnoContrato;
                }
            });
            // this.gdrEntidadeComContrato();
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaEntidadesComponent.prototype.checkActive = function (route) {
        return route === this.activeRoute;
    };
    ListaEntidadesComponent.prototype.verificaCnpjExiste = function (cnpj) {
        var _this = this;
        console.log('verificaCnpjExiste>>>>>>>', cnpj);
        if (cnpj === '__.___.___/____-__') {
            cnpj = null;
        }
        this.entidadeEdit = {
            id: null,
            cnpj: null,
            razaoSocial: '',
            dataInclusao: null,
            timestampAsString: null,
            usuarioInclusao: null,
            usuarioAlteracao: null,
            // nomeSisLog: null,
            licencaAmbiental: null,
            licencaAmbientalOrgaoEmissor: null,
            licencaAmbientalDataValidade: null,
            alvara: null,
            gdrNumeroContrato: null,
            gdrAnoContrato: null,
            gdrSituacaoContrato: null,
            gdrCertificado: null,
            codigoDmlu: null,
            adimplenteDmlu: null,
            desabilitadaMtrcc: null
        };
        this.controlaRazaoSocialEnt = true;
        // this.controlaTelefoneEnt = true;
        this.controlaEmailEnt = true;
        this.controlaNumeroCnaeEnt = true;
        this.controlaDescricaoCnaeEnt = true;
        if (cnpj == null || cnpj === '') {
            // this.spinnerService.ocultar();
            // this.notificacoesService.notificarErro('Não encontrou entidade para CNPJ informado!', null);
        }
        else {
            this.entidadeEdit.cnpj = cnpj;
            this.entidadeService.getEntidadeCnpj(cnpj).subscribe(function (entidade) {
                if (entidade != null) {
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro('Empresa com CNPJ informado já está cadastrada!', null);
                }
                else {
                    // console.log('verificaCnpjExiste1111.....>>>>>>>', entidade);
                    _this.spinnerService.exibir();
                    var novoCnpj = cnpj.replace(/[^\d]+/g, '');
                    _this.smfPessoaService.getPessoaFazenda(novoCnpj, '2').subscribe(function (pessoa) {
                        // console.log('ACESSO PESSOA SMF>>>>>>>>', pessoa);
                        _this.controlaMensagemFazenda = false;
                        if (pessoa !== null) {
                            if (pessoa.nomePessoa != null) {
                                _this.entidadeEdit.nomeFantasia = pessoa.nomeFantasia;
                                _this.entidadeEdit.razaoSocial = pessoa.nomePessoa;
                                _this.entidadeEdit.telefone = pessoa.telefone;
                                _this.entidadeEdit.email = pessoa.email;
                                _this.entidadeEdit.numeroCnae = pessoa.numeroCnae;
                                _this.entidadeEdit.descricaoCnae = pessoa.descricaoCnae;
                                _this.controlaRazaoSocialEnt = false;
                                // this.controlaTelefoneEnt = false;
                                _this.controlaEmailEnt = false;
                                _this.controlaNumeroCnaeEnt = false;
                                _this.controlaDescricaoCnaeEnt = false;
                                // this.entidadeEdit. = pessoa.;
                                // this.entidadeEdit. = pessoa.;
                                // this.entidadeEdit. = pessoa.;
                                _this.controlaMensagemFazenda = true;
                                console.log('verificaCnpjExiste1111.....>>>>>>>', pessoa);
                                console.log('verificaCnpjExiste1111.....>>>>>>>', _this.controlaMensagemFazenda);
                            }
                        }
                        _this.spinnerService.ocultar();
                    }, function (error) {
                        console.log('-----------------------------------------------------------');
                        console.log(error);
                        console.log(error.error);
                        _this.spinnerService.ocultar();
                        _this.notificacoesService.notificarErro(error.error, null);
                    });
                }
            }, function (error) {
                console.log(error);
                console.log(error.error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    ListaEntidadesComponent.prototype.verificaCpfExiste = function (cpf) {
        var _this = this;
        console.log('verificaCpfExiste>>>>>>>', cpf);
        if (cpf === '__.___.___-__') {
            cpf = null;
        }
        if (cpf == null || cpf === '') {
            // this.spinnerService.ocultar();
            // this.notificacoesService.notificarErro('Não encontrou entidade para CNPJ informado!', null);
        }
        else {
            this.pessoaFisicaService.getPessoasFisicasPorCpf(cpf).subscribe(function (pessoaFisica) {
                console.log('verificaCnpjExiste1111>>>>>>>', pessoaFisica);
                if (pessoaFisica != null) {
                    _this.pessoaFisica = pessoaFisica;
                    _this.pessoaFisica.ativo = true;
                    _this.insertMode = false;
                    // this.spinnerService.ocultar();
                    // this.notificacoesService.notificarErro('Empresa com CNPJ informado já está cadastrada!', null);
                }
                else {
                    var novoCpf = cpf.replace(/[^\d]+/g, '');
                    // Acessa SMF
                    _this.smfPessoaService.getPessoaFazenda(novoCpf, '1').subscribe(function (pessoa) {
                        console.log('ACESSO PESSOA SMF>>>>>>>>', pessoa);
                        _this.pessoaFisica.nomeCompleto = pessoa.nomePessoa;
                    });
                }
            }, function (error) {
                console.log(error);
                console.log(error.error);
                _this.pessoaFisica.cpf = null;
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    ListaEntidadesComponent.prototype.gdrEntidadeComContrato = function () {
        var _this = this;
        var listaIdEntidade = [];
        console.log('*****************************>>>>>', this.entidades.length);
        this.entidades.forEach(function (entidade) {
            listaIdEntidade.push(entidade.id);
        });
        this.spinnerService.exibir();
        this.dmluService.getEntidadesComContrato(listaIdEntidade).subscribe(function (contratos) {
            _this.spinnerService.ocultar();
            console.log('gdrEntidadeComContrato.....*******......>>>>', contratos);
            if (contratos !== null) {
                contratos.forEach(function (contratoVO) {
                    _this.entidades.forEach(function (entidade) {
                        if (entidade.id === contratoVO.idContratante) {
                            console.log('gdrEntidadeComContrato.....*******......>>>>', entidade);
                            entidade.gdrNumeroContrato = contratoVO.numeroContrato.toString() + '/' + contratoVO.anoContrato;
                            entidade.gdrSituacaoContrato = contratoVO.situacao;
                            entidade.gdrCertificado = contratoVO.id;
                        }
                    });
                });
            }
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaEntidadesComponent.prototype.informacaoCadastral = function (pf) {
        var _this = this;
        // console.log('informacaoCadastral...........>>>>', pf);
        this.mostrarDialogoPFLogada = true;
        this.pessoaFisica = pf;
        this.pfEmail = pf.email;
        this.licencaAmbientalService.getLicencasPorPessoaFisica(this.pessoaFisica.id)
            .subscribe(function (licencas) {
            _this.licencas = licencas;
            licencas.forEach(function (lic) {
                _this.licencasItem.push({ label: lic.licenca, value: lic.id });
            });
        }, function (error) {
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaEntidadesComponent.prototype.salvaPessoaFisicaLogada = function (pessoaFisica) {
        console.log('atualizaPessoaFisica....................>', pessoaFisica);
        console.log('atualizaPessoaFisica....................>', this.variaveisGlobais.pessoaFisica);
        this.mudancaEmail = false;
        if (this.pfEmail !== pessoaFisica.email) {
            this.mudancaEmail = true;
        }
        if (!this.mudancaEmail) {
            this.atualizaPessoaFisica(pessoaFisica);
        }
        this.pfEmail = '';
    };
    ListaEntidadesComponent.prototype.mostraExpedienteUnico = function (codigoLogradouro, numero) {
        var _this = this;
        this.mostrarDialogoEU = true;
        this.expedienteUnicoVO = new ExpedienteUnicoVO();
        this.expedienteUnicoVOList = [];
        this.spinnerService.exibir();
        if (codigoLogradouro === null) {
            // this.expedienteUnicoVO.expedienteFormatado = "Não foi possível consultar os EUs do endereço., pois está sem o código do logradouro";
            this.expedienteUnicoVO.expedienteFormatado = "naoOK";
            this.expedienteUnicoVO.procedimentoFormatadoSei = "Não foi possível consultar os EUs deste endereço.Este endereço está sem o código de logradouro necessário para a consulta.";
            this.expedienteUnicoVOList.push(this.expedienteUnicoVO);
            this.spinnerService.ocultar();
        }
        else {
            this.enderecoService.getEnderecoCodigoNumeroListaEU(codigoLogradouro, numero).subscribe(function (expedienteUnicoList) {
                // this.licencas = licencas;
                _this.expedienteUnicoVOList = expedienteUnicoList;
                console.log('---------------EU>>>', _this.expedienteUnicoVOList);
                _this.spinnerService.ocultar();
                if (expedienteUnicoList.length < 1) {
                    _this.expedienteUnicoVO.expedienteFormatado = "naoOK";
                    _this.expedienteUnicoVO.procedimentoFormatadoSei = "Endereço sem Expediente Único.";
                    _this.expedienteUnicoVOList.push(_this.expedienteUnicoVO);
                }
            }, function (error) {
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
                _this.expedienteUnicoVO = null;
                _this.spinnerService.ocultar();
            });
        }
    };
    ListaEntidadesComponent.prototype.habilitarDesabilitarEmpresa = function (idEntidade) {
        var _this = this;
        var entidadeParaEditar = this.entidades.find(function (entidade) {
            return entidade.id === idEntidade;
        });
        this.entidadeEdit = __assign({}, entidadeParaEditar);
        console.log('---------------habilitarDesabilitarEmpresa>>>', this.entidadeEdit);
        if (this.entidadeEdit.desabilitadaMtrcc) {
            this.entidadeEdit.desabilitadaMtrcc = false;
        }
        else {
            this.entidadeEdit.desabilitadaMtrcc = true;
        }
        this.entidadeService.getEntidadeHabilitaDesabilita(this.entidadeEdit).subscribe(function (entidade) {
            _this.entidadeEdit = entidade;
            console.log('---------------habilitarDesabilitarEmpresa>>>', _this.entidadeEdit);
            var objIndex = _this.entidades.findIndex((function (obj) { return obj.id == _this.entidadeEdit.id; }));
            _this.entidades[objIndex].desabilitadaMtrcc = _this.entidadeEdit.desabilitadaMtrcc;
            console.log('---------------habilitarDesabilitarEmpresa11>>>', _this.entidades);
            // return this.entidades;
        }, function (error) {
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
            _this.expedienteUnicoVO = null;
            _this.spinnerService.ocultar();
        });
    };
    ListaEntidadesComponent.prototype.balanca = function (codigoBalanca) {
        this.router.navigate(['/gdr/balanca/', codigoBalanca], {
            relativeTo: this.route
        });
    };
    return ListaEntidadesComponent;
}());
export { ListaEntidadesComponent };
