import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as L from 'leaflet';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MapaService = /** @class */ (function () {
    function MapaService(http) {
        this.http = http;
        this.infosGeos = '/assets/data/infosGeo.geojson';
    }
    MapaService.prototype.geoEnderecoMtrcc = function () {
        var url = environment.apiUrl + '/enderecos/coordenadasGeoMtrcc/';
        // console.log('criarEndereco Service >>>>>>', url);
        return this.http.get(url);
    };
    MapaService.prototype.geoEnderecoGdr = function () {
        var url = environment.apiUrl + '/enderecos/coordenadasGeoGdr/';
        // console.log('criarEndereco Service >>>>>>', url);
        return this.http.get(url);
    };
    MapaService.prototype.geoInfos = function (map) {
        this.http.get(this.infosGeos).subscribe(function (res) {
            for (var _i = 0, _a = res.features; _i < _a.length; _i++) {
                var c = _a[_i];
                var lon = c.geometry.coordinates[0];
                var lat = c.geometry.coordinates[1];
                var marker = L.marker([lat, lon]);
                marker.addTo(map);
            }
        });
    };
    MapaService.ngInjectableDef = i0.defineInjectable({ factory: function MapaService_Factory() { return new MapaService(i0.inject(i1.HttpClient)); }, token: MapaService, providedIn: "root" });
    return MapaService;
}());
export { MapaService };
