import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Manifesto } from '../@shared/models/manifesto.model';

import { Entidade } from '../@shared/models/entidade.model';
import { KeyPair } from '../@shared/models/keyPair.model';
import { PessoaFisica } from '../@shared/models/pessoaFisica.model';
import { tap } from 'rxjs/operators';
import { ManifestoResultado } from '../@shared/models/manifestoTotalResultado.model';


@Injectable({ providedIn: 'root' })
export class ManifestoService {

  constructor(private http: HttpClient) { }

  getManifesto(id: string): Observable<Manifesto> {
    return this.http.get<Manifesto>(environment.apiUrl + '/manifestos/');
  }

  getManifestos(): Observable<Manifesto[]> {
    return this.http.get<Manifesto[]>(environment.apiUrl + '/manifestos/listall');
  }

  getManifestosDasEmpresas(
    pessoaFisica: PessoaFisica, situacaoPesquisa: String, dataInicialPesquisa: String, dataFinalPesquisa: String,
    obraPesquisa: String, produtoPesquisa: String, secretariaPesquisa: String,
    nomeObraPesquisa: String, codLogradouroPesquisa: String, numLogradouroPesquisa: String,
    etapaPesquisa: String, manifestoPesquisa: String
  ): Observable<Manifesto[]> {

    console.log('getManifestosDasEmpresas>>>>>>dataInicial>>>>>>', dataInicialPesquisa);

    return this.http.get<Manifesto[]>(environment.apiUrl + '/manifestos/lista-entidade/' + pessoaFisica.id
      + '?situacaoPesquisa=' + situacaoPesquisa + '&dataInicialPesquisa=' + dataInicialPesquisa
      + '&dataFinalPesquisa=' + dataFinalPesquisa + '&obraPesquisa=' + obraPesquisa
      + '&produtoPesquisa=' + produtoPesquisa + '&secretariaPesquisa=' + secretariaPesquisa
      + '&nomeObraPesquisa=' + nomeObraPesquisa + '&codLogradouroPesquisa=' + codLogradouroPesquisa
      + '&numLogradouroPesquisa=' + numLogradouroPesquisa + '&etapaPesquisa=' + etapaPesquisa
      + '&manifestoPesquisa=' + manifestoPesquisa);
  }

  getManifestosDaEmpresa(
    idEntidade: number, situacaoPesquisa: String, dataInicialPesquisa: String, dataFinalPesquisa: String,
    obraPesquisa: String, produtoPesquisa: String, secretariaPesquisa: String,
    nomeObraPesquisa: String, codLogradouroPesquisa: String, numLogradouroPesquisa: String,
    etapaPesquisa: String, manifestoPesquisa: String

  ): Observable<Manifesto[]> {

    console.log('getManifestosDaEmpresa>>>>>>dataInicial>>>>>>', dataInicialPesquisa);

    return this.http.get<Manifesto[]>(environment.apiUrl + '/manifestos/lista-entidade/' + idEntidade
      + '?situacaoPesquisa=' + situacaoPesquisa + '&dataInicialPesquisa=' + dataInicialPesquisa
      + '&dataFinalPesquisa=' + dataFinalPesquisa + '&obraPesquisa=' + obraPesquisa
      + '&produtoPesquisa=' + produtoPesquisa + '&secretariaPesquisa=' + secretariaPesquisa
      + '&nomeObraPesquisa=' + nomeObraPesquisa + '&codLogradouroPesquisa=' + codLogradouroPesquisa
      + '&numLogradouroPesquisa=' + numLogradouroPesquisa + '&etapaPesquisa=' + etapaPesquisa
      + '&manifestoPesquisa=' + manifestoPesquisa);

  }

  // getManifestosModelos(idPessoaFisica, idEntidade): Observable<Manifesto[]> {

  //       console.log('getManifestosModelos>>>>>>>>>>>>', idPessoaFisica, '---', idEntidade);

  //       return this.http.get<Manifesto[]>(environment.apiUrl + '/manifestos/manifestosModelos/' + idEntidade
  //       + idPessoaFisica);
  //   }

  getManifestosSmams(
    situacaoPesquisa: String, dataInicialPesquisa: String, dataFinalPesquisa: String,
    obraPesquisa: String, produtoPesquisa: String, secretariaPesquisa: String,
    nomeObraPesquisa: String, codLogradouroPesquisa: String, numLogradouroPesquisa: String,
    etapaPesquisa: String, manifestoPesquisa: String
  ): Observable<Manifesto[]> {

    console.log('getManifestosSmams>>>>>>>>>>', environment.apiUrl + '/manifestos/listaSmams/' + situacaoPesquisa);

    return this.http.get<Manifesto[]>(environment.apiUrl + '/manifestos/listaSmams?situacaoPesquisa=' + situacaoPesquisa
      + '&dataInicialPesquisa=' + dataInicialPesquisa + '&dataFinalPesquisa=' + dataFinalPesquisa + '&obraPesquisa='
      + obraPesquisa + '&produtoPesquisa=' + produtoPesquisa + '&secretariaPesquisa=' + secretariaPesquisa
      + '&nomeObraPesquisa=' + nomeObraPesquisa + '&codLogradouroPesquisa=' + codLogradouroPesquisa
      + '&numLogradouroPesquisa=' + numLogradouroPesquisa + '&etapaPesquisa=' + etapaPesquisa
      + '&manifestoPesquisa=' + manifestoPesquisa);

  }

  getManifestosResultados(idPessoaFisica: number, dataInicial: Date, dataFinal: Date): Observable<ManifestoResultado[]> {

    // console.log('getManifestosResultados>>>>>>>>>>>', dataInicial);

    return this.http.get<ManifestoResultado[]>(environment.apiUrl + '/manifestos/resultados/'
      + idPessoaFisica + '/' + dataInicial + '/' + dataFinal);
  }

  getManifestosResultadosCnpj(idEntidade: number, dataInicial: Date, dataFinal: Date): Observable<ManifestoResultado[]> {

    // console.log('getManifestosResultadosCnpj>>>>>>>>>>>', idEntidade);

    return this.http.get<ManifestoResultado[]>(environment.apiUrl + '/manifestos/resultadosCnpj/'
      + idEntidade + '/' + dataInicial + '/' + dataFinal);
  }

  getProdutosManifesto(idManifesto: number): Observable<Manifesto> {

    console.log('getProdutosManifesto>>>>>>>>>>>', idManifesto);

    return this.http.get<Manifesto>(environment.apiUrl + '/manifestos/produtoManifesto/' + idManifesto);
  }

  getProdutosManifestos(manifestosId: String[]): Observable<Manifesto[]> {
    console.log('getProdutosManifestos Service >>>>>>', manifestosId);
    return this.http.get<Manifesto[]>(environment.apiUrl + '/manifestos/produtoManifestoLista/' + manifestosId);
  }

  totalEntidadePeriodo(idEntidade: number): Observable<Manifesto> {
    return this.http.get<Manifesto>(environment.apiUrl + '/manifestos/totalEntidadePeriodo/' + idEntidade);
  }

  criarManifesto(manifesto: Manifesto): Observable<Manifesto> {

    console.log('>>>>>>>>>>>>>>>>>>>>>>>....................CriarManifesto Service >>>>>>', manifesto);
    // console.log('CriarManifesto Service >>>>>>', manifesto);
    return this.http.post<Manifesto>(environment.apiUrl + '/manifestos/', manifesto);
  }

  editarManifesto(manifesto: Manifesto): Observable<Manifesto> {
    return this.http.put<Manifesto>(environment.apiUrl + '/manifestos/', manifesto);
  }

  anularManifesto(manifesto: Manifesto): Observable<Manifesto> {
    return this.http.put<Manifesto>(environment.apiUrl + '/manifestos/anulacao', manifesto);
  }

  desfazManifestoModelo(manifesto: Manifesto): Observable<Manifesto> {
    return this.http.put<Manifesto>(environment.apiUrl + '/manifestos/desfazModelo', manifesto);
  }

  excluirManifesto(manifesto: Manifesto): Observable<Manifesto> {
    return this.http.delete<Manifesto>(environment.apiUrl + '/manifestos/' + manifesto.id);
  }

  // obtemObras(): Observable<KeyPair[]> {
  //   const url = environment.apiUrl + '/' + this.globals.tipoAcesso + '/app/obras';
  //   return this.http.get<KeyPair[]>(url);
  // }

  // obtemEtapas(): Observable<KeyPair[]> {
  //   const url = environment.apiUrl + '/' + this.globals.tipoAcesso + '/app/etapas';
  //   return this.http.get<KeyPair[]>(url);
  // }

  enviaEmailManifesto(id: number, pdfManifesto: any) {

    const rootUrl = environment.apiUrl + '/manifestos/' + id + '/emailManifesto';

    // const headers = new Headers({
    //   'Content-Type': 'application/json',
    //   'Accept': 'application/json'
    // });
    console.log('enviaEmailManifesto>>>>>>>', rootUrl);
    console.log('enviaEmailManifesto......>>>>>>>', pdfManifesto);
    return this.http.post(rootUrl, pdfManifesto, { reportProgress: true });

  }

  correcoesExtras() {

    const rootUrl = environment.apiUrl + '/manifestos/correcoesExtras';
    return this.http.get<String>(rootUrl);

  }

}
