import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { MenuItem, MessageService, SelectItem } from 'primeng/api';
import { Entidade } from 'src/app/@shared/models/entidade.model';
import { EntidadeService } from '../entidade/entidade.service';
import { SpinnerService } from '../services/spinner.service';
import { NotificacoesService } from '../services/notificacoes.service';
import { Globals } from 'src/app/globals';
import { ObraPmpa } from 'src/app/@shared/models/obraPmpa.model';
import { ObraPmpaService } from '../services/obraPmpa.service';
import { Endereco } from 'src/app/@shared/models/endereco';
import { LogradouroCdl } from 'procempa-cdl-lib';
import { Localizacao } from 'src/app/@shared/models/localizacao';
import { SmfPessoaService } from '../services/smfPessoa.service';
import { LicencaAmbiental } from 'src/app/@shared/models/licencaAmbiental.model';
import { LicencaAmbientalService } from '../services/licencaAmbiental.service';

@Component({
  templateUrl: './obraPmpa.component.html',
  styleUrls: ['./obraPmpa.component.scss'],
  providers: [MessageService]
})
export class ObraPmpaComponent implements OnInit {

  obrasPmpa: ObraPmpa[] = [];
  items: MenuItem[];
  nomeUsuario: string;
  entidadeExecutora: Entidade;
  entidadeNome = '';
  cnpjExecutora = '';
  // Novo/edit
  obraPmpaEdit: ObraPmpa;
  mostrarDialogEdit: boolean;
  tituloDialogEdit: string;
  isInsert: boolean;
  // Exluir
  obraPmpaExcluir: ObraPmpa;
  mostrarDialogExcluir: boolean;

  variaveisGlobais: Globals;
  activeRoute: string;

  // CDL
  endereco: Endereco;
  enderecoRes: Endereco = {};
  logradouroCdl: LogradouroCdl;
  localizacao: Localizacao;
  logradouro: string;
  numero: string;

  constructor(
    private router: Router,
    private keycloakService: KeycloakService,
    private obraPmpaService: ObraPmpaService,
    private entidadeService: EntidadeService,
    private spinnerService: SpinnerService,
    private notificacoesService: NotificacoesService,
    private smfPessoaService: SmfPessoaService,
    private globals: Globals
  ) {

    this.activeRoute = this.router.url;
    this.variaveisGlobais = globals;
  }

  ngOnInit() {

    if (this.variaveisGlobais.menuRaiz === null || this.variaveisGlobais.menuRaiz === '') {
      this.router.navigate(['/sgrIni']);
    } else {
      this.inicializaOnInit();
    }

  }

  inicializaOnInit() {
    this.spinnerService.exibir();
    this.obraPmpaEdit = new ObraPmpa();
    this.endereco = null;
    this.enderecoRes = null;
    this.logradouroCdl = null;
    this.localizacao = null;
    this.logradouro = null;
    this.numero = null;

    // this.obraPmpaService.getObrasPmpa().subscribe(obrasPmpa => {
    //   this.spinnerService.ocultar();
    //   this.obrasPmpa = obrasPmpa;
    //   console.log('inicializaOnInit>>>>>OBRAS>>>>>>>>>', this.obrasPmpa);
    // });
    this.buscaObras();
  }

  buscaObras() {

    this.obrasPmpa = [];
    this.obraPmpaService.getObrasPmpa().subscribe(obrasPmpa => {
      this.spinnerService.ocultar();
      this.obrasPmpa = obrasPmpa;
      console.log('inicializaOnInit>>>>>OBRAS>>>>>>>>>', this.obrasPmpa);
    });

  }

  logout() {
    const url = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
    this.keycloakService.getKeycloakInstance().logout({redirectUri: url});
  }

  mostrarDialogParaNovo() {
    this.obraPmpaEdit = new ObraPmpa();
    this.obraPmpaEdit.status = 'Ativa';
    this.mostrarDialogEdit = true;
    this.isInsert = true;

    this.tituloDialogEdit = 'Nova Obra';
  }

  mostrarDialogParaEditar(idObraPmpa: number) {
    const obraPmpaParaEditar = this.obrasPmpa.find(function(obraPmpa) {
      return obraPmpa.id === idObraPmpa;
    });
    this.obraPmpaEdit = {...obraPmpaParaEditar};
    console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.obraPmpaEdit);

    this.mostrarDialogEdit = true;
    this.isInsert = false;
    this.tituloDialogEdit = 'Editar Obra';

  }

  mostrarDialogEditParaExcluir(idProduto: number) {
    // this.produtoExcluir =  this.produtos.find(function(produto) {
    //   return produto.id === idProduto;
    // });
    // this.mostrarDialogExcluir = true;
  }

  salvar() {

    //Verifica se todos os campos foram preenchidos
    if (this.obraPmpaEdit.nome == null || this.obraPmpaEdit.nome === '') {
      this.notificacoesService.notificarAviso('Por favor informar o NOME da Obra!', null);
      return ;
    }
    if (this.obraPmpaEdit.status == null) {
      this.notificacoesService.notificarAviso('Por favor informar a STATUS da Obra!', null);
      return ;
    }
    // if (this.obraPmpaEdit.endereco == null || this.obraPmpaEdit.endereco === '') {
    //   this.notificacoesService.notificarAviso('Por favor informar o ENDEREÇO da Secretaria/Órgão!', null);
    //   return ;
    // }

    this.spinnerService.exibir();

    console.log('salvar....................>>>>>>>>>>>>>>', this.obraPmpaEdit);
    // this.obraPmpaEdit.entidadeDO = this.entidadePMPA;

    // Se novo
    if (this.obraPmpaEdit.id == null) {
      this.obraPmpaService.criarObraPmpa(this.obraPmpaEdit).subscribe(obraPmpaNovo => {
        console.log('salvado....................>>>>>>>>>>>>>>', obraPmpaNovo);
        this.obrasPmpa.push(obraPmpaNovo);

        this.mostrarDialogEdit = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso('Adicionado com sucesso', null);
        this.buscaObras();

      },
      error => {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro('Erro ao adicionar ', error);
      });
    } else { // Se editando
      this.obraPmpaService.editarObraPmpa(this.obraPmpaEdit).subscribe(obraPmpaEditado => {
        // Remove o valor atual
        this.obrasPmpa = this.obrasPmpa.filter(item => item.id !== this.obraPmpaEdit.id);

        // Adiciona o novo
        this.obrasPmpa.push(obraPmpaEditado);

        this.mostrarDialogEdit = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso('Atualizado com sucesso', null);
        this.buscaObras();

      },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });
    }

  }

  excluir() {
    // this.spinnerService.exibir();
    // console.log('salvar....................>>>>>>>>>>>>>>', this.variaveisGlobais.menuRaiz);

    // this.produtoService.excluirProduto(this.produtoExcluir, this.variaveisGlobais.menuRaiz).subscribe(produto => {
    //   if (produto == null) {
    //     // Remove o valor atual
    //     this.produtos = this.produtos.filter(item => item.id !== this.produtoExcluir.id);
    //   }
    //   this.mostrarDialogExcluir = false;
    //   this.spinnerService.ocultar();
    //   this.notificacoesService.notificarSucesso('Excluído com sucesso', null);
    // },
    // error => {
    //   console.log(error);
    //   this.spinnerService.ocultar();
    //   this.notificacoesService.notificarErro('Erro ao exluir', error.error);
    // });
  }


  checkActive(route: string) {
    return route === this.activeRoute;
  }

  selecionaCDL(endereco) {

    this.localizacao = {latitude:null, longitude:null};

    if (endereco.latitude !== undefined && endereco.latitude !== null) {
      this.localizacao = {
        latitude: endereco.latitude,
        longitude: endereco.longitude
      };
      delete endereco['latitude'];
      delete endereco['longitude'];
    }

    this.obraPmpaEdit.endereco = endereco;
    this.obraPmpaEdit.endereco.localizacao = this.localizacao;

  }


  buscaEntidade(obraPmpa) {
      console.log('BuscaEntidade0000>>>>>>>', obraPmpa.cnpjExecutora);
    let cnpj = obraPmpa.cnpjExecutora;
    if (cnpj === '__.___.___/____-__') {
//      console.log('BuscaEntidade0000>>>>>>>', cnpj);
      cnpj = null;
    }
    if (cnpj == null || cnpj === '') {
      // this.spinnerService.ocultar();
      // this.notificacoesService.notificarErro('Não encontrou entidade para CNPJ informado!', null);
    } else {
      // this.spinnerService.exibir();
      // const novoCnpj = cnpj.replace(/[^\d]+/g, '');
      // console.log('BuscaEntidade00111111111>>>>>>>', novoCnpj);
      // this.smfPessoaService.getPessoaFazenda(novoCnpj, '2').subscribe(pessoa => {
      //   console.log('BuscaEntidade1111>>>>>>>', pessoa);
      //   this.spinnerService.ocultar();
      // });

      this.obraPmpaEdit.executora = null;
      this.entidadeService.getEntidadeCnpj(cnpj).subscribe(entidade => {

        if (entidade == null) {
            this.spinnerService.ocultar();
            this.notificacoesService.notificarErro('Não localizada empresa para o CNPJ informado no Sistema SGR.' +
            ' Empresa existe no cadastro da Prefeitura Municipal de Porto Alegre – PMPA.' +
            ' Favor solicitar o cadastramento no SGR.', null);
        } else {
          this.obraPmpaEdit.executora = entidade;
          this.entidadeExecutora = entidade;
          console.log('BuscaEntidade2222>>>>>>>', entidade);
        }

      },
      error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });
    }
  }
}
