import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EntidadeService = /** @class */ (function () {
    function EntidadeService(http) {
        this.http = http;
    }
    EntidadeService.prototype.autoCadastro = function (info) {
        console.log('autoCadastro>>>>>>>>>>>>>>>>>>', info);
        var url = environment.apiUrl + '/pessoaFisica/autoCad';
        console.log('autoCadastro url>>>>>>>>>>>>>>>>>>', url);
        var ret = this.http.post(url, info);
        console.log('autoCadastro Retorno>>>>>>>>>>>>>>>>>>', ret);
        return ret;
    };
    EntidadeService.prototype.getEntidades = function () {
        console.log('getEntidades url>>>>>>>>>>>>>>>>>>', environment.apiUrl + '/entidades/');
        return this.http.get(environment.apiUrl + '/entidades/');
    };
    EntidadeService.prototype.getEntidadeRepresentada = function () {
        var url = environment.apiUrl + '/entidades/representada';
        console.log('----------EntidadeService getEntidadeRepresentada>>>>', url);
        return this.http.get(url);
    };
    EntidadeService.prototype.getEntidade = function (id) {
        return this.http.get(environment.apiUrl + '/entidades/');
    };
    EntidadeService.prototype.getEntidadePorId = function (id) {
        return this.http.get(environment.apiUrl + '/entidades/' + id + '/entidadeId');
    };
    EntidadeService.prototype.getSubEntidades = function (id) {
        var url = environment.apiUrl + '/entidades/' + id + '/subentidades';
        return this.http.get(url);
    };
    EntidadeService.prototype.getEntidadePorUserName = function (userName) {
        return this.http.get(environment.apiUrl + '/entidades/username/' + userName);
    };
    EntidadeService.prototype.getEntidadeCnpj = function (cnpj) {
        var cnpjSemFormatacao = cnpj.replace('/', '.');
        cnpjSemFormatacao = cnpjSemFormatacao.replace('-', '.');
        var url = environment.apiUrl + '/entidades/cnpj/' + cnpjSemFormatacao;
        console.log('----------EntidadeService getEntidadeCnpj>>>>', url);
        return this.http.get(url);
    };
    EntidadeService.prototype.getEntidadePorCodigoDmlu = function (codigoDmlu) {
        return this.http.get(environment.apiUrl + '/entidades/' + codigoDmlu + '/codigoDMLU');
    };
    EntidadeService.prototype.getPessoaFisica = function (idEntidade) {
        var url = environment.apiUrl + '/entidades/' + idEntidade + '/pessoaFisica';
        return this.http.get(url);
    };
    EntidadeService.prototype.getPessoaFisicaPorId = function (id) {
        var url = environment.apiUrl + '/entidades/' + id + '/pessoaFisicaId';
        return this.http.get(url);
    };
    EntidadeService.prototype.getPessoaFisicaPorUserName = function (userName) {
        var url = environment.apiUrl + '/entidades/' + userName + '/pessoaFisicaUserName';
        return this.http.get(url);
    };
    EntidadeService.prototype.criarEntidade = function (entidade) {
        return this.http.post(environment.apiUrl + '/entidades/', entidade);
    };
    EntidadeService.prototype.editarEntidade = function (entidade) {
        var url = environment.apiUrl + '/entidades/';
        console.log('----------EntidadeService editarEntidade>>>>', url);
        return this.http.put(url, entidade);
    };
    EntidadeService.prototype.excluirEntidade = function (entidade) {
        return this.http.delete(environment.apiUrl + '/entidades/' + entidade.id);
    };
    EntidadeService.prototype.getEntidadesMtrcc = function () {
        console.log('getEntidadesMtrcc>>>>>>>>>>', environment.apiUrl + '/entidades/listaEntidadesMtrcc');
        return this.http.get(environment.apiUrl + '/entidades/listaEntidadesMtrcc');
    };
    EntidadeService.prototype.getEntidadesGeradoras = function () {
        console.log('getEntidadesGeradoras>>>>>>>>>>', environment.apiUrl + '/entidades/listaEntidadesGeradoras');
        return this.http.get(environment.apiUrl + '/entidades/listaEntidadesGeradoras');
    };
    EntidadeService.prototype.getEntidadesGdr = function (situacao) {
        // console.log('getEntidadesGeradoras>>>>>>>>>>', environment.apiUrl + '/entidades/listaEntidadesGdr');
        return this.http.get(environment.apiUrl + '/entidades/listaEntidadesGdr/' + situacao);
    };
    EntidadeService.prototype.getEntidadesVinculadas = function (id) {
        console.log('getEntidadesVinculadas>>>>>>>>>>', environment.apiUrl + '/entidades/listaEntidadesVinculadas/' + id);
        return this.http.get(environment.apiUrl + '/entidades/listaEntidadesVinculadas/' + id);
    };
    EntidadeService.prototype.getEntidadesPesquisa = function (opcoesPesquisa) {
        return this.http.get(environment.apiUrl + '/entidades/listaEntidades/' + opcoesPesquisa);
    };
    EntidadeService.prototype.getEntidadesPesquisaGdr = function (opcoesPesquisa) {
        return this.http.get(environment.apiUrl + '/entidades/listaEntidadesPesqGdr/' + opcoesPesquisa);
    };
    EntidadeService.prototype.getEntidadesDaPessoaFisica = function (idPessoaFisica) {
        console.log('getEntidadesDaPessoaFisica>>>>>>>>>>', environment.apiUrl + '/entidades/pessoaFisica/' + idPessoaFisica);
        return this.http.get(environment.apiUrl + '/entidades/pessoaFisica/' + idPessoaFisica);
    };
    EntidadeService.prototype.getPessoaJuridica = function (cnpj) {
        console.log('....................EntidadeService getPessoaJuridica>>>>', environment.apiUrl + '/entidades/fazenda/' + cnpj);
        return this.http.get(environment.apiUrl + '/entidades/fazenda/' + cnpj);
    };
    EntidadeService.prototype.atualizaEmpresaFazenda = function () {
        console.log('....................atualizaEmpresaFazenda>>>>', environment.apiUrl + '/entidades/atualizaFazenda');
        return this.http.get(environment.apiUrl + '/entidades/atualizaFazenda');
    };
    EntidadeService.prototype.correcoesExtras = function () {
        var rootUrl = environment.apiUrl + '/entidades/correcoesExtras';
        return this.http.get(rootUrl);
    };
    EntidadeService.prototype.getEntidadeHabilitaDesabilita = function (entidade) {
        var url = environment.apiUrl + '/entidades/habilitarDesabilitar';
        console.log('----------EntidadeService getEntidadeHabilitaDesabilita>>>>', url);
        return this.http.put(url, entidade);
    };
    EntidadeService.ngInjectableDef = i0.defineInjectable({ factory: function EntidadeService_Factory() { return new EntidadeService(i0.inject(i1.HttpClient)); }, token: EntidadeService, providedIn: "root" });
    return EntidadeService;
}());
export { EntidadeService };
