import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EnderecoEntidade } from '../../@shared/models/enderecoEntidade';
import { Manifesto } from 'src/app/@shared/models/manifesto.model';
import { LicencasEUsVO } from 'src/app/@shared/models/licencasEUsVO.model';
import { ExpedienteUnicoVO } from 'src/app/@shared/models/expedienteUnicoVO.model';

@Injectable({ providedIn: 'root' })
export class EnderecoService {

  constructor(private http: HttpClient) { }

  getEndereco(id: number): Observable<EnderecoEntidade> {
    const url = environment.apiUrl + '/enderecos/byId/' + id;
    console.log('criarEndereco Service >>>>>>', url);
    return this.http.get<EnderecoEntidade>(url);
  }

  getEnderecosPorEntidade(idEntidade: number): Observable<EnderecoEntidade[]> {
    const url = environment.apiUrl + '/enderecos/listaPorEntidade/' + idEntidade;

    // console.log('criarEndereco Service >>>>>>', url);
    return this.http.get<EnderecoEntidade[]>(url);
  }

  criarEndereco(endereco: EnderecoEntidade): Observable<EnderecoEntidade> {
    const url = environment.apiUrl + '/enderecos/';
    console.log('criarEndereco Service ......>>>>>>', endereco);
    console.log('criarEndereco Service ......>>>>>>', url);

    return this.http.post<EnderecoEntidade>(url, endereco);
  }

  atualizarEndereco(endereco: EnderecoEntidade): Observable<EnderecoEntidade> {

    return this.http.put<EnderecoEntidade>(environment.apiUrl + '/enderecos/', endereco);
  }

  editarEndereco(endereco: EnderecoEntidade): Observable<EnderecoEntidade> {
    return this.http.put<EnderecoEntidade>(environment.apiUrl + '/enderecos/', endereco);
  }

  deletaEndereco(idEndereco) {

    const url = environment.apiUrl + '/enderecos/deletar/' + idEndereco;
    console.log('Endereço Service - DELETA>>>>>>>', url);

    return this.http
      .delete(url, idEndereco);

  }

  getLicencasEusVO(idManifesto: number): Observable<LicencasEUsVO[]> {
    const url = environment.apiUrl + '/enderecos/licencaseusPorManifesto/' + idManifesto;

    // console.log('criarEndereco Service >>>>>>', url);
    return this.http.get<LicencasEUsVO[]>(url);
  }

  getEnderecoContrato(idContrato: number): Observable<EnderecoEntidade> {
    const url = environment.apiUrl + '/enderecos/contratoDmlu/' + idContrato;
    console.log('getEnderecoContrato Service >>>>>>', url);
    return this.http.get<EnderecoEntidade>(url);
  }

  getEnderecoCodigoNumero(codLogradouro: string, numero: string): Observable<ExpedienteUnicoVO> {
    const url = environment.apiUrl + '/enderecos/codigoNumero/' + codLogradouro + '/' + numero;
    console.log('getEnderecoContrato Service >>>>>>', url);
    return this.http.get<ExpedienteUnicoVO>(url);
  }

  getEnderecoCodigoNumeroListaEU(codLogradouro: string, numero: string): Observable<ExpedienteUnicoVO[]> {
    const url = environment.apiUrl + '/enderecos/codigoNumeroListaEU/' + codLogradouro + '/' + numero;
    console.log('getEnderecoContrato Service >>>>>>', url);
    return this.http.get<ExpedienteUnicoVO[]>(url);
  }

  // cepCorreios() {
  //   const url = environment.apiCorreio + '/90820001';

  //   console.log('cepCorreios Service >>>>>>', url);
  //   return this.http.get<any>(url);
  // }

  // getDestinosFinais(): Observable<EnderecoEntidade[]> {
  //   return this.http.get<EnderecoEntidade[]>(environment.apiUrl + '/destinoFinal/listaTodos/');
  // }

  // getDestinoFinal(id: string): Observable<EnderecoEntidade> {
  //   return this.http.get<EnderecoEntidade>(environment.apiUrl + '/destinoFinal/id/' + id);
  // }

  // getDestinoFinalNome(nome: string): Observable<EnderecoEntidade> {
  //   return this.http.get<EnderecoEntidade>(environment.apiUrl + '/destinoFinal/nome/' + nome);
  // }

  // criarDestinoFinal(destinoFinal: EnderecoEntidade): Observable<EnderecoEntidade> {
  //   return this.http.post<EnderecoEntidade>(environment.apiUrl + '/destinoFinal/', destinoFinal);
  // }

  // editarDestinoFinal(destinoFinal: EnderecoEntidade): Observable<EnderecoEntidade> {
  //   return this.http.put<EnderecoEntidade>(environment.apiUrl + '/destinoFinal/', destinoFinal);
  // }

  // excluirDestinoFinal(destinoFinal: EnderecoEntidade): Observable<EnderecoEntidade> {
  //   return this.http.delete<EnderecoEntidade>(environment.apiUrl + '/destinoFinal/deletar/' + destinoFinal.id);
  // }

}
