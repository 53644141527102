import { NgModule } from '@angular/core';

import { ReduzObservacaoPipe } from './reduzObservacao.pipe';
import { ReduzNomeImagemPipe } from './reduzNomeImagem.pipe';
import { MilharPipe } from './milhar.pipe';


@NgModule({
  exports: [
    ReduzObservacaoPipe,
    ReduzNomeImagemPipe,
    MilharPipe

  ],
  declarations: [
    ReduzObservacaoPipe,
    ReduzNomeImagemPipe,
    MilharPipe

  ]
})
export class SGRPipesModule { }
