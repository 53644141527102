import { Endereco } from './endereco';

export class SecretariaPmpa {
  id: number;
  secretaria: string;
  sigla: string;
  // orgao: string;
  observacao: string;
  timestampAsString: string;
  dataInclusao: string;
  usuarioInclusao: string;
  usuarioAlteracao: string;
  ativo: boolean;
  endereco: Endereco;
}


export interface SecretariaPmpaInter {
  id: number;
  secretaria: string;
  sigla: string;
  // orgao: string;
  observacao: string;
  timestampAsString: string;
  dataInclusao: string;
  usuarioInclusao: string;
  usuarioAlteracao: string;
  ativo: boolean;
  endereco: Endereco;
}
