
<sgr-menu></sgr-menu>
<div style="width: fit-content; margin: auto;">



    <!--
    <h2 style="font-family: 'Roboto', 'Trebuchet MS', Arial, Helvetica, sans-serif">{{tituloTela}}</h2>
-->
    <h2 class="text-primary mt-4 mb-4">{{tituloTela}}</h2>
    <!-- <p-card>
        {{tituloCard}}
    </p-card> -->


    <p-tabView [style]="{width:'1250px'}">

    <!-- <p-tabPanel header="Resíduos Gerados"> -->
      <p-table #tabelaPlanos [value]="planosMeta" [paginator]="true" [rows]="12" [globalFilterFields]="['dataInclusao','produtoDO.nome','entidadeDO.razaoSocial']"
        [style]="{width:'1250px'}">

        <ng-template pTemplate="caption" *ngIf="variaveisGlobais.roleEntidade">
            <div class="ui-g ui-fluid" *ngIf="adicionarResiduosOkAnexos !== null && adicionarResiduosOkRTecnicos !== null">
                <!-- <div class="ui-g-12" *ngIf="adicionarResiduosOkEntidade && adicionarResiduosOkPlano">
                    <div class="ui-g-3" *ngIf="isEntidade()">
                        <button type="button" pButton icon="fa fa-plus" class="ui-button-secondary" (click)="mostrarDialogParaNovo()" label="Adicionar informações"></button> -->

                <div class="ui-g-12" *ngIf="adicionarResiduosOkAnexos && adicionarResiduosOkRTecnicos">
                    <div class="ui-g-2" *ngIf="isEntidade()">
                        <button type="button" class="btn btn-primary my-4" (click)="mostrarDialogParaNovo()"
                            *ngIf="isEntidade()"><i class="fa fa-plus pr-4"></i>Adicionar</button>
                    </div>
                    <div class="ui-g-3" *ngIf="isEntidade()">
                      <span class="ui-inputgroup-addon" style="font-size: 13px; vertical-align: baseline">Referência&nbsp;&nbsp;&nbsp;</span>
                      <input pInputText [(ngModel)]="pesquisaReferencia" style="width: 80px;" (keyup.enter)="pesquisarReferencia()"/>
                    </div>
                    <div class="ui-g-5" style="text-align: right;">
                        <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                        <input type="text" pInputText size="40" placeholder="Filtrar resíduos" (input)="tabelaPlanos.filterGlobal($event.target.value, 'contains')"
                            style="width:auto">
                    </div>
                    <div class="ui-g-2">
                        Quantidade:&nbsp;&nbsp;&nbsp;<font color="#68a38a" size="6px"><b>{{planos?.length}}</b></font>
                    </div>
                </div>
                <div class="ui-g-12" *ngIf="!adicionarResiduosOkAnexos || !adicionarResiduosOkRTecnicos">
                  <p style="text-align: left;">
                    <font size="3" color="red">
                      Para habilitar o botão "Adicionar Resíduos" favor incluir:
                      <ul *ngIf="!adicionarResiduosOkRTecnicos">
                        ----> Na aba Dados da Empresa - selecionar Empresa no Menu superior e clicar em Detalhes
                        <li *ngFor="let mensPlano of mensagemRespTecnicos">
                          {{mensPlano}}
                        </li>
                      </ul>
                      <ul *ngIf="!adicionarResiduosOkAnexos">
                        ----> Na aba de Anexos - selecionar Empresa no Menu superior e clicar em Detalhes
                        <li *ngFor="let mensEnt of mensagemAnexosEntidade">
                          {{mensEnt}}
                        </li>
                      </ul>
                    </font>
                  </p>
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="caption" *ngIf="!variaveisGlobais.roleEntidade">
          <div class="ui-g ui-fluid">
              <div class="ui-g-12">
                <div class="ui-g-3" *ngIf="isEntidade()">
                  <span class="ui-inputgroup-addon" style="font-size: 13px; vertical-align: baseline">Situação</span>
                  <input pInputText [(ngModel)]="pesquisaReferencia" style="width: 230px;" (blur)="pesquisarReferencia()"/>
                </div>
                <div class="ui-g-9" style="text-align: right;">
                    <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                    <input type="text" pInputText size="80" placeholder="Filtrar resíduos" (input)="tabelaPlanos.filterGlobal($event.target.value, 'contains')"
                        style="width:auto">
                </div>
              </div>
          </div>
      </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="produtoDO.nome" style="width: 300px; height: 60px;" *ngIf="variaveisGlobais.menuRaiz=='grandeGerador'">
                    Resíduos
                    <p-sortIcon field="produtoDO.nome"></p-sortIcon>
                </th>
                <th pSortableColumn="produtoDO.nome" style="width: 150px; height: 60px;" class="pl-3 text-center" *ngIf="variaveisGlobais.menuRaiz=='mtrcc'">
                    Produto pós-consumo
                    <p-sortIcon field="produtoDO.nome"></p-sortIcon>
                </th>
                <th pSortableColumn="entidadeDO.razaoSocial" style="width: 125px" class="pl-3 text-center">
                    Empresa  <p-sortIcon field="entidadeDO.razaoSocial"></p-sortIcon>



                <!-- <th pSortableColumn="produtoDO.nome" style="width: 150px; height: 60px;" class="pl-3 text-center">
                    Produto pós-consumo
                    <p-sortIcon field="produtoDO.nome"></p-sortIcon>
                </th>
                <th pSortableColumn="entidadeDO.razaoSocial" style="width: 125px" class="pl-3 text-center">
                    Entidade/Empresa
                    <p-sortIcon field="entidadeDO.razaoSocial"></p-sortIcon> -->


                </th>
                <!-- <th pSortableColumn="situacao" style="width: 60px">
                    Situação
                    <p-sortIcon field="situacao"></p-sortIcon>
                </th> -->
                <th style="width: 110px;">Referência</th>
                <th *ngIf="variaveisGlobais.menuRaiz=='grandeGerador'" style="width: 200px;">Motivo Anulação</th>
              <!-- <th pSortableColumn="dataEnvio" style="width: 60px">
                    Envio
                    <p-sortIcon field="dataEnvio"></p-sortIcon>
                </th>
                <th pSortableColumn="dataAceite" style="width: 60px">
                    Aceite
                    <p-sortIcon field="dataAceite"></p-sortIcon>
                </th>
                <th pSortableColumn="dataDevolucao" style="width: 60px">
                    Devolução
                    <p-sortIcon field="dataDevolucao"></p-sortIcon>
                </th> -->

                <th style="width: 250px;" class="pl-3 text-center">Ações</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-plano>
            <tr>
                <td>
                    <!--
                    <a href="#" (click)="mostrarDialogParaEditar(plano.id)">
                        <u>{{plano.produtoDO.nome}}</u>
                    </a>
                    <button pButton title="Detalhes" (click)="mostrarDialogParaEditar(plano.id)" label="{{plano.produtoDO.nome}}" class="ui-button-secondary edit-button"
                    style="text-align: left"></button>
                -->
                    {{plano.residuo}}
                </td>
                <td>{{plano.nomeEmpresa}}</td>
                <!-- <td>
                    {{plano.situacao}}
                    <i class="fa fa-edit" *ngIf="plano.situacao=='Edicao'"></i>
                    <i class="fa fa-eye" *ngIf="plano.situacao=='Revisao'"></i>
                    <i class="fa fa-check" *ngIf="plano.situacao=='Aceito'"></i>
                </td> -->
                <td>{{plano.referencia}}</td>
                <td>{{plano.justificativa}}</td>
                <!-- <td>{{plano.dataEnvio | date:'dd/MM/yyyy'}}</td>
                <td>{{plano.dataAceite | date:'dd/MM/yyyy'}}</td>
                <td>{{plano.dataDevolucao | date:'dd/MM/yyyy'}}</td> -->
                <td>
                    <button title="Detalhes" (click)="mostrarDialogParaEditar(plano.id)" class="btn btn-outline-secondary  mx-2 pl-2">
                      <i class="fa fa-info pr-2"></i>Detalhes</button>
                    <span *ngIf="isEntidade()">
                    <button title="Anular" (click)="mostrarDialogEditParaAnulacao(plano.id)" class="btn btn-outline-primary pl-2"
                    *ngIf="plano.dataAnulacao == null && variaveisGlobais.roleEntidade"><i class="fa fa-archive pr-2"></i>Anular</button>
                    <span *ngIf="plano.dataAnulacao != null"><font color="grey">&nbsp;&nbsp;&nbsp;Anulado</font></span>

                    <!-- <button pButton title="Excluir" (click)="mostrarDialogEditParaExcluir(plano.id)" label="Excluir" icon="pi pi-trash"
                        class="ui-button-secondary botao-acao"></button> -->
                    <!-- <button pButton title="Excluir" (click)="mostrarDialogEditParaExcluir(plano.id)" label="Excluir" icon="pi pi-trash" class="ui-button-secondary botao-acao"
                            [disabled]="plano.situacao!='Edicao'"></button> -->

                        <!-- <button pButton title="Enviar" (click)="enviarPlano(plano.id)" label="Enviar" icon="fa fa-paper-plane" class="ui-button-secondary botao-acao"
                            [disabled]="plano.situacao!='Edicao'"></button> -->

                    </span>
                    <!-- <span *ngIf="isSMAMS()">
                        <button pButton title="Aceitar" (click)="aceitarPlano(plano.id)" label="Aceitar" icon="fa fa-check" class="ui-button-secondary botao-acao"
                            [disabled]="plano.situacao!='Revisao'"></button>

                        <button pButton title="Devolver" (click)="devolverPlano(plano.id)" icon="fa fa-reply" label="Devolver" class="ui-button-secondary botao-acao"
                            [disabled]="plano.situacao!='Revisao'"></button>
                    </span> -->
                </td>
            </tr>
        </ng-template>
      </p-table>
    <!-- </p-tabPanel> -->
    </p-tabView>

  </div>






<form [formGroup]="residuoForm" (ngSubmit)="onSubmit()">
  <p-dialog [header]="tituloDialogEdit" [(visible)]="mostrarDialogEdit" [responsive]="true" showEffect="fade" [modal]="true" [width]="1100" [height]="900"
      showEffect="explode" hideEffect="bounce" *ngIf="mostrarDialogEdit" class="scroller ontop" modal="true"
      [style]="{'position':'top'}" resizable="true">

      <p-tabView [activeIndex]="index" (onChange)="changeTable($event)">

        <!-- ABA RESÍDUOS -->
        <p-tabPanel header="Resíduos">

        <div class="p-grid">
          <div class="p-col-12" align="right">
            <button type="button" (click)="alternarAbas(1)" class="btn btn-outline-secondary">Próximo<i class="pi pi-angle-double-right"></i></button>
          </div>
        </div>


        <div *ngIf='!semPlanoResiduos'>
          <div class="p-grid">
              <div class="p-col-12">
                <div class="p-grid">
                  <div class="p-col">
                    <label>Planos de Resíduos</label>
                  </div>
                </div>
                <div class="p-grid" *ngIf="insertMode">
                  <div class="p-col-6">
                      <p-table [value]="anexosPlanoResiduo">
                          <ng-template pTemplate="body" let-anexoPlano>
                            <tr>
                              <td class="p-col-2 pr-2" style="width: 0px;">
                                <p-radioButton name="anexoPlanoSel" value="{{anexoPlano.id}}" label="" formControlName="anexoPlanoSel" (onClick)="anexoPlanoSelecionado(anexoPlano)"></p-radioButton>
                              </td>
                              <td style="width: 60px; padding-left: 32px;">
                                  <a data-toggle="tooltip" data-placement="left" title="{{ anexoPlano?.fileName }}" class="link-anexo" style="cursor:pointer"
                                  (click)="visualizaAnexo(anexoPlano,'planoResiduo');">{{anexoPlano?.fileName | reduzNomeImagem}}</a>
                              </td>
                            </tr>
                          </ng-template>
                      </p-table>
                  </div>
                </div>
                <div class="p-grid" *ngIf="!insertMode">
                  <div class="p-col">
                      <a data-toggle="tooltip" data-placement="left" title="{{ anexoPlano?.fileName }}" class="link-anexo" style="cursor:pointer" *ngIf='anexoPlano != null'
                      (click)="visualizaAnexo(anexoPlano,'planoResiduo');">{{anexoPlano?.fileName | reduzNomeImagem}}</a>
                    <!-- <input pInputText formControlName="anexoPlanoSel" style="width: 230px;"/> -->
                  </div>
                </div>
              </div>
          </div>
          <div class="p-grid">
            <div class="p-col">
              <div class="p-grid">
                <div class="p-col">
                    <label>Responsáveis Técnicos pela Elaboração</label>
                </div>
              </div>
              <div class="p-grid" *ngIf="insertMode">
                <div class="p-col">
                    <p-table [value]="rtsElaboracaoEmpresa">
                        <ng-template pTemplate="body" let-rtElaboracao>
                          <tr>
                            <td style="width: 0px;">
                              <p-radioButton name="rtElaboracaoSelPessoa" value="{{rtElaboracao.id}}" label="" formControlName="rtElaboracaoSelPessoa" (onClick)="rtElaboracaoSelecionado(rtElaboracao)"></p-radioButton>
                            </td>
                            <td style="width: 40px; padding-left: 38px;">
                                {{rtElaboracao.nomeCompleto}}
                            </td>
                          </tr>
                        </ng-template>
                    </p-table>
                </div>
              </div>
              <div class="p-grid" *ngIf="!insertMode">
                  <div class="p-col">
                    <input pInputText formControlName="rtElaboracaoSelPessoa" style="width: 230px;" disabled/>
                  </div>
              </div>
            </div>
            <div class="p-col-6">
                <div class="p-grid">
                  <div class="p-col">
                      <label>Anexos - RT de Elaboração</label>
                  </div>
                </div>
                <div class="p-grid" *ngIf="insertMode">
                  <div class="p-col">
                      <p-table [value]="anexosRTElaboracao">
                          <ng-template pTemplate="body" let-anexoRTElaboracao>
                            <tr>
                              <td style="width: 0px;">
                                <p-radioButton name="rtElaboracaoSelAnexo" value="{{anexoRTElaboracao.id}}" label="" formControlName="rtElaboracaoSelAnexo" (onClick)="rtElaboracaoSelecionado(anexoRTElaboracao)"></p-radioButton>
                              </td>
                              <td style="width: 40px; padding-left: 32px;">
                                  <a data-toggle="tooltip" data-placement="left" title="{{ anexoRTElaboracao?.fileName }}" class="link-anexo" style="cursor:pointer"
                                  (click)="visualizaAnexo(anexoRTElaboracao,'rtElaboracao');">{{anexoRTElaboracao?.fileName | reduzNomeImagem}}</a>
                              </td>
                            </tr>
                          </ng-template>
                      </p-table>
                  </div>
                </div>
                <div class="p-grid" *ngIf="!insertMode">
                    <div class="p-col">
                        <a data-toggle="tooltip" data-placement="left" title="{{ anexoRTElaboracao?.fileName }}" class="link-anexo" style="cursor:pointer" *ngIf='anexoPlano != null'
                        (click)="visualizaAnexo(anexoRTElaboracao,'rtElaboracao');">{{anexoRTElaboracao?.fileName | reduzNomeImagem}}</a>
                    </div>
                </div>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-6">
              <div class="p-grid">
                <div class="p-col">
                    <label>Responsáveis Técnicos pela Execução</label>
                </div>
              </div>
              <div class="p-grid" *ngIf="insertMode">
                <div class="p-col">
                    <p-table [value]="rtsExecucaoEmpresa">
                        <ng-template pTemplate="body" let-rtExecucao>
                          <tr>
                            <td style="width: 0px;">
                              <p-radioButton name="rtExecucaoSelPessoa" value="{{rtExecucao.id}}" label="" formControlName="rtExecucaoSelPessoa" (onClick)="rtExecucaoSelecionadoPessoa(rtExecucao)"></p-radioButton>
                            </td>
                            <td style="width: 80px; padding-left: 38px;">
                                {{rtExecucao.nomeCompleto}}
                            </td>
                          </tr>
                        </ng-template>
                    </p-table>
                  </div>
              </div>
              <div class="p-grid" *ngIf="!insertMode">
                  <div class="p-col">
                    <input pInputText formControlName="rtExecucaoSelPessoa" style="width: 230px;" disabled/>
                  </div>
              </div>
            </div>
            <div class="p-col-6">
                <div class="p-grid">
                  <div class="p-col">
                      <label>Anexos - RT de Execução</label>
                  </div>
                </div>
                <div class="p-grid" *ngIf="insertMode">
                  <div class="p-col">
                      <p-table [value]="anexosRTExecucao">
                          <ng-template pTemplate="body" let-anexoRTExecucao>
                            <tr>
                              <td style="width: 0px;">
                                <p-radioButton name="rtExecucaoSelAnexo" value="{{anexoRTExecucao.id}}" label="" formControlName="rtExecucaoSelAnexo" (onClick)="rtExecucaoSelecionadoAnexo(anexoRTExecucao)"></p-radioButton>
                              </td>
                              <td style="width: 40px;  padding-left: 32px;">
                                  <a data-toggle="tooltip" data-placement="left" title="{{ anexoRTExecucao?.fileName }}" class="link-anexo" style="cursor:pointer"
                                  (click)="visualizaAnexo(anexoRTExecucao,'rtExecucao');">{{anexoRTExecucao?.fileName | reduzNomeImagem}}</a>
                              </td>
                            </tr>
                          </ng-template>
                      </p-table>
                  </div>
                </div>
                <div class="p-grid" *ngIf="!insertMode">
                    <div class="p-col">
                        <a data-toggle="tooltip" data-placement="left" title="{{ anexoRTExecucao?.fileName }}" class="link-anexo" style="cursor:pointer" *ngIf='anexoPlano != undefined && anexoPlano != null'
                        (click)="visualizaAnexo(anexoRTExecucao,'rtExecucao');">{{anexoRTExecucao?.fileName | reduzNomeImagem}}</a>
                      </div>
                </div>

            </div>


          </div>
        </div>
<br>
          <div class="p-grid">
            <div class="p-col-6">
              <div class="p-grid">
                <div class="p-col">
                  <label>Tipo de resíduo</label>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col">
                  <p-dropdown [options]="prods" formControlName="produto" placeholder="Selecione" scrollHeight="250px">
                  <ng-template let-prod pTemplate="selectedItem">
                    <div pTooltip="{{prod.value}}" >{{prod.label}}</div>
                    </ng-template>
                  <ng-template let-prod pTemplate="item">
                    <div pTooltip="{{prod.value}}" >{{prod.label}}</div>
                    </ng-template>

                  </p-dropdown>
                </div>
              </div>
            </div>
            <div class="p-col-6">
              <div class="p-grid">
                <div class="p-col">
                  <label>Quantidade ANUAL prevista</label>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col">
                  <input class="mr-2" pInputText formControlName="quantidadePrevista" style="width: 130px;" (keypress)="numberOnly($event)"/>
                  <p-radioButton class="pl-2" name="unidadeQuantidadePrev" value="m³" label="metros cúbicos (m³)" formControlName="unidadeQuantidadePrev"></p-radioButton>&nbsp;&nbsp;&nbsp;
                  <p-radioButton name="unidadeQuantidadePrev" value="t" label="toneladas (t)" formControlName="unidadeQuantidadePrev"></p-radioButton>
                </div>
              </div>
              <div class="p-col">

              </div>
           </div>
          </div>
          <!-- <div class="p-grid">
            <div class="p-col-6">
                <div class="p-grid">
                  <div class="p-col">
                    <label>Quantidade ANUAL prevista</label>
                  </div>
                </div>
                <div class="p-grid">
                  <div class="p-col">
                    <input class="mr-2" pInputText formControlName="quantidadePrevista" style="width: 230px;" (keypress)="numberOnly($event)"/>
                    <p-radioButton class="pl-2" name="unidadeQuantidadePrev" value="m³" label="metros cúbicos (m³)" formControlName="unidadeQuantidadePrev"></p-radioButton>&nbsp;&nbsp;&nbsp;
                    <p-radioButton name="unidadeQuantidadePrev" value="t" label="toneladas (t)" formControlName="unidadeQuantidadePrev"></p-radioButton>
                  </div>
                </div>
                <div class="p-col">

                </div>
            </div>
            <div class="p-col-6">
              <div class="p-grid">
                <div class="p-col">
                  <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col">

                </div>
              </div>
            </div>
          </div> -->
          <div class="p-grid" style="padding-bottom:150px;">
            <div class="p-col-6">
                <div class="p-grid">
                  <div class="p-col">
                    <label>Secretaria/Órgão</label>
                  </div>
                </div>
                <div class="p-grid">
                  <div class="p-col">
                    <p-dropdown [options]="secretarias" formControlName="secretariaSel" placeholder="Selecione"></p-dropdown>
                  </div>
                </div>
            </div>
            <div class="p-col-6">
              <div class="p-grid">
                <div class="p-col">
                  <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col">

                </div>
              </div>
            </div>
          </div>



      </p-tabPanel>

          <!-- ABA TRANSPORTADOR -->
      <p-tabPanel header="Transportador">

        <div class="p-grid">
          <div class="p-col-12" align="right">
            <button type="button" (click)="alternarAbas(0)" class="btn btn-outline-secondary"><i class="pi pi-angle-double-left"></i>Anterior</button>
            <button type="button" (click)="alternarAbas(2)" class="btn btn-outline-secondary">Próximo<i class="pi pi-angle-double-right"></i></button>
          </div>
        </div>

        <font color="red">
          <span *ngIf="transportadorProprio">Caso você não seja o transportador, favor anexar o contrato de transporte (botão Entidades no Menu, aba Anexos) para poder selecionar a empresa transportadora dos resíduos.</span>
        </font>
        <br>
        <div class="p-grid">
          <div class="p-col-4">
            <div class="p-grid">
              <div class="p-col">
                <label>CNPJ *</label>
              </div>
            </div>
            <div class="p-grid">
              <div class="p-col">
                <p-inputMask formControlName="transportadorCnpj" #transportadorCnpj mask="99.999.999/9999-99" (onBlur)="buscaEntidade(transportadorCnpj.value, 'transportador')"
                  [style]="{'width':'100%'}" autofocus [disabled]="transportadorProprio"></p-inputMask>
              </div>
            </div>
          </div>
          <div class="p-col-8">
            <div class="p-grid">
              <div class="p-col">
                <label>Razão Social</label>
              </div>
            </div>
            <div class="p-grid">
              <div class="p-col">
                <input pInputText formControlName="transportadorNome" style="width: 100%"/>
              </div>
            </div>
          </div>
        </div>

        <div class="p-grid">
          <div class="p-col-5">
            <div class="p-grid">
              <div class="p-col-fixed">
                <label>E-mail</label>
              </div>
            </div>
            <div class="p-grid">
              <div class="p-col">
                <input pInputText formControlName="transportadorEmail" style="width: 100%"/>
              </div>
            </div>
          </div>
          <div class="p-col-3">
            <div class="p-grid">
              <div class="p-col">
                <label>Fone Comercial</label>
              </div>
            </div>
            <div class="p-grid">
              <div class="p-col">
                <p-inputMask formControlName="transportadorFone" mask="(99)9999-9999" [style]="{'width':'100%'}"></p-inputMask>
              </div>
            </div>
          </div>
          <div class="p-col-4">
              <div class="p-grid">
                <div class="p-col">
                  <label>Celular</label>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col">
                  <p-inputMask formControlName="transportadorCelular" mask="(99)9999-9999?9" [style]="{'width':'100%'}"></p-inputMask>
                </div>
              </div>
          </div>
        </div>
      </p-tabPanel>

        <!-- ABA DESTINO FINAL -->
      <p-tabPanel header="Destino Final">

        <div class="p-grid">
          <div class="p-col-12" align="right">
            <button type="button" (click)="alternarAbas(1)" class="btn btn-outline-secondary"><i class="pi pi-angle-double-left"></i>Anterior</button>
          </div>
        </div>


        <div class="p-grid">
          <div class="p-col-4">
            <div class="p-grid">
              <div class="p-col">
                <label>CNPJ</label>
              </div>
            </div>
            <div class="p-grid">
              <div class="p-col">
                <p-inputMask formControlName="destinadorCnpj" #destinadorCnpj mask="99.999.999/9999-99" (onBlur)="buscaEntidade(destinadorCnpj.value, 'destinador')" [style]="{'width':'100%'}"></p-inputMask>
              </div>
            </div>
          </div>
          <div class="p-col-8">
            <div class="p-grid">
              <div class="p-col">
                <label>Razão Social</label>
              </div>
            </div>
            <div class="p-grid">
              <div class="p-col">
                <input pInputText formControlName="destinadorNome" style="width: 100%"/>
              </div>
            </div>
          </div>
        </div>

        <div class="p-grid">
          <div class="p-col-5">
            <div class="p-grid">
              <div class="p-col-fixed">
                <label>E-mail</label>
              </div>
            </div>
            <div class="p-grid">
              <div class="p-col">
                <input pInputText formControlName="destinadorEmail" style="width: 100%"/>
              </div>
            </div>
          </div>
          <div class="p-col-3">
            <div class="p-grid">
              <div class="p-col">
                <label>Fone Comercial</label>
              </div>
            </div>
            <div class="p-grid">
              <div class="p-col">
                <p-inputMask formControlName="destinadorFone" mask="(99)9999-9999" [style]="{'width':'100%'}"></p-inputMask>
              </div>
            </div>
          </div>
          <div class="p-col-4">
              <div class="p-grid">
                <div class="p-col">
                  <label>Celular</label>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col">
                  <p-inputMask formControlName="destinadorCelular" mask="(99)9999-9999?9" [style]="{'width':'100%'}"></p-inputMask>
                </div>
              </div>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col-5">
                <div class="p-grid">
                  <div class="p-col">
                    <label>Órgão Emissor Licença</label>
                  </div>
                </div>
                <div class="p-grid">
                  <div class="p-col">
                    <input pInputText id="licencaAmbiental" formControlName="destinadorLicencaOrgaoEmissor"/>
                  </div>
                </div>
          </div>
          <div class="p-col-3">
            <div class="p-grid">
              <div class="p-col">
                <label>Licença Ambiental</label>
              </div>
            </div>
            <div class="p-grid">
              <div class="p-col">
                <input pInputText formControlName="destinadorLicenca" style="width: 100%"/>
              </div>
            </div>
          </div>
          <div class="p-col-3">
            <div class="p-grid">
              <div class="p-col">
                <label>Validade Licença Ambiental</label>
              </div>
            </div>
            <div class="p-grid">
              <div class="p-col">
                <p-calendar class="col-sm-8" formControlName="destinadorLicencaDataValidade" [showIcon]="true" dateFormat="dd/mm/yy"></p-calendar>
              </div>
            </div>
          </div>
        </div>





        <div class="p-grid">
          <div class="p-col-12" *ngIf="codigoDMLU">
              <div class="p-grid">
                <div class="p-col">
                  <label>Código de usuário do DMLU</label>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col">
                  <input pInputText formControlName="destinadorCodDmlu" style="width: 30%"/>
                </div>
              </div>
            </div>
        </div>


        <div class="p-grid" *ngIf="endsEntidadeDestinador.length>0">
          <label>Endereços</label>
          <p-table [value]="endsEntidadeDestinador">

            <ng-template pTemplate="body" let-endereco>
              <tr>
                <td style="width: 15px;">
                  <p-radioButton name="destinadorEndSel" value="{{endereco.id}}" label="" formControlName="destinadorEndSel" (onClick)="enderecoSelecionado(endereco, 'destinador')"></p-radioButton>
                </td>
                <td style="width: 200px;">
                    {{endereco.enderecoFormatado}}
                </td>
                <td style="width: 100px;">
                  {{endereco.municipio}}
                </td>
                <td style="width: 100px;">
                  <span *ngIf="endereco.licencaAmbientalDO !== null">L.Ambiental:&nbsp;{{endereco.licencaAmbientalDO?.licenca}}</span>
                  <span *ngIf="endereco.licencaAmbientalDO === null && endereco.isentoLicenca">L.Ambiental:&nbsp;Isento</span>
                </td>
                  </tr>
            </ng-template>
          </p-table>
          <br>
        </div>

        <div class="p-col" style="text-align: right">
          <button type="button" label="Cancelar" (click)="cancelar()" class="btn btn-outline-secondary">Cancelar</button>
          <button type="button" [disabled]="!residuoForm.valid || isDadosTranspInvalidos || isDadosDestinInvalidos"
            (click)="salvarResiduosGrandesGeradores()" class="btn btn-primary" style="margin-left: 5px">Salvar</button>
        </div>

      </p-tabPanel>
    </p-tabView>

  </p-dialog>



</form>


<p-dialog header="Resíduos Gerados" [(visible)]="mostrarDialogEditRealizado" [responsive]="true" modal="true" [width]="1300" [height]="900"
showEffect="explode" hideEffect="bounce" *ngIf="mostrarDialogEditRealizado" [closable]="false">
<!-- showEffect="explode" hideEffect="bounce" *ngIf="mostrarDialogEditRealizado"> -->

<div class="p-grid">
  <div class="p-col">
    <div class="p-grid">
      <div class="p-col">
          <label>{{tituloPrevistoRealizado}}</label>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col">
          <p-table [value]="planosPrevistoRealizado">
              <ng-template pTemplate="header">
                      <tr>
                          <th width="300" style="padding-left: 5px;">
                          </th>
                          <th colspan='2' width="500" class="text-center" style="padding-left: 5px;">
                            {{planoAnoAnterior}}
                          </th>
                          <th class="text-center" width="400" style="padding-left: 5px;">
                              {{planoAnoAtual}}
                            </th>
                          </tr>
                  <tr>
                      <th class="text-center" width="400" style="padding-left: 5px;">
                          Resíduo
                      </th>
                      <th class="text-center" width="100" style="padding-left: 5px;">
                          Previsto
                      </th>
                      <th class="text-center" style="padding-left: 5px;">
                          Realizado
                      </th>
                      <th class="text-center" width="600" style="padding-left: 5px;">
                          Previsto
                      </th>
                  </tr>
              </ng-template>



              <ng-template pTemplate="body" let-planoPR let-i="rowIndex">
                <tr>
                  <td width="400" style="padding-left: 5px;">
                    {{planoPR.nomeResiduo}}
                  </td>
                  <td style="padding-left: 5px;">
                      {{planoPR.qtPrevistaAnterior}}
                  </td>
                  <td width="600" style="padding-left: 5px;">




                    <input pInputText id="userInclusao" name="qtRealizadaAnterior{{i}}" [(ngModel)]="planoPR.qtRealizadaAnterior" size='5' (blur)="controlaCamposInformados(planoPR)"/>{{unidadeMedida}}
                    <!-- <p-radioButton class="pl-2" name="umRealizadaAnterior{{i}}" value="m³" label="metros cúbicos (m³)" [(ngModel)]="planoPR.umRealizadaAnterior"></p-radioButton>&nbsp;&nbsp;&nbsp;
                    <p-radioButton name="umRealizadaAnterior{{i}}" value="toneladas" label="toneladas (t)" [(ngModel)]="planoPR.umRealizadaAnterior"></p-radioButton> -->
                  </td>
                  <td style="width: 25px; padding-left: 5px;">
                      <input pInputText id="userInclusao" name="qtRealizadaAnterior{{i}}" [(ngModel)]="planoPR.qtPrevistaAtual" size='5' (blur)="controlaCamposInformados(planoPR)"/>
                      <p-radioButton class="pl-2" name="unidadeMedida{{i}}" value="m³" label="metros cúbicos (m³)" [(ngModel)]="planoPR.unidadeMedida" (click)="controlaCamposInformados(planoPR)"></p-radioButton>&nbsp;&nbsp;&nbsp;
                      <p-radioButton name="unidadeMedida{{i}}" value="t" label="toneladas (t)" [(ngModel)]="planoPR.unidadeMedida" (click)="controlaCamposInformados(planoPR)"></p-radioButton>
                      </td>
                  </tr>
              </ng-template>
          </p-table>
      </div>
    </div>
  </div>
</div>
<div class="p-grid">
  <div class="p-col" style="text-align: right">
    <button type="button" (click)="salvarPrevistoRealizado()" class="btn btn-primary" style="margin-left: 5px" [disabled]="!salvaPrevistoRealizado">Salvar</button>
    <button type="button" (click)="sairPrevistoRealizado()" class="btn btn-primary" style="margin-left: 5px">Voltar</button>
  </div>
</div>


</p-dialog>



  <!-- <p>
    Form Status: {{ residuoForm.status }}
  </p>
  <p>
  {{diagnostic}}
</p> -->








<!-- Dialog para novo/edição -->
<!-- <p-dialog [header]="tituloDialogEdit" [(visible)]="mostrarDialogEdit" [responsive]="true" showEffect="fade" [modal]="true" -->
<p-dialog [header]="tituloDialogEdit" [visible]="false" [responsive]="true" showEffect="fade" [modal]="true"
    [width]="1400">

    <p-tabView>
        <p-tabPanel header="Informações">
            <div class="ui-g ui-fluid" *ngIf="planoEdit">

                <div class="ui-g-4">
                    <label for="vin">Empresa Responsável</label>
                </div>
                <div class="ui-g-8">
                    <div>
                        {{entidade.razaoSocial}}
                    </div>
                </div>

                <div class="ui-g-4">
                    <label for="vin">Produto pós-consumo</label>
                </div>
                <div class="ui-g-8">
                    <div *ngIf="insertMode == false">
                        {{planoEdit.produtoDO.nome}}
                    </div>
                    <div *ngIf="insertMode == true">
                        <p-dropdown [options]="prods" [(ngModel)]="planoEdit.produtoDO" [filter]="true"></p-dropdown>
                    </div>
                </div>

                <div class="ui-g-4">
                    <label for="vin">Descrição do Plano</label>
                </div>
                <div class="ui-g-8">
                    <table>
                        <tr>
                            <td>
                                <textarea pInputTextarea [(ngModel)]="planoEdit.descricao" [rows]="8" [cols]="50"></textarea>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <font size="2">Máximo 8000 caracteres</font>
                            </td>
                        </tr>


                    </table>
                </div>



                <div class="ui-g-4">
                    <label for="vin">Situação</label>
                </div>
                <!-- <div class="ui-g-8">
                    {{planoEdit.situacao}}
                </div> -->

                <!-- Campos de controle -->
                <div class="ui-g-4">
                    <label for="vin">Data Inclusão</label>
                </div>
                <div class="ui-g-8">
                    {{planoEdit.dataInclusao | date:'dd/MM/yyyy HH:mm:ss'}} - {{planoEdit.usuarioInclusao}}
                </div>

                <!-- <div class="ui-g-4">
                    <label for="vin">Data Envio</label>
                </div>
                <div class="ui-g-8">
                    {{planoEdit.dataEnvio | date:'dd/MM/yyyy HH:mm:ss'}} - {{planoEdit.usuarioEnvio}}
                </div>

                <div class="ui-g-4">
                    <label for="vin">Data Aceite</label>
                </div>
                <div class="ui-g-8">
                    {{planoEdit.dataAceite | date:'dd/MM/yyyy HH:mm:ss'}} - {{planoEdit.usuarioAceite}}
                </div> -->

                <div class="ui-g-12"></div>
                <div class="ui-g-12"></div>
            </div>

            <div style="text-align: right;">
                <button type="button" (click)="mostrarDialogEdit = false" class="btn btn-secondary">Sair</button>
                <button type="button" icon="pi pi-check" (click)="salvar()" class="btn btn-primary"*ngIf="podeSalvar()" style="margin-left: 5px;">Salvar</button>
            </div>
        </p-tabPanel>

        <!-- Participações -->
        <p-tabPanel header="Entidades do Plano" *ngIf="insertMode!=true">

            <ng-template pTemplate="content">

                <p-card *ngIf="isEntidade()">
                    Clique no botão "Adicionar Entidade" para que uma linha em branco seja incluída
                    <br> Por fim, clique no botão "Salvar Entidades" para salvar a lista no banco de dados.
                </p-card>
                <br>

                <p-table [value]="participacoes" sortField="entidadeDO.razaoSocial">

                    <ng-template pTemplate="header">
                        <tr>

                          <th style="width: 105px">
                              <font color="red">*&nbsp;</font>CNPJ</th>
                            <th style="width: 98px">Nome Fantasia</th>
                            <th style="width: 95px">
                                <font color="red">*&nbsp;</font>Razão Social</th>
                            <!-- <th style="width: 70px">CNAE</th>
                            <th style="width: 135px">
                                <font color="red">*&nbsp;</font>Papel</th> -->
                            <!-- <th style="width: 108px">
                                <font color="red">*&nbsp;</font>Tipo Entidade</th> -->
                            <!-- <th title="Somente Empresas Aderentes podem ter Pontos de Coleta" style="width: 50px">Tem Ponto de Coleta</th> -->
                            <th title="Ações" style="width: 68px">Ações</th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-rowData>
                        <tr>
                          <td>
                              <p-inputMask [(ngModel)]="rowData.entidadeDO.cnpj" placeholder="__.___.___/____-__" mask="99.999.999/9999-99" size="16"></p-inputMask>
                          </td>
                            <td>
                                <input type="text" pInputText [(ngModel)]="rowData.entidadeDO.nomeFantasia" size="12">
                            </td>
                            <td>
                                <input type="text" pInputText [(ngModel)]="rowData.entidadeDO.razaoSocial" size="12">
                            </td>
                            <!-- <td>
                                <input type="text" pInputText [(ngModel)]="rowData.entidadeDO.cnae" size="8">
                            </td>

                            <td>
                                <p-dropdown [options]="papeis" [(ngModel)]="rowData.tipoParticipacao" (onChange)="controlaTipoParticipacao(rowData.tipoParticipacao)"></p-dropdown>
                                  <table *ngIf="rowData.tipoParticipacao==='OperadorAderente'">
                                      <tr>
                                          <td style="width:35px">
                                              <p-checkbox [(ngModel)]="rowData.transporte" binary="true" label="Transporte"></p-checkbox>
                                          </td>
                                          <td style="width:22px">
                                              <p-checkbox [(ngModel)]="rowData.coleta" binary="true" label="Coleta"></p-checkbox>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td style="width:35px">
                                              <p-checkbox [(ngModel)]="rowData.armazenamento" binary="true" label="Armazenagem"></p-checkbox>
                                          </td>
                                          <td style="width:22px">
                                              <p-checkbox [(ngModel)]="rowData.triagem" binary="true" label="Triagem"></p-checkbox>
                                          </td>
                                      </tr>
                                  </table>
                            </td> -->
                            <!-- <td>
                                <p-dropdown [options]="tiposEntidade" [(ngModel)]="rowData.entidadeDO.tipo" [style]="{'width': '150px'}">
                                    <ng-template let-tipo pTemplate="item">
                                        <div style="width: 330px">
                                            <font size="-1">{{tipo.label}}</font>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </td> -->

                            <!-- <td style="text-align: center">
                                <p-checkbox [(ngModel)]="rowData.pontoDeColeta" binary="true" *ngIf="rowData.tipoParticipacao!=='Certificador' && rowData.tipoParticipacao!=='Condominio'"></p-checkbox>
                            </td> -->

                            <td>
                                <button pButton (click)="excluiParticipacao(rowData)" title="Excluir" label="Excluir" icon="pi pi-trash" *ngIf="isEntidade() && podeSalvar()"
                                    class="ui-button-secondary"></button>
                            </td>

                        </tr>
                    </ng-template>
                </p-table>

                <br>
                <br>
                <br>

                <div style="text-align: right;" *ngIf="isEntidade() && podeSalvar()">
                    <button type="button" pButton icon="pi fa-plus" (click)="addEntidade()" label="Adicionar Entidade"></button>
                    <button type="button" icon="pi pi-check" class="btn btn-primary" (click)="salvarParticipacoes()" style="margin-left: 5px;">Salvar Entidades</button>
                </div>
            </ng-template>
        </p-tabPanel>

        <!--Metas Anuais -->
        <!-- <p-tabPanel header="Desempenho anual" *ngIf="insertMode!=true">

            <p-table [value]="metas">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 50px">Ano</th>
                        <th style="width: 100px">Taxa de recuperação - MMA (%)</th>
                        <th style="width: 100px">Taxa de redução do volume disposto em aterro - MMA (%)</th>
                        <th style="width: 100px">
                            <span *ngIf="planoEdit!=null && planoEdit.produtoDO!=null">Resíduo Reciclável Gerado ({{getUnidade(planoEdit)}}/dia)</span>
                        </th>
                        <th style="width: 100px">
                            <span *ngIf="planoEdit!=null && planoEdit.produtoDO!=null">Resíduo recuperado pós-consumo ({{getUnidade(planoEdit)}}/dia)</span>
                        </th>
                        <th style="width: 100px">
                            <span *ngIf="planoEdit!=null && planoEdit.produtoDO!=null">Resíduo destinado a aterro ({{getUnidade(planoEdit)}}/dia)</span>
                        </th>
                        <th style="width: 100px">Taxa de recuperação da fração seca de RSU (%)</th>
                        <th style="width: 100px">
                            <span *ngIf="planoEdit!=null && planoEdit.produtoDO!=null">Quantidade de produto pós-consumo colocado no mercado ({{getUnidade(planoEdit)}}/dia)</span>
                        </th>
                        <th style="width: 100px">
                            <span *ngIf="planoEdit!=null && planoEdit.produtoDO!=null">Quantidade de produto pós-consumo recuperada ({{getUnidade(planoEdit)}}/dia)</span>
                        </th>
                        <th style="width: 100px">
                            <span *ngIf="planoEdit!=null && planoEdit.produtoDO!=null">Quantidade de produto pós-consumo destinada a aterro ({{getUnidade(planoEdit)}}/dia)</span>
                        </th>
                        <th style="width: 100px">Taxa de destino ao aterro (%)</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input type="text" [(ngModel)]="rowData.ano">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.ano}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input type="text" [(ngModel)]="rowData.taxaRecupMMA" required>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.taxaRecupMMA}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input type="text" [(ngModel)]="rowData.taxaReducaoVolume">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.taxaReducaoVolume}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input type="text" [(ngModel)]="rowData.residuoReciclavelGerado" required>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.residuoReciclavelGerado}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input type="text" [(ngModel)]="rowData.residuoRecuperadoPosConsumo">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.residuoRecuperadoPosConsumo}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input type="text" [(ngModel)]="rowData.residuoDestinadoAterro">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.residuoDestinadoAterro | number}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input type="text" [(ngModel)]="rowData.taxaRecupFracaoSecaRSU">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.taxaRecupFracaoSecaRSU | number}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input type="text" [(ngModel)]="rowData.qtdeNoMercado">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.qtdeNoMercado | number}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input type="text" [(ngModel)]="rowData.qtdeRecuperada">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.qtdeRecuperada | number}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input type="text" [(ngModel)]="rowData.qtdeDdestinadoAterro">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.qtdeDdestinadoAterro | number}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input type="text" [(ngModel)]="rowData.taxaDestinoAterro">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData.taxaDestinoAterro | number}}
                                </ng-template>
                            </p-cellEditor>
                        </td>

                    </tr>
                </ng-template>
            </p-table>

            <div class="ui-g-12">
            </div>

            <div style="text-align: right;" *ngIf="podeSalvar() && isEntidade()">
                <button type="button" pButton icon="pi fa-plus" (click)="addMeta()" label="Adicionar Ano"></button>
                <button type="button" pButton icon="pi pi-check" (click)="salvarMetas()" label="Salvar Metas" style="margin-left: 5px;"></button>
            </div>

            <br>

        </p-tabPanel> -->

        <!-- Anexos -->
        <p-tabPanel header="Anexos" *ngIf="insertMode!=true">

            <p-fileUpload #anexoUpload name="novoAnexo" customUpload="true" (uploadHandler)="adicionarAnexo($event)" chooseLabel="Adicionar"
                cancelLabel="Cancelar" [style]="{'margin-top': '5px'}" *ngIf="podeSalvar() && isEntidade()" accept="application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                maxFileSize="5000000" invalidFileSizeMessageSummary="{0}: Arquivo muito grande, " invalidFileSizeMessageDetail="tamanho máximo permitido é {0}.">
                <ng-template let-file pTemplate="file">
                    <div class="ui-g-2">Arquivo:</div>
                    <div class="ui-g-5">
                        <b>{{file.name}}</b>
                    </div>
                    <div class="ui-g-2">Tamanho:</div>
                    <div class="ui-g-3">
                        <b>{{anexoUpload.formatSize(file.size)}}</b>
                    </div>
                    <div class="ui-g-2">Descricao:</div>
                    <div class="ui-g-10">
                        <input type="text" [(ngModel)]="novoAnexoDescricao">
                    </div>
                    <div class="ui-g-12">Clique em "Upload" para concluir o envio do arquivo.</div>
                </ng-template>
            </p-fileUpload>
            <br>
            <br>

            <p-table [value]="anexos">

                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="3">Nenhum anexo encontrado.</td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th>Arquivo</th>
                        <th>Descrição</th>
                        <th style="width: 200px">Ações</th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-anexo>
                    <tr>
                        <td>
                            <a href="{{getUrlAnexo(anexo.id)}}">{{anexo?.fileName}}</a>
                        </td>
                        <td>{{anexo.descricao}}</td>
                        <td>
                            <button pButton (click)="excluirAnexo(anexo)" title="Excluir" label="Excluir" icon="pi pi-trash" *ngIf="podeSalvar()" class="ui-button-secondary"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

        </p-tabPanel>

        <p-tabPanel header="Termo" *ngIf="insertMode!=true">
            <div [innerHtml]="termo.texto"></div>
        </p-tabPanel>

    </p-tabView>

</p-dialog>


<p-dialog header="Excluir Plano" [(visible)]="mostrarDialogExcluir" [responsive]="true" showEffect="fade" [modal]="true"
    [width]="500">
    <h3 *ngIf="planoExcluir">
        Confirma a exclusão da plano relativo a '{{planoExcluir.produtoDO.nome}}'? Todas as informações relativas ao plano (operadores,
        destinadores e metas) serão excluídas.
    </h3>
    <div class="ui-g-12"></div>
    <div style="text-align: right;">
        <button type="button" pButton icon="pi pi-times" (click)="mostrarDialogExcluir = false" label="Não, cancelar"></button>
        <button type="button" pButton icon="pi pi-check" (click)="excluir()" label="Sim, excluir" style="margin-left: 5px;"></button>
    </div>
</p-dialog>


<!-- Dialog JUSTIFICAR ANULAÇÃO DO PLANO -->
<p-dialog header="Anular Resíduo" [(visible)]="planoEdit" [responsive]="true" showEffect="fade" [modal]="true" [width]="800" [height]="500" *ngIf="justificarAnulacao">
  <p-tabView>
    <div class="p-grid">
      <div class="p-col-11">
        <div class="p-grid">
          <div class="p-col">
            <label>Justificativa de Anulação do Resíduo</label>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col">
            <textarea pInputTextarea id="descricao" [(ngModel)]="planoEdit.justificativaAnulacao" [rows]=5 [cols]="90"></textarea>
          </div>
        </div>
      </div>
    </div>
  </p-tabView>

  <p-footer>
    <div class="p-col" style="text-align: right">
      <button type="button" (click)="salvarJustificativa(planoEdit.id)" class="btn btn-primary" style="margin-left: 5px">Salvar</button>
    </div>
  </p-footer>
</p-dialog>







  <!-- <p>
    Form Status: {{ plamanifestoForm.status }}
  </p>
  <p>
  {{diagnostic}}
</p> -->
