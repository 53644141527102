import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LicencaAmbientalService = /** @class */ (function () {
    function LicencaAmbientalService(http) {
        this.http = http;
    }
    LicencaAmbientalService.prototype.getLicencasPorEntidade = function (idEntidade) {
        var url = environment.apiUrl + '/licencas/listaPorEntidade/' + idEntidade;
        console.log('Licença Service - getLicencasPorEntidade>>>>>>>', url);
        return this.http.get(url);
    };
    LicencaAmbientalService.prototype.getLicencasPorPessoaFisica = function (idPessoaFisica) {
        var url = environment.apiUrl + '/licencas/listaPorPessoaFisica/' + idPessoaFisica;
        // console.log('Licença Service - getLicencasPorEntidade>>>>>>>', url);
        return this.http.get(url);
    };
    LicencaAmbientalService.prototype.criarLicenca = function (licenca) {
        console.log('Licença Service - criarLicenca>>>>>>>', licenca);
        return this.http.post(environment.apiUrl + '/licencas/', licenca);
    };
    LicencaAmbientalService.prototype.editarLicenca = function (licenca) {
        console.log('Licença Service - editarLicenca>>>>>>>', licenca);
        return this.http.put(environment.apiUrl + '/licencas/', licenca);
    };
    LicencaAmbientalService.prototype.deletaLicenca = function (idLicenca) {
        var url = environment.apiUrl + '/licencas/deletar/' + idLicenca;
        console.log('Licença Service - DELETA>>>>>>>', url);
        return this.http.delete(url, idLicenca);
    };
    LicencaAmbientalService.ngInjectableDef = i0.defineInjectable({ factory: function LicencaAmbientalService_Factory() { return new LicencaAmbientalService(i0.inject(i1.HttpClient)); }, token: LicencaAmbientalService, providedIn: "root" });
    return LicencaAmbientalService;
}());
export { LicencaAmbientalService };
