var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { EntidadeService } from '../entidade/entidade.service';
import { SpinnerService } from '../services/spinner.service';
import { NotificacoesService } from '../services/notificacoes.service';
import { Globals } from 'src/app/globals';
import { PessoaFisica } from 'src/app/@shared/models/pessoaFisica.model';
import { PessoaFisicaService } from '../services/pessoaFisica.service';
import { SmfPessoaService } from '../services/smfPessoa.service';
import { SecretariaPmpaService } from '../services/secretariaPmpa.service';
var UsuariosPmpaComponent = /** @class */ (function () {
    function UsuariosPmpaComponent(router, keycloakService, pessoaFisicaService, entidadeService, spinnerService, notificacoesService, smfPessoaService, secretariaPmpaService, globals) {
        this.router = router;
        this.keycloakService = keycloakService;
        this.pessoaFisicaService = pessoaFisicaService;
        this.entidadeService = entidadeService;
        this.spinnerService = spinnerService;
        this.notificacoesService = notificacoesService;
        this.smfPessoaService = smfPessoaService;
        this.secretariaPmpaService = secretariaPmpaService;
        this.globals = globals;
        this.pessoasFisicasPmpa = [];
        this.pfsSecretaria = [];
        this.entidadeNome = '';
        this.secretarias = [];
        this.secretariasPmpa = [];
        this.idSecretaria = null;
        this.pfFuncao = '';
        this.mudancaEmail = false;
        this.podeInativar = false;
        this.gdrDmluContrato = false;
        this.activeRoute = this.router.url;
        this.variaveisGlobais = globals;
    }
    UsuariosPmpaComponent.prototype.ngOnInit = function () {
        if (this.variaveisGlobais.menuRaiz === null || this.variaveisGlobais.menuRaiz === '') {
            this.router.navigate(['/sgrIni']);
        }
        else {
            this.inicializaOnInit();
        }
    };
    UsuariosPmpaComponent.prototype.inicializaOnInit = function () {
        var _this = this;
        this.spinnerService.exibir();
        this.pessoaFisicaEdit = new PessoaFisica();
        this.entidadeService.getEntidadeCnpj('92.963.560/0001-60').subscribe(function (pmpa) {
            _this.entidade = pmpa;
            console.log('inicializaOnInit...........', _this.entidade);
            _this.buscaPessoasFisicasEntidade(_this.entidade.id);
            _this.spinnerService.ocultar();
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    UsuariosPmpaComponent.prototype.buscaPessoasFisicasEntidade = function (idEntidade) {
        var _this = this;
        this.pessoasFisicasPmpa = [];
        this.pfsSecretaria = [];
        this.pessoaFisicaService.getPessoasFisicasDaEntidade(idEntidade).subscribe(function (pessoaFisicas) {
            _this.pessoasFisicasPmpa = pessoaFisicas;
            _this.pfsSecretaria = pessoaFisicas;
            console.log('buscaPessoasFisicasEntidade........pessoas...........', _this.pessoasFisicasPmpa);
            var ativos = 0;
            _this.pessoasFisicasPmpa.forEach(function (pessoa) {
                if (pessoa.ativo) {
                    ativos = ativos + 1;
                    console.log('buscaPessoasFisicasEntidade.....Ativos..............', ativos);
                }
            });
            _this.buscaSecretariasPmpa();
            _this.podeInativar = false;
            if (ativos > 1) {
                _this.podeInativar = true;
            }
            console.log('Pode Inativar...................', _this.podeInativar, '---', ativos);
        });
    };
    UsuariosPmpaComponent.prototype.buscaSecretariasPmpa = function () {
        var _this = this;
        this.secretariasPmpa = [];
        this.secretariaPmpaService.getSecretariasPmpa().subscribe(function (secretarias) {
            _this.secretariasPmpa = secretarias;
            secretarias.forEach(function (sec) {
                _this.secretarias.push({ label: sec.sigla, value: sec.id });
                _this.pfsSecretaria.forEach(function (pf) {
                    if (pf.idSecretaria === sec.id) {
                        pf.sigla = sec.sigla;
                    }
                });
            });
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    UsuariosPmpaComponent.prototype.logout = function () {
        var url = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
        this.keycloakService.getKeycloakInstance().logout({ redirectUri: url });
    };
    UsuariosPmpaComponent.prototype.mostrarDialogParaNovo = function () {
        var _this = this;
        this.pessoaFisicaEdit = new PessoaFisica();
        this.mostrarDialogEdit = true;
        this.isInsert = true;
        this.tituloDialogEdit = 'Novo Responsável Legal/Usuário/Fiscal';
        this.pfFuncao = 'rSecretario';
        this.pessoaFisicaEdit.ativo = true;
        this.pfFuncaoRT = [];
        this.secretarias = [];
        this.idSecretaria = null;
        this.secretariasPmpa.forEach(function (sec) {
            _this.secretarias.push({ label: sec.sigla, value: sec.id });
        });
    };
    UsuariosPmpaComponent.prototype.mostrarDialogParaEditar = function (idPessoaFisica) {
        var _this = this;
        var pfParaEditar = this.pessoasFisicasPmpa.find(function (pf) {
            return pf.id === idPessoaFisica;
        });
        this.pessoaFisicaEdit = __assign({}, pfParaEditar);
        console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.pessoaFisicaEdit);
        this.mostrarDialogEdit = true;
        this.isInsert = false;
        this.tituloDialogEdit = 'Editar Responsável Legal/Usuário/Fiscal';
        this.pfFuncaoRT = [];
        this.pfFuncao = '';
        if (this.pessoaFisicaEdit.rSecretario) {
            this.pfFuncao = 'rSecretario';
        }
        if (this.pessoaFisicaEdit.rLegal) {
            this.pfFuncao = 'rLegal';
        }
        if (this.pessoaFisicaEdit.rusrLegal) {
            this.pfFuncao = 'rusrLegal';
        }
        if (this.pessoaFisicaEdit.rtElaboracao && this.pessoaFisicaEdit.rtExecucao) {
            this.pfFuncaoRT = ['rElaboracao', 'rExecucao'];
        }
        else {
            if (this.pessoaFisicaEdit.rtElaboracao) {
                this.pfFuncaoRT = ['rElaboracao'];
            }
            if (this.pessoaFisicaEdit.rtExecucao) {
                this.pfFuncaoRT = ['rExecucao'];
            }
        }
        this.secretarias = [];
        this.secretariasPmpa.forEach(function (sec) {
            _this.secretarias.push({ label: sec.sigla, value: sec.id });
        });
        this.idSecretaria = this.pessoaFisicaEdit.idSecretaria;
    };
    UsuariosPmpaComponent.prototype.controlaFuncao = function (funcao) {
        console.log('.......controlaFuncao>>>>', funcao);
        if (funcao === 'rSecretario' || funcao === 'rLegal' || funcao === 'rusrLegal') {
            this.pfFuncaoRT = [];
        }
        else {
            this.pfFuncao = '';
        }
    };
    UsuariosPmpaComponent.prototype.mostrarDialogEditParaExcluir = function (idProduto) {
        // this.produtoExcluir =  this.produtos.find(function(produto) {
        //   return produto.id === idProduto;
        // });
        // this.mostrarDialogExcluir = true;
    };
    UsuariosPmpaComponent.prototype.salvar = function () {
        var _this = this;
        this.pessoaFisicaEdit['idEntidade'] = this.entidade.id;
        this.pessoaFisicaEdit.rSecretario = false;
        this.pessoaFisicaEdit.rLegal = false;
        this.pessoaFisicaEdit.rusrLegal = false;
        this.pessoaFisicaEdit.rtElaboracao = false;
        this.pessoaFisicaEdit.rtExecucao = false;
        if (this.pfFuncao === 'rSecretario') {
            this.pessoaFisicaEdit.rSecretario = true;
        }
        if (this.pfFuncao === 'rLegal') {
            this.pessoaFisicaEdit.rLegal = true;
        }
        if (this.pfFuncao === 'rusrLegal') {
            this.pessoaFisicaEdit.rusrLegal = true;
        }
        if (this.pfFuncaoRT.length > 1) {
            this.pessoaFisicaEdit.rtElaboracao = true;
            this.pessoaFisicaEdit.rtExecucao = true;
        }
        else if (this.pfFuncaoRT.length > 0) {
            if (this.pfFuncaoRT[0] === 'rElaboracao') {
                this.pessoaFisicaEdit.rtElaboracao = true;
            }
            if (this.pfFuncaoRT[0] === 'rExecucao') {
                this.pessoaFisicaEdit.rtExecucao = true;
            }
        }
        var okSalvar = true;
        console.log('..........salvarPessoaFisica........listaPF..............>>', this.pessoaFisicaEdit);
        this.secretariasPmpa.forEach(function (secr) {
            if (secr.id === _this.idSecretaria) {
                _this.pessoaFisicaEdit.secretariaPmpa = secr;
            }
        });
        if (this.pessoaFisicaEdit.secretariaPmpa === undefined || this.pessoaFisicaEdit.secretariaPmpa === null) {
            this.notificacoesService.notificarAviso('Por favor, informar a Secretaria/Órgão!', null);
            okSalvar = false;
        }
        if (okSalvar) {
            console.log('salvarPessoaFisica........listaPF..............>>', this.pessoaFisicaEdit);
            if (this.pessoaFisicaEdit.id == null) {
                this.pessoaFisicaEdit.ativo = true;
                this.pessoaFisicaService.criarPessoaFisica(this.pessoaFisicaEdit).subscribe(function (retorno) {
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarSucesso('PessoaFisica salvo com sucesso', null);
                    _this.globals.pessoaFisica = retorno;
                    if (_this.pessoaFisicaEdit.id === null) {
                        _this.pessoasFisicasPmpa.push(retorno);
                    }
                    _this.pessoaFisicaEdit = retorno;
                    //this.pessoaFisicaEdit = null;
                    _this.buscaPessoasFisicasEntidade(_this.entidade.id);
                    _this.mostrarDialogEdit = false;
                    _this.isInsert = false;
                }, function (error) {
                    console.log(error);
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro(error.error, null);
                });
            }
            else {
                // let permiteInativar = false;
                // let permiteAtualizarRl = false;
                // if (this.pessoaFisicaEdit.ativo && this.pessoaFisicaEdit.rLegal) {
                //   permiteAtualizarRl = true;
                //   permiteInativar = true;
                // } else {
                //   this.pessoasFisicasPmpa.forEach(pessoaF => {
                //       if (this.pessoaFisicaEdit.id !== pessoaF.id) {
                //         if (pessoaF.rLegal) {
                //           permiteAtualizarRl = true;
                //           if (pessoaF.ativo) {
                //             permiteInativar = true;
                //           }
                //         }
                //       }
                //   });
                // }
                // if (permiteAtualizarRl && permiteInativar) {
                this.mudancaEmail = false;
                this.pessoasFisicasPmpa.forEach(function (pfOriginal) {
                    if (pfOriginal.id === _this.pessoaFisicaEdit.id) {
                        if (pfOriginal.email !== _this.pessoaFisicaEdit.email) {
                            _this.mudancaEmail = true;
                        }
                    }
                });
                if (!this.mudancaEmail) {
                    this.atualizaPessoaFisica(this.pessoaFisicaEdit);
                    this.mostrarDialogEdit = false;
                    this.isInsert = false;
                }
                // } else {
                //   this.spinnerService.ocultar();
                //   this.notificacoesService.notificarAviso('A Empresa deve possuir ao menos um Responsável Legal ATIVO!', null );
                //   this.pessoaFisicaEdit.ativo = true;
                // }
            }
        }
    };
    UsuariosPmpaComponent.prototype.atualizaPessoaFisica = function (pessoaFisica) {
        var _this = this;
        this.mudancaEmail = false;
        // pessoaFisica.secretariaPmpa = null;
        this.pessoaFisicaService.atualizarPessoaFisica(pessoaFisica).subscribe(function (retorno) {
            console.log('atualizaPessoaFisica.....................>>', retorno);
            // this.mostrarDialogEdit = false;
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarSucesso('PessoaFisica salvo com sucesso', null);
            _this.globals.pessoaFisica = retorno;
            _this.pessoaFisicaEdit = retorno;
            _this.mostrarDialogEdit = false;
            _this.buscaPessoasFisicasEntidade(_this.entidade.id);
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    UsuariosPmpaComponent.prototype.excluir = function () {
        // this.spinnerService.exibir();
        // console.log('salvar....................>>>>>>>>>>>>>>', this.variaveisGlobais.menuRaiz);
        // this.produtoService.excluirProduto(this.produtoExcluir, this.variaveisGlobais.menuRaiz).subscribe(produto => {
        //   if (produto == null) {
        //     // Remove o valor atual
        //     this.produtos = this.produtos.filter(item => item.id !== this.produtoExcluir.id);
        //   }
        //   this.mostrarDialogExcluir = false;
        //   this.spinnerService.ocultar();
        //   this.notificacoesService.notificarSucesso('Excluído com sucesso', null);
        // },
        // error => {
        //   console.log(error);
        //   this.spinnerService.ocultar();
        //   this.notificacoesService.notificarErro('Erro ao exluir', error.error);
        // });
    };
    UsuariosPmpaComponent.prototype.verificaCpfExiste = function (cpf) {
        var _this = this;
        if (cpf === '__.___.___-__') {
            console.log('111verificaCpfExiste>>>>>>>', cpf);
            cpf = null;
        }
        console.log('222verificaCpfExiste>>>>>>>', cpf);
        if (cpf == null || cpf === '') {
            // this.spinnerService.ocultar();
            // this.notificacoesService.notificarErro('Não encontrou entidade para CNPJ informado!', null);
        }
        else {
            this.pessoaFisicaService.getPessoasFisicasPorCpf(cpf).subscribe(function (pessoaFisica) {
                console.log('verificaCnpjExiste1111>>>>>>>', pessoaFisica);
                if (pessoaFisica != null) {
                    _this.pessoaFisicaEdit = pessoaFisica;
                    _this.pessoaFisicaEdit.ativo = true;
                    _this.isInsert = false;
                    // this.spinnerService.ocultar();
                    // this.notificacoesService.notificarErro('Empresa com CNPJ informado já está cadastrada!', null);
                }
                else {
                    var novoCpf = cpf.replace(/[^\d]+/g, '');
                    // Acessa SMF
                    _this.smfPessoaService.getPessoaFazenda(novoCpf, '1').subscribe(function (pessoa) {
                        console.log('ACESSO PESSOA SMF>>>>>>>>', pessoa);
                        _this.pessoaFisicaEdit.nomeCompleto = pessoa.nomePessoa;
                    });
                }
            }, function (error) {
                console.log(error);
                console.log(error.error);
                _this.pessoaFisicaEdit.cpf = null;
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    UsuariosPmpaComponent.prototype.checkActive = function (route) {
        return route === this.activeRoute;
    };
    UsuariosPmpaComponent.prototype.selSecretaria = function (secretaria) {
        console.log('Sel Secretaria..............>>>', secretaria);
        this.gdrDmluContrato = false;
        if (secretaria === 'DMLU') {
            this.gdrDmluContrato = true;
        }
    };
    return UsuariosPmpaComponent;
}());
export { UsuariosPmpaComponent };
