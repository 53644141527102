import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as i0 from "@angular/core";
// const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
var EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
// const EXCEL_TYPE = 'application/octet-stream';
var EXCEL_EXTENSION = '.xlsx';
var ExcelService = /** @class */ (function () {
    function ExcelService() {
    }
    ExcelService.prototype.exportAsExcelFile = function (json, excelFileName) {
        var worksheet = XLSX.utils.json_to_sheet(json);
        var workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
        // XLSX.writeFile(workbook, 'myFile.xls', { bookType: 'xls', type: 'array' });
    };
    ExcelService.prototype.saveAsExcelFile = function (buffer, fileName) {
        var data = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        console.log('saveAsExcelFile', data);
        FileSaver.saveAs(data, fileName + new Date().toLocaleDateString() + '_'
            + new Date().getHours() + '_' + new Date().getMinutes() + EXCEL_EXTENSION);
    };
    ExcelService.ngInjectableDef = i0.defineInjectable({ factory: function ExcelService_Factory() { return new ExcelService(); }, token: ExcelService, providedIn: "root" });
    return ExcelService;
}());
export { ExcelService };
