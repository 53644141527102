import { Entidade } from './entidade.model';


export interface Veiculo {
  id: number;
  placa: string;
  renavam: string;
  timestampAsString: string;
  dataInclusao: string;
  usuarioInclusao: string;
  usuarioAlteracao: string;
  entidadeDO?: Entidade;
}


export class VeiculoClass {
  id: number;
  placa: string;
  renavam: string;
  timestampAsString: string;
  dataInclusao: string;
  usuarioInclusao: string;
  usuarioAlteracao: string;
  entidadeDO?: Entidade;
}
