// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//apiUrl: 'https://hom.procempa.com.br/SGR/rest',
//apiUrl: 'http://localhost:8080/SGR/rest',
export const environment = {
  production: false,
  nome: 'Ambiente de Homologação',
  apiUrl: 'https://sgr-api-hom.procempa.com.br/sgr/rest',
  // apiUrl: 'http://lpmpa-dockerh13:8080/sgr/rest',
  keycloak: {
    url: 'https://sso-pmpa-hom.procempa.com.br/auth',
    clientId: 'sgr',
    realm: 'pmpa'},
  acessoPoa: {
    url: 'https://acessopoa-hom.procempa.com.br/auth',
    clientId: 'sgr',
    realm: 'acessopoa'}
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
