/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nao-encontrada.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./nao-encontrada.component";
var styles_NaoEncontadaComponent = [i0.styles];
var RenderType_NaoEncontadaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NaoEncontadaComponent, data: {} });
export { RenderType_NaoEncontadaComponent as RenderType_NaoEncontadaComponent };
export function View_NaoEncontadaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "ui-widget"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["P\u00E1gina n\u00E3o encontrada."]))], null, null); }
export function View_NaoEncontadaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sgr-nao-encontrada", [], null, null, null, View_NaoEncontadaComponent_0, RenderType_NaoEncontadaComponent)), i1.ɵdid(1, 49152, null, 0, i2.NaoEncontadaComponent, [], null, null)], null, null); }
var NaoEncontadaComponentNgFactory = i1.ɵccf("sgr-nao-encontrada", i2.NaoEncontadaComponent, View_NaoEncontadaComponent_Host_0, {}, {}, []);
export { NaoEncontadaComponentNgFactory as NaoEncontadaComponentNgFactory };
