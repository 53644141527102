import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import * as L from 'leaflet';
import { SpinnerService } from '../services/spinner.service';
import { NotificacoesService } from '../services/notificacoes.service';
import { MapaService } from '../services/mapa.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss']
})
export class MapaComponent implements AfterViewInit {

  variaveisGlobais;

  map: any;
  layersMapGerador : any = [];
  layerMapGerador : any = {};
  layersMapTransportador : any = [];
  layerMapTransportador : any = {};
  layersMapDestinador : any = [];
  layerMapDestinador : any = {};
  layersMapTodos : any = [];
  layerMapTodos : any = {};
  layersMapGdrVigente : any = [];
  layerMapGdrVigente : any = {};
  layersMapGdrAndamento : any = [];
  layerMapGdrAndamento : any = {};

  iconGerador = L.icon({
    iconUrl: 'assets/icon/mapa/marker-blue.png',
    iconSize: [55, 61],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  iconTransportador = L.icon({
    iconUrl: 'assets/icon/mapa/marker-green.ico',
    iconSize: [50, 61],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  iconDestinador = L.icon({
    iconUrl: 'assets/icon/mapa/marker-red.png',
    iconSize: [45, 61],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  iconVigente = L.icon({
    iconUrl: 'assets/icon/mapa/marker-blue.png',
    iconSize: [55, 61],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  iconAndamento = L.icon({
    iconUrl: 'assets/icon/mapa/marker-green.ico',
    iconSize: [50, 61],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  // iconTransportador = L.icon({
  //   iconRetinaUrl: 'assets/marker-icon-2x.png',
  //   iconUrl: 'assets/marker-icon.png',
  //   shadowUrl: 'assets/marker-shadow.png',
  //   iconSize: [25, 41],
  //   iconAnchor: [12, 41],
  //   popupAnchor: [1, -34],
  //   tooltipAnchor: [16, -28],
  //   shadowSize: [41, 41]
  // });

  // iconDestinador = L.icon({
  //   iconRetinaUrl: 'assets/marker-icon-2x.png',
  //   iconUrl: 'assets/marker-icon.png',
  //   shadowUrl: 'assets/marker-shadow.png',
  //   iconSize: [25, 41],
  //   iconAnchor: [12, 41],
  //   popupAnchor: [1, -34],
  //   tooltipAnchor: [16, -28],
  //   shadowSize: [41, 41]
  // });


  constructor(
    private router: Router,
    private mapaService: MapaService,
    private spinnerService: SpinnerService,
    private notificacoesService: NotificacoesService,
    private globals: Globals
  ) {
    this.variaveisGlobais = globals;
   }

  ngAfterViewInit(): void {

    this.layersMapGerador = [];
    this.layersMapTransportador = [];
    this.layersMapDestinador = [];
    this.layersMapTodos = [];
    this.layersMapGdrVigente = [];
    this.layersMapGdrAndamento = [];
  
    if (this.variaveisGlobais.menuRaiz === 'mtrcc') {
      this.mapaMtrcc();
    }
    if (this.variaveisGlobais.menuRaiz === 'gdr') {
      this.mapaGdr();
    }
    if (this.variaveisGlobais.menuRaiz === 'grandeGerador') {
      this.mapaGrandeGerador();
    }
  }

  initMap(): void {


    // var map = L.map('map').setView([-30.05, -51.15], 13);

    // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //     attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    // }).addTo(map);
    
    // L.marker([-30.05, -51.15]).addTo(map)
    //     .bindPopup('A pretty CSS3 popup.<br> Easily customizable.')
    //     .openPopup();


    // this.buscaEnderecos();
  }

  mapaMtrcc() {

    this.mapaService.geoEnderecoMtrcc().subscribe(enderecos => {
      enderecos.forEach(e => {
        // var lmarker = L.marker([e.latitude, e.longitude]).bindPopup(literalPopup);
        // this.map.addLayer(lmarker);
        
        if (e.gerador) {
          var literalPopup = 'Empresa Geradora de Resíduos<br>Razão Social: ' + e.nomeEmpresa + '<br>CNPJ: ' + e.cnpj + '<br>Endereço: ' + e.endereco;
          this.layersMapGerador.push(L.marker([e.latitude, e.longitude], {icon: this.iconGerador}).bindPopup(literalPopup));
          this.layersMapTodos.push(L.marker([e.latitude, e.longitude], {icon: this.iconGerador}).bindPopup(literalPopup));
        }
        if (e.transportador) {
          var literalPopup = 'Empresa Transportadora de Resíduos<br>Razão Social: ' + e.nomeEmpresa + '<br>CNPJ: ' + e.cnpj + '<br>Endereço: ' + e.endereco;
          this.layersMapTransportador.push(L.marker([e.latitude, e.longitude], {icon: this.iconTransportador}).bindPopup(literalPopup));
          this.layersMapTodos.push(L.marker([e.latitude, e.longitude], {icon: this.iconTransportador}).bindPopup(literalPopup));
        }
        if (e.destinador) {
          var literalPopup = 'Empresa Destino Final de Resíduos<br>Razão Social: ' + e.nomeEmpresa + '<br>CNPJ: ' + e.cnpj + '<br>Endereço: ' + e.endereco;
          this.layersMapDestinador.push(L.marker([e.latitude, e.longitude], {icon: this.iconDestinador}).bindPopup(literalPopup));
          this.layersMapTodos.push(L.marker([e.latitude, e.longitude], {icon: this.iconDestinador}).bindPopup(literalPopup));
        }
        // var popup = lmarker.getPopup();
        // popup.setContent(literalPopup, { closeOnClick: false });
        
        // this.map.eachLayer(function(layer){
          //   layer.bindPopup('Hello');
          // });
          
          
          
          // var endereco = L.marker([e.latitude, e.longitude]).bindPopup(literalPopup);
          // this.layerEnderecos.push(endereco);
      });

      this.layerMapGerador = L.layerGroup(this.layersMapGerador);
      this.layerMapTransportador = L.layerGroup(this.layersMapTransportador);
      this.layerMapDestinador = L.layerGroup(this.layersMapDestinador);
      this.layerMapTodos = L.layerGroup(this.layersMapTodos);

      const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        minZoom: 3,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      });

      // this.map = L.map('map').setView([-30.05, -51.15], 12);
      this.map = L.map('map', {
        center: [-30.05, -51.15],
        zoom: 10,
        layers: [tiles, this.layerMapGerador, this.layerMapTransportador, this.layerMapDestinador, this.layerMapTodos]
      });


      //Define base map layers
      // var baseMaps = {
      //   "Mapa": tiles
      // };
      var baseMaps = {
        "Transportadores": this.layerMapTransportador,
        "Destinos Finais": this.layerMapDestinador,
        "Geradores": this.layerMapGerador,
        "Todos": this.layerMapTodos
      };

       // Define overlay layers
      //  var overlayLayers = {
      //   "Transportadores": this.layerMapTransportador,
      //   "Destinos Finais": this.layerMapDestinador,
      //   "Geradores": this.layerMapGerador,
      //   "Todos": this.layerMapTodos
      //  }

      //  L.control.layers(baseMaps, overlayLayers).addTo(this.map);
       L.control.layers(baseMaps).addTo(this.map);

      // this.layerEnderecos.forEach(le => {
      // });

    },
      error => {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      }
    );

  }

  mapaGdr() {

    this.mapaService.geoEnderecoGdr().subscribe(enderecos => {
      enderecos.forEach(e => {
        
        if (e.ctrVigente) {
          var literalPopup = 'Empresa com Contrato Vigente<br>Razão Social: ' + e.nomeEmpresa + '<br>CNPJ: ' + e.cnpj + '<br>Endereço: ' + e.endereco;
          this.layersMapGdrVigente.push(L.marker([e.latitude, e.longitude], {icon: this.iconVigente}).bindPopup(literalPopup));
          this.layersMapTodos.push(L.marker([e.latitude, e.longitude], {icon: this.iconVigente}).bindPopup(literalPopup));
        }
        if (e.ctrAndamento) {
          var literalPopup = 'Empresa com Contrato em Andamento<br>Razão Social: ' + e.nomeEmpresa + '<br>CNPJ: ' + e.cnpj + '<br>Endereço: ' + e.endereco;
          this.layersMapGdrAndamento.push(L.marker([e.latitude, e.longitude], {icon: this.iconAndamento}).bindPopup(literalPopup));
          this.layersMapTodos.push(L.marker([e.latitude, e.longitude], {icon: this.iconAndamento}).bindPopup(literalPopup));
        }
      });

      this.layerMapGdrVigente = L.layerGroup(this.layersMapGdrVigente);
      this.layerMapGdrAndamento = L.layerGroup(this.layersMapGdrAndamento);
      this.layerMapTodos = L.layerGroup(this.layersMapTodos);

      const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        minZoom: 3,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      });

      this.map = L.map('map', {
        center: [-30.05, -51.15],
        zoom: 10,
        layers: [tiles, this.layerMapGdrVigente, this.layerMapGdrAndamento, this.layerMapTodos]
      });

      var baseMaps = {
        "Contrato Vigente": this.layerMapGdrVigente,
        "Contrato em Andamento": this.layerMapGdrAndamento,
        "Todos": this.layerMapTodos
      };

      L.control.layers(baseMaps).addTo(this.map);

    },
      error => {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      }
    );
  }

  mapaGrandeGerador() {

      const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        minZoom: 3,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      });

      this.map = L.map('map', {
        center: [-30.05, -51.15],
        zoom: 10,
        layers: [tiles]
      });

  }
}