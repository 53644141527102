var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { FileUpload } from 'primeng/primeng';
import { PlanoService } from './plano.service';
import { InfoPlano } from 'src/app/@shared/models/plano.model';
import { EntidadeService } from '../entidade/entidade.service';
import { EnderecoService } from '../services/endereco.service';
import { PessoaFisicaService } from '../services/pessoaFisica.service';
import { SpinnerService } from '../services/spinner.service';
import { NotificacoesService } from '../services/notificacoes.service';
import { AnexoService } from '../services/anexo.service';
import { Globals } from 'src/app/globals';
import { TipoUnidadeMedida } from 'src/app/@shared/models/meta.model';
import { environment } from 'src/environments/environment';
import { ProdutoService } from '../produto/produto.service';
import { SecretariaPmpaService } from '../services/secretariaPmpa.service';
import { LicencaAmbientalService } from '../services/licencaAmbiental.service';
var ListaPlanosComponent = /** @class */ (function () {
    function ListaPlanosComponent(router, route, fb, planoService, produtoService, entidadeService, enderecoService, pessoaFisicaService, spinnerService, keycloakService, notificacoesService, secretariaPmpaService, anexoService, licencaService, globals) {
        this.router = router;
        this.route = route;
        this.fb = fb;
        this.planoService = planoService;
        this.produtoService = produtoService;
        this.entidadeService = entidadeService;
        this.enderecoService = enderecoService;
        this.pessoaFisicaService = pessoaFisicaService;
        this.spinnerService = spinnerService;
        this.keycloakService = keycloakService;
        this.notificacoesService = notificacoesService;
        this.secretariaPmpaService = secretariaPmpaService;
        this.anexoService = anexoService;
        this.licencaService = licencaService;
        this.globals = globals;
        this.submitted = false;
        this.isDadosTranspInvalidos = true;
        this.isDadosDestinInvalidos = true;
        this.entidadeId = null;
        this.endsEntidadeRepresentada = [];
        this.endsEntidadeGerador = [];
        this.endsEntidadeTransportador = [];
        this.endsEntidadeDestinador = [];
        this.veisEntidadeRepresentada = [];
        this.veisEntidadeTransportador = [];
        this.rtsElaboracaoEmpresa = [];
        this.rtsExecucaoEmpresa = [];
        this.planosMeta = [];
        this.planoSimNao = false;
        this.planoPrevistoRealizado = {};
        this.planoAnoAnterior = '';
        this.planoAnoAtual = '';
        this.planosPrevistoRealizado = [];
        this.tituloPrevistoRealizado = '';
        this.salvaPrevistoRealizado = false;
        this.unidadeMedida = '';
        this.temRTElaboracao = false;
        this.temRTElaboracaoPF = false;
        this.temRTExecucao = false;
        this.justificarAnulacao = false;
        this.produtozz = '';
        this.adicionarResiduosOkRTecnicos = null;
        this.adicionarResiduosOkAnexos = null;
        this.semPlanoResiduos = null;
        this.mensagemRespTecnicos = [];
        this.transportadorProprio = false;
        this.codigoDMLU = false;
        // Novo/edit
        this.insertMode = false;
        this.mostrarDialogEditRealizado = false;
        this.papeis = [];
        this.tiposEntidade = [];
        this.termo = { texto: '' };
        this.participacoes = [];
        this.prods = [];
        this.pontosDeColeta = [];
        this.metas = [];
        this.licencasAmbientais = [];
        this.anexoSelPlano = '';
        this.anexoSelRTElaboracao = '';
        this.anexoSelRTExecucao = '';
        //Pesquisa
        this.pesquisaReferencia = null;
        // mostra input logradouro, cep e número
        this.isCompleto = true;
        this.mensagemValidacao = '';
        this.index = 0;
        this.activeRoute = this.router.url;
        this.variaveisGlobais = globals;
        console.log("Construtor ListaPlanosComponent snapshot>>>>>>>>>>>", route.snapshot);
        this.entidadeId = route.snapshot.params['id'];
    }
    ListaPlanosComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log('ngOnInit---------ListaEntidadesComponent--------------->>>', this.globals);
        this.createResiduoForm();
        this.variaveisGlobais = this.globals;
        this.variaveisGlobais.menuRaiz = this.router.url.split('/')[1];
        if (this.globals.pessoaFisica == null) {
            this.globals.recuperaKeycloak().subscribe(function (user) {
                if (_this.globals.roleEntidade) {
                    _this.globals.recuperaEntidadePessoaFisica().subscribe(function (entidade) {
                        _this.variaveisGlobais = _this.globals;
                        console.log('--------------------------->>>>>00');
                        _this.inicializaEntidadeSelecionada();
                    });
                }
                else {
                    console.log('--------------------------->>>>>11');
                    _this.inicializaEntidadeSelecionada();
                }
            });
        }
        else {
            console.log('--------------------------->>>>>22');
            this.inicializaEntidadeSelecionada();
        }
    };
    ListaPlanosComponent.prototype.inicializaEntidadeSelecionada = function () {
        var _this = this;
        this.entidadeService.getEntidadePorId(this.entidadeId).subscribe(function (entidade) {
            _this.entidade = entidade;
            _this.spinnerService.ocultar();
            if (entidade != null) {
                // console.log('inicializaEntidadeSelecionada ..... SMAMS/DMLU.....>>>>>>>', entidade);
                _this.entidade = entidade;
                _this.buscaEnderecoEntidade(entidade, null);
                _this.inicializaOnInit();
            }
            //NO MOMENTO SÓ VALE PARA GRANDESGERADORES E SECRETARIA
            _this.nomeUsuario = _this.variaveisGlobais.userFullName;
            _this.pessoaFisica = _this.variaveisGlobais.pessoaFisica;
            _this.variaveisGlobais.entidade = _this.entidade;
            _this.variaveisGlobais.enderecosEntidade = _this.endsEntidadeRepresentada;
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaPlanosComponent.prototype.inicializaOnInit = function () {
        //ATUALIZA TABELA PLANOANEXO E PLANOPESSOAFISICA *******************************************************
        // this.planoService.atualizaPlanoAnexoPessoaFisica().subscribe(
        //   atualizado => {
        //     console.log("atualizaPlanoAnexoPessoaFisica>>>>>>>>>");
        //   },
        //   error => {
        //     console.log('ERRO...............>>>>>>', error);
        //     this.spinnerService.ocultar();
        //     this.notificacoesService.notificarErro(error, null);
        //   }
        // );
        //ATUALIZA TABELA PLANOANEXO E PLANOPESSOAFISICA *******************************************************
        var _this = this;
        //FUNCIONAL PARA GRANDES GERADORES
        this.spinnerService.exibir();
        this.controlaMenu();
        this.tituloTela = 'Resíduos Gerados pela Empresa ' + this.entidade.razaoSocial;
        this.adicionarResiduosOkRTecnicos = false;
        this.adicionarResiduosOkAnexos = false;
        this.semPlanoResiduos = false;
        this.mensagemRespTecnicos = [];
        this.spinnerService.exibir();
        this.pesquisaReferencia = new Date().getFullYear();
        if (this.variaveisGlobais.roleEntidade) {
            this.anexoService.getAnexoPorTipo(this.entidade.id, 'Plano Resíduo').subscribe(function (anexoPR) {
                console.log('.........getAnexoPorTipo --- inicializaOnInit >>>>>>>>', anexoPR);
                if (anexoPR === null || anexoPR.length < 1) {
                    _this.adicionarResiduosOkRTecnicos = true;
                    _this.adicionarResiduosOkAnexos = true;
                    _this.semPlanoResiduos = true;
                }
                else {
                    _this.pessoaFisicaService.getPessoaFisicaRTPorEntidade(_this.entidade.id, 'elaboracao').subscribe(function (pessoasFRTEl) {
                        if (pessoasFRTEl.length < 1) {
                            _this.mensagemRespTecnicos.push('Responsável Técnico pela Elaboração do plano');
                        }
                        _this.pessoaFisicaService.getPessoaFisicaRTPorEntidade(_this.entidade.id, 'execucao').subscribe(function (pessoasFRTEx) {
                            // console.log('11.........ANEXOPLANO --- inicializaOnInit >>>>>>>>', pessoasFRTEx.length);
                            if (pessoasFRTEx.length > 0) {
                                if (_this.mensagemRespTecnicos.length < 1) {
                                    _this.adicionarResiduosOkRTecnicos = true;
                                    console.log('11.........adicionarResiduosOkRTecnicos --- inicializaOnInit >>>>>>>>', _this.adicionarResiduosOkRTecnicos);
                                }
                            }
                            else {
                                _this.mensagemRespTecnicos.push('Responsável Técnico pela Execução do plano');
                            }
                            //para adicionar resíduos empresa deve ter anexado alguns documentos ... faz essa consistência
                            _this.anexoService.possuiAnexosObrigatorios(_this.entidade.id, _this.variaveisGlobais.menuRaiz).subscribe(function (mensagem) {
                                _this.spinnerService.ocultar();
                                if (mensagem.length > 0) {
                                    _this.mensagemAnexosEntidade = mensagem;
                                    _this.adicionarResiduosOkAnexos = false;
                                    console.log('22.........adicionarResiduosOkAnexos --- inicializaOnInit >>>>>>>>', _this.adicionarResiduosOkAnexos);
                                }
                                else {
                                    _this.adicionarResiduosOkAnexos = true;
                                    console.log('33.........adicionarResiduosOkAnexos --- inicializaOnInit >>>>>>>>', _this.adicionarResiduosOkAnexos);
                                }
                            }, function (error) {
                                _this.spinnerService.ocultar();
                                _this.notificacoesService.notificarErro(error, null);
                            });
                        }, function (error) {
                            _this.spinnerService.ocultar();
                            _this.notificacoesService.notificarErro(error.message, null);
                        });
                    }, function (error) {
                        _this.spinnerService.ocultar();
                        _this.notificacoesService.notificarErro(error.message, null);
                    });
                }
            }, function (error) {
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.message, null);
            });
        }
        this.planosMeta = [];
        console.log('PLANO --- inicializaOnInit .......................>>>>>>>>', this.entidade.id);
        this.buscaPlanosEntidadeAno(this.pesquisaReferencia);
        // Carrega os produtos
        this.produtos = [];
        this.produtoService.getProdutos(this.variaveisGlobais.menuRaiz, this.variaveisGlobais.roleEntidade).subscribe(function (produtos) {
            console.log('Planos Inicializa Produtos>>>>>>>>>>>>>>', produtos);
            _this.spinnerService.ocultar();
            _this.produtos = produtos;
            _this.produtos.sort(function (a, b) {
                if (a.codigo > b.codigo) {
                    return 1;
                }
                if (a.codigo < b.codigo) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
            console.log('....................Planos Inicializa Produtos>>>>>>>>>>>>>>', _this.produtos);
            //      this.prods.push({ label: '', value: null });
            _this.prods = [];
            for (var _i = 0, _a = _this.produtos; _i < _a.length; _i++) {
                var o = _a[_i];
                var si = { label: o.nome, value: o.descricao };
                _this.prods.push(si);
            }
        }, function (error) {
            _this.notificacoesService.notificarErro('Erro ao obter produtos', error.message);
        });
        // Carrega lista de secretarias
        this.secretarias = [];
        this.secretariaPmpaService.getSecretariasPmpa().subscribe(function (secretarias) {
            _this.secretariaList = secretarias;
            _this.secretarias.push({ label: 'Selecione', value: '' });
            secretarias.forEach(function (sec) {
                _this.secretarias.push({ label: sec.sigla, value: sec.sigla });
            });
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaPlanosComponent.prototype.buscaPlanosEntidadeAno = function (anoReferencia) {
        // console.log('--------------------------', anoReferencia);
        var _this = this;
        if (anoReferencia !== undefined && anoReferencia !== '' && anoReferencia !== null) {
            this.planoService.getPlanosDaEntidade(this.entidade.id, anoReferencia).subscribe(function (planos) {
                _this.planos = planos;
                console.log('--------------------------', _this.planos);
                _this.atualizaPlanosNovoAno();
                ///buscar metas
                planos.forEach(function (plano) {
                    _this.metasPlanoPorAno(plano, true, _this.pesquisaReferencia);
                });
            }, function (error) {
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.message, null);
            });
        }
    };
    ListaPlanosComponent.prototype.atualizaPlanosNovoAno = function () {
        // ******* Na virada do ano fiscal, no mês de janeiro ************
        var _this = this;
        if (new Date().getMonth() === 1) { // MÊS DE FEVEREIRO --- MUDAR
            // this.mostrarDialogEditRealizado = false;
            // if (this.mostrarDialogEditRealizado) {
            if (this.variaveisGlobais.roleEntidade) {
                if (this.planos.length < 1) {
                    console.log('PLANO --- inicializaOnInit >>>>>>>>', this.planos);
                    this.mostrarDialogEditRealizado = true;
                    this.planoAnoAtual = (new Date().getFullYear()).toString();
                    var indice_1 = 0;
                    this.planoService.getPlanosDaEntidadeAnoAnterior(this.entidade.id).subscribe(function (planosAA) {
                        // this.planos = planosAA;
                        console.log('PLANO --- inicializaOnInit >>>>>>>>', planosAA);
                        if (planosAA.length > 0) {
                            planosAA.forEach(function (plano) {
                                // this.planoPrevistoRealizado = new PlanoPrevistoRealizado();
                                _this.planoPrevistoRealizado = {};
                                _this.unidadeMedida = '';
                                _this.planoService.getMetaDoPlano(plano.id).subscribe(function (meta) {
                                    if (indice_1 === 0) {
                                        _this.planoAnoAnterior = meta.ano.toString();
                                        _this.tituloPrevistoRealizado = 'Informar quantidades realizadas para o ano de ' + _this.planoAnoAnterior
                                            + ' e estimativa para o ano de ' + _this.planoAnoAtual;
                                        indice_1 = 10;
                                    }
                                    _this.planoPrevistoRealizado.idPlano = plano.id;
                                    _this.planoPrevistoRealizado.nomeResiduo = plano.produtoDO.nome;
                                    if (meta.unidadeMedidaPrevista.toString() === 'MetroCubico') {
                                        _this.unidadeMedida = ' metros cúbicos';
                                        _this.planoPrevistoRealizado.unidadeMedida = TipoUnidadeMedida.MetroCubico;
                                    }
                                    if (meta.unidadeMedidaPrevista.toString() === 'Tonelada') {
                                        _this.unidadeMedida = ' toneladas';
                                        _this.planoPrevistoRealizado.unidadeMedida = TipoUnidadeMedida.Tonelada;
                                    }
                                    _this.planoPrevistoRealizado.qtPrevistaAnterior = meta.metaAnualPrevista.toString() + _this.unidadeMedida;
                                    _this.planoPrevistoRealizado.qtRealizadaAnterior = null;
                                    _this.planoPrevistoRealizado.qtPrevistaAtual = null;
                                    _this.planosPrevistoRealizado.push(JSON.parse(JSON.stringify(_this.planoPrevistoRealizado)));
                                });
                            });
                        }
                        else {
                            _this.mostrarDialogEditRealizado = false;
                        }
                    }, function (error) {
                        _this.spinnerService.ocultar();
                        _this.notificacoesService.notificarErro(error.message, null);
                    });
                }
            }
        }
    };
    ListaPlanosComponent.prototype.inicializaEntidade = function () {
        var inicializaEntidade = null;
        return inicializaEntidade = {
            id: null,
            cnpj: null,
            razaoSocial: '',
            dataInclusao: null,
            timestampAsString: null,
            usuarioInclusao: null,
            usuarioAlteracao: null,
            // nomeSisLog: null,
            licencaAmbiental: null,
            licencaAmbientalOrgaoEmissor: null,
            licencaAmbientalDataValidade: null,
            alvara: null,
            gdrNumeroContrato: null,
            gdrAnoContrato: null,
            gdrSituacaoContrato: null,
            gdrCertificado: null,
            codigoDmlu: null,
            adimplenteDmlu: null,
            desabilitadaMtrcc: null
        };
    };
    ListaPlanosComponent.prototype.controlaMenu = function () {
        // console.log("ListaPlanosComponent controlaMenu Router>>>>>>>>>>>", this.router);
        // console.log("ListaPlanosComponent controlaMenu VariaveisGlobais>>>>", this.variaveisGlobais);
        // if (this.keycloakService.hasRole('SMAMS') || this.keycloakService.hasRole('DMLU')) {
        //   if (this.variaveisGlobais.menuRaiz === 'grandeGerador') {
        this.items = [
            {
                label: '',
                icon: 'fa fa-home',
                routerLink: '/sgrIni'
            }
        ];
        var item = {};
        if (this.variaveisGlobais.menuRaiz === 'grandeGerador') {
            item = {};
            item = {
                label: 'Entidades',
                icon: 'fa fa-industry',
                routerLink: ['/', 'grandeGerador']
            };
            this.items.push(item);
            if (!this.variaveisGlobais.roleEntidade) {
                item = {};
                item = {
                    label: 'Produtos',
                    icon: 'fa fa-industry',
                    routerLink: ['/', 'grandeGerador', 'produtos']
                };
                this.items.push(item);
            }
        }
        else {
            if (this.variaveisGlobais.menuRaiz === 'mtrcc') {
                item = {};
                item = {
                    label: 'Entidades',
                    icon: 'fa fa-industry',
                    routerLink: ['/', 'mtrcc', 'entidades']
                };
                this.items.push(item);
                if (!this.variaveisGlobais.roleEntidade) {
                    item = {};
                    item = {
                        label: 'Produtos',
                        icon: 'fa fa-industry',
                        routerLink: ['/', 'mtrcc', 'produtos']
                    };
                    this.items.push(item);
                }
            }
        }
        //   this.items = [
        //   {
        //     label: '',
        //     icon: 'fa fa-home',
        //     routerLink: '/sgrIni'
        //   },
        //   {
        //     label: 'Entidades',
        //     icon: 'fa fa-industry',
        //     routerLink: '/grandeGerador'
        //   }
        // ];
    };
    ListaPlanosComponent.prototype.logout = function () {
        var url = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
        this.keycloakService.getKeycloakInstance().logout({ redirectUri: url });
    };
    ListaPlanosComponent.prototype.createResiduoForm = function () {
        this.residuoForm = this.fb.group({
            tipoTransportador: ['poa'],
            tipoDestinador: ['poa'],
            anexoPlanoSel: ['transiente', Validators.required],
            rtElaboracaoSelPessoa: ['transiente', Validators.required],
            rtElaboracaoSelAnexo: ['transiente', Validators.required],
            rtExecucaoSelPessoa: ['transiente', Validators.required],
            rtExecucaoSelAnexo: ['transiente', Validators.required],
            produto: ['', Validators.required],
            quantidadePrevista: ['', Validators.required],
            unidadeQuantidadePrev: ['m³', Validators.required],
            quantidadeRealizada: [null],
            unidadeQuantidadeReal: ['m³', Validators.required],
            secretariaSel: [''],
            // logradouro: ['', Validators.required],
            // numero: ['', Validators.required],
            transportadorNome: [{ value: '', disabled: true }, Validators.required],
            transportadorCnpj: ['', Validators.required],
            // transportadorEndSel: ['TransienteSeFisica', Validators.required],
            // transportadorEndereco: [{ value: '', disabled: true}, Validators.required],
            // transportadorMunicipio: [{ value: '', disabled: true}, Validators.required],
            transportadorEmail: [{ value: '', disabled: true }],
            transportadorFone: [{ value: '', disabled: true }, Validators.required],
            transportadorCelular: [{ value: '', disabled: true }, Validators.required],
            // transportadorLicenca: [{ value: '', disabled: true}, Validators.required],
            // transportadorPlaca: [{ value: '', disabled: true}, Validators.required],
            // transportadorVeiSel: ['TransienteSeFisica', Validators.required],
            destinadorNome: [{ value: '', disabled: true }, Validators.required],
            destinadorCnpj: ['', Validators.required],
            destinadorEndSel: ['', Validators.required],
            destinadorEndereco: [{ value: '', disabled: true }, Validators.required],
            destinadorMunicipio: [{ value: '', disabled: true }, Validators.required],
            destinadorEmail: [{ value: '', disabled: true }],
            destinadorFone: [{ value: '', disabled: true }, Validators.required],
            destinadorCelular: [{ value: '', disabled: true }, Validators.required],
            destinadorLicenca: [{ value: '', disabled: true }, Validators.required],
            destinadorLicencaDataValidade: [{ value: '', disabled: true }, Validators.required],
            destinadorLicencaOrgaoEmissor: [{ value: '', disabled: true }, Validators.required],
            destinadorCodDmlu: [{ value: '', disabled: false }, Validators.required]
        });
    };
    Object.defineProperty(ListaPlanosComponent.prototype, "diagnostic", {
        get: function () { return JSON.stringify(this.residuoForm.value); },
        enumerable: true,
        configurable: true
    });
    ListaPlanosComponent.prototype.onSubmit = function () {
        this.ngOnInit();
    };
    ListaPlanosComponent.prototype.trocouOperador = function (event) {
    };
    // public getUnidade(plano: Plano) {
    //   return TipoUnidadeMedida[plano.produtoDO.unidadeMedida];
    // }
    ListaPlanosComponent.prototype.mostrarDialogParaNovo = function () {
        var _this = this;
        this.planoEdit = {
            id: null,
            entidadeDO: null,
            secretariaPmpaDO: null,
            produtoDO: null,
            dataInclusao: null,
            timestampAsString: null,
            usuarioInclusao: null,
            usuarioAlteracao: null,
            dataAnulacao: null,
            usuarioAnulacao: null,
            justificativaAnulacao: null,
            codigoDMLU: null
        };
        this.codigoDMLU = false;
        this.createResiduoForm();
        // this.planoEdit['entidadeDO'] = this.entidade.razaoSocial;
        this.insertMode = true;
        this.tituloDialogEdit = 'Informar detalhes sobre geração e destino de resíduos.';
        this.produtoSelecionado = null;
        this.mostrarDialogEdit = true;
        this.endsEntidadeDestinador = [];
        // this.participacoes = [];
        // this.metas = [];
        // this.anexos = [];
        //recupera Planos de Resíduos da Empresa
        this.anexoService.getAnexoPorTipo(this.entidade.id, 'Plano Resíduo').subscribe(function (anexos) {
            console.log('................ANEXOPLANO --- mostrarDialogParaNovo >>>>>>>>', anexos);
            _this.anexosPlanoResiduo = anexos;
            if (anexos.length > 0) {
                _this.residuoForm.get('anexoPlanoSel').setValue(anexos[0].id);
            }
        }, function (error) {
            _this.notificacoesService.notificarErro(error.message, null);
        });
        //recupera RT de Elaboração
        this.pessoaFisicaService.getPessoaFisicaRTPorEntidade(this.entidade.id, 'elaboracao').subscribe(function (pessoasFRTEl) {
            _this.spinnerService.ocultar();
            _this.rtsElaboracaoEmpresa = pessoasFRTEl;
            if (pessoasFRTEl.length > 0) {
                _this.residuoForm.get('rtElaboracaoSelPessoa').setValue(pessoasFRTEl[0].id);
            }
            _this.anexoService.getAnexoPorTipo(_this.entidade.id, 'RT Elaboração').subscribe(function (anexo) {
                _this.anexosRTElaboracao = anexo;
                if (anexo.length > 0) {
                    _this.residuoForm.get('rtElaboracaoSelAnexo').setValue(anexo[0].id);
                }
            }, function (error) {
                _this.notificacoesService.notificarErro(error.message, null);
            });
        }, function (error) {
            _this.notificacoesService.notificarErro(error.message, null);
        });
        this.pessoaFisicaService.getPessoaFisicaRTPorEntidade(this.entidade.id, 'execucao').subscribe(function (pessoasFRTEx) {
            _this.spinnerService.ocultar();
            _this.rtsExecucaoEmpresa = pessoasFRTEx;
            if (pessoasFRTEx.length > 0) {
                _this.residuoForm.get('rtExecucaoSelPessoa').setValue(pessoasFRTEx[0].id);
            }
            _this.anexoService.getAnexoPorTipo(_this.entidade.id, 'RT Execução').subscribe(function (anexo) {
                _this.anexosRTExecucao = anexo;
                if (anexo.length > 0) {
                    _this.residuoForm.get('rtExecucaoSelAnexo').setValue(anexo[0].id);
                }
            }, function (error) {
                _this.notificacoesService.notificarErro(error.message, null);
            });
        }, function (error) {
            _this.notificacoesService.notificarErro(error.message, null);
        });
        //verifica se tem contrato com transportador
        this.anexoService.getAnexoPorTipo(this.entidade.id, 'Contrato Transporte').subscribe(function (anexo) {
            _this.transportadorProprio = false;
            if (anexo.length < 1) {
                _this.anexoService.getAnexoPorTipo(_this.entidade.id, 'Contrato Conjunto').subscribe(function (anexoConjunto) {
                    if (anexoConjunto.length < 1) {
                        _this.transportadorProprio = true;
                        _this.residuoForm.get('transportadorCnpj').setValue(_this.entidade.cnpj);
                        _this.buscaEntidade(_this.entidade.cnpj, 'transportador');
                    }
                }, function (error) {
                    _this.notificacoesService.notificarErro(error.message, null);
                });
            }
        }, function (error) {
            _this.notificacoesService.notificarErro(error.message, null);
        });
    };
    ListaPlanosComponent.prototype.mostrarDialogParaEditar = function (idPlano) {
        var _this = this;
        for (var _i = 0, _a = this.planos; _i < _a.length; _i++) {
            var p = _a[_i];
            if (p.id === idPlano) {
                this.planoEdit = p;
                break;
            }
        }
        console.log('mostrarDialogParaEditar ... Plano...>>', this.planoEdit);
        this.codigoDMLU = false;
        this.produtoSelecionado = this.planoEdit['produtoDO'];
        this.insertMode = false;
        this.entidade = this.planoEdit['entidadeDO'];
        this.secretaria = this.planoEdit['secretariaPmpaDO'];
        // console.log('mostrarDialogParaEditar ... Produto...>>', this.produtoSelecionado.nome);
        // console.log('mostrarDialogParaEditar ... Produtossss...>>', this.prods);
        // console.log('mostrarDialogParaEditar ... Entidade...>>', this.entidade);
        this.residuoForm.get('produto').setValue(this.produtoSelecionado.descricao);
        this.residuoForm.get('rtElaboracaoSelPessoa').setValue('');
        this.residuoForm.get('rtExecucaoSelPessoa').setValue('');
        if (this.secretaria !== undefined && this.secretaria !== null) {
            this.residuoForm.get('secretariaSel').setValue(this.secretaria.sigla);
        }
        this.semPlanoResiduos = false;
        this.anexoService.getAnexoPorTipo(this.entidade.id, 'Plano Resíduo').subscribe(function (anexoPR) {
            console.log('.........getAnexoPorTipo --- inicializaOnInit >>>>>>>>', anexoPR);
            if (anexoPR === null || anexoPR.length < 1) {
                _this.semPlanoResiduos = true;
            }
            else {
                _this.pessoaFisicaService.getRTPessoasFisicasDoPlano(idPlano).subscribe(function (rtPessoasFisicas) {
                    console.log('getRTPessoasFisicasDoPlano RTPFs........ >>>>', rtPessoasFisicas);
                    rtPessoasFisicas.forEach(function (pf) {
                        if (pf.rtElaboracao) {
                            _this.residuoForm.get('rtElaboracaoSelPessoa').setValue(pf.nomeCompleto);
                            console.log('**********************getRTPessoasFisicasDoPlano RTPFs........ >>>>', rtPessoasFisicas);
                        }
                        if (pf.rtExecucao) {
                            _this.residuoForm.get('rtExecucaoSelPessoa').setValue(pf.nomeCompleto);
                        }
                    });
                });
                _this.anexoService.getAnexosDoPlano(idPlano).subscribe(function (anexosPlano) {
                    console.log('getAnexosDoPlano Anexos.......................................... >>>>', anexosPlano);
                    anexosPlano.forEach(function (anexo) {
                        if (anexo.tipoAnexo === 'Plano Resíduo') {
                            _this.anexoPlano = anexo;
                        }
                        if (anexo.tipoAnexo === 'RT Elaboração') {
                            _this.anexoRTElaboracao = anexo;
                        }
                        if (anexo.tipoAnexo === 'RT Execução') {
                            _this.anexoRTExecucao = anexo;
                        }
                    });
                });
            }
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
        this.planoService.getParticipacoes(idPlano).subscribe(function (parts) {
            _this.participacoes = parts;
            _this.participacoes.forEach(function (participacao) {
                _this.entidadeService.getEntidadePorId(participacao.entidadeDO.id).subscribe(function (entidade) {
                    console.log('mostrarDialogParaEditar Participação >>>>', participacao);
                    if (participacao.tipoParticipacao == 'Transportador') {
                        _this.entidadeTransporte = entidade;
                        console.log('mostrarDialogParaEditar EntidadeTransporte >>>>', _this.entidadeTransporte);
                        _this.residuoForm.get('transportadorCnpj').setValue(_this.entidadeTransporte.cnpj);
                        _this.buscaPessoaFisica(_this.entidadeTransporte, 'transportador');
                    }
                    if (participacao.tipoParticipacao == 'Destinador') {
                        _this.entidadeDestino = entidade;
                        console.log('mostrarDialogParaEditar EntidadeDestino >>>>', _this.entidadeDestino);
                        _this.residuoForm.get('destinadorCnpj').setValue(_this.entidadeDestino.cnpj);
                        _this.buscaEnderecoEntidade(_this.entidadeDestino, 'destinador');
                        if (_this.entidadeDestino.cnpj === '88.017.272/0001-45') {
                            _this.residuoForm.get('destinadorCodDmlu').setValue(_this.planoEdit.codigoDMLU);
                            _this.residuoForm.get('destinadorCodDmlu').disable();
                            _this.codigoDMLU = true;
                        }
                    }
                }, function (error) {
                    console.log(error);
                    console.log(error.error);
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro(error.error, null);
                });
            });
        });
        this.metasPlanoPorAno(this.planoEdit, false, this.pesquisaReferencia);
        // this.planoService.getTermo(this.planoEdit.id).subscribe(termoc => {
        //   console.log('11111');
        //   this.termo = termoc;
        //   console.log(this.termo);
        // });
        // this.planoService.getAnexos(idPlano).subscribe(anexos => {
        //   this.anexos = anexos;
        // });
        this.mostrarDialogEdit = true;
        this.tituloDialogEdit = 'Resíduo: ' + this.planoEdit['produtoDO'].nome;
    };
    ListaPlanosComponent.prototype.metasPlanoPorAno = function (plano, listaResiduos, anoReferencia) {
        var _this = this;
        this.planoService.getMetasDoPlanoPorAno(plano.id, anoReferencia).subscribe(function (metas) {
            _this.metas = metas;
            _this.metas.forEach(function (meta) {
                console.log('metasPlanoAnoAtual Meta >>>>', meta);
                if (listaResiduos) {
                    _this.planoListaMeta = {
                        id: null,
                        residuo: null,
                        nomeEmpresa: null,
                        referencia: null,
                        justificativa: null,
                        dataAnulacao: null
                    };
                    _this.planoListaMeta.id = plano.id;
                    _this.planoListaMeta.residuo = plano.produtoDO.nome;
                    _this.planoListaMeta.nomeEmpresa = plano.entidadeDO.razaoSocial;
                    _this.planoListaMeta.referencia = meta.ano;
                    _this.planoListaMeta.justificativa = plano.justificativaAnulacao;
                    _this.planoListaMeta.dataAnulacao = plano.dataAnulacao;
                    _this.planosMeta.push(_this.planoListaMeta);
                }
                else {
                    // edição do resíduo
                    _this.metaPlano = meta;
                    console.log('mostrarDialogParaEditar Meta >>>>', _this.metaPlano);
                    _this.residuoForm.get('quantidadePrevista').setValue(_this.metaPlano.metaAnualPrevista);
                    if (_this.metaPlano.unidadeMedidaPrevista.toString() === 'MetroCubico') {
                        _this.residuoForm.get('unidadeQuantidadePrev').setValue('m³');
                    }
                    if (_this.metaPlano.unidadeMedidaPrevista.toString() === 'Tonelada') {
                        _this.residuoForm.get('unidadeQuantidadePrev').setValue('t');
                    }
                    // this.residuoForm.get('logradouro').setValue(this.metaPlano.enderecoColeta.nomeLogradouro);
                    // this.residuoForm.get('numero').setValue(this.metaPlano.enderecoColeta.numero);
                }
            });
        });
    };
    ListaPlanosComponent.prototype.anexoPlanoSelecionado = function (anexoPlano) {
    };
    ListaPlanosComponent.prototype.rtElaboracaoSelecionado = function (rtElaboracao) {
        console.log('rtElaboracaoSelecionado.................... >>>>', rtElaboracao);
    };
    ListaPlanosComponent.prototype.rtExecucaoSelecionadoPessoa = function (rtExecucaoPessoa) {
    };
    ListaPlanosComponent.prototype.rtExecucaoSelecionadoAnexo = function (rtExecucaoAnexo) {
        console.log('rtExecucaoSelecionadoAnexo.................... >>>>', this.residuoForm.get('rtExecucaoSelAnexo').value);
    };
    ListaPlanosComponent.prototype.addMeta = function () {
        this.metas.push({ 'ano': 2018 });
    };
    ListaPlanosComponent.prototype.salvarMetas = function () {
        var _this = this;
        this.planoService.salvarMetas(this.planoEdit.id, this.metas).subscribe(function (retorno) {
            _this.mostrarDialogEdit = false;
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarSucesso(retorno.toString(), null);
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaPlanosComponent.prototype.findPlano = function (idPlano) {
        var plano = this.planos.find(function (planoi) {
            return planoi.id === idPlano;
        });
        return plano;
    };
    ListaPlanosComponent.prototype.isEntidade = function () {
        // const ret = KeycloakService.keycloakAuth.hasResourceRole('Entidade');
        // return ret;
        return true;
    };
    ListaPlanosComponent.prototype.isSMAMS = function () {
        // const ret = KeycloakService.keycloakAuth.hasResourceRole('SMAMS');
        // return ret;
        return true;
    };
    ListaPlanosComponent.prototype.mostrarDialogEditParaExcluir = function (idPlano) {
        this.planoExcluir = this.planos.find(function (plano) {
            return plano.id === idPlano;
        });
        this.mostrarDialogExcluir = true;
    };
    ListaPlanosComponent.prototype.aceitarPlano = function (idPlano) {
        var _this = this;
        this.planoService.aceitarPlano(idPlano).subscribe(function (plano) {
            // Remove o valor atual
            _this.planos = _this.planos.filter(function (item) { return item.id !== plano.id; });
            // Adiciona o novo
            _this.planos.push(plano);
        });
    };
    ListaPlanosComponent.prototype.devolverPlano = function (idPlano) {
        var _this = this;
        this.planoService.devolverPlano(idPlano).subscribe(function (plano) {
            // Remove o valor atual
            _this.planos = _this.planos.filter(function (item) { return item.id !== plano.id; });
            // Adiciona o novo
            _this.planos.push(plano);
        });
    };
    ListaPlanosComponent.prototype.enviarPlano = function (idPlano) {
        var _this = this;
        this.planoService.enviarPlano(idPlano).subscribe(function (plano) {
            console.log(plano);
            // Remove o valor atual
            _this.planos = _this.planos.filter(function (item) { return item.id !== plano.id; });
            console.log(_this.planos);
            // Adiciona o novo
            _this.planos.push(plano);
            console.log(_this.planos);
        }, function (error) {
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaPlanosComponent.prototype.salvar = function () {
        var _this = this;
        // Consistências
        if (this.planoEdit.produtoDO == null) {
            this.notificacoesService.notificarErro('Informe o produto', null);
            return;
        }
        this.spinnerService.exibir();
        // this.planoEdit.produto = this.produtoSelecionado;
        this.planoEdit.entidadeDO = this.entidade;
        // Se novo
        if (this.planoEdit.id == null) {
            // this.planoService.criarPlano(this.planoEdit).subscribe(planoNovo => {
            //   this.planos.push(planoNovo);
            //   this.mostrarDialogEdit = false;
            //   this.spinnerService.ocultar();
            //   this.notificacoesService.notificarSucesso('Plano adicionada com sucesso', null);
            // },
            //   error => {
            //     console.log(error);
            //     this.spinnerService.ocultar();
            //     this.notificacoesService.notificarErro(error.error, null);
            //   });
            this.spinnerService.ocultar();
        }
        else { // Se editando
            this.planoService.atualizarPlano(this.planoEdit).subscribe(function (planoEditado) {
                // Remove o valor atual
                _this.planos = _this.planos.filter(function (item) { return item.id !== _this.planoEdit.id; });
                // Adiciona o novo
                _this.planos.push(planoEditado);
                _this.mostrarDialogEdit = false;
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarSucesso('Resíduo atualizado com sucesso.', null);
            }, function (error) {
                console.log(error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    ListaPlanosComponent.prototype.excluir = function () {
        var _this = this;
        this.spinnerService.exibir();
        this.planoService.excluirPlano(this.planoExcluir).subscribe(function () {
            // Remove o valor atual
            _this.planos = _this.planos.filter(function (item) { return item.id !== _this.planoExcluir.id; });
            _this.mostrarDialogExcluir = false;
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarSucesso('Resíduo excluído com sucesso.', null);
        }, function (error) {
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro('Erro ao exluir resíduo', error.error);
        });
    };
    ListaPlanosComponent.prototype.addEntidade = function () {
        console.log("Plano - Entidades - Adicionar - Papeis", this.papeis);
        // console.log("Plano - Entidades - Adicionar - Papeis", this.tipoParticipacao
        this.participacoes.push({ id: null, tipoParticipacao: 'Transporte', entidadeDO: {} });
    };
    // addEntidade() {
    //   console.log("Plano - Entidades - Adicionar - Papeis", this.papeis);
    //   // console.log("Plano - Entidades - Adicionar - Papeis", this.tipoParticipacao
    //   this.participacoes.push({ id: null, entidadeDO: {} });
    // }
    ListaPlanosComponent.prototype.controlaTipoParticipacao = function (tipo) {
        console.log("Plano - Entidades - Adicionar - Tipo Selecionado", tipo);
        // this.participacoes.push({ id: null, tipoParticipacao: 'Operador Aderente', entidadeDO: {} });
    };
    ListaPlanosComponent.prototype.addPC = function () {
        this.pontosDeColeta.push({ id: null, numero: '0' });
    };
    ListaPlanosComponent.prototype.salvarParticipacoes = function () {
        var _this = this;
        this.planoService.salvarParticipacoes(this.planoEdit.id, this.participacoes).subscribe(function (retorno) {
            _this.mostrarDialogEdit = false;
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarSucesso(retorno.toString(), null);
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, error.message);
        });
    };
    ListaPlanosComponent.prototype.conververArquivoByteArray = function (arquivo) {
        console.log('111');
        var reader = new FileReader();
        reader.onload = function (e) {
            console.log('222');
            var rawData = reader.result;
            console.log('333');
        };
        console.log('444');
        reader.readAsArrayBuffer(arquivo);
        console.log('555');
        return reader.result;
    };
    ListaPlanosComponent.prototype.adicionarAnexo = function (event) {
        var arquivo = event.files[0];
        var tis = this;
        var reader = new FileReader();
        tis.spinnerService.exibir();
        reader.onload = function (e) {
            var data = reader.result;
            var novoAnexo = {
                fileName: arquivo.name,
                fileType: arquivo.type,
                fileSize: arquivo.size,
                // base64File: data,
                file: data,
                descricao: tis.novoAnexoDescricao,
                tipoAnexo: 'Plano Resíduo',
                situacao: true
            };
            console.log('aaa');
            console.log(novoAnexo);
            tis.planoService.salvarAnexo(tis.planoEdit.id, novoAnexo).subscribe(function (anexo) {
                console.log('bbbb');
                console.log(anexo);
                tis.planoService.setFile(anexo['id'], novoAnexo.file).subscribe(function (ret) {
                    tis.spinnerService.ocultar();
                    tis.notificacoesService.notificarSucesso('Anexo adicionado com sucesso', null);
                }, function (error) {
                    console.log(error);
                    tis.spinnerService.ocultar();
                    tis.notificacoesService.notificarErro('Erro ao adicionar anexo', error.message);
                });
                // Concluiu
                tis.novoAnexoDescricao = null;
                tis.fileUpload.clear();
                tis.anexos.push(anexo);
            }, function (error) {
                console.log(error);
                tis.spinnerService.ocultar();
                tis.notificacoesService.notificarErro('Erro ao adicionar anexo', null);
            });
        };
        reader.readAsArrayBuffer(arquivo);
        // reader.readAsDataURL(arquivo);
    };
    ListaPlanosComponent.prototype.getUrlAnexo = function (idAnexo) {
        var url = environment.apiUrl + '/anexos/' + idAnexo + '/file';
        return url;
    };
    ListaPlanosComponent.prototype.podeSalvar = function () {
        if (this.planoEdit == null) {
            return false;
        }
        // if (this.planoEdit.situacao === 'Edicao') {
        //   return true;
        // }
        if (this.insertMode === true) {
            return true;
        }
        return false;
    };
    ListaPlanosComponent.prototype.excluirAnexo = function (anexo) {
        var _this = this;
        var r = confirm('Confirme a exclusão do anexo ' + anexo.fileName + '?');
        if (r === true) {
            this.planoService.excluirAnexo(anexo.id).subscribe(function () {
                _this.notificacoesService.notificarSucesso('Anexo excluído com sucesso', '');
                _this.anexos = _this.anexos.filter(function (item) { return item.id !== anexo.id; });
            }, function (error) {
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    ListaPlanosComponent.prototype.excluiParticipacao = function (part) {
        var _this = this;
        if (part.id == null) {
            this.participacoes = this.participacoes.filter(function (item) { return item.id != null; });
            return;
        }
        var r = confirm('A Entidade ' + part['entidadeDO'].razaoSocial + ' será desligada deste plano. Confirma?');
        if (r === true) {
            this.planoService.excluirParticipacao(part.id).subscribe(function () {
                _this.participacoes = _this.participacoes.filter(function (item) { return item.id !== part.id; });
                _this.notificacoesService.notificarSucesso('Entidade desvinculada com sucesso', '');
            }, function (error) {
                console.log(error);
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    ListaPlanosComponent.prototype.limpaPrestadoraServicoTela = function (tipoEntidade) {
        if (tipoEntidade === 'transportador') {
            this.residuoForm.get('transportadorNome').setValue('');
            this.residuoForm.get('transportadorEmail').setValue('');
            this.residuoForm.get('transportadorFone').setValue('');
            this.residuoForm.get('transportadorCelular').setValue('');
        }
        if (tipoEntidade === 'destinador') {
            this.residuoForm.get('destinadorNome').setValue('');
            this.residuoForm.get('destinadorEmail').setValue('');
            this.residuoForm.get('destinadorFone').setValue('');
            this.residuoForm.get('destinadorCelular').setValue('');
            this.residuoForm.get('destinadorLicenca').setValue('');
            this.residuoForm.get('destinadorLicencaDataValidade').setValue(null);
            this.residuoForm.get('destinadorLicencaOrgaoEmissor').setValue('');
            this.residuoForm.get('destinadorCodDmlu').setValue('');
        }
    };
    ListaPlanosComponent.prototype.buscaEntidade = function (cnpj, tipoEntidade) {
        var _this = this;
        this.limpaPrestadoraServicoTela(tipoEntidade);
        if (cnpj === '__.___.___/____-__') {
            cnpj = null;
        }
        if (cnpj == null || cnpj === '') {
            // this.spinnerService.ocultar();
            // this.notificacoesService.notificarErro('Não encontrou entidade para CNPJ informado!', null);
        }
        else {
            if (cnpj === this.entidade.cnpj && tipoEntidade === 'destinador') {
                this.notificacoesService.notificarErro('Empresa Geradora de Resíduos não pode ser Destino Final!', null);
            }
            else {
                this.entidadeService.getEntidadeCnpj(cnpj).subscribe(function (entidade) {
                    console.log('BuscaEntidade1111>>>>>>>', entidade);
                    if (entidade == null) {
                        _this.notificacoesService.notificarErro('Não encontrou Empresa para CNPJ informado!', null);
                    }
                    else {
                        _this.codigoDMLU = false;
                        _this.residuoForm.get('destinadorCodDmlu').setValue('transiente');
                        if (entidade.cnpj === '88.017.272/0001-45') {
                            _this.codigoDMLU = true;
                            _this.residuoForm.get('destinadorCodDmlu').setValue('');
                        }
                        if (tipoEntidade === 'destinador') {
                            _this.buscaLicenca(entidade);
                        }
                        setTimeout(function () {
                            _this.buscaEnderecoEntidade(entidade, tipoEntidade);
                        }, 1000);
                    }
                }, function (error) {
                    console.log(error);
                    console.log(error.error);
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro(error.error, null);
                });
            }
        }
    };
    ListaPlanosComponent.prototype.buscaEnderecoEntidade = function (entidade, tipoEntidade) {
        // console.log('buscaEnderecoEntidade0000000>>>>', tipoEntidade, '----', entidade);
        // this.buscaLicenca(entidade);
        var _this = this;
        this.enderecoService.getEnderecosPorEntidade(entidade.id).subscribe(function (enderecos) {
            _this.endsEntidadeGerador = [];
            // if (tipoEntidade == 'gerador') {
            //   this.endsEntidadeGerador = enderecos;
            // }
            // if (tipoEntidade == 'transportador') {
            //   this.endsEntidadeTransportador = [];
            //   this.endsEntidadeTransportador = enderecos;
            // }
            if (tipoEntidade == 'destinador') {
                _this.endsEntidadeDestinador = [];
                _this.endsEntidadeDestinador = enderecos;
                if (enderecos.length > 0) {
                    _this.residuoForm.get(tipoEntidade + 'EndSel').setValue(enderecos[0].id);
                    // if (enderecos[0].licencaAmbientalDO !== null) {
                    //   this.selecionaLicenca(enderecos[0].licencaAmbientalDO.licenca);
                    // }
                    _this.enderecoSelecionado(enderecos[0], tipoEntidade);
                }
            }
            // console.log('buscaEnderecoEntidade>>>>', enderecos);
            _this.buscaPessoaFisica(entidade, tipoEntidade);
            // if (tipoEntidade == 'transportador') {
            //   //    BUSCA VEÍCULOS
            //   this.buscaVeiculoEntidade(entidade, tipoEntidade);
            // } else {
            //    BUSCA PessoaFisica
            // }
        }, function (error) {
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaPlanosComponent.prototype.selecionaLicenca = function (licenca) {
        // console.log('00..............selecionaLicenca>>>>', this.licencasAmbientais);
        var _this = this;
        this.licencasAmbientais.forEach(function (lic) {
            if (lic.licenca === licenca) {
                _this.licencaAmbiental = lic;
                _this.residuoForm.get('destinadorLicenca').setValue(_this.licencaAmbiental.licenca);
                if (_this.licencaAmbiental.dataValidade != null) {
                    _this.residuoForm.get('destinadorLicencaDataValidade').setValue(new Date(_this.licencaAmbiental.dataValidade));
                }
                _this.residuoForm.get('destinadorLicencaOrgaoEmissor').setValue(_this.licencaAmbiental.orgaoEmissor);
                console.log('0..............selecionaLicenca>>>>', licenca, '<--->', lic.licenca, '<<<<');
                console.log('1..............selecionaLicenca>>>>', _this.licencaAmbiental);
                console.log('2..............selecionaLicenca>>>>', _this.residuoForm.get('destinadorLicenca').value);
                console.log('3..............selecionaLicenca>>>>', _this.residuoForm.get('destinadorLicencaDataValidade').value);
                console.log('4..............selecionaLicenca>>>>', _this.residuoForm.get('destinadorLicencaOrgaoEmissor').value);
            }
        });
    };
    ListaPlanosComponent.prototype.buscaPessoaFisica = function (entidade, tipoEntidade) {
        var _this = this;
        //    BUSCA PessoaFisica
        this.pessoaFisicaService.getPessoasFisicasDaEntidade(entidade.id).subscribe(function (pessoaFisicas) {
            pessoaFisicas.forEach(function (pf) {
                if (pf.rLegal) {
                    _this.pessoaFisica = pf;
                }
            });
            if (tipoEntidade === 'transportador') {
                _this.entidadeTransporte = entidade;
                _this.formataTelaTransportador(_this.entidadeTransporte, _this.pessoaFisica);
            }
            if (tipoEntidade === 'destinador') {
                _this.entidadeDestino = entidade;
                // this.buscaLicenca(entidade);
                _this.formataTelaDestinador(_this.entidadeDestino, _this.pessoaFisica);
            }
            if (_this.insertMode) {
                _this.validaEntidade(entidade, _this.pessoaFisica, tipoEntidade);
            }
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaPlanosComponent.prototype.buscaLicenca = function (entidade) {
        var _this = this;
        console.log('..............buscaLicenca>>>>', entidade);
        //    BUSCA PessoaFisica
        this.licencaService.getLicencasPorEntidade(entidade.id).subscribe(function (licenca) {
            _this.licencasAmbientais = licenca;
            console.log('AA..............buscaLicenca>>>>', _this.licencasAmbientais);
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaPlanosComponent.prototype.validaEntidade = function (entidade, pessoaFisica, tipoEntidade) {
        console.log('validaEntidade PLANO>>>>>>>', tipoEntidade, '---', entidade, '---', pessoaFisica);
        // console.log('validaEntidade PLANO>>>>>>>', this.endsEntidadeDestinador);
        this.mensagemValidacao = '';
        if (tipoEntidade === 'transportador') {
            if (pessoaFisica.telefone == null || pessoaFisica.telefone === '') {
                this.mensagemValidacao =
                    'Favor solicitar à Empresa selecionada como TRANSPORTADORA para que complemente as informações do representante.';
            }
            if (pessoaFisica.celular == null || pessoaFisica.celular === '') {
                this.mensagemValidacao =
                    'Favor solicitar à Empresa selecionada como TRANSPORTADORA para que complemente as informações do representante.';
            }
        }
        if (tipoEntidade === 'destinador') {
            if ((pessoaFisica.telefone === null || pessoaFisica.telefone === '') && (pessoaFisica.celular == null || pessoaFisica.celular === '')) {
                this.mensagemValidacao =
                    'Favor solicitar à Empresa selecionada como DESTINO FINAL para que complemente as informações do representante.';
            }
            if (this.endsEntidadeDestinador.length < 1) {
                this.mensagemValidacao =
                    'Favor solicitar à Empresa selecionada como DESTINO FINAL para que informe o ENDEREÇO no cadastro.';
            }
            // if (this.licencaAmbiental?.orgaoEmissor == null || this.licencaAmbiental?.orgaoEmissor === '') {
            //   this.mensagemValidacao =
            //     'Favor solicitar à Empresa selecionada como DESTINO FINAL para que informe o ÓRGÃO EMISSOR DA LICENÇA AMBIENTAL no cadastro.';
            // }
            if (this.licencaAmbiental == null || this.licencaAmbiental === undefined) {
                this.mensagemValidacao =
                    'Favor solicitar à Empresa selecionada como DESTINO FINAL para que informe a LICENÇA AMBIENTAL no cadastro.';
            }
        }
        if (this.mensagemValidacao !== '') {
            this.spinnerService.ocultar();
            this.notificacoesService.notificarErro(this.mensagemValidacao, null);
            if (tipoEntidade === 'transportador') {
                this.isDadosTranspInvalidos = true;
            }
            if (tipoEntidade === 'destinador') {
                this.isDadosDestinInvalidos = true;
            }
        }
        else {
            if (tipoEntidade === 'transportador') {
                this.isDadosTranspInvalidos = false;
            }
            if (tipoEntidade === 'destinador') {
                this.isDadosDestinInvalidos = false;
            }
        }
    };
    ListaPlanosComponent.prototype.formataTelaTransportador = function (entidade, pessoaFisica) {
        this.residuoForm.get('transportadorNome').setValue(entidade.nomeFantasia);
        this.residuoForm.get('transportadorEmail').setValue(pessoaFisica.email);
        this.residuoForm.get('transportadorFone').setValue(pessoaFisica.telefone);
        this.residuoForm.get('transportadorCelular').setValue(pessoaFisica.celular);
        // this.residuoForm.get('transportadorLicenca').setValue(entidade.licencaAmbiental);
    };
    ListaPlanosComponent.prototype.formataTelaDestinador = function (entidade, pessoaFisica) {
        this.residuoForm.get('destinadorNome').setValue(entidade.nomeFantasia);
        this.residuoForm.get('destinadorEmail').setValue(pessoaFisica.email);
        this.residuoForm.get('destinadorFone').setValue(pessoaFisica.telefone);
        this.residuoForm.get('destinadorCelular').setValue(pessoaFisica.celular);
        // this.residuoForm.get('destinadorLicenca').setValue(entidade.licencaAmbiental);
        // if (entidade.licencaAmbientalDataValidade != null) {
        //   this.residuoForm.get('destinadorLicencaDataValidade').setValue(new Date(entidade.licencaAmbientalDataValidade));
        // }
        // this.residuoForm.get('destinadorLicencaOrgaoEmissor').setValue(entidade.licencaAmbientalOrgaoEmissor);
    };
    ListaPlanosComponent.prototype.cancelar = function () {
        this.mostrarDialogEdit = false;
    };
    ListaPlanosComponent.prototype.salvarResiduosGrandesGeradores = function () {
        // console.log('salvarResiduosGrandesGeradores Entidade >>>>>>>>', this.entidade);
        // console.log('salvarResiduosGrandesGeradores EntidadeTransporte >>>>>>>>', this.entidadeTransporte);
        // console.log('salvarResiduosGrandesGeradores EntidadeDestino >>>>>>>>', this.entidadeDestino);
        // console.log('salvarResiduosGrandesGeradores Produto >>>>>>>>', this.residuoForm.get('produto').value);
        // console.log('salvarResiduosGrandesGeradores EnderecoColeta >>>>>>>>', this.enderecoColetaResiduo);
        var _this = this;
        // this.planoEdit.entidade = this.entidade;
        // this.planoEdit.produtoDO = this.residuoForm.get('produto').value;
        var infoPlano = new InfoPlano();
        infoPlano.idEntidade = this.entidade.id;
        this.produtos.forEach(function (produto) {
            if (produto.descricao === _this.residuoForm.get('produto').value) {
                infoPlano.idProduto = produto.id;
            }
        });
        if (!this.semPlanoResiduos) {
            infoPlano.idAnexoPlano = this.residuoForm.get('anexoPlanoSel').value;
            infoPlano.idRTElaboracaoPessoa = this.residuoForm.get('rtElaboracaoSelPessoa').value;
            infoPlano.idRTElaboracaoAnexo = this.residuoForm.get('rtElaboracaoSelAnexo').value;
            infoPlano.idRTExecucaoPessoa = this.residuoForm.get('rtExecucaoSelPessoa').value;
            infoPlano.idRTExecucaoAnexo = this.residuoForm.get('rtExecucaoSelAnexo').value;
        }
        // this.produtoSelecionado = this.residuoForm.get('produto').value;
        infoPlano.idEntidadeTransporte = this.entidadeTransporte.id;
        infoPlano.idEntidadeDestino = this.entidadeDestino.id;
        //    infoPlano.descricao = this.residuoForm.get('descricao').value;
        infoPlano.quantidadePrevista = this.residuoForm.get('quantidadePrevista').value;
        infoPlano.unidadeQuantidadePrev = this.residuoForm.get('unidadeQuantidadePrev').value;
        infoPlano.endereco = this.enderecoColetaResiduo;
        if (this.codigoDMLU) {
            infoPlano.codigoDMLU = this.residuoForm.get('destinadorCodDmlu').value;
        }
        console.log('salvarResiduosGrandesGeradores Secretaria .............>>>>>>>>', this.residuoForm.get('secretariaSel').value);
        if (this.residuoForm.get('secretariaSel').value !== '' || this.residuoForm.get('secretariaSel').value != null) {
            this.secretariaList.forEach(function (secrt) {
                if (secrt.sigla === _this.residuoForm.get('secretariaSel').value) {
                    console.log('salvarResiduosGrandesGeradores Secretaria .............OK');
                    infoPlano.idSecretariaPmpa = secrt.id;
                }
            });
        }
        console.log('salvarResiduosGrandesGeradores Plano >>>>>>>>', infoPlano);
        this.planoService.criarPlano(infoPlano).subscribe(function (planoNovo) {
            console.log('salvarResiduosGrandesGeradores Plano >>>>>>>>', infoPlano);
            _this.planos.push(planoNovo);
            _this.mostrarDialogEdit = false;
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarSucesso('Resíduo adicionado com sucesso', null);
        }, function (error) {
            console.log(error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    ListaPlanosComponent.prototype.numberOnly = function (event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        var valor = event.target.value;
        var situacao = true;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            situacao = false;
        }
        else if (valor.length > 4) {
            situacao = false;
        }
        return situacao;
    };
    ListaPlanosComponent.prototype.selecionaCDL = function (endereco) {
        this.localizacao = { latitude: null, longitude: null };
        if (endereco.latitude !== undefined && endereco.latitude !== null) {
            this.localizacao = {
                latitude: endereco.latitude,
                longitude: endereco.longitude
            };
            delete endereco['latitude'];
            delete endereco['longitude'];
        }
        this.enderecoColetaResiduo = endereco;
        this.enderecoColetaResiduo.localizacao = this.localizacao;
    };
    ListaPlanosComponent.prototype.enderecoSelecionado = function (endereco, tipoEntidade) {
        // this.enderecoColetaResiduo = endereco;
        // if (tipoEntidade == 'transportador') {
        //   this.entidadeTransporte.transportadorEndereco = endereco.enderecoFormatado;
        //   this.manifestoEdit.transportadorMunicipio = endereco.municipio;
        // }
        // if (tipoEntidade == 'destinador') {
        //   this.manifestoEdit.destinadorEndereco = endereco.enderecoFormatado;
        //   this.manifestoEdit.destinadorMunicipio = endereco.municipio;
        // }
        this.residuoForm.get('destinadorLicenca').setValue('');
        this.residuoForm.get('destinadorLicencaDataValidade').setValue('');
        this.residuoForm.get('destinadorLicencaOrgaoEmissor').setValue('');
        if (endereco.licencaAmbientalDO !== null) {
            console.log('ENDEREÇO enderecoSelecionado>>>>>>>', endereco);
            this.selecionaLicenca(endereco.licencaAmbientalDO.licenca);
        }
    };
    ListaPlanosComponent.prototype.visualizaAnexo = function (anexo, tipoAnexo) {
        console.log("visualizaAnexo Anexo>>>>>>>", anexo);
        var tipoArquivo = anexo.fileType.split('/');
        console.log("Tipo Arquivo>>>>>", tipoArquivo);
        if (tipoArquivo[0] == 'image') {
            var image = new Image();
            image.src = "data:image/jpg;base64," + anexo.file;
            var w = window.open('');
            w.document.write(image.outerHTML);
        }
        else if (tipoArquivo[0] == 'application') {
            if (tipoArquivo[1] == 'pdf') {
                var byteCharacters = atob(anexo.file);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], { type: 'application/pdf;base64' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }
            else if (tipoArquivo[1] == 'vnd.openxmlformats-officedocument.wordprocessingml.document' || tipoArquivo[1] == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') { //docx
                // var reader = new FileReader();
                var byteCharacters = atob(anexo.file);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], { type: 'application/octet-stream' });
                var downloadUrl = window.URL.createObjectURL(file);
                var a = document.createElement("a");
                a.href = downloadUrl;
                a.download = anexo.fileName;
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(downloadUrl);
            }
            else { //xml
                this.notificacoesService.notificarSucesso('Arquivo não previsto para visualização!', '');
            }
        }
    };
    ListaPlanosComponent.prototype.mostrarDialogEditParaAnulacao = function (idPlano) {
        var planoAnular = this.planos.find(function (plano) {
            return plano.id === idPlano;
        });
        console.log('Editar mostrarDialogParaEditar11>>>>>>>', planoAnular);
        this.planoEdit = __assign({}, planoAnular);
        this.justificarAnulacao = true;
        this.mostrarDialogEdit = false;
    };
    ListaPlanosComponent.prototype.salvarJustificativa = function (idPlano) {
        var _this = this;
        // const manifestoAnularRecusar =  this.manifestos.find(function(manifesto) {
        //   return manifesto.id === idManifesto;
        // });
        // this.manifestoEdit = {...manifestoAnularRecusar};
        console.log('Editar salvarJustificativa>>>>>>>', this.planoEdit);
        this.spinnerService.exibir();
        this.planoEdit.dataAnulacao = new Date();
        this.planoService.anularPlano(this.planoEdit).subscribe(function (planoAnulado) {
            _this.justificarAnulacao = false;
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarSucesso('Resíduo anulado com sucesso!', null);
            _this.mostrarDialogEdit = false;
            _this.planoService.getPlanosDaEntidade(_this.entidade.id, new Date().getFullYear()).subscribe(function (planos) {
                _this.planos = planos;
                console.log('PLANO --- inicializaOnInit >>>>>>>>', _this.planos);
            }, function (error) {
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.message, null);
            });
        }, function (error) {
            console.log('Salvar Post Erro>>>>>>>', error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro('Erro ao anular resíduo.', error.error);
        });
        this.onSubmit();
    };
    ListaPlanosComponent.prototype.salvarPrevistoRealizado = function () {
        var _this = this;
        var todosCamposInformados = true;
        this.planosPrevistoRealizado.forEach(function (planoPR) {
            if (planoPR.qtRealizadaAnterior === null || planoPR.qtRealizadaAnterior === '') {
                todosCamposInformados = false;
            }
            if (planoPR.qtPrevistaAtual === null || planoPR.qtPrevistaAtual === '') {
                todosCamposInformados = false;
            }
        });
        if (!todosCamposInformados) {
            this.notificacoesService.notificarAviso('Por favor, informar os valores em todos os campos.', null);
        }
        else {
        }
        this.planoService.atualizaQtdAnuaisRealizadasPrevistas(this.planosPrevistoRealizado).subscribe(function (planosAtuais) {
            console.log('.............PLANO --- salvarPrevistoRealizado >>>>>>>>', planosAtuais);
            _this.planos = planosAtuais;
            _this.mostrarDialogEditRealizado = false;
        }, function (error) {
            console.log('salvarPrevistoRealizado Post Erro>>>>>>>', error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro('Erro ao atualizar quantidades realizadas e previstas.', error.error);
        });
    };
    ListaPlanosComponent.prototype.sairPrevistoRealizado = function () {
        console.log('PLANO --- fechaJanela >>>>>>>>');
        this.router.navigate(['/grandeGerador']);
    };
    ListaPlanosComponent.prototype.controlaCamposInformados = function (planoPR) {
        var _this = this;
        this.salvaPrevistoRealizado = true;
        this.planosPrevistoRealizado.forEach(function (plano) {
            console.log('PLANO --- controlaCamposInformados >>>>>>>>', plano);
            if (plano.nomeResiduo === planoPR.nomeResiduo) {
                plano.qtRealizadaAnterior = planoPR.qtRealizadaAnterior;
                plano.qtPrevistaAtual = planoPR.qtPrevistaAtual;
            }
            if (plano.qtRealizadaAnterior == null || plano.qtRealizadaAnterior === '') {
                _this.salvaPrevistoRealizado = false;
            }
            if (plano.qtPrevistaAtual == null || plano.qtPrevistaAtual === '') {
                _this.salvaPrevistoRealizado = false;
            }
        });
        // console.log('PLANO --- controlaCamposInformados >>>>>>>>', this.planosPrevistoRealizado);
    };
    ListaPlanosComponent.prototype.changeTable = function (e) {
        this.index = e.index;
    };
    ListaPlanosComponent.prototype.alternarAbas = function (ind) {
        this.index = ind;
    };
    ListaPlanosComponent.prototype.checkActive = function (route) {
        return route === this.activeRoute;
    };
    ListaPlanosComponent.prototype.pesquisarReferencia = function () {
        console.log('....pesquisarReferencia>>>>>', this.pesquisaReferencia);
        this.planosMeta = [];
        if (this.pesquisaReferencia === undefined) {
            this.pesquisaReferencia = null;
        }
        if (this.pesquisaReferencia !== null) {
            this.buscaPlanosEntidadeAno(this.pesquisaReferencia);
        }
    };
    return ListaPlanosComponent;
}());
export { ListaPlanosComponent };
