import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SecretariaPmpa } from 'src/app/@shared/models/secretariaPmpa.model';

@Injectable({providedIn: 'root'})
export class SecretariaPmpaService {

  constructor(private http: HttpClient) { }

  getSecretariaPmpa(id: number): Observable<SecretariaPmpa> {
    const url = environment.apiUrl + '/secretariaPmpa/byId/' + id;
    console.log('criarSecretaria Service >>>>>>', url);
    return this.http.get<SecretariaPmpa>(url);
  }

  getSecretariasPmpa(): Observable<SecretariaPmpa[]> {
    const url = environment.apiUrl + '/secretariaPmpa';
    console.log('criarSecretaria Service >>>>>>', url);
    return this.http.get<SecretariaPmpa[]>(url);
  }

  criarSecretariaPmpa(secretariaPmpa: SecretariaPmpa): Observable<SecretariaPmpa> {

    return this.http.post<SecretariaPmpa>(environment.apiUrl + '/secretariaPmpa/', secretariaPmpa);
  }

  editarSecretariaPmpa(secretariaPmpa: SecretariaPmpa): Observable<SecretariaPmpa> {
    return this.http.put<SecretariaPmpa>(environment.apiUrl + '/secretariaPmpa/', secretariaPmpa);
  }

  deletaSecretariaPmpa(idSecretariaPmpa) {

    const url = environment.apiUrl + '/secretariaPmpa/deletar/' + idSecretariaPmpa;
    console.log('Secretaria Service - DELETA>>>>>>>', url);

    return this.http
      .delete(url, idSecretariaPmpa);

  }

}
