import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root', // Faz dele um singleton
})
export class SpinnerService {

  spinnerSubject: Subject<boolean> = new Subject<boolean>();

  private alterar(exibir: boolean) {
    this.spinnerSubject.next(exibir);
  }

  exibir() {
    this.alterar(true);
  }

  ocultar() {
    this.alterar(false);
  }

}
