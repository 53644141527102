
export class Anexo {
  id?: number;
  fileName: string;
  fileType: string;
  fileSize: number;
  descricao: string;
  base64File?: string;
  byteArrayFile?: any;
  file: any;
  tipoAnexo: String;
  situacao: Boolean;
}


export interface AnexoInter {
  id?: number;
  fileName: string;
  fileType: string;
  fileSize: number;
  descricao: string;
  base64File?: string;
  byteArrayFile?: any;
  file: any;
  tipoAnexo: String;
  situacao: Boolean;
}
