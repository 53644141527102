import { Entidade } from './entidade.model';
import { PessoaFisica } from './pessoaFisica.model';
import { SecretariaPmpa } from './secretariaPmpa.model';

export interface LicencaAmbiental {
  id: number;
  timestampAsString: string;
  dataInclusao?: Date;
  usuarioInclusao?: string;
  usuarioAlteracao?: string;
  entidadeDO?: Entidade;
  pessoaFisicaDO?: PessoaFisica;
  secretariaPmpaDO?: SecretariaPmpa;
	licenca?: string;
	dataValidade?: Date;
	descricao?: string;
	orgaoEmissor?: string;
}


export class LicencaAmbiental {
  id: number;
  timestampAsString: string;
  dataInclusao?: Date;
  usuarioInclusao?: string;
  usuarioAlteracao?: string;
  entidadeDO?: Entidade;
  pessoaFisicaDO?: PessoaFisica;
  secretariaPmpaDO?: SecretariaPmpa;
	licenca?: string;
	dataValidade?: Date;
	descricao?: string;
	orgaoEmissor?: string;
}

