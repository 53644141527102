import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LicencaAmbiental } from 'src/app/@shared/models/licencaAmbiental.model';

@Injectable({providedIn: 'root'})
export class LicencaAmbientalService {

  constructor(private http: HttpClient) { }

  getLicencasPorEntidade(idEntidade: number): Observable<LicencaAmbiental[]> {
    const url = environment.apiUrl + '/licencas/listaPorEntidade/' + idEntidade;
    console.log('Licença Service - getLicencasPorEntidade>>>>>>>', url);
    return this.http.get<LicencaAmbiental[]>(url);
  }

  getLicencasPorPessoaFisica(idPessoaFisica: number): Observable<LicencaAmbiental[]> {
    const url = environment.apiUrl + '/licencas/listaPorPessoaFisica/' + idPessoaFisica;
    // console.log('Licença Service - getLicencasPorEntidade>>>>>>>', url);
    return this.http.get<LicencaAmbiental[]>(url);
  }

  criarLicenca(licenca: LicencaAmbiental): Observable<LicencaAmbiental> {
    console.log('Licença Service - criarLicenca>>>>>>>', licenca);

    return this.http.post<LicencaAmbiental>(environment.apiUrl + '/licencas/', licenca);
  }

  editarLicenca(licenca: LicencaAmbiental): Observable<LicencaAmbiental> {
    console.log('Licença Service - editarLicenca>>>>>>>', licenca);
    return this.http.put<LicencaAmbiental>(environment.apiUrl + '/licencas/', licenca);
  }

  deletaLicenca(idLicenca) {

    const url = environment.apiUrl + '/licencas/deletar/' + idLicenca;
    console.log('Licença Service - DELETA>>>>>>>', url);

    return this.http.delete(url, idLicenca);

  }

}
