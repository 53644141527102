import { Component, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { MenuItem } from 'primeng/api';
import { Entidade } from '../@shared/models/entidade.model';
import { EntidadeService } from '../@core/entidade/entidade.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'sgr-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() routeControle: string;
  items: MenuItem[];
  nomeUsuario: string;
  usuarioAutenticado: boolean;
  entidadeRepresentada: Entidade;
  controleRoute;

  variaveisGlobais: Globals;
  activeRoute: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private entidadeService: EntidadeService,
    // tslint:disable-next-line: deprecation
    @Inject(DOCUMENT) private document,
    private globals: Globals,
    private keycloakService: KeycloakService) {

    this.activeRoute = this.router.url;
    this.variaveisGlobais = globals;

    if (route.snapshot.routeConfig != null) {
      if (route.snapshot.routeConfig.path === undefined) {
        console.log('Construtor MenuComponent111 snapshot>>>>>>>>>>>', route.snapshot);
      } else if (route.snapshot.routeConfig.path === 'sgr') {
        console.log('Construtor MenuComponent222 snapshot>>>>>>>>>>>', route.snapshot);

      }
    }

  }

  ngOnInit() {
    // console.log("Construtor MenuComponent ngOnInit globals>>>>>>>>>>>", this.globals);
    // console.log("Construtor MenuComponent ngOnInit variaveisglobais>>>>>>>>>>>", this.variaveisGlobais);

    this.items = [
      { label: 'Home', icon: 'fa fa-home', routerLink: ['/sgrIni'], routerLinkActiveOptions: { exact: true } }
      //   {label: 'Empresa', icon: 'fa fa-industry', routerLink: '/mtrcc/entidades'},
      //   {label: 'MTRCC', icon: 'fa fa-file', routerLink: '/mtrcc'},
      //   {label: 'Resultados', icon: 'fa fa-line-chart', routerLink: '/mtrcc/resultados'}
    ];

    if (this.variaveisGlobais.menuRaiz === 'mtrcc') {
      this.items.push({ label: 'Empresa', icon: 'fa fa-industry', routerLink: ['/mtrcc'], routerLinkActiveOptions: { exact: true } });
      if (!this.variaveisGlobais.roleEntidade) {
        this.items.push({ label: 'Usuário', icon: 'fa fa-user-o', routerLink: ['/mtrcc/usuario'], routerLinkActiveOptions: { exact: true } });
      }
      this.items.push({
        label: 'MTRCC', icon: 'fa fa-file', routerLink: ['/mtrcc/manifestos'],
        routerLinkActiveOptions: { exact: true }
      });

      if (this.variaveisGlobais.roleEntidade) {

      }
      if (!this.variaveisGlobais.roleEntidade) {
        this.items.push({
          label: 'Cadastro', icon: 'pi pi-fw pi-pencil', items: [
            {
              label: 'Resíduo', icon: 'fa fa-trash-o', routerLink: ['/mtrcc/produtos'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: 'Destino Final', icon: 'fa fa-map-marker', routerLink: ['/mtrcc/destinoFinal'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: 'Secretaria/Órgão', icon: 'fa fa-university', routerLink: ['/mtrcc/secretariaPmpa'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: 'Usuário', icon: 'fa fa-user-o', routerLink: ['/mtrcc/usuariosPmpa'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: 'Obra', icon: 'fa fa-gear', routerLink: ['/mtrcc/obraPmpa'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: 'Licença Ambiental', icon: 'fa fa-check', routerLink: ['/mtrcc/licencaAmbiental'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: 'Veículo', icon: 'fa fa-truck', routerLink: ['/mtrcc/veiculoPmpa'],
              routerLinkActiveOptions: { exact: true }
            },
          ]
        });

        this.items.push({
          label: 'Mapa', icon: 'fa fa-file', routerLink: ['/mapa'],
          routerLinkActiveOptions: { exact: true }
        });

      }

      this.items.push({
        label: 'Resultados', icon: 'fa fa-bar-chart', routerLink: ['/mtrcc/resultados'],
        routerLinkActiveOptions: { exact: true }
      });
    }

    if (this.variaveisGlobais.menuRaiz === 'grandesGeradores') {
      if (this.variaveisGlobais.roleEntidade) {
        this.items.push({
          label: 'Grandes Geradores', icon: 'fa fa-check-square-o', routerLink: ['/grandesGeradores'],
          routerLinkActiveOptions: { exact: true }
        });
      }
      if (!this.variaveisGlobais.roleEntidade) {

        this.items.push({
          label: 'Resultados', icon: 'fa fa-bar-chart', routerLink: ['/mtrcc/resultados'],
          routerLinkActiveOptions: { exact: true }
        });
        this.items.push({
          label: 'Mapa', icon: 'fa fa-file', routerLink: ['/mapa'],
          routerLinkActiveOptions: { exact: true }
        });

      }
    }

    if (this.variaveisGlobais.menuRaiz === 'grandeGerador') {
      if (this.variaveisGlobais.roleEntidade) {
        this.items.push({
          label: 'Grandes Geradores', icon: 'fa fa-check-square-o', routerLink: ['/grandesGeradores'],
          routerLinkActiveOptions: { exact: true }
        });
      }
      this.items.push({
        label: 'Empresa', icon: 'fa fa-industry', routerLink: ['/grandeGerador'],
        routerLinkActiveOptions: { exact: true }
      });
      if (!this.variaveisGlobais.roleEntidade) {
        this.items.push({ label: 'Usuário', icon: 'fa fa-user-o', routerLink: ['/grandeGerador/usuario'], routerLinkActiveOptions: { exact: true } });
        this.items.push({
          label: 'Cadastro', icon: 'pi pi-fw pi-pencil', items: [
            {
              label: 'Resíduo', icon: 'fa fa-trash-o', routerLink: ['/grandeGerador/produtos'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: 'Destino Final', icon: 'fa fa-map-marker', routerLink: ['/grandeGerador/destinoFinal'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: 'Secretaria/Órgão', icon: 'fa fa-university', routerLink: ['/grandeGerador/secretariaPmpa'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: 'Usuário', icon: 'fa fa-user-o', routerLink: ['/grandeGerador/usuariosPmpa'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: 'Obra', icon: 'fa fa-gear', routerLink: ['/grandeGerador/obraPmpa'],
              routerLinkActiveOptions: { exact: true }
            },
            // {label: 'Licença Ambiental', icon: 'fa fa-check', routerLink: ['/grandeGerador/licencaAmbiental'],
            //   routerLinkActiveOptions: { exact: true }},
            {
              label: 'Veículo', icon: 'fa fa-truck', routerLink: ['/grandeGerador/veiculoPmpa'],
              routerLinkActiveOptions: { exact: true }
            },
          ]
        });

        // this.items.push({
        //   label: 'Resultados', icon: 'fa fa-bar-chart', routerLink: ['/mtrcc/resultados'],
        //   routerLinkActiveOptions: { exact: true }
        // });
        this.items.push({
          label: 'Mapa', icon: 'fa fa-file', routerLink: ['/mapa'],
          routerLinkActiveOptions: { exact: true }
        });
      }

    }

    if (this.variaveisGlobais.menuRaiz === 'gdr') {
      this.items.push({
        label: 'Empresa', icon: 'fa fa-industry', routerLink: ['/gdr'],
        routerLinkActiveOptions: { exact: true }
      });
      if (this.variaveisGlobais.roleEntidade) {
      }
      if (!this.variaveisGlobais.roleEntidade) {
        this.items.push({ label: 'Usuário', icon: 'fa fa-user-o', routerLink: ['/gdr/usuario'], routerLinkActiveOptions: { exact: true } });
        this.items.push({
          label: 'Cadastro', icon: 'pi pi-fw pi-pencil', items: [
            {
              label: 'Resíduo', icon: 'fa fa-trash-o', routerLink: ['/gdr/produtos'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: 'Secretaria/Órgão', icon: 'fa fa-university', routerLink: ['/gdr/secretariaPmpa'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: 'Usuário', icon: 'fa fa-user-o', routerLink: ['/gdr/usuariosPmpa'],
              routerLinkActiveOptions: { exact: true }
            },

          ]
        });

        // this.items.push({
        //   label: 'Resultados', icon: 'fa fa-bar-chart', routerLink: ['/mtrcc/resultados'],
        //   routerLinkActiveOptions: { exact: true }
        // });
        this.items.push({
          label: 'Mapa', icon: 'fa fa-file', routerLink: ['/mapa'],
          routerLinkActiveOptions: { exact: true }
        });
        this.items.push({
          label: 'Balança', icon: 'fa fa-file', routerLink: ['/gdr/balanca'],
          routerLinkActiveOptions: { exact: true }
        });
      }

    }

    if (this.variaveisGlobais.roleEntidade) {

      if (this.variaveisGlobais.menuRaiz === 'transportador') {
        this.items.push({
          label: 'Grandes Geradores', icon: 'fa fa-check-square-o', routerLink: ['/grandesGeradores'],
          routerLinkActiveOptions: { exact: true }
        });
        this.items.push({ label: 'Empresa', routerLink: ['/transportador'], routerLinkActiveOptions: { exact: true } });
      }
      if (this.variaveisGlobais.menuRaiz === 'destinoFinal') {
        this.items.push({
          label: 'Grandes Geradores', icon: 'fa fa-check-square-o', routerLink: ['/grandesGeradores'],
          routerLinkActiveOptions: { exact: true }
        });
        this.items.push({ label: 'Empresa', routerLink: ['/destinoFinal'], routerLinkActiveOptions: { exact: true } });
      }
    }

  }

  logout() {
    const url = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
    this.keycloakService.logout();
  }

  isEntidade() {
    // const ret = KeycloakService.keycloakAuth.hasResourceRole('Entidade');
    // console.log('IsEntidade: ' + ret);
    // return ret;
  }


}
