import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EnderecoService = /** @class */ (function () {
    function EnderecoService(http) {
        this.http = http;
    }
    EnderecoService.prototype.getEndereco = function (id) {
        var url = environment.apiUrl + '/enderecos/byId/' + id;
        console.log('criarEndereco Service >>>>>>', url);
        return this.http.get(url);
    };
    EnderecoService.prototype.getEnderecosPorEntidade = function (idEntidade) {
        var url = environment.apiUrl + '/enderecos/listaPorEntidade/' + idEntidade;
        // console.log('criarEndereco Service >>>>>>', url);
        return this.http.get(url);
    };
    EnderecoService.prototype.criarEndereco = function (endereco) {
        var url = environment.apiUrl + '/enderecos/';
        console.log('criarEndereco Service ......>>>>>>', endereco);
        console.log('criarEndereco Service ......>>>>>>', url);
        return this.http.post(url, endereco);
    };
    EnderecoService.prototype.atualizarEndereco = function (endereco) {
        return this.http.put(environment.apiUrl + '/enderecos/', endereco);
    };
    EnderecoService.prototype.editarEndereco = function (endereco) {
        return this.http.put(environment.apiUrl + '/enderecos/', endereco);
    };
    EnderecoService.prototype.deletaEndereco = function (idEndereco) {
        var url = environment.apiUrl + '/enderecos/deletar/' + idEndereco;
        console.log('Endereço Service - DELETA>>>>>>>', url);
        return this.http
            .delete(url, idEndereco);
    };
    EnderecoService.prototype.getLicencasEusVO = function (idManifesto) {
        var url = environment.apiUrl + '/enderecos/licencaseusPorManifesto/' + idManifesto;
        // console.log('criarEndereco Service >>>>>>', url);
        return this.http.get(url);
    };
    EnderecoService.prototype.getEnderecoContrato = function (idContrato) {
        var url = environment.apiUrl + '/enderecos/contratoDmlu/' + idContrato;
        console.log('getEnderecoContrato Service >>>>>>', url);
        return this.http.get(url);
    };
    EnderecoService.prototype.getEnderecoCodigoNumero = function (codLogradouro, numero) {
        var url = environment.apiUrl + '/enderecos/codigoNumero/' + codLogradouro + '/' + numero;
        console.log('getEnderecoContrato Service >>>>>>', url);
        return this.http.get(url);
    };
    EnderecoService.prototype.getEnderecoCodigoNumeroListaEU = function (codLogradouro, numero) {
        var url = environment.apiUrl + '/enderecos/codigoNumeroListaEU/' + codLogradouro + '/' + numero;
        console.log('getEnderecoContrato Service >>>>>>', url);
        return this.http.get(url);
    };
    EnderecoService.ngInjectableDef = i0.defineInjectable({ factory: function EnderecoService_Factory() { return new EnderecoService(i0.inject(i1.HttpClient)); }, token: EnderecoService, providedIn: "root" });
    return EnderecoService;
}());
export { EnderecoService };
