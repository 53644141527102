var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ptBr } from 'src/app/@shared/utils/calendar';
import { SpinnerService } from '../../services/spinner.service';
import { NotificacoesService } from '../../services/notificacoes.service';
import { DmluService } from '../dmlu.service';
import { EntidadeService } from '../../entidade/entidade.service';
import { Globals } from 'src/app/globals';
import { EntidadeClass } from 'src/app/@shared/models/entidade.model';
import { ContratoDescargaResiduo } from 'src/app/@shared/models/contratoDescargaResiduo.model';
var BalancaComponent = /** @class */ (function () {
    function BalancaComponent(router, route, spinnerService, notificacoesService, dmluService, entidadeService, globals) {
        this.router = router;
        this.route = route;
        this.spinnerService = spinnerService;
        this.notificacoesService = notificacoesService;
        this.dmluService = dmluService;
        this.entidadeService = entidadeService;
        this.globals = globals;
        this.submitted = false;
        this.codigoBalanca = null;
        this.contratoDescargaResiduos = [];
        this.contratoTotalResiduos = [];
        this.contratoDescargaResiduosDetalhe = [];
        this.first = 0;
        this.insertMode = false;
        this.mostrarMovimentoIndividual = false;
        this.mostrarEdicao = false;
        this.mostrarTotalResiduo = false;
        this.tituloEdicao = '';
        this.idEntidade = null;
        //Pesquisa
        this.mesPesquisa = '';
        this.meses = [];
        this.codigoBalancaPesquisa = null;
        this.exibeSemMovimentacao01 = false;
        this.exibeSemMovimentacao02 = false;
        this.activeRoute = this.router.url;
        this.variaveisGlobais = globals;
        this.codigoBalancaPesquisa = route.snapshot.params['codigoBalanca'];
        if (this.codigoBalancaPesquisa !== undefined) {
            this.codigoBalancaPesquisa = 19;
        }
        console.log('BalancaComponent ..........>>>>>>>', this.codigoBalancaPesquisa);
    }
    BalancaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.variaveisGlobais = this.globals;
        this.variaveisGlobais.menuRaiz = this.router.url.split('/')[1];
        this.entidade = this.variaveisGlobais.entidade;
        if (this.globals.entidade == null) {
            this.globals.recuperaKeycloak().subscribe(function (user) {
                if (_this.globals.roleEntidade) {
                    if (_this.variaveisGlobais.pessoaFisica === null) {
                        _this.globals.recuperaEntidadePessoaFisica().subscribe(function (entidade) {
                            _this.variaveisGlobais = _this.globals;
                            _this.inicializaOnInit();
                        });
                    }
                    else {
                        _this.inicializaOnInit();
                    }
                }
                else {
                    _this.inicializaOnInit();
                }
            });
        }
        else {
            this.inicializaOnInit();
        }
    };
    BalancaComponent.prototype.inicializaOnInit = function () {
        this.contratoDescargaResiduo = new ContratoDescargaResiduo();
        this.entidade = new EntidadeClass();
        this.languageCalendar = ptBr;
        // this.mostrarDialogEdit = true;
        // if (this.contratoDescargaResiduo !== null) {
        //   this.buscaContratoDescargaResiduo();
        // }
        this.meses = [];
        this.meses.push({ label: 'Selecione', value: '' });
        this.meses.push({ label: 'Janeiro', value: 'Janeiro' });
        this.meses.push({ label: 'Fevereiro', value: 'Fevereiro' });
        this.meses.push({ label: 'Março', value: 'Março' });
        this.meses.push({ label: 'Abril', value: 'Abril' });
        this.meses.push({ label: 'Maio', value: 'Maio' });
        this.meses.push({ label: 'Junho', value: 'Junho' });
        this.meses.push({ label: 'Julho', value: 'Julho' });
        this.meses.push({ label: 'Agosto', value: 'Agosto' });
        this.meses.push({ label: 'Setembro', value: 'Setembro' });
        this.meses.push({ label: 'Outubro', value: 'Outubro' });
        this.meses.push({ label: 'Novembro', value: 'Novembro' });
        this.meses.push({ label: 'Dezembro', value: 'Dezembro' });
    };
    BalancaComponent.prototype.buscaContratoDescargaResiduo = function (mesPesquisa, codigoBalancaPesquisa) {
        var _this = this;
        this.spinnerService.exibir();
        this.exibeSemMovimentacao01 = false;
        this.dmluService.getDescargaResiduoPesquisa(codigoBalancaPesquisa, mesPesquisa).subscribe(function (cdr) {
            // console.log('buscaContratoDescargaResiduo ..........>>>>>>>', cdr);
            _this.contratoDescargaResiduos = cdr;
            _this.totalEntidades = cdr.length;
            if (cdr.length < 1) {
                _this.exibeSemMovimentacao01 = true;
            }
            _this.spinnerService.ocultar();
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    BalancaComponent.prototype.mostrarDescargaResiduoCodigoBalancaMes = function (codigoBalanca) {
        var _this = this;
        this.insertMode = false;
        this.exibeSemMovimentacao02 = false;
        this.contratoDescargaResiduosDetalhe = [];
        this.tituloEdicao = 'Balança - Movimentação Individual de Descarga de Resíduos para o mês de ' + this.mesPesquisa;
        this.spinnerService.exibir();
        this.dmluService.getDescargaResiduoCodigoBalancaMes(codigoBalanca, this.mesPesquisa).subscribe(function (cdrs) {
            // console.log('------===>', cdrs);
            _this.contratoDescargaResiduosDetalhe = cdrs;
            if (!_this.variaveisGlobais.roleEntidade) {
                _this.mostrarMovimentoIndividual = true;
            }
            _this.totalDetalhes = cdrs.length;
            _this.totalPeso = 0;
            _this.contratoDescargaResiduosDetalhe.forEach(function (reg) {
                reg.dataDescargaInicio = new Date(reg.dataDescargaInicio);
                _this.totalPeso = _this.totalPeso + reg.pesoLiquido;
            });
            // console.log('mostrarDescargaResiduoCodigoBalancaMes>>>>>>>>>>>>>>', this.totalPeso);
            _this.first = 0;
            _this.entidadeService.getEntidadePorId(codigoBalanca).subscribe(function (ent) {
                // this.entidadeService.getEntidadePorCodigoDmlu(34009).subscribe(ent => {
                _this.entidade = ent;
                // console.log('------===>', this.entidade);
            }, function (error) {
                console.log(error);
                console.log(error.error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
            if (cdrs.length < 1) {
                _this.exibeSemMovimentacao02 = true;
            }
            _this.spinnerService.ocultar();
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    BalancaComponent.prototype.pesquisaBalanca = function (admin) {
        this.contratoDescargaResiduos = [];
        if (this.mesPesquisa === undefined || this.mesPesquisa === null) {
            this.mesPesquisa = '';
        }
        if (this.codigoBalancaPesquisa === undefined || this.codigoBalancaPesquisa === null || this.codigoBalancaPesquisa.toString() === '') {
            this.codigoBalancaPesquisa = 0;
        }
        if (this.mesPesquisa === '') {
            this.notificacoesService.notificarAviso('Favor informar o MÊS da pesquisa!', null);
            return;
        }
        if (!admin) {
            if (this.codigoBalancaPesquisa === 0) {
                this.notificacoesService.notificarAviso('Favor informar o CÓDIGO DMLU da pesquisa!', null);
                return;
            }
        }
        if (admin) {
            if (this.codigoBalancaPesquisa === 0) {
                this.buscaContratoDescargaResiduo(this.mesPesquisa, this.codigoBalancaPesquisa);
            }
            else {
                this.mostrarDescargaResiduoCodigoBalancaMes(this.codigoBalancaPesquisa);
            }
        }
        else {
            this.mostrarDescargaResiduoCodigoBalancaMes(this.codigoBalancaPesquisa);
        }
    };
    BalancaComponent.prototype.mostrarEditarDescarga = function (idCDR) {
        var cdr = this.contratoDescargaResiduosDetalhe.find(function (cdResiduo) {
            return cdResiduo.id === idCDR;
        });
        this.contratoDescargaResiduo = __assign({}, cdr);
        console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.contratoDescargaResiduo);
        this.mostrarEdicao = true;
        this.dataEntrada = this.contratoDescargaResiduo.dataDescargaInicio;
        this.dataSaida = new Date(this.contratoDescargaResiduo.dataDescargaFinal);
        this.codigoBalancaEdicao = this.contratoDescargaResiduo.codigoBalanca;
        this.placaVeiculo = this.contratoDescargaResiduo.placaVeiculo;
        this.pesoBruto = this.contratoDescargaResiduo.pesoBruto;
        this.pesoTara = this.contratoDescargaResiduo.pesoTara;
        this.pesoLiquido = this.contratoDescargaResiduo.pesoLiquido;
    };
    BalancaComponent.prototype.atualizarDescarga = function () {
        var _this = this;
        this.contratoDescargaResiduo.dataDescargaInicio = this.dataEntrada;
        this.contratoDescargaResiduo.dataDescargaFinal = this.dataSaida;
        this.contratoDescargaResiduo.codigoBalanca = this.codigoBalancaEdicao;
        this.contratoDescargaResiduo.placaVeiculo = this.placaVeiculo;
        this.contratoDescargaResiduo.pesoBruto = this.pesoBruto;
        this.contratoDescargaResiduo.pesoTara = this.pesoTara;
        this.contratoDescargaResiduo.pesoLiquido = this.pesoBruto - this.pesoTara;
        this.spinnerService.exibir();
        this.dmluService.atualizarDescarga(this.contratoDescargaResiduo).subscribe(function (cdr) {
            // console.log('buscaContratoDescargaResiduo ..........>>>>>>>', cdr);
            _this.mostrarDescargaResiduoCodigoBalancaMes(cdr.codigoBalanca);
            _this.mostrarEdicao = false;
            _this.spinnerService.ocultar();
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    BalancaComponent.prototype.excluirDescarga = function () {
        var _this = this;
        this.spinnerService.exibir();
        this.dmluService.excluirDescarga(this.contratoDescargaResiduo).subscribe(function (cdr) {
            // console.log('buscaContratoDescargaResiduo ..........>>>>>>>', cdr);
            _this.mostrarDescargaResiduoCodigoBalancaMes(_this.contratoDescargaResiduo.codigoBalanca);
            _this.mostrarEdicao = false;
            _this.spinnerService.ocultar();
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    BalancaComponent.prototype.mostrarTotaisResiduoBalancaMes = function (codigoBalanca) {
        var _this = this;
        if (this.mesPesquisa === undefined || this.mesPesquisa === null) {
            this.mesPesquisa = '';
        }
        if (this.mesPesquisa === '') {
            this.notificacoesService.notificarAviso('Favor informar o MÊS da pesquisa!', null);
            return;
        }
        this.insertMode = false;
        this.mostrarTotalResiduo = true;
        this.contratoTotalResiduos = [];
        this.spinnerService.exibir();
        this.dmluService.getTotalPorResiduo(codigoBalanca, this.mesPesquisa).subscribe(function (cdr) {
            // console.log('buscaContratoDescargaResiduo ..........>>>>>>>', cdr);
            _this.contratoTotalResiduos = cdr;
            _this.totalEntidades = cdr.length;
            _this.spinnerService.ocultar();
        }, function (error) {
            console.log(error);
            console.log(error.error);
            _this.spinnerService.ocultar();
            _this.notificacoesService.notificarErro(error.error, null);
        });
    };
    return BalancaComponent;
}());
export { BalancaComponent };
