import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { Entidade } from "../../@shared/models/entidade.model";
import { KeycloakService } from "@procempa/ngx-keycloak";
import { Globals } from "../../globals";
import { MenuItem, SelectItem } from 'primeng/api';

@Component({
  selector: "sgr-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  @Input() logado: boolean = true;
  variaveisGlobais;
  // @Output() respostaVariaveisGlobais = new EventEmitter();
  nomeUsuario: string;
  usuarioAutenticado: boolean;
  entidadeRepresentada: Entidade;
  showMenu: boolean;
  // entidadeMtrcc: boolean = false;
  // enderecosEntidadeRepresentada: EnderecoEntidade[] = [];
  // veiculosEntidadeRepresentada: Veiculo[] = [];
  // pessoasFisicasEntidadeRepresentada: PessoaFisica[] = [];

  selectedDocumento: String;
  showMTRCC: boolean = false;
  showGG: boolean = false;
  showGDR: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private keycloakService: KeycloakService,
    private globals: Globals
  ) {}

  ngOnInit() {
    this.usuarioAutenticado = this.keycloakService.authenticated();
    this.variaveisGlobais = this.globals;
    if (this.usuarioAutenticado) {
      this.keycloakService.getUser().subscribe(authuser => {
        this.nomeUsuario = authuser.fullName;
        this.usuarioAutenticado = true;
        // this.entidadeMtrcc = false;
      });
    }

    if (this.globals.entidade == null) {
      this.globals.recuperaKeycloak().subscribe(user => {
        if (this.globals.roleEntidade) {
          this.globals.recuperaEntidadePessoaFisica().subscribe(entidade => {
            this.entidadeRepresentada = this.globals.entidade;
            // console.log("................finalizou..............");
          });
        }
      });
    }
    this.variaveisGlobais.menuRaiz = this.router.url.split('/')[1];
    // console.log('...........Input Funcionou.........>>', this.variaveisGlobais);

  }

  toggleMenu() {
    this.showMenu = !this.showMenu;

    if (this.showMenu) {
      window.document.querySelector("body").classList.add("menu-open");
    } else {
      window.document.querySelector("body").classList.remove("menu-open");
    }
  }

  logout() {
    const url =
      document.location.protocol +
      "//" +
      document.location.hostname +
      ":" +
      document.location.port;
    this.keycloakService.getKeycloakInstance().logout({ redirectUri: url });
  }

  verInfoMTRCC() {
    this.showMTRCC = true;
  }

  verInfoGG() {
    this.showGG = true;
  }

  verInfoGDR() {
    this.showGDR = true;
  }

}
