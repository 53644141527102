import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProdutoService = /** @class */ (function () {
    function ProdutoService(http) {
        this.http = http;
    }
    // getProdutos(): Observable<Produto[]> {
    //   return this.http.get<Produto[]>(BACKEND_URL);
    // }
    ProdutoService.prototype.getProdutos = function (tipoAceso, isEntidade) {
        if (isEntidade) {
            return this.http.get(environment.apiUrl + '/produtos/tipoAcessoEmpresa/' + tipoAceso);
        }
        else {
            return this.http.get(environment.apiUrl + '/produtos/tipoAcessoSecretaria/' + tipoAceso);
        }
    };
    ProdutoService.prototype.getProdutosDoContrato = function (idContrato, isEntidade) {
        if (isEntidade) {
            return this.http.get(environment.apiUrl + '/produtos/doContrato/' + idContrato);
        }
        else {
            // return this.http.get<Produto[]>(environment.apiUrl + '/produtos/tipoAcessoSecretaria/' + tipoAceso);
        }
    };
    ProdutoService.prototype.getProduto = function (id) {
        return this.http.get(environment.apiUrl + '/produtos/');
    };
    ProdutoService.prototype.getProdutoNome = function (nome) {
        return this.http.get(environment.apiUrl + '/produtos/nome/' + nome);
    };
    ProdutoService.prototype.criarProduto = function (produto) {
        return this.http.post(environment.apiUrl + '/produtos/', produto);
    };
    ProdutoService.prototype.editarProduto = function (produto) {
        return this.http.put(environment.apiUrl + '/produtos/', produto);
    };
    ProdutoService.prototype.excluirProduto = function (produto, tipoAcesso) {
        return this.http.delete(environment.apiUrl + '/produtos/deletar/' + produto.id + '/' + tipoAcesso);
    };
    ProdutoService.prototype.getProdutosContrato = function (idContrato) {
        return this.http.get(environment.apiUrl + '/produtos/contrato/' + idContrato);
    };
    ProdutoService.ngInjectableDef = i0.defineInjectable({ factory: function ProdutoService_Factory() { return new ProdutoService(i0.inject(i1.HttpClient)); }, token: ProdutoService, providedIn: "root" });
    return ProdutoService;
}());
export { ProdutoService };
