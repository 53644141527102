import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BlockUIModule } from "primeng/blockui";
import { CheckboxModule } from "primeng/checkbox";
// import { MessageService } from 'primeng/components/common/messageservice';
import { GrowlModule } from "primeng/growl";
import { MessageModule } from "primeng/message";
import { MessagesModule } from "primeng/messages";
import {
  AccordionModule,
  AutoCompleteModule,
  ButtonModule,
  CalendarModule,
  CardModule,
  DialogModule,
  DropdownModule,
  EditorModule,
  FieldsetModule,
  FileUploadModule,
  InputMaskModule,
  InputTextareaModule,
  InputTextModule,
  MenubarModule,
  MultiSelectModule,
  PanelModule,
  ProgressSpinnerModule,
  RadioButtonModule,
  TooltipModule
} from "primeng/primeng";
import { KeyFilterModule } from 'primeng/keyfilter';
import { TabViewModule } from 'primeng/tabview';
import { SelectButtonModule } from "primeng/selectbutton";
import { TableModule } from "primeng/table";
import { TabMenuModule } from "primeng/tabmenu";
import { ToolbarModule } from "primeng/toolbar";
import { CdlLibModule } from "procempa-cdl-lib";
import { ListaEntidadesComponent } from "./entidade/lista/lista.component";
import { ContratoComponent } from './dmlu/contrato/contrato.component';
// import { BrowserModule } from '@angular/platform-browser';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { CoreRoutingModule } from './core-routing.module';
import { FooterComponent } from "./footer/footer.component";
// import { MenuComponent } from "./menu/menu.component";
import { NaoEncontadaComponent } from "./nao-encontrada/nao-encontrada.component";
import { NotificacoesComponent } from "./notificacoes/notificacoes.component";
import { ListaPlanosComponent } from "./plano/plano.component";
import { MapaComponent } from "./mapa/mapa.component";
// import { ProdutoService } from './produto/produto.service';
// import { ClassificacaoService } from './services/classificacao.service';
// import { NotificacoesService } from './services/notificacoes.service';
// import { SmfPessoaService } from './services/smfPessoa.service';
// import { SpinnerService } from './services/spinner.service';
import { SpinnerComponent } from "./spinner/spinner.component";
import { SGRPipesModule } from "../pipes/pipes.module";
import { ListaProdutosComponent } from "./produto/lista/lista.component";
import { ManifestoModule } from "../manifesto/manifesto.module";
import { HeaderComponent } from "./header/header.component";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DestinoFinalComponent } from "./destinoFinal/destinoFinal.component";
import { SecretariaPmpaComponent } from "./secretariaPmpa/secretariaPmpa.component";
import { UsuariosPmpaComponent } from "./usuariosPmpa/usuariosPmpa.component";
import { ObraPmpaComponent } from "./obraPmpa/obraPmpa.component";
import { VeiculoPmpaComponent } from "./veiculoPmpa/veiculoPmpa.component";
import { LicencaAmbientalComponent } from './licencaAmbiental/licencaAmbiental.component';
import { CertificadoComponent } from './dmlu/certificado/certificado.component';
import { MenuModuleSGR } from "../menu/menu.module";
import { UsuarioComponent } from "./usuario/usuario.component";
import { BalancaComponent } from "./dmlu/balanca/balanca.component";

@NgModule({
  imports: [
    CommonModule,
    // BrowserAnimationsModule,
    ButtonModule,
    MenubarModule,
    BrowserModule,
    BrowserAnimationsModule,
    CardModule,
    GrowlModule,
    BlockUIModule,
    ProgressSpinnerModule,
    // BrowserModule,
    // FormsModule,
    // ReactiveFormsModule,
    ToolbarModule,
    SelectButtonModule,
    CheckboxModule,
    TabMenuModule,
    EditorModule,
    MessageModule,
    MessagesModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    PanelModule,
    InputMaskModule,
    TabViewModule,
    InputTextModule,
    AccordionModule,
    FieldsetModule,
    DropdownModule,
    CdlLibModule,
    DialogModule,
    FileUploadModule,
    MultiSelectModule,
    InputTextareaModule,
    RadioButtonModule,
    CalendarModule,
    AutoCompleteModule,
    SGRPipesModule,
    ManifestoModule,
    TooltipModule,
    BsDropdownModule,
    TabViewModule,
    MenuModuleSGR,
    KeyFilterModule
  ],
  declarations: [
    FooterComponent,
    NotificacoesComponent,
    SpinnerComponent,
    NaoEncontadaComponent,
    ListaEntidadesComponent,
    ListaPlanosComponent,
    ListaProdutosComponent,
    DestinoFinalComponent,
    SecretariaPmpaComponent,
    UsuariosPmpaComponent,
    ObraPmpaComponent,
    VeiculoPmpaComponent,
    LicencaAmbientalComponent,
    ContratoComponent,
    CertificadoComponent,
    MapaComponent,
    HeaderComponent,
    BalancaComponent,
    UsuarioComponent
  ],
  exports: [
    NotificacoesComponent,
    SpinnerComponent,
    HeaderComponent
  ]
})
export class CoreModule { }
