import { Entidade } from './entidade.model';

export class ContratoDescargaResiduo {
  id: number;
  timestampAsString: string;
  dataInclusao: string;
  usuarioInclusao: string;
  usuarioAlteracao: string;
  entidade: Entidade;
	placaVeiculo: string;
	pesoBruto: number;
	pesoTara: number;
	pesoLiquido: number;
	dataDescargaInicio: Date;
	dataDescargaFinal: Date;
	codigoBalanca: number;
	operador: string;
	lancador: string;
	numResiduo: number;
	nomeResiduo: string;
	numColeta: number;
	nomeColeta: string;
	numDestino: number;
	nomeDestino: string;
	nomeEmpresa: string;
	numEmpresa: number;
	empresa: string;
	total: number;
}

export interface ContratoDescargaResiduoInter {
  id: number;
  timestampAsString: string;
  dataInclusao: string;
  usuarioInclusao: string;
  usuarioAlteracao: string;
  entidade: Entidade;
	placaVeiculo: string;
	pesoBruto: number;
	pesoTara: number;
	pesoLiquido: number;
	dataDescargaInicio: Date;
	dataDescargaFinal: Date;
	codigoBalanca: number;
	operador: string;
	lancador: string;
	numResiduo: number;
	nomeResiduo: string;
	numColeta: number;
	nomeColeta: string;
	numDestino: number;
	nomeDestino: string;
	nomeEmpresa: string;
	numEmpresa: number;
	empresa: string;
	total: number;
}
