var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { MenuItem } from 'primeng/api';
import { EntidadeService } from '../entidade/entidade.service';
import { SpinnerService } from '../services/spinner.service';
import { NotificacoesService } from '../services/notificacoes.service';
import { Globals } from 'src/app/globals';
import { ObraPmpa } from 'src/app/@shared/models/obraPmpa.model';
import { ObraPmpaService } from '../services/obraPmpa.service';
import { SmfPessoaService } from '../services/smfPessoa.service';
var ObraPmpaComponent = /** @class */ (function () {
    function ObraPmpaComponent(router, keycloakService, obraPmpaService, entidadeService, spinnerService, notificacoesService, smfPessoaService, globals) {
        this.router = router;
        this.keycloakService = keycloakService;
        this.obraPmpaService = obraPmpaService;
        this.entidadeService = entidadeService;
        this.spinnerService = spinnerService;
        this.notificacoesService = notificacoesService;
        this.smfPessoaService = smfPessoaService;
        this.globals = globals;
        this.obrasPmpa = [];
        this.entidadeNome = '';
        this.cnpjExecutora = '';
        this.enderecoRes = {};
        this.activeRoute = this.router.url;
        this.variaveisGlobais = globals;
    }
    ObraPmpaComponent.prototype.ngOnInit = function () {
        if (this.variaveisGlobais.menuRaiz === null || this.variaveisGlobais.menuRaiz === '') {
            this.router.navigate(['/sgrIni']);
        }
        else {
            this.inicializaOnInit();
        }
    };
    ObraPmpaComponent.prototype.inicializaOnInit = function () {
        this.spinnerService.exibir();
        this.obraPmpaEdit = new ObraPmpa();
        this.endereco = null;
        this.enderecoRes = null;
        this.logradouroCdl = null;
        this.localizacao = null;
        this.logradouro = null;
        this.numero = null;
        // this.obraPmpaService.getObrasPmpa().subscribe(obrasPmpa => {
        //   this.spinnerService.ocultar();
        //   this.obrasPmpa = obrasPmpa;
        //   console.log('inicializaOnInit>>>>>OBRAS>>>>>>>>>', this.obrasPmpa);
        // });
        this.buscaObras();
    };
    ObraPmpaComponent.prototype.buscaObras = function () {
        var _this = this;
        this.obrasPmpa = [];
        this.obraPmpaService.getObrasPmpa().subscribe(function (obrasPmpa) {
            _this.spinnerService.ocultar();
            _this.obrasPmpa = obrasPmpa;
            console.log('inicializaOnInit>>>>>OBRAS>>>>>>>>>', _this.obrasPmpa);
        });
    };
    ObraPmpaComponent.prototype.logout = function () {
        var url = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
        this.keycloakService.getKeycloakInstance().logout({ redirectUri: url });
    };
    ObraPmpaComponent.prototype.mostrarDialogParaNovo = function () {
        this.obraPmpaEdit = new ObraPmpa();
        this.obraPmpaEdit.status = 'Ativa';
        this.mostrarDialogEdit = true;
        this.isInsert = true;
        this.tituloDialogEdit = 'Nova Obra';
    };
    ObraPmpaComponent.prototype.mostrarDialogParaEditar = function (idObraPmpa) {
        var obraPmpaParaEditar = this.obrasPmpa.find(function (obraPmpa) {
            return obraPmpa.id === idObraPmpa;
        });
        this.obraPmpaEdit = __assign({}, obraPmpaParaEditar);
        console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.obraPmpaEdit);
        this.mostrarDialogEdit = true;
        this.isInsert = false;
        this.tituloDialogEdit = 'Editar Obra';
    };
    ObraPmpaComponent.prototype.mostrarDialogEditParaExcluir = function (idProduto) {
        // this.produtoExcluir =  this.produtos.find(function(produto) {
        //   return produto.id === idProduto;
        // });
        // this.mostrarDialogExcluir = true;
    };
    ObraPmpaComponent.prototype.salvar = function () {
        var _this = this;
        //Verifica se todos os campos foram preenchidos
        if (this.obraPmpaEdit.nome == null || this.obraPmpaEdit.nome === '') {
            this.notificacoesService.notificarAviso('Por favor informar o NOME da Obra!', null);
            return;
        }
        if (this.obraPmpaEdit.status == null) {
            this.notificacoesService.notificarAviso('Por favor informar a STATUS da Obra!', null);
            return;
        }
        // if (this.obraPmpaEdit.endereco == null || this.obraPmpaEdit.endereco === '') {
        //   this.notificacoesService.notificarAviso('Por favor informar o ENDEREÇO da Secretaria/Órgão!', null);
        //   return ;
        // }
        this.spinnerService.exibir();
        console.log('salvar....................>>>>>>>>>>>>>>', this.obraPmpaEdit);
        // this.obraPmpaEdit.entidadeDO = this.entidadePMPA;
        // Se novo
        if (this.obraPmpaEdit.id == null) {
            this.obraPmpaService.criarObraPmpa(this.obraPmpaEdit).subscribe(function (obraPmpaNovo) {
                console.log('salvado....................>>>>>>>>>>>>>>', obraPmpaNovo);
                _this.obrasPmpa.push(obraPmpaNovo);
                _this.mostrarDialogEdit = false;
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarSucesso('Adicionado com sucesso', null);
                _this.buscaObras();
            }, function (error) {
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro('Erro ao adicionar ', error);
            });
        }
        else { // Se editando
            this.obraPmpaService.editarObraPmpa(this.obraPmpaEdit).subscribe(function (obraPmpaEditado) {
                // Remove o valor atual
                _this.obrasPmpa = _this.obrasPmpa.filter(function (item) { return item.id !== _this.obraPmpaEdit.id; });
                // Adiciona o novo
                _this.obrasPmpa.push(obraPmpaEditado);
                _this.mostrarDialogEdit = false;
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarSucesso('Atualizado com sucesso', null);
                _this.buscaObras();
            }, function (error) {
                console.log(error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    ObraPmpaComponent.prototype.excluir = function () {
        // this.spinnerService.exibir();
        // console.log('salvar....................>>>>>>>>>>>>>>', this.variaveisGlobais.menuRaiz);
        // this.produtoService.excluirProduto(this.produtoExcluir, this.variaveisGlobais.menuRaiz).subscribe(produto => {
        //   if (produto == null) {
        //     // Remove o valor atual
        //     this.produtos = this.produtos.filter(item => item.id !== this.produtoExcluir.id);
        //   }
        //   this.mostrarDialogExcluir = false;
        //   this.spinnerService.ocultar();
        //   this.notificacoesService.notificarSucesso('Excluído com sucesso', null);
        // },
        // error => {
        //   console.log(error);
        //   this.spinnerService.ocultar();
        //   this.notificacoesService.notificarErro('Erro ao exluir', error.error);
        // });
    };
    ObraPmpaComponent.prototype.checkActive = function (route) {
        return route === this.activeRoute;
    };
    ObraPmpaComponent.prototype.selecionaCDL = function (endereco) {
        this.localizacao = { latitude: null, longitude: null };
        if (endereco.latitude !== undefined && endereco.latitude !== null) {
            this.localizacao = {
                latitude: endereco.latitude,
                longitude: endereco.longitude
            };
            delete endereco['latitude'];
            delete endereco['longitude'];
        }
        this.obraPmpaEdit.endereco = endereco;
        this.obraPmpaEdit.endereco.localizacao = this.localizacao;
    };
    ObraPmpaComponent.prototype.buscaEntidade = function (obraPmpa) {
        var _this = this;
        console.log('BuscaEntidade0000>>>>>>>', obraPmpa.cnpjExecutora);
        var cnpj = obraPmpa.cnpjExecutora;
        if (cnpj === '__.___.___/____-__') {
            //      console.log('BuscaEntidade0000>>>>>>>', cnpj);
            cnpj = null;
        }
        if (cnpj == null || cnpj === '') {
            // this.spinnerService.ocultar();
            // this.notificacoesService.notificarErro('Não encontrou entidade para CNPJ informado!', null);
        }
        else {
            // this.spinnerService.exibir();
            // const novoCnpj = cnpj.replace(/[^\d]+/g, '');
            // console.log('BuscaEntidade00111111111>>>>>>>', novoCnpj);
            // this.smfPessoaService.getPessoaFazenda(novoCnpj, '2').subscribe(pessoa => {
            //   console.log('BuscaEntidade1111>>>>>>>', pessoa);
            //   this.spinnerService.ocultar();
            // });
            this.obraPmpaEdit.executora = null;
            this.entidadeService.getEntidadeCnpj(cnpj).subscribe(function (entidade) {
                if (entidade == null) {
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro('Não localizada empresa para o CNPJ informado no Sistema SGR.' +
                        ' Empresa existe no cadastro da Prefeitura Municipal de Porto Alegre – PMPA.' +
                        ' Favor solicitar o cadastramento no SGR.', null);
                }
                else {
                    _this.obraPmpaEdit.executora = entidade;
                    _this.entidadeExecutora = entidade;
                    console.log('BuscaEntidade2222>>>>>>>', entidade);
                }
            }, function (error) {
                console.log(error);
                console.log(error.error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
    };
    return ObraPmpaComponent;
}());
export { ObraPmpaComponent };
