import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { MenuItem, SelectItem } from 'primeng/api';
import { FileUpload } from 'primeng/primeng';
import { PlanoService } from './plano.service';
import { Entidade } from 'src/app/@shared/models/entidade.model';
import { EnderecoEntidade } from 'src/app/@shared/models/enderecoEntidade';
import { Veiculo } from 'src/app/@shared/models/veiculo.model';
import { PessoaFisica } from 'src/app/@shared/models/pessoaFisica.model';
import { Plano, InfoPlano } from 'src/app/@shared/models/plano.model';
import { Termo } from 'src/app/@shared/models/termo.model';
import { Participacao } from 'src/app/@shared/models/participacao.model';
import { Produto } from 'src/app/@shared/models/produto.model';
import { PontoDeColeta } from 'src/app/@shared/models/coleta.model';
import { Anexo } from 'src/app/@shared/models/anexo.model';
import { LogradouroCdl } from 'procempa-cdl-lib';
import { Endereco } from 'src/app/@shared/models/endereco';
import { Localizacao } from 'src/app/@shared/models/localizacao';
import { EntidadeService } from '../entidade/entidade.service';
import { EnderecoService } from '../services/endereco.service';
import { PessoaFisicaService } from '../services/pessoaFisica.service';
import { SpinnerService } from '../services/spinner.service';
import { NotificacoesService } from '../services/notificacoes.service';
import { SmfPessoaService } from '../services/smfPessoa.service';
import { AnexoService } from '../services/anexo.service';
import { Globals } from 'src/app/globals';
import { TipoUnidadeMedida, Meta } from 'src/app/@shared/models/meta.model';
import { environment } from 'src/environments/environment';
import { ProdutoService } from '../produto/produto.service';
import { PlanoPrevistoRealizado } from 'src/app/@shared/models/planoPrevistoRealizado.model';
import { PlanoMeta } from 'src/app/@shared/models/planoMeta.model';
import { SecretariaPmpa } from 'src/app/@shared/models/secretariaPmpa.model';
import { SecretariaPmpaService } from '../services/secretariaPmpa.service';
import { LicencaAmbientalService } from '../services/licencaAmbiental.service';
import { LicencaAmbiental } from 'src/app/@shared/models/licencaAmbiental.model';


@Component({
  selector: 'sgr-plano-list',
  templateUrl: './plano.component.html',
  styleUrls: ['./plano.component.scss']
})
export class ListaPlanosComponent implements OnInit {

  submitted = false;
  residuoForm: FormGroup;
  isDadosTranspInvalidos: Boolean = true;
  isDadosDestinInvalidos: Boolean = true;
  activeRoute: string;

  items: MenuItem[];
  nomeUsuario: string;
  // usuarioAutenticado: boolean;

  // @Input() entidade: Entidade;
  entidade: Entidade;
  entidadeId = null;
  entidadeTransporte: Entidade;
  entidadeDestino: Entidade;
  endsEntidadeRepresentada: EnderecoEntidade[] = [];
  endsEntidadeGerador: EnderecoEntidade[] = [];
  endsEntidadeTransportador: EnderecoEntidade[] = [];
  endsEntidadeDestinador: EnderecoEntidade[] = [];
  veisEntidadeRepresentada: Veiculo[] = [];
  veisEntidadeTransportador: Veiculo[] = [];
  pessoaFisica: PessoaFisica;
  rtsElaboracaoEmpresa: PessoaFisica[] = [];
  rtsExecucaoEmpresa: PessoaFisica[] = [];
  // rtsElaboracaoPessoaRT: any[] = [];
  // rtsExecucaoPessoaRT: any[] = [];
  // elaboracaoPessoaRT: any = {
  //   id: null,
  //   nomePessoa: null,
  //   anexoRT: null,
  //   idAnexo: null
  // };
  // pessoaFisicaRTEx: PessoaFisica = {
  //   id: null,
  //   idEntidade: null,
  //   email: null,
  //   telefone: null,
  //   celular: null,
  //   nomeCompleto: null,
  //   cpf: null,
  //   rtElaboracao: false,
  //   rtExecucao: false
  // };
  planos: Plano[];
  planoListaMeta: PlanoMeta;
  planosMeta: PlanoMeta[] = [];
  planoSimNao: Boolean = false;
  temPlano: string;
  planoPrevistoRealizado: PlanoPrevistoRealizado = <PlanoPrevistoRealizado> {};
  planoAnoAnterior: String = '';
  planoAnoAtual: String = '';
  planosPrevistoRealizado = [];
  tituloPrevistoRealizado = '';
  salvaPrevistoRealizado: Boolean = false;
  unidadeMedida = '';
  temRTElaboracao: Boolean = false;
  temRTElaboracaoPF: Boolean = false;
  temRTExecucao: Boolean = false;
  justificarAnulacao: Boolean = false;
  produtozz = '';
  adicionarResiduosOkRTecnicos: Boolean = null;
  adicionarResiduosOkAnexos: Boolean = null;
  semPlanoResiduos: Boolean = null;
  mensagemAnexosEntidade;
  mensagemRespTecnicos = [];
  transportadorProprio: Boolean = false;
  codigoDMLU: Boolean = false;
  secretarias: SelectItem[];
  secretariaList: SecretariaPmpa[];
  secretaria: SecretariaPmpa;
  // Novo/edit
  insertMode = false;
  planoEdit: Plano;
  mostrarDialogEdit: boolean;
  mostrarDialogEditRealizado: Boolean = false;
  tituloDialogEdit: string;

  papeis: SelectItem[] = [];
  tiposEntidade: SelectItem[] = [];
  termo: Termo = { texto: '' };

  participacoes: Participacao[] = [];

  produto: Produto;
  produtos: Produto[];
  produtoSelecionado: Produto;
  prods: SelectItem[] = [];
  pontosDeColeta: PontoDeColeta[] = [];
  metas: any[] = [];
  metaPlano: Meta;

  licencaAmbiental: LicencaAmbiental;
  licencasAmbientais: LicencaAmbiental[] = [];

  // Dados para anexos
  @ViewChild('anexoUpload') fileUpload: FileUpload;
  anexo: Anexo;
  anexoPlano: Anexo;
  anexoRTElaboracao: Anexo;
  anexoRTExecucao: Anexo;
  anexosPlanoResiduo: Anexo[];
  anexosRTElaboracao: Anexo[];
  anexosRTExecucao: Anexo[];
  anexos: Anexo[];
  novoAnexoDescricao: string;
  anexoSelPlano: String = '';
  anexoSelRTElaboracao: String = '';
  anexoSelRTExecucao: String = '';



  // Exluir
  planoExcluir: Plano;
  mostrarDialogExcluir: boolean;
  tituloTela: string;
  tituloCard: string;

  //Pesquisa
  pesquisaReferencia: number = null;


//CDL
  // logradouro previo
  logradouroCdl: LogradouroCdl;
  enderecoColetaResiduo: Endereco;
  localizacao: Localizacao;
  // mostra input logradouro, cep e número
  isCompleto = true;
  logradouro;
  numero;

  variaveisGlobais;
  mensagemValidacao = '';

  index: number = 0;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private planoService: PlanoService,
    private produtoService: ProdutoService,
    private entidadeService: EntidadeService,
    private enderecoService: EnderecoService,
    private pessoaFisicaService: PessoaFisicaService,
    private spinnerService: SpinnerService,
    private keycloakService: KeycloakService,
    private notificacoesService: NotificacoesService,
    private secretariaPmpaService: SecretariaPmpaService,
    private anexoService: AnexoService,
    private licencaService: LicencaAmbientalService,
    private globals: Globals) {

      this.activeRoute = this.router.url;

      this.variaveisGlobais = globals;
      console.log("Construtor ListaPlanosComponent snapshot>>>>>>>>>>>", route.snapshot);
      this.entidadeId = route.snapshot.params['id'];
     }

  ngOnInit() {
     console.log('ngOnInit---------ListaEntidadesComponent--------------->>>', this.globals);
    this.createResiduoForm();


    this.variaveisGlobais = this.globals;
    this.variaveisGlobais.menuRaiz = this.router.url.split('/')[1];

   if (this.globals.pessoaFisica == null) {

    this.globals.recuperaKeycloak().subscribe(
      user => {

        if (this.globals.roleEntidade) {
          this.globals.recuperaEntidadePessoaFisica().subscribe(
            entidade => {
              this.variaveisGlobais = this.globals;
               console.log('--------------------------->>>>>00');
               this.inicializaEntidadeSelecionada();
              });

        } else {
          console.log('--------------------------->>>>>11');
          this.inicializaEntidadeSelecionada();
        }
      });

    } else {
       console.log('--------------------------->>>>>22');
       this.inicializaEntidadeSelecionada();
      }
  }
  inicializaEntidadeSelecionada() {

    this.entidadeService.getEntidadePorId(this.entidadeId).subscribe(entidade => {
      this.entidade = entidade;
      this.spinnerService.ocultar();
      
      if (entidade != null) {
        // console.log('inicializaEntidadeSelecionada ..... SMAMS/DMLU.....>>>>>>>', entidade);
        this.entidade = entidade;
        this.buscaEnderecoEntidade(entidade, null);
        this.inicializaOnInit();
      }

    //NO MOMENTO SÓ VALE PARA GRANDESGERADORES E SECRETARIA

      this.nomeUsuario = this.variaveisGlobais.userFullName;
      this.pessoaFisica = this.variaveisGlobais.pessoaFisica;
      this.variaveisGlobais.entidade = this.entidade;
      this.variaveisGlobais.enderecosEntidade = this.endsEntidadeRepresentada;

    },
    error => {
      console.log(error);
      console.log(error.error);
      this.spinnerService.ocultar();
      this.notificacoesService.notificarErro(error.error, null);
    });
  }

  inicializaOnInit() {



//ATUALIZA TABELA PLANOANEXO E PLANOPESSOAFISICA *******************************************************
// this.planoService.atualizaPlanoAnexoPessoaFisica().subscribe(
//   atualizado => {
//     console.log("atualizaPlanoAnexoPessoaFisica>>>>>>>>>");
//   },
//   error => {
//     console.log('ERRO...............>>>>>>', error);
//     this.spinnerService.ocultar();
//     this.notificacoesService.notificarErro(error, null);
//   }
// );
//ATUALIZA TABELA PLANOANEXO E PLANOPESSOAFISICA *******************************************************










//FUNCIONAL PARA GRANDES GERADORES


    this.spinnerService.exibir();

    this.controlaMenu();

    this.tituloTela = 'Resíduos Gerados pela Empresa ' + this.entidade.razaoSocial;
    this.adicionarResiduosOkRTecnicos = false;
    this.adicionarResiduosOkAnexos = false;
    this.semPlanoResiduos = false;
    this.mensagemRespTecnicos = [];
    this.spinnerService.exibir();
    this.pesquisaReferencia = new Date().getFullYear();

    if (this.variaveisGlobais.roleEntidade) {
      this.anexoService.getAnexoPorTipo(this.entidade.id, 'Plano Resíduo').subscribe(anexoPR => {
        console.log('.........getAnexoPorTipo --- inicializaOnInit >>>>>>>>', anexoPR);
        if (anexoPR === null || anexoPR.length < 1) {
          this.adicionarResiduosOkRTecnicos = true;
          this.adicionarResiduosOkAnexos = true;
          this.semPlanoResiduos = true;
        } else {
          this.pessoaFisicaService.getPessoaFisicaRTPorEntidade(this.entidade.id, 'elaboracao').subscribe(pessoasFRTEl => {
            if (pessoasFRTEl.length < 1) {
              this.mensagemRespTecnicos.push('Responsável Técnico pela Elaboração do plano');
            }


            this.pessoaFisicaService.getPessoaFisicaRTPorEntidade(this.entidade.id, 'execucao').subscribe(pessoasFRTEx => {
              // console.log('11.........ANEXOPLANO --- inicializaOnInit >>>>>>>>', pessoasFRTEx.length);
              if (pessoasFRTEx.length > 0) {
                if (this.mensagemRespTecnicos.length < 1) {
                  this.adicionarResiduosOkRTecnicos = true;
                  console.log('11.........adicionarResiduosOkRTecnicos --- inicializaOnInit >>>>>>>>', this.adicionarResiduosOkRTecnicos);
                }
              } else {
                this.mensagemRespTecnicos.push('Responsável Técnico pela Execução do plano');
              }

                //para adicionar resíduos empresa deve ter anexado alguns documentos ... faz essa consistência
                this.anexoService.possuiAnexosObrigatorios(this.entidade.id, this.variaveisGlobais.menuRaiz).subscribe(mensagem => {
                  this.spinnerService.ocultar();

                  if (mensagem.length > 0) {
                    this.mensagemAnexosEntidade = mensagem;
                    this.adicionarResiduosOkAnexos = false;
                    console.log('22.........adicionarResiduosOkAnexos --- inicializaOnInit >>>>>>>>', this.adicionarResiduosOkAnexos);
                  } else {
                    this.adicionarResiduosOkAnexos = true;
                    console.log('33.........adicionarResiduosOkAnexos --- inicializaOnInit >>>>>>>>', this.adicionarResiduosOkAnexos);
                  }
                },
                error => {
                  this.spinnerService.ocultar();
                  this.notificacoesService.notificarErro(error, null);
                });
            },
            error => {
              this.spinnerService.ocultar();
              this.notificacoesService.notificarErro(error.message, null);
            });
          },
          error => {
            this.spinnerService.ocultar();
            this.notificacoesService.notificarErro(error.message, null);
          });
        }
      },
      error => {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.message, null);
      });
    }

    this.planosMeta = [];
    console.log('PLANO --- inicializaOnInit .......................>>>>>>>>', this.entidade.id);

    this.buscaPlanosEntidadeAno(this.pesquisaReferencia);

    // Carrega os produtos
    this.produtos = [];
    this.produtoService.getProdutos(this.variaveisGlobais.menuRaiz, this.variaveisGlobais.roleEntidade).subscribe(produtos => {
      console.log('Planos Inicializa Produtos>>>>>>>>>>>>>>', produtos);

      this.spinnerService.ocultar();
      this.produtos = produtos;
      this.produtos.sort(function (a, b) {
        if (a.codigo > b.codigo) {
          return 1;
        }
        if (a.codigo < b.codigo) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      console.log('....................Planos Inicializa Produtos>>>>>>>>>>>>>>', this.produtos);

      //      this.prods.push({ label: '', value: null });
      this.prods = [];
      for (const o of this.produtos) {
        const si = { label: o.nome, value: o.descricao };
        this.prods.push(si);
      }
    },
      error => {
        this.notificacoesService.notificarErro('Erro ao obter produtos', error.message);
      }
    );

      // Carrega lista de secretarias
      this.secretarias = [];
      this.secretariaPmpaService.getSecretariasPmpa().subscribe(secretarias => {
        this.secretariaList = secretarias;
        this.secretarias.push( {label: 'Selecione', value: ''});
        secretarias.forEach(sec => {
          this.secretarias.push( {label: sec.sigla, value: sec.sigla});
        });
      },
      error => {
          console.log(error);
          console.log(error.error);
          this.spinnerService.ocultar();
          this.notificacoesService.notificarErro(error.error, null);
      });

  }

  buscaPlanosEntidadeAno(anoReferencia) {
    // console.log('--------------------------', anoReferencia);

    if (anoReferencia !== undefined && anoReferencia !== '' && anoReferencia !== null) {

      this.planoService.getPlanosDaEntidade(this.entidade.id, anoReferencia).subscribe(planos => {
        this.planos = planos;
        console.log('--------------------------', this.planos);

        this.atualizaPlanosNovoAno();

        ///buscar metas

        planos.forEach(plano => {
          this.metasPlanoPorAno(plano, true, this.pesquisaReferencia);
        });

      },
      error => {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.message, null);
      });
    }
  }

  atualizaPlanosNovoAno() {

    // ******* Na virada do ano fiscal, no mês de janeiro ************


    if (new Date().getMonth() === 1) { // MÊS DE FEVEREIRO --- MUDAR

      // this.mostrarDialogEditRealizado = false;
      // if (this.mostrarDialogEditRealizado) {

      if (this.variaveisGlobais.roleEntidade) {
        if (this.planos.length < 1) {
          console.log('PLANO --- inicializaOnInit >>>>>>>>', this.planos);
          this.mostrarDialogEditRealizado = true;
          this.planoAnoAtual = (new Date().getFullYear()).toString();
          let indice = 0;
          this.planoService.getPlanosDaEntidadeAnoAnterior(this.entidade.id).subscribe(planosAA => {
            // this.planos = planosAA;
            console.log('PLANO --- inicializaOnInit >>>>>>>>', planosAA);

            if (planosAA.length > 0) {
              planosAA.forEach(plano => {
                // this.planoPrevistoRealizado = new PlanoPrevistoRealizado();
                this.planoPrevistoRealizado = <PlanoPrevistoRealizado>{};

                this.unidadeMedida = '';
                this.planoService.getMetaDoPlano(plano.id).subscribe(meta => {

                  if (indice === 0 ) {
                    this.planoAnoAnterior = meta.ano.toString();
                    this.tituloPrevistoRealizado = 'Informar quantidades realizadas para o ano de ' + this.planoAnoAnterior
                    + ' e estimativa para o ano de ' + this.planoAnoAtual;
                    indice = 10;
                  }

                  this.planoPrevistoRealizado.idPlano = plano.id;
                  this.planoPrevistoRealizado.nomeResiduo = plano.produtoDO.nome;
                  if (meta.unidadeMedidaPrevista.toString() === 'MetroCubico') {
                    this.unidadeMedida = ' metros cúbicos';
                    this.planoPrevistoRealizado.unidadeMedida = TipoUnidadeMedida.MetroCubico;
                  }
                  if (meta.unidadeMedidaPrevista.toString() === 'Tonelada') {
                    this.unidadeMedida = ' toneladas';
                    this.planoPrevistoRealizado.unidadeMedida = TipoUnidadeMedida.Tonelada;
                  }
                  this.planoPrevistoRealizado.qtPrevistaAnterior = meta.metaAnualPrevista.toString() + this.unidadeMedida;
                  this.planoPrevistoRealizado.qtRealizadaAnterior = null;
                  this.planoPrevistoRealizado.qtPrevistaAtual = null;
                  this.planosPrevistoRealizado.push(JSON.parse(JSON.stringify(this.planoPrevistoRealizado)));

                });

              });
            } else {
              this.mostrarDialogEditRealizado = false;
            }
          },
          error => {
            this.spinnerService.ocultar();
            this.notificacoesService.notificarErro(error.message, null);
          });
        }
      }
    }

  }




  inicializaEntidade() {

    let inicializaEntidade: Entidade = null;
    return inicializaEntidade = {
      id: null,
      cnpj: null,
      razaoSocial: '',
      dataInclusao: null,
      timestampAsString: null,
      usuarioInclusao: null,
      usuarioAlteracao: null,
      // nomeSisLog: null,
      licencaAmbiental: null,
      licencaAmbientalOrgaoEmissor: null,
      licencaAmbientalDataValidade: null,
      alvara: null,
      gdrNumeroContrato: null,
      gdrAnoContrato: null,
      gdrSituacaoContrato: null,
      gdrCertificado: null,
      codigoDmlu: null,
      adimplenteDmlu: null,
      desabilitadaMtrcc: null
    };

  }

  controlaMenu() {

    // console.log("ListaPlanosComponent controlaMenu Router>>>>>>>>>>>", this.router);
    // console.log("ListaPlanosComponent controlaMenu VariaveisGlobais>>>>", this.variaveisGlobais);

    // if (this.keycloakService.hasRole('SMAMS') || this.keycloakService.hasRole('DMLU')) {
    //   if (this.variaveisGlobais.menuRaiz === 'grandeGerador') {
      this.items = [
        {
          label: '',
          icon: 'fa fa-home',
          routerLink: '/sgrIni'
        }
      ];

      let item =  {};
      if (this.variaveisGlobais.menuRaiz === 'grandeGerador') {
        item = {};
        item =  {
          label: 'Entidades',
          icon: 'fa fa-industry',
          routerLink: ['/', 'grandeGerador']
        };
        this.items.push(item);
        if (!this.variaveisGlobais.roleEntidade) {
          item = {};
          item =  {
            label: 'Produtos',
            icon: 'fa fa-industry',
            routerLink: ['/', 'grandeGerador', 'produtos']
          };
          this.items.push(item);
        }
      } else {
        if (this.variaveisGlobais.menuRaiz === 'mtrcc') {
          item = {};
          item =  {
            label: 'Entidades',
            icon: 'fa fa-industry',
            routerLink: ['/', 'mtrcc', 'entidades']
          };
          this.items.push(item);
          if (!this.variaveisGlobais.roleEntidade) {
            item = {};
            item =  {
              label: 'Produtos',
              icon: 'fa fa-industry',
              routerLink: ['/', 'mtrcc', 'produtos']
            };
            this.items.push(item);
          }
        }
      }

        //   this.items = [
        //   {
        //     label: '',
        //     icon: 'fa fa-home',
        //     routerLink: '/sgrIni'
        //   },
        //   {
        //     label: 'Entidades',
        //     icon: 'fa fa-industry',
        //     routerLink: '/grandeGerador'
        //   }
        // ];
  }

  logout() {
    const url = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
    this.keycloakService.getKeycloakInstance().logout({redirectUri:url});
  }

  createResiduoForm() {
    this.residuoForm = this.fb.group({
      tipoTransportador: ['poa'],
      tipoDestinador: ['poa'],
      anexoPlanoSel: ['transiente', Validators.required],
      rtElaboracaoSelPessoa: ['transiente', Validators.required],
      rtElaboracaoSelAnexo: ['transiente', Validators.required],
      rtExecucaoSelPessoa: ['transiente', Validators.required],
      rtExecucaoSelAnexo: ['transiente', Validators.required],
      produto: ['', Validators.required],
      quantidadePrevista: ['', Validators.required],
      unidadeQuantidadePrev: ['m³', Validators.required],
      quantidadeRealizada: [null],
      unidadeQuantidadeReal: ['m³', Validators.required],
      secretariaSel: [''],
      // logradouro: ['', Validators.required],
      // numero: ['', Validators.required],
      transportadorNome: [{ value: '', disabled: true}, Validators.required],
      transportadorCnpj: ['', Validators.required],
      // transportadorEndSel: ['TransienteSeFisica', Validators.required],
      // transportadorEndereco: [{ value: '', disabled: true}, Validators.required],
      // transportadorMunicipio: [{ value: '', disabled: true}, Validators.required],
      transportadorEmail: [{ value: '', disabled: true}],
      transportadorFone: [{ value: '', disabled: true}, Validators.required],
      transportadorCelular: [{ value: '', disabled: true}, Validators.required],
      // transportadorLicenca: [{ value: '', disabled: true}, Validators.required],
      // transportadorPlaca: [{ value: '', disabled: true}, Validators.required],
      // transportadorVeiSel: ['TransienteSeFisica', Validators.required],
      destinadorNome: [{ value: '', disabled: true}, Validators.required],
      destinadorCnpj: ['', Validators.required],
      destinadorEndSel: ['', Validators.required],
      destinadorEndereco: [{ value: '', disabled: true}, Validators.required],
      destinadorMunicipio: [{ value: '', disabled: true}, Validators.required],
      destinadorEmail: [{ value: '', disabled: true}],
      destinadorFone: [{ value: '', disabled: true}, Validators.required],
      destinadorCelular: [{ value: '', disabled: true}, Validators.required],
      destinadorLicenca: [{ value: '', disabled: true}, Validators.required],
      destinadorLicencaDataValidade: [{ value: '', disabled: true}, Validators.required],
      destinadorLicencaOrgaoEmissor: [{ value: '', disabled: true}, Validators.required],
      destinadorCodDmlu: [{ value: '', disabled: false}, Validators.required]
    });
  }

  get diagnostic() { return JSON.stringify(this.residuoForm.value); }

  onSubmit() {
    this.ngOnInit();
  }

  trocouOperador(event) {
  }

  // public getUnidade(plano: Plano) {
  //   return TipoUnidadeMedida[plano.produtoDO.unidadeMedida];
  // }

  mostrarDialogParaNovo() {
    this.planoEdit = {
      id: null,
      entidadeDO: null,
      secretariaPmpaDO: null,
      produtoDO: null,
      dataInclusao: null,
      timestampAsString: null,
      usuarioInclusao: null,
      usuarioAlteracao: null,
      dataAnulacao: null,
      usuarioAnulacao: null,
      justificativaAnulacao: null,
      codigoDMLU: null
    };
    this.codigoDMLU = false;
    this.createResiduoForm();
    // this.planoEdit['entidadeDO'] = this.entidade.razaoSocial;
    this.insertMode = true;
    this.tituloDialogEdit = 'Informar detalhes sobre geração e destino de resíduos.';
    this.produtoSelecionado = null;
    this.mostrarDialogEdit = true;
    this.endsEntidadeDestinador = [];

    // this.participacoes = [];
    // this.metas = [];
    // this.anexos = [];


    //recupera Planos de Resíduos da Empresa
    this.anexoService.getAnexoPorTipo(this.entidade.id, 'Plano Resíduo').subscribe(anexos => {

      console.log('................ANEXOPLANO --- mostrarDialogParaNovo >>>>>>>>', anexos);
      this.anexosPlanoResiduo = anexos;
      if (anexos.length > 0) {
        this.residuoForm.get('anexoPlanoSel').setValue(anexos[0].id);
      }
    },
    error => {
      this.notificacoesService.notificarErro(error.message, null);
    });

    //recupera RT de Elaboração
    this.pessoaFisicaService.getPessoaFisicaRTPorEntidade(this.entidade.id, 'elaboracao').subscribe(pessoasFRTEl => {
      this.spinnerService.ocultar();

      this.rtsElaboracaoEmpresa = pessoasFRTEl;
      if (pessoasFRTEl.length > 0) {
        this.residuoForm.get('rtElaboracaoSelPessoa').setValue(pessoasFRTEl[0].id);
      }

      this.anexoService.getAnexoPorTipo(this.entidade.id, 'RT Elaboração').subscribe(anexo => {
        this.anexosRTElaboracao = anexo;
        if (anexo.length > 0) {
          this.residuoForm.get('rtElaboracaoSelAnexo').setValue(anexo[0].id);
        }
      },
      error => {
        this.notificacoesService.notificarErro(error.message, null);
      });

    },
    error => {
      this.notificacoesService.notificarErro(error.message, null);
    });


    this.pessoaFisicaService.getPessoaFisicaRTPorEntidade(this.entidade.id, 'execucao').subscribe(pessoasFRTEx => {
      this.spinnerService.ocultar();
      this.rtsExecucaoEmpresa = pessoasFRTEx;
      if (pessoasFRTEx.length > 0) {
        this.residuoForm.get('rtExecucaoSelPessoa').setValue(pessoasFRTEx[0].id);
      }

      this.anexoService.getAnexoPorTipo(this.entidade.id, 'RT Execução').subscribe(anexo => {
        this.anexosRTExecucao = anexo;
        if (anexo.length > 0) {
          this.residuoForm.get('rtExecucaoSelAnexo').setValue(anexo[0].id);
        }
      },
      error => {
        this.notificacoesService.notificarErro(error.message, null);
      });

    },
    error => {
        this.notificacoesService.notificarErro(error.message, null);
    });





    //verifica se tem contrato com transportador
    this.anexoService.getAnexoPorTipo(this.entidade.id, 'Contrato Transporte').subscribe(anexo => {
      this.transportadorProprio = false;
      if (anexo.length < 1) {
        this.anexoService.getAnexoPorTipo(this.entidade.id, 'Contrato Conjunto').subscribe(anexoConjunto => {
          if (anexoConjunto.length < 1) {
            this.transportadorProprio = true;
            this.residuoForm.get('transportadorCnpj').setValue(this.entidade.cnpj);
            this.buscaEntidade(this.entidade.cnpj, 'transportador');
          }
        },
        error => {
          this.notificacoesService.notificarErro(error.message, null);
        });
      }
    },
    error => {
      this.notificacoesService.notificarErro(error.message, null);
    });



  }

  mostrarDialogParaEditar(idPlano: number) {
    for (const p of this.planos) {
      if (p.id === idPlano) {
        this.planoEdit = p;
        break;
      }
    }
    console.log('mostrarDialogParaEditar ... Plano...>>', this.planoEdit);

    this.codigoDMLU = false;
    this.produtoSelecionado = this.planoEdit['produtoDO'];
    this.insertMode = false;
    this.entidade = this.planoEdit['entidadeDO'];
    this.secretaria = this.planoEdit['secretariaPmpaDO'];
    // console.log('mostrarDialogParaEditar ... Produto...>>', this.produtoSelecionado.nome);
    // console.log('mostrarDialogParaEditar ... Produtossss...>>', this.prods);
    // console.log('mostrarDialogParaEditar ... Entidade...>>', this.entidade);
    this.residuoForm.get('produto').setValue(this.produtoSelecionado.descricao);
    this.residuoForm.get('rtElaboracaoSelPessoa').setValue('');
    this.residuoForm.get('rtExecucaoSelPessoa').setValue('');
    if (this.secretaria !== undefined && this.secretaria !== null) {
      this.residuoForm.get('secretariaSel').setValue(this.secretaria.sigla);
    }

    this.semPlanoResiduos = false;
    this.anexoService.getAnexoPorTipo(this.entidade.id, 'Plano Resíduo').subscribe(anexoPR => {
      console.log('.........getAnexoPorTipo --- inicializaOnInit >>>>>>>>', anexoPR);
      if (anexoPR === null || anexoPR.length < 1) {
        this.semPlanoResiduos = true;
      } else {


        this.pessoaFisicaService.getRTPessoasFisicasDoPlano(idPlano).subscribe(rtPessoasFisicas => {
          console.log('getRTPessoasFisicasDoPlano RTPFs........ >>>>', rtPessoasFisicas);
          rtPessoasFisicas.forEach(pf => {
            if (pf.rtElaboracao) {
              this.residuoForm.get('rtElaboracaoSelPessoa').setValue(pf.nomeCompleto);
              console.log('**********************getRTPessoasFisicasDoPlano RTPFs........ >>>>', rtPessoasFisicas);
            }
            if (pf.rtExecucao) {
              this.residuoForm.get('rtExecucaoSelPessoa').setValue(pf.nomeCompleto);
            }
          });
        });
        this.anexoService.getAnexosDoPlano(idPlano).subscribe(anexosPlano => {
          console.log('getAnexosDoPlano Anexos.......................................... >>>>', anexosPlano);
          anexosPlano.forEach(anexo => {
            if (anexo.tipoAnexo === 'Plano Resíduo') {
              this.anexoPlano = anexo;
            }
            if (anexo.tipoAnexo === 'RT Elaboração') {
              this.anexoRTElaboracao = anexo;
            }
            if (anexo.tipoAnexo === 'RT Execução') {
              this.anexoRTExecucao = anexo;
            }
          });
        });
      }
    },
    error => {
      console.log(error);
      console.log(error.error);
      this.spinnerService.ocultar();
      this.notificacoesService.notificarErro(error.error, null);
    });


    this.planoService.getParticipacoes(idPlano).subscribe(parts => {
      this.participacoes = parts;

      this.participacoes.forEach(participacao => {
        this.entidadeService.getEntidadePorId(participacao.entidadeDO.id).subscribe(entidade => {
          console.log('mostrarDialogParaEditar Participação >>>>', participacao);
          if (participacao.tipoParticipacao == 'Transportador') {
            this.entidadeTransporte = entidade;
            console.log('mostrarDialogParaEditar EntidadeTransporte >>>>', this.entidadeTransporte);
            this.residuoForm.get('transportadorCnpj').setValue(this.entidadeTransporte.cnpj);
            this.buscaPessoaFisica(this.entidadeTransporte, 'transportador');
          }
          if (participacao.tipoParticipacao == 'Destinador') {
            this.entidadeDestino = entidade;
            console.log('mostrarDialogParaEditar EntidadeDestino >>>>', this.entidadeDestino);
            this.residuoForm.get('destinadorCnpj').setValue(this.entidadeDestino.cnpj);
            this.buscaEnderecoEntidade(this.entidadeDestino, 'destinador');
            if (this.entidadeDestino.cnpj === '88.017.272/0001-45') {
              this.residuoForm.get('destinadorCodDmlu').setValue(this.planoEdit.codigoDMLU);
              this.residuoForm.get('destinadorCodDmlu').disable();
              this.codigoDMLU = true;
            }
                  }

        },
        error => {
          console.log(error);
          console.log(error.error);
          this.spinnerService.ocultar();
          this.notificacoesService.notificarErro(error.error, null);
        });
      });
    });

    this.metasPlanoPorAno(this.planoEdit, false, this.pesquisaReferencia);

    // this.planoService.getTermo(this.planoEdit.id).subscribe(termoc => {
    //   console.log('11111');
    //   this.termo = termoc;
    //   console.log(this.termo);
    // });

    // this.planoService.getAnexos(idPlano).subscribe(anexos => {
    //   this.anexos = anexos;
    // });

    this.mostrarDialogEdit = true;
    this.tituloDialogEdit = 'Resíduo: ' + this.planoEdit['produtoDO'].nome;

  }

  metasPlanoPorAno(plano, listaResiduos, anoReferencia) {
    this.planoService.getMetasDoPlanoPorAno(plano.id, anoReferencia).subscribe(metas => {
      this.metas = metas;
      this.metas.forEach(meta => {
        console.log('metasPlanoAnoAtual Meta >>>>', meta);

        if (listaResiduos) {
          this.planoListaMeta = {
            id: null,
            residuo: null,
            nomeEmpresa: null,
            referencia: null,
            justificativa: null,
            dataAnulacao: null };
          this.planoListaMeta.id = plano.id;
          this.planoListaMeta.residuo = plano.produtoDO.nome;
          this.planoListaMeta.nomeEmpresa = plano.entidadeDO.razaoSocial;
          this.planoListaMeta.referencia = meta.ano;
          this.planoListaMeta.justificativa = plano.justificativaAnulacao;
          this.planoListaMeta.dataAnulacao = plano.dataAnulacao;
          this.planosMeta.push(this.planoListaMeta);

        } else {
          // edição do resíduo
          this.metaPlano = meta;
          console.log('mostrarDialogParaEditar Meta >>>>', this.metaPlano);
          this.residuoForm.get('quantidadePrevista').setValue(this.metaPlano.metaAnualPrevista);
          if (this.metaPlano.unidadeMedidaPrevista.toString() === 'MetroCubico') {
            this.residuoForm.get('unidadeQuantidadePrev').setValue('m³');
          }
          if (this.metaPlano.unidadeMedidaPrevista.toString() === 'Tonelada') {
            this.residuoForm.get('unidadeQuantidadePrev').setValue('t');
          }
          // this.residuoForm.get('logradouro').setValue(this.metaPlano.enderecoColeta.nomeLogradouro);
          // this.residuoForm.get('numero').setValue(this.metaPlano.enderecoColeta.numero);
        }
      });
    });
  }

  anexoPlanoSelecionado(anexoPlano) {

  }

  rtElaboracaoSelecionado(rtElaboracao) {
    console.log('rtElaboracaoSelecionado.................... >>>>', rtElaboracao);
  }

  rtExecucaoSelecionadoPessoa(rtExecucaoPessoa) {

  }

  rtExecucaoSelecionadoAnexo(rtExecucaoAnexo) {
    console.log('rtExecucaoSelecionadoAnexo.................... >>>>', this.residuoForm.get('rtExecucaoSelAnexo').value);

  }

  addMeta() {
    this.metas.push({ 'ano': 2018 });
  }


  salvarMetas() {
    this.planoService.salvarMetas(this.planoEdit.id, this.metas).subscribe(retorno => {
      this.mostrarDialogEdit = false;
      this.spinnerService.ocultar();
      this.notificacoesService.notificarSucesso(retorno.toString(), null);
    },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });
  }

  findPlano(idPlano: number): Plano {
    const plano = this.planos.find(function (planoi) {
      return planoi.id === idPlano;
    });
    return plano;
  }

  isEntidade(): boolean {
    // const ret = KeycloakService.keycloakAuth.hasResourceRole('Entidade');
    // return ret;
    return true;
  }

  isSMAMS(): boolean {
    // const ret = KeycloakService.keycloakAuth.hasResourceRole('SMAMS');
    // return ret;
    return true;
  }


  mostrarDialogEditParaExcluir(idPlano: number) {
    this.planoExcluir = this.planos.find(function (plano) {
      return plano.id === idPlano;
    });
    this.mostrarDialogExcluir = true;
  }

  aceitarPlano(idPlano: number) {

    this.planoService.aceitarPlano(idPlano).subscribe(plano => {
      // Remove o valor atual
      this.planos = this.planos.filter(item => item.id !== plano.id);

      // Adiciona o novo
      this.planos.push(plano);
    });
  }

  devolverPlano(idPlano: number) {
    this.planoService.devolverPlano(idPlano).subscribe(plano => {
      // Remove o valor atual
      this.planos = this.planos.filter(item => item.id !== plano.id);

      // Adiciona o novo
      this.planos.push(plano);
    });
  }

  enviarPlano(idPlano: number) {
    this.planoService.enviarPlano(idPlano).subscribe(plano => {
      console.log(plano);
      // Remove o valor atual
      this.planos = this.planos.filter(item => item.id !== plano.id);
      console.log(this.planos);
      // Adiciona o novo
      this.planos.push(plano);
      console.log(this.planos);
    },
      error => {
        this.notificacoesService.notificarErro(error.error, null);
      });
  }

  salvar() {

    // Consistências
    if (this.planoEdit.produtoDO == null) {
      this.notificacoesService.notificarErro('Informe o produto', null);
      return;
    }

    this.spinnerService.exibir();
    // this.planoEdit.produto = this.produtoSelecionado;
    this.planoEdit.entidadeDO = this.entidade;

    // Se novo
    if (this.planoEdit.id == null) {
      // this.planoService.criarPlano(this.planoEdit).subscribe(planoNovo => {
      //   this.planos.push(planoNovo);
      //   this.mostrarDialogEdit = false;
      //   this.spinnerService.ocultar();
      //   this.notificacoesService.notificarSucesso('Plano adicionada com sucesso', null);
      // },
      //   error => {
      //     console.log(error);
      //     this.spinnerService.ocultar();
      //     this.notificacoesService.notificarErro(error.error, null);
      //   });
      this.spinnerService.ocultar();

    } else { // Se editando

      this.planoService.atualizarPlano(this.planoEdit).subscribe(planoEditado => {
        // Remove o valor atual
        this.planos = this.planos.filter(item => item.id !== this.planoEdit.id);

        // Adiciona o novo
        this.planos.push(planoEditado);

        this.mostrarDialogEdit = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso('Resíduo atualizado com sucesso.', null);
      },
        error => {
          console.log(error);
          this.spinnerService.ocultar();
          this.notificacoesService.notificarErro(error.error, null);
        });
    }
  }

  excluir() {
    this.spinnerService.exibir();

    this.planoService.excluirPlano(this.planoExcluir).subscribe(() => {
      // Remove o valor atual
      this.planos = this.planos.filter(item => item.id !== this.planoExcluir.id);

      this.mostrarDialogExcluir = false;
      this.spinnerService.ocultar();
      this.notificacoesService.notificarSucesso('Resíduo excluído com sucesso.', null);
    },
      error => {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro('Erro ao exluir resíduo', error.error);
      });
  }

  addEntidade() {
    console.log("Plano - Entidades - Adicionar - Papeis", this.papeis);
    // console.log("Plano - Entidades - Adicionar - Papeis", this.tipoParticipacao
    this.participacoes.push({ id: null, tipoParticipacao: 'Transporte', entidadeDO: {} });
  }

  // addEntidade() {
  //   console.log("Plano - Entidades - Adicionar - Papeis", this.papeis);
  //   // console.log("Plano - Entidades - Adicionar - Papeis", this.tipoParticipacao
  //   this.participacoes.push({ id: null, entidadeDO: {} });
  // }

  controlaTipoParticipacao(tipo) {
    console.log("Plano - Entidades - Adicionar - Tipo Selecionado", tipo);
    // this.participacoes.push({ id: null, tipoParticipacao: 'Operador Aderente', entidadeDO: {} });
  }

  addPC() {
    this.pontosDeColeta.push({ id: null, numero: '0' });
  }

  salvarParticipacoes() {
    this.planoService.salvarParticipacoes(this.planoEdit.id, this.participacoes).subscribe(
      retorno => {
        this.mostrarDialogEdit = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso(retorno.toString(), null);
      },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, error.message);
      });
  }

  private conververArquivoByteArray(arquivo: File): any {
    console.log('111');
    const reader = new FileReader();

    reader.onload = function (e) {
      console.log('222');
      const rawData = reader.result;
      console.log('333');
    };
    console.log('444');
    reader.readAsArrayBuffer(arquivo);
    console.log('555');
    return reader.result;
  }

  adicionarAnexo(event) {

    const arquivo: File = event.files[0];
    const tis = this;
    const reader = new FileReader();
    tis.spinnerService.exibir();

    reader.onload = function (e) {
      const data = reader.result;

      const novoAnexo: Anexo = {
        fileName: arquivo.name,
        fileType: arquivo.type,
        fileSize: arquivo.size,
        // base64File: data,
        file: data,
        descricao: tis.novoAnexoDescricao,
        tipoAnexo: 'Plano Resíduo',
        situacao: true
      };

      console.log('aaa');
      console.log(novoAnexo);

      tis.planoService.salvarAnexo(tis.planoEdit.id, novoAnexo).subscribe(
        anexo => {
          console.log('bbbb');
          console.log(anexo);
          tis.planoService.setFile(anexo['id'], novoAnexo.file).subscribe(ret => {
            tis.spinnerService.ocultar();
            tis.notificacoesService.notificarSucesso('Anexo adicionado com sucesso', null);
          },
            error => {
              console.log(error);
              tis.spinnerService.ocultar();
              tis.notificacoesService.notificarErro('Erro ao adicionar anexo', error.message);
            }
          );
          // Concluiu
          tis.novoAnexoDescricao = null;
          tis.fileUpload.clear();
          tis.anexos.push(anexo);
        },
        error => {
          console.log(error);
          tis.spinnerService.ocultar();
          tis.notificacoesService.notificarErro('Erro ao adicionar anexo', null);
        });

    };
    reader.readAsArrayBuffer(arquivo);
    // reader.readAsDataURL(arquivo);

  }

  getUrlAnexo(idAnexo: number): string {
    const url = environment.apiUrl + '/anexos/' + idAnexo + '/file';
    return url;
  }

  podeSalvar(): boolean {
    if (this.planoEdit == null) {
      return false;
    }
    // if (this.planoEdit.situacao === 'Edicao') {
    //   return true;
    // }

    if (this.insertMode === true) {
      return true;
    }

    return false;


  }

  excluirAnexo(anexo: Anexo) {
    const r = confirm('Confirme a exclusão do anexo ' + anexo.fileName + '?');
    if (r === true) {
      this.planoService.excluirAnexo(anexo.id).subscribe(() => {
        this.notificacoesService.notificarSucesso('Anexo excluído com sucesso', '');
        this.anexos = this.anexos.filter(item => item.id !== anexo.id);
      },
        error => {
          this.notificacoesService.notificarErro(error.error, null);
        });
    }
  }

  excluiParticipacao(part: Participacao) {

    if (part.id == null) {
      this.participacoes = this.participacoes.filter(item => item.id != null);
      return;
    }

    const r = confirm('A Entidade ' + part['entidadeDO'].razaoSocial + ' será desligada deste plano. Confirma?');
    if (r === true) {
      this.planoService.excluirParticipacao(part.id).subscribe(() => {
        this.participacoes = this.participacoes.filter(item => item.id !== part.id);
        this.notificacoesService.notificarSucesso('Entidade desvinculada com sucesso', '');
      },
        error => {
          console.log (error);
          this.notificacoesService.notificarErro(error.error, null);
        });
    }
  }


  limpaPrestadoraServicoTela(tipoEntidade) {

    if (tipoEntidade === 'transportador') {
      this.residuoForm.get('transportadorNome').setValue('');
      this.residuoForm.get('transportadorEmail').setValue('');
      this.residuoForm.get('transportadorFone').setValue('');
      this.residuoForm.get('transportadorCelular').setValue('');
    }

    if (tipoEntidade === 'destinador') {
      this.residuoForm.get('destinadorNome').setValue('');
      this.residuoForm.get('destinadorEmail').setValue('');
      this.residuoForm.get('destinadorFone').setValue('');
      this.residuoForm.get('destinadorCelular').setValue('');
      this.residuoForm.get('destinadorLicenca').setValue('');
      this.residuoForm.get('destinadorLicencaDataValidade').setValue(null);
      this.residuoForm.get('destinadorLicencaOrgaoEmissor').setValue('');
      this.residuoForm.get('destinadorCodDmlu').setValue('');
    }

  }


  buscaEntidade(cnpj: string, tipoEntidade: string) {

    this.limpaPrestadoraServicoTela(tipoEntidade);

    if (cnpj === '__.___.___/____-__') {
      cnpj = null;
    }

    if (cnpj == null || cnpj === '') {
      // this.spinnerService.ocultar();
      // this.notificacoesService.notificarErro('Não encontrou entidade para CNPJ informado!', null);
    } else {

      if (cnpj === this.entidade.cnpj && tipoEntidade === 'destinador') {
          this.notificacoesService.notificarErro('Empresa Geradora de Resíduos não pode ser Destino Final!', null);
      } else {

        this.entidadeService.getEntidadeCnpj(cnpj).subscribe(entidade => {

          console.log('BuscaEntidade1111>>>>>>>', entidade);
          if (entidade == null) {
            this.notificacoesService.notificarErro('Não encontrou Empresa para CNPJ informado!', null);
          } else {
            this.codigoDMLU = false;
            this.residuoForm.get('destinadorCodDmlu').setValue('transiente');
            if (entidade.cnpj === '88.017.272/0001-45') {
              this.codigoDMLU = true;
              this.residuoForm.get('destinadorCodDmlu').setValue('');
            }

            if (tipoEntidade === 'destinador') {
              this.buscaLicenca(entidade);
            }
            setTimeout(() => {
              this.buscaEnderecoEntidade(entidade, tipoEntidade);
            }, 1000);
          }
        },
          error => {
            console.log(error);
            console.log(error.error);
            this.spinnerService.ocultar();
            this.notificacoesService.notificarErro(error.error, null);
        });
      }
    }

  }

  buscaEnderecoEntidade(entidade: Entidade, tipoEntidade: string) {
    // console.log('buscaEnderecoEntidade0000000>>>>', tipoEntidade, '----', entidade);
    // this.buscaLicenca(entidade);

    this.enderecoService.getEnderecosPorEntidade(entidade.id).subscribe(enderecos => {
      this.endsEntidadeGerador = [];
      // if (tipoEntidade == 'gerador') {
      //   this.endsEntidadeGerador = enderecos;
      // }
      // if (tipoEntidade == 'transportador') {
      //   this.endsEntidadeTransportador = [];
      //   this.endsEntidadeTransportador = enderecos;
      // }
      if (tipoEntidade == 'destinador') {
        this.endsEntidadeDestinador = [];
        this.endsEntidadeDestinador = enderecos;
        if (enderecos.length > 0) {
          this.residuoForm.get(tipoEntidade + 'EndSel').setValue(enderecos[0].id);
          // if (enderecos[0].licencaAmbientalDO !== null) {
          //   this.selecionaLicenca(enderecos[0].licencaAmbientalDO.licenca);
          // }
          this.enderecoSelecionado(enderecos[0], tipoEntidade);
        }
      }
      // console.log('buscaEnderecoEntidade>>>>', enderecos);

      this.buscaPessoaFisica(entidade, tipoEntidade);

      // if (tipoEntidade == 'transportador') {
      //   //    BUSCA VEÍCULOS
      //   this.buscaVeiculoEntidade(entidade, tipoEntidade);
      // } else {
        //    BUSCA PessoaFisica
      // }

    },
    error => {
      this.spinnerService.ocultar();
      this.notificacoesService.notificarErro(error.error, null);
    });

  }

  selecionaLicenca(licenca) {

    // console.log('00..............selecionaLicenca>>>>', this.licencasAmbientais);
    
    this.licencasAmbientais.forEach(lic => {
      if (lic.licenca === licenca) {
        this.licencaAmbiental = lic;
        this.residuoForm.get('destinadorLicenca').setValue(this.licencaAmbiental.licenca);
        if (this.licencaAmbiental.dataValidade != null) {
          this.residuoForm.get('destinadorLicencaDataValidade').setValue(new Date(this.licencaAmbiental.dataValidade));
        }
        this.residuoForm.get('destinadorLicencaOrgaoEmissor').setValue(this.licencaAmbiental.orgaoEmissor);

        console.log('0..............selecionaLicenca>>>>', licenca, '<--->', lic.licenca, '<<<<');
        console.log('1..............selecionaLicenca>>>>', this.licencaAmbiental);
        console.log('2..............selecionaLicenca>>>>', this.residuoForm.get('destinadorLicenca').value);
        console.log('3..............selecionaLicenca>>>>', this.residuoForm.get('destinadorLicencaDataValidade').value);
        console.log('4..............selecionaLicenca>>>>', this.residuoForm.get('destinadorLicencaOrgaoEmissor').value);
      }
    });

  }

  buscaPessoaFisica(entidade: Entidade, tipoEntidade: string) {
    //    BUSCA PessoaFisica
    this.pessoaFisicaService.getPessoasFisicasDaEntidade(entidade.id).subscribe(pessoaFisicas => {

      pessoaFisicas.forEach(pf => {
        if (pf.rLegal) {
          this.pessoaFisica = pf;
        }
      });

      if (tipoEntidade === 'transportador') {
        this.entidadeTransporte = entidade;
        this.formataTelaTransportador(this.entidadeTransporte, this.pessoaFisica);
      }
      if (tipoEntidade === 'destinador') {
        this.entidadeDestino = entidade;
        // this.buscaLicenca(entidade);
        this.formataTelaDestinador(this.entidadeDestino, this.pessoaFisica);
      }

      if (this.insertMode) {
        this.validaEntidade(entidade, this.pessoaFisica, tipoEntidade);
      }

    },
    error => {
      console.log(error);
      console.log(error.error);
      this.spinnerService.ocultar();
      this.notificacoesService.notificarErro(error.error, null);
    });
  }

  buscaLicenca(entidade: Entidade) {
    console.log('..............buscaLicenca>>>>', entidade);
    //    BUSCA PessoaFisica
    this.licencaService.getLicencasPorEntidade(entidade.id).subscribe(licenca => {

      this.licencasAmbientais = licenca;
      console.log('AA..............buscaLicenca>>>>', this.licencasAmbientais);

    },
    error => {
      console.log(error);
      console.log(error.error);
      this.spinnerService.ocultar();
      this.notificacoesService.notificarErro(error.error, null);
    });
  }

  validaEntidade(entidade: Entidade, pessoaFisica: PessoaFisica, tipoEntidade: string) {
    console.log('validaEntidade PLANO>>>>>>>', tipoEntidade, '---', entidade, '---', pessoaFisica);
    // console.log('validaEntidade PLANO>>>>>>>', this.endsEntidadeDestinador);
    this.mensagemValidacao = '';
    if (tipoEntidade === 'transportador') {
        if (pessoaFisica.telefone == null || pessoaFisica.telefone === '') {
          this.mensagemValidacao =
            'Favor solicitar à Empresa selecionada como TRANSPORTADORA para que complemente as informações do representante.';
        }
        if (pessoaFisica.celular == null || pessoaFisica.celular === '') {
          this.mensagemValidacao =
           'Favor solicitar à Empresa selecionada como TRANSPORTADORA para que complemente as informações do representante.';
        }
    }
    if (tipoEntidade === 'destinador') {
        if ((pessoaFisica.telefone === null || pessoaFisica.telefone === '') && (pessoaFisica.celular == null || pessoaFisica.celular === '')) {
          this.mensagemValidacao =
           'Favor solicitar à Empresa selecionada como DESTINO FINAL para que complemente as informações do representante.';
        }
        if (this.endsEntidadeDestinador.length < 1) {
          this.mensagemValidacao =
            'Favor solicitar à Empresa selecionada como DESTINO FINAL para que informe o ENDEREÇO no cadastro.';
        }
        // if (this.licencaAmbiental?.orgaoEmissor == null || this.licencaAmbiental?.orgaoEmissor === '') {
        //   this.mensagemValidacao =
        //     'Favor solicitar à Empresa selecionada como DESTINO FINAL para que informe o ÓRGÃO EMISSOR DA LICENÇA AMBIENTAL no cadastro.';
        // }
        if (this.licencaAmbiental == null || this.licencaAmbiental === undefined) {
          this.mensagemValidacao =
            'Favor solicitar à Empresa selecionada como DESTINO FINAL para que informe a LICENÇA AMBIENTAL no cadastro.';
        }
    }
    if (this.mensagemValidacao !== '') {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(this.mensagemValidacao, null);
        if (tipoEntidade === 'transportador') {
          this.isDadosTranspInvalidos = true;
        }
        if (tipoEntidade === 'destinador') {
          this.isDadosDestinInvalidos = true;
        }
    } else {
        if (tipoEntidade === 'transportador') {
          this.isDadosTranspInvalidos = false;
        }
        if (tipoEntidade === 'destinador') {
          this.isDadosDestinInvalidos = false;
        }
    }
  }

  formataTelaTransportador(entidade: Entidade, pessoaFisica: PessoaFisica) {
    this.residuoForm.get('transportadorNome').setValue(entidade.nomeFantasia);
    this.residuoForm.get('transportadorEmail').setValue(pessoaFisica.email);
    this.residuoForm.get('transportadorFone').setValue(pessoaFisica.telefone);
    this.residuoForm.get('transportadorCelular').setValue(pessoaFisica.celular);
    // this.residuoForm.get('transportadorLicenca').setValue(entidade.licencaAmbiental);
  }

  formataTelaDestinador(entidade: Entidade, pessoaFisica: PessoaFisica) {
    this.residuoForm.get('destinadorNome').setValue(entidade.nomeFantasia);
    this.residuoForm.get('destinadorEmail').setValue(pessoaFisica.email);
    this.residuoForm.get('destinadorFone').setValue(pessoaFisica.telefone);
    this.residuoForm.get('destinadorCelular').setValue(pessoaFisica.celular);
    // this.residuoForm.get('destinadorLicenca').setValue(entidade.licencaAmbiental);
    // if (entidade.licencaAmbientalDataValidade != null) {
    //   this.residuoForm.get('destinadorLicencaDataValidade').setValue(new Date(entidade.licencaAmbientalDataValidade));
    // }
    // this.residuoForm.get('destinadorLicencaOrgaoEmissor').setValue(entidade.licencaAmbientalOrgaoEmissor);
  }

  cancelar() {
    this.mostrarDialogEdit = false;
  }

  salvarResiduosGrandesGeradores() {
    // console.log('salvarResiduosGrandesGeradores Entidade >>>>>>>>', this.entidade);
    // console.log('salvarResiduosGrandesGeradores EntidadeTransporte >>>>>>>>', this.entidadeTransporte);
    // console.log('salvarResiduosGrandesGeradores EntidadeDestino >>>>>>>>', this.entidadeDestino);
    // console.log('salvarResiduosGrandesGeradores Produto >>>>>>>>', this.residuoForm.get('produto').value);
    // console.log('salvarResiduosGrandesGeradores EnderecoColeta >>>>>>>>', this.enderecoColetaResiduo);

    // this.planoEdit.entidade = this.entidade;
    // this.planoEdit.produtoDO = this.residuoForm.get('produto').value;

    const infoPlano = new InfoPlano();
    infoPlano.idEntidade = this.entidade.id;

    this.produtos.forEach(produto => {
      if (produto.descricao === this.residuoForm.get('produto').value) {
        infoPlano.idProduto = produto.id;
      }
    });

    if (!this.semPlanoResiduos) {
      infoPlano.idAnexoPlano = this.residuoForm.get('anexoPlanoSel').value;
      infoPlano.idRTElaboracaoPessoa = this.residuoForm.get('rtElaboracaoSelPessoa').value;
      infoPlano.idRTElaboracaoAnexo = this.residuoForm.get('rtElaboracaoSelAnexo').value;
      infoPlano.idRTExecucaoPessoa = this.residuoForm.get('rtExecucaoSelPessoa').value;
      infoPlano.idRTExecucaoAnexo = this.residuoForm.get('rtExecucaoSelAnexo').value;
    }

    // this.produtoSelecionado = this.residuoForm.get('produto').value;
    infoPlano.idEntidadeTransporte = this.entidadeTransporte.id;
    infoPlano.idEntidadeDestino = this.entidadeDestino.id;
//    infoPlano.descricao = this.residuoForm.get('descricao').value;
    infoPlano.quantidadePrevista = this.residuoForm.get('quantidadePrevista').value;
    infoPlano.unidadeQuantidadePrev = this.residuoForm.get('unidadeQuantidadePrev').value;


    infoPlano.endereco = this.enderecoColetaResiduo;

    if (this.codigoDMLU) {
      infoPlano.codigoDMLU = this.residuoForm.get('destinadorCodDmlu').value;
    }


    console.log('salvarResiduosGrandesGeradores Secretaria .............>>>>>>>>', this.residuoForm.get('secretariaSel').value);

    if (this.residuoForm.get('secretariaSel').value !== '' || this.residuoForm.get('secretariaSel').value != null) {
      this.secretariaList.forEach(secrt => {
        if (secrt.sigla === this.residuoForm.get('secretariaSel').value) {
          console.log('salvarResiduosGrandesGeradores Secretaria .............OK');
          infoPlano.idSecretariaPmpa = secrt.id;
        }
      });
    }

    console.log('salvarResiduosGrandesGeradores Plano >>>>>>>>', infoPlano);

    this.planoService.criarPlano(infoPlano).subscribe(planoNovo => {
      console.log('salvarResiduosGrandesGeradores Plano >>>>>>>>', infoPlano);
      this.planos.push(planoNovo);
      this.mostrarDialogEdit = false;
      this.spinnerService.ocultar();
      this.notificacoesService.notificarSucesso('Resíduo adicionado com sucesso', null);
    },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });




  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    const valor = event.target.value;
    let situacao = true;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      situacao = false;
    } else if (valor.length > 4) {
      situacao = false;
    }

    return situacao;

  }


  selecionaCDL(endereco) {

    this.localizacao = {latitude:null, longitude:null};

    if (endereco.latitude !== undefined && endereco.latitude !== null) {
      this.localizacao = {
        latitude: endereco.latitude,
        longitude: endereco.longitude
      };
      delete endereco['latitude'];
      delete endereco['longitude'];
    }
    this.enderecoColetaResiduo = endereco;
    this.enderecoColetaResiduo.localizacao = this.localizacao;

  }

  enderecoSelecionado(endereco, tipoEntidade) {
    // this.enderecoColetaResiduo = endereco;
    // if (tipoEntidade == 'transportador') {
      //   this.entidadeTransporte.transportadorEndereco = endereco.enderecoFormatado;
      //   this.manifestoEdit.transportadorMunicipio = endereco.municipio;
      // }
      // if (tipoEntidade == 'destinador') {
        //   this.manifestoEdit.destinadorEndereco = endereco.enderecoFormatado;
        //   this.manifestoEdit.destinadorMunicipio = endereco.municipio;
        // }
        this.residuoForm.get('destinadorLicenca').setValue('');
        this.residuoForm.get('destinadorLicencaDataValidade').setValue('');
        this.residuoForm.get('destinadorLicencaOrgaoEmissor').setValue('');
        
    if (endereco.licencaAmbientalDO !== null) {
      console.log('ENDEREÇO enderecoSelecionado>>>>>>>', endereco);
      this.selecionaLicenca(endereco.licencaAmbientalDO.licenca);
    }
  }



  visualizaAnexo(anexo, tipoAnexo) {

    console.log("visualizaAnexo Anexo>>>>>>>", anexo);
    const tipoArquivo = anexo.fileType.split('/');
    console.log("Tipo Arquivo>>>>>", tipoArquivo);



    if (tipoArquivo[0] == 'image') {

      var image = new Image();
      image.src = "data:image/jpg;base64," + anexo.file;

      var w = window.open('');
      w.document.write(image.outerHTML);
    } else if (tipoArquivo[0] == 'application') {

      if (tipoArquivo[1] == 'pdf') {

        var byteCharacters = atob(anexo.file);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      } else if (tipoArquivo[1] == 'vnd.openxmlformats-officedocument.wordprocessingml.document' || tipoArquivo[1] == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') { //docx


        // var reader = new FileReader();
        var byteCharacters = atob(anexo.file);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/octet-stream' });
        var downloadUrl = window.URL.createObjectURL(file);
        var a = document.createElement("a");
        a.href = downloadUrl;
        a.download = anexo.fileName;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(downloadUrl);

      } else { //xml
        this.notificacoesService.notificarSucesso('Arquivo não previsto para visualização!', '');

      }
    }

  }


  mostrarDialogEditParaAnulacao(idPlano: number) {
    const planoAnular =  this.planos.find(function(plano) {
      return plano.id === idPlano;
    });
    console.log('Editar mostrarDialogParaEditar11>>>>>>>', planoAnular);
    this.planoEdit = {...planoAnular};

    this.justificarAnulacao = true;
    this.mostrarDialogEdit = false;
  }

  salvarJustificativa(idPlano) {
    // const manifestoAnularRecusar =  this.manifestos.find(function(manifesto) {
    //   return manifesto.id === idManifesto;
    // });
    // this.manifestoEdit = {...manifestoAnularRecusar};
    console.log('Editar salvarJustificativa>>>>>>>', this.planoEdit);

    this.spinnerService.exibir();
    this.planoEdit.dataAnulacao = new Date();

    this.planoService.anularPlano(this.planoEdit).subscribe(planoAnulado => {
      this.justificarAnulacao = false;
      this.spinnerService.ocultar();
      this.notificacoesService.notificarSucesso('Resíduo anulado com sucesso!', null);

      this.mostrarDialogEdit = false;

      this.planoService.getPlanosDaEntidade(this.entidade.id, new Date().getFullYear()).subscribe(planos => {
        this.planos = planos;
        console.log('PLANO --- inicializaOnInit >>>>>>>>', this.planos);
      },
      error => {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.message, null);
      });

    },
    error => {
      console.log('Salvar Post Erro>>>>>>>', error);
      this.spinnerService.ocultar();
      this.notificacoesService.notificarErro('Erro ao anular resíduo.', error.error);
    });

    this.onSubmit();

  }

  salvarPrevistoRealizado() {
    let todosCamposInformados = true;
    this.planosPrevistoRealizado.forEach(planoPR => {
      if (planoPR.qtRealizadaAnterior === null || planoPR.qtRealizadaAnterior === '') {
        todosCamposInformados = false;
      }
      if (planoPR.qtPrevistaAtual === null || planoPR.qtPrevistaAtual === '') {
        todosCamposInformados = false;
      }
    });
    if (!todosCamposInformados) {
      this.notificacoesService.notificarAviso('Por favor, informar os valores em todos os campos.', null);
    } else {

    }

    this.planoService.atualizaQtdAnuaisRealizadasPrevistas(this.planosPrevistoRealizado).subscribe(planosAtuais => {
      console.log('.............PLANO --- salvarPrevistoRealizado >>>>>>>>', planosAtuais);
      this.planos = planosAtuais;
      this.mostrarDialogEditRealizado = false;

    },
    error => {
      console.log('salvarPrevistoRealizado Post Erro>>>>>>>', error);
      this.spinnerService.ocultar();
      this.notificacoesService.notificarErro('Erro ao atualizar quantidades realizadas e previstas.', error.error);
    });




  }
  sairPrevistoRealizado() {
    console.log('PLANO --- fechaJanela >>>>>>>>');
    this.router.navigate(['/grandeGerador']);
  }

  controlaCamposInformados(planoPR) {
    this.salvaPrevistoRealizado = true;
    this.planosPrevistoRealizado.forEach(plano => {
      console.log('PLANO --- controlaCamposInformados >>>>>>>>', plano);
      if (plano.nomeResiduo === planoPR.nomeResiduo) {
        plano.qtRealizadaAnterior = planoPR.qtRealizadaAnterior;
        plano.qtPrevistaAtual = planoPR.qtPrevistaAtual;
      }
      if (plano.qtRealizadaAnterior == null || plano.qtRealizadaAnterior === '') {
        this.salvaPrevistoRealizado = false;
      }
      if (plano.qtPrevistaAtual == null || plano.qtPrevistaAtual === '') {
        this.salvaPrevistoRealizado = false;
      }

    });
   // console.log('PLANO --- controlaCamposInformados >>>>>>>>', this.planosPrevistoRealizado);

  }

  changeTable(e) {
    this.index = e.index;
  }
  alternarAbas(ind: number) {

      this.index = ind;
  }


  checkActive(route: string) {
    return route === this.activeRoute;
  }

  pesquisarReferencia() {

    console.log('....pesquisarReferencia>>>>>', this.pesquisaReferencia);
    this.planosMeta = [];
    if (this.pesquisaReferencia === undefined) {
      this.pesquisaReferencia = null;
    }

    if (this.pesquisaReferencia !== null) {
      this.buscaPlanosEntidadeAno(this.pesquisaReferencia);
    }

  }

}
