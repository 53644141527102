<sgr-menu></sgr-menu>
<div class="container">

  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/sgrIni">SGR</a></li>
    <li class="breadcrumb-item active" aria-current="page">MTRCC</li>
  </ol>
  <div class="row justify-content-between">
    <div class="col-auto">
      <h1 class="page-header text-primary">
        MTRCC <br>
        <small>Relatórios </small>
      </h1>
    </div>
  </div>
    <div class="card">
      <div class="card-header text-black info05 text-center">
        <strong>Período da pesquisa dos resultados</strong>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-auto form-group ui-inputgroup">
            <span class="ui-inputgroup-addon" >Dt. Início</span>
            <p-calendar [(ngModel)]="dataInicial" [showIcon]="true" dateFormat="dd/mm/yy"
            showButtonBar="true" appendTo="body" [style]="{ 'z-index': '999'}"></p-calendar>
          </div>
          <div class="col-auto form-group ui-inputgroup">
            <span class="ui-inputgroup-addon">Dt. Fim</span>
            <p-calendar [(ngModel)]="dataFinal" [showIcon]="true" dateFormat="dd/mm/yy"
            showButtonBar="true"></p-calendar>
          </div>
          <div class="col-auto ml-auto">
            <button type="button" pButton class="btn btn-primary btn-inputgroup" [style]="{'width':'150px', 'align':'left', 'height': '36px'}" 
              (click)="pesquisaManifesto()" label="Resultados"></button>
          </div>
        </div>
      </div>
    </div>


  <div class="row mt-4" *ngIf='totalManifestos > 0'>
    <div class="col-md-8 col-lg-6">
      <div class="alert alert-success text-center">
        <h2 class="h4">Total de Manifestos do período<br>
        <strong class="mt-2 d-block">{{ totalManifestos}}</strong> 
        </h2>
      </div>
    </div>
  </div>


</div>
