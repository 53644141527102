import { Injectable } from '@angular/core';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { merge, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EnderecoService } from './@core/services/endereco.service';
import { VeiculoService } from './@core/services/veiculo.service';
import { EnderecoEntidade } from './@shared/models/enderecoEntidade';
import { Entidade } from './@shared/models/entidade.model';
import { PessoaFisica } from './@shared/models/pessoaFisica.model';
import { Veiculo } from './@shared/models/veiculo.model';
import { EntidadeService } from './@core/entidade/entidade.service';
import { PessoaFisicaService } from './@core/services/pessoaFisica.service';
import { SpinnerService } from './@core/services/spinner.service';
import { NotificacoesService } from './@core/services/notificacoes.service';

@Injectable({providedIn: 'root'})
export class Globals {

  roleEntidade = false;
  entidade: Entidade = null;
  entidades: Entidade[] = [];
  // enderecosEntidade: EnderecoEntidade[] = [];
  // veiculosEntidade: Veiculo[] = [];
  pessoaFisica: PessoaFisica = null;
  pessoasFisicas: PessoaFisica[] = [];
  userFullName = '';
  userName = '';
  userEmail = '';
  menuRaiz = '';

  constructor(private keycloakService: KeycloakService,
    private entidadeService: EntidadeService,
    private enderecoService: EnderecoService,
    private pessoaFisicaService: PessoaFisicaService,
    private veiculoService: VeiculoService,
    private spinnerService: SpinnerService,
    private notificacoesService: NotificacoesService
    ) {

    // setTimeout(() => {
      keycloakService.getUser().subscribe(user => {
          this.userFullName = user.fullName;
          this.userName = user.name;
        });
        this.menuRaiz = '';
    // }, 3850);
  }


  recuperaKeycloak() {
    return this.keycloakService.getUser().pipe(tap(user => {
      this.userFullName = user.fullName;
      this.userName = user.username;
      this.userEmail = user.email;
      if (this.keycloakService.hasRole('Entidade')) {
        this.roleEntidade = true;
      }
    }));
  }

  recuperaEntidadePessoaFisica() {
    // console.log('Globals>>>>>>>>>recuperaEntidadePessoaFisica..........', this.menuRaiz);
    return new Observable(obs => {
    //  this.entidadeService.getEntidadeRepresentada().subscribe(entidade => {
      this.pessoaFisicaService.getPessoaFisicaPorEmail(this.userEmail).subscribe(pessoasFisicas => {
        this.pessoasFisicas = pessoasFisicas;
        // console.log('############Globals>>>>>>>>>getEntidadeRepresentada>>>>', pessoasFisicas);
        if (pessoasFisicas.length === 1) {
          // console.log('%%%%%%%%%%%%%%Globals>>>>>>>>>getEntidadeRepresentada>>>>', pessoasFisicas);
          this.pessoaFisica = pessoasFisicas[0];

          const extras = [
            this.entidadeService.getEntidadesDaPessoaFisica(this.pessoaFisica.id).pipe(tap(entidades => {
              this.entidades = entidades;
              // console.log('0000Globals>>>>>>>>>buscaEntidades>>>>', this.entidades.length);
              // console.log('Globals>>>>>>>>>buscaEntidades>>>>', this.entidades);
            if (entidades.length === 1) {
              this.entidade = entidades[0];
            }
          }))];

          merge(...extras).subscribe(outs => {}, e => obs.error(e), () => {
            obs.next(pessoasFisicas);
            obs.complete();
          });
        }
      });
    });
  }

}
