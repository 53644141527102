import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { MenuItem, SelectItem } from 'primeng/api';
import { FileUpload } from 'primeng/primeng';
import { LogradouroCdl } from 'procempa-cdl-lib';
import { EntidadeService } from '../../entidade/entidade.service';
import { EnderecoService } from '../../services/endereco.service';
import { PessoaFisicaService } from '../../services/pessoaFisica.service';
import { SpinnerService } from '../../services/spinner.service';
import { NotificacoesService } from '../../services/notificacoes.service';
import { SmfPessoaService } from '../../services/smfPessoa.service';
import { AnexoService } from '../../services/anexo.service';
import { ProdutoService } from '../../produto/produto.service';
import { DmluService } from '../dmlu.service';
import { Globals } from 'src/app/globals';
import { Contrato } from 'src/app/@shared/models/contrato.model';
import { Entidade } from 'src/app/@shared/models/entidade.model';
import { Localizacao } from 'src/app/@shared/models/localizacao';
import { Anexo } from 'src/app/@shared/models/anexo.model';
import { PessoaFisica } from 'src/app/@shared/models/pessoaFisica.model';
import { ptBr } from 'src/app/@shared/utils/calendar';
import { EnderecoEntidade } from 'src/app/@shared/models/enderecoEntidade';
import { Produto } from 'src/app/@shared/models/produto.model';
import { ThrowStmt } from '@angular/compiler';
import { ContratoVO, ContratoVOInter } from 'src/app/@shared/models/contratoVO.model';

@Component({
  selector: 'sgr-contrato-dmlu',
  templateUrl: './contrato.component.html',
  styleUrls: ['./contrato.component.scss']
})

export class ContratoComponent implements OnInit {

  submitted = false;
  contratoForm: FormGroup;
  activeRoute: string;
  nomeUsuario: string;

  idEntidade = null;
  entidade: Entidade;
  contratoId = null;
  contrato: Contrato;
  contratoVO: ContratoVO;
  contratos: ContratoVO[] = [];
  temContrato: Boolean = false;
  dataInicial: Date = new Date('01/01/1500');
  dataFinal: Date = new Date('01/01/1500');
  localizacao: Localizacao;
  languageCalendar: any;
  dataInicio: Date;
  dataFim: Date;
  dataVistoria: Date;
  abaEmpresa: Boolean = true;
  abaAssinaturaSei: Boolean = false;
  abaResiduo: Boolean = false;
  abaInfos: Boolean = false;
  abaIsento: Boolean = false;
  abaFinalizar: Boolean = false;
  abaTramitacao: Boolean = false;
  abaVigente: Boolean = false;
  codigoDmlu: Boolean = false;
  numeroContrato: Boolean = false;
  documentos: String = '';
  sistemaSEI: String = 'sistemaSEI';
  obsPendencia: String[];
  selectedSituacao: string = '';
  selectedAnaliseIsencao: string = '';
  selectedIsencaoPrazo: string = '';
  selectedAnaliseDocumental: string = '';
  selectedSEI: string = 'liberada';
  horaVistoria: Date = null;
  adimplenteInadimplente: string = 'adimplente';
  valorUFM: number;


  // Novo/edit
  insertMode = false;
  mostrarDialogEdit: Boolean = false;
  contratoAnaliseSAC: Boolean = false;
  contratoInfDivida: Boolean = false;
  contratoAnaliseSMCA: Boolean = false;
  contratoDiretorGeral: Boolean = false;
  contratoInfComplementar: Boolean = false;
  contratoAnaliseNCC: Boolean = false;
  contratoNccInfComplementar: Boolean = false;
  contratoVistoria: Boolean = false;
  contratoAssinaturaEmpresa: Boolean = false;
  contratoAssinaturaPmpa: Boolean = false;



  // contratoElaboracao: Boolean = false;
  contratoPendenteUsuario: Boolean = false;
  contratoVigente: Boolean = false;
  tituloDialogEdit: String = 'Contrato com o DMLU';

  empresaIsentaTarifa: boolean;


  // Pessoa Física
  responsaveisEntidade: PessoaFisica[] = [];
  responsavel: PessoaFisica = null;
  responsavelSel: number = null;

  // Endereço
  enderecosEntidade: EnderecoEntidade[] = [];
  endereco: EnderecoEntidade = null;
  enderecoSel: number = null;

  // Resíduos
  residuo: Produto;
  residuos: Produto[] = [];
  residuosContrato: Produto[] = [];
  residuosSelTb: Produto[] = [];
  residuosSelCp: Produto[] = [];
  residuosSelTg: Produto[] = [];
  residuosSelTgSt: String[] = [];
  residuosTransbordo: Produto[] = [];
  residuosCompostagem: Produto[] = [];
  residuosTriagem: Produto[] = [];
  alteracaoResiduos: Boolean = false;
  novoTipoResiduo: Boolean = false;
  residuoNovo: String = '';


  // Exluir
  mostrarDialogExcluir: boolean;
  tituloTela: string;
  tituloCard: string;


  // CDL
  logradouroCdl: LogradouroCdl;
  isCompleto = true;
  logradouro;
  numero;

  variaveisGlobais;
  mensagemValidacao = '';

  // Dados para anexos
  //  @ViewChild("anexoUpload") fileUpload: FileUpload;
  anexo: Anexo;
  anexoSel: String = '';
  anexos: Anexo[];
  // novoAnexoDescricao: string;
  tiposAnexos: SelectItem[] = [
    { label: 'CPF/RG', value: 'CPF/RG' },
    { label: 'Contrato Social', value: 'Contrato Social' },
    { label: 'Procuração', value: 'Procuração' }
  ];
  tipoAnexo: String = '';
  cpfRg: String = '';
  isCpfRg: Boolean = false;
  anexoCpgRg: Anexo = null;
  contratoSocialEstatuto: String = '';
  isContratoSocialEstatuto: Boolean = false;
  anexoContratoSocialEstatuto: Anexo = null;
  procuracao: String = '';
  isProcuracao: Boolean = false;
  anexoProcuracao: Anexo = null;
  relDivida: String = '';
  isRelDivida: Boolean = false;
  anexoRelDivida: Anexo = null;
  boletoPago: String = '';
  isBoletoPago: Boolean = false;
  anexoBoletoPago: Anexo = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    // private fb: FormBuilder,
    private keycloakService: KeycloakService,
    private spinnerService: SpinnerService,
    private notificacoesService: NotificacoesService,
    private dmluService: DmluService,
    private entidadeService: EntidadeService,
    private enderecoService: EnderecoService,
    private pessoaFisicaService: PessoaFisicaService,
    private produtoService: ProdutoService,
    private anexoService: AnexoService,
    private globals: Globals
  ) {

    this.activeRoute = this.router.url;

    this.variaveisGlobais = globals;
    this.idEntidade = route.snapshot.params['id'];
    console.log('Construtor ContratoComponent >>>>>>>>>>>', this.idEntidade);

  }

  ngOnInit() {
    console.log('ngOnInit---------ContratoComponent--------------->>>', this.globals);
    this.empresaIsentaTarifa = false;
    this.variaveisGlobais = this.globals;
    this.variaveisGlobais.menuRaiz = this.router.url.split('/')[1];
    this.entidade = this.variaveisGlobais.entidade;

    if (this.globals.entidade == null) {
      this.globals.recuperaKeycloak().subscribe(user => {
        if (this.globals.roleEntidade) {
          if (this.variaveisGlobais.pessoaFisica === null) {
            this.globals.recuperaEntidadePessoaFisica().subscribe(entidade => {
              this.variaveisGlobais = this.globals;
              console.log('--------------------------->>>>>00');
              this.inicializaOnInit();
            });
          } else {
            this.inicializaOnInit();
          }
        } else {
          console.log('--------------------------->>>>>11');
          this.inicializaOnInit();
        }
      });
    } else {
      console.log('--------------------------->>>>>22');
      this.inicializaOnInit();
    }

  }

  inicializaOnInit() {

    this.contrato = new Contrato();
    this.languageCalendar = ptBr;
    // this.mostrarDialogEdit = true;

    if (this.entidade !== null) {
      this.entidadeService.getEntidadePorId(this.idEntidade).subscribe(entidade => {
        this.entidade = entidade;
        this.spinnerService.ocultar();

        console.log('inicializaOnInit ..... SMAMS/DMLU.....>>>>>>>', entidade);
        if (entidade != null) {
          this.entidade = entidade;
          // this.buscaEnderecoEntidade(entidade, null);
          if (this.contrato !== null) {
            console.log('inicializaOnInit ..... buscaContratos.....>>>>>>>', this.contrato);
            this.buscaContratos();
          }
          this.buscaPessoasFisicas(entidade);
          this.buscaEnderecos(entidade);
          this.recuperaAnexosEntidade(entidade.id);
        }

        this.nomeUsuario = this.variaveisGlobais.userFullName;
        // this.pessoaFisica = this.variaveisGlobais.pessoaFisica;
        this.variaveisGlobais.entidade = this.entidade;
        // this.variaveisGlobais.enderecosEntidade = this.endsEntidadeRepresentada;

      },
        error => {
          console.log(error);
          console.log(error.error);
          this.spinnerService.ocultar();
          this.notificacoesService.notificarErro(error.error, null);
        });
    } else {
      this.buscaPessoasFisicas(this.entidade);
    }

    // Carrega valor inicial para projetos
    if (this.variaveisGlobais.roleEntidade) {

    } else {


    }

    // Carrega os residuos
    this.produtoService.getProdutos(this.variaveisGlobais.menuRaiz, this.variaveisGlobais.roleEntidade).subscribe(residuos => {
      console.log('Cadastro Inicializa Resíduos>>>>>>>>>>>>>>', residuos);

      this.spinnerService.ocultar();
      this.residuos = residuos;
      this.residuosTransbordo = [];
      this.residuosCompostagem = [];
      this.residuosTriagem = [];

      residuos.forEach(residuo => {
        if (residuo.gdrTransbordo) {
          this.residuosTransbordo.push(residuo);
        }
        if (residuo.gdrCompostagem) {
          this.residuosCompostagem.push(residuo);
        }
        if (residuo.gdrTriagem) {
          this.residuosTriagem.push(residuo);
        }
      });

      this.residuosTransbordo.sort(function (a, b) {
        if (a.nome > b.nome) {
          return 1;
        }
        if (a.nome < b.nome) {
          return -1;
        }
        return 0;
      });
      this.residuosCompostagem.sort(function (a, b) {
        if (a.nome > b.nome) {
          return 1;
        }
        if (a.nome < b.nome) {
          return -1;
        }
        return 0;
      });
      this.residuosTriagem.sort(function (a, b) {
        if (a.nome > b.nome) {
          return 1;
        }
        if (a.nome < b.nome) {
          return -1;
        }
        return 0;
      });

    },
      error => {
        this.notificacoesService.notificarErro('Erro ao obter resíduos', error.message);
      }
    );

    //Busca valor da UFM
    if (this.variaveisGlobais.roleEntidade) {
      this.dmluService.getValorUFM().subscribe(valorUFM => {
        // console.log('inicializaOnInit ..... getContratoIdEntidade.....>>>>>>>', contratos);
        this.valorUFM = valorUFM;

      },
        error => {
          console.log(error);
          console.log(error.error);
          this.spinnerService.ocultar();
          this.notificacoesService.notificarErro(error.error, null);
        });
    }


  }

  buscaContratos() {

    this.dmluService.getContratosIdEntidade(this.idEntidade).subscribe(contratos => {
      console.log('inicializaOnInit ..... getContratoIdEntidade.....>>>>>>>', contratos);
      this.contratos = contratos;
      this.spinnerService.ocultar();

      contratos.forEach(contrato => {
        contrato.idContratante = this.entidade.id;
      });

    },
      error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });

  }
  checkActive(route: string) {
    return route === this.activeRoute;
  }

  onSubmit() {
    // this.ngOnInit();
  }

  mostrarDialogParaNovo() {

    //Verifica se já existe contrato aberto para a empresa
    this.dmluService.getEmpresaJaTemContrato(this.entidade.id).subscribe(empresaJaTemContrato => {
      if (empresaJaTemContrato) {
        this.notificacoesService.notificarAviso('Empresa não pode iniciar novo contrato pois já possui contrato vigente ou em tramitação', null);
        return;
      }
      this.contrato = new Contrato();

      this.mostrarDialogEdit = true;
      this.insertMode = true;

      this.tituloDialogEdit = 'Novo Contrato com o DMLU';
      this.abaEmpresa = true;
      this.abaAssinaturaSei = false;
      this.abaResiduo = false;
      this.abaInfos = false;
      this.abaFinalizar = false;
      this.abaTramitacao = false;
      this.abaVigente = false;
      this.contrato.id = null;

      this.residuosSelTb = [];
      this.residuosSelCp = [];
      this.residuosSelTg = [];

      this.residuos.forEach(res => {

        this.residuosTransbordo.forEach(item => {
          item.selecionado = false;
        });
        this.residuosCompostagem.forEach(item => {
          item.selecionado = false;
        });
        this.residuosTriagem.forEach(item => {
          item.selecionado = false;
        });
      });

      // console.log('mostrarDialogParaNovo>>>>>>>>>>>>>>', this.contratoVO);

    },
      error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });

  }

  mostrarDialogParaEditar(idContrato: number) {

    this.insertMode = false;
    this.abaAssinaturaSei = false;
    this.abaResiduo = false;
    this.abaInfos = false;
    this.abaFinalizar = false;
    this.abaTramitacao = false;
    this.abaVigente = false;

    const contratoParaEditar = this.contratos.find(function (contrato) {
      console.log('00mostrarDialogParaEditar>>>>>>>>>>>>>>', idContrato);
      return contrato.id === idContrato;
    });
    console.log('11mostrarDialogParaEditar>>>>>>>>>>>>>>', this.contratos);
    this.contratoVO = { ...contratoParaEditar };

    this.dmluService.getContratoId(idContrato).subscribe(contratoEditado => {
      this.contrato = contratoEditado;

      this.enderecoSel = this.contrato.enderecoContratante.id;
      this.responsavelSel = this.contrato.responsavelLegal.id;
      // if (this.contrato.obsPendencia !== null) {
      //   this.obsPendencia = this.contrato.obsPendencia.split('***');
      // }
      if (this.contrato.dataInicial !== null) {
        this.contrato.dataInicial = new Date(this.contrato.dataInicial);
        this.contrato.dataFinal = new Date(this.contrato.dataFinal);
      }
      if (this.entidade.codigoDmlu === null) {
        this.codigoDmlu = false;
      }

      this.buscaResiduosContrato(true);
    },
      error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });


  }

  buscaResiduosContrato(isEdicaoContrato) {

    // Carrega os residuos do contrato
    this.produtoService.getProdutosContrato(this.contratoVO.id).subscribe(residuos => {
      console.log('Cadastro Inicializa Resíduos>>>>>>>>>>>>>>', residuos);

      this.spinnerService.ocultar();

      // this.residuosContrato = residuos;
      // this.residuosContrato.sort(function (a, b) {
      //   if (a.nome > b.nome) {
      //     return 1;
      //   }
      //   if (a.nome < b.nome) {
      //     return -1;
      //   }
      //   return 0;
      // });



      // this.contrato.residuosDOList = this.residuosContrato;
      this.residuosSelTb = [];
      this.residuosSelCp = [];
      this.residuosSelTg = [];

      residuos.forEach(res => {

        this.residuosTransbordo.forEach(item => {
          if (item.id === res.id) {
            item.selecionado = res.selecionado;
            this.residuosSelTb.push(res);
          }
        });
        this.residuosCompostagem.forEach(item => {
          if (item.id === res.id) {
            item.selecionado = res.selecionado;
            this.residuosSelCp.push(res);
          }
        });
        this.residuosTriagem.forEach(item => {
          if (item.id === res.id) {
            item.selecionado = res.selecionado;
            this.residuosSelTg.push(res);
          }
        });
      });

      // this.residuosSelTb = this.residuosTransbordo;
      // this.residuosSelCp = this.residuosCompostagem;
      // this.residuosSelTg = this.residuosTriagem;
      // console.log('-----resíduos Transbordo>>>', this.residuosSelTb);
      // console.log('-----resíduos Compostagem>>>', this.residuosSelCp);
      // console.log('-----resíduos Triagem>>>', this.residuosSelTg);


      if (isEdicaoContrato) {

        this.mostrarDialogEdit = true;
        this.abaEmpresa = true;
        this.codigoDmlu = true;
      } else {

        this.alteracaoResiduos = true;
      }

    },
      error => {
        this.notificacoesService.notificarErro('Erro ao obter resíduos', error.message);
      }
    );

  }

  editarContratoTramitacao(idContrato, situacao) {
    this.horaVistoria = null;
    const contratoParaEditar = this.contratos.find(function (contrato) {
      return contrato.id === idContrato;
    });
    this.contratoVO = { ...contratoParaEditar };

    this.dmluService.getContratoId(idContrato).subscribe(contratoEditado => {
      this.contrato = contratoEditado;

      // console.log('cpntratoElaborado>>>>>>>>>>>>>>', this.contratoVO);
      this.enderecoSel = this.contrato.enderecoContratante.id;
      this.responsavelSel = this.contrato.responsavelLegal.id;

      if (situacao === 'analiseSAC') {
        this.contratoAnaliseSAC = true;
      }

      if (situacao === 'infRegularidade') {
        this.contratoInfDivida = true;
      }

      if (situacao === 'analiseSMCA') {
        this.codigoDmlu = true;
        if (this.entidade.codigoDmlu === null) {
          this.codigoDmlu = false;
        }
        this.contratoAnaliseSMCA = true;
        if (this.contrato.situacao === 'Cadastro encaminhado para análise técnica') {
          this.tituloDialogEdit = 'Análise Técnica da Empresa ' + this.entidade.razaoSocial;
        }
        if (this.contrato.situacao === 'Aguardando vistoria técnica') {
          this.tituloDialogEdit = 'Análise Técnica da Empresa ' + this.entidade.razaoSocial +
            ' - Andamento do contrato após a vistoria técnica.';
        }
        this.selectedSituacao = 'Aprovado'; // Inicializa as opções na tela de edição
      }

      if (situacao === 'analiseIsencaoTarifa') {
        this.contratoDiretorGeral = true;
        this.tituloDialogEdit = 'Análise para isenção de tarifa da Empresa ' + this.entidade.razaoSocial;
        this.selectedAnaliseIsencao = 'Deferida'; // Inicializa as opções na tela de edição
      }

      if (situacao === 'complementar') {
        this.contratoInfComplementar = true;
      }

      if (situacao === 'vistoria') {
        this.contratoVistoria = true;
      }

      if (situacao === 'analiseNCC') {
        this.numeroContrato = true;
        if (this.contrato.numeroContrato === null) {
          this.numeroContrato = false;
        }
        this.contratoAnaliseNCC = true;
        this.tituloDialogEdit = 'Análise Documental da Empresa ' + this.entidade.razaoSocial;
        this.selectedAnaliseDocumental = 'Aprovada'; // Inicializa as opções na tela de edição

      }

      if (situacao === 'ncccomplementar') {
        this.contratoNccInfComplementar = true;
      }

      if (situacao === 'assinaturaEmpresa') {
        this.contratoAssinaturaEmpresa = true;
      }
      if (situacao === 'assinaturaPmpa') {
        this.contratoAssinaturaPmpa = true;
      }
      this.insertMode = false;

    },
      error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });
  }

  buscaPessoasFisicas(entidade: Entidade) {
    //    BUSCA PessoaFisica
    this.pessoaFisicaService.getPessoasFisicasResponsavelLegalEntidade(entidade.id).subscribe(pessoasFisicas => {
      this.responsaveisEntidade = pessoasFisicas;

      if (pessoasFisicas.length > 0) {
        this.responsavelSel = pessoasFisicas[0].id;
        this.responsavel = pessoasFisicas[0];
      }
    },
      error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });
  }

  buscaEnderecos(entidade: Entidade) {
    //    BUSCA PessoaFisica
    this.enderecoService.getEnderecosPorEntidade(entidade.id).subscribe(enderecos => {
      this.enderecosEntidade = enderecos;

      if (enderecos.length > 0) {
        this.enderecoSel = enderecos[0].id;
        this.endereco = enderecos[0];
      }
    },
      error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });
  }

  responsavelSelecionado(pessoaFisica) {
    console.log('Responsável  respSelecionado>>>>>>>', pessoaFisica);
    const pfParaEditar = this.responsaveisEntidade.find(function (pf) {
      return pf.id === pessoaFisica;
    });
    this.responsavel = { ...pfParaEditar };
    // this.contrato.responsavelLegal = pessoaFisica;

  }

  enderecoSelecionado(endereco) {
    console.log('Responsável  endereçoSelecionado>>>>>>>', endereco);
    const enderecoParaEditar = this.enderecosEntidade.find(function (end) {
      return end.id === endereco;
    });
    this.endereco = { ...enderecoParaEditar };
    // this.contrato.enderecoContratante = endereco;

  }

  visualizaAnexo(tipoAnexo) {
    let anexo = new Anexo();
    if (tipoAnexo === 'cpfRg') { anexo = this.anexoCpgRg; }
    if (tipoAnexo === 'contratoEstatuto') { anexo = this.anexoContratoSocialEstatuto; }
    if (tipoAnexo === 'procuracao') { anexo = this.anexoProcuracao; }

    this.spinnerService.exibir();
    this.anexoService.getAnexo(anexo.id).subscribe(anexoDO => {

      this.spinnerService.ocultar();

      console.log('visualizaAnexo Anexo>>>>>>>', anexoDO);
      const tipoArquivo = anexoDO.fileType.split('/');
      console.log('Tipo Arquivo>>>>>', tipoArquivo);

      if (tipoArquivo[0] === 'image') {
        const image = new Image();
        image.src = 'data:image/jpg;base64,' + anexoDO.file;

        const w = window.open('');
        w.document.write(image.outerHTML);
      } else if (tipoArquivo[0] === 'application') {
        if (tipoArquivo[1] === 'pdf') {
          var byteCharacters = atob(anexoDO.file);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var file = new Blob([byteArray], { type: 'application/pdf;base64' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else if (tipoArquivo[1] === 'vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          tipoArquivo[1] === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          //docx

          // var reader = new FileReader();
          var byteCharacters = atob(anexoDO.file);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var file = new Blob([byteArray], { type: 'application/octet-stream' });
          var downloadUrl = window.URL.createObjectURL(file);
          var a = document.createElement('a');
          a.href = downloadUrl;
          a.download = anexoDO.fileName;
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(downloadUrl);
        } else {
          this.notificacoesService.notificarSucesso('Arquivo não previsto para visualização!', '');
        }
      }

    },
      error => {
        console.log('ERRO>>>>>>', error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });

    // var FileSaver = require('file-saver');
    // var blob = new Blob(["Hello, world!"], { type: "text/plain;charset=utf-8" });
    // FileSaver.saveAs(blob, "hello world.txt");
    // console.log("File Saver>>>>>", FileSaver)

    // const anexoPdf = atob(anexo.value);
    // const b = new Blob([(<any>anexoPdf)], { type: 'application/pdf;base64' });
    // const fileURL = URL.createObjectURL(b);
    // window.open(fileURL);
  }

  recuperaAnexosEntidade(idEntidade: number) {

    this.anexoCpgRg = null;
    this.anexoContratoSocialEstatuto = null;
    this.anexoProcuracao = null;
    this.anexoRelDivida = null;
    this.anexoBoletoPago = null;

    this.anexos = [];
    this.anexoService.getAnexosPorEntidade(idEntidade, this.variaveisGlobais.menuRaiz).subscribe(
      anexos => {
        this.anexos = anexos;

        this.isCpfRg = false;
        this.cpfRg = 'Por favor, anexar o CPF/RG do Responsável Legal!' +
          ' Para incluir o anexo, acesse a edição de Empresas, aba de ANEXOS.';
        this.isContratoSocialEstatuto = false;
        this.contratoSocialEstatuto = 'Por favor, anexar o Contrato Social ou Estatuto do Responsável Legal!' +
          ' Para incluir o anexo, acesse a edição de Empresas, aba de ANEXOS.';
        this.isProcuracao = false;
        this.procuracao = 'Empresa sem necessidade de procuração de representação!';

        this.isBoletoPago = false;
        this.isCpfRg = false;
        this.isProcuracao = false;
        this.isRelDivida = false;

        this.anexos.forEach(anexo => {
          if (anexo.tipoAnexo === 'CPF/RG') {
            this.cpfRg = anexo.fileName;
            this.isCpfRg = true;
            this.anexoCpgRg = anexo;
          }
          if (anexo.tipoAnexo === 'Contrato Social') {
            this.contratoSocialEstatuto = anexo.fileName;
            this.isContratoSocialEstatuto = true;
            this.anexoContratoSocialEstatuto = anexo;
          }
          if (anexo.tipoAnexo === 'Procuração') {
            this.procuracao = anexo.fileName;
            this.isProcuracao = true;
            this.anexoProcuracao = anexo;
          }
          if (anexo.tipoAnexo === 'Relatório Dívida') {
            this.relDivida = anexo.fileName;
            this.isRelDivida = true;
            this.anexoRelDivida = anexo;
          }
          if (anexo.tipoAnexo === 'Boleto Pago') {
            this.boletoPago = anexo.fileName;
            this.isBoletoPago = true;
            this.anexoBoletoPago = anexo;
          }
          // console.log('Boletoooooo>>>>>>>>>anexos>>>>', anexo.tipoAnexo);
        });
      },
      error => {
        console.log('ListaEntidade>>>>>>>>>erro>>>>', error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      }
    );
  }

  salvar() {

    // Cadastro encaminhado para análise técnica

    // Pendente com NCC - elaboração do contrato
    // Pendente com NCC - assinatura do SAF
    // Pendente com usuário - assinar contrato
    // Pendente com usuário - documentos e/ou SEI
    // Contrato vigente
    // Contrato vencido
    // Contrato pendente de vistoria
    // NCC - Núcleo de Contratos e Convênios
    // NCC/DMLU/PMPA - fone 3289-6841
    this.contrato.contratante = this.entidade;
    this.contrato.responsavelLegal = this.responsavel;
    // this.enderecoSel.entidadeDO = null;
    this.contrato.enderecoContratante = this.endereco;
    this.contrato.situacao = 'Cadastro encaminhado para verificação de regularidade no SAC';

    this.contrato.rgCPFAnexo = this.anexoCpgRg;
    this.contrato.contratoSocialEstatutoAnexo = this.anexoContratoSocialEstatuto;
    this.contrato.procuracaoRepresentacaoAnexo = this.anexoProcuracao;

    this.contrato.residuosDOList = [];
    // if (this.contrato.outrosResiduos === undefined) {
    //   this.contrato.outrosResiduos = null;
    // }
    // console.log('Contrato Salvar....>>>', this.contrato);

    this.residuosSelTb.forEach(residuo => {
      if (residuo.selecionado !== undefined) {
        if (residuo.selecionado) {
          this.contrato.residuosDOList.push(residuo);
        }
      }
    });

    this.residuosSelCp.forEach(residuo => {
      if (residuo.selecionado !== undefined) {
        if (residuo.selecionado) {
          this.contrato.residuosDOList.push(residuo);
        }
      }
    });

    this.residuosSelTg.forEach(residuo => {
      if (residuo.selecionado !== undefined) {
        if (residuo.selecionado) {
          this.contrato.residuosDOList.push(residuo);
        }
      }
    });
    if (this.empresaIsentaTarifa === true) {
      this.contrato.isencaoTarifa = true;
    } else {
      this.contrato.isencaoTarifa = false;
    }



    // console.log('Contrato Salvar....>>>', this.contrato);



    // Verifica se todos os campos foram preenchidos
    if (this.contrato.contratante.email == null) {
      this.notificacoesService.notificarAviso('Por favor informe o Email da empresa!', null);
      return;
    }
    if (this.contrato.enderecoContratante == null) {
      this.notificacoesService.notificarAviso('Por favor informe o Endereço da empresa!', null);
      return;
    }
    if (this.contrato.responsavelLegal == null) {
      this.notificacoesService.notificarAviso('Por favor informe o Responsável Legal da empresa!', null);
      return;
    }
    if (this.contrato.rgCPFAnexo == null) {
      this.notificacoesService.notificarAviso('Por favor incluir o CPF/RG do Responsável Legal!', null);
      return;
    }
    if (this.contrato.contratoSocialEstatutoAnexo == null) {
      this.notificacoesService.notificarAviso('Por favor incluir o Contrato Social ou Estatuto da Empresa!', null);
      return;
    }
    if (this.contrato.residuosDOList.length < 1) {
      this.notificacoesService.notificarAviso('Por favor informe os resíduos que serão descartados!', null);
      return;
    }

    this.dmluService.novoContrato(this.contrato).subscribe(contratoNovo => {
      console.log('salvado....................>>>>>>>>>>>>>>', contratoNovo);

      this.buscaContratos();
      this.mostrarDialogEdit = false;
      this.spinnerService.ocultar();
      this.notificacoesService.notificarSucesso('Adicionado com sucesso', null);
    },
      error => {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro('Erro ao adicionar ', error);
      });
  }

  atualizarResiduos() {

    this.spinnerService.exibir();
    this.dmluService.getContratoId(this.contrato.id).subscribe(contratoEditado => {
      this.contrato = contratoEditado;
      this.contrato.residuosDOList = [];

      this.residuosSelTb.forEach(residuo => {
        if (residuo.selecionado !== undefined) {
          if (residuo.selecionado) {
            this.contrato.residuosDOList.push(residuo);
          }
        }
      });

      this.residuosSelCp.forEach(residuo => {
        if (residuo.selecionado !== undefined) {
          if (residuo.selecionado) {
            this.contrato.residuosDOList.push(residuo);
          }
        }
      });

      this.residuosSelTg.forEach(residuo => {
        if (residuo.selecionado !== undefined) {
          if (residuo.selecionado) {
            this.contrato.residuosDOList.push(residuo);
          }
        }
      });
      //Verifica se todos os campos foram preenchidos
      if (this.contrato.residuosDOList.length < 1) {
        this.notificacoesService.notificarAviso('Por favor informe os resíduos que serão descartados!', null);
        return;
      }

      console.log('Contrato Salvar....>>>', this.contrato);

      this.dmluService.atualizarResiduos(this.contrato).subscribe(contratoEditado => {

        this.buscaContratos();

        this.alteracaoResiduos = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso('Atualizado com sucesso', null);
      },
        error => {
          this.spinnerService.ocultar();
          this.notificacoesService.notificarErro('Erro ao atualizar ', error);
        });
    },
      error => {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro('Erro ao recuperar contrato ', error);
      });

  }

  // contratoParaAtualizar(situacao, usuario) {

  //   this.spinnerService.exibir();
  //   this.dmluService.getContratoId(this.contrato.id).subscribe(contratoEditado => {
  //     this.contrato = contratoEditado;

  //     if (usuario == 'PMPA') {
  //       this.contratoAndamentoPmpa(situacao);
  //     } else {
  //       this.contratoAndamentoEmpresa(situacao);
  //     }

  //   },
  //   error => {
  //     this.spinnerService.ocultar();
  //     this.notificacoesService.notificarErro('Erro ao recuperar contrato ', error);
  //   });

  // }

  contratoAndamentoPmpa(situacao) {

    if (situacao === 'contratoAnaliseSAC') {
      if (this.adimplenteInadimplente === 'adimplente') {
        this.contrato.situacao = 'Cadastro encaminhado para análise técnica';
      } else {
        this.contrato.situacao = 'Cadastro aguardando regularização da dívida';
      }
      this.empresaAdimplenteInadimplente();
      this.atualizaContrato(this.contrato);
      this.contratoAnaliseSAC = false;

    }

    if (situacao === 'contratoAnaliseSMCA') {
      if (this.selectedSituacao === 'Aprovado') {
        if (this.entidade.codigoDmlu === null) {
          this.notificacoesService.notificarAviso('Por favor informar o Código da balança!', null);
          return;
        } else {

          if (this.contrato.isencaoTarifa) {
            this.contrato.situacao = 'Encaminhado para o Diretor Geral';
          } else {
            this.contrato.situacao = 'Encaminhado para análise documental';
          }

          this.entidadeService.editarEntidade(this.entidade).subscribe(entidade => {
            this.entidade = entidade;
            this.contrato.contratante = entidade;
            this.atualizaContrato(this.contrato);

          },
            error => {
              console.log(error);
              this.spinnerService.ocultar();
              this.notificacoesService.notificarErro(error.error, null);
            });


        }
      }
      if (this.selectedSituacao === 'Complemento') {
        if (this.contrato.obsSmcaComplementar === null || this.contrato.obsSmcaComplementar === '') {
          this.notificacoesService.notificarAviso('Por favor informar as orientações sobre os documentos complementares necessários!', null);
          return;
        } else {
          this.contrato.situacao = 'Aguardando avaliação técnica complementar';

          this.atualizaContrato(this.contrato);
        }
      }
      if (this.selectedSituacao === 'Vistoria') {

        this.contrato.dataVistoria = null;
        if (this.dataVistoria !== undefined) {
          this.contrato.dataVistoria = this.dataVistoria;
        }
        if (this.contrato.obsSmcaVistoria === null || this.contrato.obsSmcaVistoria === '') {
          this.notificacoesService.notificarAviso('Por favor informar as orientações necessárias à vistoria!', null);
          return;
        }
        if (this.contrato.dataVistoria === null) {
          this.notificacoesService.notificarAviso('Por favor informar a data/hora sugeridas para a realização da vistoria!', null);
          return;
        }
        if (this.horaVistoria === null) {
          this.notificacoesService.notificarAviso('Por favor informar a data/hora sugeridas para a realização da vistoria!', null);
          return;
        }
        this.contrato.situacao = 'Aguardando vistoria técnica';
        this.contrato.dataVistoria.setHours(new Date(this.horaVistoria).getHours());
        this.contrato.dataVistoria.setMinutes(new Date(this.horaVistoria).getMinutes());

        this.atualizaContrato(this.contrato);

      }
      if (this.selectedSituacao === 'Reprovado') {
        this.contrato.situacao = 'Cadastro técnico reprovado';

        this.atualizaContrato(this.contrato);
      }
      this.contratoAnaliseSMCA = false;

    }

    if (situacao === 'contratoVistoria') {
      if (this.entidade.codigoDmlu === null) {
        this.notificacoesService.notificarErro('Por favor informar o Código da balança!', null);
        return;
      } else {
        this.contrato.situacao = 'Encaminhado para análise documental';

        this.entidadeService.editarEntidade(this.entidade).subscribe(entidade => {
          this.entidade = entidade;
          this.contrato.contratante = entidade;
          this.atualizaContrato(this.contrato);

        },
          error => {
            console.log(error);
            this.spinnerService.ocultar();
            this.notificacoesService.notificarErro(error.error, null);
          });
      }
      this.contratoAnaliseSMCA = false;

    }
    // console.log('----------contratoAndamentoPmpa>>>>>', situacao);

    if (situacao === 'contratoAnaliseIsencao') {
      // console.log('----------contratoAnaliseIsencao>>>>>', this.selectedAnaliseIsencao);
      // console.log('----------contratoAnaliseIsencao>>>>>', this.selectedIsencaoPrazo);
      if (this.selectedAnaliseIsencao === 'Deferida') {
        if (this.selectedIsencaoPrazo === undefined || this.selectedIsencaoPrazo === '') {
          this.notificacoesService.notificarAviso('Por favor, informar a opção de isenção!', null);
          return;
        }
        if (this.selectedIsencaoPrazo === 'isencaoDescargas') {
          if (this.contrato.isencaoDescargas === null) {
            this.notificacoesService.notificarAviso('Por favor, informar o Número de descargas!', null);
            return;
          }
        }
        if (this.selectedIsencaoPrazo === 'isencaoDeterminada') {
          if (this.contrato.isencaoData === null) {
            this.notificacoesService.notificarAviso('Por favor, informar a Data limite para a isenção!', null);
            return;
          }
        }
        if (this.selectedIsencaoPrazo === 'isencaoDescargas') {
          this.contrato.isencaoData = null;
        }
        if (this.selectedIsencaoPrazo === 'isencaoDeterminada') {
          this.contrato.isencaoDescargas = null;
        }
        this.contrato.situacao = 'Encaminhado para análise documental';
      }
      if (this.selectedAnaliseIsencao === 'Indeferida') {
        this.contrato.situacao = 'Solicitação de isenção indeferida';
      }
      // console.log('----------contratoAndamentoPmpa>>>>>', this.contrato);
      this.atualizaContrato(this.contrato);
      this.contratoDiretorGeral = false;

    }

    if (situacao === 'contratoAnaliseNCC') {
      if (this.selectedAnaliseDocumental === 'Aprovada') {
        if (this.contrato.numeroContrato === null) {
          this.notificacoesService.notificarAviso('Por favor informar o Número do contrato!', null);
          return;
        }
        if (this.contrato.anoContrato === null) {
          this.notificacoesService.notificarAviso('Por favor informar o Ano do contrato!', null);
          return;
        }
        // if (this.selectedSEI === 'liberada') {
        this.contrato.situacao = 'Contrato aguardando assinaturas digitais no SEI';
        // }
        // if (this.selectedSEI === 'naoLiberada') {
        //   this.contrato.situacao = 'Aguarda liberação de assinatura eletrônica do Gerador no sistema SEI';
        // }

      }
      if (this.selectedAnaliseDocumental === 'Complemento') {
        if (this.contrato.obsNccComplementar === null || this.contrato.obsNccComplementar === '') {
          this.notificacoesService.notificarAviso('Por favor informar as orientações sobre os ' +
            'documentos complementares necessários!', null);
          return;
        } else {
          this.contrato.situacao = 'Aguardando complementação de documentação';
        }
      }
      if (this.selectedAnaliseDocumental === 'Reprovada') {
        this.contrato.situacao = 'Análise documental reprovada';
      }
      this.atualizaContrato(this.contrato);
      this.contratoAnaliseNCC = false;

    }

    if (situacao === 'contratoAssinadoPmpa') {
      if (this.contrato.dataInicial === null) {
        this.notificacoesService.notificarAviso('Por favor informar a data da assinatura do contrato no SAF!', null);
        return;
      }
      this.contrato.situacao = 'Contrato vigente';
      this.atualizaContrato(this.contrato);
      this.contratoAssinaturaPmpa = false;
      // this.empresaAdimplenteInadimplente(this.entidade.id, true);
    }

  }

  contratoAndamentoEmpresa(situacao) {

    if (situacao === 'contratoInfDivida') {

      // if (this.isRelDivida) {
      if (!this.isBoletoPago) {
        this.notificacoesService.notificarAviso('Por favor, antes de reenviar o Contrato providencie o pagamento dos ' +
          'débitos e após insira o Boleto Pago nos anexos da Empresa.', null);
        return;
      }
      // }

      this.contrato.situacao = 'Cadastro reencaminhado para verificação de regularidade no SAC';
      this.atualizaContrato(this.contrato);
      this.contratoInfDivida = false;
    }

    if (situacao === 'contratoAnaliseSMCA') {
      this.contrato.situacao = 'Cadastro encaminhado para análise técnica';
      this.atualizaContrato(this.contrato);
      this.contratoInfComplementar = false;
    }

    if (situacao === 'contratoAnaliseSMCAResiduos') {
      this.contrato.situacao = 'Cadastro encaminhado para análise técnica - resíduos';
      this.atualizaContrato(this.contrato);
      this.contratoInfComplementar = false;
    }

    if (situacao === 'contratoInfComplementar') {
      let anexoComplementar: Boolean = false;
      this.anexos.forEach(anexo => {
        if (anexo.tipoAnexo === 'Complementar') {
          anexoComplementar = true;
        }
      });
      console.log('................contratoAndamentoEmpresa>>>', this.contrato);
      if (!anexoComplementar) {
        if (this.contrato.obsSmcaComplementada === null || this.contrato.obsSmcaComplementada === '') {
          this.notificacoesService.notificarAviso('Por favor providenciar as informações complementares solicitadas!', null);
          return;
        }
      }
      this.contrato.situacao = 'Cadastro reencaminhado para análise técnica';
      this.atualizaContrato(this.contrato);
      this.contratoInfComplementar = false;
    }

    if (situacao === 'contratoNccInfComplementar') {
      // let anexoComplementar: Boolean = false;
      // this.anexos.forEach(anexo => {
      //   if (anexo.tipoAnexo === 'Complementar') {
      //     anexoComplementar = true;
      //   }
      // });
      // console.log('................contratoAndamentoEmpresa>>>', this.contrato);
      // if (!anexoComplementar) {
      if (this.contrato.obsNccComplementada === null || this.contrato.obsNccComplementada === '') {
        this.notificacoesService.notificarAviso('Por favor providenciar as informações complementares solicitadas!', null);
        return;
      }
      // }
      this.contrato.situacao = 'Reencaminhado para análise documental';
      this.atualizaContrato(this.contrato);
      this.contratoNccInfComplementar = false;
    }

    if (situacao === 'contratoAssinadoEmpresa') {
      this.contrato.situacao = 'Contrato assinado pelo Gerador';
      this.atualizaContrato(this.contrato);
      this.contratoAssinaturaEmpresa = false;

    }


  }

  atualizaContrato(contrato) {

    this.spinnerService.exibir();
    this.dmluService.contratoAndamento(contrato).subscribe(contratoEditado => {
      // Remove o valor atual
      this.contratos = this.contratos.filter(item => item.id !== this.contrato.id);

      this.buscaContratos();

      this.spinnerService.ocultar();
      this.notificacoesService.notificarSucesso('Contrato atualizado com sucesso', null);
    },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });


  }

  // controlaOpcao(opcao) {
  //   console.log('.......controlaFuncao>>>>' , opcao);
  //   if (opcao === 'documentos') {
  //     this.sistemaSEI = '';
  //     this.contrato.numeroSei = null;
  //   }
  //   if (opcao === 'sistemaSEI') {
  //     this.documentos = '';
  //     this.contrato.numeroSei = null;
  //   }
  //   if (opcao === 'numeroSEI') {
  //     this.sistemaSEI = '';
  //     this.documentos = '';
  //   }

  // }

  // controlaSituacao(situacao) {

  //   console.log('.......controlaSituacao..............>>>>' , situacao);
  //   //this.selectedSituacao = situacao;

  // }

  gerarNovoCodigoDmlu(idEntidade) {

    this.spinnerService.exibir();

    this.dmluService.gerarCodigoDmlu(idEntidade).subscribe(codigoDmlu => {

      this.entidade.codigoDmlu = codigoDmlu;

      this.spinnerService.ocultar();
      // this.notificacoesService.notificarSucesso('Código Balança gerado com sucesso', null);
    },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });

  }

  empresaAdimplenteInadimplente() {

    this.spinnerService.exibir();

    let adimplente: boolean = false;
    if (this.adimplenteInadimplente === 'adimplente') {
      adimplente = true;
    }

    this.dmluService.empresaAdimplenteInadimplente(this.entidade.id, adimplente).subscribe(entidade => {

      this.entidade = entidade;

      this.spinnerService.ocultar();
      // this.notificacoesService.notificarSucesso('Código Balança gerado com sucesso', null);
    },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });

  }
  gerarNovoNumeroContrato() {

    this.spinnerService.exibir();

    this.dmluService.gerarNumeroContrato().subscribe(numeroContrato => {

      this.contrato.numeroContrato = numeroContrato;
      this.contrato.anoContrato = new Date().getFullYear();

      this.spinnerService.ocultar();
      // this.notificacoesService.notificarSucesso('Código Balança gerado com sucesso', null);
    },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });

  }

  novosTiposResiduos() {
    this.novoTipoResiduo = true;
  }

  enviaEmailSMCA() {
    this.novoTipoResiduo = false;

    this.spinnerService.exibir();

    this.dmluService.enviaEmailSMCA(this.entidade.id, this.residuoNovo).subscribe(retorno => {

      this.spinnerService.ocultar();
      // this.notificacoesService.notificarSucesso('Código Balança gerado com sucesso', null);
    },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });

  }

  atualizaSelecaoResiduo(residuoSel, selecionado: boolean, tipoLocal) {
    console.log('------------------------------>>', residuoSel, '---', selecionado);

    if (tipoLocal === 'transbordo') {
      this.residuosTransbordo.forEach(residuo => {
        if (residuoSel === residuo.id) {
          residuo.selecionado = selecionado;
        }
      });
      this.residuosSelTb = [];

      this.residuosTransbordo.forEach(res => {
        if (res.selecionado) {
          this.residuosSelTb.push(res);
        }
      });
    }
    if (tipoLocal === 'compostagem') {
      this.residuosCompostagem.forEach(residuo => {
        if (residuoSel === residuo.id) {
          residuo.selecionado = selecionado;
        }
      });
      this.residuosSelCp = [];

      this.residuosCompostagem.forEach(res => {
        if (res.selecionado) {
          this.residuosSelCp.push(res);
        }
      });
    }
    if (tipoLocal === 'triagem') {
      this.residuosTriagem.forEach(residuo => {
        if (residuoSel === residuo.id) {
          residuo.selecionado = selecionado;
        }
      });
      this.residuosSelTg = [];

      this.residuosTriagem.forEach(res => {
        if (res.selecionado) {
          this.residuosSelTg.push(res);
        }
      });
    }


    // console.log('------------------------------>>', this.residuos);
    // console.log('------------------------------>>', this.residuosContrato);

  }

  alterarResiduos(idContrato) {
    // console.log('------------------------------>>', this.residuos);
    const contratoParaEditar = this.contratos.find(function (contrato) {
      return contrato.id === idContrato;
    });
    this.contratoVO = { ...contratoParaEditar };

    this.dmluService.getContratoId(idContrato).subscribe(contratoEditado => {
      this.contrato = contratoEditado;
      console.log('Cadastro Inicializa Resíduos>>>>>buscaResiduosContrato>>>>>>>>>', this.contrato);

      this.buscaResiduosContrato(false);

    },
      error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });


  }

  changeCheckmark(boolEvt: boolean, residuo: any) {
    console.log(boolEvt + ' ' + residuo);

    if (boolEvt) {
      this.residuosSelTb.push(residuo);
    } else {
      for (let index = 0; index < this.residuosSelTb.length; index++) {
        const element = this.residuosSelTb[index];

        if (element.id === residuo.id) {
          this.residuosSelTb.splice(index, 1);
        }
      }
    }

    console.log(this.residuosSelTb);
  }

  atualizaIsencao(selecionado: boolean) {
    // console.log('------------atualizaIsencao------------------>>', selecionado);

    this.empresaIsentaTarifa[0] = selecionado;
    this.contrato.isencaoTarifa = selecionado;
    // console.log('------------atualizaIsencao------------------>>', this.empresaIsentaTarifa[0]);

  }
}
