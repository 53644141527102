<sgr-menu></sgr-menu>
<div class="container">

  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/sgrIni">SGR</a></li>
    <li class="breadcrumb-item active" aria-current="page">Cadastro</li>
  </ol> 

  <h1 class="page-header text-primary">
    Licença Ambiental<br>
    <small>Cadastro</small>
  </h1>

  <!-- <h2
    class="text-primary mt-4 mb-0 pb-4"
    style="font-family: 'Roboto', 'Trebuchet MS', Arial, Helvetica, sans-serif"
  >
    Licença Ambiental
  </h2> -->

  <div style="overflow-x: auto">
    <p-table #tabelaLicencasAmbientais [value]="licencasAmbientais" [paginator]="true" sortField="licenca" [rows]="10"
      [globalFilterFields]="['licenca']">
      <ng-template pTemplate="caption">
        <div class="row justify-content-between">
          <div class="col-auto form-group">
            <button type="button" class="btn btn-primary text-nowrap" (click)="mostrarDialogParaNovo()"
              label="Adicionar" *ngIf="!variaveisGlobais.roleEntidade">
              <i class="fa fa-plus pr-2"></i>Adicionar
            </button>
          </div>
          <div class="col-sm-12 col-md-6 form-group busca">
            <input type="text" pInputText size="50" placeholder="Filtrar licenças"
              (input)="tabelaLicencasAmbientais.filterGlobal($event.target.value, 'contains')" class="form-control" />
              <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="licenca" class="text-center" style="width: 100px; height: 60px;">
            Licenca
            <p-sortIcon field="licenca"></p-sortIcon>
          </th>
          <th style="width: 210px;">Órgão Emissor</th>
          <th style="width: 130px;">Validade</th>
          <th style="width: 60px;" class="text-center">
            Ações
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-licencaAmbiental>
        <tr>
          <td>
            {{ licencaAmbiental?.licenca }}
          </td>
          <td>
            {{licencaAmbiental?.orgaoEmissor}}
          </td>
          <td>
            {{ licencaAmbiental?.dataValidade | date: 'dd/MM/yyyy' : 'pt' }}
          </td>
          <td>
            <button title="Editar" (click)="mostrarDialogParaEditar(licencaAmbiental.id)" label="Detalhes"
              class="btn btn-outline-secondary mx-2">
              <i class="fa fa-info pr-2"></i>Detalhes
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>



<!-- Dialog para novo -->
<p-dialog [header]="tituloDialogEdit" [(visible)]="mostrarDialogEdit" [responsive]="true" showEffect="fade"
  [modal]="true" [width]="700">
  <div class="row">
    <div class="col-auto form-group">
      <label for="vin">Código</label>
      <input pInputText id="licencaAmbiental" [(ngModel)]="licencaAmbiental.licenca" maxlength="15" class="form-control" />
    </div>
    <div class="col-auto form-group">
      <label for="vin">Validade</label>
      <p-calendar [(ngModel)]="licencaAmbiental.dataValidade" [showIcon]="true"
        dateFormat="dd/mm/yy"></p-calendar>
    </div>
    <div class="col-auto form-group">
      <label for="vin">Órgão Emissor</label>
      <p-dropdown [options]="secretarias" [(ngModel)]="licencaAmbiental.secretariaPmpaDO" placeholder="Selecione">
      </p-dropdown>
    </div>
    <div class="col-12 form-group">
      <label for="vin">Descrição</label>
      <textarea pInputTextarea id="descricao" [(ngModel)]="licencaAmbiental.descricao" [rows]="3"
        [cols]="80" class="form-control"></textarea>
    </div>
  </div>

  <div class="botoes text-right" *ngIf="!variaveisGlobais.roleEntidade">
    <button type="button" (click)="mostrarDialogEdit = false" label="Cancelar" class="btn btn-outline-secondary">
      Cancelar
    </button>
    <button type="button" (click)="salvar()" label="Salvar" class="btn btn-primary">
      Salvar
    </button>
  </div>
</p-dialog>