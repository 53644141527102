import { Entidade } from './entidade.model';

export class Certificado {
  id: number;
  timestampAsString: string;
  dataInclusao: string;
  usuarioInclusao: string;
  usuarioAlteracao: string;
  contratante: Entidade;
  mesCompetencia: number;
  anoCompetencia: number;
  // numeroCertificado: number;
  recebimento: Date;
	placaVeiculo: string;
	quantidade: number;
	apontador: string;
  matricula: number;
  isentoMtrFepam: boolean;
  competencia: string;
  competenciaSort: string;
}

export interface CertificadoInter {
  id: number;
  timestampAsString: string;
  dataInclusao: string;
  usuarioInclusao: string;
  usuarioAlteracao: string;
  contratante: Entidade;
  mesCompetencia: number;
  anoCompetencia: number;
  // numeroCertificado: number;
  recebimento: Date;
	placaVeiculo: string;
	quantidade: number;
	apontador: string;
	matricula: number;
  isentoMtrFepam: boolean;
  competencia: string;
  competenciaSort: string;
}
