import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { merge, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MenuItem, MessageService, SelectItem } from 'primeng/api';
import { ClassificacaoService } from '../../@core/services/classificacao.service';
import { EnderecoService } from '../../@core/services/endereco.service';
import { NotificacoesService } from '../../@core/services/notificacoes.service';
import { SmfPessoaService } from '../../@core/services/smfPessoa.service';
import { SpinnerService } from '../../@core/services/spinner.service';
import { VeiculoService } from '../../@core/services/veiculo.service';
import { Classificacao } from '../../@shared/models/classificacao.model';
import { Endereco } from '../../@shared/models/endereco';
import { EnderecoEntidade } from '../../@shared/models/enderecoEntidade';
import { Entidade } from '../../@shared/models/entidade.model';
import { Etapas } from '../../@shared/models/etapa.model';
import { LogradouroCdl } from '../../@shared/models/logradouroCdl';
import { Manifesto } from '../../@shared/models/manifesto.model';
import { Obras } from '../../@shared/models/obra.model';
import { PessoaFisica } from '../../@shared/models/pessoaFisica.model';
import { Produto } from '../../@shared/models/produto.model';
import { Veiculo } from '../../@shared/models/veiculo.model';
import { ptBr } from '../../@shared/utils/calendar';
import { Globals } from '../../globals';
import { ManifestoService } from '../manifesto.service';
import { EntidadeService } from 'src/app/@core/entidade/entidade.service';
import { ProdutoService } from 'src/app/@core/produto/produto.service';
import { Localizacao } from 'src/app/@shared/models/localizacao';
import { PessoaFisicaService } from 'src/app/@core/services/pessoaFisica.service';
import * as jspdf from 'jspdf';
import { SecretariaPmpaService } from 'src/app/@core/services/secretariaPmpa.service';
import { SecretariaPmpa } from 'src/app/@shared/models/secretariaPmpa.model';
import { version } from 'punycode';
import { ObraPmpa } from 'src/app/@shared/models/obraPmpa.model';
import { ObraPmpaService } from 'src/app/@core/services/obraPmpa.service';
import { getLocaleDateTimeFormat } from '@angular/common';
import { ManifestoResultado } from 'src/app/@shared/models/manifestoTotalResultado.model';
import { ManifestoTotalEmpresa } from 'src/app/@shared/models/manifestoTotalEmpresa.model';
import { ManifestoTotalResiduo } from 'src/app/@shared/models/manifestoTotalResiduo.model';

@Component({
  templateUrl: './resultadosManifestos.component.html',
  styleUrls: ['./resultadosManifestos.component.scss'],
  providers: [MessageService]
})



export class ResultadosManifestosComponent implements OnInit {

  roleEntidadeMtrcc: Boolean = false;
  dataInicial: Date = new Date();
  dataFinal: Date = new Date();
  idEntidade: number = 0;
  activeRoute: string;

  variaveisGlobais;
  languageCalendar: any;
  submitted = false;
  numLinha = 0;
  linhas = 0;
  paginas = 0;

  totalManifestos = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private manifestoService: ManifestoService,
    private keycloakService: KeycloakService,
    private entidadeService: EntidadeService,
    private enderecoService: EnderecoService,
    private pessoaFisicaService: PessoaFisicaService,
    private veiculoService: VeiculoService,
    private spinnerService: SpinnerService,
    private secretariaPmpaService: SecretariaPmpaService,
    private produtoService: ProdutoService,
    private obraPmpaService: ObraPmpaService,
    private smfPessoaService: SmfPessoaService,
    private notificacoesService: NotificacoesService,
    private messageService: MessageService,
    private globals: Globals) {

      this.activeRoute = this.router.url;

      this.variaveisGlobais = globals;
      // console.log("Construtor ListaPlanosComponent snapshot>>>>>>>>>>>", route.snapshot);
      this.idEntidade = route.snapshot.params['id'];



    }

  ngOnInit() {
    this.variaveisGlobais = this.globals;
    this.dataInicial.setMonth(this.dataInicial.getMonth() - 2);
    if (this.idEntidade === undefined) {
      this.idEntidade = 0;
    }





  }

  pesquisaManifesto() {
    // console.log('pesquisaManifesto Manifestos ...>>', this.dataInicial);
    this.spinnerService.exibir();

    if (this.idEntidade === 0) {
      let idPF = 0;
      if (this.variaveisGlobais.pessoaFisica !== null) {
        idPF = this.variaveisGlobais.pessoaFisica.id;
      }

      this.manifestoService.getManifestosResultados(idPF, this.dataInicial, this.dataFinal)
      .subscribe(resultados => {
          this.spinnerService.ocultar();

        // console.log('Resultados Manifestos ...>>', resultados);
        this.totalManifestos = resultados[0].totalManifestos;
        if (this.totalManifestos > 0 ) {
          this.geraPdfResultados(resultados);
        }
      },
        error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });
    } else {
      // console.log("...............pesquisaManifesto idEntidade>>>>>>>>>>>", this.idEntidade);

      this.manifestoService.getManifestosResultadosCnpj(this.idEntidade, this.dataInicial, this.dataFinal)
      .subscribe(resultados => {
          this.spinnerService.ocultar();

        console.log('Resultados Manifestos ...>>', resultados);
        this.totalManifestos = resultados[0].totalManifestos;
        this.geraPdfResultados(resultados);
      },
        error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });

    }

  }

  logout() {
    const url = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
    this.keycloakService.getKeycloakInstance().logout({redirectUri: url});
  }

  onSubmit() {
    this.ngOnInit();
  }

  checkActive(route: string) {
    return route === this.activeRoute;
  }

  pdfInicial(doc) {
    doc.setFontSize(14);
    let img = new Image();
    img.src = 'assets/img/logo_pmpa.png';
    doc.addImage(img, 'png', 10, 15, 17, 15);
    doc.text(48, 20, 'PREFEITURA MUNICIPAL DE PORTO ALEGRE');
    doc.text(35, 30, 'SECRETARIA MUNICIPAL DO MEIO AMBIENTE, URBANISMO E SUSTENTABILIDADE');
    // img = new Image();
    // img.src = 'assets/img/smam.jpg';
    // doc.addImage(img, 'jpg', 180, 15, 17, 13);
    img = new Image();
    img.src = 'assets/img/mtrcconline.png';
    doc.addImage(img, 'png', 133, 32, 40, 12);
    doc.text(40, 40, 'SGR - Sistema de Gestão de Resíduos');
  }

  novaPagina(doc) {

    let img = new Image();
    doc.setFontSize(12);
    img = new Image();
    img.src = 'assets/img/logoProcempa.png';
    doc.addImage(img, 'png', 155, 285, 40, 5);
    doc.text(5, 290, this.paginas.toString());
    doc.text(20, 290, 'Secretaria Municipal de Meio Ambiente, Urbanismo e Sustentabilidade – SMAMUS');

    doc.addPage();
    this.pdfInicial(doc);
    // doc.setFontType('bolditalic');
    // doc.text(10, 60, tipoEmpresa);
    // doc.text(15, 65, 'Total de Manifestos');
    // doc.text(60, 65, 'Empresa');
    // doc.text(15, 70, 'Total de Resíduos');
    // doc.rect(10, 72, 190, 0.5, 'F');

    // buscar produtos do manifesto
    this.numLinha = 60;
    this.linhas = 0;
    this.paginas = this.paginas + 1;
    // doc.setFontSize(10);
    // doc.setFontType('normal');

  }

  geraPdfResultados(mResultados: ManifestoResultado[]) {

    const doc = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
    this.pdfInicial(doc);

    doc.setFontSize(12);
    doc.setFontType('bolditalic');
    doc.text(20, 50, 'Total de manifestos no período de ' +  new Date(this.dataInicial).toLocaleDateString()
      + ' a ' +  new Date(this.dataFinal).toLocaleDateString() + ' = ' + mResultados[0].totalManifestos);
    this.numLinha = 60;
    this.linhas = 0;
    this.paginas = 1;

    this.listaEmpresas(mResultados[0].geradores, doc, 'GERADORES');
    this.numLinha = this.numLinha + 5;
    this.listaEmpresas(mResultados[0].transportadores, doc, 'TRANSPORTADORES');
    this.numLinha = this.numLinha + 5;
    this.listaEmpresas(mResultados[0].destinadores, doc, 'DESTINADORES');
    if (mResultados[0].executores.length > 0) {
      this.numLinha = this.numLinha + 5;
      this.listaEmpresas(mResultados[0].executores, doc, 'EXECUTORES');
    }

console.log ('numero de linhas.......>', this.linhas);

    this.numLinha = this.numLinha + 5;
    this.listaResiduos(mResultados[0].residuosTipoResiduo, doc, 'Tipo de Resíduo');
    this.numLinha = this.numLinha + 5;
    this.listaResiduos(mResultados[0].residuosObra, doc, 'Tipo de Obra');
    this.numLinha = this.numLinha + 5;
    this.listaResiduos(mResultados[0].residuosLocalNomeObra, doc, 'Nome da Obra/Endereço');

    let img = new Image();
    img = new Image();
    img.src = 'assets/img/logoProcempa.png';
    doc.addImage(img, 'png', 155, 285, 40, 5);
    doc.text(5, 290, this.paginas.toString());
    doc.text(20, 290, 'Secretaria Municipal de Meio Ambiente, Urbanismo e Sustentabilidade – SMAMuS');

    doc.save('MTRCC_Resultados_' + new Date().toLocaleDateString() + '_'
      + new Date().getHours() + '_' + new Date().getMinutes() + '.pdf');
  }

  listaEmpresas(mteList: ManifestoTotalEmpresa[], doc, tipoEmpresa) {

    if (this.linhas > 42) {
      this.novaPagina(doc);
    }
    doc.setFontType('bolditalic');
    doc.setFontSize(12);
    doc.text(10, this.numLinha, tipoEmpresa);
    this.numLinha = this.numLinha + 5;
    doc.text(15, this.numLinha, 'Num. Manifestos');
    doc.text(60, this.numLinha, 'Empresa');
    this.numLinha = this.numLinha + 5;
    doc.text(15, this.numLinha, 'Qtd. Resíduos');
    this.numLinha = this.numLinha + 2;

    doc.rect(10, this.numLinha, 190, 0.5, 'F');
    doc.setFontSize(10);
    doc.setFontType('normal');
    this.numLinha = this.numLinha + 5;
    this.linhas = this.linhas + 4;

    mteList.forEach(mte => {

      if (this.linhas > 43) {
        this.novaPagina(doc);
      }
      doc.setFontSize(10);
      doc.setFontType('normal');
      doc.text(15, this.numLinha, mte.totalManifestos.toString());
      doc.text(60, this.numLinha, mte.cpfCnpj + ' - ' + mte.nome);
      mte.manifestoTotalResiduo.forEach(mtr => {
        if (this.linhas > 43) {
          this.novaPagina(doc);
        }
        doc.setFontSize(10);
        doc.setFontType('normal');
        this.numLinha = this.numLinha + 4;
        this.linhas = this.linhas + 1;
          // console.log('yyyyyyyyyyyyy>>>>>', mtr);

        let unidade = mtr.unidadeMedida;
        if (unidade === 'toneladas') {
          unidade = 't';
        }
        doc.text(15, this.numLinha, mtr.totalResiduos + ' ' + unidade);
      });
      this.numLinha = this.numLinha + 1;

      doc.rect(10, this.numLinha, 180, 0.1, 'F');
      this.numLinha = this.numLinha + 4;
      this.linhas = this.linhas + 1;

    });
  }

  listaResiduos(mttrList: ManifestoTotalResiduo[], doc, tipoLista) {

//    console.log('ListaResíduos>>>>>>>', mttrList);


    if (this.linhas > 42) {
      this.novaPagina(doc);
    }
    doc.setFontType('bolditalic');
    doc.setFontSize(12);
    doc.text(10, this.numLinha, 'Quantidade de Resíduos por ' + tipoLista);
    this.numLinha = this.numLinha + 5;
    doc.text(15, this.numLinha, 'Qtde.');
    doc.text(35, this.numLinha, tipoLista);
    this.numLinha = this.numLinha + 2;

    doc.rect(10, this.numLinha, 190, 0.5, 'F');
    doc.setFontSize(10);
    doc.setFontType('normal');
    this.numLinha = this.numLinha + 5;
    this.linhas = this.linhas + 3;

    mttrList.forEach(mttr => {

    // console.log('ListaResíduos>>>>>>>', this.linhas);

      if (this.linhas > 43) {
        this.novaPagina(doc);
      }
      doc.setFontSize(10);
      doc.setFontType('normal');
      let unidade = mttr.unidadeMedida;
      if (unidade === 'toneladas') {
        unidade = 't';
      }
      doc.text(15, this.numLinha, mttr.totalResiduos.toString() + ' ' + unidade);
      doc.text(35, this.numLinha, mttr.descricao);
      this.numLinha = this.numLinha + 1;

      doc.rect(10, this.numLinha, 180, 0.1, 'F');
      this.numLinha = this.numLinha + 4;
      this.linhas = this.linhas + 1;

    });
  }
}
