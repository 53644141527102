/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mapa.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../menu/menu.component.ngfactory";
import * as i3 from "../../menu/menu.component";
import * as i4 from "@angular/router";
import * as i5 from "../entidade/entidade.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../globals";
import * as i8 from "@procempa/ngx-keycloak";
import * as i9 from "./mapa.component";
import * as i10 from "../services/mapa.service";
import * as i11 from "../services/spinner.service";
import * as i12 from "../services/notificacoes.service";
var styles_MapaComponent = [i0.styles];
var RenderType_MapaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapaComponent, data: {} });
export { RenderType_MapaComponent as RenderType_MapaComponent };
export function View_MapaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sgr-menu", [], null, null, null, i2.View_MenuComponent_0, i2.RenderType_MenuComponent)), i1.ɵdid(1, 114688, null, 0, i3.MenuComponent, [i4.ActivatedRoute, i4.Router, i5.EntidadeService, i6.DOCUMENT, i7.Globals, i8.KeycloakService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "map-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "map-frame"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["id", "map"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_MapaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mapa", [], null, null, null, View_MapaComponent_0, RenderType_MapaComponent)), i1.ɵdid(1, 4243456, null, 0, i9.MapaComponent, [i4.Router, i10.MapaService, i11.SpinnerService, i12.NotificacoesService, i7.Globals], null, null)], null, null); }
var MapaComponentNgFactory = i1.ɵccf("app-mapa", i9.MapaComponent, View_MapaComponent_Host_0, {}, {}, []);
export { MapaComponentNgFactory as MapaComponentNgFactory };
