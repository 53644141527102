export enum Etapas {
  Demolicao = 'Demolição',
  PreparoTerreno = 'Preparo do terreno',
  Fundacoes = 'Fundações',
  Estrutura = 'Estrutura',
  Acabamento = 'Acabamento'
}

export interface Etapa {
    etapa: Etapas[];
}


