import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { MenuItem } from 'primeng/api';
import { FileUpload, SelectItem } from 'primeng/primeng';
import { LogradouroCdl } from 'procempa-cdl-lib';
import { Localizacao } from 'procempa-cdl-lib/lib/model/Localizacao';
import { Anexo } from 'src/app/@shared/models/anexo.model';
import { EnderecoEntidade } from 'src/app/@shared/models/enderecoEntidade';
import { Entidade } from 'src/app/@shared/models/entidade.model';
import { PessoaFisica } from 'src/app/@shared/models/pessoaFisica.model';
import { Veiculo } from 'src/app/@shared/models/veiculo.model';
import { LicencaAmbiental } from 'src/app/@shared/models/licencaAmbiental.model';
import { Globals } from 'src/app/globals';
import { environment } from 'src/environments/environment';
import { AnexoService } from '../../services/anexo.service';
import { EnderecoService } from '../../services/endereco.service';
import { NotificacoesService } from '../../services/notificacoes.service';
import { SpinnerService } from '../../services/spinner.service';
import { VeiculoService } from '../../services/veiculo.service';
import { LicencaAmbientalService } from '../../services/licencaAmbiental.service';
import { EntidadeService } from '../entidade.service';
import { PessoaFisicaService } from '../../services/pessoaFisica.service';
import { SmfPessoaService } from '../../services/smfPessoa.service';
import { SecretariaPmpaService } from '../../services/secretariaPmpa.service';
import { SecretariaPmpa } from 'src/app/@shared/models/secretariaPmpa.model';
import { Endereco } from 'src/app/@shared/models/endereco';
import { DmluService } from '../../dmlu/dmlu.service';
import { ExpedienteUnicoVO } from 'src/app/@shared/models/expedienteUnicoVO.model';

@Component({
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListaEntidadesComponent implements OnInit {

  // [x: string]: any;
  entidadeForm: FormGroup;
  private username: string;
  items: MenuItem[];
  nomeUsuario: string;
  pfFuncao = '';
  pfFuncaoRT: string[];
  // usuarioAutenticado: boolean;

  // Lista
  entidades: Entidade[] = [];
  entidadesVinculadas: Entidade[] = [];
  entidadeRepresentada: Entidade;
  veiculo: Veiculo;
  veiculos: Veiculo[] = [];
  licenca: LicencaAmbiental;
  licencas: LicencaAmbiental[] = [];
  licencasItem: SelectItem[] = [];
  idLicenca: number;
  licencaIsento: boolean = false;
  selectedPesquisa: String = '';
  totalEntidades: number;

  // para datas
  minimumDateGerador = new Date();
  languageCalendar: any;

  // Novo/edit
  entidadeEdit: Entidade;
  mostrarDialogEdit: boolean;
  mostrarVinculos: boolean;
  mostrarDialogPlanos: boolean;
  tituloDialogEdit: string;
  tituloVinculos: string;
  tituloLicenca: string;
  // tiposEntidade: SelectItem[];
  pessoaFisicas: PessoaFisica[] = [];
  pfEmail: string;
  mudancaEmail: Boolean = false;
  podeInativar: Boolean = false;
  tituloDialogEditPessoaFisica: string;
  insertMode = false;
  insertModePF = false;
  isPMPA = false;
  controlaRazaoSocialEnt = false;
  controlaTelefoneEnt = false;
  controlaEmailEnt = false;
  controlaNumeroCnaeEnt = false;
  controlaDescricaoCnaeEnt = false;
  controlaMensagemFazenda = false;
  podeSalvar: boolean;
  pessoaFisica: PessoaFisica = new PessoaFisica();
  isDestinoFinal: boolean;

  // Exluir
  entidadeExcluir: Entidade;
  mostrarDialogExcluir: boolean;


  mostrarDialogoPessoaFisica: Boolean = false;
  mostrarDialogoPFLogada: Boolean = false;
  mostrarDialogoVeiculo: Boolean = false;
  mostrarDialogoLicenca: Boolean = false;
  mostrarDialogoEU: Boolean = false;

  expedienteUnicoVO: ExpedienteUnicoVO = new ExpedienteUnicoVO();
  expedienteUnicoVOList: ExpedienteUnicoVO[] = [];

  variaveisGlobais;
  secretarias: SelectItem[] = [];
  secretariasPmpa: SecretariaPmpa[] = [];
  idSecretaria: number = null;
  index: number = 0;

  // CDL
  endereco: EnderecoEntidade;
  enderecos: EnderecoEntidade[] = [];
  logradouroCdl: LogradouroCdl;
  isCompleto = true;
  logradouro = null;
  numero = null;
  mostrarDialogoEnderecoPoa = false;
  mostrarDialogoEnderecoForaPoa = false;
  tituloDialogEditEndereco: string;
  localizacao: Localizacao;
  activeRoute: string;

  // Dados para anexos
  @ViewChild('anexoUpload') fileUpload: FileUpload;
  anexo: Anexo;
  anexoSel = '';
  anexos: Anexo[] = [];
  novoAnexoDescricao: string;
  BACKEND_URL = environment.apiUrl;
  comAnexos = false;

  // Anexos
  tiposAnexos: SelectItem[] = [];
  tipoAnexo = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private entidadeService: EntidadeService,
    private enderecoService: EnderecoService,
    private pessoaFisicaService: PessoaFisicaService,
    private veiculoService: VeiculoService,
    private licencaAmbientalService: LicencaAmbientalService,
    private anexoService: AnexoService,
    private keycloakService: KeycloakService,
    private spinnerService: SpinnerService,
    private notificacoesService: NotificacoesService,
    private smfPessoaService: SmfPessoaService,
    private dmluService: DmluService,
    private secretariaPmpaService: SecretariaPmpaService,
    private globals: Globals
  ) {
    this.activeRoute = this.router.url;
    this.globals.menuRaiz = this.router.url.split('/')[1];

    // keycloakService.getUser().subscribe(user => {
    //   this.globals.userFullName = user.fullName;
    //   this.globals.userName = user.name;
    //   this.globals.roleEntidade = this.keycloakService.hasRole('Entidade');
    // this.variaveisGlobais = globals;
    // });
  }

  ngOnInit() {

    this.variaveisGlobais = this.globals;
    console.log('ngOnInit---------ListaEntidadesComponent----VVVV----------->>>', this.variaveisGlobais);
    this.endereco = new EnderecoEntidade();
    this.expedienteUnicoVO = new ExpedienteUnicoVO();


    if (this.globals.entidade == null) {
      this.globals.recuperaKeycloak().subscribe(user => {
        if (this.globals.roleEntidade) {
          if (this.variaveisGlobais.pessoaFisica === null) {
            this.globals.recuperaEntidadePessoaFisica().subscribe(entidade => {
              // console.log('recuperaEntidadePessoaFisica---------------------------');
              // console.log('--------------------------->>>>>11', entidade);


              this.variaveisGlobais = this.globals;
              // console.log('--------------------------->>>>>00');
              this.inicializaOnInit();
            });
          } else {
            this.inicializaOnInit();
          }
        } else {
          // console.log('--------------------------->>>>>11');
          this.inicializaOnInit();
        }
      });
    } else {
      // console.log('--------------------------->>>>>22');
      this.inicializaOnInit();
    }
  }

  inicializaOnInit() {

    this.entidadeRepresentada = this.variaveisGlobais.entidade;
    this.mostrarVinculos = false;

    if (this.variaveisGlobais.menuRaiz === 'grandeGerador') {
      this.tiposAnexos = [
        { label: 'Alvará', value: 'Alvará' },
        { label: 'CNPJ', value: 'CNPJ' },
        { label: 'CPF/RG', value: 'CPF/RG' },
        { label: 'Contrato Conjunto (Transp e Dest.Final)', value: 'Contrato Conjunto' },
        { label: 'Contrato Destino Final', value: 'Contrato Destino Final' },
        { label: 'Contrato Transporte', value: 'Contrato Transporte' },
        { label: 'Plano Resíduo', value: 'Plano Resíduo' },
        { label: 'RT Elaboração', value: 'RT Elaboração' },
        { label: 'RT Execução', value: 'RT Execução' }
      ];
    }
    if (this.variaveisGlobais.menuRaiz === 'gdr') {
      this.tiposAnexos = [
        { label: 'Alvará', value: 'Alvará' },
        { label: 'Boleto Pago', value: 'Boleto Pago' },
        { label: 'Contrato Social ou Estatuto', value: 'Contrato Social' },
        { label: 'CNH', value: 'CNH' },
        { label: 'CPF/RG', value: 'CPF/RG' },
        { label: 'Designação Diário Oficial', value: 'Designação Diário Oficial' },
        { label: 'Documento Complementar', value: 'Complementar' },
        { label: 'Outros', value: 'Outros' },
        { label: 'Procuração de Representação', value: 'Procuração' },
        { label: 'Relatório da Dívida', value: 'Relatório Dívida' },
        { label: 'Relatório da Vistoria Técnica', value: 'Vistoria' }
      ];
    }

    // Carrega valor inicial para projetos
    if (this.variaveisGlobais.roleEntidade) {
      if (
        this.variaveisGlobais.menuRaiz === 'grandeGerador' ||
        this.variaveisGlobais.menuRaiz === 'mtrcc' ||
        this.variaveisGlobais.menuRaiz === 'transportador' ||
        this.variaveisGlobais.menuRaiz === 'destinoFinal' ||
        this.variaveisGlobais.menuRaiz === 'gdr'
      ) {

        if (this.variaveisGlobais.entidades.length > 0) { // entidades já recuperadas
          console.log('inicializaOnInit>>>>>>>>>Entidades já existem>>>>', this.entidades);
          this.entidades = this.variaveisGlobais.entidades;
          this.totalEntidades = this.entidades.length;

          this.spinnerService.exibir();
          this.secretariasPmpa = [];
          this.secretariaPmpaService.getSecretariasPmpa().subscribe(secretarias => {
            this.secretariasPmpa = secretarias;
            secretarias.forEach(sec => {
              this.secretarias.push({ label: sec.sigla, value: sec });
            });
            this.spinnerService.ocultar();
            if (this.variaveisGlobais.menuRaiz === 'gdr') {
              this.gdrEntidadeComContrato();
            }

          },
            error => {
              console.log(error);
              this.spinnerService.ocultar();
              this.notificacoesService.notificarErro(error.error, null);
            });

          this.isPMPA = false;
          if (this.entidadeRepresentada === undefined || this.entidadeRepresentada !== null) {
            if (this.entidadeRepresentada.cnpj === '92.963.560/0001-60') {
              this.isPMPA = true;
              // console.log('inicializaOnInit......667.....>>', this.isPMPA);
            }
          }
        } else { // recuperar entidades
          console.log('inicializaOnInit>>>>>>>>>Recuperar Entidades>>>>', this.entidades);

          this.spinnerService.exibir();
          this.entidadeService.getEntidadesDaPessoaFisica(this.variaveisGlobais.pessoaFisica.id).subscribe(entidades => {
            this.entidades = entidades;
            this.totalEntidades = entidades.length;
            if (this.variaveisGlobais.menuRaiz === 'gdr') {
              this.gdrEntidadeComContrato();
            }

            this.secretariasPmpa = [];
            this.secretariaPmpaService.getSecretariasPmpa().subscribe(secretarias => {
              this.secretariasPmpa = secretarias;
              secretarias.forEach(sec => {
                this.secretarias.push({ label: sec.sigla, value: sec });
              });

            },
              error => {
                console.log(error);
                this.spinnerService.ocultar();
                this.notificacoesService.notificarErro(error.error, null);
              });

            this.isPMPA = false;
            if (this.entidadeRepresentada === undefined || this.entidadeRepresentada !== null) {
              if (this.entidadeRepresentada.cnpj === '92.963.560/0001-60') {
                this.isPMPA = true;
                console.log('inicializaOnInit......667.....>>', this.isPMPA);
              }
            }

            this.spinnerService.ocultar();

          },
            error => {
              console.log(error);
              this.spinnerService.ocultar();
              this.notificacoesService.notificarErro(error.error, null);
            });
        }
      }
    } else {
      // console.log('inicializaOnInit>>>>>>>>>Administrador>>>>', this.entidades);

      if (this.variaveisGlobais.menuRaiz === 'grandeGerador') {
        this.spinnerService.exibir();

        this.entidadeService.getEntidadesGeradoras().subscribe(
          entidades => {
            this.spinnerService.ocultar();
            this.entidades = entidades;
            this.totalEntidades = entidades.length;
            console.log('Lista Entidades >>>>', entidades);
          },
          error => {
            console.log(error);
            console.log(error.error);
            this.spinnerService.ocultar();
            this.notificacoesService.notificarErro(error.error, null);
          });
        // this.pesquisaEntidades("");
      }
      if (this.variaveisGlobais.menuRaiz === 'mtrcc') {
        this.spinnerService.exibir();
        this.entidadeService.getEntidadesMtrcc().subscribe(
          entidades => {
            this.spinnerService.ocultar();
            this.entidades = entidades;
            this.totalEntidades = entidades.length;
            // if (this.variaveisGlobais.menuRaiz === 'gdr') {
            //   this.gdrEntidadeComContrato();
            // }
            console.log('Lista Entidades >>>>', entidades);

          },
          error => {
            console.log(error);
            console.log(error.error);
            this.spinnerService.ocultar();
            this.notificacoesService.notificarErro(error.error, null);
          }
        );
      }
      if (this.variaveisGlobais.menuRaiz === 'gdr') {
        this.spinnerService.exibir();

        // this.pesquisaEntidadesGdr('Todos');
        this.entidadeService.getEntidadesGdr('Contrato vigente').subscribe(
          entidades => {
            this.spinnerService.ocultar();
            this.entidades = entidades;
            this.totalEntidades = entidades.length;
            this.entidades.forEach(entidade => {
              console.log('gdrEntidadeComContrato.....*******......>>>>', entidade);
              if (entidade.gdrNumeroContrato !== null) {
                entidade.gdrNumeroContrato = entidade.gdrNumeroContrato.toString() + '/' + entidade.gdrAnoContrato;
              }
            });
            // this.gdrEntidadeComContrato();
            console.log('Lista Entidades >>>>', entidades);
          },
          error => {
            console.log(error);
            console.log(error.error);
            this.spinnerService.ocultar();
            this.notificacoesService.notificarErro(error.error, null);
          });
        // this.pesquisaEntidades("");
      }
    }

    this.selectedPesquisa = 'Todos';
    if (this.variaveisGlobais.menuRaiz === 'gdr') {
      this.selectedPesquisa = 'Vigente';
    }
    // console.log('....................ANEXOS .............>>>>', this.anexos);

    // this.nomeUsuario = this.variaveisGlobais.userFullName;
    // this.username = this.variaveisGlobais.userName;
  }

  isEntidade(): boolean {
    const ret = this.keycloakService.hasRole('Entidade');
    return ret;
  }

  podeExibirResiduos(possuiPlanos): boolean {

    if (this.variaveisGlobais.menuRaiz === 'grandeGerador') {
      if (this.variaveisGlobais.roleEntidade) {
        return true;
      } else if (possuiPlanos) {
        // console.log('podeExibirResiduos...............>>>>TRUE');
        return true;
      }
    }
    return false;
  }

  podeExibirManifestos(entidade: Entidade): boolean {
    if (this.variaveisGlobais.menuRaiz === 'mtrcc') {
      if (!this.variaveisGlobais.roleEntidade) {
        return true;
      }
    }
    return false;
  }
  isSMAMS(): boolean {
    const ret = this.keycloakService.hasRole('SMAMS');
    return ret;
  }

  logout() {
    const url =
      document.location.protocol +
      '//' +
      document.location.hostname +
      ':' +
      document.location.port;
    this.keycloakService.getKeycloakInstance().logout({ redirectUri: url });
  }

  mostrarDialogParaNovo() {

    //Início correções automáticas nas tabelas
    // this.entidadeService.correcoesExtras().subscribe(string => {

    //   console.log('OKkkkkkkkkkkkkkk!!!!!!!!!!!');
    // },
    // error => {
    //     console.log(error);
    //     console.log(error.error);
    //     this.spinnerService.ocultar();
    //     this.notificacoesService.notificarErro(error.error, null);
    // });
    //Fim correções automáticas nas tabelas



    this.entidadeEdit = {
      id: null,
      cnpj: null,
      razaoSocial: '',
      dataInclusao: null,
      timestampAsString: null,
      usuarioInclusao: null,
      usuarioAlteracao: null,
      // nomeSisLog: null,
      licencaAmbiental: null,
      licencaAmbientalOrgaoEmissor: null,
      licencaAmbientalDataValidade: null,
      alvara: null,
      gdrNumeroContrato: null,
      gdrAnoContrato: null,
      gdrSituacaoContrato: null,
      gdrCertificado: null,
      codigoDmlu: null,
      adimplenteDmlu: null,
      desabilitadaMtrcc: null
    };

    this.enderecos = [];
    this.veiculos = [];
    this.licencas = [];
    this.pessoaFisicas = [];
    this.mostrarDialogEdit = true;
    this.tituloDialogEdit = 'Nova Entidade';
    this.insertMode = true;
    this.controlaRazaoSocialEnt = true;
    // this.controlaTelefoneEnt = true;
    this.controlaEmailEnt = true;
    this.controlaNumeroCnaeEnt = true;
    this.controlaDescricaoCnaeEnt = true;
    this.isDestinoFinal = false;
    if (this.variaveisGlobais.menuRaiz === 'destinoFinal') {
      this.isDestinoFinal = true;
      this.tituloLicenca = 'Informações da Licença Ambiental';
    }
    if (this.variaveisGlobais.menuRaiz === 'mtrcc') {
      this.isDestinoFinal = true;
      this.tituloLicenca = 'Se TRANSPORTADOR, preenchimento dos campos da licença são opcionais';
    }

    this.endereco = new EnderecoEntidade();
    this.endereco.nomeLogradouro = null;

    console.log("***************>>>", this.endereco);
    // corrige bug numeroManifesto .....
    // this.spinnerService.exibir();
    // this.entidadeService.atualizaEmpresaFazenda().subscribe(retorno => {
    //   this.notificacoesService.notificarSucesso('OKkkkkkkkkk', null);
    //   this.spinnerService.ocultar();
    // },
    //   error => {
    //     this.spinnerService.ocultar();
    //     this.notificacoesService.notificarErro(error.error, null);
    //   }
    // );




  }

  mostrarDialogComPlanos(idEntidade: number) {
    this.router.navigate(['/planos/' + idEntidade]);

    const entidadeParaEditar = this.entidades.find(function (entidade) {
      return entidade.id === idEntidade;
    });
    this.entidadeEdit = { ...entidadeParaEditar };
    this.mostrarDialogPlanos = true;
    this.tituloDialogEdit = 'Planos da Entidade';
    this.insertMode = false;
  }

  mostrarResiduosGerados(idEntidade: number) {
    // console.log('mostrarResiduosGerados........00>>>>>', this.route);
    // this.entidadeEdit = this.entidades.find(function (entidade) {
    //   return entidade.id === idEntidade;
    // });
    // this.globals.entidade = this.entidadeEdit;
    // this.pessoaFisica = this.pessoaFisicas.find(function (pessoaFisica) {
    //   return pessoaFisica.id === idPessoaFisica;
    // });

    this.router.navigate(['/grandeGerador/empresaResiduos/', idEntidade], {
      relativeTo: this.route
    });
    console.log('mostrarResiduosGerados........11>>>>>', this.variaveisGlobais);
    // <a [routerLink]="['../matrizGestao', evento.id, evento.descricao]">

    // this.mostrarDialogPlanos = true;
    // this.tituloDialogEdit = 'Planos da Entidade';

  }

  mostrarManifestos(idEntidade: number) {

    this.router.navigate(['/mtrcc/empresaManifestos/', idEntidade], {
      relativeTo: this.route
    });
    console.log('mostrarManifestos........11>>>>>', this.variaveisGlobais);

  }

  mostrarResultados(idEntidade: number) {

    this.router.navigate(['/mtrcc/resultados/', idEntidade], {
      relativeTo: this.route
    });
    console.log('mostrarResultados........11>>>>>', this.variaveisGlobais);

  }

  mostrarContratoDMLU(idEntidade: number) {


    this.globals.entidade = this.entidades.find(function (entidade) {
      return entidade.id === idEntidade;
    });


    this.router.navigate(['/gdr/contrato/', idEntidade], {
      relativeTo: this.route
    });

  }

  mostrarCertificadoDMLU(idEntidade: number) {


    this.globals.entidade = this.entidades.find(function (entidade) {
      return entidade.id === idEntidade;
    });


    this.router.navigate(['/gdr/certificado/', idEntidade], {
      relativeTo: this.route
    });

  }

  mostrarDialogParaEditarPessoaFisica(idPessoaFisica: number) {
    const pFisica = this.pessoaFisicas.find(function (pessoaFisica) {
      return pessoaFisica.id === idPessoaFisica;
    });
    this.pessoaFisica = { ...pFisica };
    // console.log('@@@@@@@@@@@@@@mostrarDialogParaEditarPessoaFisica........>>>>>', this.pessoaFisica);

    if (!this.variaveisGlobais.roleEntidade) {
      this.podeSalvar = true;
    } else {

      if (this.pessoaFisica.id === this.variaveisGlobais.pessoaFisica.id) {
        this.podeSalvar = true;
      } else {
        this.pessoaFisicaService.getPessoasFisicasEntidadeRL(this.entidadeEdit.id, this.variaveisGlobais.pessoaFisica.id)
          .subscribe(pesFisica => {
            this.podeSalvar = false;
            if (pesFisica !== null) {
              if (pesFisica.rLegal) {
                this.podeSalvar = true;
              }
            }
            this.spinnerService.ocultar();
          },
            error => {
              this.spinnerService.ocultar();
              this.notificacoesService.notificarErro(error.error, null);
            }
          );
      }
    }

    this.pfFuncaoRT = [];
    this.pfFuncao = '';
    if (this.pessoaFisica.rLegal) {
      this.pfFuncao = 'rLegal';
    }
    if (this.pessoaFisica.rusrLegal) {
      this.pfFuncao = 'rusrLegal';
    }
    if (this.pessoaFisica.rtElaboracao && this.pessoaFisica.rtExecucao) {
      this.pfFuncaoRT = ['rElaboracao', 'rExecucao'];

    } else {
      if (this.pessoaFisica.rtElaboracao) {
        this.pfFuncaoRT = ['rElaboracao'];
      }
      if (this.pessoaFisica.rtExecucao) {
        this.pfFuncaoRT = ['rExecucao'];
      }
    }
    // } else {
    //   this.tituloDialogEditPessoaFisica = 'Editar PessoaFisica';
    // }
    this.insertMode = false;
    this.insertModePF = false;
    // if (this.variaveisGlobais.menuRaiz == "mtrcc") {
    this.tituloDialogEditPessoaFisica = 'Editar Responsável';
    console.log('mostrarDialogParaEditarPessoaFisica......667.....>>', this.isPMPA);

    if (this.isPMPA) {
      this.secretarias = [];
      this.secretariasPmpa.forEach(sec => {
        this.secretarias.push({ label: sec.sigla, value: sec.id });
      });
      this.mostrarDialogoPessoaFisica = true;
      this.idSecretaria = this.pessoaFisica.idSecretaria;
      console.log('@@@@@@@@@@@@@@mostrarDialogParaEditarPessoaFisica........>>>>>', this.idSecretaria, '----', this.pessoaFisica);
    } else {
      this.mostrarDialogoPessoaFisica = true;
    }


  }

  mostrarDialogParaEditar(idEntidade: number) {
    // this.anexos.length = -1;
    this.isDestinoFinal = false;
    if (this.variaveisGlobais.menuRaiz === 'destinoFinal') {
      this.isDestinoFinal = true;
      this.tituloLicenca = 'Informações da Licença Ambiental';
    }
    if (this.variaveisGlobais.menuRaiz === 'mtrcc') {
      this.isDestinoFinal = true;
      this.tituloLicenca = 'Se TRANSPORTADOR, preenchimento dos campos da licença são opcionais';
    }

    this.enderecos = [];
    this.enderecoService.getEnderecosPorEntidade(idEntidade).subscribe(
      enderecos => {
        this.enderecos = enderecos;
        console.log('........mostrarDialogParaEditar................>>', this.enderecos);
      },
      error => {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      }
    );

    this.licencasItem = [];
    this.licencaAmbientalService.getLicencasPorEntidade(idEntidade).subscribe(
      licencas => {
        this.licencas = licencas;
        licencas.forEach(lic => {
          this.licencasItem.push({ label: lic.licenca, value: lic.id });
        });
        this.spinnerService.ocultar();

      },
      error => {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      }
    );
    this.veiculoService.getVeiculosPorEntidade(idEntidade).subscribe(
      veiculos => {
        this.veiculos = veiculos;
        this.spinnerService.ocultar();
      },
      error => {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      }
    );

    this.recuperaAnexosEntidade(idEntidade);
    this.buscaPessoasFisicasEntidade(idEntidade);

    // this.entidadeService.getEntidade;
    this.insertMode = false;
    const entidadeParaEditar = this.entidades.find(function (entidade) {
      return entidade.id === idEntidade;
    });
    this.entidadeEdit = { ...entidadeParaEditar };
    if (this.entidadeEdit.licencaAmbientalDataValidade != null) {
      this.entidadeEdit.licencaAmbientalDataValidade = new Date(
        this.entidadeEdit.licencaAmbientalDataValidade
      );
    }
    console.log('Passou...ENTIDADE................', this.entidadeEdit);

    this.controlaRazaoSocialEnt = true;
    // this.controlaTelefoneEnt = true;
    this.controlaEmailEnt = true;
    this.controlaNumeroCnaeEnt = true;
    this.controlaDescricaoCnaeEnt = true;
    if (this.entidadeEdit.razaoSocial != null) {
      this.controlaRazaoSocialEnt = false;
    }
    // if (this.entidadeEdit.telefone != null) {
    //   this.controlaTelefoneEnt = false;
    // }
    if (this.entidadeEdit.email != null) {
      this.controlaEmailEnt = false;
    }
    if (this.entidadeEdit.numeroCnae != null) {
      this.controlaNumeroCnaeEnt = false;
    }
    if (this.entidadeEdit.descricaoCnae != null) {
      this.controlaDescricaoCnaeEnt = false;
    }

    this.podeSalvar = false;
    if (this.variaveisGlobais.roleEntidade || this.variaveisGlobais.userName === 'flavio'
      || this.variaveisGlobais.userName === 'walfrido') {
      this.podeSalvar = true;
    }
    // if (this.entidadeEdit.enderecoDO == null) {
    //   this.entidadeEdit.enderecoDO = {};
    // }
    this.mostrarDialogEdit = true;
    this.tituloDialogEdit = 'Editar Empresa ' + this.entidadeEdit.razaoSocial;
  }

  recuperaAnexosEntidade(idEntidade: number) {
    this.comAnexos = false;
    this.anexos = [];
    // this.anexos.length = 999;
    this.spinnerService.exibir();
    this.anexoService.getAnexosPorEntidade(idEntidade, this.variaveisGlobais.menuRaiz).subscribe(
      anexos => {
        this.anexos = anexos;
        this.spinnerService.ocultar();
        // if (this.anexos.length > 0) {
        console.log('recuperaAnexosEntidade>>>>>>>>>......Anexos..................', anexos);
        this.comAnexos = true;
        // }
      },
      error => {
        console.log('recuperaAnexosEntidade>>>>>>>>>erro>>>>', error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      }
    );
  }

  mostrarDialogEditParaExcluir(idEntidade: number) {
    this.entidadeExcluir = this.entidades.find(function (entidade) {
      return entidade.id === idEntidade;
    });
    this.mostrarDialogExcluir = true;
  }

  salvarNovo() {

    // Verifica se campos obrigatórios foram preenchidos
    if (this.entidadeEdit.cnpj == null || this.entidadeEdit.cnpj === '') {
      this.notificacoesService.notificarErro('CNPJ não informado', null);
      return;
    }
    if (this.entidadeEdit.nomeFantasia == null || this.entidadeEdit.nomeFantasia === '') {
      this.notificacoesService.notificarErro('NOME FANTASIA não informado', null);
      return;
    }
    if (this.entidadeEdit.razaoSocial == null || this.entidadeEdit.razaoSocial === '') {
      this.notificacoesService.notificarErro('RAZÃO SOCIAL não informado', null);
      return;
    }
    if (this.entidadeEdit.cnpj == null || this.entidadeEdit.cnpj === '') {
      this.notificacoesService.notificarErro('CNPJ não informado', null);
      return;
    }
    if (this.entidadeEdit.telefone == null || this.entidadeEdit.telefone === '') {
      this.notificacoesService.notificarErro('TELEFONE da Empresa não informado', null);
      return;
    }
    if (this.entidadeEdit.email == null || this.entidadeEdit.email === '') {
      this.notificacoesService.notificarErro('EMAIL não informado', null);
      return;
    }
    // console.log('ENDEREÇO DA ENTIDADE INSERT .............>>', this.endereco);
    if (this.endereco.municipio === undefined || this.endereco.municipio === null) {
      this.notificacoesService.notificarErro('ENDEREÇO não informado', null);
      return;
    }
    this.salvar();
  }

  salvar() {
    // console.log('SALVARRRRRRRRR   ENDEREÇO DA ENTIDADE INSERT .............>>', this.endereco);
    /**    // Verifica se todos os campos foram preenchidos
        if (this.entidadeEdit.nome == null || this.entidadeEdit.nome === ''
            || this.entidadeEdit.descricao == null || this.entidadeEdit.descricao === ''
            || this.entidadeEdit.unidadeMedida == null) {
          this.notificacoesService.notificarErro('Preencha todos os campos', null);
          return ;
        }
    */

    this.spinnerService.exibir();

    // Se novo
    if (this.entidadeEdit.id == null) {
      this.entidadeService.criarEntidade(this.entidadeEdit).subscribe(
        entidadeNovo => {
          this.entidadeEdit = entidadeNovo;
          this.entidades.push(entidadeNovo);
          this.globals.entidade = entidadeNovo;
          this.mostrarDialogEdit = false;
          this.spinnerService.ocultar();
          this.notificacoesService.notificarSucesso('Entidade adicionada com sucesso', null);


          // inclui relacionamento da nova empresa com usuário logado
          this.pfFuncao = 'rLegal';
          this.pessoaFisica = this.variaveisGlobais.pessoaFisica;
          this.geraRelacionamentoPJPF();
          this.insertMode = false;
          this.controlaRazaoSocialEnt = false;
          // this.controlaTelefoneEnt = false;
          this.controlaEmailEnt = false;
          this.controlaNumeroCnaeEnt = false;
          this.controlaDescricaoCnaeEnt = false;
          this.salvarEndereco();
          this.pessoaFisica = this.variaveisGlobais.pessoaFisica;


        },
        error => {
          console.log(error);
          this.spinnerService.ocultar();
          this.notificacoesService.notificarErro(error.error, null);
        }
      );
    } else {
      // Se editando
      this.entidadeService.editarEntidade(this.entidadeEdit).subscribe(
        entidadeEditado => {
          // Remove o valor atual
          // console.log("Lista de entidades atual .............>>", this.entidades);
          this.entidades = this.entidades.filter(
            item => item.id !== this.entidadeEdit.id
          );

          // Adiciona o novo
          this.entidades.push(entidadeEditado);
          this.globals.entidade = entidadeEditado;
          this.globals.entidades = this.entidades;

          this.mostrarDialogEdit = false;
          this.spinnerService.ocultar();

          this.pessoaFisica = this.variaveisGlobais.pessoaFisica;
          console.log('PESSOA FISICA .............>>', this.pessoaFisica);

          this.notificacoesService.notificarSucesso(
            'Entidade atualizada com sucesso',
            null
          );
        },
        error => {
          console.log(error);
          this.spinnerService.ocultar();
          this.notificacoesService.notificarErro(error.error, null);
        }
      );
    }
  }

  excluir() {
    this.spinnerService.exibir();

    this.entidadeService.excluirEntidade(this.entidadeExcluir).subscribe(
      () => {
        // Remove o valor atual
        this.entidades = this.entidades.filter(
          item => item.id !== this.entidadeExcluir.id
        );

        this.mostrarDialogExcluir = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso(
          'Entidade excluída com sucesso',
          null
        );
      },
      error => {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(
          'Erro ao exluir entidade',
          error.error
        );
      }
    );
  }

  addPessoaFisica() {
    console.log('--------------------->>>>>', this.variaveisGlobais);
    this.pessoaFisica = new PessoaFisica();
    this.pessoaFisica.nomeCompleto = '';
    this.pessoaFisica.idEntidade = this.entidadeEdit.id;
    // this.pessoaFisica.endereco = new Endereco;
    console.log(this.pessoaFisica);
    this.mostrarDialogoPessoaFisica = true;
    // this.mostrarDialogEdit = false;
    // if (this.variaveisGlobais.menuRaiz == "mtrcc") {
    this.tituloDialogEditPessoaFisica = 'Novo Responsável';
    this.insertModePF = true;
    this.pfFuncao = 'rLegal';
    this.pessoaFisica.ativo = true;
    this.pfFuncaoRT = [];

    if (this.isPMPA) {
      this.secretarias = [];
      this.idSecretaria = null;
      this.secretariasPmpa.forEach(sec => {
        this.secretarias.push({ label: sec.sigla, value: sec.id });
      });
    }

  }

  controlaFuncao(funcao) {
    console.log('.......controlaFuncao>>>>', funcao);
    if (funcao === 'rLegal' || funcao === 'rusrLegal') {
      this.pfFuncaoRT = [];
    } else {
      this.pfFuncao = '';
    }

  }

  salvarPessoaFisica() {
    // this.entidadeService.getEntidadePorId(this.entidadeEdit.id).subscribe(
    //   entidade => {
    //     this.entidadeEdit = entidade;
    // //Salva PessoaFisica
    this.pessoaFisica['idEntidade'] = this.entidadeEdit.id;

    this.pessoaFisica.rLegal = false;
    this.pessoaFisica.rusrLegal = false;
    this.pessoaFisica.rtElaboracao = false;
    this.pessoaFisica.rtExecucao = false;
    if (this.pfFuncao === 'rLegal') {
      this.pessoaFisica.rLegal = true;
    }
    if (this.pfFuncao === 'rusrLegal') {
      this.pessoaFisica.rusrLegal = true;
    }
    if (this.pfFuncaoRT.length > 1) {
      this.pessoaFisica.rtElaboracao = true;
      this.pessoaFisica.rtExecucao = true;
    } else if (this.pfFuncaoRT.length > 0) {
      if (this.pfFuncaoRT[0] === 'rElaboracao') {
        this.pessoaFisica.rtElaboracao = true;
      }
      if (this.pfFuncaoRT[0] === 'rExecucao') {
        this.pessoaFisica.rtExecucao = true;
      }
    }

    let okSalvar = true;
    if (this.isPMPA) {

      console.log('..........salvarPessoaFisica........listaPF..............>>', this.idSecretaria);
      console.log('..........salvarPessoaFisica........listaPF..............>>', this.secretariasPmpa);
      this.secretariasPmpa.forEach(secr => {
        if (secr.id === this.idSecretaria) {
          this.pessoaFisica.secretariaPmpa = secr;
        }

      });
      if (this.pessoaFisica.secretariaPmpa === undefined || this.pessoaFisica.secretariaPmpa === null) {
        this.notificacoesService.notificarAviso('Por favor, informar a Secretaria/Órgão!', null);
        okSalvar = false;
      }
    }

    if (okSalvar) {
      console.log('salvarPessoaFisica........listaPF..............>>', this.pessoaFisica);
      if (this.pessoaFisica.id == null) {
        this.pessoaFisica.ativo = true;
        this.pessoaFisicaService.criarPessoaFisica(this.pessoaFisica).subscribe(
          retorno => {
            // this.mostrarDialogEdit = false;
            this.spinnerService.ocultar();
            this.notificacoesService.notificarSucesso('PessoaFisica salvo com sucesso', null);
            this.globals.pessoaFisica = retorno;
            if (this.pessoaFisica.id === null) {
              this.pessoaFisicas.push(retorno);
            }
            this.pessoaFisica = retorno;
            this.mostrarDialogoPessoaFisica = false;
            this.pessoaFisica = null;
            this.buscaPessoasFisicasEntidade(this.entidadeEdit.id);

            this.pessoaFisica = this.variaveisGlobais.pessoaFisica;
            console.log('PESSOA FISICA .............>>', this.pessoaFisica);

          },
          error => {
            console.log(error);
            this.spinnerService.ocultar();
            this.notificacoesService.notificarErro(error.error, null);
          }
        );
      } else {
        let permiteInativar = false;
        let permiteAtualizarRl = false;
        if (this.pessoaFisica.ativo && this.pessoaFisica.rLegal) {
          permiteAtualizarRl = true;
          permiteInativar = true;
        } else {
          this.pessoaFisicas.forEach(pessoaF => {
            if (this.pessoaFisica.id !== pessoaF.id) {
              if (pessoaF.rLegal) {
                permiteAtualizarRl = true;
                if (pessoaF.ativo) {
                  permiteInativar = true;
                }
              }
            }
          });
        }
        if (permiteAtualizarRl && permiteInativar) {

          this.mudancaEmail = false;
          this.pessoaFisicas.forEach(pfOriginal => {
            if (pfOriginal.id === this.pessoaFisica.id) {
              if (pfOriginal.email !== this.pessoaFisica.email) {
                this.mudancaEmail = true;
              }
            }
          });

          if (!this.mudancaEmail) {
            this.atualizaPessoaFisica(this.pessoaFisica);
          }

        } else {
          this.spinnerService.ocultar();
          this.notificacoesService.notificarAviso('A Empresa deve possuir ao menos um Responsável Legal ATIVO!', null);
          this.pessoaFisica.ativo = true;
          // this.pessoaFisica.rLegal = true;
        }
      }
    }
    //   },
    //   error => {
    //     console.log(error);
    //     this.spinnerService.ocultar();
    //     this.notificacoesService.notificarErro(error.error, null);
    //   }
    // );
    this.insertMode = false;
  }

  atualizaPessoaFisica(pessoaFisica) {
    // console.log('atualizaPessoaFisica....................>', pessoaFisica);
    // console.log('atualizaPessoaFisica....................>', this.pessoaFisicas);
    this.mudancaEmail = false;

    this.pessoaFisicaService.atualizarPessoaFisica(pessoaFisica).subscribe(
      retorno => {
        // this.mostrarDialogEdit = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso('PessoaFisica salvo com sucesso', null);
        this.globals.pessoaFisica = retorno;
        this.pessoaFisica = retorno;
        this.mostrarDialogoPessoaFisica = false;
        this.mostrarDialogoPFLogada = false;

        if (this.entidadeEdit.id !== null) {
          this.pessoaFisica = null;
          this.buscaPessoasFisicasEntidade(this.entidadeEdit.id);
        }

        this.pessoaFisica = this.variaveisGlobais.pessoaFisica;
        // console.log('PESSOA FISICA .............>>', this.pessoaFisica);

      },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      }
    );

  }

  geraRelacionamentoPJPF() {

    this.pessoaFisica['idEntidade'] = this.entidadeEdit.id;
    this.pessoaFisica.ativo = true;
    this.pessoaFisica.rLegal = true;
    this.pessoaFisica.rusrLegal = false;
    // console.log('salvarPessoaFisica........update..............>>', this.pessoaFisica);
    this.pessoaFisicaService.geraRelacionamentoPJPF(this.pessoaFisica).subscribe(
      retorno => {
        this.pessoaFisica = null;
      },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      }
    );
  }

  inativarPessoaFisica(idPessoaFisica: number) {
    this.pessoaFisica = this.pessoaFisicas.find(function (pessoaFisica) {
      return pessoaFisica.id === idPessoaFisica;
    });

    this.pessoaFisicaService.inativarPessoaFisica(this.pessoaFisica).subscribe(
      retorno => {
        // this.mostrarDialogEdit = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso(
          'PessoaFisica inativada com sucesso',
          null
        );
        // this.globals.pessoaFisica = retorno;
        // this.pessoaFisica = retorno;
        this.mostrarDialogoPessoaFisica = false;

        this.buscaPessoasFisicasEntidade(this.entidadeEdit.id);
        this.pessoaFisica = null;
        this.buscaPessoasFisicasEntidade(this.entidadeEdit.id);


      },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      }
    );

  }

  buscaPessoasFisicasEntidade(idEntidade) {
    this.pessoaFisicas = [];
    this.pessoaFisicaService.getPessoasFisicasDaEntidade(idEntidade).subscribe(pessoaFisicas => {
      this.pessoaFisicas = pessoaFisicas;
      // console.log('Pode Inativar........pessoas...........', this.pessoaFisicas);
      let ativos = 0;
      this.pessoaFisicas.forEach(pessoa => {
        if (pessoa.ativo) {
          ativos = ativos + 1;
          // console.log('Pode Inativar.....Ativos..............', ativos);
        }
      });

      this.podeInativar = false;
      if (ativos > 1) {
        this.podeInativar = true;
      }
      // console.log('Pode Inativar...................', this.podeInativar, '---', ativos);
      this.spinnerService.ocultar();
    });

  }

  selecionaCDL(endereco) {
    // console.log('ENDEREÇO selecionarCdl>>>>>>>', endereco);

    if (endereco.localizacao.latitude === undefined) {
      endereco.localizacao = {};
      // console.log('ENDEREÇO selecionarCdl>>>>>>>', endereco);
      endereco.localizacao.latitude = null;
      endereco.localizacao.longitude = null;
    }
    this.endereco = endereco;
    this.endereco.id = null;
    this.endereco.dataInclusao = null;
    this.endereco.timestampAsString = null;
    this.endereco.usuarioInclusao = null;
    this.endereco.usuarioAlteracao = null;
    this.endereco.isentoLicenca = false;

    // console.log('ENDEREÇO selecionarCdl>>>>>>>', this.endereco);
  }

  salvarEndereco() {
    // this.entidadeEdit.enderecoDO = endereco;
    // console.log('SALVAR ENDEREÇO >>>>>>>', this.entidadeEdit);
    // console.log('SALVAR ENDEREÇO >>>>>>>', this.insertMode);

    if (!this.insertMode && this.entidadeEdit.id !== null) {

      this.endereco.entidadeDO = this.entidadeEdit;
      // this.endereco.isentoLicenca = false;
      // const isencao = this.licencaIsento;
      // if (isencao === 'isento') {
      //   this.endereco.isentoLicenca = true;
      // }

      this.endereco.licencaAmbientalDO = null;
      this.licencas.forEach(lic => {
        if (lic.id === this.idLicenca) {
          this.endereco.licencaAmbientalDO = lic;
        }
      });
      this.endereco.isentoLicenca = this.licencaIsento;

      // console.log('ENDEREÇO LICENÇA salvarEndereco>>>>>>>', this.licencaIsento.checked);

      if (this.endereco.codigoLogradouro == null) {
        this.endereco.enderecoFormatado =
          this.endereco.nomeLogradouro + ', ' + this.endereco.numero;
      } else {
        this.endereco.municipio = 'Porto Alegre';
      }
      console.log('ENDEREÇO salvarEndereco>>>......>>>>', this.endereco);

      if (this.endereco.id === undefined || this.endereco.id === null) {
        this.enderecoService.criarEndereco(this.endereco).subscribe(
          retorno => {
            this.mostrarDialogoEnderecoPoa = false;
            this.spinnerService.ocultar();
            this.notificacoesService.notificarSucesso(
              'Endereço salvo com sucesso',
              null
            );
            if (this.endereco.id === null) {
              this.enderecos.push(retorno);
            }
            this.endereco = retorno;

            this.enderecos = [];
            this.enderecoService
              .getEnderecosPorEntidade(this.entidadeEdit.id)
              .subscribe(
                enderecos => {
                  this.enderecos = enderecos;
                },
                error => {
                  this.spinnerService.ocultar();
                  this.notificacoesService.notificarErro(error.error, null);
                });
          },
          error => {
            console.log(error);
            this.spinnerService.ocultar();
            this.notificacoesService.notificarErro(error.error, null);
          }
        );
      } else {
        this.enderecoService.atualizarEndereco(this.endereco).subscribe(
          retorno => {
            this.mostrarDialogoEnderecoPoa = false;
            this.spinnerService.ocultar();
            this.notificacoesService.notificarSucesso(
              'Endereço salvo com sucesso',
              null
            );
            if (this.endereco.id === null) {
              this.enderecos.push(retorno);
            }
            this.endereco = retorno;

            this.enderecos = [];
            this.enderecoService
              .getEnderecosPorEntidade(this.entidadeEdit.id)
              .subscribe(
                enderecos => {
                  this.enderecos = enderecos;
                },
                error => {
                  this.spinnerService.ocultar();
                  this.notificacoesService.notificarErro(error.error, null);
                });
          },
          error => {
            console.log(error);
            this.spinnerService.ocultar();
            this.notificacoesService.notificarErro(error.error, null);
          }
        );
      }
    } else {
      // console.log('SALVAR ENDEREÇO >>>>>>>', this.endereco);

      if (this.endereco.codigoLogradouro !== undefined && this.endereco.codigoLogradouro !== null) {
        this.endereco.municipio = 'Porto Alegre';
      }

    }

    this.idLicenca = null;
    this.licencaIsento = null;
    this.tituloDialogEditEndereco = '';
    this.mostrarDialogoEnderecoPoa = false;
    this.mostrarDialogoEnderecoForaPoa = false;

  }

  informaEnderecoPoa(isPoa) {
    console.log('ENDEREÇO informaEnderecoPoa>>>>>>>', this.licencasItem);
    this.endereco = new EnderecoEntidade();
    // this.endereco = {
    //   id: null,
    //   dataInclusao: null,
    //   timestampAsString: null,
    //   usuarioInclusao: null,
    //   usuarioAlteracao: null,
    //   cep: null,
    //   codigoBairro: null,
    //   codigoLogradouro: null,
    //   codigoLogradouroFim: null,
    //   codigoLogradouroInicio: null,
    //   dataHistorico: null,
    //   enderecoFormatado: null,
    //   enderecoFormatadoCurto: null,
    //   lado: null,
    //   localizacao: null,
    //   nomeBairro: null,
    //   nomeBairroHistorico: null,
    //   nomeHistorico: null,
    //   nomeLogradouro: null,
    //   nomeLogradouroFim: null,
    //   nomeLogradouroInicio: null,
    //   numero: null,
    //   municipio: null,
    //   situacao: null
    // };

    this.tituloDialogEditEndereco = '';
    this.mostrarDialogoEnderecoPoa = false;
    this.mostrarDialogoEnderecoForaPoa = false;
    if (isPoa) {
      this.mostrarDialogoEnderecoPoa = true;
      this.tituloDialogEditEndereco = 'Endereço de Porto Alegre.';
    } else {
      this.mostrarDialogoEnderecoForaPoa = true;
      this.tituloDialogEditEndereco = 'Endereço de outros Municípios.';
    }
    this.endereco.isentoLicenca = false;

    this.idLicenca = null;
    this.licencaIsento = null;
    console.log('NOVO ENDEREÇO atualizaEndereco>>>>>>>>', this.endereco, '----', this.insertMode);

  }
  atualizaEndereco(idEndereco) {
    const enderecoParaEditar = this.enderecos.find(function (end) {
      return end.id === idEndereco;
    });
    this.endereco = { ...enderecoParaEditar };
    console.log('ENDEREÇO atualizaEndereco>>>>>>>>', this.endereco);

    this.tituloDialogEditEndereco = '';
    this.mostrarDialogoEnderecoPoa = false;
    this.mostrarDialogoEnderecoForaPoa = false;
    if (this.endereco.municipio === 'Porto Alegre') {
      this.mostrarDialogoEnderecoPoa = true;
      this.tituloDialogEditEndereco = 'Endereço de Porto Alegre.';
    } else {
      this.mostrarDialogoEnderecoForaPoa = true;
      this.tituloDialogEditEndereco = 'Endereço de outros Municípios.';
    }
    this.idLicenca = null;
    if (this.endereco.licencaAmbientalDO !== null) {
      this.idLicenca = this.endereco.licencaAmbientalDO.id;
    }
    this.licencaIsento = this.endereco.isentoLicenca;

  }
  excluirEndereco(idEndereco) {
    console.log('ENDEREÇO EXCLUSÃO>>>>>>>>', idEndereco);

    if (confirm('Confirma deleção do ENDEREÇO?')) {
      this.enderecoService.deletaEndereco(idEndereco).subscribe(
        res => {
          this.spinnerService.ocultar();
          this.notificacoesService.notificarSucesso(
            'Endereço excluido com sucesso',
            null
          );

          this.enderecos = [];
          this.enderecoService
            .getEnderecosPorEntidade(this.entidadeEdit.id)
            .subscribe(
              enderecos => {
                this.enderecos = enderecos;
                // this.globals.enderecosEntidade = enderecos;
              },
              error => {
                this.spinnerService.ocultar();
                this.notificacoesService.notificarErro(error.error, null);
              }
            );
        },
        error => {
          this.spinnerService.ocultar();
          this.notificacoesService.notificarErro(error.error, null);
        }
      );
    }
  }

  informaLicenca() {
    console.log('LICENÇA informaLicença>>>>>>>');

    this.licenca = {
      id: null,
      dataInclusao: null,
      timestampAsString: null,
      usuarioInclusao: null,
      usuarioAlteracao: null,
      entidadeDO: null,
      secretariaPmpaDO: null,
      licenca: null,
      dataValidade: null,
      descricao: null
    };

    this.mostrarDialogoLicenca = true;
  }

  selLicenca() {
    this.licencaIsento = false;
  }

  licencaIsencao(licencaIsento) {

    // console.log('===========licencaIsencao=========>', licencaIsento.checked);
    // this.endereco.isentoLicenca = licencaIsento.checked;
    // if (licencaIsento.checked) {
    //   this.idLicenca = null;
    // }
    console.log('===========licencaIsencao=========>', this.licencaIsento);
    this.endereco.isentoLicenca = this.licencaIsento;
    if (this.licencaIsento) {
      this.idLicenca = null;
    }
  }
  informaVeiculo() {
    console.log('ENDEREÇO informaVeiculo>>>>>>>');

    this.veiculo = {
      id: null,
      dataInclusao: null,
      timestampAsString: null,
      usuarioInclusao: null,
      usuarioAlteracao: null,
      placa: null,
      renavam: null
    };

    this.mostrarDialogoVeiculo = true;
  }

  salvarLicenca() {

    if (this.licenca.secretariaPmpaDO !== null) {
      this.licenca.orgaoEmissor = this.licenca.secretariaPmpaDO.sigla;
    }
    // console.log('LICENÇA salvarLicenca>>>>>>>', this.licenca);

    if (this.entidadeEdit === undefined || this.entidadeEdit === null) {
      this.licenca.pessoaFisicaDO = this.pessoaFisica;
    } else {
      this.licenca.entidadeDO = this.entidadeEdit;
    }
    console.log('LICENÇA salvarLicenca>>>>>>>', this.licenca);

    this.licencaAmbientalService.criarLicenca(this.licenca).subscribe(
      retorno => {
        this.mostrarDialogoLicenca = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso(
          'Licença Ambiental salva com sucesso!',
          null
        );
        this.licenca = new LicencaAmbiental;

        this.licencas = [];

        if (this.entidadeEdit === undefined || this.entidadeEdit === null) {

          this.licencaAmbientalService.getLicencasPorPessoaFisica(this.pessoaFisica.id)
            .subscribe(
              licencas => {
                this.licencas = licencas;
                licencas.forEach(lic => {
                  this.licencasItem.push({ label: lic.licenca, value: lic.id });
                });

              },
              error => {
                this.spinnerService.ocultar();
                this.notificacoesService.notificarErro(error.error, null);
              }
            );

        } else {

          this.licencaAmbientalService.getLicencasPorEntidade(this.entidadeEdit.id)
            .subscribe(
              licencas => {
                this.licencas = licencas;
                licencas.forEach(lic => {
                  this.licencasItem.push({ label: lic.licenca, value: lic.id });
                });

              },
              error => {
                this.spinnerService.ocultar();
                this.notificacoesService.notificarErro(error.error, null);
              }
            );
        }
      },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      }
    );
  }

  excluirLicenca(idLicenca) {
    console.log('LICENÇA EXCLUSÃO>>>>>>>>', idLicenca);

    if (confirm('Confirma deleção da Licença Ambiental?')) {
      this.licencaAmbientalService.deletaLicenca(idLicenca).subscribe(
        res => {
          this.spinnerService.ocultar();
          this.notificacoesService.notificarSucesso(
            'Licença Ambiental excluida com sucesso',
            null
          );

          this.licencas = [];
          this.licencaAmbientalService.getLicencasPorEntidade(this.entidadeEdit.id)
            .subscribe(
              licencas => {
                this.licencas = licencas;
                licencas.forEach(lic => {
                  this.licencasItem.push({ label: lic.licenca, value: lic.id });
                });

              },
              error => {
                this.spinnerService.ocultar();
                this.notificacoesService.notificarErro(error.error, null);
              }
            );
        },
        error => {
          this.spinnerService.ocultar();
          this.notificacoesService.notificarErro(error.error, null);
        }
      );
    }
  }

  salvarVeiculo() {
    console.log('VEÍCULO salvarVeiculo>>>>>>>', this.veiculo);

    this.veiculo.entidadeDO = this.entidadeEdit;

    this.veiculoService.criarVeiculo(this.veiculo).subscribe(
      retorno => {
        this.mostrarDialogoVeiculo = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso(
          'Veículo salvo com sucesso!',
          null
        );
        this.veiculo = retorno;

        this.veiculos = [];
        this.veiculoService
          .getVeiculosPorEntidade(this.entidadeEdit.id)
          .subscribe(
            veiculos => {
              this.veiculos = veiculos;
            },
            error => {
              this.spinnerService.ocultar();
              this.notificacoesService.notificarErro(error.error, null);
            }
          );
      },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      }
    );
  }

  excluirVeiculo(idVeiculo) {
    console.log('VEÍCULO EXCLUSÃO>>>>>>>>', idVeiculo);

    if (confirm('Confirma deleção do VEÍCULO?')) {
      this.veiculoService.deletaVeiculo(idVeiculo).subscribe(
        res => {
          this.spinnerService.ocultar();
          this.notificacoesService.notificarSucesso(
            'Veículo excluido com sucesso',
            null
          );

          this.veiculos = [];
          this.veiculoService
            .getVeiculosPorEntidade(this.entidadeEdit.id)
            .subscribe(
              veiculos => {
                this.veiculos = veiculos;
              },
              error => {
                this.spinnerService.ocultar();
                this.notificacoesService.notificarErro(error.error, null);
              }
            );
        },
        error => {
          this.spinnerService.ocultar();
          this.notificacoesService.notificarErro(error.error, null);
        }
      );
    }
  }

  // adicionarAnexo(event) {
  //   console.log('1111111111');

  //   const arquivo: File = event.files[0];
  //   const tis = this;
  //   const reader = new FileReader();
  //   tis.spinnerService.exibir();

  //   reader.onload = function(e) {
  //     const data = reader.result;

  //     const novoAnexo: Anexo = {
  //       fileName: arquivo.name,
  //       fileType: arquivo.type,
  //       fileSize: arquivo.size,
  //       // base64File: data,
  //       file: data,
  //       descricao: tis.novoAnexoDescricao,
  //       tipoAnexo: tis.tipoAnexo,
  //       situacao: tis.situacao
  //     };

  //     tis.anexoService.salvarAnexo(tis.entidadeEdit.id, novoAnexo).subscribe(
  //       anexo => {
  //         tis.anexoService.setFile(anexo['id'], novoAnexo.file).subscribe(
  //           ret => {
  //             tis.spinnerService.ocultar();
  //             tis.notificacoesService.notificarSucesso(
  //               'Anexo adicionado com sucesso',
  //               null
  //             );
  //             console.log('setFile>>>>>>>>>>>>....', ret);
  //             tis.novoAnexoDescricao = null;
  //             tis.fileUpload.clear();
  //             tis.recuperaAnexosEntidade(tis.entidadeEdit.id);
  //             // tis.anexos.push(anexo);
  //           },
  //           error => {
  //             console.log(error);
  //             tis.spinnerService.ocultar();
  //             tis.notificacoesService.notificarErro(
  //               'Erro ao adicionar anexo',
  //               error.message
  //             );
  //           }
  //         );
  //       },
  //       error => {
  //         console.log(error);
  //         tis.spinnerService.ocultar();
  //         tis.notificacoesService.notificarErro(
  //           'Erro ao adicionar anexo',
  //           null
  //         );
  //       }
  //     );
  //   };
  //   reader.readAsArrayBuffer(arquivo);
  //   // reader.readAsDataURL(arquivo);
  // }

  // recuperaAnexo(anexo): string {

  //   // this.anexoService.getAnexo(anexo.id).subscribe(retorno => {
  //   //   // this.spinnerService.ocultar();
  //   //   console.log('getUrlAnexo', retorno);
  //   //   // this.notificacoesService.notificarSucesso('PessoaFisica salvo com sucesso', null);
  //   // },
  //   //   error => {
  //   //     console.log('ERRO>>>>>>', error);
  //   //     this.spinnerService.ocultar();
  //   //     this.notificacoesService.notificarErro(error.error, null);
  //   //   });
  // //  console.log('getUrlAnexo>>>>>', anexo);

  //   // const url = environment.apiUrl + '/anexos/' + idAnexo;
  //   // return url;
  //   const url = environment.apiUrl + '/anexos/' + anexo.id;
  //   console.log('getUrlAnexo>>>>>>>>>>>>>', url);
  //   return url;
  // }

  excluirAnexo(anexo: Anexo) {
    const r = confirm('Confirme a exclusão do anexo ' + anexo.fileName + '?');
    if (r === true) {
      this.anexoService.excluirAnexo(anexo.id).subscribe(
        () => {
          this.notificacoesService.notificarSucesso(
            'Anexo excluído com sucesso',
            ''
          );
          this.anexos = this.anexos.filter(item => item.id !== anexo.id);
        },
        error => {
          this.notificacoesService.notificarErro(error.error, null);
        }
      );
    }
  }

  situacaoAnexo(anexo: Anexo, situacao: String) {

    anexo.situacao = false;
    if (situacao === 'ativar') {
      anexo.situacao = true;
    }
    anexo.file = null;
    this.anexoService.situacaoAnexo(anexo.id, situacao).subscribe(anexo => {
      this.recuperaAnexosEntidade(this.entidadeEdit.id);
      // this.anexos = this.anexos.filter(item => item.id !== anexo.id);
    },
      error => {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      }
    );
  }

  podeSalvarAnexo(): boolean {
    // if (this.planoEdit == null) {
    //   return false;
    // }
    // if (this.planoEdit.situacao === 'Edicao') {
    //   return true;
    // }

    if (this.insertMode === true) {
      return true;
    }

    return true;
  }

  visualizaAnexo(anexo) {

    // console.log('visualizaAnexo..........getUrlAnexo', anexo);
    this.spinnerService.exibir();
    this.anexoService.getAnexo(anexo.id).subscribe(anexoDO => {

      this.spinnerService.ocultar();
      const tipoArquivo = anexoDO.fileType.split('/');
  
      if (tipoArquivo[0] === 'image') {
        const image = new Image();
        image.src = 'data:image/jpg;base64,' + anexoDO.file;
  
        const w = window.open('');
        w.document.write(image.outerHTML);
      } else if (tipoArquivo[0] === 'application') {
        if (tipoArquivo[1] === 'pdf') {
          const byteCharacters = atob(anexoDO.file);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const file = new Blob([byteArray], { type: 'application/pdf;base64' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else if (
          tipoArquivo[1] ===
          'vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          tipoArquivo[1] ===
          'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          // docx
  
          // const reader = new FileReader();
          const byteCharacters = atob(anexoDO.file);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const file = new Blob([byteArray], { type: 'application/octet-stream' });
          const downloadUrl = window.URL.createObjectURL(file);
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = anexoDO.fileName;
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(downloadUrl);
        } else {
          // xml
          this.notificacoesService.notificarSucesso(
            'Arquivo não previsto para visualização!',
            ''
          );
        }
      }

    },
      error => {
        console.log('ERRO>>>>>>', error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });

    // const FileSaver = require('file-saver');
    // const blob = new Blob(["Hello, world!"], { type: "text/plain;charset=utf-8" });
    // FileSaver.saveAs(blob, "hello world.txt");
    // console.log("File Saver>>>>>", FileSaver)

    // const anexoPdf = atob(anexo.value);
    // const b = new Blob([(<any>anexoPdf)], { type: 'application/pdf;base64' });
    // const fileURL = URL.createObjectURL(b);
    // window.open(fileURL);
  }

  onFileChange(event) {
    // console.log("UPLOAD TESTE EVENT>>>>>>>>>", event.target.files[0]);

    this.anexo = {
      id: null,
      fileType: null,
      fileName: null,
      fileSize: null,
      file: null
    } as Anexo;

    const reader = new FileReader();
    console.log('onFileChange reader>>>>>>>>>', reader);

    let file;
    if (event.target.files && event.target.files.length > 0) {
      file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        // const novoAnexo: Anexo = {
        this.anexo.fileName = file.name;
        this.anexo.fileType = file.type;
        this.anexo.fileSize = file.size;
        // base64File: data,
        console.log(reader.result);
        // @ts-ignore
        this.anexo.file = reader.result.split(',')[1];
        this.anexo.descricao = null;
        // }

        console.log('UPLOAD TESTE ANEXO>>>>>>>>>', this.anexo);
      };
    }

    //   anexo.fileType = event.target.files[0].type,
    //   fileName: event.target.files[0].name,
    //   fileSize: event.target.files[0].size,
    //   value: JSON.stringify(reader),
    //   evidenciaDO: this.evidencia
    // } as Anexo;

    // this.anexo.fileName = event.target.files.name;
    // this.anexo.fileType = event.target.files.type;
    // this.anexo.fileSize = event.target.files.size;
    // this.anexo.value = reader.result;
    // this.anexo = novoAnexo;

    // console.log("UPLOAD TESTE>>>>>>>>>", reader);
    // console.log("UPLOAD TESTE EVENT>>>>>>>>>", event.target.files[0]);
  }

  clearFile() {
    // this.fileInput.nativeElement.value = '';
    this.anexo = null;
    this.anexoSel = '';
    // this.evidenciaForm.get('avatar').setValue("");
    // this.evidenciaForm.get('avatar').markAsPristine();
    // this.evidenciaForm.get('avatar').markAsUntouched();
  }

  persisteAnexo() {
    // console.log('persisteAnexo ANEXO ===============', this.anexoSel);
    // console.log('persisteAnexo TIPOANEXO ===============', this.tipoAnexo);

    if (this.anexoSel === '' || this.tipoAnexo === '') {
      this.notificacoesService.notificarErro(
        'Para salvar é necessário <Escolher o Arquivo> e selecionar o <Tipo Anexo>!',
        null
      );
    } else {
      const tipoArquivo = this.anexo.fileType.split('/');
      // console.log('persisteAnexo ===============>>>>>>>>', tipoArquivo);
      if (tipoArquivo[0] !== 'image' && tipoArquivo[1] !== 'pdf') {
        this.notificacoesService.notificarErro(
          'Favor anexar somente arquivos tipo imagem ou pdf!',
          null
        );
      } else {
        this.anexo.tipoAnexo = this.tipoAnexo;
        console.log('******************************************************************');

        if (this.anexo) {
          this.anexo.situacao = true;
          this.spinnerService.exibir();
          this.anexoService.incluirAnexo(this.entidadeEdit.id, this.anexo).subscribe(anexo => {
            // console.log('evidenciaDetail - Retorno INCLUIR Anexo ===============', anexo);
            this.recuperaAnexosEntidade(this.entidadeEdit.id);
            this.spinnerService.ocultar();
            this.notificacoesService.notificarSucesso('Anexo salvo com sucesso!', null);
            this.anexoSel = '';
            this.tipoAnexo = '';
            this.anexo = {
              id: null,
              fileType: null,
              fileName: null,
              fileSize: null,
              file: null,
              situacao: null
            } as Anexo;
            // console.log('persisteAnexo ANEXO 0000===============');
          },
            error => {
              console.log('ERRO>>>>>>', error);
              this.spinnerService.ocultar();
              this.notificacoesService.notificarErro(error.error, null);
            }
          );
        }
      }
    }
  }

  mostrarVinculosAtuais(idEntidade) {
    this.entidadeEdit = this.entidades.find(function (entidade) {
      return entidade.id === idEntidade;
    });

    console.log('Vinculadas Entidade Editada>>>>', this.entidadeEdit);
    this.spinnerService.exibir();
    this.entidadeService.getEntidadesVinculadas(idEntidade).subscribe(
      entidades => {
        console.log('Lista Entidades Vinculadas>>>>', entidades);

        if (entidades.length > 0) {
          this.spinnerService.ocultar();
          this.entidadesVinculadas = entidades;
          this.mostrarVinculos = true;
        } else {
          this.mostrarVinculos = false;
        }
      },
      error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });
    // this.pesquisaEntidades("");
    this.tituloVinculos = 'Lista de Transportadores e Destinos Finais';



  }

  pesquisaEntidades(tipoPesquisa) {
    this.entidades = [];
    console.log('Pesquisa>>>>>>>>>>>>', tipoPesquisa, this.selectedPesquisa);

    this.spinnerService.exibir();
    this.entidadeService.getEntidadesPesquisa(this.selectedPesquisa).subscribe(
      entidades => {
        this.spinnerService.ocultar();
        this.entidades = entidades;
        // this.totalEntidades = entidades.length;
        // this.entidades.forEach(entidade => {
        //   console.log('Lista Entidades >>>>', entidade.possuiPlanos);
        //   this.podeExibirResiduos(entidade);
        // });
      },
      error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      }
    );
  }

  pesquisaEntidadesGdr(tipoPesquisa) {
    this.entidades = [];
    console.log('pesquisaEntidadesGdr>>>>>>>>>>>>', tipoPesquisa, this.selectedPesquisa);

    this.spinnerService.exibir();
    this.entidadeService.getEntidadesPesquisaGdr(tipoPesquisa).subscribe(
      entidades => {
        this.spinnerService.ocultar();
        this.entidades = entidades;
        this.totalEntidades = entidades.length;
        this.entidades.forEach(entidade => {
          console.log('gdrEntidadeComContrato.....*******......>>>>', entidade);
          if (entidade.gdrNumeroContrato !== null) {
            entidade.gdrNumeroContrato = entidade.gdrNumeroContrato.toString() + '/' + entidade.gdrAnoContrato;
          }
        });
        // this.gdrEntidadeComContrato();
      },
      error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      }
    );
  }
  checkActive(route: string) {
    return route === this.activeRoute;
  }

  verificaCnpjExiste(cnpj: string) {

    console.log('verificaCnpjExiste>>>>>>>', cnpj);
    if (cnpj === '__.___.___/____-__') {
      cnpj = null;
    }

    this.entidadeEdit = {
      id: null,
      cnpj: null,
      razaoSocial: '',
      dataInclusao: null,
      timestampAsString: null,
      usuarioInclusao: null,
      usuarioAlteracao: null,
      // nomeSisLog: null,
      licencaAmbiental: null,
      licencaAmbientalOrgaoEmissor: null,
      licencaAmbientalDataValidade: null,
      alvara: null,
      gdrNumeroContrato: null,
      gdrAnoContrato: null,
      gdrSituacaoContrato: null,
      gdrCertificado: null,
      codigoDmlu: null,
      adimplenteDmlu: null,
      desabilitadaMtrcc: null
    };
    this.controlaRazaoSocialEnt = true;
    // this.controlaTelefoneEnt = true;
    this.controlaEmailEnt = true;
    this.controlaNumeroCnaeEnt = true;
    this.controlaDescricaoCnaeEnt = true;

    if (cnpj == null || cnpj === '') {
      // this.spinnerService.ocultar();
      // this.notificacoesService.notificarErro('Não encontrou entidade para CNPJ informado!', null);
    } else {

      this.entidadeEdit.cnpj = cnpj;
      this.entidadeService.getEntidadeCnpj(cnpj).subscribe(entidade => {


        if (entidade != null) {
          this.spinnerService.ocultar();
          this.notificacoesService.notificarErro('Empresa com CNPJ informado já está cadastrada!', null);
        } else {
          // console.log('verificaCnpjExiste1111.....>>>>>>>', entidade);
          this.spinnerService.exibir();
          const novoCnpj = cnpj.replace(/[^\d]+/g, '');
          this.smfPessoaService.getPessoaFazenda(novoCnpj, '2').subscribe(pessoa => {
            // console.log('ACESSO PESSOA SMF>>>>>>>>', pessoa);
            this.controlaMensagemFazenda = false;

            if (pessoa !== null) {
              if (pessoa.nomePessoa != null) {
                this.entidadeEdit.nomeFantasia = pessoa.nomeFantasia;
                this.entidadeEdit.razaoSocial = pessoa.nomePessoa;
                this.entidadeEdit.telefone = pessoa.telefone;
                this.entidadeEdit.email = pessoa.email;
                this.entidadeEdit.numeroCnae = pessoa.numeroCnae;
                this.entidadeEdit.descricaoCnae = pessoa.descricaoCnae;
                this.controlaRazaoSocialEnt = false;
                // this.controlaTelefoneEnt = false;
                this.controlaEmailEnt = false;
                this.controlaNumeroCnaeEnt = false;
                this.controlaDescricaoCnaeEnt = false;
                // this.entidadeEdit. = pessoa.;
                // this.entidadeEdit. = pessoa.;
                // this.entidadeEdit. = pessoa.;
                this.controlaMensagemFazenda = true;
                console.log('verificaCnpjExiste1111.....>>>>>>>', pessoa);
                console.log('verificaCnpjExiste1111.....>>>>>>>', this.controlaMensagemFazenda);

              }
            }
            this.spinnerService.ocultar();

          },
            error => {
              console.log('-----------------------------------------------------------');
              console.log(error);
              console.log(error.error);
              this.spinnerService.ocultar();
              this.notificacoesService.notificarErro(error.error, null);
            });
        }

      },
        error => {
          console.log(error);
          console.log(error.error);
          this.spinnerService.ocultar();
          this.notificacoesService.notificarErro(error.error, null);
        });
    }

  }

  verificaCpfExiste(cpf: string) {

    console.log('verificaCpfExiste>>>>>>>', cpf);
    if (cpf === '__.___.___-__') {
      cpf = null;
    }

    if (cpf == null || cpf === '') {
      // this.spinnerService.ocultar();
      // this.notificacoesService.notificarErro('Não encontrou entidade para CNPJ informado!', null);
    } else {

      this.pessoaFisicaService.getPessoasFisicasPorCpf(cpf).subscribe(pessoaFisica => {

        console.log('verificaCnpjExiste1111>>>>>>>', pessoaFisica);

        if (pessoaFisica != null) {
          this.pessoaFisica = pessoaFisica;
          this.pessoaFisica.ativo = true;
          this.insertMode = false;
          // this.spinnerService.ocultar();
          // this.notificacoesService.notificarErro('Empresa com CNPJ informado já está cadastrada!', null);
        } else {
          const novoCpf = cpf.replace(/[^\d]+/g, '');
          // Acessa SMF
          this.smfPessoaService.getPessoaFazenda(novoCpf, '1').subscribe(pessoa => {
            console.log('ACESSO PESSOA SMF>>>>>>>>', pessoa);
            this.pessoaFisica.nomeCompleto = pessoa.nomePessoa;
          });
        }

      },
        error => {
          console.log(error);
          console.log(error.error);
          this.pessoaFisica.cpf = null;
          this.spinnerService.ocultar();
          this.notificacoesService.notificarErro(error.error, null);
        });
    }
  }

  gdrEntidadeComContrato() {

    let listaIdEntidade: any = [];
    console.log('*****************************>>>>>', this.entidades.length);
    this.entidades.forEach(entidade => {
      listaIdEntidade.push(entidade.id);
    });
    this.spinnerService.exibir();

    this.dmluService.getEntidadesComContrato(listaIdEntidade).subscribe(
      contratos => {
        this.spinnerService.ocultar();
        console.log('gdrEntidadeComContrato.....*******......>>>>', contratos);

        if (contratos !== null) {
          contratos.forEach(contratoVO => {
            this.entidades.forEach(entidade => {
              if (entidade.id === contratoVO.idContratante) {
                console.log('gdrEntidadeComContrato.....*******......>>>>', entidade);
                entidade.gdrNumeroContrato = contratoVO.numeroContrato.toString() + '/' + contratoVO.anoContrato;
                entidade.gdrSituacaoContrato = contratoVO.situacao;
                entidade.gdrCertificado = contratoVO.id;
              }
            });
          });
        }
      },
      error => {
        console.log(error);
        console.log(error.error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      }
    );

  }

  informacaoCadastral(pf: PessoaFisica) {
    // console.log('informacaoCadastral...........>>>>', pf);
    this.mostrarDialogoPFLogada = true;
    this.pessoaFisica = pf;
    this.pfEmail = pf.email;

    this.licencaAmbientalService.getLicencasPorPessoaFisica(this.pessoaFisica.id)
      .subscribe(
        licencas => {
          this.licencas = licencas;
          licencas.forEach(lic => {
            this.licencasItem.push({ label: lic.licenca, value: lic.id });
          });

        },
        error => {
          this.spinnerService.ocultar();
          this.notificacoesService.notificarErro(error.error, null);
        }
      );
  }

  salvaPessoaFisicaLogada(pessoaFisica) {
    console.log('atualizaPessoaFisica....................>', pessoaFisica);
    console.log('atualizaPessoaFisica....................>', this.variaveisGlobais.pessoaFisica);

    this.mudancaEmail = false;
    if (this.pfEmail !== pessoaFisica.email) {
      this.mudancaEmail = true;
    }

    if (!this.mudancaEmail) {
      this.atualizaPessoaFisica(pessoaFisica);
    }

    this.pfEmail = '';
  }

  mostraExpedienteUnico(codigoLogradouro, numero) {

    this.mostrarDialogoEU = true;
    this.expedienteUnicoVO = new ExpedienteUnicoVO();
    this.expedienteUnicoVOList = [];
    this.spinnerService.exibir();

    if (codigoLogradouro === null) {
      // this.expedienteUnicoVO.expedienteFormatado = "Não foi possível consultar os EUs do endereço., pois está sem o código do logradouro";
      this.expedienteUnicoVO.expedienteFormatado = "naoOK";
      this.expedienteUnicoVO.procedimentoFormatadoSei = "Não foi possível consultar os EUs deste endereço.Este endereço está sem o código de logradouro necessário para a consulta."
      this.expedienteUnicoVOList.push(this.expedienteUnicoVO);
      this.spinnerService.ocultar();
    } else {
      this.enderecoService.getEnderecoCodigoNumeroListaEU(codigoLogradouro, numero).subscribe(expedienteUnicoList => {
        // this.licencas = licencas;
        this.expedienteUnicoVOList = expedienteUnicoList;
        console.log('---------------EU>>>', this.expedienteUnicoVOList);
        this.spinnerService.ocultar();
        if (expedienteUnicoList.length < 1) {
          this.expedienteUnicoVO.expedienteFormatado = "naoOK";
          this.expedienteUnicoVO.procedimentoFormatadoSei = "Endereço sem Expediente Único."
          this.expedienteUnicoVOList.push(this.expedienteUnicoVO);
        }

      },
        error => {
          this.spinnerService.ocultar();
          this.notificacoesService.notificarErro(error.error, null);
          this.expedienteUnicoVO = null;
          this.spinnerService.ocultar();
        });
    }

  }

  habilitarDesabilitarEmpresa(idEntidade: number) {

    const entidadeParaEditar = this.entidades.find(function (entidade) {
      return entidade.id === idEntidade;
    });
    this.entidadeEdit = { ...entidadeParaEditar };

    console.log('---------------habilitarDesabilitarEmpresa>>>', this.entidadeEdit);

    if (this.entidadeEdit.desabilitadaMtrcc) {
      this.entidadeEdit.desabilitadaMtrcc = false;
    } else {
      this.entidadeEdit.desabilitadaMtrcc = true;
    }

    this.entidadeService.getEntidadeHabilitaDesabilita(this.entidadeEdit).subscribe(entidade => {

      this.entidadeEdit = entidade;
      console.log('---------------habilitarDesabilitarEmpresa>>>', this.entidadeEdit);
      let objIndex = this.entidades.findIndex((obj => obj.id == this.entidadeEdit.id));
      this.entidades[objIndex].desabilitadaMtrcc = this.entidadeEdit.desabilitadaMtrcc;
      console.log('---------------habilitarDesabilitarEmpresa11>>>', this.entidades);
      // return this.entidades;
    },
      error => {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
        this.expedienteUnicoVO = null;
        this.spinnerService.ocultar();
      });

  }

  balanca(codigoBalanca: number) {

    this.router.navigate(['/gdr/balanca/', codigoBalanca], {
      relativeTo: this.route
    });

  }

}
