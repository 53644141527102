import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { PessoaVO } from 'src/app/@shared/models/pessoaVO.model';

@Injectable({providedIn: 'root'})
export class SmfPessoaService {

  constructor(private http: HttpClient) { }

  getPessoaFazenda(documento, tipoPessoa): Observable<PessoaVO> {
    console.log('....................EntidadeService getPessoaJuridica>>>>', environment.apiUrl + '/pessoaFazenda/' + documento+ '---' + tipoPessoa);
    return this.http.get<PessoaVO>(environment.apiUrl + '/pessoaFazenda/' + documento + '/' + tipoPessoa);
  }

  // getPessoaFisica(cpf): Observable<any> {
  //   console.log('SmfPessoaService getPessoaFisica>>>>', cpf);
  //   return this.http.get<any>(BACKEND_URL + cpf + '&tipoPessoa=1&buscaLogradouros=true&buscaContatos=true&buscaSocios=false&buscaSociedades=true&buscaTelefonesEmails=true&buscaNomeFantasia=false&buscaAtividadeCnaeIsq=true&apikey=05eec362-6599-451e-a3d2-f727b6f2c842');
  // }


}
