import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { MenuItem, MessageService, SelectItem } from 'primeng/api';
import { SpinnerService } from '../services/spinner.service';
import { NotificacoesService } from '../services/notificacoes.service';
import { Globals } from 'src/app/globals';
import { VeiculoClass } from 'src/app/@shared/models/veiculo.model';
import { VeiculoService } from '../services/veiculo.service';
import { Entidade } from 'src/app/@shared/models/entidade.model';
import { EntidadeService } from '../entidade/entidade.service';

@Component({
  templateUrl: './veiculoPmpa.component.html',
  styleUrls: ['./veiculoPmpa.component.scss'],
  providers: [MessageService]
})
export class VeiculoPmpaComponent implements OnInit {

  veiculos: VeiculoClass[] = [];
  items: MenuItem[];
  nomeUsuario: string;
  entidade: Entidade;
  entidadeNome = '';
  cnpjExecutora = '';
  // Novo/edit
  veiculoEdit: VeiculoClass;
  mostrarDialogEdit: boolean;
  tituloDialogEdit: string;
  isInsert: boolean;
  // Exluir
  veiculoExcluir: VeiculoClass;
  mostrarDialogExcluir: boolean;

  variaveisGlobais: Globals;
  activeRoute: string;

  constructor(
    private router: Router,
    private keycloakService: KeycloakService,
    private veiculoService: VeiculoService,
    private spinnerService: SpinnerService,
    private notificacoesService: NotificacoesService,
    private entidadeService: EntidadeService,
    private globals: Globals
  ) {

    this.activeRoute = this.router.url;
    this.variaveisGlobais = globals;
  }

  ngOnInit() {

    if (this.variaveisGlobais.menuRaiz === null || this.variaveisGlobais.menuRaiz === '') {
      this.router.navigate(['/sgrIni']);
    } else {
      this.inicializaOnInit();
    }

  }

  inicializaOnInit() {
    this.spinnerService.exibir();
    this.veiculoEdit = new VeiculoClass();

    this.entidade = null;
    this.entidadeService.getEntidadeCnpj('92.963.560/0001-60').subscribe(entidade => {
      this.entidade = entidade;
      this.veiculoService.getVeiculosPorEntidade(entidade.id).subscribe(veiculos => {
        this.spinnerService.ocultar();
        this.veiculos = veiculos;
      });

    },
    error => {
      console.log(error);
      console.log(error.error);
      this.spinnerService.ocultar();
      this.notificacoesService.notificarErro(error.error, null);
    });






  }

  logout() {
    const url = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
    this.keycloakService.getKeycloakInstance().logout({redirectUri: url});
  }

  mostrarDialogParaNovo() {
    this.veiculoEdit = new VeiculoClass();
    this.mostrarDialogEdit = true;
    this.isInsert = true;

    this.tituloDialogEdit = 'Novo Veículo';
  }

  mostrarDialogParaEditar(idVeiculo: number) {
    const veiculoParaEditar = this.veiculos.find(function(veiculo) {
      return veiculo.id === idVeiculo;
    });
    this.veiculoEdit = {...veiculoParaEditar};
    console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.veiculoEdit);

    this.mostrarDialogEdit = true;
    this.isInsert = false;
    this.tituloDialogEdit = 'Editar Veículo';
  }

  mostrarDialogEditParaExcluir(idProduto: number) {
    // this.produtoExcluir =  this.produtos.find(function(produto) {
    //   return produto.id === idProduto;
    // });
    // this.mostrarDialogExcluir = true;
  }

  salvar() {

    //Verifica se todos os campos foram preenchidos
    if (this.veiculoEdit.placa == null || this.veiculoEdit.placa === '') {
      this.notificacoesService.notificarAviso('Por favor informar a PLACA do Veículo!', null);
      return ;
    }
    if (this.veiculoEdit.renavam == null || this.veiculoEdit.renavam === '') {
      this.notificacoesService.notificarAviso('Por favor informar o RENAVAM do Veículo!', null);
      return ;
    }
    // if (this.veiculoEdit.endereco == null || this.veiculoEdit.endereco === '') {
    //   this.notificacoesService.notificarAviso('Por favor informar o ENDEREÇO da Secretaria/Órgão!', null);
    //   return ;
    // }

    this.spinnerService.exibir();

    console.log('salvar....................>>>>>>>>>>>>>>', this.veiculoEdit);
    this.veiculoEdit.entidadeDO = this.entidade;

    // Se novo
    if (this.veiculoEdit.id == null) {
      this.veiculoService.criarVeiculo(this.veiculoEdit).subscribe(veiculoNovo => {
        console.log('salvado....................>>>>>>>>>>>>>>', veiculoNovo);
        this.veiculos.push(veiculoNovo);

        this.mostrarDialogEdit = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso('Adicionado com sucesso', null);
      },
      error => {
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro('Erro ao adicionar ', error);
      });
    } else { // Se editando
      this.veiculoService.editarVeiculo(this.veiculoEdit).subscribe(veiculoEditado => {
        // Remove o valor atual
        this.veiculos = this.veiculos.filter(item => item.id !== this.veiculoEdit.id);

        // Adiciona o novo
        this.veiculos.push(veiculoEditado);

        this.mostrarDialogEdit = false;
        this.spinnerService.ocultar();
        this.notificacoesService.notificarSucesso('Atualizado com sucesso', null);
      },
      error => {
        console.log(error);
        this.spinnerService.ocultar();
        this.notificacoesService.notificarErro(error.error, null);
      });
    }
  }

  excluir() {
    // this.spinnerService.exibir();
    // console.log('salvar....................>>>>>>>>>>>>>>', this.variaveisGlobais.menuRaiz);

    // this.produtoService.excluirProduto(this.produtoExcluir, this.variaveisGlobais.menuRaiz).subscribe(produto => {
    //   if (produto == null) {
    //     // Remove o valor atual
    //     this.produtos = this.produtos.filter(item => item.id !== this.produtoExcluir.id);
    //   }
    //   this.mostrarDialogExcluir = false;
    //   this.spinnerService.ocultar();
    //   this.notificacoesService.notificarSucesso('Excluído com sucesso', null);
    // },
    // error => {
    //   console.log(error);
    //   this.spinnerService.ocultar();
    //   this.notificacoesService.notificarErro('Erro ao exluir', error.error);
    // });
  }


  checkActive(route: string) {
    return route === this.activeRoute;
  }

}
