import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Contrato, ContratoInter } from 'src/app/@shared/models/contrato.model';
import { ContratoVO, ContratoVOInter } from 'src/app/@shared/models/contratoVO.model';
import { Certificado } from 'src/app/@shared/models/certificado.model';
import { Entidade } from 'src/app/@shared/models/entidade.model';
import { ContratoDescargaResiduo } from 'src/app/@shared/models/contratoDescargaResiduo.model';

@Injectable({ providedIn: 'root' })
export class DmluService {

  constructor(private http: HttpClient) { }

  //CONTRATOS
  getContratosIdEntidade(idEntidade: number): Observable<ContratoVO[]> {
    const url = environment.apiUrl + '/gdr/contratoIdEntidade/' + idEntidade;
    return this.http.get<ContratoVO[]>(url);
  }

  getContratoId(idContrato: number): Observable<Contrato> {
    const url = environment.apiUrl + '/gdr/contratoId/' + idContrato;
    return this.http.get<Contrato>(url);
  }

  novoContrato(contrato: Contrato): Observable<Contrato> {

    console.log('Service ... novoContrato>>>', contrato);
    return this.http.post<Contrato>(environment.apiUrl + '/gdr', contrato);

  }

  atualizarResiduos(contrato: Contrato): Observable<Contrato> {

    console.log('Service ... atualizarResiduos>>>', contrato);
    return this.http.put<Contrato>(environment.apiUrl + '/gdr', contrato);

  }

  contratoAndamento(contrato: Contrato): Observable<Contrato> {
    return this.http.put<Contrato>(environment.apiUrl + '/gdr/contratoAndamento', contrato);
  }

  getEmpresaJaTemContrato(idEntidade: number): Observable<Boolean> {
    const url = environment.apiUrl + '/gdr/empresaJaTemContrato/' + idEntidade;
    console.log('Service ... getEmpresaJaTemContrato>>>', idEntidade);
    return this.http.get<Boolean>(url);
  }

  // getEntidadesComContrato(idEntidade: number): Observable<Contrato> {
  //   const url = environment.apiUrl + '/gdr/entidadeComContrato/' + idEntidade;
  //   return this.http.get<Contrato>(url);
  // }

  getEntidadesComContrato(listaIdEntidade): Observable<ContratoVO[]> {
    console.log('Service ... getEntidadesComContrato>>>', listaIdEntidade);

    const url = environment.apiUrl + '/gdr/entidadeComContrato?listaIdEntidade=' + listaIdEntidade;
    return this.http.get<ContratoVO[]>(url);
  }

  getContratoValido(idEntidade: number): Observable<Contrato> {
    return this.http.get<Contrato>(environment.apiUrl + '/gdr/contratoValido/' + idEntidade);
  }

  gerarNumeroContrato(): Observable<number> {
    const url = environment.apiUrl + '/gdr/geraNumeroContrato';
    return this.http.get<number>(url);
  }

  gerarCodigoDmlu(idEntidade: number): Observable<number> {
    const url = environment.apiUrl + '/gdr/geraCodigoDmlu/' + idEntidade;
    return this.http.get<number>(url);
  }

  empresaAdimplenteInadimplente(idEntidade: number, adimplente: boolean): Observable<Entidade> {
    const url = environment.apiUrl + '/gdr/empresaAdimplenteDmlu/' + idEntidade + '/' + adimplente;
    return this.http.get<Entidade>(url);
  }

  enviaEmailSMCA(idEntidade, residuoNovo: String): Observable<String> {
    const url = environment.apiUrl + '/gdr/enviaEmailSMCA/' + idEntidade + '/' + residuoNovo;
    return this.http.get<String>(url);
  }

  //CERTIFICADOS
  getCertificadosIdEntidade(idEntidade: number): Observable<Certificado[]> {
    const url = environment.apiUrl + '/gdr/certificadoIdEntidade/' + idEntidade;
    return this.http.get<Certificado[]>(url);
  }

  editarCertificado(certificado: Certificado): Observable<Certificado> {
    return this.http.put<Certificado>(environment.apiUrl + '/gdr/certificado', certificado);
  }

  novoCertificado(certificado: Certificado): Observable<Certificado> {

    console.log('Service ... novoCertificado>>>', certificado);
    return this.http.post<Certificado>(environment.apiUrl + '/gdr/certificado', certificado);

  }

  getValorUFM(): Observable<number> {
    return this.http.get<number>(environment.apiUrl + '/gdr/valorUFM');
  }

  getDescargaResiduoPesquisa(codigoBalancaPesquisa,mesPesquisa): Observable<ContratoDescargaResiduo[]> {
    return this.http.get<ContratoDescargaResiduo[]>(environment.apiUrl + '/gdr/movimentoMensalGeral/' + codigoBalancaPesquisa + '/' + mesPesquisa);
  }

  getTotalPorResiduo(codigoBalancaPesquisa,mesPesquisa): Observable<ContratoDescargaResiduo[]> {
    return this.http.get<ContratoDescargaResiduo[]>(environment.apiUrl + '/gdr/totalPorResituo/' + codigoBalancaPesquisa + '/' + mesPesquisa);
  }

  getDescargaResiduoCodigoBalancaMes(codigoBalanca, mesPesquisa): Observable<ContratoDescargaResiduo[]> {
    return this.http.get<ContratoDescargaResiduo[]>(environment.apiUrl + '/gdr/descargaResiduoCodigoBalancaMes/' + codigoBalanca + '/' + mesPesquisa);
  }

  atualizarDescarga(cdr: ContratoDescargaResiduo): Observable<ContratoDescargaResiduo> {

    console.log('Service ... atualizarDescarga>>>', cdr);
    return this.http.put<ContratoDescargaResiduo>(environment.apiUrl + '/gdr/atualizarDescarga', cdr);

  }

  excluirDescarga(cdr: ContratoDescargaResiduo) {

    const url = environment.apiUrl + '/gdr/deletarDescarga/' + cdr.id;

    return this.http.delete(url);

  }

}
