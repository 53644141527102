<sgr-menu></sgr-menu>
<div style="width: fit-content; margin: auto;">
  <h2 class="text-primary mt-4 mb-0 pb-4" style="font-family: 'Roboto', 'Trebuchet MS', Arial, Helvetica, sans-serif">
    Balança - Descarga de Resíduos - DMLU
  </h2>
</div>
<div style="width: fit-content; margin: auto;">

  <div class="mb-3">
    <p-accordion>
      <p-accordionTab header="Critérios de Pesquisa - Balança">
        <div class="row">
          <div class="col-auto form-group">
            <label for="">Mês</label>
            <p-dropdown [options]="meses" [(ngModel)]="mesPesquisa" placeholder="Selecione" (click)="exibeSemMovimentacao01=false;exibeSemMovimentacao02=false"></p-dropdown>
          </div>
          <div class="col-auto form-group">
            <label for="">Código DMLU</label>
            <input [(ngModel)]="codigoBalancaPesquisa" name="codigoBalancaPesquisa" size="10" class="form-control">
          </div>
          <div class="col-auto form-group">
            <!-- <label for="">CNPJ</label>
            <p-inputMask id="cnpj" mask="99.999.999/9999-99" [(ngModel)]="cnpjPesquisa" size="16" name="cnpjPesquisa">
            </p-inputMask> --> 
          </div>
          <div class="botoes col-auto form-group" style="display: flex; align-items: flex-end">
            <button type="button" pButton class="btn btn-primary my-0" (click)="pesquisaBalanca(true)"
              label="Pesquisa" style="height:38px" *ngIf="!variaveisGlobais.roleEntidade"></button>
            <button type="button" pButton class="btn btn-primary my-0" (click)="pesquisaBalanca(false)"
              label="Pesquisa" style="height:38px" *ngIf="variaveisGlobais.roleEntidade"></button>
            <button type="button" pButton class="btn btn-primary my-0" (click)="mesPesquisa='';codigoBalancaPesquisa=0" 
              label="Limpar Critérios" style="height:38px"></button>
            <button title="Totais por resíduos" (click)="mostrarTotaisResiduoBalancaMes(codigoBalancaPesquisa)" label="Total por resíduo"
              class="btn btn-outline-secondary mx-2" *ngIf="variaveisGlobais.roleEntidade">
              <i class="fa fa-calculator pr-2"></i>Total por resíduo
            </button>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
  

  <div style="overflow-x: auto" *ngIf="!variaveisGlobais.roleEntidade">
    <p-table #tabelaBalanca [value]="contratoDescargaResiduos" [paginator]="true" sortField="codigoBalanca"
      [rows]="10" [globalFilterFields]="['codigoBalanca', 'nomeEmpresa']"
      [style]="{ width: '1300px' }">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="codigoBalanca" class="text-center" style="width: 100px; height: 60px;">
            Código Balança
            <p-sortIcon field="codigoBalanca"></p-sortIcon>
          </th>
          <th pSortableColumn="nomeEmpresa" class="text-center" style="width: 100px; height: 60px;">
            Empresa
            <p-sortIcon field="nomeEmpresa"></p-sortIcon>
          </th>
          <th class="text-center" style="width: 100px; height: 60px;">
            Total (Kg)
          </th>
          <th style="width: 200px;" class="text-center">
            Ações
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-cdr>
        <tr>
          <td>
            {{ cdr.codigoBalanca }}
          </td>
          <td>
            {{ cdr.nomeEmpresa }}
          </td>
          <td>
            {{ cdr.total | milhar }}
          </td>
          <td>
            <button title="Editar" (click)="mostrarDescargaResiduoCodigoBalancaMes(cdr.codigoBalanca)" label="Detalhes"
              class="btn btn-outline-secondary mx-2"><i class="fa fa-info pr-2"></i>Detalhes
            </button>
            <button title="Totais por resíduos" (click)="mostrarTotaisResiduoBalancaMes(cdr.codigoBalanca)" label="Total por resíduo"
              class="btn btn-outline-secondary mx-2">
              <i class="fa fa-calculator pr-2"></i>Total por resíduo</button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <span *ngIf="exibeSemMovimentacao01 && !variaveisGlobais.roleEntidade"><b>Sem movimentação para o mes de {{mesPesquisa}}!</b></span>
  </div>

  <div style="overflow-x: auto" *ngIf="variaveisGlobais.roleEntidade">
    <p-table #tabelaBalanca [value]="contratoDescargaResiduosDetalhe"
    [rows]="10" [style]="{ width: '1300px' }" [totalRecords]="totalEntidades"
    pageLinks="10" [rowsPerPageOptions]="[15,30,50]" [paginator]="true">
      <ng-template pTemplate="header">
        <tr>
          <th class="text-center" style="width: 50px; height: 60px;">
            Data Descarga
          </th>
          <th class="text-center" style="width: 50px; height: 40px;">
            Hora Entrada
          </th>
          <th class="text-center" style="width: 50px; height: 40px;">
            Hora Saída
          </th>
          <th class="text-center" style="width: 30px; height: 60px;">
            Veículo
          </th>
          <th class="text-center" style="width: 50px; height: 60px;">
            Peso Bruto (Kg)
          </th>
          <th class="text-center" style="width: 50px; height: 60px;">
            Peso Tara (Kg)
          </th>
          <th class="text-center" style="width: 50px; height: 60px;">
            Peso Líquido (Kg)
          </th>
          <th class="text-center" style="width: 100px; height: 60px;">
            Destino
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-cdr>
        <tr>
          <td>
            {{ cdr.dataDescargaInicio | date: 'dd/MM/yyyy' : 'pt' }}
          </td>
          <td>
            {{ cdr.dataDescargaInicio | date: 'HH:mm' : 'pt' }}
          </td>
          <td>
            {{ cdr.dataDescargaFinal | date: 'HH:mm' : 'pt' }}
          </td>
          <td>
            {{ cdr.placaVeiculo }}
          </td>
          <td>
            {{ cdr.pesoBruto | milhar }}
          </td>
          <td>
            {{ cdr.pesoTara | milhar }}
          </td>
          <td>
            {{ cdr.pesoLiquido | milhar }}
          </td>
          <td>
            {{ cdr.nomeDestino }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <span *ngIf="exibeSemMovimentacao01 && !variaveisGlobais.roleEntidade"><b>Sem movimentação para o mes de {{mesPesquisa}}!</b></span>
  </div>
</div>

<p-dialog [header]="tituloEdicao" 
  [(visible)]="mostrarMovimentoIndividual" [responsive]="true" showEffect="fade"
  [modal]="true" [width]="1500" [contentStyle]="{'max-height':'800px'}" resizable="true" class="scroller container">
  <div class="row">

    <div class="col-sm-3 form-group">
      <label for="cpf">CNPJ</label>
      <div class="card">
        <div class="card-header">
          {{entidade?.cnpj}}
        </div>
      </div>
    </div>
    <div class="col-sm-3 form-group">
      <label for="cpf">Razão Social</label>
      <div class="card">
        <div class="card-header">
          {{entidade?.razaoSocial}}
        </div>
      </div>
    </div>
    <div class="col-sm-3 form-group">
      <label for="cpf">Código DMLU</label>
      <div class="card">
        <div class="card-header">
          {{entidade?.codigoDmlu}}
        </div>
      </div>
    </div>
    <div class="col-sm-3 form-group">
      <label for="cpf">Peso Total (Kg)</label>
      <div class="card">
        <div class="card-header">
          {{totalPeso | milhar}}
        </div>
      </div>
    </div>


    <div style="overflow-x: auto">
      <p-table #tabelaBalanca [value]="contratoDescargaResiduosDetalhe"
      [rows]="10" [style]="{ width: '1300px' }" [totalRecords]="totalEntidades"
      pageLinks="10" [rowsPerPageOptions]="[15,30,50]" [paginator]="true">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center" style="width: 50px; height: 60px;">
              Data Descarga
            </th>
            <th class="text-center" style="width: 50px; height: 60px;">
              Hora Entrada
            </th>
            <th class="text-center" style="width: 50px; height: 60px;">
              Hora Saída
            </th>
            <th class="text-center" style="width: 30px; height: 60px;">
              Veículo
            </th>
            <th class="text-center" style="width: 50px; height: 60px;">
              Peso Bruto (Kg)
            </th>
            <th class="text-center" style="width: 50px; height: 60px;">
              Peso Tara (Kg)
            </th>
            <th class="text-center" style="width: 50px; height: 60px;">
              Peso Líquido (Kg)
            </th>
            <th class="text-center" style="width: 100px; height: 60px;">
              Destino
            </th>
            <th style="width: 100px;" class="text-center" *ngIf="!variaveisGlobais.roleEntidade">
              Ações
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-cdr>
          <tr>
            <td>
              {{ cdr.dataDescargaInicio | date: 'dd/MM/yyyy' : 'pt' }}
            </td>
            <td>
              {{ cdr.dataDescargaInicio | date: 'HH:mm' : 'pt' }}
            </td>
            <td>
              {{ cdr.dataDescargaFinal | date: 'HH:mm' : 'pt' }}
            </td>
            <td>
              {{ cdr.placaVeiculo }}
            </td>
            <td>
              {{ cdr.pesoBruto | milhar }}
            </td>
            <td>
              {{ cdr.pesoTara | milhar }}
            </td>
            <td>
              {{ cdr.pesoLiquido | milhar }}
            </td>
            <td>
              {{ cdr.nomeDestino }}
            </td>
            <td *ngIf="!variaveisGlobais.roleEntidade">
              <button title="Editar" (click)="mostrarEditarDescarga(cdr.id)" label="Detalhes"
                class="btn btn-outline-secondary mx-2"><i class="fa fa-info pr-2"></i>Detalhes
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <span *ngIf="exibeSemMovimentacao02"><b>Sem movimentação para o mes de {{mesPesquisa}}!</b></span>
    </div>

  </div>
  
</p-dialog>


<p-dialog header="Balança - Edição de Descarga de Resíduos" 
  [(visible)]="mostrarEdicao" [responsive]="true" showEffect="fade" [modal]="true" 
  [width]="1500" [contentStyle]="{'max-height':'800px'}" resizable="true" class="scroller container">
  <div class="row">

    <div class="col-sm-3 form-group">
      <label for="cpf">CNPJ</label>
      <div class="card">
        <div class="card-header">
          {{entidade?.cnpj}}
        </div>
      </div>
    </div>
    <div class="col-sm-3 form-group">
      <label for="cpf">Razão Social</label>
      <div class="card">
        <div class="card-header">
          {{entidade?.razaoSocial}}
        </div>
      </div>
    </div>

    <div class="col-sm-12 form-group">
      <div class="row">
        <div class="col-sm-2 form-group">
          <label for="">Código Balança</label>
          <input pInputText id="codigoBalancaEdicao" [(ngModel)]="codigoBalancaEdicao" maxlength="7" size="7"/>
        </div>
        <div class="col-sm-4 form-group">
          <label for="">Data e Hora de Entrada</label>
          <p-calendar class="col-sm-3 p0" [(ngModel)]="dataEntrada" [showIcon]="true" dateFormat="dd/mm/yy"
            [style]="{'width':'50%', 'align' : 'left'}" [inputStyle]="{'width':'50%', 'align' : 'left'}"
            appendTo="body"></p-calendar>
          <p-calendar [(ngModel)]="dataEntrada" timeOnly="true" showTime="true" hourFormat="24" [utc]="true"
            [style]="{'width':'40%', 'align' : 'left'}" [inputStyle]="{'width':'40%'}"></p-calendar>
        </div>
        <div class="col-sm-4 form-group">
          <label for="">Data e Hora de Saída</label>
          <p-calendar class="col-sm-3 p0" [(ngModel)]="dataSaida" [showIcon]="true" dateFormat="dd/mm/yy"
            [style]="{'width':'50%'}" [inputStyle]="{'width':'50%'}"></p-calendar>
          <p-calendar [(ngModel)]="dataSaida" timeOnly="true" showTime="true" hourFormat="24" [utc]="true"
            [style]="{'width':'40%', 'align' : 'left'}" [inputStyle]="{'width':'40%'}"></p-calendar>
        </div>
        <div class="col-sm-2 form-group">
        </div>
      </div>
    </div>

    <div class="col-sm-12 form-group">
      <div class="row">
        <div class="col-sm-2 form-group">
          <label for="">Veículo</label>
          <input pInputText id="placaVeiculo" [(ngModel)]="placaVeiculo" maxlength="7" size="7"/>
        </div>
        <div class="col-sm-2 form-group">
          <label for="">Peso Bruto (Kg)</label>
          <input pInputText id="pesoBruto" [(ngModel)]="pesoBruto" maxlength="7" size="7"/>
        </div>
        <div class="col-sm-2 form-group">
          <label for="">Peso Tara (Kg)</label>
          <input pInputText id="pesoTara" [(ngModel)]="pesoTara" maxlength="7" size="7"/>
        </div>
        <div class="col-sm-2 form-group">
          <label for="">Peso Líquido (Kg)</label>
          <input pInputText id="pesoLiquido" [(ngModel)]="pesoLiquido" maxlength="7" size="7" disabled="true"/>
        </div>
        <div class="col-sm-4 form-group">
        </div>
      </div>
    </div>
    <div class="col-sm-12 form-group">
      <div class="row" class="text-left">
        INFORMAÇÃO!! <br>
        Ao mudar o código da balança e SALVAR, esse registro será computado para o código de balança informado.
      </div>
      <div class="row" class="text-right botoes">
        <button title="Salvar" type="button" (click)="atualizarDescarga()"
        class="btn btn-primary mb-2"> <i class="pi pi-check"></i>Salvar</button>
        <button title="Excluir" type="button" class="btn btn-primary mb-2"
        (click)="excluirDescarga()"> <i class="pi pi-trash"></i>Excluir</button>
      </div>
    </div>

  </div>
  
</p-dialog>


<p-dialog header="Balança - Total por Resíduos para o mês de {{mesPesquisa}}" 
  [(visible)]="mostrarTotalResiduo" [responsive]="true" showEffect="fade" [modal]="true" 
  [width]="1500" [contentStyle]="{'max-height':'800px'}" resizable="true" class="scroller container">
  <div class="row">

    <div class="col-sm-3 form-group">
      <label for="cpf">CNPJ</label>
      <div class="card">
        <div class="card-header">
          {{entidade?.cnpj}}
        </div>
      </div>
    </div>
    <div class="col-sm-3 form-group">
      <label for="cpf">Razão Social</label>
      <div class="card">
        <div class="card-header">
          {{entidade?.razaoSocial}}
        </div>
      </div>
    </div>
    <div class="col-sm-3 form-group">
      <label for="cpf">Código DMLU</label>
      <div class="card">
        <div class="card-header">
          {{entidade?.codigoDmlu}}
        </div>
      </div>
    </div>

    <p-table #tabelaBalanca [value]="contratoTotalResiduos" [paginator]="true" sortField="codigoBalanca"
      [rows]="10" [globalFilterFields]="['codigoBalanca', 'nomeEmpresa']"
      [style]="{ width: '1300px' }">
      <ng-template pTemplate="header">
        <tr>
          <th class="text-center" style="width: 100px; height: 60px;">
            Resíduo
          </th>
          <!-- <th class="text-center" style="width: 50px; height: 60px;">
            Data Descarga
          </th> -->
          <th class="text-center" style="width: 50px; height: 60px;">
            Peso Líquido (Kg)
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-cdr>
        <tr>
          <td>
            {{ cdr.nomeResiduo }}
          </td>
          <!-- <td>
            {{ cdr.dataDescargaInicio | date: 'dd/MM/yyyy' : 'pt' }}
          </td> -->
          <td>
            {{ cdr.total | milhar }}
          </td>
        </tr>
      </ng-template>
    </p-table>

  </div>
  
</p-dialog>