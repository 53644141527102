import { Classificacao } from './classificacao.model';
// export enum TipoUnidadeMedida {
//   Kg = 'kg',
//   Tonelada = 't',
//   Litro = 'L',
//   MetroCubico = 'm³',
//   Unidade = 'unidade'
// }

export class Produto {
  id?: number;
  codigo?: string;
  nome?: string;
  descricao?: string;
  // unidadeMedida: TipoUnidadeMedida;
  classificacaoDO?: Classificacao;
  timestampAsString?: string;
  dataInclusao?: string;
  usuarioInclusao?: string;
  usuarioAlteracao?: string;
  mtrcc?: boolean;
  lrr?: boolean;
  gg?: boolean;
  gdrTransbordo?: boolean;
  gdrCompostagem?: boolean;
  gdrTriagem?: boolean;
  ativo?: boolean;
  selecionado?: boolean;
}

export interface ProdutoInter {
  id: number;
  codigo: string;
  nome: string;
  descricao: string;
  // unidadeMedida: TipoUnidadeMedida;
  classificacaoDO: Classificacao;
  timestampAsString: string;
  dataInclusao: string;
  usuarioInclusao: string;
  usuarioAlteracao: string;
  mtrcc: boolean;
  lrr: boolean;
  gg: boolean;
  gdrTransbordo: boolean;
  gdrCompostagem: boolean;
  gdrTriagem: boolean;
  ativo: boolean;
  selecionado?: boolean;
}


