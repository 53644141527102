import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import {MenubarModule} from "primeng/primeng";
import { TabMenuModule } from "primeng/tabmenu";
import { MenuComponent } from "./menu.component";

@NgModule({
  imports: [
    CommonModule,
    MenubarModule,
    TabMenuModule,
  ],
  declarations: [
    MenuComponent
  ],
  exports: [
    MenuComponent
  ]
})
export class MenuModuleSGR {}
