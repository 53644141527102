<sgr-menu></sgr-menu>

<div class="container">

  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/sgrIni">SGR</a></li>
    <li class="breadcrumb-item active" aria-current="page">Cadastro</li>
  </ol>

  <h1 class="page-header text-primary">
    Usuários da PMPA<br>
    <small>Cadastro</small>
  </h1>


  <div>
    <p-table #tabelaDestinosFinais [value]="pfsSecretaria" [paginator]="true" sortField="nomeCompleto" [rows]="10"
      [globalFilterFields]="['nomeCompleto', 'sigla']">
      <ng-template pTemplate="caption">
        <div class="row justify-content-between">
          <div class="col-auto form-group">
            <button type="button" class="btn btn-primary text-nowrap" (click)="mostrarDialogParaNovo()"
              label="Adicionar" *ngIf="!variaveisGlobais.roleEntidade">
              <i class="fa fa-plus pr-2"></i>Adicionar Usuário
            </button>
          </div>
          <div class="col-sm-12 col-md-6 form-group busca text-right">
            <input type="text" pInputText size="50" placeholder="Filtrar usuários"
              (input)="tabelaDestinosFinais.filterGlobal($event.target.value, 'contains')" class="form-control" />
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="nomeCompleto" class="text-center" style="width: 100px; height: 60px;">
            Nome
            <p-sortIcon field="nomeCompleto"></p-sortIcon>
          </th>
          <th pSortableColumn="sigla" class="text-center" style="width: 80px; height: 60px;">
            Secretaria
            <p-sortIcon field="sigla"></p-sortIcon>
          </th>
          <th class="text-center" style="width: 200px; height: 60px;">
            Função
          </th>
          <th style="width: 150px;" class="text-center">
            Ações
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-usuarioPmpa>
        <tr>
          <td>
            {{ usuarioPmpa?.nomeCompleto }}
          </td>
          <td>
            {{ usuarioPmpa?.sigla }}
          </td>
          <td>
            <!-- <span *ngIf='usuarioPmpa.rLegal === true'>Resp. Legal</span> -->
            <span *ngIf='usuarioPmpa.rusrLegal === true'>Usuário</span>
            <span *ngIf='usuarioPmpa.rSecretario === true'>Secretário</span>
            <!-- <span *ngIf='usuarioPmpa.rtElaboracao === true && usuarioPmpa.rtExecucao === false'>RT Elaboração</span>
            <span *ngIf='usuarioPmpa.rtElaboracao === false && usuarioPmpa.rtExecucao === true'>RT Execução</span>
            <span *ngIf='usuarioPmpa.rtElaboracao === true && usuarioPmpa.rtExecucao === true'>RT Ambos</span> -->
          </td>
          <td>
            <button title="Editar" (click)="mostrarDialogParaEditar(usuarioPmpa.id)" label="Detalhes"
              class="btn btn-outline-secondary mx-2">
              <i class="fa fa-info pr-2"></i>Detalhes
            </button>
            <span class="text-danger" *ngIf="!usuarioPmpa.ativo">Inativo</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<!-- Dialog para novo -->
<p-dialog [header]="tituloDialogEdit" [(visible)]="mostrarDialogEdit" [responsive]="true" showEffect="fade"
  [modal]="true" [width]="1000">


  <div class="row">

    <div class="col-sm-4 col-lg-auto form-group">
      <label for="cpf">CPF</label>
      <p-inputMask id="cpf" mask="999.999.999-99" [(ngModel)]="pessoaFisicaEdit.cpf" placeholder="__.___.___/____-__"
        size="20" *ngIf="!isInsert" disabled></p-inputMask>
      <p-inputMask id="cpf" mask="999.999.999-99" [(ngModel)]="pessoaFisicaEdit.cpf" placeholder="__.___.___/____-__"
        size="20" *ngIf="isInsert" (onBlur)="verificaCpfExiste(pessoaFisicaEdit.cpf)"></p-inputMask>
    </div>

    <div class="col-sm-8 form-group">
      <label for="float-input">Nome</label>
      <input id="float-input" type="text" size="60" pInputText [(ngModel)]="pessoaFisicaEdit.nomeCompleto"
        class="form-control">
    </div>

    <!-- <div class="ui-g-6">
      <label for="lastName">Sobrenome</label>
      <br>
      <input id="lastName" type="text" size="30" pInputText [(ngModel)]="pessoaFisica.lastName">
    </div> -->

    <div class="col-sm-6 col-lg-5 form-group">
      <label for="email">Email</label>
      <input id="email" type="text" pInputText [(ngModel)]="pessoaFisicaEdit.email" size="40" class="form-control" />
    </div>

    <div class="col-sm-3 col-lg-2 form-group">
      <label for="fone">Fone Comercial</label>
      <p-inputMask id="fone" mask="(99) 9999-9999?9" [(ngModel)]="pessoaFisicaEdit.telefone" size="15"></p-inputMask>
    </div>

    <div class="col-sm-3 col-lg-2 form-group">
      <label for="fone">Celular</label>
      <p-inputMask id="fone" mask="(99) 99999-999?9" [(ngModel)]="pessoaFisicaEdit.celular" size="15"></p-inputMask>
    </div>
    <div class="col-sm-auto form-group">
      <label for="vin">Secretaria/Órgão</label>
      <p-dropdown [options]="secretarias" [(ngModel)]="idSecretaria" placeholder="Selecione"
        (onChange)="selSecretaria($event.label)"></p-dropdown>
      <!-- <p-multiSelect [options]="secretarias" [(ngModel)]="pessoaFisica.idSecretariaPmpa" [filter]="false" defaultLabel="Selecione" selectionLimit="5" [maxSelectedLabels]="5" [style]="{'width':'300px'}" appendTo="body"></p-multiSelect> -->
    </div>
    <div class="col-sm-auto form-group mr-4">
      <label for="vin">Situação</label>
      <div>
        <p-radioButton name="prodAtivo" [value]="true" label="Ativo" [(ngModel)]="pessoaFisicaEdit.ativo">
        </p-radioButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <p-radioButton name="prodAtivo" [value]="false" label="Inativo" [(ngModel)]="pessoaFisicaEdit.ativo">
        </p-radioButton>
      </div>
    </div>

    <div class="col-sm-auto form-group">
      <label for="fone">Função</label>
      <div>
        <p-radioButton name="funcao" value="rSecretario" label="Secretário" [(ngModel)]="pfFuncao" inputId="f02"
          (click)="controlaFuncao(pfFuncao)"></p-radioButton>&nbsp;&nbsp;&nbsp;
        <p-radioButton name="funcao" value="rLegal" label="Responsável Legal" [(ngModel)]="pfFuncao" inputId="f01"
          (click)="controlaFuncao(pfFuncao)" *ngIf="variaveisGlobais.menuRaiz!=='mtrcc'"></p-radioButton>
        &nbsp;&nbsp;&nbsp;
        <p-radioButton name="funcao" value="rusrLegal" label="Usuário" [(ngModel)]="pfFuncao" inputId="f02"
          (click)="controlaFuncao(pfFuncao)"></p-radioButton>&nbsp;&nbsp;&nbsp;
        <p-radioButton name="funcao" value="gdrContratoDmlu" label="Contrato GDR" [(ngModel)]="pfFuncao" inputId="f02"
          (click)="controlaFuncao(pfFuncao)" *ngIf="gdrDmluContrato"></p-radioButton>&nbsp;&nbsp;&nbsp;
        <!-- <p-radioButton name="funcao" value="rElaboracao" label="RT Elaboração" [(ngModel)]="pfFuncao" inputId="f02"></p-radioButton>&nbsp;&nbsp;&nbsp;
          <p-radioButton name="funcao" value="rExecucao" label="RT Execução" [(ngModel)]="pfFuncao" inputId="f03"></p-radioButton>&nbsp;&nbsp;&nbsp; -->

        <!-- <p-checkbox name="group1" value="rElaboracao" label="RT Elaboração" [(ngModel)]="pfFuncaoRT" inputId="rtel"
                (click)="controlaFuncao(pfFuncaoRT)" *ngIf="variaveisGlobais.menuRaiz!=='mtrcc'"></p-checkbox>&nbsp;&nbsp;&nbsp;
          <p-checkbox name="group1" value="rExecucao" label="RT Execução" [(ngModel)]="pfFuncaoRT" inputId="rtex"
                (click)="controlaFuncao(pfFuncaoRT)" *ngIf="variaveisGlobais.menuRaiz!=='mtrcc'"></p-checkbox>&nbsp;&nbsp;&nbsp; -->
      </div>
    </div>

  </div>

  <div class="botoes text-right" *ngIf="!variaveisGlobais.roleEntidade">
    <button type="button" (click)="mostrarDialogEdit = false" label="Cancelar" class="btn btn-outline-secondary">
      Cancelar
    </button>
    <button type="button" (click)="salvar()" label="Salvar" class="btn btn-primary">
      Salvar
    </button>
  </div>
</p-dialog>

<!-- <p-dialog
  header="Excluir Destino Final" [(visible)]="mostrarDialogExcluir" [responsive]="true" showEffect="fade" [modal]="true" [width]="500">
  <h3>
    Confirma a exclusão do destino final '{{ pessoaFisicaExcluir?.nomeCompleto}}'?
  </h3>
  <div class="ui-g-12"></div>
  <div style="text-align: right;">
    <button type="button" class="btn btn-outline-secondary" (click)="mostrarDialogExcluir = false" label="Não, cancelar">
      Não. Cancelar.
    </button>
    <button type="button" class="btn btn-outline-primary" (click)="excluir()" label="Sim, excluir" style="margin-left: 5px;">
      Sim. Excluir.
    </button>
  </div>
</p-dialog> -->