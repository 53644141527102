import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({providedIn: 'root'})
export class NotificacoesService {

  notificacoesSubject: Subject<Object> = new Subject<Object>();

  private notificar(severity: string, summary: string, detail: string) {
    console.log('notificar>>>>>>>', severity, summary, detail);
    this.notificacoesSubject.next({ severity, summary, detail });
  }

  notificarInfo(mensagem: string, detalhe: string) {
    this.notificar('info', mensagem, detalhe);
  }

  notificarSucesso(mensagem: string, detalhe: string) {
    this.notificar('success', mensagem, detalhe);
  }

  notificarAviso(mensagem: string, detalhe: string) {
    this.notificar('warn', mensagem, detalhe);
  }

  notificarErro(mensagem: string, detalhe: string) {
    console.log('notificarErro>>>>>>>', mensagem, detalhe);

    this.notificar('error', mensagem, detalhe);
  }

}
