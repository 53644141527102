import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/globals';
import { environment } from 'src/environments/environment';
import { SpinnerService } from './@core/services/spinner.service';
import { PessoaFisicaService } from './@core/services/pessoaFisica.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  variaveisGlobais: Globals;

  constructor(
    private router: Router,
    private spinnerService: SpinnerService,
    private pessoaFisicaService: PessoaFisicaService,
    private globals: Globals
  ) {
    // console.log("AppComponent", router.url);
    this.variaveisGlobais = globals;
  }

  ngOnInit() {
    const url = window.location.origin.split('//');
    this.spinnerService.ocultar();

    if (url[1] === '10.110.114.228:4200' || url[1] === 'localhost:4200') {

      // this.globals.roleEntidade = false;
      // environment.apiUrl = environment.apiUrl + '/internal';
      this.globals.roleEntidade = true;
      environment.apiUrl = environment.apiUrl + '/external';

    } else {
      const urlIs = url[1].split('.');
      //  console.log("UrlIs..............", urlIs[0]);
      if (urlIs[0] === 'sgr' || urlIs[0] === 'sgr-hom') {
        this.globals.roleEntidade = true;
        environment.apiUrl = environment.apiUrl + '/external';
      }
      if (urlIs[0] === 'sgr-admin' || urlIs[0] === 'sgr-admin-hom') {
        this.globals.roleEntidade = false;
        environment.apiUrl = environment.apiUrl + '/internal';
      }
    }

    if (!this.globals.roleEntidade) {
      this.router.navigate(['/sgrIni']);
    } else {
      this.globals.recuperaKeycloak().subscribe(user => {
        this.pessoaFisicaService
          .getPessoaFisicaPorEmail(this.globals.userEmail)
          .subscribe(pessoasFisicas => {
            if (pessoasFisicas.length < 1) {
              this.router.navigate(['/cadastro']);
            } else {
              this.router.navigate(['/sgrIni']);
            }
          });
      });
    }
  }
}