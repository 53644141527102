import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ClassificacaoService = /** @class */ (function () {
    function ClassificacaoService(http) {
        this.http = http;
    }
    ClassificacaoService.prototype.getClassificacoes = function () {
        return this.http.get(environment.apiUrl + '/classificacao/');
    };
    ClassificacaoService.prototype.excluirClassificacao = function (classificacao) {
        return this.http.delete(environment.apiUrl + '/classificacao/' + classificacao.id);
    };
    ClassificacaoService.ngInjectableDef = i0.defineInjectable({ factory: function ClassificacaoService_Factory() { return new ClassificacaoService(i0.inject(i1.HttpClient)); }, token: ClassificacaoService, providedIn: "root" });
    return ClassificacaoService;
}());
export { ClassificacaoService };
