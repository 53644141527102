var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { EntidadeService } from '../entidade/entidade.service';
import { SpinnerService } from '../services/spinner.service';
import { NotificacoesService } from '../services/notificacoes.service';
import { ClassificacaoService } from '../services/classificacao.service';
import { Globals } from 'src/app/globals';
import { EnderecoService } from '../services/endereco.service';
import { LicencaAmbientalService } from '../services/licencaAmbiental.service';
var DestinoFinalComponent = /** @class */ (function () {
    function DestinoFinalComponent(router, keycloakService, destinoFinalService, entidadeService, licencaAmbientalService, spinnerService, notificacoesService, classificacaoService, globals) {
        this.router = router;
        this.keycloakService = keycloakService;
        this.destinoFinalService = destinoFinalService;
        this.entidadeService = entidadeService;
        this.licencaAmbientalService = licencaAmbientalService;
        this.spinnerService = spinnerService;
        this.notificacoesService = notificacoesService;
        this.classificacaoService = classificacaoService;
        this.globals = globals;
        this.destinosFinais = [];
        this.entidadeNome = '';
        this.licencas = [];
        this.licencasItem = [];
        this.licencaIsento = false;
        this.enderecoRes = {};
        this.activeRoute = this.router.url;
        this.variaveisGlobais = globals;
    }
    DestinoFinalComponent.prototype.ngOnInit = function () {
        console.log('000ListaProdutosComponent>>>>>>>>>>>', this.variaveisGlobais);
        if (this.variaveisGlobais.menuRaiz === null || this.variaveisGlobais.menuRaiz === '') {
            this.router.navigate(['/sgrIni']);
        }
        else {
            this.inicializaOnInit();
        }
    };
    DestinoFinalComponent.prototype.inicializaOnInit = function () {
        var _this = this;
        this.spinnerService.exibir();
        // this.destinoFinalEdit = {
        //   id: null,
        //   dataInclusao: null,
        //   timestampAsString: null,
        //   usuarioInclusao: null,
        //   usuarioAlteracao: null,
        //   nome: '',
        //   observacao: '',
        //   entidadeDO: null,
        //   endereco: null,
        //   ativo: true
        // };
        this.destinoFinalEdit = {
            id: null,
            dataInclusao: null,
            timestampAsString: null,
            usuarioInclusao: null,
            usuarioAlteracao: null,
            cep: null,
            codigoBairro: null,
            codigoLogradouro: null,
            codigoLogradouroFim: null,
            codigoLogradouroInicio: null,
            dataHistorico: null,
            enderecoFormatado: null,
            enderecoFormatadoCurto: null,
            lado: null,
            localizacao: null,
            nomeBairro: null,
            nomeBairroHistorico: null,
            nomeHistorico: null,
            nomeLogradouro: null,
            nomeLogradouroFim: null,
            nomeLogradouroInicio: null,
            numero: null,
            municipio: null,
            situacao: null,
            entidadeDO: null,
            identificacao: null,
            observacao: null,
            ativo: true
        };
        this.entidadeService.getEntidadeCnpj('92.963.560/0001-60').subscribe(function (entidade) {
            if (entidade != null) {
                _this.entidadePMPA = entidade;
                _this.destinoFinalService.getEnderecosPorEntidade(entidade.id).subscribe(function (destinosFinais) {
                    console.log('Destinos Finais ........ >>>>>>>>', destinosFinais);
                    _this.spinnerService.ocultar();
                    destinosFinais.forEach(function (df) {
                        if (df.identificacao != null) {
                            _this.destinosFinais.push(df);
                        }
                    });
                });
                _this.licencasItem = [];
                _this.licencaAmbientalService.getLicencasPorEntidade(entidade.id).subscribe(function (licencas) {
                    _this.licencas = licencas;
                    console.log('Licencas Ambientais>>>>>Licenças>>>>>>>>>', _this.licencas);
                    licencas.forEach(function (lic) {
                        _this.licencasItem.push({ label: lic.licenca, value: lic.id });
                    });
                }, function (error) {
                    _this.spinnerService.ocultar();
                    _this.notificacoesService.notificarErro(error.error, null);
                });
            }
        });
        // this.destinoFinalService.cepCorreios().subscribe(cep => {
        //   console.log('CEP CORREIOS>>>>>>>>', cep);
        // },
        // error => {
        //   this.spinnerService.ocultar();
        //   this.notificacoesService.notificarErro(error.error, null);
        // });
    };
    DestinoFinalComponent.prototype.logout = function () {
        var url = document.location.protocol + '//' + document.location.hostname + ':' + document.location.port;
        this.keycloakService.getKeycloakInstance().logout({ redirectUri: url });
    };
    DestinoFinalComponent.prototype.mostrarDialogParaNovo = function () {
        // this.destinoFinalEdit = {
        //   id: null,
        //   dataInclusao: null,
        //   timestampAsString: null,
        //   usuarioInclusao: null,
        //   usuarioAlteracao: null,
        //   nome: '',
        //   observacao: '',
        //   entidadeDO: null,
        //   endereco: null,
        //   ativo: true
        // };
        this.destinoFinalEdit = {
            id: null,
            dataInclusao: null,
            timestampAsString: null,
            usuarioInclusao: null,
            usuarioAlteracao: null,
            cep: null,
            codigoBairro: null,
            codigoLogradouro: null,
            codigoLogradouroFim: null,
            codigoLogradouroInicio: null,
            dataHistorico: null,
            enderecoFormatado: null,
            enderecoFormatadoCurto: null,
            lado: null,
            localizacao: null,
            nomeBairro: null,
            nomeBairroHistorico: null,
            nomeHistorico: null,
            nomeLogradouro: null,
            nomeLogradouroFim: null,
            nomeLogradouroInicio: null,
            numero: null,
            municipio: null,
            situacao: null,
            entidadeDO: null,
            identificacao: null,
            observacao: null,
            ativo: true
        };
        this.mostrarDialogEdit = true;
        this.isInsert = true;
        this.tituloDialogEdit = 'Novo Destino Final';
        this.destinoFinalEdit.isentoLicenca = false;
        this.idLicenca = null;
        this.licencaIsento = true;
    };
    DestinoFinalComponent.prototype.mostrarDialogParaEditar = function (idDestinoFinal) {
        var destinoFinalParaEditar = this.destinosFinais.find(function (destinoFinal) {
            return destinoFinal.id === idDestinoFinal;
        });
        this.destinoFinalEdit = __assign({}, destinoFinalParaEditar);
        console.log('mostrarDialogParaEditar>>>>>>>>>>>>>>', this.destinoFinalEdit);
        this.mostrarDialogEdit = true;
        this.isInsert = false;
        this.tituloDialogEdit = 'Destino Final';
        this.idLicenca = null;
        if (this.destinoFinalEdit.licencaAmbientalDO !== null) {
            this.idLicenca = this.destinoFinalEdit.licencaAmbientalDO.id;
        }
        if (this.destinoFinalEdit.isentoLicenca !== null) {
            this.licencaIsento = this.destinoFinalEdit.isentoLicenca;
        }
    };
    DestinoFinalComponent.prototype.mostrarDialogEditParaExcluir = function (idProduto) {
        // this.produtoExcluir =  this.produtos.find(function(produto) {
        //   return produto.id === idProduto;
        // });
        // this.mostrarDialogExcluir = true;
    };
    DestinoFinalComponent.prototype.selLicenca = function () {
        this.licencaIsento = false;
    };
    DestinoFinalComponent.prototype.licencaIsencao = function (licencaIsento) {
        // console.log('===========licencaIsencao=========>', licencaIsento.binary);
        console.log('===========licencaIsencao=========>', this.licencaIsento);
        this.destinoFinalEdit.isentoLicenca = this.licencaIsento;
        if (this.licencaIsento) {
            this.idLicenca = null;
        }
    };
    DestinoFinalComponent.prototype.salvar = function () {
        var _this = this;
        //Verifica se todos os campos foram preenchidos
        if (this.destinoFinalEdit.identificacao == null || this.destinoFinalEdit.identificacao === '') {
            this.notificacoesService.notificarErro('Por favor informar o NOME do Destino Final!', null);
            return;
        }
        this.spinnerService.exibir();
        console.log('salvar....................>>>>>>>>>>>>>>', this.destinoFinalEdit);
        this.destinoFinalEdit.municipio = 'Porto Alegre';
        this.destinoFinalEdit.entidadeDO = this.entidadePMPA;
        this.destinoFinalEdit.licencaAmbientalDO = null;
        this.licencas.forEach(function (lic) {
            if (lic.id === _this.idLicenca) {
                _this.destinoFinalEdit.licencaAmbientalDO = lic;
            }
        });
        this.destinoFinalEdit.isentoLicenca = this.licencaIsento;
        // Se novo
        if (this.destinoFinalEdit.id == null) {
            this.destinoFinalService.criarEndereco(this.destinoFinalEdit).subscribe(function (destinoFinalNovo) {
                console.log('salvado....................>>>>>>>>>>>>>>', destinoFinalNovo);
                _this.destinosFinais.push(destinoFinalNovo);
                _this.mostrarDialogEdit = false;
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarSucesso('Adicionado com sucesso', null);
            }, function (error) {
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro('Erro ao adicionar ', error);
            });
        }
        else { // Se editando
            this.destinoFinalService.editarEndereco(this.destinoFinalEdit).subscribe(function (destinoFinalEditado) {
                // Remove o valor atual
                _this.destinosFinais = _this.destinosFinais.filter(function (item) { return item.id !== _this.destinoFinalEdit.id; });
                // Adiciona o novo
                _this.destinosFinais.push(destinoFinalEditado);
                _this.mostrarDialogEdit = false;
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarSucesso('Atualizado com sucesso', null);
            }, function (error) {
                console.log(error);
                _this.spinnerService.ocultar();
                _this.notificacoesService.notificarErro(error.error, null);
            });
        }
        this.idLicenca = null;
        this.licencaIsento = null;
    };
    DestinoFinalComponent.prototype.isSMAMS = function () {
        var ret = this.keycloakService.hasRole('SMAMS');
        return ret;
    };
    DestinoFinalComponent.prototype.excluir = function () {
        // this.spinnerService.exibir();
        // console.log('salvar....................>>>>>>>>>>>>>>', this.variaveisGlobais.menuRaiz);
        // this.produtoService.excluirProduto(this.produtoExcluir, this.variaveisGlobais.menuRaiz).subscribe(produto => {
        //   if (produto == null) {
        //     // Remove o valor atual
        //     this.produtos = this.produtos.filter(item => item.id !== this.produtoExcluir.id);
        //   }
        //   this.mostrarDialogExcluir = false;
        //   this.spinnerService.ocultar();
        //   this.notificacoesService.notificarSucesso('Excluído com sucesso', null);
        // },
        // error => {
        //   console.log(error);
        //   this.spinnerService.ocultar();
        //   this.notificacoesService.notificarErro('Erro ao exluir', error.error);
        // });
    };
    DestinoFinalComponent.prototype.checkActive = function (route) {
        return route === this.activeRoute;
    };
    DestinoFinalComponent.prototype.selecionaCDL = function (endereco) {
        this.localizacao = { latitude: null, longitude: null };
        if (endereco.latitude !== undefined && endereco.latitude !== null) {
            this.localizacao = {
                latitude: endereco.latitude,
                longitude: endereco.longitude
            };
            delete endereco['latitude'];
            delete endereco['longitude'];
        }
        this.destinoFinalEdit.cep = endereco.cep;
        this.destinoFinalEdit.codigoBairro = endereco.codigoBairro;
        this.destinoFinalEdit.codigoLogradouro = endereco.codigoLogradouro;
        this.destinoFinalEdit.codigoLogradouroFim = endereco.codigoLogradouroFim;
        this.destinoFinalEdit.codigoLogradouroInicio = endereco.codigoLogradouroInicio;
        this.destinoFinalEdit.dataHistorico = endereco.dataHistorico;
        this.destinoFinalEdit.enderecoFormatado = endereco.enderecoFormatado;
        this.destinoFinalEdit.enderecoFormatadoCurto = endereco.enderecoFormatadoCurto;
        this.destinoFinalEdit.lado = endereco.lado;
        this.destinoFinalEdit.localizacao = this.localizacao;
        this.destinoFinalEdit.nomeBairro = endereco.nomeBairro;
        this.destinoFinalEdit.nomeBairroHistorico = endereco.nomeBairroHistorico;
        this.destinoFinalEdit.nomeHistorico = endereco.nomeHistorico;
        this.destinoFinalEdit.nomeLogradouro = endereco.nomeLogradouro;
        this.destinoFinalEdit.nomeLogradouroFim = endereco.nomeLogradouroFim;
        this.destinoFinalEdit.nomeLogradouroInicio = endereco.nomeLogradouroInicio;
        this.destinoFinalEdit.numero = endereco.numero;
        this.destinoFinalEdit.municipio = endereco.municipio;
        this.destinoFinalEdit.situacao = endereco.situacao;
    };
    return DestinoFinalComponent;
}());
export { DestinoFinalComponent };
