<sgr-menu></sgr-menu>
<div class="container">

  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/sgrIni">SGR</a></li>
    <li class="breadcrumb-item active" aria-current="page">Cadastro</li>
  </ol>

  <h1 class="page-header text-primary">
    Resíduos<br>
    <small>Cadastro</small>
  </h1>

  <div class="mobile-overflow-x">
    <p-table #tabelaProdutos [value]="produtos" [paginator]="true" sortField="codigo" [rows]="10"
      [globalFilterFields]="['nome', 'descricao', 'codigo']">
      <ng-template pTemplate="caption">
        <div class="row justify-content-between">
          <div class="col-auto form-group">
            <button type="button" class="btn btn-primary text-nowrap" (click)="mostrarDialogParaNovo()"
              label="Adicionar" *ngIf="!variaveisGlobais.roleEntidade">
              <i class="fa fa-plus pr-2"></i>Adicionar tipo de resíduo
            </button>
          </div>

          <div class="col-auto col-md-6 form-group busca text-right">
            <input type="text" pInputText size="50" placeholder="Filtrar resíduos" (input)="
                tabelaProdutos.filterGlobal($event.target.value, 'contains')
              " class="form-control" />
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <!-- <th pSortableColumn="codigo" style="width: 63px;">
          Código
          <p-sortIcon field="codigo"></p-sortIcon>
        </th> -->
          <th pSortableColumn="codigo" class="text-center" style="width: 80px; height: 60px;"
            *ngIf="variaveisGlobais.menuRaiz == 'grandeGerador' || variaveisGlobais.menuRaiz == 'gdr'">
            Número
            <p-sortIcon field="nome"></p-sortIcon>
          </th>

          <th class="text-center" style="width: 310px; height: 60px;"
            *ngIf="variaveisGlobais.menuRaiz == 'grandeGerador' || variaveisGlobais.menuRaiz == 'gdr'">
            Tipo Resíduo
          </th>
          <th pSortableColumn="nome" class="text-center" style="width: 310px; height: 60px;"
            *ngIf="variaveisGlobais.menuRaiz == 'mtrcc'">
            Nome
            <p-sortIcon field="nome"></p-sortIcon>
          </th>

          <th class="text-center" style="width: 310px; height: 60px;">
            Descrição
          </th>
          <th class="text-center" style="width: 210px; height: 60px;">
            Unidade
          </th>

          <!-- <th pSortableColumn="unidadeMedida" style="width: 70px;">
          Un. Medida
          <p-sortIcon field="unidadeMedida"></p-sortIcon>
        </th> -->

          <th style="width: 240px;" class="text-center" *ngIf="!variaveisGlobais.roleEntidade">
            Ações
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-produto>
        <tr>
          <!--
        <td>
            <button pButton title="Editar" (click)="mostrarDialogParaEditar(produto.id)" label="{{produto.codigo}}" class="ui-button-secondary" style="width: 80px"></button>
            <a (click)="mostrarDialogParaEditar(produto.id)"><u>{{produto.codigo}}</u></a>
          </td>
        -->
          <!-- <td>{{produto.codigo}}</td> -->
          <td *ngIf="variaveisGlobais.menuRaiz == 'grandeGerador' || variaveisGlobais.menuRaiz == 'gdr'"
            class="text-center">
            {{ produto.codigo }}
          </td>
          <td>{{ produto.nome }}</td>
          <td data-toggle="tooltip" data-placement="left" title="{{ produto.descricao }}">
            {{ produto.descricao | reduzObs }}
          </td>
          <td>
            <span *ngIf='produto.gdrTransbordo'>Estação de Transbordo</span>
            <span *ngIf='produto.gdrCompostagem'>Unidade de Compostagem</span>
            <span *ngIf='produto.gdrTriagem'>Unidade de Triagem</span>
          </td>
          <td *ngIf="!variaveisGlobais.roleEntidade">
            <div class="botoes">
              <button title="Editar" (click)="mostrarDialogParaEditar(produto.id)" label="Detalhes"
                class="btn btn-outline-secondary mx-2">
                <i class="fa fa-info pr-2"></i>Detalhes
              </button>
              <button title="Excluir" (click)="mostrarDialogEditParaExcluir(produto.id)"
                class="btn btn-outline-secondary mx-2" *ngIf="produto.ativo">
                <i class="pi pi-trash pr-2"></i>Excluir
              </button>
              <span class="text-danger ml-2" *ngIf="!produto.ativo">Inativo</span>
              <!-- troquei div por span -->
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<!-- Dialog para novo -->
<p-dialog [header]="tituloDialogEdit" [(visible)]="mostrarDialogEdit" [responsive]="true" showEffect="fade"
  [modal]="true" [width]="700">
  <div class="" *ngIf="produtoEdit">
    <div class="row">

      <div class="col-md-4 form-group"
        *ngIf="variaveisGlobais.menuRaiz == 'grandeGerador' || variaveisGlobais.menuRaiz == 'gdr'">
        <label>Número</label>
        <input pInputText id="codigo" [(ngModel)]="produtoEdit.codigo" class="form-control" size='20' />
      </div>

      <div class="col-sm-3 form-group" *ngIf="variaveisGlobais.menuRaiz == 'mtrcc'">
        <label for="vin">Classificação</label>
        <p-dropdown [options]="classificacoes" [(ngModel)]="selClassificacao" placeholder="Selecione"></p-dropdown>
      </div>

      <div class="col-sm-9 form-group">
        <label for="vin" *ngIf="variaveisGlobais.menuRaiz == 'grandeGerador'
          || variaveisGlobais.menuRaiz == 'gdr'">Tipo Resíduo</label>
        <label for="vin" *ngIf="variaveisGlobais.menuRaiz == 'mtrcc'">Nome</label>
        <input pInputText id="nome" [(ngModel)]="produtoEdit.nome" class="form-control" size='75' />
      </div>

      <div class="col-12 form-group">
        <label for="vin">Descrição</label>
        <textarea pInputTextarea id="descricao" [(ngModel)]="produtoEdit.descricao" class="form-control" [rows]="2"
          [cols]='75'></textarea>
      </div>

      <div class="col-12 form-group">
        <label for="vin">Situação do resíduo</label>
        <div>
          <p-radioButton name="prodAtivo" [value]="true" label="Ativo" [(ngModel)]="produtoEdit.ativo"></p-radioButton>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p-radioButton name="prodAtivo" [value]="false" label="Inativo" [(ngModel)]="produtoEdit.ativo">
          </p-radioButton>
        </div>
      </div>

      <div class="col-12 form-group" *ngIf="variaveisGlobais.menuRaiz == 'gdr'">
        <label for="vin">DMLU - Unidade de destino</label>
        <div>
          <p-radioButton name="gdrDestino" value="transbordo" label="Estação de Transbordo" [(ngModel)]="gdrDestino">
          </p-radioButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p-radioButton name="gdrDestino" value="compostagem" label="Unidade de Compostagem" [(ngModel)]="gdrDestino">
          </p-radioButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p-radioButton name="gdrDestino" value="triagem" label="Unidade de Triagem" [(ngModel)]="gdrDestino">
          </p-radioButton>
        </div>
      </div>

      <!-- <div class="ui-g-12">
      <div class="ui-g-3">
        <label for="vin">Associado ao:</label>
      </div>
      <div class="ui-g-4">
        <p-selectButton [options]="tipoListas" [(ngModel)]="selectedTipos" multiple="multiple"></p-selectButton>
      </div>
    </div> -->

      <!-- Espaços para campo de unidades ser exibida dentro da dialog -->
      <!-- <div class="ui-g-12"></div>
    <div class="ui-g-12"></div> -->

    </div>
  </div>

  <div class="botoes text-right" *ngIf="!variaveisGlobais.roleEntidade">
    <button type="button" (click)="mostrarDialogEdit = false" label="Cancelar" class="btn btn-outline-secondary">
      Cancelar
    </button>
    <button type="button" (click)="salvar()" label="Salvar" class="btn btn-primary">
      Salvar
    </button>
  </div>
</p-dialog>

<p-dialog header="Excluir Resíduo" [(visible)]="mostrarDialogExcluir" [responsive]="true" showEffect="fade"
  [modal]="true" [width]="500">
  <p *ngIf="produtoExcluir" class="mb-5">
    <strong>Confirma a exclusão</strong> do resíduo <em>{{ produtoExcluir.nome }}</em>?
  </p>
  <div class="botoes text-right">
    <button type="button" class="btn btn-outline-secondary" (click)="mostrarDialogExcluir = false"
      label="Não, cancelar">
      Não. Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="excluir()" label="Sim, excluir">
      Sim. Excluir
    </button>
  </div>
</p-dialog>